import "../../../Assets/Styles/Header.css";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { useAuth } from "./AuthContext";
import { useDispatch } from "react-redux";
import { setUserUnauthenticated } from "../../../redux/authSlice";
import disableBackButtonAfterSignout from "../../../redux/disableBackButton";
import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import profileicon from "../../../Assets/Images/profileicon.png"


const UserHeader = ({ heading }) => {
  const { instance } = useMsal();
  const authenticatedState = useSelector((state) => state.auth.isAuthenticated)
  const userData = useSelector((state) => state.auth.userDetails);
  console.log("userDataRedux", userData)
  const lastLogin = localStorage.getItem("lastLogin")
  const dispatch = useDispatch()
  let navigate = useNavigate();
  let isAzureLogin = localStorage.getItem("isAzureLogin")
  console.log("isAzureLogin", isAzureLogin)
  // const { logout } = useAuth(); 
  // const history = useHistory()
  const signOut = () => {
    console.log("storage", localStorage, sessionStorage)

    dispatch(setUserUnauthenticated())
    disableBackButtonAfterSignout()
    localStorage.removeItem("Logindata");
    localStorage.removeItem("SelectedMenuItem");
    localStorage.removeItem("UserName");
    localStorage.removeItem("userRole")
    localStorage.clear();
    navigate("/");
    // adding for sign out issue
    window.history.pushState(null, null, window.location.href); window.onpopstate = function () {
      window.history.go(1);
    };
    // ends here
    
  };

  const handleLogoutAzure = (logoutType) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
    localStorage.clear();
    dispatch(setUserUnauthenticated())
  }

  useEffect(() => {
    console.log("userheaderauthstate", authenticatedState,lastLogin)
  }, [authenticatedState])

  const MoveToMyProfile = () => {
    navigate('/userprofile')
  }

  const MoveToMySettings = () => {
    navigate('/usersettings')
  }

  const menu = (
    <Menu>
      <Menu.Item className="text-center">
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={MoveToMyProfile}
        >
          My Profile
        </a>
      </Menu.Item>
      {/* <Menu.Item className="text-center">
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={MoveToMySettings}
        >
          Settings
        </a>
      </Menu.Item> */}

      <Menu.Item danger
        // onClick={signOut}
        // onClick={() => handleLogoutAzure("popup")}
        onClick={isAzureLogin ? () => handleLogoutAzure("popup") : authenticatedState ? signOut : signOut}
        // onClick={() => handleLogoutAzure("redirect")}     
        className="text-center">
        Sign Out
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <div className=" navbarmain d-flex justify-content-center ">
        <div className="col-md-6 d-flex justify-content-start headerName">
          {heading}
        </div>
        <div className="col-md-6 d-flex justify-content-end ">
          <Dropdown overlay={menu}>
            <text
              className="ant-dropdown-link text-profile-red"
              onClick={(e) => e.preventDefault()}
            >
              {userData.name}
              {/* {localStorage.getItem("Logindata")}{" "} */}
              {/* <DownOutlined className="nav-text " /> */}
              <img src={profileicon} className="profileicon" />
              <br />
              <div
                className="ant-dropdown-link text-profile"
              >
                                {lastLogin == null ? "" : lastLogin == "Invalid Date" ? "" : lastLogin == 'null' ? "" : `Last login: ${lastLogin}`}
              </div>
            </text>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default UserHeader;
