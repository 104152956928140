

import React, { useState } from "react";
import alba_logo from "../../../Assets/Images/alba_logo.png";
import location from "../../../Assets/Images/location.png";
import call from "../../../Assets/Images/call.png";
import mail from "../../../Assets/Images/mail.png";
import building from "../../../Assets/Images/building.png";

const Footer = () => {
    const currentYear = new Date().getFullYear()
    return (
        <footer className="footer mt-auto py-3 text-white">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <img
                            src={alba_logo}
                            alt="Logo"
                            className="img-fluid"
                            style={{ maxWidth: "93px", maxHeight: "52px" }}
                        />
                    </div>
                    <div className="col-3 d-flex align-items-center">
                        <img
                            src={location}
                            alt="Logo"
                            className="img-fluid"
                            style={{ maxWidth: "54px", maxHeight: "54px" }}
                        />
                        <div className="p-1 text-container">
                            <p>ALUMINIUM BAHRAIN B.S.C</p><br />
                            <p>P.O BOX 570</p><br />
                            <p>Kingdom of Bahrain</p>
                        </div>
                    </div>
                    <div className="col d-flex align-items-center">
                        <img
                            src={call}
                            alt="Logo"
                            className="img-fluid"
                            style={{ maxWidth: "24px", maxHeight: "25px" }}
                        />
                        <div className="px-2 text-container">
                            <p>(+973) 1783 0000</p>
                        </div>
                    </div>
                    <div className="col d-flex align-items-center">
                        <img
                            src={mail}
                            alt="Logo"
                            className="img-fluid"
                            style={{ maxWidth: "24px", maxHeight: "25px" }}
                        />
                        <div className="px-2 text-container">
                            <p>info@alba.com.bh</p>
                        </div>
                    </div>
                    <div className="col d-flex align-items-center">
                        <img
                            src={building}
                            alt="Logo"
                            className="img-fluid"
                            style={{ maxWidth: "24px", maxHeight: "25px" }}
                        />
                        <div className="px-2 text-container">
                            <p>(+973) 1783 0083</p>
                        </div>
                    </div>
                </div>
                <hr style={{ border: "1px solid #9B9B9B" }} />
                <div className="row">
                    <div className="col text-center">
                        <span>&copy; {currentYear} Aluminium Bahrain B.S.C. (Alba), all rights reserved.</span>
                        <div className="mt-1">
                            <a href="https://www.albasmelter.com/en/category/terms-of-use">Terms of Use</a>
                            <a style={{ marginLeft: "15px" }} href="https://www.albasmelter.com/en/category/privacy-notice">Privacy Notice</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
