import React, { useEffect, useState, useCallback, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import { getDocumentKeys, getParameterRules, getAllParameterRules, setDocumentRules, getKeyRulesDataforDocID, addNewKeysForRules, editPreviousKeyRules, deleteKeyRules } from "../../../../Services/AdminServices";
import AppliedRulesList from "./AppliedRulesList";
import { Modal } from "antd";
import Loader from "../../../../utils/Loader/Loader";
import ErrorCatch from "../../../Reusable_Components/ErrorCatch";

const SetRulesForKeys = ({ selectedRow, isSetRuleModalOpen, gridData }) => {
  const [documentKeysList, setDocumentKeysList] = useState([])
  const [parameterRulesList, setParameterRulesList] = useState([])

  const [newRuleModal, setNewRuleModal] = useState(false)
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error,setError] = useState()
  const [rowData, setRowData] = useState([])
  const [isEditable, setIsEditable] = useState(false)
  const [keyRulesList, setKeyRulesList] = useState([])
  const [paramRulesList, setParamRulesList] = useState([])
  var isEnabled = []
  var creatableIsDisabled = []

  const keyRuleData = {
    docdetails_id: 27,
    keyrulepayload: [{ "paramvalue_dockey": "1234", "_parameter_id": "1", "_documetkeys_id": "9" }, { "paramvalue_dockey": "2234", "_parameter_id": "2", "_documetkeys_id": "10" }]
  }

  const getAppliedKeyRules = async () => {
    setLoading(true)
    // if (keyRuleData.length > 0) {
    //   setRowData(keyRuleData.keyrulepayload)
    // }
    console.log("selectedRow", selectedRow)

    try {
      let response = await getKeyRulesDataforDocID(selectedRow._rule_id)
      console.log("getKeyRulesDataforDocID", response)
      let keyRulesDataforDocID = []
      if (response.status == 200) {
        keyRulesDataforDocID = response.data.data
        // keyRulesDataforDocID = [{...response.data.data, "documentKeys": documentKeysList, "parameterRules": parameterRulesList }]
        let finalArr = []
        keyRulesDataforDocID.map((obj) => {
          obj["documentKeys"] = documentKeysList
          obj["parameterRules"] = parameterRulesList
          obj.isEnabled = true
          finalArr.push(obj)
        })

        finalArr.forEach((obj, i) => {
          obj.id = i++
        })
        // setRowData(keyRulesDataforDocID)
        console.log("keyRulesDataforDocID", keyRulesDataforDocID, finalArr)
        // setRowData([...finalArr,{}])
        setRowData(finalArr)

        let keyListArr = []
        documentKeysList.map((obj) => {
          keyListArr.push({
            "label": obj._key,
            "value": obj._documetkeys_id
          })
        })
        console.log("keyListArr", keyListArr)
        setKeyRulesList(keyListArr)

        let paramListArr = []
        parameterRulesList.map((obj) => {
          paramListArr.push({
            "label": obj._paramname,
            "value": obj._parameter_id
          })
        })
        setParamRulesList(paramListArr)

      }
    }
    catch (err) {
      setError(err)
    }
    setLoading(false)
  }

  const [docType, setDocType] = useState({
    name: "Keys",
    value: ""
  })

  const [formatType, setFormatType] = useState({
    name: "Param Rule",
    value: ""
  })

  const getDocumentKeysList = async () => {
    setLoading(true)
    try {
      const response = await getDocumentKeys()
      console.log("getDocumentKeys", response);

      if (response.status !== 200) {
        toast.error(response.message)
        setLoading(false)
        return
      }
      let res = response.resData
      setDocumentKeysList(res.data.data);
    } catch (error) {
      console.log(error);
      setError(error)
    }
    setLoading(false)
  }

  const getParameterRulesList = async () => {
    setLoading(true)
    try {
      // const response = await getParameterRules()
      const response = await getAllParameterRules()
      console.log("getParameterRules", response);
      let res = response.data.data
      let finalArr = []
      res.map((obj) => {
        if (obj._paramtype == "keys wise") {
          finalArr.push(obj)
        }
      })
      console.log("finalArr", finalArr)
      setParameterRulesList(finalArr);
    } catch (error) {
      console.log(error);
      setError(error)
    }
    setLoading(false)
  }


  const [IDNo, setIDNo] = useState()
  const [docKeys, setDocKeys] = useState("")
  const [paramRule, setParamRule] = useState("")


  const handleDocumentKeys = (e) => {
    const { name, value } = e.target;
    setRulesValue({ ...rulesvalue, [e.target.id]: e.target.value })

    // setInputData({ ...inputData, [name]: value });
    setDocKeys(value)
  };

  const [newKeys, setNewKeys] = useState()
  const handleNewKeys = (e) => {
    const { name, value } = e.target;
    setRulesValue({ ...rulesvalue, [e.target.id]: e.target.value })

    // setInputData({ ...inputData, [name]: value });
    setNewKeys(value)
  }

  const [newParamNames, setNewParamNames] = useState()
  const handleNewParamNames = (e) => {
    const { name, value } = e.target;
    setRulesValue({ ...rulesvalue, [e.target.id]: e.target.value })

    // setInputData({ ...inputData, [name]: value });
    setNewParamNames(value)
  }

  const [newValueNo, setNewValueNo] = useState()
  const handleNewValueNo = (e) => {
    const { name, value } = e.target;
    let val = e.target.value.replace(/[A-Za-z' `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/g, '');
    // setInputData({ ...inputData, [name]: val });
    setNewValueNo(val)
    setRulesValue({ ...rulesvalue, [e.target.id]: e.target.value })
  };

  const handleParameterRules = (e) => {
    const { name, value } = e.target;
    setRulesValue({ ...rulesvalue, [e.target.id]: e.target.value })

    // setInputData({ ...inputData, [name]: value });
    setParamRule(value)
  };

  const handleIDNo = (e) => {
    const { name, value } = e.target;
    let val = e.target.value.replace(/[A-Za-z' `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/g, '');
    // setInputData({ ...inputData, [name]: val });
    setIDNo(val)
    setRulesValue({ ...rulesvalue, [e.target.id]: e.target.value })
  };

  useEffect(() => {
    getDocumentKeysList()
    getParameterRulesList()

    setDocType({
      name: "Keys",
      value: ""
    })
    setFormatType({
      name: "Param Rule",
      value: ""
    })
    setDocKeys("")
    setParamRule("")
    setIDNo()
  }, [isSetRuleModalOpen])

  useEffect(() => {
    if (documentKeysList.length > 0 && parameterRulesList.length > 0) {
      getAppliedKeyRules()
    }
  }, [parameterRulesList, documentKeysList, selectedRow])

  //   useEffect(()=>{
  // if(isSetRuleModalOpen == false){
  //   setRowData([])
  // }    
  //   },[isSetRuleModalOpen])

  useEffect(() => {
    console.log("inputData", docKeys, paramRule, IDNo, rulesvalue)
  }, [IDNo, docKeys, paramRule])

  useEffect(() => {
    if ((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) {
      setSubmitBtnDisabled(false)
    }
    else {
      setSubmitBtnDisabled(true)
    }
  }, [newRuleModal, newValueNo, newKeys, newParamNames])

  const handleNewSubmit = async (e) => {
    setLoading(true)
    let sendData = {
      "_rule_id": selectedRow._rule_id,
      // "formType": "key rules",
      "keyrulepayload": [{
        "_paramvalue": newValueNo,
        "_parameter_id": newParamNames,
        "_documetkeys_id": newKeys,
      }]
    }

    try {
      // const response = await setDocumentRules(sendData)
      const response = await addNewKeysForRules(sendData)

      console.log("addNewKeysForRules", response)
      if (response.status == 201) {
        toast.success(response.data.msg);
      } else {
        toast.error("Something went wrong, Can you please try again");
      }
    }
    catch (error) {
      console.log("sapsingledataerror", error)
      // toast.error(error.response.data.data, {
      //   position: "top-right",
      //   autoClose: 5000,
      // });
      setError(error)
    }
    finally {
      setNewRuleModal(false)
      getAppliedKeyRules()
    }
    setLoading(false)
  }

  const onSubmitRequestApi = async (e) => {
    setLoading(true)
    // e.preventDefault();

    // let sendData = {
    //   paramvalue_dockey: IDNo,
    //   _parameter_id: paramRule,
    //   _document_id: docKeys,
    // }
    console.log("selectedRow", selectedRow)

    let sendData = {
      "docdetails_id": selectedRow._docdetails_id,
      "formType": "key rules",
      "keyrulepayload": [{
        "paramvalue_dockey": IDNo,
        "_parameter_id": paramRule,
        "_documetkeys_id": docKeys,
      }]
    }

    try {
      const response = await setDocumentRules(sendData)

      console.log("setDocumentRulesdata", response)
      if (response.status == 200) {
        toast.success("your request send successfully");
      } else {
        toast.error("Something went wrong, Can you please try again");
      }
    }
    catch (error) {
      console.log("sapsingledataerror", error)
      // toast.error(error.response.data.data, {
      //   position: "top-right",
      //   autoClose: 5000,
      // });
      setError(error)
    }
    setLoading(false)
  };


  const handleAddKeysSpec = (e) => {
    setNewRuleModal(true)
    setNewKeys("")
    setNewParamNames("")
    setNewValueNo("")
    // if (rulesvalue !== "") {
    //   setAddValue({ ...rulesvalue, [e.target.id]: e.target.value })
    // }
    // setRulesValue("")
  }

  const newRuleModalCancel = () => {
    setNewRuleModal(false);
  };

  const [masterData, setMasterData] = useState([])
  const [rulesvalue, setRulesValue] = useState(
    {
      "key": "",
      "paramkey": "",
      "docKeys": ""
    })

  const [addvalue, setAddValue] = useState()

  // -------------

  const [editClicked, setEditClicked] = useState()
  // useEffect(() => {
  //   isEnabled = isEnabled.map((obj, i) => i == index ? obj : false)
  // }, [editClicked])

  const updateKeyRules = async (param, followUpId) => {
    console.log("param_followupid", param, followUpId)
    isEnabled = rowData.map((obj, i) => {
      if (obj._ruleskeys_id == followUpId) {
        obj._keys = param.label
        obj["_documetkeys_id"] = param.value
      }
      return obj
    })

    let isEnabledToUpdate = isEnabled.filter((obj) => {
      if (obj._ruleskeys_id == followUpId) {
        return obj
      }
    })

    console.log("param_followupid", param, followUpId, isEnabled, isEnabledToUpdate)

    if (isEnabledToUpdate[0].isEnabled == false) {
      console.log("false", "false is present")
      setRowData(isEnabled)
      return
    }

  }


  const updateParamRules = async (param, followUpId) => {
    console.log("param_followupid", param, followUpId)
    isEnabled = rowData.map((obj, i) => {
      if (obj._ruleskeys_id == followUpId) {
        obj._paramname = param.label
        obj["_parameter_id"] = param.value
      }
      return obj
    })

    let isEnabledToUpdate = isEnabled.filter((obj) => {
      if (obj._ruleskeys_id == followUpId) {
        return obj
      }
    })

    console.log("param_followupid", param, followUpId, isEnabled, isEnabledToUpdate)

    if (isEnabledToUpdate[0].isEnabled == false) {
      console.log("false", "false is present")
      setRowData(isEnabled)
      return
    }

  }

  const resetValues = () => {
    setIsEditable(true)
  }

  const onInputChange = (param, followUpId) => {
    console.log("onInputChange", param, followUpId)
    isEnabled = rowData.map((obj, i) => {
      if (obj._ruleskeys_id == followUpId) {
        obj._paramvalue = Number(param)
      }
      return obj
    })
    setRowData(isEnabled)
  }

  const onEditClicked = (param, isEditable) => {
    console.log("onEditClickedparam", param, isEditable, rowData)
    if (isEditable) {
      getAppliedKeyRules()
      return
    }
    isEnabled = rowData.map((obj, i) => {
      if (obj._ruleskeys_id == param) {
        obj.isEnabled = false
      }
      else {
        obj.isEnabled = true
      }
      return obj
    })
    setRowData(isEnabled)
    console.log("onEditClicked", param, isEnabled)

  }

  const onDeleteClicked = async (param) => {
    console.log("ondelclickedparam", param)
    setLoading(true)
    try {
      let response = await deleteKeyRules(param)
      console.log("delresponse", response)
      if (response.status == 200) {
        // toast.success("your request send successfully");
        toast.success(response.data.msg)
      } else {
        toast.error("Something went wrong, Can you please try again");
      }
    }
    catch (error) {
      console.log("delkeyruleerror", error)
      // toast.error(error.response.data.data, {
      //   position: "top-right",
      //   autoClose: 5000,
      // });
      setError(error)
    }
    finally {
      getAppliedKeyRules()
    }
    setLoading(false)
  }

  const updateRecord = async (param) => {
    setLoading(true)
    console.log("updateRecord", param, rowData)
    let rowDataToUpdate = rowData.filter((obj) => {
      return obj._ruleskeys_id == param
    })
    rowDataToUpdate[0]._keys = rowDataToUpdate[0]._keys
    delete rowDataToUpdate[0].isEnabled

    console.log("rowDataToUpdate", rowDataToUpdate)
    let sendData = {
      // "docdetails_id": selectedRow._docdetails_id,
      // "docdetails_id": rowDataToUpdate[0]._docdetails_id,
      "formType": "key rules",
      "keyrulepayload": [{
        "_paramvalue": rowDataToUpdate[0]._paramvalue,
        "_parameter_id": rowDataToUpdate[0]._parameter_id,
        "_documetkeys_id": rowDataToUpdate[0]._documetkeys_id,
        "_ruleskeys_id": rowDataToUpdate[0]._ruleskeys_id
      }]
    }
    console.log("editPreviousKeyRulessendata", sendData)
    try {
      const response = await editPreviousKeyRules(sendData)

      console.log("editPreviousKeyRules", response)
      if (response.status == 201) {
        toast.success(response.data.msg);
      } else {
        toast.error("Something went wrong, Can you please try again");
      }
    }
    catch (error) {
      console.log("sapsingledataerror", error)
      // toast.error(error.response.data.data, {
      //   position: "top-right",
      //   autoClose: 5000,
      // });
      setError(error)
    }
    getAppliedKeyRules()
    setLoading(false)
  }


  return (
    <>
      <Loader loading={loading} />
      <ErrorCatch err={error}/>
      <div className="">
        <div className="row">
          <h6>
            <b>Set Key Specification </b>
          </h6>
        </div>
        <div className="row">
          <div className="col d-flex justify-content-end">
            <div style={{ marginRight: "10px" }}>
              <button
                onClick={(e) => handleAddKeysSpec(e)}
                class="btn btn-primary btn-sm"
              >
                Add Keys Spec
              </button>
            </div>
            {/* <div>
              <button
                onClick={(e) => handleAddRules(e)}
                class="btn btn-danger"
              >
                Delete
              </button>
            </div> */}
          </div>
          {/* <div className="row mt-4">
            <div className="col-sm-2">
              <select
                name="_documetkeys_id"
                onChange={(e) => handleDocumentKeys(e)}
                class="form-control" value={rulesvalue["key"]} id={"key"}
              >
                <option value={docType.value} disabled selected hidden>{docType.name}</option>
                {documentKeysList.map((val) => {
                  const { _documetkeys_id, _key } = val;
                  return <option value={_documetkeys_id} >{_key}</option>;
                })}
              </select>
            </div>

            <div className="col-sm-4">
              <select
                name="_parameter_id"
                onChange={(e) => handleParameterRules(e)}
                class="form-control" value={rulesvalue["paramkey"]} id={"paramkey"}
              >
                <option value={formatType.value} disabled selected hidden>{formatType.name}</option>
                {parameterRulesList.map((val) => {
                  const { _parameter_id, _paramname } = val;
                  return <option value={_parameter_id}>{_paramname}</option>;
                })}
              </select>
            </div>

            <div className="col-sm-3">
              <input
                type="text"
                name="_idno"
                className="form-control"
                id={"docKeys"}
                // value={rulesvalue["docKeys"]}
                onChange={(e) => handleIDNo(e)}
                class="form-control"
              // placeholder={docID == "1" ? "Cast ID No" : docID == "2" ? "Sales Order No" : ""}
              />
            </div>
          </div> */}

          <div className="row">
            <div className="col-sm-3">
              {rowData.length > 0 && "Keys"}
            </div>
            <div className="col-sm-3">
              {rowData.length > 0 && "Parameter"}
            </div>
            <div className="col-sm-3">
              {rowData.length > 0 && "Value"}
            </div>


            {rowData.length > 0 ?
              rowData.map((obj, inx) => (
                <AppliedRulesList
                  isEditable={!obj.isEnabled}
                  followUpId={obj._ruleskeys_id}
                  // ruleskeysid={obj["_ruleskeys_id"]}
                  onEditClicked={onEditClicked}
                  onDeleteClicked={onDeleteClicked}
                  onInputChange={onInputChange}
                  onSaveClicked={updateRecord}
                  keyRulesList={keyRulesList}
                  paramRulesList={paramRulesList}
                  ruleType={obj._keys}
                  paramType={obj._paramname}
                  paramValue={obj._paramvalue}
                  // optionType={optionType}
                  updateKeyRules={updateKeyRules}
                  updateParamRules={updateParamRules}
                  inx={inx}
                  creatableIsDisabled={false}
                  showSaveButton={!obj.isEnabled}
                  isEnabled={obj.isEnabled}
                  keyRulesListSet={setKeyRulesList}
                />
              )) :
              <div> No records to show </div>
            }

          </div>

          {/* <div className="mt-3">
            <div className="col d-flex justify-content-end" >
              <button
                // type="submit"
                onClick={(e) => onSubmitRequestApi(e)}
                class="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </div> */}

        </div>

      </div>
      <Modal
        className="p-2"
        open={newRuleModal}
        onCancel={newRuleModalCancel}
        footer={null}
      >
        <h6>Add New Keys</h6>
        <div className="row mt-2">
          <div className="col-sm-2 mt-2">
            <select
              style={{ height: "28px" }}
              // id={obj._ruleskeys_id}
              value={newKeys}
              onChange={(e) => handleNewKeys(e)}

            >
              <option value={""} disabled selected hidden>{"keys"}</option>
              {documentKeysList.map((val) => {
                const { _documetkeys_id, _key } = val;
                return <option value={_documetkeys_id}>{_key}</option>;
              })}
            </select>
          </div>

          <div className="col-sm-3 mt-2">
            <select
              style={{ height: "28px" }}
              // id={obj._ruleskeys_id}
              value={newParamNames}
              onChange={(e) => handleNewParamNames(e)}

            >
              <option value={""} disabled selected hidden>{"Parameters"}</option>
              {parameterRulesList.map((val) => {
                const { _parameter_id, _paramname } = val;
                return <option value={_parameter_id}>{_paramname}</option>;
              })}
            </select>
          </div>

          <div className="col-sm-3 mt-2">
            <input
              style={{ height: "28px" }}
              type="text"
              name="newValueNo"
              className="w-50"
              // id={"docKeys"}
              value={newValueNo}
              onChange={(e) => handleNewValueNo(e)}
              class="form-control"
              placeholder="value"

            />
          </div>

          <div className="col-sm-3 mt-2">
            <div style={{ marginRight: "10px" }}>
              <button
                // style={{ padding: "0 5px" }}
                onClick={(e) => handleNewSubmit(e)}
                class="btn btn-primary btn-sm"
                disabled={submitBtnDisabled}
              >
                Submit
              </button>
            </div>
          </div>

        </div>

      </Modal>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default SetRulesForKeys;
