import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { getRequestedData } from "../../../../Services/AdminServices";
import { LuView } from "react-icons/lu";
import { Modal } from "antd";
import { JsonViewer } from '@textea/json-viewer'

import approvereject from "../../../../Assets/Images/approverejeact.png"
import ChangeDocStatus from "../ChangeDocStatus";
import StyledDataGrid from "../../../Reusable_Components/StyledGrid";
import ErrorCatch from "../../../Reusable_Components/ErrorCatch";
const DocumentList = ({ allDocumentList }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rawData, setRawData] = useState()
  const [userActive, setUserActive] = useState();
  const [isApproveRejectModalOpen, setIsApproveRejectModalOpen] = useState(false)
  const [isModaldataRefresh, setIsModaldataRefresh] = useState(false);
  const [input, setInput] = useState({
    _reason: "",
    _spec_status_id: "",
  });


  const email = localStorage.getItem("Logindata");

  //Status update For Document
  const showModal = (p) => {
    setRawData(JSON.parse(p.row._docvalue))
    setIsModalOpen(true);
    // setUserActive(act.row);
    // setIsModaldataRefresh(true)
  };

  const handleOk = () => {
    setIsModalOpen(false);
    // requestedData()
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    // setIsModaldataRefresh(false)
  };

  const showModalAprroveReject = (act) => {
    console.log("Active", act.row);
    setInput({
      _reason: "", _spec_status_id: ""
    })
    setIsApproveRejectModalOpen(true);
    setUserActive(act.row);
    setIsModaldataRefresh(true)
  };
  const handleIsApproveRejectOk = () => {
    setIsApproveRejectModalOpen(false);
    // requestedData()
  };
  const handleIsApproveRejectApproveCancel = () => {
    setIsApproveRejectModalOpen(false);
    setIsModaldataRefresh(false)
  };


  // Table Data Start
  const TableData = [
    {
      field: "_docdetails_id",
      headerName: "ID",
      width: 80,

    },
    {

      field: "_docname",
      headerName: "Document Name",
      width: 160,
    },

    {

      field: "_format_type",
      headerName: "Document Format",
      width: 160,
    },

    {

      field: "_doc_id",
      // headerName: "CastID/Sales Order No.",
      headerName: "Parameter",
      width: 120,
    },
    {

      field: "_username",
      headerName: "User Name",
      width: 200,
    },
    {
      field: "_spec_status_name",
      headerName: "Status",
      width: 100,
    },
    {
      field: "_docvalue",
      headerName: "Raw Data",
      width: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <a class="btn" onClick={() => showModal(params)}>
              <LuView />
            </a>
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Approve/Reject",
      width: 80,
      renderCell: (params) => {
        return (
          <a className="text-center"
            onClick={() => showModalAprroveReject(params)}
          ><img src={approvereject} style={{ width: "1.5rem" }} /></a>
        );
      },
    }
  ];

  // Table Data end
  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";

    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    return retVal;
  }

  const requestedData = async () => {
    console.log("loginemail", email)
    try {
      let res = await getRequestedData(email)
      if (res.status == 200) {
        console.log("requesteddatares doc page", res)
        let resdata = res.data.data

        setData(resdata)

      }
      console.log("requesteddatares doc page", data)
    }
    catch (err) {
      setError(err)
    }
  }

  useEffect(() => {
    // requestedData()
  }, []);

  useEffect(() => {
    if (allDocumentList == true) {
      // requestedData()
    }

  }, [allDocumentList]);




  return (
    <>
      <ErrorCatch err={error} />
      <div className="container">
        <h6><b>Requested Document</b> </h6>
        {data.length > 0 ? (
          <div className="row mt-3">
            {/* <DataGrid
              className="datagrid"
              rows={data}
              columns={TableData}
              pageSize={9}
              handleChangeCheckbox
              onCellClick={(params) => params}
              getRowId={(data) => data._docdetails_id}
              checkboxSelection={false}
              hideFooter
            /> */}

            <StyledDataGrid
              className="datagrid"
              rows={data}
              columns={TableData}
              pageSize={9}
              handleChangeCheckbox
              onCellClick={(params) => params}
              getRowId={(data) => data._docdetails_id}
              checkboxSelection={false}
              hideFooter
              getRowClassName={(params) => `super-app-theme--${params.row._spec_status_name}`}
            />

          </div>
        ) :
          <div className="container">
            <div className="row justify-content-center">
              <div className="col d-flex justify-content-center"> Now Rows to show</div>
            </div>
          </div>
        }

        <div className="d-flex justify-content-center">

          <Modal
            className="p-2 w-25"
            open={isApproveRejectModalOpen}
            onOk={handleIsApproveRejectOk}
            onCancel={handleIsApproveRejectApproveCancel}
            footer={null}
          >
            <ChangeDocStatus isModalOpen={isApproveRejectModalOpen}
              input={input} setInput={setInput}
              data={userActive} handleOk={handleIsApproveRejectOk} dataRefresh={isModaldataRefresh} />
          </Modal>

          <Modal
            className="w-50"
            title="View Document"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <h6><b>ID:</b> <text>{rawData?.id}</text></h6>

            <hr />
            <div style={{ height: "22rem", overflowY: "auto" }}>
              <JsonViewer className="text-dark" value={rawData} />
            </div>

          </Modal>
        </div>


      </div>
    </>
  )
}

export default DocumentList;