import { JsonViewer } from '@textea/json-viewer'
import { useEffect, useState } from "react";
import { ViewRules } from '../../../../Services/AdminServices';
import ErrorCatch from '../../../Reusable_Components/ErrorCatch';


const ViewRule = (props) => {
    const [ViewrulesData, setViewRulesData] = useState([])
    const [error, setError] = useState()

    const ViewRulesData = async () => {
        // console.log("id mil gye 11111", props.ruleid)
        try {
            await ViewRules(props.ruleid).then((res) => {
                // console.log("Rules View", res.data)
                setViewRulesData(res.data)
            })
        }
        catch (err) {
            setError(err)
        }
    }

    useEffect(() => {
        ViewRulesData()
    }, [props.ruleid])



    const lineToHighlight = 5;

    // console.log("999", ViewrulesData)

    return (
        <>
            <ErrorCatch err={error} />
            <h6><b>ID:</b> <text>{props.ruleid}</text></h6>
            <hr />
            <div className="row">
                <h6>Rule Content</h6>
            </div>

            <div className='my-json-viewer' style={{ height: "22rem", overflowY: "auto" }}>
                <JsonViewer value={ViewrulesData} className={`highlight-line-${lineToHighlight}`} />
            </div>



        </>
    )
}
export default ViewRule;