import React, { useEffect, useState, useRef } from "react";
import CryptoJS from 'crypto-js';
import { DatePicker } from 'antd';
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import toast, { Toaster } from "react-hot-toast";
import { jsPDF } from 'jspdf';
import { JsonViewer } from "@textea/json-viewer";
import 'jspdf-autotable';
import axios from "axios";
import LOGO from '../../../Assets/Images/alba_logo.png'
import { BsDownload } from 'react-icons/bs';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { RiInformationLine } from 'react-icons/ri';
import { AiFillFile } from 'react-icons/ai';
import { BsFiletypeRaw } from "react-icons/bs";
import Draggable from 'react-draggable';
import { DraggableModal, DraggableModalProvider } from 'ant-design-draggable-modal'
// import 'antd/dist/antd.css'
import 'ant-design-draggable-modal/dist/index.css'
import { Input, Select } from 'antd';
import env from "../../../Services/Environment";
import { DataGrid } from "@mui/x-data-grid";
// import { makeStyles } from '@mui/styles';
import File from '../../../Assets/Images/tabler_file-filled.png'
import Box from '@mui/material/Box';
import { CreateSoDetails, applyRuleToDoc, applyRuleToDocAWS, deleteSoDetail, getActiveCustomer, getBundleHistory, getDocForAdmin, getDocumentName, getInvoiceHistory, getParamColDetails, getParameterDocSearch, getRulesForAdmin, getSODetails, getSoCustomerDetails, getSoNoforCompany, updateSoDetails, insertLogs, getUsersByCompId, emailDocs, sendFile } from "../../../Services/AdminServices";
import { Radio } from 'antd';
import { Modal } from 'antd';
import Loader from "../../../utils/Loader/Loader";
import { JsonToExcel } from "react-json-to-excel";
import * as XLSX from "xlsx";
import SelectInput from "../../Reusable_Components/SelectInput";
import InputBox from "../../Reusable_Components/InputBox";
import moment from "moment/moment";
import SelectDate from "../../Reusable_Components/DatePicker";
// import AppliedRulesHistoryPopup from "./AppliedRulesHistoryPopup";
import _ from "lodash";
import { messages } from "../../Messages";
import RadioButton from "../../Reusable_Components/RadioButton";
import Column from "antd/es/table/Column";
import BasicButton from "../../Reusable_Components/Button";
import { blue } from "@material-ui/core/colors";
import { FaEdit } from 'react-icons/fa';
import { BsPlus, BsFillTrashFill } from 'react-icons/bs';
import { IoMdCloseCircleOutline } from "react-icons/io";
import docIcon from "../../../Assets/Images/solar_document.png"
import AgGridComponent from "../../Reusable_Components/AgGridComponent";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
import TabsAntd from "../../Reusable_Components/TabsAntd";
import JsonComp from "./JsonComp";
import RawDataTable from "./RawDataTable";
import CheckboxComp from "../../Reusable_Components/CheckBox";
import plus from "../../../Assets/Images/arrow_closed.svg";
import minus from "../../../Assets/Images/arrow_open.svg";
import { useSelector } from "react-redux";
import PdfPreviewer from "../../Reusable_Components/PdfPreviewer";
import CsvPreviewer from "../../Reusable_Components/CsvPreviewer";
import TextPreviewer from "../../Reusable_Components/TextPreviewer";
import ExcelPreviewer from "../../Reusable_Components/ExcelPreviewer";
import FilePreviewer from "../../Reusable_Components/FilePreviewer";
import MultiSelectAntd from "../../Reusable_Components/MultiSelectAntd";

const AdminDoc = ({ isAdminDoc }) => {
    const gridRef = useRef();
    const [customerList, setCustomerList] = useState([])
    const [customerName, setCustomername] = useState("")
    const [documentname, setDocumentName] = useState([])
    const [docParam, setDocParam] = useState([])
    const [docperaval, setDocperaVal] = useState("")
    const [docData, setDocData] = useState([])

    const [docResponse, setDocResponse] = useState([])
    const [ruleHide, setRuleHide] = useState(false);

    const [docID, setDocID] = useState(1)
    const [docTypeID, setDocTypeID] = useState(docID)
    const [columns, setColumns] = useState([]);
    const [ruleFormat, setRuleFormat] = useState([])
    const [selection, setSelection] = useState([])
    const [radioSelection, setRadioSelection] = useState(3)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRawDataModal, setIsRawDataModal] = useState(false);
    const [headerName, setHeaderName] = useState([]);
    const [convertData, setConvertData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [dataForExcel, setDataForExcel] = useState([])
    const [appliedRuleData, setAppliedRuleData] = useState([])
    const [afterConverstionData, setAfterConverstionData] = useState([])
    const [ruleType, setRuleType] = useState({
        name: "Select a Rule Type",
        value: ""
    })

    // const [ruleType, setRuleType] = useState("")
    const [ruleType1, setRuleType1] = useState(ruleFormat[0]);
    const [docVal, setDocVal] = useState({
        doc_val: ""
    })
    const [docType, setDocType] = useState({
        name: "Doc Type",
        value: ""
    })

    const [paramType, setParamType] = useState({
        name: "Doc parameter",
        value: ""
    })

    const today = dayjs();
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [checkDate, setCheckDate] = useState("")

    // const [formattedFromDate, setFormattedFromDate] = useState("31-7-2023")
    // const [formattedToDate, setFormattedToDate] = useState("31-7-2023")

    const [formattedFromDate, setFormattedFromDate] = useState("")
    const [formattedToDate, setFormattedToDate] = useState("")

    const [isSixMonthDate, setIsSixMonthDate] = useState(true)

    const [selectedValue, setSelectedValue] = useState(2);
    const [statusList, setStatusList] = useState([
        {
            label: "PDF",
            value: 1,
            status: false
        },
        {
            label: "CSV",
            value: 2,
            status: true
        },
        {
            label: "Excel",
            value: 3,
            status: false
        },
        {
            label: "Text",
            value: 4,
            status: false
        }
    ])

    // sales order popup
    const [soModalOpen, setSoModalOpen] = useState(false);
    const [soList, setSoList] = useState([])
    const [salesOrderNo, setSalesOrderNo] = useState("")
    const [parameterName, setParameterName] = useState("")
    const [paramValue, setParamValue] = useState("")
    const [isUpdateButton, setIsUpdateButton] = useState(false)
    const [SORowData, setSORowData] = useState([])

    const [companyId, setCompanyId] = useState()
    const [rowId, setRowId] = useState()
    const [customerData, setCustomerData] = useState([])

    const [rawData, setRawData] = useState([])
    const [parameterColsData, setParameterColsData] = useState([])
    const [isRawDataTable, setIsRawDataTable] = useState(false)
    const [isJsonComp, setIsJsonComp] = useState(false)
    const [isFilter, setIsFilter] = useState(true)

    const [soNo, setSoNo] = useState('')
    const [deliveryNo, setDeliveryNo] = useState('')

    const [soData, setSoData] = useState([])
    const [isSoSelect, setIsSoSelect] = useState(null)
    const [soRowData, setSoRowData] = useState([])

    const [selectedSo, setSelectedSo] = useState([])


    const [isSendEmail, setIsSendEmail] = useState(false);
    const [pdfUrl, setPdfUrl] = useState("")
    // uam role object start
    const userDetails = useSelector((state) => state)
    const appsubmodulename = "Services"
    const appfunctionalityname = "Document"
    const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
    const userDetailsLog = userDetails.auth.userDetails
    const [appObjects, setAppObjects] = useState(
        {
            "Submit": false,
            "Apply Rule": false,
            "Download": false,
            "PDF": false,
            "CSV": false,
            "Excel": false,
            "TXT": false,
            "Select rule": false,
            "Send Email": true
        }
    )

    const [roleType, setRoleType] = useState(localStorage.getItem('userRole')) //super-user
    const [defaultRule, setDefaultRule] = useState()
    const [companyDetails, setCompanyDetails] = useState({
        "comp_name": "",
        "comp_id": 0
    })

    const [mainCompanyUsers, setMainCompanyUsers] = useState([])
    const [otherUsers, setOtherUsers] = useState("")
    const [subject, setSubject] = useState("")
    const [otherUsersCC, setOtherUsersCC] = useState("")
    const [otherUsersBCC, setOtherUsersBCC] = useState("")
    const [emailList, setEmailList] = useState([])
    const [docName, setDocName] = useState("")
    const [fileBlob, setFileBlob] = useState()

    console.log("userDetails", userDetails)

    const updateAppObjects = (arr) => {
        let objectsArray = []
        arr.map((obj) => {
            console.log("updateButtons", obj)
            // if (obj.objecttypename == "button") {
            objectsArray.push(obj)
            // }
        })
        const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
            acc[appobjectname] = true;
            return acc;
        }, {});
        setAppObjects({ ...appObjects, ...finalObject })
        console.log("objectsArray", objectsArray)
        console.log("finalObject", finalObject)
    }

    useEffect(() => {
        console.log("appObjects", appObjects)
    }, [appObjects])

    useEffect(() => {
        console.log("userRoleObjects", userRoleObjects)
        const filteredList = []
        userRoleObjects.filter((obj) => {
            if (obj.appsubmodulename == appsubmodulename && obj.appfunctionalityname == appfunctionalityname) {
                filteredList.push(obj)
            }
        })

        console.log("filteredList", filteredList)
        updateAppObjects(filteredList)

    }, [])

    // uam role object end

    const handleSixMonthDate = (e) => {
        let value = e.target.checked
        setIsSixMonthDate(value)
        if (value == false) {
            setFormattedFromDate("2016-01-01")
        }
        else if (value == true) {
            defaultDate()
        }
    }


    useEffect(() => {
        console.log("parameterColsData", parameterColsData)
    }, [parameterColsData])

    useEffect(() => {
        getParameterCols()
    }, [docID])

    useEffect(() => {
        console.log("appliedRuleData", appliedRuleData)
    }, [appliedRuleData])

    // AppliedRulesHistoryPopup start

    const [isAppliedRulesHistoryOpen, setIsAppliedRulesHistoryOpen] = useState(false)
    const [historyData, setHistoryData] = useState([])

    const fileNameGeneration = (arr) => {
        console.log("selectionFileGen", arr)
        let docName = documentname.filter((obj) => {
            let nm
            if (obj.value == docTypeID) {
                return obj.name
            }
        })[0].name
        console.log("docName", docName)
        let fileName
        if (arr.length == 1) {
            if ("col1" in arr[0]) {
                // fileName = "COA_" + arr[0].castid
                // fileName = "COA_" + arr[0].bundleno
                fileName = `${docName}_` + arr[0].col1
            }
        }
        else if (arr.length > 1) {
            // if ("castid" in arr[0]) {
            //     fileName = "COA_" + dayjs().format("DD-MM-YYYY")
            // }
            // if ("col1" in arr[0]) {
            //     fileName = "Doc_" + dayjs().format("DD-MM-YYYY")
            // }
            fileName = `${docName}_` + dayjs().format("DD-MM-YYYY")
        }
        console.log("fileName", fileName)
        setDocName(fileName)
        return fileName
    }

    const handleRadioButton = (event) => {
        console.log("handleradio", event.target)
        let value = event.target.value
        console.log("item", statusList, value)

        let updatedStatusList = statusList.map((item) => {
            if (item.value == value) {
                item.status = true
            }
            else {
                item.status = false
            }
            return item
        })
        console.log("updatedStatusList", updatedStatusList)
        setSelectedValue(value)
        setStatusList(updatedStatusList)
    }

    const showAppliedRulesHistoryPopup = async (params) => {
        console.log("showAppliedRulesHistoryPopupparams", params, docTypeID)
        setLoading(true)
        let sendData

        if (docID == 1) {
            sendData = {
                castid: params.data.col2,
                bundleno: params.data.col1
            }
            try {
                let response = await getBundleHistory(sendData)
                console.log("response", response)
                if (response.status == 200) {
                    setIsAppliedRulesHistoryOpen(true)
                    setHistoryData(response.data.data)
                }
                else {
                    toast.error(messages.noDataFound)
                }
            }
            catch (error) {
                // toast.error(error.message)
                setError(error)

            }
        }
        else if (docID == 2) {
            sendData = {
                invoiceno: params.data.col1
            }
            try {
                let response = await getInvoiceHistory(sendData)
                console.log("response", response)
                if (response.status == 200) {
                    setIsAppliedRulesHistoryOpen(true)
                    setHistoryData(response.data.data)
                }
                else {
                    toast.error(messages.noDataFound)
                }
            }
            catch (error) {
                // toast.error(error.message)
                setError(error)
            }
        }
        else {
            console.log("documid", params.data.col1)
            toast.error(messages.noDataFound)
        }

        console.log("sendData", sendData)
        setLoading(false)
    }

    const onAppliedRulesHistoryCancel = () => {
        setIsAppliedRulesHistoryOpen(false)
    }
    // AppliedRulesHistoryPopup end

    const columnFunctions = (params) => {
        console.log("params_", params)
        if ("invoiceno" in params.data) {
            toast.error(messages.noDataFound)
            return
        }
        if (params.colDef.field == "File") {
            showModal(params)
        }
        else if (params.colDef.field == "Raw data") {
            showJsonRawData(params)
        }
        else if (params.colDef.field == "History") {
            showAppliedRulesHistoryPopup(params)
        }
    }

    const processForRawData = async (documentData, colsData) => {
        setLoading(true)
        console.log("processForRawData", documentData);
        console.log("colsData", colsData);
        let paramData = documentData
        let soNo = documentData[0].col19
        let finalArr = []
        paramData.map(item => {
            delete item.col20
            finalArr.push(item)
        })
        console.log("finalArr", finalArr);
        let generalColumn = generalisedColumn(finalArr, colsData)
        console.log("generalColumn", generalColumn);
        let customerSoDetails = await getSoCustomerData(soNo)
        let headerData = []
        let keyData = []
        generalColumn.map((item) => {
            if (item.type == "document_wise") {
                let header = {
                    key: item.key,
                    value: item.value
                }
                headerData.push(header)
            } else {
                let key = {
                    key: item.key,
                    value: item.value
                }
                keyData.push(key)
            }
        })
        let customerData = []
        console.log("customerSoDetails", customerSoDetails)
        if (customerSoDetails.length > 0) {
            customerData = customerSoDetails
        }
        let rawDataDetails = {
            header_keys: headerData,
            document_keys: keyData,
            customer_Data: customerData
        }
        console.log("rawDataDetails", rawDataDetails)
        setRawData(rawDataDetails)
        setLoading(false)
    }

    const handleCheckBox = (newSelectionModel) => {
        console.log("newSelectionModel", newSelectionModel, docData);
        let finalArr = []
        docData.map(item => {
            newSelectionModel.filter(obj => {
                // if (obj == item.castid) {
                //     finalArr.push(item)
                // }
                // if (docID == 1) {
                //     if (obj == item.col1) {
                //         finalArr.push(item)
                //     }
                // }
                if (obj == item.col1) {
                    finalArr.push(item)
                }
            })
        })
        console.log("newSelectionModel_finalarr", finalArr)
        // if(finalArr.length == 0){
        //     setRuleType()
        // }
        setSelection(finalArr)
        // setSelectionModel(newSelectionModel);
    };


    useEffect(() => {
        if (selection.length !== 0 && docData.length !== 0) {
            console.log("docData__", docData)
            let finalArr = []
            docData.map(item => {
                selection.filter(obj => {
                    if (obj.col1 == item.col1) {
                        finalArr.push(item)
                    }
                    // if (obj.castid == item.castid) {
                    //     finalArr.push(item)
                    // }
                    // if (docID == 1) {
                    //     if (obj.col1 == item.col1) {
                    //         finalArr.push(item)
                    //     }
                    // }
                    // if (docID == 1) {
                    //     if (obj.bundleno == item.bundleno) {
                    //         finalArr.push(item)
                    //     }
                    // }
                    // else if (docID == 2) {
                    //     if (obj.invoiceno == item.invoiceno) {
                    //         finalArr.push(item)
                    //     }
                    // }
                })
            })
            console.log("newSelectionModel_finalarr", finalArr)
            setSelection(finalArr)
        }
    }, [docData]);

    useEffect(() => {
        setDocData([])
        setSoData([])
        setRuleHide(false)
        setCustomerData([])
        setSelection([])
        setSelectedSo([])
    }, [docID, docperaval, docVal, fromDate, toDate]);

    const handleCellSelection = (value) => {
        console.log("handleCellSelectionValue", value, selection)
        // if (undefined !== selection) {
        if (Object.keys(selection).length > 0 && selection.constructor === Object) {
            if (value.row.castid == selection.castid) {
                setSelection({})
            }
        }
        else {
            setSelection(value.row)
        }
        // setSelection(value.row)
    }

    useEffect(() => {
        if (isAdminDoc == true) {
            console.log("ruleformatnewdoc", ruleFormat)
            // getRuleType()
            defaultDate()
            setRuleHide(false)
            // setRuleType
            setDocID(1)
            setDocTypeID(1)
            setDocperaVal("")
            setDocVal({ doc_val: "" })
            setSoNo('')
            setDeliveryNo('')
            setSoData([])
            setCustomername('')
            setSelectedSo([])
            // setFromDate("")
            // setToDate("")
            // setFormattedFromDate("31-7-2023")
            // setFormattedToDate("31-7-2023")

            setCheckDate("")
            setRuleType("")
            setDocData([])
            setCustomerData([])
            setSelection([])
            setSelectedValue(2)
            setStatusList([
                {
                    label: "PDF",
                    value: 1,
                    status: false
                },
                {
                    label: "CSV",
                    value: 2,
                    status: true
                },
                {
                    label: "Excel",
                    value: 3,
                    status: false
                },
                {
                    label: "txt",
                    value: 4,
                    status: false
                }
            ])
            setIsSendMailBtn(true)
            // setRuleType({
            //     name: "Select a Rule Type",
            //     value: ""
            // })

        }
    }, [isAdminDoc])


    useEffect(() => {
        console.log("selection", selection)
    }, [selection])

    useEffect(() => {
        defaultDate()
        setCustomername("")
        setSoData([])
        setSelectedSo([])
        setRuleHide(false)
        // setFromDate("")
        // setToDate("")
        // setFormattedFromDate("31-7-2023")
        // setFormattedToDate("31-7-2023")
        setIsSendMailBtn(true)
        setRadioSelection(3)
        setSelectedValue(2)
        setStatusList([
            {
                label: "PDF",
                value: 1,
                status: false
            },
            {
                label: "CSV",
                value: 2,
                status: true
            },
            {
                label: "Excel",
                value: 3,
                status: false
            },
            {
                label: "Text",
                value: 4,
                status: false
            }
        ])
    }, [])

    const showModal = async (params) => {
        console.log("params", params, convertData)

        // if(convertData.length == 0 || convertData == null || undefined == convertData){
        //     toast.error("No data to show")
        //     return
        // }
        let getDocDetails = await SubmitDocDetails(1)
        console.log("docData=>", docResponse, getDocDetails)

        let fullData = getDocDetails.fullData
        // let filterArr = fullData.filter(item => item.castid == params.id)
        // let filterArr = fullData.filter(item => item.bundleno == params.id)
        let filterArr = fullData.filter(item => item.col1 == params.data.col1)
        console.log("ModalfilterArr", filterArr)
        filterArr = filterArr[0]
        // if ("conversion_data" in filterArr) {
        //     // setConvertData(JSON.parse(filterArr.conversion_data))
        //     filterArr = JSON.parse(filterArr.conversion_data)
        //     console.log("filterArr", filterArr)
        //     setConvertData(filterArr)
        // } 
        if ("col20" in filterArr) {
            // setConvertData(JSON.parse(filterArr.col20))
            filterArr = JSON.parse(filterArr.col20)
            console.log("filterArr", filterArr)
            if (filterArr !== null) {
                setConvertData(filterArr)
            } else {
                setConvertData([])
            }
        }

        else {
            setConvertData([])
        }

        setIsModalOpen(true);
    };

    const showJsonRawData = async (params) => {
        console.log("showJsonRawDataParams", params)
        console.log("parameterColsData", parameterColsData)
        let rowDetails = [params.data]
        // let colData = parameterColsData.length > 0 ? parameterColsData : await getParameterCols()
        // console.log("colData", colData)
        processForRawData(rowDetails, parameterColsData)
        // setIsRawDataModal(true)
    }

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleRawDataModalOk = () => {
        setIsRawDataModal(false);
    };

    const handleRawDataModalCancel = () => {
        setIsRawDataModal(false);
    };

    let actionColumns = [

        {
            field: "file",
            headerName: "File",
            // width: "150px",
            flex: 0.2,
            renderCell: (params) => {
                console.log("convewrtateddata ", params)
                return (
                    <div className="cellAction">
                        <a class="btn" onClick={() => showModal(params.data)}>
                            <img src={File} alt="fileicon" style={{ width: '0.8rem' }} />
                        </a>
                    </div>
                );
            }
        },

        // {
        //     field: "history",
        //     // find history here
        //     headerName: "History",
        //     // width: "150px",
        //     flex: 0.2,
        //     renderCell: (params) => {
        //         console.log("convewrtateddata ", params)
        //         return (
        //             <div className="cellAction">
        //                 <a class="btn" onClick={() => showModal(params.row)}>
        //                     <img src={File} alt="fileicon" style={{ width: '0.8rem' }} />
        //                 </a>
        //             </div>
        //         );
        //     }
        // }
    ]

    const subtractMonth = () => {
        let sixMonthsBefore = new Date()
        sixMonthsBefore.setMonth(sixMonthsBefore.getMonth() - 6)
        let day = sixMonthsBefore.getDate().toString().padStart(2, '0')
        let month = (sixMonthsBefore.getMonth() + 1).toString().padStart(2, '0')
        let year = sixMonthsBefore.getFullYear()
        let defaultToDate = `${year}-${month}-${day}`
        setFormattedFromDate(defaultToDate)
        setFromDate(defaultToDate)
        console.log("subtractMonth", sixMonthsBefore, defaultToDate)
        // return sixMonthsBefore
    }

    const defaultDate = () => {
        let todaysDate = new Date()
        let day = todaysDate.getDate().toString().padStart(2, '0')
        let month = (todaysDate.getMonth() + 1).toString().padStart(2, '0')
        let year = todaysDate.getFullYear()
        let defaultFromDate = `${year}-${month}-${day}`
        console.log("defaultDate", todaysDate, defaultFromDate)
        setFormattedToDate(defaultFromDate)
        setToDate(defaultFromDate)
        subtractMonth()
        // return finalDate
    }

    const handleDateChange = (dates, datestring) => {
        // console.log("dates", dates, dates.$d.toLocaleDateString(), fromDate)
        console.log("dates", dates, dates.$d, fromDate)
        console.log("thedate", dates.target.value)
        // let date1 = dates.$d.toDateString()
        // let date1 = dates.$d.toLocaleDateString()
        let day, month, year
        day = dates.$D
        month = dates.$M + 1
        year = dates.$y
        // let formattedDate = day + "-" + month + "-" + year
        // let formattedDate = `${day}-${month}-${year}`
        let formattedDate = dates.target.value
        console.log("formattedDate", formattedDate, typeof (formattedDate))
        setFormattedFromDate(formattedDate)
        setFromDate(formattedDate)
        let fDate = new Date(formattedDate)
        let tDate = new Date(formattedToDate)
        console.log("fdatetdate", fDate, tDate)
        if (fDate >= tDate) {
            console.log("thefDate", fDate)
            setFormattedToDate(formattedDate)
            setToDate(formattedDate)
        }

        // setFormattedToDate(formattedDate)

        // setCheckDate(dates.$d)
        // let theDate = moment(date1).format("DD-MM-YYYY")
        // console.log("thedate", theDate)

        // const newDate = new Date(dates.$d)
        // const newDate = new Date(dates.$x)

        // const date = newDate.getDate();
        // const month = newDate.getMonth() + 1;
        // const year = newDate.getFullYear();
        // setFromDate(`${date}-${month}-${year}`)
        // setFromDate(moment(datestring))
        // setFromDate(theDate)
        // setFromDate(formattedDate)
        // setFromDate(dates)
        // setToDate(dates)

        // setToDate(formattedDate)
    };
    const DesableDate = (current) => {
        return current && current.valueOf() < checkDate
    }


    const handleToDateChange = (dates, datestring) => {
        console.log("dates", dates)
        // let theDate = moment(dates.$d).format("DD-MM-YYYY")
        // console.log("thedate", theDate)
        // const todates = new Date(dates.$d)
        // const date = todates.getDate();
        // const month = todates.getMonth() + 1;
        // const year = todates.getFullYear();
        // console.log("date", date+"/"+month+"/"+year)
        // setToDate(`${date}-${month}-${year}`)
        let day, month, year
        day = dates.$D
        month = dates.$M + 1
        year = dates.$y
        // let formattedDate = `${day}-${month}-${year}`
        let formattedDate = dates.target.value
        setFormattedToDate(formattedDate)
        // setToDate(dates)
        setToDate(formattedDate)


        // if (new Date(fromDate) > new Date(toDate)) {
        //     setToDate("")
        // }

    };


    // const convertData = convertData.find(item=>item.key === 'conversion_data')?.value;


    //Document Name
    const handleDocumentType = (e) => {
        const { name, value } = e.target;
        console.log()
        setDocID(value)
    }
    console.log("log", docID)
    //Document perameter value
    const handleParameter = (e) => {
        const { name, value } = e.target;
        setDocperaVal(value)
        console.log("log1", docperaval)
    }
    console.log("log1", docperaval)
    //Doc Value
    const Inputhandlechange = (e) => {
        const { name, value } = e.target;
        console.log("value", name, value)
        if (name == 'sales_order_no') {
            setSoNo(value)
            setDeliveryNo('')
        } else if (name == 'delivery_no') {
            setDeliveryNo(value)
            setSoNo('')
        }
        // setDocVal({ ...docVal, [name]: value })
    }
    //Rule Type
    const handleRuleType = (e) => {
        console.log("handleruletypeevent", e.target)
        const { name, value } = e.target;
        // setInputData({ ...inputData, [name]: value });
        setRuleType(value)
    };
    // const handleRuleType1 = (e) => {
    //     console.log("handleruletypeevent", e.target)
    //     const { name, value } = e.target;
    //     // setInputData({ ...inputData, [name]: value });
    //     setRuleType1(value)
    // };

    const getAPIFormat2 = async () => {
        setLoading(true)
        try {
            const response = await getDocumentName();
            console.log("getAPIFormat2", response);
            if (response.status == 200) {
                let data = response.data.data
                let finalArr = []
                data.map((obj) => {
                    finalArr.push({
                        "name": obj._docname,
                        "value": obj._document_id
                    })
                })
                setDocumentName(finalArr);
            }
        }
        catch (error) {
            console.log("getAPIFormat2error", error);
            setError(error)
        }
        setLoading(false)
    };

    const GetDocParameter = async () => {
        try {
            console.log("resgetdocparameter", docID)
            // start of change
            const res = await getParameterDocSearch(docID)
            // end of change
            console.log("resgetdocparameter", res)
            if (res.status == 200) {
                let data = res.data.data
                console.log("data", data)
                let finalArr = []
                data.map((obj) => {
                    finalArr.push({
                        name: obj.docparamname,
                        value: obj.docparamvalue
                    })
                })
                setDocParam(finalArr)
            }
        }
        catch (error) {
            console.log(error);
            setError(error)
        }
    }
    console.log("final", docParam)

    useEffect(() => {
        console.log("docTypeID", docTypeID)
    }, [docTypeID])

    // const generalisedColumn = (searchData, columnDetails) => {
    //     console.log("searchData", searchData)
    //     console.log("columnDetails", columnDetails)
    //     // // Create a map for faster lookup
    //     const paramTextMap = new Map();
    //     for (const row of columnDetails) {
    //         paramTextMap.set(row.colnames, row.paramnames);
    //     }
    //     // Process the document_keys array
    //     const responseData = [];
    //     for (const item of searchData) {
    //         const paramKey = paramTextMap.get(item.key);
    //         if (paramKey) {
    //             responseData.push({
    //                 key: paramKey,
    //                 value: item.value,
    //             });
    //         }
    //     }
    //     console.log("generalisedColumn", responseData)
    //     return responseData;
    // }

    const generalisedColumn = (searchData, columnDetails) => {
        console.log("searchData", searchData)
        console.log("columnDetails", columnDetails)
        const columnMap = {};
        columnDetails.forEach(item => {
            columnMap[item.colnames] = item.paramnames;
        });

        // Iterate through array1 and transform the data
        const result = [];
        const transformedData = searchData.map(item => {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    const columnName = key;
                    const value = item[key];
                    if (columnMap[columnName]) {
                        result.push({
                            key: columnMap[columnName],
                            value: value
                        });
                    }
                }
            }

        })
        console.log("result", result)
        result.map((item) => {
            columnDetails.map((obj) => {
                if (item.key == obj.paramnames) {
                    item['type'] = obj.type
                }
            })
        })
        return result;
    }

    const getParameterCols = async () => {
        setLoading(true)
        try {
            const response = await getParamColDetails(docID);
            setLoading(false)
            console.log("getParameterColsResponse", response)
            // if (response.status == 200) {
            let finalData = response.data.data
            console.log("finalData", finalData)
            setParameterColsData(finalData)
            return finalData
            // }
        } catch (error) {
            console.log("error", error)
        }
        setLoading(false)
    }

    const getSoCustomerData = async (soNoParam) => {
        console.log("soNoParam", soNoParam)
        try {
            let response = await getSoCustomerDetails(docID, soNoParam, companyId)
            console.log("getSoCustomerDataResponse", response)
            let finalData = response.data.data.filter(obj => obj.key !== '')
            return finalData
        }
        catch (err) {
            console.log("err", err)
            setError(err)
        }
    }

    const SubmitDocDetails = async (castId = 0) => {
        // if(docID == "" || docVal.doc_val == "" || docperaval == ""){
        //     toast.error()
        // }
        if (castId == 1) {
            setIsSoSelect((prev) => { return prev })
        } else {
            setIsSoSelect(null)
        }
        setSelection([])
        setSelectedSo([])

        console.log("doctypeid", docID, docTypeID)
        setDocTypeID(docID)

        let concatParamValue = "";
        let concatParamName = "";
        // if (soNo !== '' && deliveryNo !== '' && castNo !== '') {
        //     concatParamName = 'soNo' + ':' + 'deliveryNo' + ':' + 'castNo'
        //     concatParamValue = soNo + ':' + deliveryNo + ':' + castNo
        // } else if (soNo !== '' && deliveryNo !== '') {
        //     concatParamName = 'soNo' + ':' + 'deliveryNo'
        //     concatParamValue = soNo + ':' + deliveryNo
        // } else if (soNo !== '' && castNo !== ''){
        //     concatParamName = 'soNo' + ':' + 'castNo'
        //     concatParamValue = soNo + ':' + castNo
        // }else if (deliveryNo !== '' && castNo !== '') {
        //     concatParamName = 'deliveryNo' + ':' + 'castNo'
        //     concatParamValue = deliveryNo + ':' + castNo
        // } else {
        concatParamName = (soNo !== '') && 'soNo' || (deliveryNo !== '') && 'deliveryNo'
        concatParamValue = soNo || deliveryNo
        // }

        if (formattedFromDate == "") {
            toast.error("Please select From Date")
            return
        }
        if (formattedToDate == "") {
            toast.error("Please select To Date")
            return
        }
        let sendData = {

            // "docId": docID == "" ? 1 : docID,
            "docId": docID,
            "paramvalue": concatParamValue,
            "paramname": concatParamName,
            // "fromDate": fromDate,
            // "toDate": toDate
            "fromDate": formattedFromDate,
            "toDate": formattedToDate,
            "email": localStorage.getItem("Logindata"),
            "customer": customerName

            // "fromDate": "31-7-2023",
            // "toDate": "31-7-2023"
        }

        console.log("docparametersendData", sendData)

        setLoading(true);
        try {
            let response = await getDocForAdmin(sendData)
            console.log("getDocByParameterresponse", response)
            // response = response.resData
            if (response.status !== 200) {
                if ("message" in response) {
                    toast.error(response.message);
                }
                else {
                    toast.error("No Data Found");
                }
                setDocData([]);
                setSelectedSo([])
                setCustomerData([])
                setDocResponse([])
                setDocResponse([])
                setHeaderName([])
                setSoData([])
                setSelection([])
                setSelectedSo([])
                setRuleHide(false)
                setRuleHide(false)
                setLoading(false);
                return
            }

            let sum = 0
            let assignedSODetails = response.data.fullData.map((item, i) => {
                sum += parseInt(item.col3)
                console.log("assignedSODetails_", item, sum)

                // if (selectedSo !== '') {
                //     selectedSo.map((obj) => {
                //         if (obj == item.col19) {
                //             item['isChecked'] = true
                //         }
                //     })
                // } else {
                //     item['isChecked'] = false
                // }
                item['isChecked'] = false
                item['totalWeight'] = sum
                return item
            });
            console.log("assignedSODetails__", assignedSODetails)
            // let uniqAssignedPolicyDetails = _.uniqBy(assignedSODetails, 'salesorderno');
            // const uniqueArray = [...new Set(assignedSODetails)];

            let filteredRowData = response.data.fullData.filter(item => selectedSo.includes(item.col19))
            // setSelection(filteredRowData)
            console.log("filteredRowData_", filteredRowData)

            const uniqueArray = Array.from(assignedSODetails.reduce((map, obj) => map.set(obj.col19, obj), new Map()).values());
            console.log("uniqueArraySoData", uniqueArray)
            setSoData(uniqueArray)
            setDocData(response.data.fullData);
            setCustomerData(response.data.customerData)
            getRuleTypeForAdmin()
            setRuleHide(true)
            setDocResponse([])
            setHeaderName(response.data.data1)
            GetingRowandheaders(response.data.data1)
            toast.success(response.data.msg, {
                id: 'data fetched',
            });
            // setTimeout(() => {
            //     setLoading(false);
            // }, 1000);

            if (castId !== 0) {
                return response.data
            }
            return filteredRowData
        }
        catch (err) {
            setError(err)
        }
        setLoading(false)
    }

    const GetingRowandheaders = (headerName) => {
        console.log("row values mill rhi hai?", headerName)
        // let headers = [];
        // headers = Object.keys(headerName)
        let dataGridColumns = headerName.map((header) => ({
            // field: header.docparamvalue,
            // headerCheckboxSelection: header.paramid == 1 ? true : false,
            // checkboxSelection: header.paramid == 1 ? true : false,
            id: header.paramid,
            field: header.param_text,
            headerName: header.docparamname.charAt(0).toUpperCase() + header.docparamname.slice(1),
            filter: true,
            resizable: false,
            // suppressMenu: false,
            // width: 150,
            flex: 0.4,
        }))

        let Arr = [
            { label: "File", icon: (<AiFillFile />), function: { showModal } },
            { label: "Raw data", icon: (<BsFiletypeRaw />), function: { showJsonRawData } },
            // { label: "History", icon: (<RiInformationLine />), function: { showAppliedRulesHistoryPopup } },
        ]
        Arr.map(item => {
            dataGridColumns.push(
                ({
                    id: dataGridColumns.length + 1,
                    field: item.label,
                    headerName: item.label,
                    filter: false,
                    // width: "150px",
                    flex: 0.3,
                    cellRenderer: (params) => {
                        console.log("convewrtateddata ", params)
                        return (
                            <div className="cellAction">
                                <a class="btn" onClick={() => columnFunctions(params)}>
                                    {/* <img src={File} alt="fileicon" style={{ width: '0.8rem' }} /> */}
                                    {/* __icon comes here */}
                                    {item.icon}
                                </a>
                            </div>
                        );
                    }
                }
                )
            )
        })

        dataGridColumns.sort((a, b) => a.id - b.id)

        console.log("dataGridColumns", dataGridColumns)
        // __data comes for table from here
        setColumns(dataGridColumns); // Set the dynamic columns
    }

    // useEffect(() => { }, [columns])

    const OnRadioChange = (e) => {
        console.log('radio checked', e.target.value);
        setRadioSelection(e.target.value)

    };

    useEffect(() => {
        setRadioSelection(3)
        // setSelectedValue(1)
        // setStatusList([
        //     {
        //         label: "PDF",
        //         value: 1,
        //         status: true
        //     },
        //     {
        //         label: "CSV",
        //         value: 2,
        //         status: false
        //     },
        //     {
        //         label: "Excel",
        //         value: 3,
        //         status: false
        //     }
        // ])
    }, [docData])

    useEffect(() => {
        console.log("selectiondata", selection)

    }, [selection])

    useEffect(() => {
        getUserDetailsCompId()
        // }, [companyId])
    }, [])

    useEffect(() => {
        if (isAdminDoc) {
            getUserDetailsCompId()
        }
        // }, [companyId])
    }, [isAdminDoc])


    const getUserDetailsCompId = async () => {
        let compID = userDetailsLog.comp_id
        try {
            const response = await getUsersByCompId(compID)
            console.log("getUserDetailsCompIdresponse", response)
            setMainCompanyUsers(response)
        }
        catch (err) {
            console.log("err", err)
        }
    }

    const retrieveEmailList = (emailList) => {
        console.log("retrieveEmailList", emailList)
        setEmailList(emailList)
    }

    const handleOtherUsers = (e) => {
        console.log("handleOtherUsers", e.target)
        let value = e.target.value
        setOtherUsers(value)
    }

    const handleSubject = (e) => {
        console.log("handleSubject", e.target)
        let value = e.target.value
        setSubject(value)
    }

    const handleOtherUsersCC = (e) => {
        console.log("handleOtherUsersCC", e.target)
        let value = e.target.value
        setOtherUsersCC(value)
    }

    const handleOtherUsersBCC = (e) => {
        console.log("handleOtherUsersBCC", e.target)
        let value = e.target.value
        setOtherUsersBCC(value)
    }


    useEffect(() => {
        console.log("otherUsers", otherUsers)
        // parseAndValidateEmails(otherUsers)
    }, [otherUsers])

    const parseAndValidateEmails = (input) => {
        if (input) {
            // Regular expression for basic email validation
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            // Split the input string by commas or semicolons
            const emailArray = input.split(/[\s,;]+/);

            const hasInvalidEmails = emailArray.map(email => email.trim()).some(obj => !emailPattern.test(obj))
            console.log("hasInvalidEmails", hasInvalidEmails)
            // Filter valid emails
            const validEmails = emailArray.filter(email => emailPattern.test(email.trim()));
            console.log("validEmails", validEmails)
            let emailObj = { validEmails, hasInvalidEmails }
            return emailObj;
        }
    }

    const sendDocsToEmailList = async () => {
        let emailUsers = { emailList }
        if (otherUsers.length == 0) {
            toast.error("Please provide valid email address")
            return
        }
        let { validEmails, hasInvalidEmails } = parseAndValidateEmails(otherUsers)
        if (hasInvalidEmails) {
            toast.error("Please provide valid email address")
            return
        }
        if (validEmails.length == 0) {
            toast.error("Please provide email address")
            return
        }

        let validEmailsCC = ""
        let hasInvalidEmailsCC
        if (otherUsersCC) {
            let { validEmails: validEmailsCC2, hasInvalidEmails: hasInvalidEmailsCC2 } = parseAndValidateEmails(otherUsersCC)
            if (hasInvalidEmailsCC2) {
                toast.error("Please provide valid email address")
                return
            }

            if (validEmailsCC2.length == 0) {
                toast.error("Please provide email address")
                return
            }
            validEmailsCC = validEmailsCC2
            hasInvalidEmailsCC = hasInvalidEmailsCC2
        }
        let validEmailsBCC = ""
        let hasInvalidEmailsBCC
        if (otherUsersBCC) {
            let { validEmails: validEmailsBCC2, hasInvalidEmails: hasInvalidEmailsBCC2 } = parseAndValidateEmails(otherUsersBCC)
            if (hasInvalidEmailsBCC2) {
                toast.error("Please provide valid email address")
                return
            }

            if (validEmailsBCC2.length == 0) {
                toast.error("Please provide email address")
                return
            }
            validEmailsBCC = validEmailsBCC2
            hasInvalidEmailsBCC = hasInvalidEmailsBCC2
        }

        setLoading(true)
        try {
            const formData = new FormData();
            console.log("fileBlob2", fileBlob)
            console.log("docName2", docName)
            formData.append('emailList', validEmails)
            if (validEmailsCC) {
                formData.append('cc', validEmailsCC)
            }
            if (validEmailsBCC) {
                formData.append('bcc', validEmailsBCC)
            }
            if (subject) {
                formData.append('subject', subject)
            }

            formData.append('attachment', fileBlob, docName)
            // const response = await emailDocs(emailUsers)
            console.log('validEmails', validEmails)
            console.log("sendemailformData", formData)
            const response = await emailDocs(formData)
            console.log("getUserDetailsCompIdresponse", response)
            if (response.status == 200) {
                let logParams = selectedValue == 1 ? "PDF" : selectedValue == 2 ? "CSV" : selectedValue == 3 ? "Excel" : selectedValue == 4 ? "Text" : "PDF"
                // insertLogsForUser(logParams)   
                downloadFile(docName)
            }
            setPdfUrl("")
            setIsSendEmail(false)
        }
        catch (err) {
            console.log("err", err)
            // setError(err)
            if (err?.response?.data?.response?.message) {
                toast.error(err?.response?.data?.response.message)
            }
            else {
                setError(err)
            }
            // toast.error("An error has occured")
        }
        setLoading(false)
    }


    // export const fileNameGeneration = (arr) => {
    //     console.log("selectionFileGen", arr)
    //     let fileName
    //     if (arr.length == 1) {
    //         if ("castid" in arr[0]) {
    //             fileName = "COA_" + arr[0].castid
    //         }
    //     }
    //     else if (arr.length > 1) {
    //         if ("castid" in arr[0]) {
    //             fileName = "COA_" + dayjs().format("DD-MM-YYYY")
    //         }
    //     }
    //     console.log("fileName", fileName)
    //     return fileName
    // }

    // const equals = (array1, array2) => JSON.stringify(array1) === JSON.stringify(array2);

    // const DownloadFile = () => {
    //     console.log("selection", selection, "ruletype", ruleType)
    //     if (selection.length == 0) {
    //         toast.error("No rows selected")
    //         return
    //     }
    //     // let downloadData = selection[0]["conversion_data"]
    //     let finalDownloadData = []
    //     // let selectedCastID = selection.map((obj) => obj.castid)
    //     // let selectedBundleNo = selection.map((obj) => obj.bundleno)
    //     let selectedItem = selection.map((obj) => obj.col1)
    //     // console.log("selectedCastID", selectedCastID)
    //     console.log("selectedItem", selectedItem)
    //     // console.log("selectedBundleNo", selectedBundleNo)
    //     // let downloadData = docResponse.filter((item, i) => {

    //     //     if (item.castid == selectedCastID[i]) {
    //     //         return finalDownloadData.push(item)
    //     //     }

    //     // })
    //     // downloadData = JSON.parse(downloadData)
    //     console.log("selection_conversion", selection, docResponse)
    //     // console.log("downloadData", downloadData, finalDownloadData)
    //     let conversionData = selection.map((obj) => obj.conversion_data)
    //     let convertedSelectedData = []
    //     // selection.forEach((obj) => convertedSelectedData.push(JSON.parse(obj.conversion_data)))
    //     selection.forEach((obj) => convertedSelectedData.push(JSON.parse(obj.col20)))
    //     let convertedKeys = []
    //     convertedSelectedData.forEach((obj) => {
    //         convertedKeys.push(Object.keys(obj))
    //     })
    //     let matchingKeys = []
    //     if (convertedKeys.length > 1) {
    //         for (let i = 1; i < convertedKeys.length; i++) {
    //             matchingKeys.push(equals(convertedKeys[0], convertedKeys[i]))
    //         }
    //         if (matchingKeys.includes(false)) {
    //             toast.error("No matching keys found")
    //             return
    //         }
    //     }

    //     console.log("convertedSelectedData", convertedSelectedData, convertedKeys, matchingKeys)

    //     // if (undefined == ruleType.value || ruleType.value == "") {
    //     //     toast.error("No rules selected")
    //     //     return
    //     // 

    //     // if (undefined == ruleType || ruleType == "") {
    //     //     toast.error("No rules selected")
    //     //     return
    //     // }

    //     if (selectedValue == 1) {
    //         console.log("convertdata_selection", convertedSelectedData, selection)
    //         // return
    //         if (convertedSelectedData.length == 1) {
    //             generatePDF(convertedSelectedData, fileNameGeneration(selection))
    //         }
    //         else {
    //             let forExcel = transformForDownload(convertedSelectedData)
    //             shouldUseA3(forExcel)
    //             // convertExcelToPDF(forExcel[0].analysisDataArrayForExcel, fileNameGeneration(selection))
    //         }
    //     }
    //     else if (selectedValue == 2) {
    //         handleConvertToCSV(convertedSelectedData, fileNameGeneration(selection))
    //     }
    //     else if (selectedValue == 3) {
    //         console.log("dataForExcel", dataForExcel)

    //         // let analysisData = dataForExcel.filter((obj)=> Array.isArray(obj) == true)
    //         // let forExcel = transformForDownload(dataForExcel)
    //         let forExcel = transformForDownload(convertedSelectedData)
    //         console.log("forExcel", forExcel, Object.keys(forExcel[0].analysisDataArrayForExcel[0]), Object.values(forExcel[0].analysisDataArrayForExcel[0]))
    //         // console.log("selectionData",selectionData2)
    //         // handleConvertToExcel()

    //         // if (undefined == dataForExcel || dataForExcel.length == 0) {
    //         //     toast.error("No data available for download")
    //         //     return
    //         // }
    //         // exportWorksheet(dataForExcel, "sample-excel")
    //         exportWorksheet(forExcel[0].analysisDataArrayForExcel, fileNameGeneration(selection))
    //     }
    // }

    // for new header keys & customerData start
    const equals = (array1, array2) => JSON.stringify(array1) === JSON.stringify(array2);

    const insertLogsForUser = async (params) => {
        console.log("userDetailsLog_", userDetailsLog, companyDetails, selectedSo, selectedSo.toString());
        let payload = {
            userId: userDetailsLog.user_id,
            userName: userDetailsLog.name,
            email: userDetailsLog.email,
            compId: companyDetails.comp_id,
            compName: companyDetails.comp_name,
            actionType: "Download",
            soNo: selectedSo.toString(),
            fileType: params,
            docType: "COA",
            profileName: "",
            title: "",
            designation: "",
            department: "",
            userType: "",
            phoneNo: "",
            address: "",
        }
        console.log("userDetailsLog_", payload);
        let logresponse = await insertLogs(payload)
        console.log("logresponse_", logresponse);
    }

    const DownloadFile = async () => {
        console.log("selection", selection, "ruletype", ruleType);
        if (selection.length === 0) {
            toast.error("No rows selected");
            return;
        }

        let ApplyRule = []
        let parsedObj


        // Extracting 'col20' data and parsing JSON
        let convertedSelectedData = [];
        for (let i = 0; i < selection.length; i++) {
            if (roleType == "super-user") {
                console.log("insideif_2", selection.length == i + 1)
                if (selection.length == i + 1) {
                    console.log("insideif_3")
                    ApplyRule = await handleApplyRule()
                    console.log("ApplyRule_", ApplyRule)
                }
            } else {
                if (isSendMailBtn && selection[i].col20 == null && selectedSo.length == 1) {
                    toast.error("Kindly apply rule and download it")
                    return
                }
                parsedObj = JSON.parse(selection[i].col20);
                convertedSelectedData.push(parsedObj);
            }
        }

        if (ApplyRule.length > 0) {
            console.log("insideif_4")
            for (let i = 0; i < ApplyRule.length; i++) {
                // parsedObj = JSON.parse(ApplyRule[i].col20);
                convertedSelectedData.push(ApplyRule[i]);
            }
        }
        // selection.forEach((obj) => {
        //     try {
        //         const parsedObj = JSON.parse(obj.col20);
        //         convertedSelectedData.push(parsedObj);
        //     } catch (error) {
        //         console.error("Error parsing 'col20' JSON:", error);
        //     }
        // });

        console.log("convertedSelectedData", convertedSelectedData);

        // Check if all objects in 'convertedSelectedData' have the same keys in 'document_keys'
        let firstKeys = [];
        let headerkeys = [];
        if (convertedSelectedData.length > 0 && (convertedSelectedData[0]?.document_keys || convertedSelectedData[0]?.header_keys)) {
            firstKeys = convertedSelectedData[0]?.document_keys.map((keyObj) => keyObj.key);
            headerkeys = convertedSelectedData[0]?.header_keys.map((keyObj) => keyObj.key)
        }

        for (let i = 1; i < convertedSelectedData.length; i++) {
            const currentKeys = convertedSelectedData[i]?.document_keys.map((keyObj) => keyObj.key);
            const headerKeycheck = convertedSelectedData[i]?.header_keys.map((keyObj) => keyObj.key);
            if (isSendMailBtn && !equals(firstKeys, currentKeys) || !equals(headerkeys, headerKeycheck)) {
                toast.error("Kindly apply same rule and download it");
                return;
            }
        }

        // If we reached here, all objects have the same keys in 'document_keys'
        console.log("All objects have matching keys in 'document_keys'");

        console.log("convertedSelectedData", convertedSelectedData, firstKeys)

        if (selectedValue == 1) {
            console.log("convertdata_selection", convertedSelectedData, selection)
            // return
            // generatePDF(convertedSelectedData, fileNameGeneration(selection))
            if (convertedSelectedData.length == 1) {
                // if (convertedSelectedData) {
                generatePDF(convertedSelectedData, fileNameGeneration(selection))

            }
            else {
                let forExcel = transformForDownload(convertedSelectedData)
                shouldUseA3(forExcel)
                // convertExcelToPDF(forExcel[0].analysisDataArrayForExcel, fileNameGeneration(selection))
            }

        }
        else if (selectedValue == 2) {
            handleConvertToCSV(convertedSelectedData, fileNameGeneration(selection))

        }
        else if (selectedValue == 3) {
            console.log("dataForExcel", dataForExcel)
            let forExcel = transformForDownload(convertedSelectedData)
            console.log("forExcel", forExcel, Object.keys(forExcel[0].analysisDataArrayForExcel[0]), Object.values(forExcel[0].analysisDataArrayForExcel[0]))
            exportWorksheet(forExcel[0].analysisDataArrayForExcel, fileNameGeneration(selection))

        }
        else if (selectedValue == 4) {
            handleConvertToText(convertedSelectedData, fileNameGeneration(selection))

        }

        // Further processing logic here...

        // Example: Output the first object's 'document_keys' for reference
        if (convertedSelectedData.length > 0) {
            const firstDocumentKeys = convertedSelectedData[0].document_keys;
            console.log("First object document_keys:");
            firstDocumentKeys.forEach((keyObj) => {
                console.log(`${keyObj.key}: ${keyObj.value}`);
            });
        }
    };

    // for new header keys & customerData end

    function transformForDownload(arr) {
        console.log("transformForDownloadarr", arr)
        let ObjectKeys, analysisData
        let theObject
        let finalArr = []
        let COA = []
        let docHeader = []
        let customHeader = []
        let finalArr2 = []
        let newObj = {}
        let finalObj = {}
        let finalObj2 = {}
        let finalArr3 = []
        let headerAndKeys = []
        let finalObj4 = {}
        let finalArr4 = []
        let finalObj5 = {}
        let finalArr5 = []
        for (let i = 0; i < arr.length; i++) {
            theObject = arr[i]

            ObjectKeys = Object.keys(theObject)
            console.log("ObjectKeys", theObject, ObjectKeys)
            analysisData = ObjectKeys.filter((item) => {
                if (Array.isArray(theObject[item]) == true) {
                    return item
                }
            })

            // rawData.header_keys start

            // for (let j = 0; j < analysisData.length; j++) {
            //     finalArr = theObject[analysisData[j]]
            //     headerAndKeys = headerAndKeys.concat(finalArr)
            //     console.log("h_k", theObject, analysisData[j], finalArr, headerAndKeys)
            // }

            let docKeys = []
            let ruleKeys = []
            let customData = []

            if (theObject.hasOwnProperty("header_keys")) {
                docKeys = theObject["header_keys"]
            }

            if (theObject.hasOwnProperty("document_keys")) {
                ruleKeys = theObject["document_keys"]
            }

            if (theObject.hasOwnProperty("customer_Data")) {
                customData = theObject["customer_Data"]
            }

            headerAndKeys = docKeys.concat(ruleKeys)
            headerAndKeys = headerAndKeys.concat(customData)
            console.log("docKeys", docKeys, "ruleKeys", ruleKeys,)
            console.log("headerAndKeys", headerAndKeys)

            headerAndKeys.forEach((obj) => {
                newObj[obj.key] = obj.value
            })

            finalArr = theObject["document_keys"]
            COA = finalArr
            docHeader = theObject["header_keys"]
            customHeader = theObject["customer_Data"]
            finalObj4 = { ...newObj }
            finalArr4.push(finalObj4)
            finalObj5 = {
                "docHeader": docHeader,
                "customHeader": customHeader,
                "COA": COA,
                "analysisDataArrayForExcel": finalArr4
            }

            finalArr5.push(finalObj5)
            console.log("theFinalObj", finalObj4, finalObj5)

            // rawData.header_keys end



            // prev header as obj start

            // finalArr = theObject[analysisData]
            // COA = finalArr
            // delete theObject[analysisData]
            // console.log("transformDetails", finalArr, theObject, analysisData)
            // finalArr.forEach((obj) => {
            //     newObj[obj.key] = obj.value
            // })
            // finalObj = { ...theObject, ...newObj }
            // finalArr2.push(finalObj)
            // finalObj2 = {
            //     "docHeader": theObject,
            //     "analysisDataName": analysisData,
            //     "COA": COA,
            //     "analysisDataArrayForExcel": finalArr2
            // }
            // finalArr3.push(finalObj2)

            // prev header as obj end

        }

        console.log("analysisData", theObject, ObjectKeys, analysisData, finalArr, newObj, finalObj, finalArr2)
        console.log("finalObj2", finalObj2)
        console.log("finalArr3", finalArr3)
        // return finalArr3
        console.log("theFinalArr", finalArr4, finalArr5)
        return finalArr5
    }

    const isArrayAllSame = (arr) => {
        if (arr.length > 0) {
            let referenceItem = arr[0].col19
            for (let i = 1; i < arr.length; i++) {
                if (arr[i].col19 !== referenceItem) {
                    return false
                }
            }
            return true
        }
    }


    //Table Function

    // Get selected rows
    const handleApplyRule = async () => {

        console.log('SelectedRows', selection);

        // if (Object.keys(selection).length === 0 && selection.constructor === Object) {
        //     toast.error("No rows selected")
        //     return
        // }
        if (selection.length == 0) {
            toast.error("No rows selected")
            return
        }

        // if (selection.length > 1 && !isArrayAllSame(selection)) {
        //     toast.error("Select Same SO Number")
        //     return
        // }

        let isSpRule = false
        ruleFormat.map((item, i) => {
            if (item.value == ruleType) {
                if (item.ruleType == "sprule") {
                    isSpRule = true
                } else {
                    isSpRule = false
                }
            }
        })

        if (isSpRule) {
            if (selection.length > 1) {
                if (selection[0].col1 !== selection[1]?.col1) {
                    toast.error("Multiple Batch Number are not allowed")
                    return
                }
            }
        }

        // if (selection.length > 1) {
        //     if (selection[0]?.col19 !== selection[1]?.col19) {
        //         toast.error("Select Same SO Number")
        //         return
        //     }
        // }

        // if (undefined == ruleType.value || ruleType.value == "") {
        //     toast.error("No rules selected")
        //     return
        // }
        if (undefined == ruleType || ruleType == "") {
            toast.error("No rules selected")
            return
        }

        // // check if ruleType is lambda
        // let isLambda = false
        // isLambda = ruleFormat.some((obj) => {
        //     return obj.value == ruleType && obj.ruleType == "lambda"
        // })
        // console.log("islambda", isLambda)
        // if(isLambda){
        //     let sendDataAWS = {
        //         "searchData": selection,
        //         // "castid": selection.castid,
        //         "rule_id": ruleType,
        //         "docid": docID,
        //         email: localStorage.getItem("Logindata")

        //     }
        //     console.log("sendDataAWS", sendDataAWS)
        //     try{
        //         setLoading(true);
        //         let response = await applyRuleToDocAWS(sendDataAWS)
        //         console.log("ruleAppliedresponseaws", response)
        //     }
        //     catch (error) {
        //         console.log("ruleAppliederror", error)
        //         if ("response" in error) {
        //             toast.error(error.response.data.msg)
        //         }
        //         else {
        //             toast.error(error.message)
        //         }

        //     }
        // }

        delete selection.conversion_data
        // let searchData1 = selection.filter((obj)=>delete obj.conversion_data)

        let paramSoArray = []
        let paramBundleArray = []
        let paramDeliveryArray = []

        const uniqueArray = Array.from(selection.reduce((map, obj) => map.set(obj.col19, obj), new Map()).values());

        uniqueArray.map((item, i) => {
            console.log("item_no", item)
            // paramSoArray.push(`'${item.col19}'`)
            paramSoArray.push(item.col19)
            paramBundleArray.push(item.col1)
            paramDeliveryArray.push(item.col39)
        })

        console.log("paramDatas_", paramSoArray, paramBundleArray, paramDeliveryArray)
        let paramValue = ""
        paramValue = paramSoArray.toString()
        console.log("paramSoArr_", paramValue);

        let sendData = {
            // "searchData": selection,
            "paramname": 'salesorder',
            "paramvalue": paramValue,
            // "castid": selection.castid,
            "rule_id": ruleType.toString(),
            "docid": docID,
            email: localStorage.getItem("Logindata"),
            // "soNo": selection[0].col19,
            // "bundleno": selection[0].col1
        }
        console.log("sendData", sendData, "searchData1")
        // return
        // setDocData([])

        try {
            setLoading(true)
            let response = await applyRuleToDoc(sendData)
            setLoading(false)
            // console.log("rule applied success", response.data.outputData.COA[0].conversion_data)
            console.log("ruleAppliedresponse", response)
            let data = response.data.outputData
            console.log("ruleAppliedresponsedata", data)
            if (response.status == 200) {
                if (roleType == "super-admin") {
                    toast.success("Rule applied successfully.", {
                        id: 'rule applied',
                    })
                }
                // setDocData([])
                setSelection([])
                setSoData([])
                setRuleHide(false)
                let docData = await SubmitDocDetails(0)
                console.log("docdata_", docData)
                // Delayfilefun()
                // setAfterConverstionData([])
                // setAfterConverstionData(data)
                // setConvertData(data)
                setAppliedRuleData(data)
                setDataForExcel(data)
                return data
            }

            else {
                toast.error(messages.somethingWrong)
                return false
            }
        }
        catch (error) {
            console.log("ruleAppliederror", error)
            // if ("response" in error) {
            //     toast.error(error.response.data.msg)
            // }
            // else {
            //     toast.error(error.message)
            // }

            setError(error)
            return false
        }

    };

    const getRuleTypeForAdmin = async () => {
        const email = localStorage.getItem("Logindata");
        let userid = userDetailsLog.user_id
        let encCustName = encrypt(customerName.toString())
        let encDocID = encrypt(docID.toString())
        let encUserid = encrypt(userid.toString())
        console.log("getRuleTypeForAdminRes", encCustName,encDocID,encUserid)

        setLoading(true);
        try {
            
            let response = await getRulesForAdmin(encCustName, encDocID, encUserid)
            console.log("getRulesResponse", response)
            if (response.status == 200) {
                let data = response.data.data
                let finalArr = []
                data.map((obj) => {
                    if (obj.rulestype !== null) {
                        const trimmedRulestype = obj._rulestype.trim()
                        // if (obj._spec_status_name == "Approved") {
                        finalArr.push({
                            name: obj._rule_name,
                            value: obj._rule_id,
                            ruleType: trimmedRulestype,
                            isDefault: obj._is_default_rule
                        })
                        // }
                    }
                })
                finalArr.sort((a, b) => b.value - a.value);
                setRuleFormat(finalArr)
                console.log("__final", finalArr)
                if (finalArr.length === 1) {
                    setRuleType(finalArr[0].value)
                }
                if (selection.length == 0) {
                    finalArr.filter((obj) => {
                        if (obj.isDefault == true) {
                            setRuleType(obj.value)
                        }
                    })
                }
                if(response.data.isDefaultRule){
                    setRuleType(response.data.defaultRuleId)
                }
            }

        } catch (error) {
            console.log(error);
            setError(error)
        }
        setLoading(false);
    };


    // determining column length
    const shouldUseA3 = (csvData) => {
        const fontSize = 10
        let columnHeaderKeys = Object.keys(csvData[0].analysisDataArrayForExcel[0])
        let columnHeaderValue = Object.values(csvData[0].analysisDataArrayForExcel[0])
        let columnHeaderKeyStrings = columnHeaderKeys.map((obj) => obj.toString())
        let columnHeaderValueStrings = columnHeaderValue.map((obj) => obj.toString())
        // let totalLengthOfKeys = columnHeaderKeyStrings.reduce((acc, str) => acc + str.length, 0)
        let totalLengthOfKeys = columnHeaderKeyStrings.reduce((acc, str) => acc + Math.floor((str.length * fontSize) / 72 * 25.4), 0)
        let totalLengthOfValues = columnHeaderValueStrings.reduce((acc, str) => acc + Math.floor((str.length * fontSize) / 72 * 25.4), 0)
        let totalLengthOfCharacters = totalLengthOfKeys > totalLengthOfValues ? totalLengthOfKeys : totalLengthOfValues
        console.log("totalLengthOfKeys", columnHeaderValueStrings, totalLengthOfKeys, totalLengthOfValues, totalLengthOfCharacters)
        let pageWidth = totalLengthOfCharacters + 20
        const marginSubtract = 40
        const a4PortraitWidth = 210
        const a4LandscapeWidth = 297
        // const a3PortraitWidth = 297
        const a3LandscapeWidth = 420
        const a2LandscapeWidth = 594

        let page = { orientation: "landscape", format: "a4" }
        if (totalLengthOfCharacters <= a4PortraitWidth - marginSubtract) {
            page.format = "a4"
            page.orientation = "portrait"
        } else if (totalLengthOfCharacters <= a4LandscapeWidth - marginSubtract) {
            page.format = "a4"
            page.orientation = "landscape"
        } else if (totalLengthOfCharacters <= a3LandscapeWidth - marginSubtract) {
            page.format = "a3"
            page.orientation = "landscape"
        }
        else if (totalLengthOfCharacters <= a2LandscapeWidth - marginSubtract) {
            page.format = "a2"
            page.orientation = "landscape"
        }
        else {
            page.format = "a2"
            page.orientation = "landscape"
        }
        console.log("page", page)

        let pageWidthInMillimeters = a4PortraitWidth;
        let effectiveContentWidthInMillimeters = pageWidthInMillimeters - marginSubtract
        let averageCharacterWidthInMillimeters = 6.35;
        let charactersPerLine = Math.floor(effectiveContentWidthInMillimeters / averageCharacterWidthInMillimeters);
        // let contentWidthRequired = (totalLengthOfCharacters / charactersPerLine) * (fontSize / 72) * 25.4; // Font size conversion from points to millimeters
        let contentWidthRequired = (totalLengthOfCharacters) * (fontSize / 72) * 25.4
        // let page = {orientation: "landscape", format: "a4"}
        // console.log("Approximate characters per line:", charactersPerLine,contentWidthRequired);
        // if (contentWidthRequired <= a4PortraitWidth) {
        //     page.format = "a4"
        //     page.orientation = "portrait"
        // } else if (contentWidthRequired <= a4LandscapeWidth) {
        //     page.format = "a4"
        //     page.orientation = "landscape"
        // } else if (contentWidthRequired <= a3LandscapeWidth) {
        //     page.format = "a3"
        //     page.orientation = "landscape"
        // }
        // else if (contentWidthRequired <= a2LandscapeWidth) {
        //     page.format = "a2"
        //     page.orientation = "landscape"
        // }
        // console.log("page",page)
        // else {
        //     pageSize = 'Other'; // If none of the above sizes fit, consider a custom or larger page size
        // }
        convertExcelToPDF(csvData[0].analysisDataArrayForExcel, fileNameGeneration(selection), page)
    }



    function getTextWidth(text, fontSize = 12, fontFamily = "Arial") {
        if (typeof text !== 'string') {
            text = String(text);
        }
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        context.font = `${fontSize}pt ${fontFamily}`;
        return context.measureText(text.width);
    }

    function calculateTotalColumnWidth(evoData, fontSize = 12, fontFamily = 'Arial') {
        if (!Array.isArray(evoData) || evoData.length === 0) {
            console.log("error_:Invalid data provided for evoData")
        }

        const columnHeaderKeys = Object.keys(evoData[0]);
        let totalColumnWidth = columnHeaderKeys.reduce((acc, key) => {
            const columnData = evoData.map(obj => obj[key] ? obj[key].toString() : "");
            const columnWidth = columnData.reduce((maxWidth, str) => {
                const strWidth = getTextWidth(str, fontSize, fontFamily)
                if (isNaN(maxWidth)) {
                    maxWidth = 0;
                }
                const width = strWidth?.width
                return Math.max(maxWidth, width);
            }, 0);
            return acc + columnWidth;
        }, 0);
        return totalColumnWidth;
    }


    // PDF settings
    const convertExcelToPDF = (csvData, fileName, page) => {
        const totalColumnWidth = calculateTotalColumnWidth(csvData)
        console.log(totalColumnWidth, "_totalColumnWidth")
        page.format = [totalColumnWidth, 841.89]
        page.orientation = 'landscape';
        console.log("thepagesize", page)
        // const columns = Object.keys(csvData[0]).map(key => ({ header: key, dataKey: key }));
        // const rows = csvData.map(obj => Object.values(obj));

        // const doc = new jsPDF();
        const doc = new jsPDF({
            orientation: page.orientation, // Set orientation to landscape
            unit: 'pt', // Use millimeters as the unit
            // format: [497, 210], // Custom page size: 297mm (width) x 210mm (height)
            format: page.format,
        });
        const columns = Object.keys(csvData[0]);
        const rows = csvData.map(obj => Object.values(obj));
        doc.setFontSize(10)
        doc.autoTable({
            head: [columns],
            body: rows,
            startY: 20,
            margin: { top: 20 }
        });
        if (isSendEmail) {
            console.log("gendpdfdoc", doc)
            const blob = doc.output('blob');
            const url = URL.createObjectURL(blob);
            console.log("blob", blob, "url", url)
            setPdfUrl(url)
            setFileBlob(blob)
            return
        }
        let logParams = selectedValue == 1 ? "PDF" : selectedValue == 2 ? "CSV" : selectedValue == 3 ? "Excel" : selectedValue == 4 ? "Text" : "PDF"
        insertLogsForUser(logParams)
        doc.save(`${fileName}.pdf`);
    }

    const generatePDF = (data, fileName) => {
        console.log("convertData", convertData, selection)
        // if(undefined == convertData){
        //     toast.error("No file available")
        //     return
        // }
        // let pdfObj = transformForDownload([convertData])
        let pdfObj = transformForDownload(data)

        console.log("pdfObj", pdfObj, data)
        // if (Object.keys(selection).length > 0 && selection.constructor === Object) {
        //    toast.error("please select any one record")
        //    return
        // }

        let text, docHeaderText, docHeaderLength
        let imgData = LOGO;
        let headerText
        let headers
        let rows
        let tableProps

        const doc = new jsPDF();
        var requiredPages = pdfObj.length
        for (let i = 0; i < requiredPages; i++) {

            let { docHeader, COA, customHeader } = pdfObj[i]
            let headerRows, coaRows, customRows

            doc.addImage(imgData, 'PNG', 170, 10, 20, 10);
            doc.setFontSize(12);

            tableProps = {
                margin: { top: 10, left: 20 },
                headStyles: { fillColor: [100, 100, 100] },
                footStyles: { fillColor: [100, 100, 100] },

            };

            if (docHeader && docHeader.length > 0) {
                headerRows = docHeader.map(item => [item.key, item.value])
            }
            if (COA && COA.length > 0) {
                coaRows = COA.map(item => [item.key, item.value])
            }
            if (customHeader && customHeader.length > 0) {
                customRows = customHeader.map(item => [item.key, item.value])
            }
            console.log("headerRows", headerRows, "coaRows", coaRows, "customRows", customRows)

            if (headerRows && headerRows.length > 0 && coaRows && coaRows.length > 0 && customRows && customRows.length > 0) {
                doc.text("Document Header", 20, 30);
                doc.autoTable({
                    head: [['Key', 'Value']],
                    body: headerRows,
                    startY: 35,
                    ...tableProps
                });

                doc.text("Certificate of Analysis", 20, doc.lastAutoTable.finalY + 10);
                doc.autoTable({
                    head: [['Key', 'Value']],
                    body: coaRows,
                    startY: doc.lastAutoTable.finalY + 15,
                    ...tableProps
                });

                doc.text("Customer Data", 20, doc.lastAutoTable.finalY + 10);
                doc.autoTable({
                    head: [['Key', 'Value']],
                    body: customRows,
                    startY: doc.lastAutoTable.finalY + 15,
                    ...tableProps
                });
            }
            else if (headerRows && headerRows.length > 0 && !coaRows) {
                doc.text("Document Header", 20, 30);
                doc.autoTable({
                    head: [['Key', 'Value']],
                    body: headerRows,
                    startY: 35,
                    ...tableProps
                });
            }
            else if (!headerRows && coaRows && coaRows.length > 0) {
                doc.text("Certificate of Analysis", 20, 30);
                doc.autoTable({
                    head: [['Key', 'Value']],
                    body: coaRows,
                    startY: 35,
                    ...tableProps
                });
            }

            doc.addPage();

            // text = JSON.stringify(pdfObj[i].docHeader)
            // text = text.replace(/[{}]/g, '');
            // text = text.replaceAll(':', ': ');
            // text = text.replaceAll('"', '');
            // docHeaderLength = Object.keys(pdfObj[i].docHeader).length
            // docHeaderText = `${text}`

            // console.log("docHeaderText_length", docHeaderText.length, docHeaderLength)
            // doc.addImage(imgData, 'PNG', 170, 10, 20, 10);
            // headerText = docHeaderText.split(",").join("\n")
            // console.log("headerText", text, "docHeaderText", docHeaderText, headerText)
            // headers = [['Key', 'Value']];
            // rows = pdfObj[i].COA.map(item => [item.key, item.value]);
            // tableProps = {
            //     margin: { top: 10, left: 20 },
            //     headStyles: { fillColor: [100, 100, 100] },
            //     footStyles: { fillColor: [100, 100, 100] },
            //     // startY: 40,
            //     // startY: (docHeaderLength * 10) + 1,
            //     startY: (docHeaderLength * 15) + 1,
            // };
            // doc.setFontSize(12);
            // doc.text(headerText, 20, 20);
            // doc.autoTable({ head: headers, body: rows, ...tableProps });
            // doc.addPage();
        }
        var pageCount = doc.internal.getNumberOfPages();
        doc.deletePage(pageCount)
        // doc.save('data.pdf');
        if (isSendEmail) {
            // Get the Blob URL
            console.log("gendpdfdoc", doc)
            const blob = doc.output('blob');
            const url = URL.createObjectURL(blob);
            console.log("blob", blob, "url", url)
            setPdfUrl(url)
            setFileBlob(blob)
            return
        }

        let logParams = selectedValue == 1 ? "PDF" : selectedValue == 2 ? "CSV" : selectedValue == 3 ? "Excel" : selectedValue == 4 ? "Text" : "PDF"
        insertLogsForUser(logParams)
        // downloadFile(docName) 

        doc.save(fileName);
        return

        doc.addImage(imgData, 'PNG', 170, 10, 20, 10);
        // Set header text

        // const headerText = `Cast ID: ${convertData.cast_id}\nDocument Type: ${convertData.document_type
        //     }`;
        headerText = docHeaderText.split(",").join("\n")
        console.log("headerText", text, docHeaderText, headerText)

        // Set table headers
        headers = [['Key', 'Value']];

        // Set table rows
        // const rows = convertData.COA.map(item => [item.key, item.value]);
        rows = pdfObj.COA.map(item => [item.key, item.value]);

        // Set table properties
        tableProps = {
            margin: { top: 10, left: 20 },
            headStyles: { fillColor: [100, 100, 100] },
            footStyles: { fillColor: [100, 100, 100] },
            startY: 30,
        };

        // Add header text
        doc.setFontSize(12);
        doc.text(headerText, 20, 20);

        // Add table to the PDF
        doc.autoTable({ head: headers, body: rows, ...tableProps });

        // Save the PDF
        doc.save('data.pdf');
    };

    // PDF settings end

    //CSV file function 

    // const handleConvertToCSV = (data) => {
    //     console.log("convertData",data,convertData)
    //     const csvData = convertToCSV(convertData.COA);
    //     console.log("csvData",csvData)
    //     const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    //     saveAs(blob, "data.csv");
    // };

    // const convertToCSV = (data) => {
    //     const header = Object.keys(data[0]).join(",");
    //     const rows = data.map((obj) => Object.values(obj).join(","));
    //     return `${header}\n${rows.join("\n")}`;
    // };
    const handleConvertToText = (data, fileName) => {
        console.log("convertData", data)
        let txtObj = transformForDownload(data)
        console.log("txtObj", txtObj)
        const txtData = convertToTxt(txtObj);
        console.log("txtData", txtData)
        const blob = new Blob([txtData], { type: "text/plain;charset=utf-8" });
        // saveAs(blob, "data_csv.csv");
        if (isSendEmail) {
            const url = URL.createObjectURL(blob);
            console.log("blob", blob, "url", url)
            setPdfUrl(url)
            // setPdfUrl(blob)
            setFileBlob(blob)
            return
        }
        let logParams = selectedValue == 1 ? "PDF" : selectedValue == 2 ? "CSV" : selectedValue == 3 ? "Excel" : selectedValue == 4 ? "Text" : "PDF"
        insertLogsForUser(logParams)
        saveAs(blob, fileName);
    }

    const convertToTxt = (data) => {
        console.log("datacsv", data)
        let dataForCSV = data[0].analysisDataArrayForExcel
        const headers = Array.from(new Set(dataForCSV.flatMap(item => Object.keys(item)))).join(" ");
        const rows = dataForCSV.map(item => {
            return headers.split(" ").map(header => item[header] !== undefined ? item[header] : "").join(" ");
        });
        const csvString = `${headers}\n${rows.join("\n")}`;
        return csvString
    };



    // const convertToTxt = (data) => {
    //     console.log("datacsv", data)
    //     let dataForCSV = data[0].analysisDataArrayForExcel
    //     // const header = Object.keys(data[0]["COA"]).join(",");
    //     let rows
    //     // data.forEach((obj) => {
    //     // console.log("objCOA", obj)
    //     // rows += obj.COA.map((item) => Object.values(item).join(",\n")) ;
    //     // rows += obj.COA.map((item) => `${Object.values(item)}\n`);

    //     // rows += obj.COA.map((item) => `${Object.values(item)}`);
    //     // rows += obj.analysisDataArrayForExcel.map((item) => `${Object.keys(item)} ${Object.values(item)}`);
    //     rows += dataForCSV.map((item, i) => `${i > 0 && "\n"}${Object.entries(item).map(([key, value]) => `${key.substring(0, 3) == "key" ? "" : key} ${value}`).join(' ')}`);
    //     // rows += Object.entries(dataForCSV).map(([key, value]) => `${key} ${value}`).join(' ')
    //     // rows += "\n"
    //     // })
    //     rows = rows.replace("undefined", "")
    //     rows = rows.replace("false", "")
    //     // rows = rows.replace(/\s/g, "");
    //     // rows = rows.replace(/ \n/g, '');
    //     // rows = rows.replace("\n,", ",\n")
    //     console.log("csvheader", rows, typeof (rows))
    //     // return `${header}\n${rows.join("\n")}`;
    //     return `${rows}`;
    // };

    const handleConvertToCSV = (data, fileName) => {
        console.log("convertData", data)
        let csvObj = transformForDownload(data)
        console.log("csvObj", csvObj)
        const csvData = convertToCSV(csvObj);
        console.log("csvData", csvData)
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
        // saveAs(blob, "data_csv.csv");
        if (isSendEmail) {
            const url = URL.createObjectURL(blob);
            console.log("blob", blob, "url", url)
            setPdfUrl(url)
            setFileBlob(blob)
            return
        }
        let logParams = selectedValue == 1 ? "PDF" : selectedValue == 2 ? "CSV" : selectedValue == 3 ? "Excel" : selectedValue == 4 ? "Text" : "PDF"
        insertLogsForUser(logParams)
        saveAs(blob, fileName);
    };

    // const convertToCSV = (data) => {
    //     console.log("datacsv", data)
    //     let dataForCSV = data[0].analysisDataArrayForExcel
    //     // const header = Object.keys(data[0]["COA"]).join(",");
    //     let rows
    //     // data.forEach((obj) => {
    //     // console.log("objCOA", obj)
    //     // rows += obj.COA.map((item) => Object.values(item).join(",\n")) ;
    //     // rows += obj.COA.map((item) => `${Object.values(item)}\n`);

    //     // rows += obj.COA.map((item) => `${Object.values(item)}`);
    //     // rows += obj.analysisDataArrayForExcel.map((item) => `${Object.keys(item)}, ${Object.values(item)}`);
    //     rows += dataForCSV.map((item, i) => `${i > 0 && "\n"}${Object.entries(item)}`);
    //     rows += "\n"
    //     // })
    //     rows = rows.replace("undefined", "")
    //     rows = rows.replace("false", "")
    //     rows = rows.replace("null", "")
    //     // rows = rows.replace(/\s/g, "");
    //     // rows = rows.replace(/ \n/g, '');
    //     // rows = rows.replace("\n,", ",\n")
    //     console.log("csvheader", rows, typeof (rows))
    //     // return `${header}\n${rows.join("\n")}`;
    //     return `${rows}`;
    // };

    const convertToCSV = (data) => {
        console.log("datacsv", data)
        let dataForCSV = data[0].analysisDataArrayForExcel
        const headers = Array.from(new Set(dataForCSV.flatMap(item => Object.keys(item)))).join(",");
        const rows = dataForCSV.map(item => {
            return headers.split(",").map(header => item[header] !== undefined ? item[header] : "").join(",");
        });
        const csvString = `${headers}\n${rows.join("\n")}`;
        return csvString
    }

    //CSV settings end

    // Excel file function
    const handleConvertToExcel = () => {
        const excelData = convertToExcel(convertData.COA);
        const blob = new Blob([excelData], { type: "text/csv;charset=utf-8" });
        saveAs(blob, "data.xls");
    };

    // const exportWorksheet = (jsonObject) => {
    //     console.log("jsonObject",jsonObject)
    //     var myFile = "myFile.xlsx";
    //     var myWorkSheet = XLSX.utils.json_to_sheet(jsonObject);
    //     var myWorkBook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(myWorkBook, myWorkSheet, "myWorkSheet");
    //     XLSX.writeFile(myWorkBook, myFile);

    // }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportWorksheet = (csvData, fileName) => {
        console.log("csvData", csvData)
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        // FileSaver.saveAs(data, fileName + fileExtension);
        if (isSendEmail) {
            const url = URL.createObjectURL(data);
            console.log("exportWorksheetdata", data, "url", url)
            setFileBlob(data)
            setPdfUrl(data)
            return
        }
        let logParams = selectedValue == 1 ? "PDF" : selectedValue == 2 ? "CSV" : selectedValue == 3 ? "Excel" : selectedValue == 4 ? "Text" : "PDF"
        insertLogsForUser(logParams)
        saveAs.saveAs(data, fileName + fileExtension);
        // fileNameGeneration()
    }

    const convertToExcel = (data) => {
        const header = Object.keys(data[0]).join(",");
        const rows = data.map((obj) => Object.values(obj).join(","));
        return `${header}\n${rows.join("\n")}`;
    };
    // start of change
    useEffect(() => {
        GetDocParameter()
    }, [docID])
    // end of change
    useEffect(() => {
        getActiveCustomerList()
        getAPIFormat2()
        getParameterCols()
        // GetDocParameter()
        // getRuleType()

    }, [])

    // const useStyles = makeStyles((theme) => ({
    //     highlightedRow: {
    //       backgroundColor: 'red',

    //     },
    //   }));

    //   const classes = useStyles();
    const handleSoParameter = () => {
        console.log("soItem")

        if (selection.length > 1 && !isArrayAllSame(selection)) {
            toast.error("Select Same SO Number")
            return
        }

        // if (selection.length > 1) {
        //     if (selection[0]?.col19 !== selection[1]?.col19) {
        //         toast.error("Select Same SO Number")
        //         return
        //     }
        // }

        if (selection.length > 0) {
            setSalesOrderNo(selection[0]?.col19)
            getSODetailsForComp(selection[0]?.col19)
        }
        getSoNoForComp()
        setSoModalOpen(true)
    }

    const handleCancelSo = () => {
        setSoModalOpen(false)
        setParameterName("")
        setParamValue("")
        setSalesOrderNo("")
        setSORowData([])
        // setSelection([])
        setIsUpdateButton(false)
    }

    const handleSalesOrder = (e) => {
        console.log("soItem", e.target.value)
        setSalesOrderNo(e.target.value)
        getSODetailsForComp(e.target.value)
    }

    const handleParamName = (e) => {
        console.log("ParamName_", e)
        setParameterName(e.target.value)
    }

    const handleParamValue = (e) => {
        console.log("ParamName_", e)
        setParamValue(e.target.value)
    }

    const handleAddParam = (e) => {
        console.log("addParams_")
        addSoDetails()
    }

    const handleUpdateParam = async (param) => {
        console.log("addParams_")
        let payload = {
            id: rowId,
            paramname: parameterName,
            paramvalue: paramValue
        }
        try {
            let soUpdateResponse = await updateSoDetails(payload)
            console.log("soDeleteResponse_", soUpdateResponse);
            if (soUpdateResponse.status == 200) {
                toast.success(soUpdateResponse.data.msg)
                getSODetailsForComp(salesOrderNo)
            } else {
                toast.error(soUpdateResponse.data.msg)
            }
        }
        catch (err) {
            setError(err)
        }

    }

    const handleDeleteSo = async (params) => {
        console.log("params", params)
        // let payload = {
        //     "parametername": params.data.parametername,
        //     "salesorderno": params.data.salesorderno
        // }

        try {
            let soDeleteResponse = await deleteSoDetail(params.data.id)
            console.log("soDeleteResponse_", soDeleteResponse);
            if (soDeleteResponse.status == 200) {
                toast.success(soDeleteResponse.data.msg)
                getSODetailsForComp(salesOrderNo)
            }
        } catch (err) {
            setError(err)
        }
    }

    const handleEditSo = (params) => {
        const data = params.data
        console.log("params", params)
        setParameterName(data['parametername'])
        setParamValue(data['paramvalue'])
        setRowId(data['id'])
        setIsUpdateButton(true)
    }

    const handleCloseUpdate = (param) => {
        console.log("params", param)
        setParameterName("")
        setParamValue("")
        setIsUpdateButton(false)

    }

    const columnData = [
        {
            headerName: "SO Number",
            field: "salesorderno",
            // width: 150,
            flex: 0.8,
        },
        {
            headerName: "Name",
            field: "parametername",
            // width: 150,
            flex: 0.8,
        },
        {
            headerName: "Value",
            field: "paramvalue",
            // width: 150,
            flex: 0.8,
        },
        {
            field: "",
            // width: 150,
            flex: 0.4,
            cellClass: 'my-class',
            filter: false,
            cellRenderer: (params) => {
                // console.log("Coming ...", val);
                return (
                    <div className="cellAction">
                        <a onClick={() => handleEditSo(params)} class="" style={{ fontSize: "15px" }}>
                            <FaEdit />
                        </a>
                    </div>
                );
            },
        },
        {
            field: "",
            // width: 150,
            flex: 0.4,
            filter: false,
            cellClass: 'my-class',
            cellRenderer: (params) => {
                // console.log("Coming ...", val);
                return (
                    <div className="cellAction">
                        <a onClick={() => handleDeleteSo(params)} style={{ color: "red", fontSize: "15px" }}>
                            <BsFillTrashFill />
                        </a>
                    </div>
                );
            },
        },
    ]

    const getSoNoForComp = async () => {
        console.log("inside_so_details", companyId)
        let payload = {
            "comp_id": companyId
        }
        try {
            let soResponse = await getSoNoforCompany(payload)
            console.log("soResponse_", soResponse)
            let soData = soResponse.resData.data.data
            if (soResponse.status == 200) {
                let soValuedata = soData.map((item) => {
                    let soValue = {
                        name: item,
                        value: item
                    }
                    return soValue
                })
                setSoList(soValuedata)
            }
        }
        catch (err) {
            setError(err)
        }
    }

    const getSODetailsForComp = async (param) => {
        console.log("insideSoDetails_")
        setIsUpdateButton(false)
        setParameterName("")
        setParamValue("")
        let payload = {
            "comp_id": companyId,
            "salesorderno": param || salesOrderNo
        }
        let soDetailsResponse = await getSODetails(payload)
        console.log("soDetailsResponse_", soDetailsResponse)
        if (soDetailsResponse.status == 200) {
            let rowData = soDetailsResponse.resData.data.data
            setSORowData(rowData)
        } else {
            // let rowData = soDetailsResponse.resData.data.data
            setSORowData([])
        }
    }
    const addSoDetails = async () => {
        let payload = {
            "companyid": companyId,
            "docid": 1,
            "salesorderno": salesOrderNo,
            "salesorderitem": "",
            "parametername": parameterName,
            "paramvalue": paramValue
        }
        try {
            let addResponse = await CreateSoDetails(payload)
            console.log("addResponse_", addResponse);
            if (addResponse.status == 200) {
                toast.success(`${parameterName} added successfully`)
                getSODetailsForComp()
            } else {
                toast.error(`${parameterName} was not added`)
            }
        }
        catch (err) {
            setError(err)
        }
    }

    const handleSoSubmit = async () => {
        setSoModalOpen(false)
        SubmitDocDetails()
    }

    const [masterCustomerList, setMasterCustomerList] = useState([])
    const [isSendMailBtn, setIsSendMailBtn] = useState(true)
    useEffect(() => {
        let isMainCompany = masterCustomerList.some(obj => obj.comp_id == companyId && obj.ismaincompany === 1)
        console.log("isMainCompany", isMainCompany)
        if (isMainCompany) {
            setIsSendMailBtn(false)
        }
        else {
            setIsSendMailBtn(true)
        }
    }, [companyId])

    const encrypt = (plaintext) => {
        console.log("encryptPlaintext", plaintext);
        // let encryptedPassword = CryptoJS.AES.encrypt(plaintext, process.env.REACT_APP_SECRET_KEY).toString();
        // return encryptedPassword

        return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(plaintext))
        .replace(/=+$/, '') // Remove padding
        .replace(/\+/g, '-') // Replace + with -
        .replace(/\//g, '_'); // Replace / with _
    };

    const getActiveCustomerList = async () => {
        console.log("userDetailsLog", userDetailsLog);
        let currentUserLoginEmail = encrypt(userDetailsLog.email)
        console.log("currentUserLoginEmail", currentUserLoginEmail);
        try {
            const response = await getActiveCustomer(currentUserLoginEmail);
            console.log("getActiveCustomer", response);
            if (response.status == 200) {
                let data = response.data.data
                setMasterCustomerList(data)
                let finalArr = []
                data.map((obj) => {
                    finalArr.push({
                        name: obj._customername,
                        value: obj.comp_id
                    })
                })
                setCustomerList(finalArr);
            }

        } catch (error) {
            console.log("error", error);
            setError(error)
        }
    };

    const handleCustomerName = (e) => {
        const { name, value } = e.target;
        let companyName = customerList.filter((item) => {
            if (item.value == value) {
                return name
            }
        })
        console.log("compName_", companyName,"valueN", value)
        setCompanyDetails({
            comp_name: companyName[0]?.name,
            comp_id: companyName[0]?.value
        })
        setCustomername(value)
        setCompanyId(value)
        setSoNo('')
        setDeliveryNo('')
        setDocData([])
        setRuleHide(false)
        setSoData([])
        setIsSoSelect(null)
    };


    //   useEffect(() => {
    //     if (customerName !== "" && docID !== "") {
    //       getCustomRulesList(customerName, docID)
    //     }
    //   }, [customerName, docID])

    useEffect(() => {
        console.log("rawDatadetails", rawData)
        if (rawData.length == 0 || rawData !== null || undefined !== rawData) {
            if (Object.keys(rawData).length > 0) {
                setIsRawDataModal(true)
                setIsRawDataTable(true)
            }
        }
    }, [rawData])

    useEffect(() => {
        console.log("convertDataDetails", convertData)
        if (convertData?.length > 0 || convertData !== null || undefined !== convertData) {
            console.log("convertDataDetails", convertData)
            if (Object.keys(convertData)?.length > 0) {
                setIsModalOpen(true)
                setIsRawDataTable(true)
            }
        }
    }, [convertData])

    const tabItemsConvertedData = [
        {
            key: "1",
            label: `Table`,
            children: <RawDataTable isRawDataTable={isRawDataTable} rawData={convertData} />,
        },
        {
            key: "2",
            label: `JSON`,
            children: convertData ? <JsonComp isJsonComp={isJsonComp} rawData={convertData} /> : null,
        },
    ]

    const tabItemsRawData = [
        {
            key: "1",
            label: `Table`,
            children: rawData ? <RawDataTable isRawDataTable={isRawDataTable} rawData={rawData} /> : null,
        },
        {
            key: "2",
            label: `JSON`,
            children: rawData ? <JsonComp isJsonComp={isJsonComp} rawData={rawData} /> : null,
        },
    ]

    const onChangeTabsConvertData = (key) => {
        console.log("onChangeTabsonChangeTabsConvertDataRawData", key);
        if (key == "1") {
            setIsJsonComp(false)
            setIsRawDataTable(true)
        }
        else if (key == "2") {
            setIsJsonComp(true)
            setIsRawDataTable(false)
        }
    }

    const onChangeTabsRawData = (key) => {
        console.log("onChangeTabsRawData", key);
        if (key == "1") {
            setIsJsonComp(false)
            setIsRawDataTable(true)
        }
        else if (key == "2") {
            setIsJsonComp(true)
            setIsRawDataTable(false)
        }
    }
    let gridApi;
    const onGridReady = (params) => {
        console.log("paramsApi_", params)
        gridApi = params.api;
    };

    const onSelectionChanged = () => {
        console.log("gridRef_", gridRef)
        const selectedRows = gridRef.current.api.getSelectedRows();
        setSelection(selectedRows)
        console.log("selectedRows_", selectedRows)
    }

    const handleFilter = () => {
        setIsFilter(!isFilter)
    }

    const handleSelectSO = (index, soNo, isChecked) => {
        soData.map((item, i) => {
            if (i == index) { item.isChecked = !item.isChecked }
            //    else{item.isChecked = false} 
        })
        console.log(index, "i==", soData)
        setSoData([...soData])
        // setSelectedSo(prevArray => {
        //     return [...prevArray, ...soNo];
        // })
        console.log("isChecked_", isChecked)
        if (isChecked == false || isChecked == undefined) {
            setSelectedSo([...selectedSo, soNo])
        } else if (isChecked == true) {
            let filteruncheck = selectedSo.filter((item) => item !== soNo)
            console.log("filteruncheck_", filteruncheck);
            setSelectedSo(filteruncheck)
        }

        let checkarray = []
        soData.map((item, i) => {
            if (item.isChecked == true) {
                console.log("insideIf_")
                checkarray.push(item)
            }
        })
        console.log("inside_", checkarray)
        let filteredRowData

        if (checkarray.length == 0) {
            setSelection([])
            return
        } else if (checkarray.length == 1) {
            console.log("checkarray_", checkarray, filteredRowData);
            filteredRowData = docData.filter(obj => {
                return obj.col19 == checkarray[0]?.col19
            })
            setSelection(filteredRowData)
            return
        }
        filteredRowData = docData.filter(obj => {
            return obj.col19 == soNo
        })

        setSelection(prevArray => {
            return [...prevArray, ...filteredRowData];
        });

    }

    const getDatawithid = (change, data) => {
        console.log("docData_", docData, data)
        if (docData.length > 0) {
            setIsSoSelect(change)
            let filteredRowData = docData.filter(obj => {
                return obj.col19 == data
            })
            console.log("filteredRowData_", filteredRowData)
            setSoRowData(filteredRowData)
            // setSelection(filteredRowData)
        }
    }

    const rowClassRules = {
        'even-row-so': (params) => params.node.rowIndex % 2 !== 0,
    };

    const openSendEmail = () => {
        if (selection.length === 0) {
            toast.error("No rows selected");
            return;
        }
        setIsSendEmail(true)
    }

    useEffect(() => {
        if (isSendEmail) {
            setOtherUsers("")
            setSubject("")
            setOtherUsersCC("")
            setOtherUsersBCC("")
            setEmailList([])
            DownloadFile()
        }
    }, [isSendEmail])
    const handleCancelSendEmail = () => {
        setIsSendEmail(false)
        // setMainCompanyUsers([])
    }

    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const validTypes = [
                'application/pdf',
                'text/csv',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'text/plain'
            ];
            if (validTypes.includes(selectedFile.type)) {
                setFile(selectedFile);
                setError('');
            } else {
                setError('Please upload a valid file type (PDF, CSV, Excel, or Text).');
            }
        }
    };

    const handleUpload = async () => {
        if (!file) {
            setError('Please select a file to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('attachment', file);

        try {
            const response = await sendFile(formData)
            console.log('sendFile', response);

        } catch (error) {
            console.error('Error uploading file:', error);
            setError('Failed to upload file.');
        }
    };

    const downloadFile = (filename) => {
        fetch(`http://localhost:5889/download/${filename}`)
            .then(response => {
                if (response.ok) {
                    return response.blob(); // Get the response as a Blob
                }
                throw new Error('Network response was not ok.');
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = filename; // Set the file name for download
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url); // Clean up
            })
            .catch(error => {
                console.error('Error downloading file:', error);
            });
    };

    return (
        <>
            <Loader loading={loading} />
            <Toaster position="top-center" reverseOrder={false} />
            <ErrorCatch err={error} />
            <div className="container-fluid">
                {/* <div className="row">
                    <p className="page-title">Document List</p>
                </div> */}
                {console.log("lenguth", statusList.length == 0 ? <>On</> : <></>)}
                <span className="bordertext p-2" onClick={handleFilter}>
                    Filter & Search
                </span>
                {isFilter &&
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card p-2">
                                <div className='row p-3'>
                                    {/* <div className='col-md-2 '>
                                    <SelectInput
                                        margin="0"
                                        selectLabel="Document Type"
                                        name="_documetkeys_id"
                                        value={docID}
                                        onChange={(e) => handleDocumentType(e)}
                                        optionsList={documentname}
                                    />
                                </div> */}
                                    {/* <div className="col-md-4 "> */}

                                    {/* <SelectInput
                                        margin="0"
                                        selectLabel="Parameter"
                                        name="docparam"
                                        value={docperaval}
                                        onChange={(e) => handleParameter(e)}
                                        optionsList={docParam}
                                    /> */}

                                    {/* <select class="form-select form-select-sm"
                                        name="docparam"
                                        style={{ fontSize: "12px" }}
                                        onChange={(e) => handleParameter(e)}>
                                        <option value={paramType.value} disabled selected hidden>{paramType.name}</option>

                                        {docParam.map((val) => {
                                            const { docparamname, docparamvalue } = val;
                                            return <option value={docparamvalue}>{docparamname}</option>;
                                        })}

                                    </select> */}
                                    {/* </div> */}
                                    <div className="col-md-2">
                                        <SelectInput
                                            margin="0"
                                            selectLabel="*Customer"
                                            name="_comp_id"
                                            value={customerName}
                                            onChange={(e) => handleCustomerName(e)}
                                            optionsList={customerList}
                                        />
                                    </div>
                                    <div className="col-md-2 ">
                                        <InputBox
                                            label="Sales Order No"
                                            name="sales_order_no"
                                            fullWidth
                                            onChange={(e) => Inputhandlechange(e)}
                                            value={soNo}
                                        />

                                        {/* <Input placeholder="Document Value" style={{ fontSize: "12px" }} name="doc_val" type="text" value={docVal.doc_val} onChange={(e) => Inputhandlechange(e)} /> */}

                                    </div>
                                    <div className="col-md-2 ">
                                        <InputBox
                                            label="Delivery No"
                                            name="delivery_no"
                                            fullWidth
                                            onChange={(e) => Inputhandlechange(e)}
                                            value={deliveryNo}
                                        />

                                        {/* <Input placeholder="Document Value" style={{ fontSize: "12px" }} name="doc_val" type="text" value={docVal.doc_val} onChange={(e) => Inputhandlechange(e)} /> */}

                                    </div>
                                    {/* <div className="col-md-2 ">
                                    <InputBox
                                        label="Delivery No"
                                        name="doc_val"
                                        fullWidth
                                        onChange={(e) => Inputhandlechange(e)}
                                        value={docVal.doc_val}
                                    />
                                </div> */}
                                    <div className="col-md-1">
                                        <CheckboxComp
                                            label="Date"
                                            isChecked={isSixMonthDate}
                                            onChange={handleSixMonthDate}
                                        />
                                    </div>

                                    <div className="col-md-2  a1 border-color: color: rgba(0, 0, 0, 0.6)">

                                        <SelectDate class="a1"
                                            fullWidth
                                            // style={{Color: rgb(0, 0, 0, 0.6)}}
                                            // defaultValue={today}
                                            label="From Date"
                                            // value={fromDate == "" ? "" : fromDate
                                            value={fromDate}
                                            maxDate={toDate}
                                            onChange={(value) => handleDateChange(value)}
                                            disabled={false}
                                        // onChange={handleDateChange}
                                        />
                                    </div>
                                    <div className="col-md-2  c1">
                                        <SelectDate className="a3"
                                            fullWidth
                                            style={{ Color: blue }}
                                            label="To Date"
                                            sx={{
                                                svg: { color: '#fff' },

                                            }}
                                            minDate={fromDate}
                                            maxDate={toDate}
                                            // value={toDate == "" ? "" : toDate}
                                            value={toDate}
                                            // onChange={handleToDateChange}
                                            onChange={(value) => handleToDateChange(value)}
                                            disabled={fromDate == "" ? true : false}
                                        />
                                    </div>
                                    <div className="col-md-1 p-1">
                                        {appObjects["Submit"] && <BasicButton
                                            className={'submitbtn'}
                                            text="Submit"
                                            onClick={SubmitDocDetails}
                                            disabled={customerName !== "" ? false : true}
                                        />}
                                    </div>
                                </div>

                                {/* <div className="row d-flex justify-content-end">
                                    <div className="col-md-1 p-1">
                                        {appObjects["Submit"] && <BasicButton
                                            className={'submitbtn'}
                                            text="Submit"
                                            onClick={SubmitDocDetails}
                                            disabled={customerName !== "" ? false : true}
                                        />}
                                    </div>
                                </div> */}

                                {/* </div> */}
                            </div>
                        </div>

                        {soModalOpen &&
                            <Modal title={"Customer Data"} open={soModalOpen} onOk={handleSoSubmit} onCancel={handleCancelSo} style={{ minWidth: 300, maxWidth: 750 }}>
                                <SelectInput
                                    margin="0"
                                    selectLabel="SO Number"
                                    name="so_No"
                                    disabled={(selection.length > 0) ? true : false}
                                    onChange={(e) => handleSalesOrder(e)}
                                    value={selection.length > 0 ? selection[0].col19 : salesOrderNo}
                                    optionsList={soList}
                                />
                                <div className="col-md-12 d-flex pt-2">
                                    <div className={isUpdateButton ? "col-md-4 px-0" : "col-md-5 pl-0"}>
                                        <InputBox
                                            name="parameter"
                                            value={parameterName}
                                            onChange={(e) => handleParamName(e)}
                                            // placeholder="Parameter Name"
                                            label="Name"
                                        />
                                    </div>
                                    <div className={isUpdateButton ? "col-md-4 px-2" : "col-md-5 px-3"}>
                                        <InputBox
                                            name="parameter"
                                            value={paramValue}
                                            onChange={(e) => handleParamValue(e)}
                                            // placeholder="Parameter Value"
                                            label="Value"
                                        />
                                    </div>
                                    <div className={isUpdateButton ? "col-md-4 pt-1" : "col-md-3 px-2 pt-1"}>
                                        {isUpdateButton ?
                                            <div className="d-flex p-0">
                                                <div className="px-1">
                                                    <BasicButton
                                                        text="Update"
                                                        onClick={handleUpdateParam}
                                                        className="buttonAdd"
                                                    />
                                                </div>
                                                <div>
                                                    <BasicButton
                                                        text="Cancel"
                                                        onClick={handleCloseUpdate}
                                                        className="buttonAdd"
                                                    />
                                                </div>
                                            </div>
                                            : <BasicButton
                                                text="Add"
                                                onClick={handleAddParam}
                                                className="buttonAdd"
                                            />}
                                    </div>
                                </div>
                                <div className="pt-2">
                                    <AgGridComponent
                                        columnDef={columnData}
                                        height={300}
                                        rowData={SORowData}
                                    />
                                </div>
                            </Modal>}


                    </div>}

                <div className="gridcontainer pt-4 pt-4 mt-4">
                    <div className=" user-access-grid-header">
                        <div className="d-flex justify-content-between">
                            <div className="mb-0 d-flex justify-content-center so-access-cell-data-header pl-3" style={{ borderRight: "0.5px solid rgb(153 153 153)", height: '42px' }}>
                                <label className="mb-0 pt-2">Sales Order</label>
                            </div>
                            <label className="mb-0 pt-2 so-access-cell-data-header">Delivery No</label>
                            <label className="vl mb-0 pt-2 so-access-cell-data-header">Total Weight (Kg)</label>
                            <label className="vl mb-0 pt-2 so-access-cell-data-header">Alloy</label>
                            <label className="vl mb-0 pt-2 so-access-cell-data-header">Length</label>
                        </div>
                    </div>
                    {soData.length > 0 &&
                        soData.map((obj, index) => {
                            return (<div className={index % 2 == 0 ? "mt-1 user-access-grid-border" : "mt-1 user-access-grid-border-UA"}>
                                <div className="d-flex justify-content-between">
                                    <div className="mb-0 d-flex so-access-cell-data pl-3" style={{ borderRight: "0.5px solid rgb(153 153 153)", height: '42px', paddingLeft: '5px' }}>
                                        <CheckboxComp className='mb-0 pl-5' isChecked={obj.isChecked} isLabelShow={false}
                                            onChange={() => handleSelectSO(index, obj.col19, obj.isChecked)} name="policyCopy" />
                                        {index == isSoSelect ?
                                            <img src={minus} onClick={() => getDatawithid(null, obj.col19)} style={{ height: '25px', width: '25px', cursor: 'pointer', marginTop: '8px' }} />
                                            : <img src={plus} onClick={() => getDatawithid(index, obj.col19)} style={{ height: '25px', width: '25px', cursor: 'pointer', marginTop: '8px' }} />}
                                        <label className="mb-0 pt-2">{obj?.col19.toString().substring(3)}</label>
                                    </div>
                                    <label className="mb-0 pt-2 so-access-cell-data v1">{obj.col39}</label>
                                    <label className="vl mb-0 pt-2 so-access-cell-data">{obj.totalWeight}</label>
                                    <label className="vl mb-0 pt-2 so-access-cell-data">{obj.col7}</label>
                                    <label className="vl mb-0 pt-2 so-access-cell-data">{obj.col43}</label>
                                </div>
                                {
                                    index == isSoSelect ?
                                        <div className="p-3">
                                            <AgGridComponent
                                                rowData={soRowData}
                                                columnDef={columns}
                                                height={350}
                                                onGridReady={onGridReady}
                                                onSelectionChanged={onSelectionChanged}
                                                gridRef={gridRef}
                                                rowSelection={"multiple"}
                                                rowClassRules={rowClassRules}
                                            />
                                        </div>
                                        : null
                                }
                            </div>
                            )
                        })}
                </div>

                {/* Table */}
                {/* <div className="col-md-12">
                    <div className="row mt-3">
                        <div className="col-md-12">
                            {docData && headerName?.length > 0 && docData?.length != 0 ?
                                // <Box sx={{
                                //     height: "47vh",
                                //     // width: "87vw", 
                                // }}>
                                //     <DataGrid
                                //         sx={{
                                //             borderColor: "secondary",
                                //             "& .MuiDataGrid-cell:hover": {
                                //                 color: "primary.main",
                                //             },
                                //             "& .MuiDataGrid-columnHeader": {
                                //                 backgroundColor: "#0E3386",
                                //                 fontSize: "12px",
                                //                 color: "white",
                                //                 padding: "10px",

                                //                 // Set your desired header background color
                                //             },
                                //             "& .MuiDataGrid-cell": {
                                //                 fontSize: "10px",
                                //                 padding: "10px",
                                //                 borderRight: "2px solid #e0e0e0",
                                //                 // Adjust the font size as needed
                                //                 // Adjust the cell padding as needed
                                //             },
                                //             '&.Mui-selected': {
                                //                 // backgroundColor: '#FF0000',
                                //                 // backgroundColor: '#0E3386',
                                //                 '&:hover': {
                                //                     backgroundColor: '#FF0000',
                                //                 },
                                //             },
                                //         }}

                                //         rows={docData}
                                //         disableRowSelectionOnClick
                                //         // disableColumnSelector
                                //         // disableColumnMenu

                                //         // disableMultipleRowSelection={true}
                                //         // checkboxSelection={true}
                                //         // handleChangeCheckbox
                                //         checkboxSelection
                                //         disableSelectionOnClick

                                //         // onCellClick={(params) => handleCellSelection(params)}
                                //         // getRowId={(docData) => docData.castid}
                                //         // getRowId={(docData) => docData.bundleno}
                                //         getRowId={(docData) => {
                                //             return docData.col1
                                //             // return docData.param_text == "col1"
                                //             // if (docID == 1) {
                                //             //     return docData.bundleno
                                //             //     // return docData.col1
                                //             // }
                                //             // else if (docID == 2) {
                                //             //     return docData.invoiceno
                                //             // }
                                //         }}
                                //         onRowSelectionModelChange={(newRowSelectionModel) =>
                                //             handleCheckBox(newRowSelectionModel)
                                //         }

                                //         // rowSelectionModel={selection}

                                //         rowHeight={30}
                                //         columns={columns}

                                //         hideFooter
                                //         disableMultipleColumnsSorting={true}
                                //         sortModel={[
                                //             { field: "action1", sort: "asc" },
                                //             { field: "action", sort: "asc" },
                                //         ]}
                                //     />
                                // </Box>
                                <AgGridComponent
                                    rowData={docData}
                                    columnDef={columns}
                                    height={350}
                                    onGridReady={onGridReady}
                                    onSelectionChanged={onSelectionChanged}
                                    gridRef={gridRef}
                                    rowSelection={"multiple"}
                                />
                                // find select document type                                
                                : headerName?.length == 0 ? <div className="mt-2"><p>No rows to show</p></div>
                                    : <div className="mt-2"><p>Select document type to populate table. </p></div>
                            }
                        </div>
                    </div>
                </div> */}

                {ruleHide &&
                    <>
                        {/* <div className="col-md-6"></div> */}
                        <div className="card rulecard col-md-12 mt-2">
                            <div className="row">
                                <div className="col-md-3">
                                    {appObjects["Select rule"] && <SelectInput
                                        margin="0"
                                        selectLabel="*Select rule"
                                        name="_rule_id"
                                        value={ruleType}
                                        disabled={(selection.length > 0) ? false : true}
                                        onChange={(e) => handleRuleType(e)}
                                        optionsList={ruleFormat}
                                    />}
                                </div>
                                <div className="col-md-2 d-flex justify-content-start">
                                    {appObjects["Apply Rule"] && <BasicButton
                                        text="Apply Rule"
                                        onClick={handleApplyRule}
                                        className={'applybtn'}
                                    />}
                                </div>
                                <div className="col-md-4 d-flex justify-content-end">
                                    {appObjects["PDF"] && appObjects["CSV"] && appObjects["Excel"] && appObjects["TXT"] &&
                                        <RadioButton
                                            optionsList={statusList}
                                            selectedValue={selectedValue}
                                            handleChange={(event) => handleRadioButton(event)}
                                        />}
                                </div>

                                <div className="col-md-3 gap-3 d-flex justify-content-start">
                                    <div className="d-flex">
                                        {/* {appObjects["Download"] && <button className="btn btn-light btn-sm"
                                        onClick={DownloadFile}
                                    ><FileDownloadRoundedIcon fontSize="medium" />
                                    </button>} */}

                                        {appObjects["Download"] &&
                                            <BasicButton
                                                text="Download"
                                                onClick={DownloadFile}
                                            //     // disabled={isSendMailBtn == false && selection.length === 1 ? false : true}
                                            // disabled={isSendMailBtn}
                                            />}
                                    </div>
                                    <div className="d-flex">
                                        {appObjects["Send Email"] && <BasicButton
                                            text="Send Email"
                                            onClick={openSendEmail}
                                            // disabled={isSendMailBtn == false && selection.length === 1 ? false : true}
                                            disabled={isSendMailBtn}
                                        />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

                {/* <Modal title="Converted data" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                    style={{ top: 20, minWidth: 300, maxWidth: 600 }}
                    footer={null}
                >
                    <hr />
                    <div
                        style={{ height: "70vh", overflowY: "auto" }}
                    >
                        <JsonViewer value={convertData} />
                    </div>
                </Modal> */}

                {/* <AppliedRulesHistoryPopup
                    docid={docID}
                    open={isAppliedRulesHistoryOpen}
                    // onOk=
                    onCancel={onAppliedRulesHistoryCancel}
                    // data={docData}
                    data={historyData}
                    pdf={generatePDF}
                    csv={handleConvertToCSV}
                    transform={transformForDownload}
                    excel={exportWorksheet}
                /> */}

            </div>
            {/* </div> */}

            <Modal title="Converted data" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                style={{ top: 20, minWidth: 600, maxWidth: 800 }}
                footer={null}
            >
                <hr />
                <div
                >
                    <TabsAntd
                        defaultActiveKey="1"
                        items={tabItemsConvertedData}
                        onChange={(key) => onChangeTabsConvertData(key)}
                        centered
                    />
                    {/* <JsonViewer value={rawData} /> */}
                </div>
            </Modal>

            <Modal title="Data" open={isRawDataModal} onOk={handleRawDataModalOk} onCancel={handleRawDataModalCancel}
                style={{ top: 20, minWidth: 600, maxWidth: 800 }}
                footer={null}
            >
                <hr />
                <div
                >
                    <TabsAntd
                        defaultActiveKey="1"
                        items={tabItemsRawData}
                        onChange={(key) => onChangeTabsRawData(key)}
                        centered
                    />
                    {/* <JsonViewer value={rawData} /> */}
                </div>
            </Modal>

            <Modal title={"File preview"} open={isSendEmail}
                // onOk={handleSoSubmit} 
                onCancel={handleCancelSendEmail}
                width={1000}
                height={600}
                // style={{ minWidth: 300, maxWidth: 1200 }}
                footer={null}
            >
                {pdfUrl ? <div className="row">

                    {/* <div className="col-md-5">
                        <div className="p-1">
                            <MultiSelectAntd
                                label="Email"
                                optionsList={mainCompanyUsers}
                                selectedOptions={retrieveEmailList}
                            />
                        </div>
                        <div className="p-1">
                            <BasicButton
                                text="Send"
                                onClick={sendDocsToEmailList}
                                disabled={emailList.length > 0 ? false : true}
                            />
                        </div>
                    </div> */}

                    <div className="col-md-12">
                        {/* <PdfPreviewer file={pdfUrl} /> */}
                        {/* <iframe
                            src={pdfUrl}
                            style={{ width: '100%', height: '600px' }}
                            title="PDF Preview"
                        ></iframe> */}

                        {/* <CsvPreviewer/> */}

                        {/* <TextPreviewer file={pdfUrl} /> */}
                        {/* <ExcelPreviewer file={pdfUrl}/> */}
                        <div>
                            <div className="p-2">
                                {/* <MultiSelectAntd
                                label="Email"
                                optionsList={mainCompanyUsers}
                                selectedOptions={retrieveEmailList}
                            /> */}
                                <InputBox
                                    label="*Email"
                                    // name=""
                                    fullWidth
                                    onChange={handleOtherUsers}
                                    value={otherUsers}
                                />
                            </div>
                            <div className="p-2">
                                <InputBox
                                    label="Subject"
                                    // name=""
                                    fullWidth
                                    onChange={handleSubject}
                                    value={subject}
                                />
                            </div>
                            <div className="p-2">
                                <InputBox
                                    label="CC"
                                    fullWidth
                                    onChange={handleOtherUsersCC}
                                    value={otherUsersCC}
                                />
                            </div>
                            <div className="p-2">
                                <InputBox
                                    label="BCC"
                                    fullWidth
                                    onChange={handleOtherUsersBCC}
                                    value={otherUsersBCC}
                                />
                            </div>
                            <div className="p-2">
                                <BasicButton
                                    text="Send"
                                    onClick={sendDocsToEmailList}
                                // disabled={emailList.length > 0 ? false : true}
                                />
                            </div>
                        </div>
                        <div className="mt-2 p-2"
                            style={{ border: "1px solid black", padding: "2px" }}
                        >
                            <FilePreviewer
                                file={pdfUrl}
                                selectedValue={selectedValue}
                            />
                        </div>

                    </div>
                </div>
                    :
                    <div></div>
                }
            </Modal>

            {/* <div>
                <div>
                    <h2>Upload File</h2>
                    <input type="file" onChange={handleFileChange} />
                    <button onClick={handleUpload}>Upload</button>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </div>
            </div> */}

        </>
    )
}

export default AdminDoc;