import { Tabs } from "antd";
import DisplayActiveAPIList from "../../../Components/Pages/CustomerAdmin/API/DisplayActiveAPIList";
import SetRules from "../CustomerAdmin/Document/Rules";
import AllDocumentList from "../CustomerAdmin/Document/DocumentList";
import { useState, useEffect } from "react";
import NewDoc from "../CustomerAdmin/Document/NewDoc";
import TabsAntd from "../../Reusable_Components/TabsAntd";
import { useSelector } from "react-redux";

const SubAdminServices = () => {
  const [tab, setTab] = useState("1")
  const [activeAPIList, setActiveAPIList] = useState(false)
  const [allDocumentList, setAllDocumentList] = useState(false)
  const [rulesList, setRulesList] = useState(false)
  const [newDoc, setNewDoc] = useState(false)
  const [customRules, setCustomRules] = useState(false)


  // uam role object start
  const userDetails = useSelector((state) => state)
  const appsubmodulename = "Services"
  // const appfunctionalityname = "Customer Feedback"
  const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
  const [appObjects, setAppObjects] = useState(
    {
      "APIs": false,
      "Rules": false,
      "Document": false,
    }
  )
  console.log("userDetails", userDetails)

  const updateAppObjects = (arr) => {
    let objectsArray = []
    arr.map((obj) => {
      console.log("updateButtons", obj)
      if (obj.objecttypename == "tab") {
        objectsArray.push(obj)
      }
    })
    const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
      acc[appobjectname] = true;
      return acc;
    }, {});
    setAppObjects({ ...appObjects, ...finalObject })
    console.log("objectsArray", objectsArray)
    console.log("finalObject", finalObject)
  }

  useEffect(() => {
    console.log("appObjects", appObjects)
  }, [appObjects])

  useEffect(() => {
    console.log("userRoleObjects", userRoleObjects)
    const filteredList = []
    userRoleObjects.filter((obj) => {
      if (obj.appsubmodulename == appsubmodulename) {
        filteredList.push(obj)
      }
    })

    console.log("filteredList", filteredList)
    updateAppObjects(filteredList)

  }, [])

  // uam role object end

  const onChange = (key) => {
    console.log("onChangetab", key);
    setTab(key)
    if (key == "1") {
      setActiveAPIList(true)
      setAllDocumentList(false)
      setRulesList(false)
      setCustomRules(false)
      setNewDoc(false)
    }
    else if (key == "2") {
      setActiveAPIList(false)
      setAllDocumentList(true)
      setRulesList(false)
      setCustomRules(false)
      setNewDoc(false)
    }
    else if (key == "3") {
      setActiveAPIList(false)
      setAllDocumentList(false)
      setRulesList(true)
      setCustomRules(false)
      setNewDoc(false)
    }
    else if (key == "4") {
      setActiveAPIList(false)
      setAllDocumentList(false)
      setRulesList(false)
      // setCustomRules(true)
      setNewDoc(true)
    }
  };
  const items = [
    {
      key: "1",
      label: `Document List`,
      children: appObjects["Document"] ? <NewDoc newDoc={newDoc} /> : <div><h3>No Access Provided</h3> </div>,

    },
    {
      key: "2",
      label: `API's`,
      children: appObjects["APIs"] ? <DisplayActiveAPIList activeAPIList={activeAPIList} /> : <div><h3>No Access Provided</h3> </div>,
    },

    {
      key: "3",
      label: `Rules`,
      children: appObjects["Rules"] ? <SetRules rulesList={rulesList} /> : <div><h3>No Access Provided</h3> </div>,
    },

    // {
    //   key: "2",
    //   label: `Document`,
    //   children: <AllDocumentList allDocumentList={allDocumentList} />,
    // },


    // {
    //   key: "4",
    //   label: `Details`,
    //   children: <SetRules rulesList={rulesList} />,
    // },
  ];
  return (
    <>
      <div className="mt-1">
        <h6 className="px-3">
          {/* <b className="page-title">Services</b> */}
          {/* <Tabs
            defaultActiveKey="1"
            items={items}
            // accessKey={tab}
            // // type="card"
            onChange={(key) => onChange(key)}
            centered
            style={{
              width: "100%",
              height: "80vh",
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: '500',
              fontSize: '14px',
              color: '#646464',
            }}
          /> */}

          <TabsAntd
            defaultActiveKey="1"
            items={items}
            onChange={(key) => onChange(key)}
            centered
          />
        </h6>


      </div>

    </>
  );
};

export default SubAdminServices;
