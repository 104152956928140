import React from 'react';
import { Select } from 'antd';

const onChange = (value: string) => {
  console.log(`selected ${value}`);
};

const onSearch = (value: string) => {
  console.log('search:', value);
};

const SelectInputAntd = (
    {
        placeholder,onChange,optionsList,disabled,id,value,mode
    }
) => (
  <Select
    showSearch
    mode={mode}
    disabled={disabled}
    style={{ width: '100%'}}
    id={id}
    value={value == "" ? placeholder : value}
    placeholder={placeholder}
    optionFilterProp="children"
    onChange={onChange}
    // onSearch={onSearch}
    filterOption={(input, option) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    }
    options={optionsList.length > 0 && optionsList.map((item)=>({
        label: item.label,
        value: item.value,
    }))}
  />
);

export default SelectInputAntd