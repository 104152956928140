import { Tabs } from "antd";
import { useState, useEffect } from "react";
import AllApiRequestlist from "../Admin/APIRequestList"
import DocumentList from "./Documents/RequestedDocumentList"
import RequestedRulesList from '../Admin/Rules/RequestedRulesList'
import AdminSetRules from "./Rules/AdminCreateRules";
import NewItems from "./AddNewItems/NewItems";
import CustomRulesSuperAdmin from "./CustomRulesSuperAdmin/CustomRulesSuperAdmin";
import TabsAntd from "../../Reusable_Components/TabsAntd";
import AdminDoc from "./AdminDoc";
import { useSelector } from "react-redux";

const Services = () => {
  const [apiList, setApiList] = useState(false)
  const [allDocumentList, setAllDocumentList] = useState(false)
  const [rulesList, setRulesList] = useState(false)
  const [createRulesList, setCreateRulesList] = useState(false)
  const [newItems, setNewItems] = useState(false)
  const [customRules, setCustomRules] = useState(false)
  const [isAdminDoc, setIsAdminDoc] = useState(false)

  // uam role object start
  const userDetails = useSelector((state) => state)
  const appsubmodulename = "Services"
  // const appfunctionalityname = "Customer Feedback"
  const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
  const [appObjects, setAppObjects] = useState(
    {
      "APIs": false,
      "Rules": false,
      "Register": false,
      "Document": false,
    }
  )
  console.log("userDetails", userDetails)

  const updateAppObjects = (arr) => {
    let objectsArray = []
    arr.map((obj) => {
      console.log("updateButtons", obj)
      if (obj.objecttypename == "tab") {
        objectsArray.push(obj)
      }
    })
    const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
      acc[appobjectname] = true;
      return acc;
    }, {});
    setAppObjects({ ...appObjects, ...finalObject })
    console.log("objectsArray", objectsArray)
    console.log("finalObject", finalObject)
  }

  useEffect(() => {
    console.log("appObjects", appObjects)
  }, [appObjects])

  useEffect(() => {
    console.log("userRoleObjects", userRoleObjects)
    const filteredList = []
    userRoleObjects.filter((obj) => {
      if (obj.appsubmodulename == appsubmodulename) {
        filteredList.push(obj)
      }
    })

    console.log("filteredList", filteredList)
    updateAppObjects(filteredList)

  }, [])

  // uam role object end

  const onChange = (key) => {
    console.log("onChangetab", key);
    if (key == "1") {
      setApiList(true)
      setAllDocumentList(false)
      setRulesList(false)
      setCreateRulesList(false)
      setNewItems(false)
      setCustomRules(false)
      setIsAdminDoc(false)
    }
    else if (key == "2") {
      setApiList(false)
      setAllDocumentList(true)
      setRulesList(false)
      setCreateRulesList(false)
      setNewItems(false)
      setCustomRules(false)
      setIsAdminDoc(false)
    }
    else if (key == "3") {
      setApiList(false)
      setAllDocumentList(false)
      setRulesList(true)
      setCreateRulesList(false)
      setNewItems(false)
      setCustomRules(false)
      setIsAdminDoc(false)
    }
    else if (key == "4") {
      setApiList(false)
      setAllDocumentList(false)
      setRulesList(false)
      setCreateRulesList(true)
      setNewItems(false)
      setCustomRules(false)
      setIsAdminDoc(false)
    }
    else if (key == "5") {
      setApiList(false)
      setAllDocumentList(false)
      setRulesList(false)
      setCreateRulesList(false)
      setNewItems(true)
      setCustomRules(false)
      setIsAdminDoc(false)
    }
    else if (key == "6") {
      setApiList(false)
      setAllDocumentList(false)
      setRulesList(false)
      setCreateRulesList(false)
      setNewItems(false)
      setCustomRules(true)
      setIsAdminDoc(false)
    }
    else if (key == "7") {
      setApiList(false)
      setAllDocumentList(false)
      setRulesList(false)
      setCreateRulesList(false)
      setNewItems(false)
      setCustomRules(false)
      setIsAdminDoc(true)
    }
  };
  const items = [
    {
      key: "1",
      label: `API's `,
      children:  appObjects["APIs"] ? <AllApiRequestlist apiList={apiList} /> : <div><h3>No Access Provided</h3> </div>
    },
    // {
    //   key: "2",
    //   label: `Documents`,
    //   children: <DocumentList allDocumentList={allDocumentList} />,
    // },
    // {
    //   key: "3",
    //   label: `Rules`,
    //   children: <RequestedRulesList rulesList={rulesList}/>,
    // },
    {
      key: "4",
      label: `Rules`,
      children: appObjects["Rules"] ? <AdminSetRules createRulesList={createRulesList} /> : <div><h3>No Access Provided</h3> </div>,
    },
    // {
    //   key: "5",
    //   label: `Items`,
    //   children: <NewItems newItems={newItems} />,
    // },
    {
      key: "6",
      label: `Register`,
      children: appObjects["Register"] ? <CustomRulesSuperAdmin customRules={customRules} /> : <div><h3>No Access Provided</h3> </div>,
    },
    {
      key: "7",
      label: `Document`,
      children: appObjects["Document"] ? <AdminDoc isAdminDoc={isAdminDoc} /> : <div><h3>No Access Provided</h3> </div>,
    },
  ];
  return (
    <>
      <div className="container-fluid">
        {/* <h6 className="px-3 mt-3">
          <b className="mt-3 page-title">Services</b>
        </h6> */}
        <div className="row mt-2">
          <div className="col d-flex justify-content-center">
            {/* <Tabs
              defaultActiveKey="1"
              items={items}
              centered
              onChange={(key) => onChange(key)}
              style={{
                width: "100%",
                backgroundColor: "white",
                padding: "5px",
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: '500',
                fontSize: '14px',
              }}
            /> */}

            <TabsAntd
              defaultActiveKey="7"
              items={items}
              onChange={(key) => onChange(key)}
              centered
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
