import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';


const AgGridComponent = (props) => {
  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([
    { make: "Tesla", model: "Model Y", price: 64950, electric: true,_status_id:'Active' },
    { make: "Ford", model: "F-Series", price: 33850, electric: false,_status_id:'InActive' },
    { make: "Toyota", model: "Corolla", price: 29600, electric: false,_status_id:'Pending' },
  ]);

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([
    { field: "make" },
    { field: "model" },
    { field: "price" },
    { field: "electric" }
  ]);

  return (
    <div
      className="ag-theme-quartz" // applying the grid theme
      style={{ height: props.height ? props.height : 500 }} // the grid will fill the size of the parent container
    >
      <AgGridReact
        // icons={{
        //   menu: '<i class="fa fa-filter fa-size" aria-hidden="true">'
        // }}
        rowData={props.rowData}
        columnDefs={props.columnDef}
        defaultColDef={{ filter: true, sortable: true }}
        rowSelection={false}
        rowClassRules={props.rowClassRules}
        onGridReady={props.onGridReady}
        onSelectionChanged={props.onSelectionChanged}
        ref={props.gridRef}
        suppressMenuHide={true}
        enableCellTextSelection={true}
      // rowHeight={24}
      />
    </div>
  )
}

export default AgGridComponent;