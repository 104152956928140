import React, { useEffect, useState, useRef } from "react";
import { DatePicker } from 'antd';
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import toast, { Toaster } from "react-hot-toast";
import { jsPDF } from 'jspdf';
import { JsonViewer } from "@textea/json-viewer";
import 'jspdf-autotable';
import axios from "axios";
import LOGO from '../../../../Assets/Images/alba_logo.png'
import { BsDownload } from 'react-icons/bs';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { RiInformationLine } from 'react-icons/ri';
import { AiFillFile } from 'react-icons/ai';
import { BsFiletypeRaw } from "react-icons/bs";
import Draggable from 'react-draggable';
import { DraggableModal, DraggableModalProvider } from 'ant-design-draggable-modal'
// import 'antd/dist/antd.css'
import 'ant-design-draggable-modal/dist/index.css'
import { Input, Select } from 'antd';
import env from "../../../../Services/Environment";
import { DataGrid } from "@mui/x-data-grid";
// import { makeStyles } from '@mui/styles';
import File from '../../../../Assets/Images/tabler_file-filled.png'
import Box from '@mui/material/Box';
import { CreateSoDetails, applyRuleToDoc, applyRuleToDocAWS, deleteSoDetail, getBundleHistory, getCOAHistory, getDocByParameter, getDocumentName, getInvoiceHistory, getParamColDetails, getParameterDocSearch, getRules, getSODetails, getSoCustomerDetails, getSoNoforCompany, updateSoDetails, insertLogs } from "../../../../Services/AdminServices";
import { Radio } from 'antd';
import { Modal } from 'antd';
import Loader from "../../../../utils/Loader/Loader";
import { JsonToExcel } from "react-json-to-excel";
import * as XLSX from "xlsx";
import SelectInput from "../../../Reusable_Components/SelectInput";
import InputBox from "../../../Reusable_Components/InputBox";
import moment from "moment/moment";
import SelectDate from "../../../Reusable_Components/DatePicker";
import AppliedRulesHistoryPopup from "./AppliedRulesHistoryPopup";
import { selectionData, selectionData2 } from "./dataForDownload";
import _, { filter } from "lodash";
import { messages } from "../../../Messages";
import RadioButton from "../../../Reusable_Components/RadioButton";
import Column from "antd/es/table/Column";
import BasicButton from "../../../Reusable_Components/Button";
import { blue } from "@material-ui/core/colors";
import AgGridComponent from "../../../Reusable_Components/AgGridComponent";
import { FaEdit } from 'react-icons/fa';
import { BsPlus, BsFillTrashFill } from 'react-icons/bs';
import { IoMdCloseCircleOutline } from "react-icons/io";
import docIcon from "../../../../Assets/Images/solar_document.png"
import ErrorCatch from "../../../Reusable_Components/ErrorCatch";
import RawDataTable from "../../Admin/RawDataTable";
import JsonComp from "../../Admin/JsonComp";
import TabsAntd from "../../../Reusable_Components/TabsAntd";
import CheckboxComp from "../../../Reusable_Components/CheckBox";
import plus from "../../../../Assets/Images/arrow_closed.svg";
import minus from "../../../../Assets/Images/arrow_open.svg";
import { useSelector } from "react-redux";


// export const fileNameGeneration = (arr) => {
//     console.log("selectionFileGen", arr)
//     let fileName
//     if (arr.length == 1) {
//         if ("col1" in arr[0]) {
//             // fileName = "COA_" + arr[0].castid
//             // fileName = "COA_" + arr[0].bundleno
//             fileName = "Doc_" + arr[0].col1
//         }
//     }
//     else if (arr.length > 1) {
//         // if ("castid" in arr[0]) {
//         //     fileName = "COA_" + dayjs().format("DD-MM-YYYY")
//         // }
//         if ("col1" in arr[0]) {
//             fileName = "Doc_" + dayjs().format("DD-MM-YYYY")
//         }
//     }
//     console.log("fileName", fileName)
//     return fileName
// }


const NewDoc = ({ newDoc }) => {
    const gridRef = useRef();
    const [documentname, setDocumentName] = useState([])
    const [docParam, setDocParam] = useState([])
    const [docperaval, setDocperaVal] = useState("")
    const [docData, setDocData] = useState([])

    const [docResponse, setDocResponse] = useState([])
    const [ruleHide, setRuleHide] = useState(false);

    const [docID, setDocID] = useState(1)
    const [docTypeID, setDocTypeID] = useState(docID)
    const [columns, setColumns] = useState([]);
    const [ruleFormat, setRuleFormat] = useState([])
    const [selection, setSelection] = useState([])
    const [radioSelection, setRadioSelection] = useState(3)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRawDataModal, setIsRawDataModal] = useState(false);
    const [headerName, setHeaderName] = useState([]);
    const [convertData, setConvertData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [dataForExcel, setDataForExcel] = useState([])
    const [appliedRuleData, setAppliedRuleData] = useState([])
    const [afterConverstionData, setAfterConverstionData] = useState([])
    const [ruleType, setRuleType] = useState({
        name: "Select a Rule Type",
        value: ""
    })

    // const [ruleType, setRuleType] = useState("")
    const [ruleType1, setRuleType1] = useState(ruleFormat[0]);
    const [docVal, setDocVal] = useState({
        name: "",
        value: ""
    })
    const [docType, setDocType] = useState({
        name: "Doc Type",
        value: ""
    })

    const [paramType, setParamType] = useState({
        name: "Doc parameter",
        value: ""
    })

    const today = dayjs();
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [checkDate, setCheckDate] = useState("")
    const [isPageLoad, setIsPageLoad] = useState(false)

    // const [formattedFromDate, setFormattedFromDate] = useState("31-7-2023")
    // const [formattedToDate, setFormattedToDate] = useState("31-7-2023")

    const [formattedFromDate, setFormattedFromDate] = useState("")
    const [formattedToDate, setFormattedToDate] = useState("")
    const [isSixMonthDate, setIsSixMonthDate] = useState(true)
    const [selectedValue, setSelectedValue] = useState(2);
    const [statusList, setStatusList] = useState([
        {
            label: "PDF",
            value: 1,
            status: false
        },
        {
            label: "CSV",
            value: 2,
            status: true
        },
        {
            label: "Excel",
            value: 3,
            status: false
        },
        {
            label: "Text",
            value: 4,
            status: false
        }
    ])

    // sales order popup
    const [soModalOpen, setSoModalOpen] = useState(false);
    const [soList, setSoList] = useState([])
    const [salesOrderNo, setSalesOrderNo] = useState("")
    const [parameterName, setParameterName] = useState("")
    const [paramValue, setParamValue] = useState("")
    const [isUpdateButton, setIsUpdateButton] = useState(false)
    const [SORowData, setSORowData] = useState([])

    const [companyId, setCompanyId] = useState(localStorage.getItem('comp_id'))
    const [rowId, setRowId] = useState()
    const [customerData, setCustomerData] = useState([])

    const [rawData, setRawData] = useState([])
    const [parameterColsData, setParameterColsData] = useState([])
    const [isRawDataTable, setIsRawDataTable] = useState(false)
    const [isJsonComp, setIsJsonComp] = useState(false)
    const [isFilter, setIsFilter] = useState(true)

    const [soNo, setSoNo] = useState('')
    const [deliveryNo, setDeliveryNo] = useState('')
    const [castNo, setCastNo] = useState('')

    const [soData, setSoData] = useState([])
    const [isSoSelect, setIsSoSelect] = useState(null)
    const [soRowData, setSoRowData] = useState([])

    const [selectedSo, setSelectedSo] = useState([])

    // uam role object start
    const userDetails = useSelector((state) => state)
    const appsubmodulename = "Services"
    const appfunctionalityname = "Document"
    const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
    const [appObjects, setAppObjects] = useState(
        {
            "Submit": false,
            "Apply Rule": false,
            "Download": false,
            "PDF": false,
            "CSV": false,
            "Excel": false,
            "TXT": false,
            "Select rule": false
        }
    )
    const [roleType, setRoleType] = useState(localStorage.getItem('userRole')) //customer-user
    const [defaultRule, setDefaultRule] = useState()

    const userDetailsLog = userDetails.auth.userDetails


    console.log("userDetails", userDetails)

    const updateAppObjects = (arr) => {
        let objectsArray = []
        arr.map((obj) => {
            console.log("updateButtons", obj)
            // if (obj.objecttypename == "button") {
            objectsArray.push(obj)
            // }
        })
        const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
            acc[appobjectname] = true;
            return acc;
        }, {});
        setAppObjects({ ...appObjects, ...finalObject })
        console.log("objectsArray", objectsArray)
        console.log("finalObject", finalObject)
    }

    useEffect(() => {
        console.log("appObjects", appObjects)
    }, [appObjects])

    useEffect(() => {
        console.log("userRoleObjects", userRoleObjects)
        const filteredList = []
        userRoleObjects.filter((obj) => {
            if (obj.appsubmodulename == appsubmodulename && obj.appfunctionalityname == appfunctionalityname) {
                filteredList.push(obj)
            }
        })

        console.log("filteredList", filteredList)
        updateAppObjects(filteredList)

    }, [])

    // uam role object end

    const handleSixMonthDate = (e) => {
        let value = e.target.checked
        setIsSixMonthDate(value)
        if (value == false) {
            setFormattedFromDate("2016-01-01")
        }
        else if (value == true) {
            defaultDate()
        }
    }

    useEffect(() => {
        console.log("parameterColsData", parameterColsData)
    }, [parameterColsData])

    useEffect(() => {
        getParameterCols()
    }, [docID])

    useEffect(() => {
        console.log("appliedRuleData", appliedRuleData)
    }, [appliedRuleData])

    let gridApi;

    const onGridReady = (params) => {
        console.log("paramsApi_", params)
        gridApi = params.api;
    };

    // AppliedRulesHistoryPopup start

    const [isAppliedRulesHistoryOpen, setIsAppliedRulesHistoryOpen] = useState(false)
    const [historyData, setHistoryData] = useState([])
    const dummyHistoryData = {
        HistoryData: [
            {
                analysis_history_id: '101',
                castid: 'T8506351',
                dropid: '1',
                si: '0.46',
                fe: '0.20',
                cu: '0.0',
                mn: '0.04',
                mg: '0.37',
                cr: '0.0',
                zn: '0.01',
                ti: '0.016',
                othe: '0.00',
                T_OT: '0.04',
                al: '98.86',
                conversion_data: {
                    id: 'T8506351',
                    cast_id: 'T8506351',
                    document_type: 'COA',
                    COA: [Array],
                    saleorder: '1111'
                },
                ruleid: null,
                createdbyid: null,
                createdat: "2023 -08 - 22T03: 48: 23.734Z",
                versionno: '1.00',
                historycreatedat: "2023 -08 - 24T07:01:00.205Z"
            },
            {

                analysis_history_id: '102',
                castid: 'T8506351',
                dropid: '1',
                si: '0.46',
                fe: '0.20',
                cu: '0.0',
                mn: '0.04',
                mg: '0.37',
                cr: '0.0',
                zn: '0.01',
                ti: '0.016',
                othe: '0.00',
                T_OT: '0.04',
                al: '98.86     ',
                conversion_data: {
                    id: 'T8506351',
                    cast_id: 'T8506351',
                    document_type: 'COA',
                    COA: [Array],
                    salesorder: '9876'
                },
                ruleid: null,
                createdbyid: null,
                createdat: "2023-08-22T03:48:23.734Z",
                versionno: '2.00',
                historycreatedat: "2023-08-24T07:01:37.929Z"

            },
            {
                analysis_history_id: '103',
                castid: 'T8506351',
                dropid: '1',
                si: '0.46',
                fe: '0.20',
                cu: '0.0',
                mn: '0.04',
                mg: '0.37',
                cr: '0.0',
                zn: '0.01',
                ti: '0.016',
                othe: '0.00',
                T_OT: '0.04',
                al: '98.86',
                conversion_data: {
                    id: 'T8506351',
                    cast_id: 'T8506351',
                    document_type: 'COA',
                    COA: [Array],
                    salesorder: '9999',
                    'PO NO': '1001'

                },
                ruleid: 433,
                createdbyid: 467,
                createdat: "2023-08-22T03:48:23.734Z",
                versionno: '3.00',
                email: "alhamid@rapidqube.com",
                historycreatedat: "2023-08-25T01:38:23.971Z"

            }

        ]
    }

    const fileNameGeneration = (arr) => {
        console.log("selectionFileGen", arr)
        let docName = documentname.filter((obj) => {
            let nm
            if (obj.value == docTypeID) {
                return obj.name
            }
        })[0].name
        console.log("docName", docName)
        let fileName
        if (arr.length == 1) {
            if ("col1" in arr[0]) {
                // fileName = "COA_" + arr[0].castid
                // fileName = "COA_" + arr[0].bundleno
                fileName = `${docName}_` + arr[0].col1
            }
        }
        else if (arr.length > 1) {
            // if ("castid" in arr[0]) {
            //     fileName = "COA_" + dayjs().format("DD-MM-YYYY")
            // }
            // if ("col1" in arr[0]) {
            //     fileName = "Doc_" + dayjs().format("DD-MM-YYYY")
            // }
            fileName = `${docName}_` + dayjs().format("DD-MM-YYYY")
        }
        console.log("fileName", fileName)
        return fileName
    }

    const handleRadioButton = (event) => {
        console.log("handleradio", event.target)
        let value = event.target.value
        console.log("item", statusList, value)

        let updatedStatusList = statusList.map((item) => {
            if (item.value == value) {
                item.status = true
            }
            else {
                item.status = false
            }
            return item
        })
        console.log("updatedStatusList", updatedStatusList)
        setSelectedValue(value)
        setStatusList(updatedStatusList)
    }

    const showAppliedRulesHistoryPopup = async (params) => {
        console.log("showAppliedRulesHistoryPopupparams", params, docTypeID)
        setLoading(true)
        let sendData

        if (docID == 1) {
            sendData = {
                castid: params.data.col2,
                bundleno: params.data.col1
            }
            try {
                // let response = await getCOAHistory(`${params.id}`)
                let response = await getBundleHistory(sendData)
                console.log("response", response)
                if (response.status == 200) {
                    setIsAppliedRulesHistoryOpen(true)
                    setHistoryData(response.data.data)
                }
                else {
                    toast.error(messages.noDataFound)
                }
            }
            catch (error) {
                // toast.error(error.message)
                setError(error)
            }
        }
        else if (docID == 2) {
            sendData = {
                invoiceno: params.data.col1
            }
            try {
                let response = await getInvoiceHistory(sendData)
                console.log("response", response)
                if (response.status == 200) {
                    setIsAppliedRulesHistoryOpen(true)
                    setHistoryData(response.data.data)
                }
                else {
                    toast.error(messages.noDataFound)
                }
            }
            catch (error) {
                // toast.error(error.message)
                setError(error)
            }
        }
        else {
            console.log("documid", params.data.col1)
            toast.error(messages.noDataFound)
        }

        console.log("dummyHistoryData", dummyHistoryData)
        console.log("sendData", sendData)
        // setHistoryData(dummyHistoryData.HistoryData)
        setLoading(false)
    }

    const onAppliedRulesHistoryCancel = () => {
        setIsAppliedRulesHistoryOpen(false)
    }
    // AppliedRulesHistoryPopup end



    const columnFunctions = (params) => {

        if ("invoiceno" in params.data) {
            toast.error(messages.noDataFound)
            return
        }
        if (params.colDef.field == "File") {
            showModal(params)
        }
        else if (params.colDef.field == "Raw data") {
            showJsonRawData(params)
        }
        else if (params.colDef.field == "History") {
            showAppliedRulesHistoryPopup(params)
        }
    }

    const processForRawData = async (documentData, colsData) => {
        setLoading(true)
        console.log("processForRawData", documentData, documentname);
        console.log("colsData", colsData);
        let paramData = documentData
        let soNo = documentData[0].col19
        let finalArr = []
        paramData.map(item => {
            delete item.col20
            finalArr.push(item)
        })
        console.log("finalArr", finalArr);
        let generalColumn = generalisedColumn(finalArr, colsData)
        console.log("generalColumn", generalColumn);
        let customerSoDetails = await getSoCustomerData(soNo)
        let customerData = []
        console.log("customerSoDetails", customerSoDetails)
        if (customerSoDetails.length > 0) {
            customerData = customerSoDetails
        }
        let rawDataDetails = {
            header_keys: [
                { key: "id", value: documentData[0].col1 },
                { key: "document_type", value: documentname.filter(obj => obj.value == docID)[0].name },
            ],
            document_keys: generalColumn,
            customer_Data: customerData
        }
        console.log("rawDataDetails", rawDataDetails)
        setRawData(rawDataDetails)
        setLoading(false)
    }

    const handleCheckBox = (newSelectionModel) => {
        console.log("newSelectionModel==>", newSelectionModel, docData);
        let finalArr = []
        docData.map(item => {
            newSelectionModel.filter(obj => {
                // if (obj == item.castid) {
                //     finalArr.push(item)
                // }
                // if (docID == 1) {
                //     if (obj == item.col1) {
                //         finalArr.push(item)
                //     }
                // }
                if (obj == item.col1) {
                    finalArr.push(item)
                }
            })
        })
        console.log("newSelectionModel_finalarr", finalArr)
        setSelection(finalArr)
        // setSelectionModel(newSelectionModel);
    };


    useEffect(() => {
        if (selection.length !== 0 && docData.length !== 0) {
            console.log("docData__", docData)
            let finalArr = []
            docData.map(item => {
                selection.filter(obj => {
                    if (obj.col1 == item.col1) {
                        finalArr.push(item)
                    }
                    // if (obj.castid == item.castid) {
                    //     finalArr.push(item)
                    // }
                    // if (docID == 1) {
                    //     if (obj.col1 == item.col1) {
                    //         finalArr.push(item)
                    //     }
                    // }
                    // if (docID == 1) {
                    //     if (obj.bundleno == item.bundleno) {
                    //         finalArr.push(item)
                    //     }
                    // }
                    // else if (docID == 2) {
                    //     if (obj.invoiceno == item.invoiceno) {
                    //         finalArr.push(item)
                    //     }
                    // }
                })
            })
            console.log("newSelectionModel_finalarr", finalArr)
            setSelection(finalArr)
        }
    }, [docData]);

    useEffect(() => {
        setDocData([])
        setSoData([])
        setRuleHide(false)
        setCustomerData([])
        setSelection([])
    }, [docID, docperaval, docVal, fromDate, toDate]);

    const handleCellSelection = (value) => {
        console.log("handleCellSelectionValue", value, selection)
        // if (undefined !== selection) {
        if (Object.keys(selection).length > 0 && selection.constructor === Object) {
            if (value.row.castid == selection.castid) {
                setSelection([])
            }
        }
        else {
            setSelection(value.row)
        }
        // setSelection(value.row)
    }

    useEffect(() => {
        if (newDoc == true) {
            console.log("ruleformatnewdoc", ruleFormat)
            // getRuleType()
            // defaultDate()
            subtractMonth()
            setRuleHide(false)
            // setRuleType
            setDocID(1)
            setDocTypeID(1)
            setDocperaVal("")
            setDocVal({ name: "", value: "" })

            // setFromDate("")
            // setToDate("")
            // setFormattedFromDate("31-7-2023")
            // setFormattedToDate("31-7-2023")

            setCheckDate("")
            setRuleType("")
            setDocData([])
            setSoData([])
            setSelectedSo([])
            setCustomerData([])
            setSelection([])
            setSelectedValue(2)
            setStatusList([
                {
                    label: "PDF",
                    value: 1,
                    status: false
                },
                {
                    label: "CSV",
                    value: 2,
                    status: true
                },
                {
                    label: "Excel",
                    value: 3,
                    status: false
                },
                {
                    label: "Text",
                    value: 4,
                    status: false
                }
            ])
            // setRuleType({
            //     name: "Select a Rule Type",
            //     value: ""
            // })

        }
    }, [newDoc])

    useEffect(() => {
        getRuleType()
    }, [docID])

    useEffect(() => {
        console.log("selection", selection)
    }, [selection])

    useEffect(() => {
        // defaultDate()
        subtractMonth()
        setSelectedSo([])
        // setFromDate("")
        // setToDate("")
        // setFormattedFromDate("31-7-2023")
        // setFormattedToDate("31-7-2023")
        setRadioSelection(3)
        setSelectedValue(2)
        setStatusList([
            {
                label: "PDF",
                value: 1,
                status: false
            },
            {
                label: "CSV",
                value: 2,
                status: true
            },
            {
                label: "Excel",
                value: 3,
                status: false
            },
            {
                label: "Text",
                value: 4,
                status: false
            }
        ])
    }, [])


    const dataForExcel2 = [
        { "cast HouseNo": 660, customerCastNumber: "r41321a", bu: 580, speiraPONumber: 4559485721, trialNumber: "V236", netSlabWeight: 10708, IngotNumber: "r4131a12", castNo: 41321 },
        { "cast HouseNo": 660, customerCastNumber: "r41321a", bu: 580, speiraPONumber: 4559485721, trialNumber: "V236", netSlabWeight: 10708, IngotNumber: "r4131a41", castNo: 41321 },
        { "cast HouseNo": 660, customerCastNumber: "r41321a", bu: 580, speiraPONumber: 4559485721, trialNumber: "V236", netSlabWeight: 10708, IngotNumber: "r4131a42", castNo: 41321 },
        { "cast HouseNo": 660, customerCastNumber: "r41321a", bu: 580, speiraPONumber: 4559485721, trialNumber: "V236", netSlabWeight: 10708, IngotNumber: "r4131a21", castNo: 41321 },
        { "cast HouseNo": 660, customerCastNumber: "r41321a", bu: 580, speiraPONumber: 4559485721, trialNumber: "V236", netSlabWeight: 10708, IngotNumber: "r4131a22", castNo: 41321 },
        { "cast HouseNo": 660, customerCastNumber: "r41321a", bu: 580, speiraPONumber: 4559485721, trialNumber: "V236", netSlabWeight: 10708, IngotNumber: "r4131a31", castNo: 41321 },
        { "cast HouseNo": 660, customerCastNumber: "r41321a", bu: 580, speiraPONumber: 4559485721, trialNumber: "V236", netSlabWeight: 10708, IngotNumber: "r4131a32", castNo: 41321 },
        { "cast HouseNo": 660, customerCastNumber: "r41321a", bu: 580, speiraPONumber: 4559485721, trialNumber: "V236", netSlabWeight: 10708, IngotNumber: "r4131a51", castNo: 41321 },
    ]

    useEffect(() => {
        console.log("convertdataupdate", convertData)
    }, [convertData])

    const showModal = async (params) => {

        console.log("params", params, convertData, formattedFromDate, formattedToDate)
        // if(convertData.length == 0 || convertData == null || undefined == convertData){
        //     toast.error("No data to show")
        //     return
        // }
        setError(error)
        try {
            let getDocDetails = await SubmitDocDetails(1)
            console.log("docData=>", docResponse, getDocDetails)

            let fullData = getDocDetails.fullData
            let filterArr = fullData.filter(item => item.col1 == params.data.col1)
            console.log("ModalfilterArr", filterArr)
            filterArr = filterArr[0]

            if ("col20" in filterArr) {
                filterArr = JSON.parse(filterArr.col20)
                console.log("filterArr", filterArr)
                if (filterArr !== null) {
                    setConvertData(filterArr)
                } else {
                    setConvertData([])
                }
            }

            else {
                setConvertData([])
            }

            setIsModalOpen(true);
        }
        catch (error) {
            setError(error)
        }
    };

    const showJsonRawData = async (params) => {
        console.log("showJsonRawDataParams", params)
        console.log("parameterColsData", parameterColsData)
        let rowDetails = [params.data]
        processForRawData(rowDetails, parameterColsData)
    }

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleRawDataModalOk = () => {
        setIsRawDataModal(false);
    };

    const handleRawDataModalCancel = () => {
        setIsRawDataModal(false);
    };

    let actionColumns = [

        {
            field: "file",
            headerName: "File",
            // width: "150px",
            flex: 0.2,
            renderCell: (params) => {
                console.log("convewrtateddata ", params)
                return (
                    <div className="cellAction">
                        <a class="btn" onClick={() => showModal(params.row)}>
                            <img src={File} alt="fileicon" style={{ width: '0.8rem' }} />
                        </a>
                    </div>
                );
            }
        },
        {
            field: "history",
            // find history here
            headerName: "History",
            // width: "150px",
            flex: 0.2,
            renderCell: (params) => {
                console.log("convewrtateddata ", params)
                return (
                    <div className="cellAction">
                        <a class="btn" onClick={() => showModal(params.row)}>
                            <img src={File} alt="fileicon" style={{ width: '0.8rem' }} />
                        </a>
                    </div>
                );
            }
        }
    ]

    //     useEffect(()=>{
    // setFormattedFromDate("")
    // setFormattedToDate("")
    //     },[docperaval])

    const subtractMonth = () => {
        let sixMonthsBefore = new Date()
        sixMonthsBefore.setMonth(sixMonthsBefore.getMonth() - 6)
        let day = sixMonthsBefore.getDate().toString().padStart(2, '0')
        let month = (sixMonthsBefore.getMonth() + 1).toString().padStart(2, '0')
        let year = sixMonthsBefore.getFullYear()
        let defaultToDate = `${year}-${month}-${day}`
        setFormattedFromDate(defaultToDate)
        setFromDate(defaultToDate)
        defaultDate(defaultToDate)
        console.log("subtractMonth", sixMonthsBefore, defaultToDate)
        // return sixMonthsBefore
    }

    useEffect(() => {
        console.log("pageload",formattedFromDate,formattedFromDate,isPageLoad,isSixMonthDate )
        if (formattedFromDate && formattedToDate && isPageLoad && isSixMonthDate) {
            console.log("pageload_inside_if")
            SubmitDocDetails(0, formattedToDate, formattedFromDate)
        }
    }, [formattedFromDate, formattedToDate])

    const defaultDate = (fromDate) => {
        let todaysDate = new Date()
        let day = todaysDate.getDate().toString().padStart(2, '0')
        let month = (todaysDate.getMonth() + 1).toString().padStart(2, '0')
        let year = todaysDate.getFullYear()
        let defaultFromDate = `${year}-${month}-${day}`
        console.log("defaultDate", todaysDate, defaultFromDate)
        setFormattedToDate(defaultFromDate)
        setToDate(defaultFromDate)
        setIsPageLoad(true)
        // SubmitDocDetails(0,fromDate,defaultFromDate)
        // return finalDate
    }

    const handleDateChange = (dates, datestring) => {
        // console.log("dates", dates, dates.$d.toLocaleDateString(), fromDate)
        console.log("dates", dates, dates.$d, fromDate)
        console.log("thedate", dates.target.value)
        // let date1 = dates.$d.toDateString()
        // let date1 = dates.$d.toLocaleDateString()
        setIsPageLoad(false)
        let day, month, year
        day = dates.$D
        month = dates.$M + 1
        year = dates.$y
        // let formattedDate = day + "-" + month + "-" + year
        // let formattedDate = `${day}-${month}-${year}`
        let formattedDate = dates.target.value
        console.log("formattedDate", formattedDate, typeof (formattedDate))
        setFormattedFromDate(formattedDate)
        setFromDate(formattedDate)
        let fDate = new Date(formattedDate)
        let tDate = new Date(formattedToDate)
        console.log("fdatetdate", fDate, tDate)
        if (fDate >= tDate) {
            console.log("thefDate", fDate)
            setFormattedToDate(formattedDate)
            setToDate(formattedDate)
        }

        // setFormattedToDate(formattedDate)

        // setCheckDate(dates.$d)
        // let theDate = moment(date1).format("DD-MM-YYYY")
        // console.log("thedate", theDate)

        // const newDate = new Date(dates.$d)
        // const newDate = new Date(dates.$x)

        // const date = newDate.getDate();
        // const month = newDate.getMonth() + 1;
        // const year = newDate.getFullYear();
        // setFromDate(`${date}-${month}-${year}`)
        // setFromDate(moment(datestring))
        // setFromDate(theDate)
        // setFromDate(formattedDate)
        // setFromDate(dates)
        // setToDate(dates)

        // setToDate(formattedDate)
    };
    const DesableDate = (current) => {
        return current && current.valueOf() < checkDate
    }


    const handleToDateChange = (dates, datestring) => {
        console.log("dates", dates)
        // let theDate = moment(dates.$d).format("DD-MM-YYYY")
        // console.log("thedate", theDate)
        // const todates = new Date(dates.$d)
        // const date = todates.getDate();
        // const month = todates.getMonth() + 1;
        // const year = todates.getFullYear();
        // console.log("date", date+"/"+month+"/"+year)
        // setToDate(`${date}-${month}-${year}`)
        setIsPageLoad(false)
        let day, month, year
        day = dates.$D
        month = dates.$M + 1
        year = dates.$y
        // let formattedDate = `${day}-${month}-${year}`
        let formattedDate = dates.target.value
        setFormattedToDate(formattedDate)
        // setToDate(dates)
        setToDate(formattedDate)


        // if (new Date(fromDate) > new Date(toDate)) {
        //     setToDate("")
        // }

    };


    // const convertData = convertData.find(item=>item.key === 'conversion_data')?.value;


    //Document Name
    const handleDocumentType = (e) => {
        const { name, value } = e.target;
        console.log()
        setDocID(value)
    }
    console.log("log", docID)
    //Document perameter value
    const handleParameter = (e) => {
        const { name, value } = e.target;
        setDocperaVal(value)
        console.log("log1", docperaval)
    }
    console.log("log1", docperaval)
    //Doc Value
    const Inputhandlechange = (e) => {
        const { name, value } = e.target;
        console.log("value", name, value)
        if (name == 'sales_order_no') {
            setSoNo(value)
            setDeliveryNo('')
            setCastNo('')
        } else if (name == 'delivery_no') {
            setDeliveryNo(value)
            setSoNo('')
            setCastNo('')
        } else if (name == 'cast_no') {
            setCastNo(value)
            setSoNo('')
            setDeliveryNo('')
        }
        // setDocVal({ ...docVal, name: name,value:value })
    }
    //Rule Type
    const handleRuleType = (e) => {
        console.log("handleruletypeevent", e.target)
        const { name, value } = e.target;
        // setInputData({ ...inputData, [name]: value });
        setRuleType(value)
    };
    // const handleRuleType1 = (e) => {
    //     console.log("handleruletypeevent", e.target)
    //     const { name, value } = e.target;
    //     // setInputData({ ...inputData, [name]: value });
    //     setRuleType1(value)
    // };

    const getAPIFormat2 = async () => {
        setLoading(true)
        try {
            const response = await getDocumentName();
            console.log("getAPIFormat2", response);
            if (response.status == 200) {
                let data = response.data.data
                let finalArr = []
                data.map((obj) => {
                    finalArr.push({
                        "name": obj._docname,
                        "value": obj._document_id
                    })
                })
                setDocumentName(finalArr);
            }
        }
        catch (error) {
            console.log("getAPIFormat2error", error);
            setError(error)
        }
        setLoading(false)
    };

    const GetDocParameter = async () => {
        try {
            console.log("resgetdocparameter", docID)
            // start of change
            const res = await getParameterDocSearch(docID)
            // end of change
            console.log("resgetdocparameter", res)
            if (res.status == 200) {
                let data = res.data.data
                console.log("data", data)
                let finalArr = []
                data.map((obj) => {
                    finalArr.push({
                        name: obj.docparamname,
                        value: obj.docparamvalue
                    })
                })
                setDocParam(finalArr)
            }
        }
        catch (error) {
            console.log(error);
            setError(error)
        }
    }
    console.log("final", docParam)

    useEffect(() => {
        console.log("docTypeID", docTypeID)
    }, [docTypeID])

    const generalisedColumn = (searchData, columnDetails) => {
        console.log("searchData", searchData)
        console.log("columnDetails", columnDetails)
        const columnMap = {};
        columnDetails.forEach(item => {
            columnMap[item.colnames] = item.paramnames;
        });

        // Iterate through array1 and transform the data
        const result = [];
        const transformedData = searchData.map(item => {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    const columnName = key;
                    const value = item[key];
                    if (columnMap[columnName]) {
                        result.push({
                            key: columnMap[columnName],
                            value: value
                        });
                    }
                }
            }

        })
        console.log("result", result)
        return result;
    }

    const getParameterCols = async () => {
        setLoading(true)
        try {
            const response = await getParamColDetails(docID);
            setLoading(false)
            console.log("getParameterColsResponse", response)
            // if (response.status == 200) {
            let finalData = response.data.data
            console.log("finalData", finalData)
            setParameterColsData(finalData)
            return finalData
            // }
        } catch (error) {
            console.log("error", error)
        }
        setLoading(false)
    }

    const getSoCustomerData = async (soNoParam) => {
        console.log("soNoParam", soNoParam)
        try {
            let response = await getSoCustomerDetails(docID, soNoParam, companyId)
            console.log("getSoCustomerDataResponse", response)
            let finalData = response.data.data.filter(obj => obj.key !== '')
            return finalData
        }
        catch (err) {
            console.log("err", err)
            setError(err)
        }
    }

    const SubmitDocDetails = async (castId = 0, fromDate, toDate) => {
        console.log("doctypeid", docID, docTypeID)
        console.log("concatParams_", deliveryNo, castNo, soNo);
        setIsSoSelect(null)
        setSelectedSo([])
        setSelection([])
        let concatParamValue = "";
        let concatParamName = "";
        if (castId == 1) {
            setIsSoSelect((prev)=>{return prev})
        } else {
            setIsSoSelect(null)
        }

        // if (soNo !== '' && deliveryNo !== '' && castNo !== '') {
        //     concatParamName = 'soNo' + ':' + 'deliveryNo' + ':' + 'castNo'
        //     concatParamValue = soNo + ':' + deliveryNo + ':' + castNo
        // } else if (soNo !== '' && deliveryNo !== '') {
        //     concatParamName = 'soNo' + ':' + 'deliveryNo'
        //     concatParamValue = soNo + ':' + deliveryNo
        // } else if (soNo !== '' && castNo !== ''){
        //     concatParamName = 'soNo' + ':' + 'castNo'
        //     concatParamValue = soNo + ':' + castNo
        // }else if (deliveryNo !== '' && castNo !== '') {
        //     concatParamName = 'deliveryNo' + ':' + 'castNo'
        //     concatParamValue = deliveryNo + ':' + castNo
        // } else {
        concatParamName = (soNo !== '') && 'soNo' || (deliveryNo !== '') && 'deliveryNo' || (castNo !== '') && 'castNo'
        concatParamValue = soNo || deliveryNo || castNo
        // }
        console.log("concatParam_", concatParamName, formattedFromDate, fromDate, formattedToDate, toDate);
       
        if(formattedFromDate == ""){
            toast.error("Please select From Date")
            return
        }
        if(formattedToDate == ""){
            toast.error("Please select To Date")
            return
        }
        try {
            setDocTypeID(docID)
            let sendData = {
                // "docId": docID == "" ? 1 : docID,
                "docId": docID,
                "paramvalue": concatParamValue,
                "paramname": concatParamName,
                // "fromDate": fromDate,
                // "toDate": toDate
                "fromDate": formattedFromDate || fromDate,
                "toDate": formattedToDate || toDate,
                "email": localStorage.getItem("Logindata"),
                // "fromDate": "31-7-2023",
                // "toDate": "31-7-2023"

            }

            console.log("docparametersendData", sendData)
            setLoading(true);
            let response = await getDocByParameter(sendData)
            console.log("getDocByParameterresponse", response)
            if (response.resData.status !== 200) {
                toast.error("No Data Found");
                setDocData([]);
                setSoData([])
                setCustomerData([])
                setDocResponse([])
                setDocResponse([])
                setHeaderName([])
                setRuleHide(false)
                setLoading(false);
                setSelectedSo([])
                setSelection([])
                // setSoNo('')
                // setCastNo('')
                // setDeliveryNo('')
                return
            }
            response = response.resData
            let sum = 0
            let assignedSODetails = response.data.fullData.map((item, i) => {
                sum += parseInt(item.col3)
                console.log("assignedSODetails_", selectedSo)


                // if (isRefresh) {
                //     if (selectedSo.length !== 0) {
                //         selectedSo.map((obj) => {
                //             if (obj == item.col19) {
                //                 item['isChecked'] =true
                //             }
                //         })
                //     }
                // }else{
                item['isChecked'] = false
                // }
                item['totalWeight'] = sum
                return item
            });

            console.log("assignedSODetails__", assignedSODetails)
            // arr1.filter(item => arr2.includes(item));

            let filteredRowData = response.data.fullData.filter(item => selectedSo.includes(item.col19))
            // let lastSo = selectedSo.map((item, i) => {
            //     return item
            // })
            //         console.log("lastSo",selectedSo)
            //     return obj.col19 == selectedSo
            // })
            // setSelection(filteredRowData)
            // console.log("filteredRowData_", filteredRowData)

            // setSelection(prevArray => {
            //     return [...prevArray, ...filteredRowData];
            // });
            // let uniqAssignedPolicyDetails = _.uniqBy(assignedSODetails, 'salesorderno');
            // const uniqueArray = [...new Set(assignedSODetails)];
            const uniqueArray = Array.from(assignedSODetails.reduce((map, obj) => map.set(obj.col19, obj), new Map()).values());
            setSoData(uniqueArray)
            setDocData(response.data.fullData);
            setCustomerData(response.data.customerData)
            setRuleHide(true)
            setDocResponse([])
            // setSoNo('')
            // setCastNo('')
            // setDeliveryNo('')
            setHeaderName(response.data.data1)
            // toast.success(response.data.msg);
            GetingRowandheaders(response.data.data1)
            setTimeout(() => {
                setLoading(false);
            }, 1000);

            if (castId !== 0) {
                return response.data
            }
            return filteredRowData
        }
        catch (error) {
            setError(error)
        }
        setLoading(false)
    }


    const GetingRowandheaders = (headerName) => {
        console.log("row values", headerName)
        // let headers = [{
        //     field: "checkbox",
        //     headerName: "",
        //     // width: "150px",
        //     flex: 0.3,
        // }];

        // headers.map((item) => {
        //     header
        // })
        // headers = Object.keys(headerName)

        let dataGridColumns = headerName?.map((header, i) => ({
            // field: header.docparamvalue,
            // checkboxSelection: header.paramid == 1 ? true : false,
            // headerCheckboxSelection: header.paramid == 1 ? true : false,
            id: header.paramid,
            field: header.param_text,
            headerName: header.docparamname.charAt(0).toUpperCase() + header.docparamname.slice(1),
            // width: 150,
            flex: 0.4,
        }))

        let Arr = [
            { label: "File", icon: (<AiFillFile />), function: { showModal } },
            // { label: "Raw data", icon: (<BsFiletypeRaw />), function: { showJsonRawData } },
            { label: "History", icon: (<RiInformationLine />), function: { showAppliedRulesHistoryPopup } },]
        Arr.map(item => {
            dataGridColumns?.push(
                ({
                    field: item.label,
                    headerName: item.label,
                    // width: "150px",
                    flex: 0.3,
                    cellRenderer: (params) => {
                        // console.log("convewrtateddata ", params)
                        return (
                            <div className="cellAction">
                                <a class="btn" onClick={() => columnFunctions(params)}>
                                    {/* <img src={File} alt="fileicon" style={{ width: '0.8rem' }} /> */}
                                    {/* __icon comes here */}
                                    {item.icon}
                                </a>
                            </div>
                        );
                    }
                }
                )
            )
        })

        dataGridColumns.sort((a, b) => a.id - b.id)
        // console.log("colums aarha hai?", dataGridColumns)
        // __data comes for table from here
        setColumns(dataGridColumns); // Set the dynamic columns
        console.log("__column", columns)
    }

    // useEffect(() => { }, [columns])

    const OnRadioChange = (e) => {
        console.log('radio checked', e.target.value);
        setRadioSelection(e.target.value)

    };

    useEffect(() => {
        setRadioSelection(3)
        // setSelectedValue(1)
        // setStatusList([
        //     {
        //         label: "PDF",
        //         value: 1,
        //         status: true
        //     },
        //     {
        //         label: "CSV",
        //         value: 2,
        //         status: false
        //     },
        //     {
        //         label: "Excel",
        //         value: 3,
        //         status: false
        //     }
        // ])
    }, [docData])



    // export const fileNameGeneration = (arr) => {
    //     console.log("selectionFileGen", arr)
    //     let fileName
    //     if (arr.length == 1) {
    //         if ("castid" in arr[0]) {
    //             fileName = "COA_" + arr[0].castid
    //         }
    //     }
    //     else if (arr.length > 1) {
    //         if ("castid" in arr[0]) {
    //             fileName = "COA_" + dayjs().format("DD-MM-YYYY")
    //         }
    //     }
    //     console.log("fileName", fileName)
    //     return fileName
    // }

    // const equals = (array1, array2) => JSON.stringify(array1) === JSON.stringify(array2);

    // const DownloadFile = () => {
    //     console.log("selection", selection, "ruletype", ruleType)
    //     if (selection.length == 0) {
    //         toast.error("No rows selected")
    //         return
    //     }
    //     // let downloadData = selection[0]["conversion_data"]
    //     let finalDownloadData = []
    //     // let selectedCastID = selection.map((obj) => obj.castid)
    //     // let selectedBundleNo = selection.map((obj) => obj.bundleno)
    //     let selectedItem = selection.map((obj) => obj.col1)
    //     // console.log("selectedCastID", selectedCastID)
    //     console.log("selectedItem", selectedItem)
    //     // console.log("selectedBundleNo", selectedBundleNo)
    //     // let downloadData = docResponse.filter((item, i) => {

    //     //     if (item.castid == selectedCastID[i]) {
    //     //         return finalDownloadData.push(item)
    //     //     }

    //     // })
    //     // downloadData = JSON.parse(downloadData)
    //     console.log("selection_conversion", selection, docResponse)
    //     // console.log("downloadData", downloadData, finalDownloadData)
    //     let conversionData = selection.map((obj) => obj.conversion_data)
    //     let convertedSelectedData = []
    //     // selection.forEach((obj) => convertedSelectedData.push(JSON.parse(obj.conversion_data)))
    //     selection.forEach((obj) => convertedSelectedData.push(JSON.parse(obj.col20)))
    //     let convertedKeys = []
    //     convertedSelectedData.forEach((obj) => {
    //         convertedKeys.push(Object.keys(obj))
    //     })
    //     let matchingKeys = []
    //     if (convertedKeys.length > 1) {
    //         for (let i = 1; i < convertedKeys.length; i++) {
    //             matchingKeys.push(equals(convertedKeys[0], convertedKeys[i]))
    //         }
    //         if (matchingKeys.includes(false)) {
    //             toast.error("No matching keys found")
    //             return
    //         }
    //     }

    //     console.log("convertedSelectedData", convertedSelectedData, convertedKeys, matchingKeys)

    //     // if (undefined == ruleType.value || ruleType.value == "") {
    //     //     toast.error("No rules selected")
    //     //     return
    //     // 

    //     // if (undefined == ruleType || ruleType == "") {
    //     //     toast.error("No rules selected")
    //     //     return
    //     // }

    //     if (selectedValue == 1) {
    //         console.log("convertdata_selection", convertedSelectedData, selection)
    //         // return
    //         if (convertedSelectedData.length == 1) {
    //             generatePDF(convertedSelectedData, fileNameGeneration(selection))
    //         }
    //         else {
    //             let forExcel = transformForDownload(convertedSelectedData)
    //             shouldUseA3(forExcel)
    //             // convertExcelToPDF(forExcel[0].analysisDataArrayForExcel, fileNameGeneration(selection))
    //         }
    //     }
    //     else if (selectedValue == 2) {
    //         handleConvertToCSV(convertedSelectedData, fileNameGeneration(selection))
    //     }
    //     else if (selectedValue == 3) {
    //         console.log("dataForExcel", dataForExcel)

    //         // let analysisData = dataForExcel.filter((obj)=> Array.isArray(obj) == true)
    //         // let forExcel = transformForDownload(dataForExcel)
    //         let forExcel = transformForDownload(convertedSelectedData)
    //         console.log("forExcel", forExcel, Object.keys(forExcel[0].analysisDataArrayForExcel[0]), Object.values(forExcel[0].analysisDataArrayForExcel[0]))
    //         // console.log("selectionData",selectionData2)
    //         // handleConvertToExcel()

    //         // if (undefined == dataForExcel || dataForExcel.length == 0) {
    //         //     toast.error("No data available for download")
    //         //     return
    //         // }
    //         // exportWorksheet(dataForExcel, "sample-excel")
    //         exportWorksheet(forExcel[0].analysisDataArrayForExcel, fileNameGeneration(selection))
    //     }
    // }

    // for new header keys & customerData start
    const equals = (array1, array2) => JSON.stringify(array1) === JSON.stringify(array2);

    const insertLogsForUser = async (params) => {
        console.log("userDetailsLog_", userDetailsLog, selectedSo, selectedSo.toString());
        let payload = {
            userId: userDetailsLog.user_id,
            userName: userDetailsLog.name,
            email: userDetailsLog.email,
            compId: userDetailsLog.comp_id,
            compName: userDetailsLog.comp_name,
            actionType: "Download",
            soNo: selectedSo.toString(),
            fileType: params,
            docType: "COA"
        }
        console.log("userDetailsLog_", payload);
        let logresponse = await insertLogs(payload)
        console.log("logresponse_", logresponse);
    }

    const DownloadFile = async () => {
        console.log("selection", selection,selectedSo,soData, "ruletype", ruleType);

        if (selection.length === 0) {
            toast.error("No rows selected");
            return;
        }

        let ApplyRule = []
        //     if (ruleFormat.length == 1) {
        //         ApplyRule =await handleApplyRule()
        //    }

        // Extracting 'col20' data and parsing JSON
        let convertedSelectedData = [];
        let parsedObj
        let firstKeys = [];
        let headerkeys = [];
        
        console.log("roleType_", roleType);

        for (let i = 0; i < selection.length; i++) {
            if (roleType == "customer-user") {
                console.log("insideif_2", selection.length == i + 1)
                if (selection.length == i + 1) {
                    console.log("insideif_3")
                    ApplyRule = await handleApplyRule()
                    console.log("ApplyRule_", ApplyRule)
                }
            } else {
                if (selection[i].col20 == null &&  selectedSo.length == 1) {
                    toast.error("Kindly apply rule and download it")
                    return
                }
                parsedObj = JSON.parse(selection[i].col20);
                convertedSelectedData.push(parsedObj);
            }
        }

        if (ApplyRule.length > 0) {
            console.log("insideif_4")
            for (let i = 0; i < ApplyRule.length; i++) {
                // parsedObj = JSON.parse(ApplyRule[i].col20);
                console.log("parsedObj_",parsedObj)
                convertedSelectedData.push(ApplyRule[i]);
            }
        }

        console.log("convertedSelectedData", convertedSelectedData);

        // Check if all objects in 'convertedSelectedData' have the same keys in 'document_keys'

        if (convertedSelectedData.length > 0 && (convertedSelectedData[0]?.document_keys || convertedSelectedData[0]?.header_keys)) {
            firstKeys = convertedSelectedData[0]?.document_keys.map((keyObj) => keyObj.key);
            headerkeys = convertedSelectedData[0]?.header_keys.map((keyObj) => keyObj.key)
        }

        for (let i = 1; i < convertedSelectedData.length; i++) {
            const currentKeys = convertedSelectedData[i]?.document_keys.map((keyObj) => keyObj.key);
            const headerKeycheck = convertedSelectedData[i]?.header_keys.map((keyObj) => keyObj.key);
            if (!equals(firstKeys, currentKeys) || !equals(headerkeys, headerKeycheck)) {
                // toast.error("No matching keys found");
                toast.error("Kindly apply same rule and download it")
                return;
            }
        }

        // If we reached here, all objects have the same keys in 'document_keys'
        console.log("All objects have matching keys in 'document_keys'");

        console.log("convertedSelectedData", convertedSelectedData, firstKeys)

        if (selectedValue == 1) {
            console.log("convertdata_selection", convertedSelectedData, selection)
            // return
            if (convertedSelectedData.length == 1) {
                generatePDF(convertedSelectedData, fileNameGeneration(selection))
            }
            else {
                let forExcel = transformForDownload(convertedSelectedData)
                shouldUseA3(forExcel)
                // convertExcelToPDF(forExcel[0].analysisDataArrayForExcel, fileNameGeneration(selection))
            }
            insertLogsForUser("PDF")
        }
        else if (selectedValue == 2) {
            handleConvertToCSV(convertedSelectedData, fileNameGeneration(selection))
            insertLogsForUser("CSV")
        }
        else if (selectedValue == 3) {
            console.log("dataForExcel", dataForExcel)
            let forExcel = transformForDownload(convertedSelectedData)
            console.log("forExcel", forExcel, Object.keys(forExcel[0].analysisDataArrayForExcel[0]), Object.values(forExcel[0].analysisDataArrayForExcel[0]))
            exportWorksheet(forExcel[0].analysisDataArrayForExcel, fileNameGeneration(selection))
            insertLogsForUser("Excel")
        }
        else if (selectedValue == 4) {
            handleConvertToText(convertedSelectedData, fileNameGeneration(selection))
            insertLogsForUser("Text")
        }

        // Further processing logic here...

        // Example: Output the first object's 'document_keys' for reference
        if (convertedSelectedData.length > 0) {
            const firstDocumentKeys = convertedSelectedData[0].document_keys;
            console.log("First object document_keys:");
            firstDocumentKeys.forEach((keyObj) => {
                console.log(`${keyObj.key}: ${keyObj.value}`);
            });
        }
    };

    function transformForDownload(arr) {
        console.log("transformForDownloadarr", arr)
        let ObjectKeys, analysisData
        let theObject
        let finalArr = []
        let COA = []
        let docHeader = []
        let customHeader = []
        let finalArr2 = []
        let newObj = {}
        let finalObj = {}
        let finalObj2 = {}
        let finalArr3 = []
        let headerAndKeys = []
        let finalObj4 = {}
        let finalArr4 = []
        let finalObj5 = {}
        let finalArr5 = []
        for (let i = 0; i < arr.length; i++) {
            theObject = arr[i]

            ObjectKeys = Object.keys(theObject)
            console.log("ObjectKeys", theObject, ObjectKeys)
            analysisData = ObjectKeys.filter((item) => {
                if (Array.isArray(theObject[item]) == true) {
                    return item
                }
            })

            // rawData.header_keys start

            // for (let j = 0; j < analysisData.length; j++) {
            //     finalArr = theObject[analysisData[j]]
            //     headerAndKeys = headerAndKeys.concat(finalArr)
            //     console.log("h_k", theObject, analysisData[j], finalArr, headerAndKeys)
            // }

            let docKeys = []
            let ruleKeys = []
            let customData = []

            if (theObject.hasOwnProperty("header_keys")) {
                docKeys = theObject["header_keys"]
            }

            if (theObject.hasOwnProperty("document_keys")) {
                ruleKeys = theObject["document_keys"]
            }

            if (theObject.hasOwnProperty("customer_Data")) {
                customData = theObject["customer_Data"]
            }

            headerAndKeys = docKeys.concat(ruleKeys)
            headerAndKeys = headerAndKeys.concat(customData)
            console.log("docKeys", docKeys, "ruleKeys", ruleKeys,)
            console.log("headerAndKeys", headerAndKeys)

            headerAndKeys.forEach((obj) => {
                newObj[obj.key] = obj.value
            })

            finalArr = theObject["document_keys"]
            COA = finalArr
            docHeader = theObject["header_keys"]
            customHeader = theObject["customer_Data"]
            finalObj4 = { ...newObj }
            finalArr4.push(finalObj4)
            finalObj5 = {
                "docHeader": docHeader,
                "customHeader": customHeader,
                "COA": COA,
                "analysisDataArrayForExcel": finalArr4
            }

            finalArr5.push(finalObj5)
            console.log("theFinalObj", finalObj4, finalObj5)

            // rawData.header_keys end

            // prev header as obj start

            // finalArr = theObject[analysisData]
            // COA = finalArr
            // delete theObject[analysisData]
            // console.log("transformDetails", finalArr, theObject, analysisData)
            // finalArr.forEach((obj) => {
            //     newObj[obj.key] = obj.value
            // })
            // finalObj = { ...theObject, ...newObj }
            // finalArr2.push(finalObj)
            // finalObj2 = {
            //     "docHeader": theObject,
            //     "analysisDataName": analysisData,
            //     "COA": COA,
            //     "analysisDataArrayForExcel": finalArr2
            // }
            // finalArr3.push(finalObj2)

            // prev header as obj end

        }

        console.log("analysisData", theObject, ObjectKeys, analysisData, finalArr, newObj, finalObj, finalArr2)
        console.log("finalObj2", finalObj2)
        console.log("finalArr3", finalArr3)
        // return finalArr3
        console.log("theFinalArr", finalArr4, finalArr5)
        return finalArr5
    }

    //Table Function

    // Get selected rows
    const handleApplyRule = async () => {

        console.log('SelectedRows', selection);
        console.log("ruleType_", ruleType, ruleFormat)

        // if (Object.keys(selection).length === 0 && selection.constructor === Object) {
        //     toast.error("No rows selected")
        //     return
        // }
        if (selection.length == 0) {
            toast.error("No rows selected")
            return
        }

        // if (selection.length > 1) {
        //     if (selection[0]?.col19 !== selection[1]?.col19) {
        //         toast.error("Select Same SO Number")
        //         return
        //     }
        // }
        let isSpRule = false
        ruleFormat.map((item, i) => {
            if (item.value == ruleType) {
                if (item.ruleType == "sprule") {
                    isSpRule = true
                } else {
                    isSpRule = false
                }
            }
        })

        if (isSpRule) {
            if (selection.length > 1) {
                if (selection[0]?.col1 !== selection[1]?.col1) {
                    toast.error("Multiple Batch Number are not allowed")
                    return
                }
            }
        }

        // if (undefined == ruleType.value || ruleType.value == "") {
        //     toast.error("No rules selected")
        //     return
        // }
        if (undefined == ruleType || ruleType == "") {
            toast.error("No rules selected")
            return
        }

        // // check if ruleType is lambda
        // let isLambda = false
        // isLambda = ruleFormat.some((obj) => {
        //     return obj.value == ruleType && obj.ruleType == "lambda"
        // })
        // console.log("islambda", isLambda)
        // if(isLambda){
        //     let sendDataAWS = {
        //         "searchData": selection,
        //         // "castid": selection.castid,
        //         "rule_id": ruleType,
        //         "docid": docID,
        //         email: localStorage.getItem("Logindata")

        //     }
        //     console.log("sendDataAWS", sendDataAWS)
        //     try{
        //         setLoading(true);
        //         let response = await applyRuleToDocAWS(sendDataAWS)
        //         console.log("ruleAppliedresponseaws", response)
        //     }
        //     catch (error) {
        //         console.log("ruleAppliederror", error)
        //         if ("response" in error) {
        //             toast.error(error.response.data.msg)
        //         }
        //         else {
        //             toast.error(error.message)
        //         }

        //     }
        // }

        delete selection.conversion_data
        // let searchData1 = selection.filter((obj)=>delete obj.conversion_data)

        let paramSoArray = []
        let paramBundleArray = []
        let paramDeliveryArray = []

        const uniqueArray = Array.from(selection.reduce((map, obj) => map.set(obj.col19, obj), new Map()).values());

        uniqueArray.map((item, i) => {
            console.log("item_no", item)
            // paramSoArray.push(`'${item.col19}'`)
            paramSoArray.push(item.col19)
            paramBundleArray.push(item.col1)
            paramDeliveryArray.push(item.col39)
        })
        console.log("paramDatas_", paramSoArray, paramBundleArray, paramDeliveryArray)
        let paramValue = ""
        paramValue = paramSoArray.toString()
        console.log("paramSoArr_", paramValue);

        let sendData = {
            // "searchData": selection,
            "paramname": 'salesorder',
            "paramvalue": paramValue,
            // "castid": selection.castid,
            "rule_id": roleType == "customer-user" ? defaultRule.toString() : ruleType.toString(),
            "docid": docID,
            email: localStorage.getItem("Logindata"),
            // "soNo": selection[0]?.col19,
            // "bundleno": selection[0]?.col1
        }
        console.log("sendData", sendData, "searchData1")
        // return
        // setDocData([])
        try {
            setLoading(true);
            let response = await applyRuleToDoc(sendData)
            // console.log("rule applied success", response.data.outputData.COA[0].conversion_data)
            console.log("ruleAppliedresponse", response)
            let data = response.data.outputData
            console.log("ruleAppliedresponsedata", data)
            if (response.status == 200) {
                if(roleType == "customer-admin"){
                    toast.success("Rule applied successfully.")
                }
                // setDocData([])
                setSelection([])
                setSoData([])
                let docData = await SubmitDocDetails(0)
                console.log("DocDataLatest_", docData,data)
                // Delayfilefun()
                // setAfterConverstionData([])
                // setAfterConverstionData(data)
                // setConvertData(data)
                setAppliedRuleData(data)
                setDataForExcel(data)
                return data
            }

            else {
                toast.error(messages.somethingWrong)
                return false
            }
        }
        catch (error) {
            console.log("ruleAppliederror", error)
            // if ("response" in error) {
            //     toast.error(error.response.data.msg)
            // }
            // else {
            //     toast.error(error.message)
            // }
            setError(error)
        }
        setLoading(false);
    };

    const getRuleType = async () => {

        const email = localStorage.getItem("Logindata");
        setLoading(true);
        try {
            let response = await getRules(email, docID)
            console.log("getRulesResponse", response)
            if (response.status == 200) {
                let data = response.data.data
                let finalArr = []
                data.map((obj) => {
                    if (obj._rulestype !== null) {
                        const trimmedRulestype = obj._rulestype.trim()
                        if (obj._spec_status_name == "Approved") {
                            finalArr.push({
                                name: obj._rule_name,
                                value: obj._rule_id,
                                ruleType: trimmedRulestype,
                                isDefault: obj._is_default_rule
                            })
                        }
                    }
                })
                finalArr.sort((a, b) => b.value - a.value);
                setRuleFormat(finalArr)
                console.log("__final", finalArr)
                if (finalArr.length === 1) {
                    setRuleType(finalArr[0].value)
                }
                if (selection.length == 0) {
                    finalArr.filter((obj) => {
                        if (obj.isDefault == true) {
                            setRuleType(obj.value)
                            setDefaultRule(obj.value)
                        }
                    })
                }
            }

        } catch (error) {
            console.log(error);
            setError(error)
        }
        setLoading(false);
    };

    // determining column length
    const shouldUseA3 = (csvData) => {
        const fontSize = 10
        let columnHeaderKeys = Object.keys(csvData[0].analysisDataArrayForExcel[0])
        let columnHeaderValue = Object.values(csvData[0].analysisDataArrayForExcel[0])
        let columnHeaderKeyStrings = columnHeaderKeys.map((obj) => obj.toString())
        let columnHeaderValueStrings = columnHeaderValue.map((obj) => obj.toString())
        // let totalLengthOfKeys = columnHeaderKeyStrings.reduce((acc, str) => acc + str.length, 0)
        let totalLengthOfKeys = columnHeaderKeyStrings.reduce((acc, str) => acc + Math.floor((str.length * fontSize) / 72 * 25.4), 0)
        let totalLengthOfValues = columnHeaderValueStrings.reduce((acc, str) => acc + Math.floor((str.length * fontSize) / 72 * 25.4), 0)
        let totalLengthOfCharacters = totalLengthOfKeys > totalLengthOfValues ? totalLengthOfKeys : totalLengthOfValues
        console.log("totalLengthOfKeys", columnHeaderValueStrings, totalLengthOfKeys, totalLengthOfValues, totalLengthOfCharacters)
        let pageWidth = totalLengthOfCharacters + 20
        const marginSubtract = 40
        const a4PortraitWidth = 210
        const a4LandscapeWidth = 297
        // const a3PortraitWidth = 297
        const a3LandscapeWidth = 420
        const a2LandscapeWidth = 594

        let page = { orientation: "landscape", format: "a4" }
        if (totalLengthOfCharacters <= a4PortraitWidth - marginSubtract) {
            page.format = "a4"
            page.orientation = "portrait"
        } else if (totalLengthOfCharacters <= a4LandscapeWidth - marginSubtract) {
            page.format = "a4"
            page.orientation = "landscape"
        } else if (totalLengthOfCharacters <= a3LandscapeWidth - marginSubtract) {
            page.format = "a3"
            page.orientation = "landscape"
        }
        else if (totalLengthOfCharacters <= a2LandscapeWidth - marginSubtract) {
            page.format = "a2"
            page.orientation = "landscape"
        }
        else {
            page.format = "a2"
            page.orientation = "landscape"
        }
        console.log("page", page)

        let pageWidthInMillimeters = a4PortraitWidth;
        let effectiveContentWidthInMillimeters = pageWidthInMillimeters - marginSubtract
        let averageCharacterWidthInMillimeters = 6.35;
        let charactersPerLine = Math.floor(effectiveContentWidthInMillimeters / averageCharacterWidthInMillimeters);
        // let contentWidthRequired = (totalLengthOfCharacters / charactersPerLine) * (fontSize / 72) * 25.4; // Font size conversion from points to millimeters
        let contentWidthRequired = (totalLengthOfCharacters) * (fontSize / 72) * 25.4
        // let page = {orientation: "landscape", format: "a4"}
        // console.log("Approximate characters per line:", charactersPerLine,contentWidthRequired);
        // if (contentWidthRequired <= a4PortraitWidth) {
        //     page.format = "a4"
        //     page.orientation = "portrait"
        // } else if (contentWidthRequired <= a4LandscapeWidth) {
        //     page.format = "a4"
        //     page.orientation = "landscape"
        // } else if (contentWidthRequired <= a3LandscapeWidth) {
        //     page.format = "a3"
        //     page.orientation = "landscape"
        // }
        // else if (contentWidthRequired <= a2LandscapeWidth) {
        //     page.format = "a2"
        //     page.orientation = "landscape"
        // }
        // console.log("page",page)
        // else {
        //     pageSize = 'Other'; // If none of the above sizes fit, consider a custom or larger page size
        // }
        convertExcelToPDF(csvData[0].analysisDataArrayForExcel, fileNameGeneration(selection), page)
    }

    // PDF settings


    function getTextWidth(text, fontSize = 12, fontFamily = "Arial") {
        if (typeof text !== 'string') {
            text = String(text);
        }
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        context.font = `${fontSize}pt ${fontFamily}`;
        return context.measureText(text.width);
    }

    function calculateTotalColumnWidth(evoData, fontSize = 12, fontFamily = 'Arial') {
        if (!Array.isArray(evoData) || evoData.length === 0) {
            console.log("error_:Invalid data provided for evoData")
        }

        const columnHeaderKeys = Object.keys(evoData[0]);
        let totalColumnWidth = columnHeaderKeys.reduce((acc, key) => {
            const columnData = evoData.map(obj => obj[key] ? obj[key].toString() : "");
            const columnWidth = columnData.reduce((maxWidth, str) => {
                const strWidth = getTextWidth(str, fontSize, fontFamily)
                if (isNaN(maxWidth)) {
                    maxWidth = 0;
                }
                const width = strWidth?.width
                return Math.max(maxWidth, width);
            }, 0);
            return acc + columnWidth;
        }, 0);
        return totalColumnWidth;
    }


    // PDF settings
    const convertExcelToPDF = (csvData, fileName, page) => {
        const totalColumnWidth = calculateTotalColumnWidth(csvData)
        console.log(totalColumnWidth, "_totalColumnWidth")
        page.format = [totalColumnWidth, 841.89]
        page.orientation = 'landscape';
        console.log("thepagesize", page)
        // const columns = Object.keys(csvData[0]).map(key => ({ header: key, dataKey: key }));
        // const rows = csvData.map(obj => Object.values(obj));

        // const doc = new jsPDF();
        const doc = new jsPDF({
            orientation: page.orientation, // Set orientation to landscape
            unit: 'pt', // Use millimeters as the unit
            // format: [497, 210], // Custom page size: 297mm (width) x 210mm (height)
            format: page.format,
        });
        const columns = Object.keys(csvData[0]);
        const rows = csvData.map(obj => Object.values(obj));
        doc.setFontSize(10)
        doc.autoTable({
            head: [columns],
            body: rows,
            startY: 20,
            margin: { top: 20 }
        });

        doc.save(`${fileName}.pdf`);
    }


    const generatePDF = (data, fileName) => {
        console.log("convertData", convertData, selection)
        console.log("selectionData", selectionData)
        // if(undefined == convertData){
        //     toast.error("No file available")
        //     return
        // }
        // let pdfObj = transformForDownload([convertData])
        // let pdfObj = transformForDownload(selectionData)
        let pdfObj = transformForDownload(data)

        console.log("pdfObj", pdfObj, data, selectionData)
        // if (Object.keys(selection).length > 0 && selection.constructor === Object) {
        //    toast.error("please select any one record")
        //    return
        // }

        let text, docHeaderText, docHeaderLength
        let imgData = LOGO;
        let headerText
        let headers
        let rows
        let tableProps

        const doc = new jsPDF();
        var requiredPages = pdfObj.length
        for (let i = 0; i < requiredPages; i++) {

            let { docHeader, COA, customHeader } = pdfObj[i]
            let headerRows, coaRows, customRows

            doc.addImage(imgData, 'PNG', 170, 10, 20, 10);
            doc.setFontSize(12);

            tableProps = {
                margin: { top: 10, left: 20 },
                headStyles: { fillColor: [100, 100, 100] },
                footStyles: { fillColor: [100, 100, 100] },

            };

            if (docHeader && docHeader.length > 0) {
                headerRows = docHeader.map(item => [item.key, item.value])
            }
            if (COA && COA.length > 0) {
                coaRows = COA.map(item => [item.key, item.value])
            }
            if (customHeader && customHeader.length > 0) {
                customRows = customHeader.map(item => [item.key, item.value])
            }
            console.log("headerRows", headerRows, "coaRows", coaRows, "customRows", customRows)

            if (headerRows && headerRows.length > 0 && coaRows && coaRows.length > 0 && customRows && customRows.length > 0) {
                doc.text("Document Header", 20, 30);
                doc.autoTable({
                    head: [['Key', 'Value']],
                    body: headerRows,
                    startY: 35,
                    ...tableProps
                });

                doc.text("Certificate of Analysis", 20, doc.lastAutoTable.finalY + 10);
                doc.autoTable({
                    head: [['Key', 'Value']],
                    body: coaRows,
                    startY: doc.lastAutoTable.finalY + 15,
                    ...tableProps
                });

                doc.text("Customer Data", 20, doc.lastAutoTable.finalY + 10);
                doc.autoTable({
                    head: [['Key', 'Value']],
                    body: customRows,
                    startY: doc.lastAutoTable.finalY + 15,
                    ...tableProps
                });
            }
            else if (headerRows && headerRows.length > 0 && !coaRows) {
                doc.text("Document Header", 20, 30);
                doc.autoTable({
                    head: [['Key', 'Value']],
                    body: headerRows,
                    startY: 35,
                    ...tableProps
                });
            }
            else if (!headerRows && coaRows && coaRows.length > 0) {
                doc.text("Certificate of Analysis", 20, 30);
                doc.autoTable({
                    head: [['Key', 'Value']],
                    body: coaRows,
                    startY: 35,
                    ...tableProps
                });
            }

            doc.addPage();

            // text = JSON.stringify(pdfObj[i].docHeader)
            // text = text.replace(/[{}]/g, '');
            // text = text.replaceAll(':', ': ');
            // text = text.replaceAll('"', '');
            // docHeaderLength = Object.keys(pdfObj[i].docHeader).length
            // docHeaderText = `${text}`

            // console.log("docHeaderText_length", docHeaderText.length, docHeaderLength)
            // doc.addImage(imgData, 'PNG', 170, 10, 20, 10);
            // headerText = docHeaderText.split(",").join("\n")
            // console.log("headerText", text, "docHeaderText", docHeaderText, headerText)
            // headers = [['Key', 'Value']];
            // rows = pdfObj[i].COA.map(item => [item.key, item.value]);
            // tableProps = {
            //     margin: { top: 10, left: 20 },
            //     headStyles: { fillColor: [100, 100, 100] },
            //     footStyles: { fillColor: [100, 100, 100] },
            //     // startY: 40,
            //     // startY: (docHeaderLength * 10) + 1,
            //     startY: (docHeaderLength * 15) + 1,
            // };
            // doc.setFontSize(12);
            // doc.text(headerText, 20, 20);
            // doc.autoTable({ head: headers, body: rows, ...tableProps });
            // doc.addPage();
        }
        var pageCount = doc.internal.getNumberOfPages();
        doc.deletePage(pageCount)
        // doc.save('data.pdf');
        doc.save(fileName);
        return

        doc.addImage(imgData, 'PNG', 170, 10, 20, 10);
        // Set header text

        // const headerText = `Cast ID: ${convertData.cast_id}\nDocument Type: ${convertData.document_type
        //     }`;
        headerText = docHeaderText.split(",").join("\n")
        console.log("headerText", text, docHeaderText, headerText)

        // Set table headers
        headers = [['Key', 'Value']];

        // Set table rows
        // const rows = convertData.COA.map(item => [item.key, item.value]);
        rows = pdfObj.COA.map(item => [item.key, item.value]);

        // Set table properties
        tableProps = {
            margin: { top: 10, left: 20 },
            headStyles: { fillColor: [100, 100, 100] },
            footStyles: { fillColor: [100, 100, 100] },
            startY: 30,
        };

        // Add header text
        doc.setFontSize(12);
        doc.text(headerText, 20, 20);

        // Add table to the PDF
        doc.autoTable({ head: headers, body: rows, ...tableProps });

        // Save the PDF
        doc.save('data.pdf');
    };

    // PDF settings end

    //CSV file function 

    // const handleConvertToCSV = (data) => {
    //     console.log("convertData",data,convertData)
    //     const csvData = convertToCSV(convertData.COA);
    //     console.log("csvData",csvData)
    //     const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    //     saveAs(blob, "data.csv");
    // };

    // const convertToCSV = (data) => {
    //     const header = Object.keys(data[0]).join(",");
    //     const rows = data.map((obj) => Object.values(obj).join(","));
    //     return `${header}\n${rows.join("\n")}`;
    // };

    const handleConvertToText = (data, fileName) => {
        console.log("convertData", data)
        let txtObj = transformForDownload(data)
        console.log("txtObj", txtObj)
        const txtData = convertToTxt(txtObj);
        console.log("txtData", txtData)
        const blob = new Blob([txtData], { type: "text/plain;charset=utf-8" });
        // saveAs(blob, "data_csv.csv");
        saveAs(blob, fileName);
    }

    const convertToTxt = (data) => {
        console.log("datacsv", data)
        let dataForCSV = data[0].analysisDataArrayForExcel
        const headers = Array.from(new Set(dataForCSV.flatMap(item => Object.keys(item)))).join(" ");
        const rows = dataForCSV.map(item => {
            return headers.split(" ").map(header => item[header] !== undefined ? item[header] : "").join(" ");
        });
        const csvString = `${headers}\n${rows.join("\n")}`;
        return csvString
    };

    const handleConvertToCSV = (data, fileName) => {
        console.log("convertData", data)
        let csvObj = transformForDownload(data)
        console.log("csvObj", csvObj)
        const csvData = convertToCSV(csvObj);
        console.log("csvData", csvData)
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
        // saveAs(blob, "data_csv.csv");
        saveAs(blob, fileName);
    };

    // const convertToCSV = (data) => {
    //     console.log("datacsv", data)
    //     let dataForCSV = data[0].analysisDataArrayForExcel
    //     // const header = Object.keys(data[0]["COA"]).join(",");
    //     let rows
    //     // data.forEach((obj) => {
    //     // console.log("objCOA", obj)
    //     // rows += obj.COA.map((item) => Object.values(item).join(",\n")) ;
    //     // rows += obj.COA.map((item) => `${Object.values(item)}\n`);

    //     // rows += obj.COA.map((item) => `${Object.values(item)}`);
    //     // rows += obj.analysisDataArrayForExcel.map((item) => `${Object.keys(item)}, ${Object.values(item)}`);
    //     rows += dataForCSV.map((item, i) => `${i > 0 && "\n"}${Object.entries(item)}`);
    //     rows += "\n"
    //     // })
    //     rows = rows.replace("undefined", "")
    //     rows = rows.replace("false", "")
    //     rows = rows.replace("null", "")
    //     // rows = rows.replace(/\s/g, "");
    //     // rows = rows.replace(/ \n/g, '');
    //     // rows = rows.replace("\n,", ",\n")
    //     console.log("csvheader", rows, typeof (rows))
    //     // return `${header}\n${rows.join("\n")}`;
    //     return `${rows}`;
    // };

    //CSV settings end

    // Excel file function


    const convertToCSV = (data) => {
        console.log("datacsv", data)
        let dataForCSV = data[0].analysisDataArrayForExcel
        const headers = Array.from(new Set(dataForCSV.flatMap(item => Object.keys(item)))).join(",");
        const rows = dataForCSV.map(item => {
            return headers.split(",").map(header => item[header] !== undefined ? item[header] : "").join(",");
        });
        const csvString = `${headers}\n${rows.join("\n")}`;
        console.log("csvString_", csvString, typeof (csvString))
        return csvString;
    };




    const handleConvertToExcel = () => {
        const excelData = convertToExcel(convertData.COA);
        const blob = new Blob([excelData], { type: "text/csv;charset=utf-8" });
        saveAs(blob, "data.xls");
    };

    // const exportWorksheet = (jsonObject) => {
    //     console.log("jsonObject",jsonObject)
    //     var myFile = "myFile.xlsx";
    //     var myWorkSheet = XLSX.utils.json_to_sheet(jsonObject);
    //     var myWorkBook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(myWorkBook, myWorkSheet, "myWorkShe<et");
    //     XLSX.writeFile(myWorkBook, myFile);

    // }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportWorksheet = (csvData, fileName) => {
        console.log("csvData", csvData)
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        // FileSaver.saveAs(data, fileName + fileExtension);
        saveAs.saveAs(data, fileName + fileExtension);
        // fileNameGeneration()
    }

    const convertToExcel = (data) => {
        const header = Object.keys(data[0]).join(",");
        const rows = data.map((obj) => Object.values(obj).join(","));
        return `${header}\n${rows.join("\n")}`;
    };
    // start of change
    useEffect(() => {
        GetDocParameter()
    }, [docID])
    // end of change
    useEffect(() => {
        getAPIFormat2()
        // GetDocParameter()
        getRuleType()

    }, [])

    // const useStyles = makeStyles((theme) => ({
    //     highlightedRow: {
    //       backgroundColor: 'red',

    //     },
    //   }));

    //   const classes = useStyles();
    const handleSoParameter = () => {
        console.log("soItem")

        if (selection.length > 1) {
            if (selection[0]?.col19 !== selection[1]?.col19) {
                toast.error("Select Same SO Number")
                return
            }
        }
        if (selection.length > 0) {
            setSalesOrderNo(selection[0]?.col19)
            getSODetailsForComp(selection[0]?.col19)
        }
        getSoNoForComp()
        setSoModalOpen(true)
    }

    const handleCancelSo = () => {
        setSoModalOpen(false)
        setParameterName("")
        setParamValue("")
        setSalesOrderNo("")
        setSORowData([])
        // setSelection([])
        setIsUpdateButton(false)
    }

    const handleSalesOrder = (e) => {
        console.log("soItem", e.target.value)
        setSalesOrderNo(e.target.value)
        getSODetailsForComp(e.target.value)
    }

    const handleParamName = (e) => {
        console.log("ParamName_", e)
        setParameterName(e.target.value)
    }

    const handleParamValue = (e) => {
        console.log("ParamName_", e)
        setParamValue(e.target.value)
    }

    const handleAddParam = (e) => {
        console.log("addParams_")
        addSoDetails()
    }

    const handleUpdateParam = async (param) => {

        console.log("addParams_")
        try {
            let payload = {
                id: rowId,
                paramname: parameterName,
                paramvalue: paramValue
            }
            let soUpdateResponse = await updateSoDetails(payload)
            console.log("soDeleteResponse_", soUpdateResponse);
            if (soUpdateResponse.status == 200) {
                toast.success(soUpdateResponse.data.msg)
                getSODetailsForComp(salesOrderNo)
            } else {
                toast.error(soUpdateResponse.data.msg)
            }
        }
        catch (error) {
            setError(error)
        }

    }

    const handleDeleteSo = async (params) => {
        console.log("params", params)
        try {
            // let payload = {
            //     "parametername": params.data.parametername,
            //     "salesorderno": params.data.salesorderno
            // }

            let soDeleteResponse = await deleteSoDetail(params.data.id)
            console.log("soDeleteResponse_", soDeleteResponse);
            if (soDeleteResponse.status == 200) {
                toast.success(soDeleteResponse.data.msg)
                getSODetailsForComp(salesOrderNo)
            }
        }
        catch (error) {
            setError(error)
        }
    }

    const handleEditSo = (params) => {
        const data = params.data
        console.log("params", params)
        setParameterName(data['parametername'])
        setParamValue(data['paramvalue'])
        setRowId(data['id'])
        setIsUpdateButton(true)
    }

    const handleCloseUpdate = (param) => {
        console.log("params", param)
        setParameterName("")
        setParamValue("")
        setIsUpdateButton(false)

    }

    const columnData = [
        {
            headerName: "SO Number",
            field: "salesorderno",
            // width: 150,
            flex: 0.8,
        },
        {
            headerName: "Name",
            field: "parametername",
            // width: 150,
            flex: 0.8,
        },
        {
            headerName: "Value",
            field: "paramvalue",
            // width: 150,
            flex: 0.8,
        },
        {
            field: "",
            // width: 150,
            flex: 0.4,
            cellClass: 'my-class',
            cellRenderer: (params) => {
                // console.log("Coming ...", val);
                return (
                    <div className="cellAction">
                        <a onClick={() => handleEditSo(params)} class="" style={{ fontSize: "15px" }}>
                            <FaEdit />
                        </a>
                    </div>
                );
            },
        },
        {
            field: "",
            // width: 150,
            flex: 0.4,
            cellClass: 'my-class',
            cellRenderer: (params) => {
                // console.log("Coming ...", val);
                return (
                    <div className="cellAction">
                        <a onClick={() => handleDeleteSo(params)} style={{ color: "red", fontSize: "15px" }}>
                            <BsFillTrashFill />
                        </a>
                    </div>
                );
            },
        },
    ]

    const getSoNoForComp = async () => {
        console.log("inside_so_details")
        try {
            let payload = {
                "comp_id": companyId
            }
            let soResponse = await getSoNoforCompany(payload)
            console.log("soResponse_", soResponse)
            let soData = soResponse.resData.data.data
            if (soResponse.status == 200) {
                let soValuedata = soData.map((item) => {
                    let soValue = {
                        name: item,
                        value: item
                    }
                    return soValue
                })
                setSoList(soValuedata)
            }
        }
        catch (error) {
            setError(error)
        }
    }

    const getSODetailsForComp = async (param) => {
        console.log("insideSoDetails_")
        try {
            setIsUpdateButton(false)
            setParameterName("")
            setParamValue("")
            let payload = {
                "comp_id": companyId,
                "salesorderno": param || salesOrderNo
            }
            let soDetailsResponse = await getSODetails(payload)
            console.log("soDetailsResponse_", soDetailsResponse)
            if (soDetailsResponse.status == 200) {
                let rowData = soDetailsResponse.resData.data.data
                setSORowData(rowData)
            } else {
                // let rowData = soDetailsResponse.resData.data.data
                setSORowData([])
            }
        }
        catch (error) {
            setError(error)
        }
    }

    const addSoDetails = async () => {
        try {
            let payload = {
                "companyid": companyId,
                "docid": 1,
                "salesorderno": salesOrderNo,
                "salesorderitem": "",
                "parametername": parameterName,
                "paramvalue": paramValue
            }
            let addResponse = await CreateSoDetails(payload)
            console.log("addResponse_", addResponse);
            if (addResponse.status == 200) {
                toast.success(`${parameterName} added successfully`)
                getSODetailsForComp(salesOrderNo)
            } else {
                toast.error(`${parameterName} was not added`)
            }
        }
        catch (error) {
            setError(error)
        }
    }

    const handleSoSubmit = async () => {
        setSoModalOpen(false)
        SubmitDocDetails()
    }

    useEffect(() => {
        console.log("rawDatadetails", rawData)
        if (rawData.length == 0 || rawData !== null || undefined !== rawData) {
            if (Object.keys(rawData).length > 0) {
                setIsRawDataModal(true)
                setIsRawDataTable(true)
            }
        }
    }, [rawData])

    useEffect(() => {
        console.log("convertDataDetails", convertData)
        if (convertData.length > 0 || convertData !== null || undefined !== convertData) {
            if (Object.keys(convertData)?.length > 0) {
                setIsModalOpen(true)
                setIsRawDataTable(true)
            }
        }
    }, [convertData])

    const tabItemsConvertedData = [
        {
            key: "1",
            label: `Table`,
            children: <RawDataTable isRawDataTable={isRawDataTable} rawData={convertData} />,
        },
        {
            key: "2",
            label: `JSON`,
            children: convertData ? <JsonComp isJsonComp={isJsonComp} rawData={convertData} /> : null,
        },
    ]

    const tabItemsRawData = [
        {
            key: "1",
            label: `Table`,
            children: rawData ? <RawDataTable isRawDataTable={isRawDataTable} rawData={rawData} /> : null,
        },
        {
            key: "2",
            label: `JSON`,
            children: rawData ? <JsonComp isJsonComp={isJsonComp} rawData={rawData} /> : null,
        },
    ]

    const onChangeTabsConvertData = (key) => {
        console.log("onChangeTabsonChangeTabsConvertDataRawData", key);
        if (key == "1") {
            setIsJsonComp(false)
            setIsRawDataTable(true)
        }
        else if (key == "2") {
            setIsJsonComp(true)
            setIsRawDataTable(false)
        }
    }

    const onChangeTabsRawData = (key) => {
        console.log("onChangeTabsRawData", key);
        if (key == "1") {
            setIsJsonComp(false)
            setIsRawDataTable(true)
        }
        else if (key == "2") {
            setIsJsonComp(true)
            setIsRawDataTable(false)
        }
    }

    const handleFilter = () => {
        setIsFilter(!isFilter)
    }

    const onSelectionChanged = () => {
        console.log("gridRef_", gridRef)
        const selectedRows = gridRef.current.api.getSelectedRows();
        setSelection(selectedRows)
        console.log("selectedRows_", selectedRows)
    }

    const rowClassRules = {
        'even-row-so': (params) => params.node.rowIndex % 2 !== 0,
    };

    const handleSelectSO = (index, soNo, isChecked) => {
        soData.map((item, i) => {
            if (i == index) { item.isChecked = !item.isChecked }
            //    else{item.isChecked = false} 
        })
        console.log(index, "i==", soData)
        setSoData([...soData])
        console.log("isChecked_", isChecked)
        if (isChecked == false || isChecked == undefined) {
            setSelectedSo([...selectedSo, soNo])
        } else if (isChecked == true) {
            let filteruncheck = selectedSo.filter((item) => item !== soNo)
            console.log("filteruncheck_", filteruncheck);
            setSelectedSo(filteruncheck)
        }

        let checkarray = []
        soData.map((item, i) => {
            if (item.isChecked == true) {
                console.log("insideIf_")
                checkarray.push(item)
            }
        })
        console.log("inside_", checkarray)
        let filteredRowData

        if (checkarray.length == 0) {
            setSelection([])
            return
        } else if (checkarray.length == 1) {
            console.log("checkarray_", checkarray, filteredRowData);
            filteredRowData = docData.filter(obj => {
                return obj.col19 == checkarray[0]?.col19
            })
            setSelection(filteredRowData)
            return
        }
        filteredRowData = docData.filter(obj => {
            return obj.col19 == soNo
        })
        // setSelection(filteredRowData)

        setSelection(prevArray => {
            return [...prevArray, ...filteredRowData];
        });

        // setSelection([...selection,filteredRowData])
    }

    const getDatawithid = (change, data) => {
        console.log("docData_", docData, data)
        if (docData.length > 0) {
            setIsSoSelect(change)
            let filteredRowData = docData.filter(obj => {
                return obj.col19 == data
            })
            console.log("filteredRowData_", filteredRowData)
            setSoRowData(filteredRowData)
            // setSelection(filteredRowData)
        }
    }

    return (
        <>
            <Loader loading={loading} />
            <Toaster position="top-center" reverseOrder={false} />
            <ErrorCatch err={error} />
            <div className="container-fluid mt-2">
                {/* <div className="row">
                    <p className="page-title">Document List</p>
                </div> */}
                {console.log("length", statusList.length == 0 ? <>On</> : <></>)}
                <span className="bordertext p-2" onClick={handleFilter}>
                    Filter & Search
                </span>
                {isFilter &&
                    <div className="card col-md-12 p-4">
                        <div className='row'>
                            {/* <div className='col-md-2 '>
                                    <SelectInput
                                        margin="0"
                                        selectLabel="Document Type"
                                        name="_documetkeys_id"
                                        value={docID}
                                        onChange={(e) => handleDocumentType(e)}
                                        optionsList={documentname}
                                    />
                                </div> */}
                            {/* <div className="col-md-4 "> */}

                            {/* <SelectInput
                                        margin="0"
                                        selectLabel="Parameter"
                                        name="docparam"
                                        value={docperaval}
                                        onChange={(e) => handleParameter(e)}
                                        optionsList={docParam}
                                    /> */}

                            {/* <select class="form-select form-select-sm"
                                        name="docparam"
                                        style={{ fontSize: "12px" }}
                                        onChange={(e) => handleParameter(e)}>
                                        <option value={paramType.value} disabled selected hidden>{paramType.name}</option>

                                        {docParam.map((val) => {
                                            const { docparamname, docparamvalue } = val;
                                            return <option value={docparamvalue}>{docparamname}</option>;
                                        })}

                                    </select> */}
                            {/* </div> */}
                            <div className="col-md-2">
                                <InputBox
                                    label="Sales Order No"
                                    name="sales_order_no"
                                    fullWidth
                                    onChange={(e) => Inputhandlechange(e)}
                                    value={soNo}
                                />

                                {/* <Input placeholder="Document Value" style={{ fontSize: "12px" }} name="doc_val" type="text" value={docVal.doc_val} onChange={(e) => Inputhandlechange(e)} /> */}

                            </div>

                            <div className="col-md-2">
                                <InputBox
                                    label="Delivery No"
                                    name="delivery_no"
                                    fullWidth
                                    onChange={(e) => Inputhandlechange(e)}
                                    value={deliveryNo}
                                />

                                {/* <Input placeholder="Document Value" style={{ fontSize: "12px" }} name="doc_val" type="text" value={docVal.doc_val} onChange={(e) => Inputhandlechange(e)} /> */}

                            </div>
                            <div className="col-md-2">
                                <InputBox
                                    label="Cast No"
                                    name="cast_no"
                                    fullWidth
                                    onChange={(e) => Inputhandlechange(e)}
                                    value={castNo}
                                />

                                {/* <Input placeholder="Document Value" style={{ fontSize: "12px" }} name="doc_val" type="text" value={docVal.doc_val} onChange={(e) => Inputhandlechange(e)} /> */}

                            </div>

                            <div className="col-md-1">
                                    <CheckboxComp
                                        label="Date"
                                        isChecked={isSixMonthDate}
                                        onChange={handleSixMonthDate}
                                    />
                                </div>

                            <div className="col-md-2  a1 border-color: color: rgba(0, 0, 0, 0.6)">

                                <SelectDate class="a1"
                                    fullWidth
                                    // style={{Color: rgb(0, 0, 0, 0.6)}}
                                    // defaultValue={today}
                                    label="From Date"
                                    maxDate={toDate}
                                    // value={fromDate == "" ? "" : fromDate
                                    value={fromDate}
                                    onChange={(value) => handleDateChange(value)}
                                    disabled={false}
                                // onChange={handleDateChange}
                                />
                            </div>
                            <div className="col-md-2  c1">
                                <SelectDate className="a3"
                                    fullWidth
                                    style={{ Color: blue }}
                                    label="To Date"
                                    sx={{
                                        svg: { color: '#fff' },

                                    }}
                                    minDate={fromDate}
                                    maxDate={toDate}
                                    // value={toDate == "" ? "" : toDate}
                                    value={toDate}
                                    // onChange={handleToDateChange}
                                    onChange={(value) => handleToDateChange(value)}
                                    disabled={fromDate == "" ? true : false}
                                />
                            </div>
                            <div className="col-md-1">
                                {appObjects["Submit"] && <BasicButton
                                    className={'submitbtn'}
                                    text="Submit"
                                    onClick={SubmitDocDetails}
                                />}
                            </div>
                        </div>

                        {soModalOpen &&
                            <Modal title={"Customer Data"} open={soModalOpen} onOk={handleSoSubmit} onCancel={handleCancelSo} style={{ minWidth: 300, maxWidth: 750 }}>
                                <SelectInput
                                    margin="0"
                                    selectLabel="SO Number"
                                    name="so_No"
                                    disabled={(selection.length > 0) ? true : false}
                                    onChange={(e) => handleSalesOrder(e)}
                                    value={selection.length > 0 ? selection[0].col19 : salesOrderNo}
                                    optionsList={soList}
                                />
                                <div className="col-md-12 d-flex pt-2">
                                    <div className={isUpdateButton ? "col-md-4 px-0" : "col-md-5 pl-0"}>
                                        <InputBox
                                            name="parameter"
                                            value={parameterName}
                                            onChange={(e) => handleParamName(e)}
                                            // placeholder="Parameter Name"
                                            label="Name"
                                        />
                                    </div>
                                    <div className={isUpdateButton ? "col-md-4 px-2" : "col-md-5 px-3"}>
                                        <InputBox
                                            name="parameter"
                                            value={paramValue}
                                            onChange={(e) => handleParamValue(e)}
                                            // placeholder="Parameter Value"
                                            label="Value"
                                        />
                                    </div>
                                    <div className={isUpdateButton ? "col-md-4 pt-1" : "col-md-3 px-2 pt-1"}>
                                        {isUpdateButton ?
                                            <div className="d-flex p-0">
                                                <div className="px-1">
                                                    <BasicButton
                                                        text="Update"
                                                        onClick={handleUpdateParam}
                                                        className="buttonAdd"
                                                    />
                                                </div>
                                                <div>
                                                    <BasicButton
                                                        text="Cancel"
                                                        onClick={handleCloseUpdate}
                                                        className="buttonAdd"
                                                    />
                                                </div>
                                            </div>
                                            : <BasicButton
                                                text="Add"
                                                onClick={handleAddParam}
                                                className="buttonAdd"
                                            />}
                                    </div>
                                </div>
                                <div className="pt-2">
                                    <AgGridComponent
                                        columnDef={columnData}
                                        height={300}
                                        rowData={SORowData}
                                    />
                                </div>
                            </Modal>}


                    </div>}

                <div className="gridcontainer pt-4 mt-4">
                    <div className=" user-access-grid-header">
                        <div className="d-flex justify-content-between">
                            <div className="mb-0 d-flex justify-content-center so-access-cell-data-header pl-3" style={{ borderRight: "0.5px solid rgb(153 153 153)", height: '42px' }}>
                                <label className="mb-0 pt-2">Sales Order</label>
                            </div>
                            <label className="mb-0 pt-2 so-access-cell-data-header">Delivery No</label>
                            <label className="vl mb-0 pt-2 so-access-cell-data-header">Total Weight (Kg)</label>
                            <label className="vl mb-0 pt-2 so-access-cell-data-header">Alloy</label>
                            <label className="vl mb-0 pt-2 so-access-cell-data-header">Length</label>
                        </div>
                    </div>
                    {soData.length > 0 &&
                        soData.map((obj, index) => {
                            return (<div className={index % 2 == 0 ? "mt-1 user-access-grid-border" : "mt-1 user-access-grid-border-UA"}>
                                <div className="d-flex justify-content-between">
                                    <div className="mb-0 d-flex so-access-cell-data pl-3" style={{ borderRight: "0.5px solid rgb(153 153 153)", height: '42px', paddingLeft: '5px' }}>
                                        <CheckboxComp className='mb-0 pl-5' isChecked={obj.isChecked} isLabelShow={false}
                                            onChange={() => handleSelectSO(index, obj.col19, obj.isChecked)} name="policyCopy" />
                                        {index == isSoSelect ?
                                            <img src={minus} onClick={() => getDatawithid(null, obj.col19)} style={{ height: '25px', width: '25px', cursor: 'pointer', marginTop: '8px' }} />
                                            : <img src={plus} onClick={() => getDatawithid(index, obj.col19)} style={{ height: '25px', width: '25px', cursor: 'pointer', marginTop: '8px' }} />}
                                        <label className="mb-0 pt-2">{obj.col19.toString().substring(3)}</label>
                                    </div>
                                    <label className="mb-0 pt-2 so-access-cell-data v1">{obj.col39}</label>
                                    <label className="vl mb-0 pt-2 so-access-cell-data">{obj.totalWeight}</label>
                                    <label className="vl mb-0 pt-2 so-access-cell-data">{obj.col7}</label>
                                    <label className="vl mb-0 pt-2 so-access-cell-data">{obj.col43}</label>
                                </div>
                                {
                                    index == isSoSelect ?
                                        <div className="p-3" id="myGrid">
                                            <AgGridComponent
                                                rowData={soRowData}
                                                columnDef={columns}
                                                height={350}
                                                onGridReady={onGridReady}
                                                onSelectionChanged={onSelectionChanged}
                                                gridRef={gridRef}
                                                rowSelection={"multiple"}
                                                rowClassRules={rowClassRules}
                                            />
                                        </div>
                                        : null
                                }
                            </div>
                            )
                        })}
                </div>

                {/* Table */}
                {/* <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            {docData && headerName.length > 0 && docData?.length != 0 ?
                                // <Box sx={{
                                //     height: "47vh",
                                //     // width: "87vw", 
                                // }}>
                                //     <DataGrid
                                //         sx={{
                                //             borderColor: "secondary",
                                //             "& .MuiDataGrid-cell:hover": {
                                //                 color: "primary.main",
                                //             },
                                //             "& .MuiDataGrid-columnHeader": {
                                //                 backgroundColor: "#0E3386",
                                //                 fontSize: "12px",
                                //                 color: "white",
                                //                 padding: "10px",

                                //                 // Set your desired header background color
                                //             },
                                //             "& .MuiDataGrid-cell": {
                                //                 fontSize: "10px",
                                //                 padding: "10px",
                                //                 borderRight: "2px solid #e0e0e0",
                                //                 // Adjust the font size as needed
                                //                 // Adjust the cell padding as needed
                                //             },
                                //             '&.Mui-selected': {
                                //                 // backgroundColor: '#FF0000',
                                //                 // backgroundColor: '#0E3386',
                                //                 '&:hover': {
                                //                     backgroundColor: '#FF0000',
                                //                 },
                                //             },
                                //         }}

                                //         rows={docData}
                                //         disableRowSelectionOnClick
                                //         // disableColumnSelector
                                //         // disableColumnMenu

                                //         // disableMultipleRowSelection={true}
                                //         // checkboxSelection={true}
                                //         // handleChangeCheckbox
                                //         checkboxSelection
                                //         disableSelectionOnClick

                                //         // onCellClick={(params) => handleCellSelection(params)}
                                //         // getRowId={(docData) => docData.castid}
                                //         // getRowId={(docData) => docData.bundleno}
                                //         getRowId={(docData) => {
                                //             return docData.col1
                                //             // return docData.param_text == "col1"
                                //             // if (docID == 1) {
                                //             //     return docData.bundleno
                                //             //     // return docData.col1
                                //             // }
                                //             // else if (docID == 2) {
                                //             //     return docData.invoiceno
                                //             // }
                                //         }}
                                //         onRowSelectionModelChange={(newRowSelectionModel) =>
                                //             handleCheckBox(newRowSelectionModel)
                                //         }

                                //         // rowSelectionModel={selection}

                                //         rowHeight={30}
                                //         columns={columns}
                                //         hideFooter
                                //         disableMultipleColumnsSorting={true}
                                //         sortModel={[
                                //             { field: "action1", sort: "asc" },
                                //             { field: "action", sort: "asc" },
                                //         ]}
                                //     />
                                // </Box>


                                <AgGridComponent
                                    rowData={docData}
                                    columnDef={columns}
                                    height={350}
                                    onGridReady={onGridReady}
                                    onSelectionChanged={onSelectionChanged}
                                    gridRef={gridRef}
                                    rowSelection={"multiple"}
                                    rowClassRules={rowClassRules}
                                />
                                // find select document type                                
                                : headerName?.length == 0 ? <div className="mt-3"><p>No rows to show</p></div>
                                    : <div className="mt-3"><p>Select document type to populate table. </p></div>
                            }
                        </div>
                    </div>
                </div> */}
                {/* {ruleHide &&
                    <div className="p-2 ">
                        <div className="card p-2">
                            <div className="row col-md-12 justify-content-evenly">
                                <div className="col-md-2">
                                    <SelectInput
                                        margin="0"
                                        selectLabel="*Select rule"
                                        name="_rule_id"
                                        value={ruleType}
                                        disabled={(selection.length > 0) ? false : true}
                                        onChange={(e) => handleRuleType(e)}
                                        optionsList={ruleFormat}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <BasicButton
                                        text="Apply Rule"
                                        onClick={handleApplyRule}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <RadioButton
                                        optionsList={statusList}
                                        selectedValue={selectedValue}
                                        handleChange={(event) => handleRadioButton(event)}
                                    />
                                </div>
                                <div className="col-md-1">
                                    <button className="btn btn-light btn-sm" onClick={DownloadFile}><FileDownloadRoundedIcon fontSize="medium" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                } */}
                {ruleHide &&
                    <>
                        {/* <div className="col-md-6"></div> */}
                        <div className="card rulecard col-md-7 col-lg-8 col-sm-9 mt-2">
                            <div className="row">
                                <div className="col-md-3">
                                    {appObjects["Select rule"] &&
                                        <SelectInput
                                            margin="0"
                                            selectLabel="*Select rule"
                                            name="_rule_id"
                                            value={ruleType}
                                            disabled={(selection.length > 0) ? false : true}
                                            onChange={(e) => handleRuleType(e)}
                                            optionsList={ruleFormat}
                                        />}
                                </div>
                                <div className="col-md-2 pt-1 px-0">
                                    {appObjects["Apply Rule"] && <BasicButton
                                        text="Apply Rule"
                                        onClick={handleApplyRule}
                                        className={'applybtn'}
                                    />}
                                </div>
                                <div className="col-md-6 ">
                                    {appObjects["PDF"] && appObjects["CSV"] && appObjects["Excel"] && appObjects["TXT"] &&
                                        <RadioButton
                                            optionsList={statusList}
                                            selectedValue={selectedValue}
                                            handleChange={(event) => handleRadioButton(event)}
                                        />}
                                </div>
                                <div className="col-md-1 pt-1">
                                    {appObjects["Download"] && <button
                                        className="btn btn-light btn-sm"
                                        onClick={DownloadFile}
                                    ><FileDownloadRoundedIcon fontSize="medium" />
                                    </button>}
                                </div>
                            </div>
                        </div>
                    </>
                }

                {/* <Modal title="Converted data" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                    style={{ top: 20, minWidth: 300, maxWidth: 600 }}
                    footer={null}
                >
                    <hr />
                    <div
                        style={{ height: "70vh", overflowY: "auto" }}
                    >
                        <JsonViewer value={convertData} />
                    </div>
                </Modal> */}

                <AppliedRulesHistoryPopup
                    docid={docID}
                    open={isAppliedRulesHistoryOpen}
                    // onOk=
                    onCancel={onAppliedRulesHistoryCancel}
                    // data={docData}
                    data={historyData}
                    pdf={generatePDF}
                    csv={handleConvertToCSV}
                    transform={transformForDownload}
                    excel={exportWorksheet}
                // columns={columns}
                />

            </div >
            {/* </div> */}

            < Modal title="Converted data" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                style={{ top: 20, minWidth: 600, maxWidth: 800 }
                }
                footer={null}
            >
                <hr />
                <div
                >
                    <TabsAntd
                        defaultActiveKey="1"
                        items={tabItemsConvertedData}
                        onChange={(key) => onChangeTabsConvertData(key)}
                        centered
                    />
                </div>
            </Modal >

            <Modal title="Data" open={isRawDataModal} onOk={handleRawDataModalOk} onCancel={handleRawDataModalCancel}
                style={{ top: 20, minWidth: 600, maxWidth: 800 }}
                footer={null}
            >
                <hr />
                <div
                >
                    <TabsAntd
                        defaultActiveKey="1"
                        items={tabItemsRawData}
                        onChange={(key) => onChangeTabsRawData(key)}
                        centered
                    />
                </div>
            </Modal>
        </>
    )
}

export default NewDoc;