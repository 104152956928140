const disableBackButtonAfterSignout = () => {
    // // Replace the current history entry with a new one
    // const newUrl = window.location.href.split('?')[0]; // Remove any query parameters
    // window.history.replaceState(null, null, newUrl);

    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, null, window.location.href);
    };
  };

  export default disableBackButtonAfterSignout

  // preventBackNavigation.js

// Function to prevent back navigation
// function preventBackNavigation() {
//     window.history.pushState(null, null, window.location.href);
//     window.onpopstate = function () {
//       window.history.pushState(null, null, window.location.href);
//     };
//   }
  
//   export default preventBackNavigation;
  