import "../../../Assets/Styles/Myprofile.css";
import profile from "../../../Assets/Images/Avtar.png";
import { getSingleuser } from "../../../Services/AdminServices";
import React, { useState, useEffect } from "react";
import { MdOutlineVerified } from "react-icons/md";
import EditProfilePage from "../Admin/EditProfile";
import MessagePrefrence from "../Admin/Messagesettings";
import MyLogs from '../Admin/AdminLogs';
import { Tabs } from 'antd';
import ImageSlider from "../../Reusable_Components/ImageSlider";
import user_profile from "../../../Assets/Images/user_profile.png";
import prd1 from '../../../Assets/Images/alba_prd_1.png';
import prd2 from '../../../Assets/Images/alba_prd_2.png';
import prd3 from '../../../Assets/Images/alba_prd_3.png';
import prd4 from '../../../Assets/Images/alba_prd_4.png';

const UserSettingsScreen = () => {
  const email = localStorage.getItem("Logindata");
  const [isProfileSettings, setIsProfileSettings] = useState(true)
  const [isMessagePreference, setIsMessagePreference] = useState(false)

  const onChange = (key) => {
    console.log(key);
    if (key == 1) {
      setIsProfileSettings(true)
      setIsMessagePreference(false)
    }
    else if (key == 2) {
      setIsMessagePreference(true)
      setIsProfileSettings(false)
    }
    else if (key == 3) {
      setIsMessagePreference(false)
      setIsProfileSettings(false)
    }
  };

  // const items = [
  //   {
  //     key: '1',
  //     label: ` Profile Settings`,
  //     children: <EditProfilePage isProfileSettings={isProfileSettings} />,
  //   },

  //   {
  //     key: '2',
  //     label: `Message Preferences`,
  //     children: <MessagePrefrence isMessagePreference={isMessagePreference} />,
  //   },
  //   {
  //     key: '3',
  //     label: ` Logs`,
  //     children: <MyLogs />
  //   }
  // ];
  const images = [
    prd1, prd2, prd3, prd4
  ]
  const images1 = [
    prd2, prd4, prd1, prd3
  ]
  const images2 = [
    prd4, prd3, prd2, prd1
  ]

  return (
    <>
      <div className="container-fluid mt-2 px-4">
        <div className="d-flex col-md-12 col-lg-12 col-sm-12">
          <div className="col-md-4 col-lg-4 col-sm-12">
            <ImageSlider images={images} className={'slidecontainer'} />
          </div>
          <div className="col-md-4 col-lg-4 px-2 col-sm-12">
            <ImageSlider images={images1} className={'slidecontainer'} />
          </div>
          <div className="col-md-4 col-lg-4 col-sm-12">
            <ImageSlider images={images2} className={'slidecontainer'} />
          </div>
        </div>
      </div>
      <div className="mt-2 container px-4">
        <div className="col d-flex justify-content-center p-2">
          <img
            src={user_profile}
            alt="user profile"
            className="img-fluid"
            style={{ maxWidth: "35px", maxHeight: "33px" }}
          />
          <div className="px-3 heading-text">Profile Information</div>
        </div>
        <div className="col-md-12 justify-content-center">
          <EditProfilePage isProfileSettings={isProfileSettings} />
        </div>
      </div>
    </>
  )
};
export default UserSettingsScreen;
