import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { darken, lighten, styled } from '@mui/material/styles';
import axios from "axios";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Box from "@mui/material/Box";
import env from "../../../Services/Environment";
import { useNavigate } from "react-router-dom";
import { BsDownload } from 'react-icons/bs';
import { GrFormView } from 'react-icons/gr';
import ErrorCatch from "../../Reusable_Components/ErrorCatch";

const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .super-app-theme--api_active_03': {
      backgroundColor: getBackgroundColor("#F7F5BC", theme.palette.mode),
      '&:hover': {
          backgroundColor: getHoverBackgroundColor(
              "#F7F5BC",
              theme.palette.mode,
          ),
      },
      '&.Mui-selected': {
          backgroundColor: getSelectedBackgroundColor(
              "#F7F5BC",
              theme.palette.mode,
          ),
          '&:hover': {
              backgroundColor: getSelectedHoverBackgroundColor(
                  "#F7F5BC",
                  theme.palette.mode,
              ),
          },
      },
  },

  '& .super-app-theme--api_active_01': {
      backgroundColor: getBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode,
      ),
      '&:hover': {
          backgroundColor: getHoverBackgroundColor(
              theme.palette.success.main,
              theme.palette.mode,
          ),
      },
      '&.Mui-selected': {
          backgroundColor: getSelectedBackgroundColor(
              theme.palette.success.main,
              theme.palette.mode,
          ),
          '&:hover': {
              backgroundColor: getSelectedHoverBackgroundColor(
                  theme.palette.success.main,
                  theme.palette.mode,
              ),
          },
      },
  },
  '& .super-app-theme--api_active_02': {
      backgroundColor: getBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
      ),
      '&:hover': {
          backgroundColor: getHoverBackgroundColor(
              theme.palette.error.main,
              theme.palette.mode,
          ),
      },
      '&.Mui-selected': {
          backgroundColor: getSelectedBackgroundColor(
              theme.palette.error.main,
              theme.palette.mode,
          ),
          '&:hover': {
              backgroundColor: getSelectedHoverBackgroundColor(
                  theme.palette.error.main,
                  theme.palette.mode,
              ),
          },
      },
  },
}));

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function DocumentList() {
  const [data, setData] = useState([]);
  const [error,setError] = useState()
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    // fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${env.Backend_Url}/getAllApiUser`);
      setData(response.data.data.rows);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error)
    }
  };

  const ViewDocument = () => {
    navigate('/viewfile')
  }

  const downloadPDF = () => {
    const docDefinition = {
      content: [
        { text: "Data Table", style: "header" },
        { text: "\n" },
        {
          table: {
            headerRows: 1,
            body: [
              ["ID", "Name", "Email"],
              ...data.map((item) => [
                item.cust_req_id,
                item.requestor_name,
                item.email,
              ]),
            ],
          },
        },
      ],

      styles: {
        header: {
          fontSize: 18,
          bold: true,
        },
      },
    };

    pdfMake.createPdf(docDefinition).download("data-table.pdf");
  };

  const columns = [
    {
      field: "cust_req_id",
      headerName: "S.no",
      // width: 200,
      flex: 0.1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "DocumentName",
      headerName: "Document Name",
      // width: 250,
      flex: 0.3,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "DocumentType",
      headerName: "Document Type",
      // width: 200,
      flex: 0.2,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "DocumentFormat",
      headerName: "Document Format",
      // width: 200,
      flex: 0.2,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "action",
      headerName: "Download",
      headerClassName: "super-app-theme--header",
      // width: 200,
      flex: 0.1,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <button className="btn btn-light" onClick={downloadPDF}>
              <BsDownload />
            </button>
          </div>
        );
      },
    },

    {
      field: "action1",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      // width: 200,
      flex: 0.1,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <button className="btn btn-light" onClick={ViewDocument}>
              <GrFormView />
            </button>
          </div>
        );
      },
    },
  ];

  // const User = [
  //   {
  //     cust_req_id: 1,
  //     DocumentName: "RequestrDatails",
  //     DocumentType: "RequestList",
  //     DocumentFormat: "PDF",
  //   },
  // ];
  const user = []

  return (
    <>
    <ErrorCatch err={error}/>
      <div className="container" style={{ width: "90%" }}>
        <h6 className="text-start">
          <b className="text-dark">Document List</b>
        </h6>
        <Box
        >
          {/* <DataGrid
            sx={{ thead: "#160042" }}
            columns={columns}
            rows={User}
            getRowId={(data) => data.cust_req_id}
            checkboxSelection={false}
            hideFooter
          /> */}

          <StyledDataGrid
            sx={{
              height: "57vh", width: "100%",
              overflowY: "auto",
              borderColor: "secondary",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "#0E3386",
                fontSize: "12px",
                color: "white",
                // padding: "10px",

                // Set your desired header background color
              },
              "& .MuiDataGrid-cell": {
                fontSize: "10px",
                padding: "10px",
                borderRight: "2px solid #e0e0e0",
                // Adjust the font size as needed
                // Adjust the cell padding as needed
              },
            }}
            rowHeight={30}
            getRowId={(data) => data.cust_req_id}
            rows={user}
            columns={columns}
            checkboxSelection={false}
            hideFooter
          />
        </Box>
      </div>
    </>
  );
}

export default DocumentList;
