import React, { useEffect, useState, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import { AiFillFile } from 'react-icons/ai';
// import 'antd/dist/antd.css'
import 'ant-design-draggable-modal/dist/index.css'
import { getActiveCustomer, getAllCustomerList, getallUser, getRulesForAdmin, updateUserRole } from "../../../Services/AdminServices";
import Loader from "../../../utils/Loader/Loader";
import SelectInput from "../../Reusable_Components/SelectInput";
import InputBox from "../../Reusable_Components/InputBox";
import _ from "lodash";
import AgGridComponent from "../../Reusable_Components/AgGridComponent";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
import CheckboxComp from "../../Reusable_Components/CheckBox";
import plus from "../../../Assets/Images/arrow_closed.svg";
import minus from "../../../Assets/Images/arrow_open.svg";
import ButtonComp from "../../Reusable_Components/ButtonComp";
import { getAllRolesObject, getObjectsByRoles } from "../../../Services/UAMservices";
import { useSelector } from "react-redux";
import AWS from 'aws-sdk';
import { cognitoListUser, cognitoUpdateUserDetails } from "../../../Services/CognitoServices";

const RoleAccess = ({ isRoleAccess }) => {
    const userDetails = useSelector((state) => state)
    console.log("userDetails", userDetails)
    const adminEmail = userDetails.auth.userDetails.email
    const gridRef = useRef();
    const [customerList, setCustomerList] = useState([])
    const [customerName, setCustomername] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [isFilter, setIsFilter] = useState(true)
    const [isSoSelect, setIsSoSelect] = useState(null)
    const [soRowData, setSoRowData] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const [userList, setUserList] = useState([])
    const [selectedUser, setSelectedUser] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [userCurrentRole, setUserCurrentRole] = useState("")
    const [masterRoleObjects, setMasterRoleObjects] = useState([])
    const [masterCustomerList, setMasterCustomerList] = useState([])
    const [roleObjects, setRoleObjects] = useState([])
    const [currentSelection, setCurrentSelection] = useState([])
    const [selectedRole, setSelectedRole] = useState("")
    const [cognitoUserList, setCognitoUserList] = useState([])

    // Configure AWS SDK
    // AWS.config.update({ region: process.env.REACT_APP_AWS_COGNITO_REGION })
    AWS.config.update({
        region: process.env.REACT_APP_AWS_COGNITO_REGION,
        credentials: {
            accessKeyId: process.env.REACT_APP_COGNITO_ACCESS_KEYID,
            secretAccessKey: process.env.REACT_APP_COGNITO_SECRET_ACCESS_KEY
        }
    })
    const cognito = new AWS.CognitoIdentityServiceProvider()

    const columns = [
        {
            field: "roleDefId",
            headerName: "Role Def Id",
            flex: 0.2,
        },
        {
            field: "appsubmodulename",
            headerName: "SubModule Name",
            flex: 0.3,
        },
        {
            field: "appfunctionalityname",
            headerName: "Functionality Name",
            flex: 0.3,
        },
        {
            field: "appobjectname",
            headerName: "Object Name",
            flex: 0.3,
        },
        {
            field: "objecttypename",
            headerName: "Object Type",
            flex: 0.2,
        },
        {
            field: "isActive",
            headerName: "Active",
            flex: 0.2,
        }
    ]

    const cognitoGetAllUser = async () => {
        const params = {
            UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        }

        try {
            const response = await cognito.listUsers(params).promise();
            console.log('cognitoGetAllUserResponse', response)
        } catch (error) {
            console.error('cognitoGetAllUserError', error);
            toast.error(error.message)
        }
    }

    const getAllUserWithCognito = async () => {
        try {
            let getAllResponse = await cognitoListUser()
            console.log("getAllResponse_", getAllResponse)
            let data = getAllResponse.data.result
            setCognitoUserList(data)

        } catch (error) {
            console.log("cognitoGetAllerror", error)
        }
    }


    const handleAddPolicy = async () => {
        const now = new Date();
        const isoString = now.toISOString()

        let sendData = {
            "email": userEmail,
            "status_datetime": isoString,
            "status_reason": "UAM role change",
            "status_id": 1,
            "user_type": selectedRole,
            "adminEmail": adminEmail
        }
        const params = {
            UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
            Username: userEmail,
            UserAttributes: [
                {
                    Name: 'custom:role',
                    Value: selectedRole,
                },
            ]
        }
        console.log("sendData", sendData)
        console.log("params", params)
        if (userEmail == "") {
            toast.error("Please select User")
            return
        }
        if (selectedRole == "") {
            toast.error("Please check a Role")
            return
        }

        let newParams = {
            _email: userEmail,
            userAttributes: [
                {
                    Name: 'custom:role',
                    Value: selectedRole,
                },
            ]
        }
        setLoading(true)
        try {
            // const response = await cognito.adminUpdateUserAttributes(params).promise();
            // console.log('cognitoUpdateUserDetailsResponse', response);
            const response1 = await cognitoUpdateUserDetails(newParams)
            console.log('cognitoUpdateUserDetailsResponse', response1);
            let response2 = await updateUserRole(sendData)
            toast.success("Updated successfully.");
            setUserCurrentRole(selectedRole)
            fetchAllUser()
            // cognitoGetAllUser()
            getAllUserWithCognito()
        }
        catch (err) {
            setError(err)
        }
        finally {
            let finalArr = []
            roleObjects.map((item, i) => {
                item.isChecked = false
                finalArr.push(item)
            })
            console.log("finalRoleObj", finalArr)
            setRoleObjects(finalArr)

        }
        setLoading(false)
    }

    const fetchAllUser = async () => {
        setLoading(true);
        try {
            await getallUser().then((res) => {
                let response = res.data;
                console.log("alluser", res.data);
                response.map((item) => {
                    if (item._status_id === 2) {
                        item._status_id = "InActive";
                    } else if (item._status_id === 1) {
                        item._status_id = "Active";
                    } else {
                        item._status_id = "Pending";
                    }
                    return item;
                });
                response.sort((a, b) => b._user_id - a._user_id);
                setAllUsers([...response]);

            });
        }
        catch (err) {
            setError(err)
        }
        setLoading(false);
    };

    // end of change
    useEffect(() => {
        fetchAllUser()
        getAllCustomer()
        // getUAMrolesObject()
        getUAMObjectsByRoles()
        setUserEmail("")
        setCustomername("")
        setSelectedUser("")
        setUserCurrentRole("")
        setSelectedRole("")
    }, [isRoleAccess])

    const getUAMrolesObject = async () => {
        try {
            const response = await getAllRolesObject()
            console.log("getUAMrolesObject", response);
        }
        catch (error) {
            console.log("error", error);
            setError(error)
        }
    }

    const getUAMObjectsByRoles = async () => {
        try {
            const response = await getObjectsByRoles()
            console.log("getUAMObjectsByRoles", response);
            // let updatedRoleObjects = response.data.roleObjects.map((obj) => ({
            //     ...obj,
            //     isChecked: false
            // }))
            let masterData = []
            response.data.roleObjects.map((obj) => {
                masterData.push({
                    ...obj,
                    isChecked: false
                })
            })
            setMasterRoleObjects(masterData)
            // let updatedRoleObjects = []
            // response.data.roleObjects.map((obj) => {
            //     if (obj.roleName == "admin" || obj.roleName == "user") {
            //         updatedRoleObjects.push({
            //             ...obj,
            //             isChecked: false
            //         })
            //     }
            // })
            // setRoleObjects(updatedRoleObjects)
        }
        catch (error) {
            console.log("error", error);
            setError(error)
        }
    }

    const getAllCustomer = async () => {
        try {
            const response = await getAllCustomerList();
            console.log("getAllCustomer", response);
            if (response.status == 200) {
                let data = response.data.data
                setMasterCustomerList(data)
                let finalArr = []
                data.map((obj) => {
                    finalArr.push({
                        name: obj.comp_name,
                        value: obj.comp_id
                    })
                })
                setCustomerList(finalArr);
            }

        } catch (error) {
            console.log("error", error);
            setError(error)
        }
    };

    const handleCustomerName = (e) => {
        const { name, value } = e.target;
        console.log("valuehcn", value)
        setCustomername(value)
        let updatedRoleObjects = []
        let mainCompany = false
        masterCustomerList.map((obj) => {
            if (obj.comp_id == value) {
                if (obj.ismaincompany == 1) {
                    mainCompany = true
                }
            }
        })
        if (mainCompany) {
            masterRoleObjects.map((item) => {
                if (item.roleName == "super-admin" || item.roleName == "super-user" || item.roleName == "IT-Admin") {
                    updatedRoleObjects.push({
                        ...item,
                        isChecked: false
                    })
                }
            })
        }
        if (!mainCompany) {
            masterRoleObjects.map((item) => {
                if (item.roleName == "customer-admin" || item.roleName == "customer-user") {
                    updatedRoleObjects.push({
                        ...item,
                        isChecked: false
                    })
                }
            })
        }
        console.log("updatedRoleObjects", updatedRoleObjects)
        setRoleObjects(updatedRoleObjects)
        let finalArr = []
        allUsers.filter((obj) => {
            if (obj._comp_id == value) {
                finalArr.push({
                    name: obj._name,
                    value: obj._user_id
                })
            }
        })
        console.log("handleCustomerName", finalArr)
        setUserList(finalArr)
        setSelectedUser("")
        setUserEmail("")
        setUserCurrentRole("")
    };

    const handleUser = (e) => {
        const { value } = e.target;
        setSelectedUser(value)
        let finalArr = allUsers.filter(obj => obj._user_id == value)
        console.log("handleUser", finalArr)
        let { _email, _user_type } = finalArr[0]
        setUserEmail(_email)
        setUserCurrentRole(_user_type)
    }

    let gridApi;
    const onGridReady = (params) => {
        console.log("paramsApi_", params)
        gridApi = params.api;
    };

    const onSelectionChanged = () => {
        console.log("gridRef_", gridRef)
        const selectedRows = gridRef.current.api.getSelectedRows();
        console.log("selectedRows_", selectedRows)
    }

    const handleSelectedRoles = (index, id) => {
        console.log("handleSelectedRoles", index, id, roleObjects)
        let newRole = roleObjects.filter(obj => obj.roleId == id)[0].roleName
        setSelectedRole(newRole)
        let finalArr = []
        roleObjects.map((item, i) => {
            if (i == index) {
                item.isChecked = !item.isChecked
            }
            if (i !== index) {
                item.isChecked = false
            }
            finalArr.push(item)
        })
        console.log("finalRoleObj", finalArr)
        setRoleObjects(finalArr)
    }

    const getDatawithid = (change, data) => {
        console.log("getDatawithid", change, roleObjects, data)
        if (roleObjects.length > 0) {
            setIsSoSelect(change)
            let filteredRowData = roleObjects.filter(obj => {
                return obj.roleId == data
            })
            console.log("filteredRowData_", filteredRowData)
            setSoRowData(filteredRowData[0].objectAccess)
        }
    }

    const rowClassRules = {
        'even-row-so': (params) => params.node.rowIndex % 2 !== 0,
    };

    return (
        <>
            <Loader loading={loading} />
            <Toaster position="top-center" reverseOrder={false} />
            <ErrorCatch err={error} />
            <div className="container-fluid">
                {isFilter && <div className="row">
                    <div className="col-md-12">
                        <div className="card p-2">
                            <div className='row p-3'>

                                <div className="col-md-2 p-2">
                                    <SelectInput
                                        margin="0"
                                        selectLabel="*Company"
                                        name="_comp_id"
                                        value={customerName}
                                        onChange={(e) => handleCustomerName(e)}
                                        optionsList={customerList}
                                    />
                                </div>
                                <div className="col-md-3 p-2">
                                    <SelectInput
                                        margin="0"
                                        selectLabel="*User"
                                        name="_user"
                                        value={selectedUser}
                                        onChange={handleUser}
                                        optionsList={userList}
                                    />
                                </div>
                                <div className="col-md-3 p-2">
                                    <InputBox
                                        label="Email"
                                        name="_email"
                                        fullWidth
                                        value={userEmail}
                                        disabled={true}
                                    />
                                </div>
                                <div className="col-md-2 p-2">
                                    <InputBox
                                        label="Role"
                                        name="_role"
                                        fullWidth
                                        value={userCurrentRole}
                                        disabled={true}
                                    />
                                </div>

                                <div className="d-flex p-2 col-md-6 gap-3">
                                    <ButtonComp
                                        text="Add Roles"
                                        onClick={handleAddPolicy}
                                    />
                                    {/* <ButtonComp
                                        text="Remove Roles"
                                        type="secondary"
                                        onClick={handleAddPolicy}
                                    /> */}
                                </div>
                            </div>
                        </div>
                        <div className="mt-2">
                            <h6 className="page-title">Role Provision for Users</h6>
                        </div>
                    </div>
                </div>}

                <div className="mt-2"
                    style={{ height: "50vh", overflowY: "auto" }}
                >
                    <div className=" user-access-grid-header">
                        <div className="d-flex justify-content-between">
                            <div className="mb-0 d-flex justify-content-center so-access-cell-data-header pl-3" style={{ borderRight: "0.5px solid rgb(153 153 153)", height: '42px' }}>
                                <label className="mb-0 pt-2">Role Name</label>
                            </div>
                            {/* <label className="mb-0 pt-2 so-access-cell-data-header">Valid From</label>
                            <label className="vl mb-0 pt-2 so-access-cell-data-header">Valid To</label> */}
                        </div>
                    </div>
                    {roleObjects.length > 0 &&
                        roleObjects.map((obj, index) => {
                            return (<div className={index % 2 == 0 ? "mt-1 user-access-grid-border" : "mt-1 user-access-grid-border-UA"}>
                                <div className="d-flex justify-content-between">
                                    <div className="mb-0 d-flex so-access-cell-data pl-3" style={{ borderRight: "0.5px solid rgb(153 153 153)", height: '42px', paddingLeft: '5px' }}>
                                        <CheckboxComp className='mb-0 pl-5' isChecked={obj.isChecked} isLabelShow={false}
                                            onChange={() => handleSelectedRoles(index, obj.roleId)} name="policyCopy" />
                                        {index == isSoSelect ?
                                            <img src={minus} onClick={() => getDatawithid(null, obj.roleId)} style={{ height: '25px', width: '25px', cursor: 'pointer', marginTop: '8px' }} />
                                            : <img src={plus} onClick={() => getDatawithid(index, obj.roleId)} style={{ height: '25px', width: '25px', cursor: 'pointer', marginTop: '8px' }} />}
                                        <label className="mb-0 pt-2">{obj.roleName}</label>
                                    </div>
                                    {/* <label className="mb-0 pt-2 so-access-cell-data v1">{obj.policyName}</label> */}
                                    {/* <label className="vl mb-0 pt-2 so-access-cell-data">{obj.validfrom}</label>
                                    <label className="vl mb-0 pt-2 so-access-cell-data">{obj.validto}</label> */}

                                </div>
                                {
                                    index == isSoSelect ?
                                        <div className="p-3">
                                            <AgGridComponent
                                                rowData={soRowData}
                                                columnDef={columns}
                                                height={350}
                                                onGridReady={onGridReady}
                                                onSelectionChanged={onSelectionChanged}
                                                gridRef={gridRef}
                                                rowSelection={"multiple"}
                                                rowClassRules={rowClassRules}
                                            />
                                        </div>
                                        : null
                                }
                            </div>
                            )
                        })}
                </div>
            </div>
            {/* </div> */}

        </>
    )
}

export default RoleAccess;