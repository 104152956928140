import { Link } from "react-router-dom";

export const activeAPIColumn = [
  {
    // id: "9",
    field: "api_name",
    headerName: "API Name",
    // width: 150,
    flex: 0.3,
  },
  {
    // id: "1",
    field: "api_format_id",
    headerName: "Format",
    // width: 80,
    flex: 0.3,
  },
  {
    // id: "2",
    field: "app_name",
    headerName: "Application",
    // width: 170,
    flex: 0.4,
  },

  {
    // id: "3",
    field: "purpose_data_consum",
    headerName: "Description",
    // width: 180,
    flex: 0.4,
    editable: true
  },
  {
    field: "rule_name",
    headerName: "Rule Name",
    // width: 150,
    flex: 0.5,
  },
  {

    field: "api_status_name",
    headerName: "Status",
    // width: 90,   
    flex: 0.4,
    cellRenderer: (params) => {
      console.log("Coming ...", params);
      let val = params.data.api_status_name;

      if (val === "Active") {
        return (
          <div className="btnactive" role="button">
            Active
          </div>
        );
      } else if (val === "Deactive") {
        return (
          <div className="btnDeactive" role="button">
            InActive
          </div>
        );
      } else if (val === "In Progress") {
        return (
          <div className="btnenable" role="button">
            In Progress
          </div>
        );
      }
      else {
        return (
          <div className="btnpending" role="button">
            Pending
          </div>
        );
      }
    },
  },
  {
    field: "api_url",
    headerName: "API url",
    // width: 280,
    flex: 0.8,
    cellRenderer: (params) => {
      const { api_status_name, api_url } = params.data;
      return api_status_name === "Deactive" || api_status_name === "Pending" ? <>Don't Have Access</> : api_url;
    },
  },
];
