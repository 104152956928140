import "../../../Assets/Styles/Myprofile.css";
import profile from "../../../Assets/Images/Avtar.png";
import { getSingleuser } from "../../../Services/AdminServices";
import React, { useState, useEffect } from "react";
import { MdOutlineVerified } from "react-icons/md";
import EditProfilePage from "../Admin/EditProfile";
import MessagePrefrence from "../Admin/Messagesettings";
import MyLogs from "../Admin/AdminLogs";
import ActivityBoard from "../Admin/Activity";
import { Tabs } from "antd";
import { IconButton } from "@mui/material";
import CreateIcon from '@mui/icons-material/Create';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ImageSlider from "../../Reusable_Components/ImageSlider";
import user_profile from "../../../Assets/Images/user_profile.png";
import prd1 from '../../../Assets/Images/alba_prd_1.png';
import prd2 from '../../../Assets/Images/alba_prd_2.png';
import prd3 from '../../../Assets/Images/alba_prd_3.png';
import prd4 from '../../../Assets/Images/alba_prd_4.png';

const SubSettingsScreen = () => {
  const [MyDetails, setMyDetails] = useState();
  const email = localStorage.getItem("Logindata");
  const [isProfileSettings, setIsProfileSettings] = useState(true)
  const [isMessagePreference, setIsMessagePreference] = useState(true)
  // image upload function
  const [image, setImage] = useState('');
  const GetMyProfile = (email) => {
    getSingleuser(email).then((res) => {
      // console.log("MyData", res.data.data)
      setMyDetails(res.data.data[0]);
    });
  };
  useEffect(() => {
    GetMyProfile(email);
  }, []);

  const onChange = (key) => {
    console.log(key);
    if (key == 1) {
      setIsProfileSettings(true)
      setIsMessagePreference(false)
    }
    else if (key == 3) {
      setIsMessagePreference(true)
      setIsProfileSettings(false)
    }
  };

  // image upload function
  const [selectfile, setSelectfile] = useState('');
  const upload = (e) => {

    // setImage(e.target.files);
    console.log("img", e.target.files[0]);
    setSelectfile(e.target.files[0]);
  }
  console.log("select", selectfile)
  const handleUpload = () => {
    if (selectfile) {
      const formData = new FormData();
      formData.append('image', selectfile);

      // Replace 'YOUR_BACKEND_URL' with your actual backend API endpoint
      fetch('YOUR_BACKEND_URL', {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Upload successful:', data);
          // Handle any further logic or UI updates
        })
        .catch((error) => {
          console.error('Upload error:', error);
          // Handle error cases
        });
    }
  }

  // return (
  //   <>
  //     <div className="container">
  //       <h6 className="px-2">

  //       </h6>
  //       <div className="row  topCard mb-4 ">
  //         <div className="col-md-3" >
  //           <img src={profile} alt="avtar" className="img-fluid avtarimg" />
  //           {/* <IconButton className="i3"
  //         type="button"
  //         sx={{ p: "10px" }}
  //         aria-label="search"
  //          onClick={upload}
  //       >
  //         <CreateIcon />
  //       </IconButton> */}

  //           {/* <input type="file" onChange={upload} placeholder="Upload" className="i3"/>
  //       <button className="i4" onClick={handleUpload}>Upload</button> */}
  //         </div>
  //         <div className="col-md-6 d-flex justify-content-start">
  //           <div className="toptext mt-3">
  //             <h5>
  //               <b>{MyDetails?._name}</b>
  //               <MdOutlineVerified />
  //             </h5>
  //             <text>{MyDetails?._email}</text>
  //             <h5>
  //               <b>{MyDetails?._designation}</b>
  //             </h5>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="row ">
  //         <div className="col d-flex justify-content-center settingstab  pb-5">
  //           <Tabs
  //             defaultActiveKey="1"
  //             centered
  //             items={items}
  //             onChange={onChange}
  //             className="w-50 text-center"
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
  const images = [
    prd1, prd2, prd3, prd4
  ]
  const images1 = [
    prd2, prd4, prd1, prd3
  ]
  const images2 = [
    prd4, prd3, prd2, prd1
  ]
  return (
    <>
       <div className="container-fluid mt-2 px-4">
        <div className="d-flex col-md-12 col-lg-12 col-sm-12">
          <div className="col-md-4 col-lg-4 col-sm-12">
            <ImageSlider images={images} className={'slidecontainer'} />
          </div>
          <div className="col-md-4 col-lg-4 px-2 col-sm-12">
            <ImageSlider images={images1} className={'slidecontainer'} />
          </div>
          <div className="col-md-4 col-lg-4 col-sm-12">
            <ImageSlider images={images2} className={'slidecontainer'} />
          </div>
        </div>
      </div>
     
      <div className="mt-3 container-fluid px-4">
        <div className="col d-flex justify-content-center p-2">
          <img
            src={user_profile}
            alt="user profile"
            className="img-fluid"
            // style={{ maxWidth: "35px", maxHeight: "33px" }}
          />
          <div className="px-3 heading-text">Profile Information</div>
        </div>
        <div className="col-md-12 justify-content-center">
          <EditProfilePage isProfileSettings={isProfileSettings} />
        </div>
      </div>
    </>
  )

}
export default SubSettingsScreen;
