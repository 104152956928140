export const RulesColData = [
  // {
  //   field: "_rule_id",
  //   headerName: "ID",
  //   // width: 138,
  //   flex: 0.2,
  // },

  {
    // id: "1",
    field: "_rule_name",
    headerName: "Rule Name",
    // width: 225,
    flex: 0.5,
  },
  {
    // id: "2",
    field: "_description",
    headerName: "Description",
    // width: 225,
    flex: 0.5,
  },
  {
    // id: "2",
    field: "_spec_status_name",
    headerName: "Status",
    // width: 160,
    flex: 0.2,
    cellRenderer: (params) => {
      console.log("Coming ...", params);
     let val = params.data._spec_status_name;
     if (val ==="Approved") {
       return (
         <div className="btnactive" role="button">
           {val}
         </div>
       );
     } else if (val ==="Deactive") {
       return (
         <div className="btnDeactive" role="button">
           {val}
         </div>
       );
     } else {
       return (
         <div className="btnpending" role="button">
           {val}
         </div>
       );
     }
   },  
  },
];

