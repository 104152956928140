import "../../../Assets/Styles/AdminDasbord.css";
export const userColumns = [

  {
    
    field: "_user_id",
    headerName: "Id",
    flex:0.2,
    // width: 30,

  },
  {
    field: "_name",
    
    headerName: "Name",
    flex:0.4
    // width: 120,
  },

  {
    field: "_email",
    
    headerName: "Email",
    flex:0.5
    // width: 260,
  },

  // {
  //   field: "_status_id",
  //   
  //   headerName: "Status",
  //   width: 100,
  // },

  {
    field: "_comp_name",
    
    headerName: "Company",
    flex:0.4
    // width: 200,
  },

  {
    field: "_designation",
    
    headerName: "Designation",
    flex:0.4
    // width: 170,
  },

  {
    field: "_user_type",
    
    headerName: "Role",
    flex:0.4
   
  },

  // {
  //   field: "_address",
  //   
  //   headerName: "Address",
  //   width: 200,
  // },

  {
    field: "_status_id",
    headerName: "Status",
    
    // width: 80,
    flex:0.4,
    cellRenderer: (params) => {
      let val = params.data._status_id;
      console.log("params_",params);
      if (val === "Active") {
        return (
          <button className="btnenable" role="button">
           Active
          </button>
        );
      } else if (val === "InActive") {
        return (
          <button className="btnnormal" role="button">
            InActive
          </button>
        );
      } 
      else if (val === "Pending") {
        return (
          <button className="btnpending" role="button">
            Pending
          </button>
        );
      }
      else {
        return (
          <button className="button-34" role="button">
            {val}
          </button>
        );
      }
    },
  },
  // {
  //   field: "",
  //   headerClassName: "super-app-theme--header",
  //   headerName: "Reject",
  //   renderCell: (params) => {
  //     return (
  //       <button className="btn btn-sm btn-danger" role="button">
  //         {/* {params.formattedValue} */}
  //         Action
  //       </button>
  //     );
  //   },
  // }
  
];
