const companyMappingColumn = [
    {
        id: "1",
        field: "_id",
        headerName: "ID",
        // width: 80,
        flex: 0.2,
        headerClassName: "super-app-theme--header",
    },
    {
        id: "2",
        field: "socustomername",
        headerName: "SO Customer",
        // width: 150,
        flex: 0.5,
        headerClassName: "super-app-theme--header",
        editable: true,
        type: 'singleSelect',
        valueOptions: ['Market', 'Finance', 'Development'],
    },

    {
        id: "3",
        field: "pocustomername",
        headerName: "Customer",
        // width: 150,
        flex: 0.5,
        headerClassName: "super-app-theme--header",
        editable: true,
        type: 'singleSelect',
        valueOptions: ['Market', 'Finance', 'Development'],
    },
    // {
    //     id: "4",
    //     field: "adminname",
    //     headerName: "Admin Name",
    //     // width: 200,
    //     flex: 0.5,
    //     headerClassName: "super-app-theme--header",
    // },

    // {
    //     id: "5",
    //     field: "email",
    //     headerName: "Email",
    //     // width: 200,
    //     flex: 0.5,
    //     headerClassName: "super-app-theme--header",
    // },
    // {
    //     id: "6",
    //     field: "address",
    //     headerName: "Address",
    //     // width: 200,
    //     flex: 0.5,
    //     headerClassName: "super-app-theme--header",
    // },

];

export default companyMappingColumn