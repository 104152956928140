import ExcelPreviewer from "./ExcelPreviewer";
import PdfPreviewer from "./PdfPreviewer";
import TextPreviewer from "./TextPreviewer";

const FilePreviewer = (file,selectedValue) => {
    console.log("FilePreviewerFile", file)
    console.log("FilePreviewerselectedValue", selectedValue)
    if (file.selectedValue == 1) return <PdfPreviewer file={file.file} />;
    if (file.selectedValue == 2 || file.selectedValue == 4) return <TextPreviewer file={file.file} />;
    if (file.selectedValue == 3) return <ExcelPreviewer file={file.file} />;
    //   if (selectedValue === 'text') return <TextPreviewer file={file} />;

    return <div>Unsupported file type</div>;
}
export default FilePreviewer