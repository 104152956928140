import * as XLSX from "xlsx";
import { useEffect, useState } from "react";

function ExcelPreviewer({ file }) {
    console.log("ExcelPreviewer", file)
    const [data, setData] = useState(null);

    useEffect(() => {
        if (file && file instanceof Blob) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const workbook = XLSX.read(e.target.result, { type: 'array' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                setData(json);
            };
            reader.readAsArrayBuffer(file);
        } else {
            setData(null); // Handle case where file is not valid
        }
    }, [file]);

    if (!data) return <div>Loading...</div>;

    return (
        <div
            style={{ width: "100%", height: "500px", overflow: "auto" }}
        >
            <table>
                <tbody>
                    {data.map((row, i) => (
                        <tr key={i}>
                            {row.map((cell, j) => (
                                <td
                                    style={{ border: "1px solid black", padding: "5px" }}
                                    key={j}>{cell}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ExcelPreviewer;
