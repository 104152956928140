import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import { userLogsColumns } from "./userLogsColumn";
import Box from "@mui/material/Box";
import {
  getSingleUserFromLogs,
  getSingleUserApiLogs,
  getSingleUserFromLogsByDate,
} from "../../../../Services/AdminServices";
import { userApiLogsColumns } from "./apiLogsColumn";
import ErrorCatch from "../../../Reusable_Components/ErrorCatch";
import dayjs from 'dayjs';
import AgGridComponent from "../../../Reusable_Components/AgGridComponent";

const SpecificUserLogs = ({ logType, email, handleCancel, isUsage, fromDate, toDate }) => {
  console.log("email", email);
  const [data, setData] = useState([]);
  const [error, setError] = useState()
  const userLogsCol = userLogsColumns;
  const userApiLogsCol = userApiLogsColumns;
  const [column, setColumn] = useState(userLogsCol);

  // useEffect(() => {
  //   setData([])
  // }, [handleCancel])

  const getLogs = async () => {

    if (isUsage) {
      let payload = {
        email: email,
        fromdate: fromDate,
        todate: toDate
      }
      await getSingleUserFromLogsByDate(payload)
        .then((res) => {
          console.log("getSingleUserFromLogsres", res.data.data);
          let response = res.data.data;
          response.forEach((obj) => {
            obj["_datetime"] = moment(obj._datetime).format("DD-MM-YYYY hh:mm:ss A");
            obj["_status_datetime"] = moment(obj._status_datetime).format(
              "DD-MM-YYYY H:mm:ss"
            );
            obj["_added_on"] = dayjs(obj._added_on).format('DD MMMM, YYYY h:mm:ss A');
            console.log("added_on_", obj['_added_on'], moment(obj._added_on).format("DD-MM-YYYY h:mm:ss a"),)
          });
          setData(response);
        })
        .catch((error) => {
          console.log(("getSingleUserFromLogserror", error));
          setError(error)
        });
    } else {
      await getSingleUserFromLogs(email)
        .then((res) => {
          console.log("getSingleUserFromLogsres", res.data.data);
          let response = res.data.data;
          console.log("getSingleUserFromLogsresponse", response.length)
          let finalArr = []
          response.forEach((obj) => {
            obj["_datetime"] = moment(obj._datetime).format("DD-MM-YYYY hh:mm:ss A");
            obj["_status_datetime"] = moment(obj._status_datetime).format(
              "DD-MM-YYYY H:mm:ss"
            );
            obj["_added_on"] = dayjs(obj._added_on).format('DD MMMM, YYYY h:mm:ss A');
            finalArr.push(obj)
            console.log("added_on_", obj['_added_on'], moment(obj._added_on).format("DD-MM-YYYY h:mm:ss a"),)
          });
          console.log("resData", response)
          // setData(response);
          console.log("finalArr", finalArr)
          setData(finalArr);
        })
        .catch((error) => {
          console.log(("getSingleUserFromLogserror", error));
          setError(error)
        });
    }

  };


  const getApiLogs = async () => {
    await getSingleUserApiLogs(email)
      .then((res) => {
        console.log("getSingleUserFromLogsres", res.data.data);
        let response = res.data.data;
        setData(response);
      })
      .catch((error) => {
        console.log("getSingleUserFromLogserror", error);
        setError(error)
      });
  };
  useEffect(() => {
    console.log("logType", logType, email);

    if (logType == "userlogs") {
      console.log("logType", logType, email);
      setColumn(userLogsCol);
      getLogs();
    } else if (logType == "apilogs") {
      console.log("logType", logType, email);
      setColumn(userApiLogsCol);
      getApiLogs();
    }
  }, [email]);

  function generateRandom(param) {
    console.log("generateRandomParams", param)
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const rowClassRules = {
    'even-row': (params) => params.node.rowIndex % 2 !== 0,
  };

  return (
    <>
      <ErrorCatch err={error} />
      {data.length > 0 ? (
        <div className="datatable  px-2 p-3 "
          // style={{ height: "60vh" }}
        >
          <div className="row">
            <h6>
              <b>Logs</b>
            </h6>
          </div>

          {/* <DataGrid
            sx={{
              borderColor: "secondary",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
              "& .MuiDataGrid-columnHeader": {
                backgroundColor: "#0E3386",
                fontSize: "12px",
                color: "white",
                padding: "10px",

                // Set your desired header background color
              },
              "& .MuiDataGrid-cell": {
                fontSize: "10px",
                padding: "10px",
                borderRight: "2px solid #e0e0e0",
                // Adjust the font size as needed
                // Adjust the cell padding as needed
              },
            }}
            // rows={data}
            rows={sampleData}
            // rowHeight={30}
            getRowId={(data) => data._record_id}
            // getRowId={(row) => generateRandom(row)}
            // columns={logType == "userlogs" ? userLogsColumns : logType == "apilogs" ? userApiLogsColumns : ""}
            columns={column}
            // pageSize={10}
            pagination={false}
            disableMultipleRowSelection={true}
            handleChangeCheckbox
            checkboxSelection={false}
            hideFooter={true}
            onCellClick={(params) => params}
            // rowsPerPageOptions={[9]}
            // rowsPerPageOptions={[10, 25, 50, 100]}
          /> */}

          <AgGridComponent
            rowData={data}
            columnDef={column}
            rowClassRules={rowClassRules}
          />
        </div>
      ) : (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col d-flex justify-content-center">
              {" "}
              No Rows to show
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SpecificUserLogs;
