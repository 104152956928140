import React, { useEffect } from "react";
import bg2 from "../../../Assets/Images/landing.jpg";
import "../../../Assets/Styles/Landingpage.css";
import Navbar from "../Homepages/Navbar";
import Numberofusers from "../Homepages/UserStatus";
import { useNavigate, useParams } from "react-router-dom";
import { isVerified } from "../../../Services/UserServices";
import { useState } from "react";
import { Modal } from "antd";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../../../utils/Loader/Loader";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
import CarouselContainer from "./CarouselContainer";
import Footer from "./Footer";
import Signin from "./Signin";
import CognitoSignup from "./CognitoSignup";

const Home = () => {
  const { email, otp } = useParams();
  const [userEmail, setUserEmail] = useState()
  const [userOtp, setUserOtp] = useState()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [status, setStatus] = useState("")
  const [showSignupCognito, setIsShowSignupCognito] = useState(false)
  const [cognitoTitle, setCognitoTitle] = useState("SignIn")
  const [showSignin, setShowSignin] = useState(false)
  const [isshowSignin, setIsShowSignin] = useState(true)
  const [showPasswordReset, setShowPasswordReset] = useState(false)
  const [showForgotPassword, setShowForgotPassword] = useState(false)

  useEffect(() => {
    console.log("verificationPage", email, otp)
  }, [])

  useEffect(() => {
    if (undefined !== userEmail && undefined !== userOtp) {
      handleVerify(userEmail, userOtp);
    }
  }, [userEmail])

  const handleVerify = async (email, otp) => {
    // otp.otp = value.otp;
    setLoading(true)
    await isVerified({ email: email, otp: otp })
      .then((res) => {
        console.log("OTP verification done", res);
        if (res.status == 200) {
          console.log("isverifiedres", res)
          setIsModalOpen(true)
          setStatus("Thank You ! Your email address has been verified")
          // toast.success("otp verified successfully.");
          // setTimeout(() => {
          //     navigate("/");
          // }, 10000);
        } else {
          toast.error("Something went wrong, try again");
        }
      })
      .catch((error) => {
        console.log(error);
        // toast.error(error.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        // });
        setError(error)
      });
    setLoading(false)
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    navigate("/")
  };

  useEffect(() => {
    if ((undefined !== email || email !== "") && (undefined !== otp || otp !== "")) {
      console.log("handleverify", email, otp)
      // handleVerify(email, otp);
      setUserEmail(email)
      setUserOtp(otp)
    }
  }, [email, otp])

  const handleShowSignin = () => {
    setShowSignin(!showSignin)
  }

  const closeSignin = () => {
    setShowSignin(false)
  }
  const handleShowSignupCognito = () => {
    setIsShowSignupCognito(true)
  }

  const closeSignupCognito = () => {
    setIsShowSignupCognito(!showSignupCognito)
    setIsShowSignin(true)
    setShowForgotPassword(false)
    setShowPasswordReset(false)
    setCognitoTitle("SignIn")
  }



  return (
    <div>
      <Navbar onclick={handleShowSignin} onclickCognito={handleShowSignupCognito} />
      <CarouselContainer
      // showSignin={showSignin} 
      />
      <Modal
        className="signin-modal"
        open={showSignin}
        onCancel={closeSignin}
        footer={null}
        maskClosable={false}
      >
        <Signin />
      </Modal>

      <Modal
        // title="Cognito"
        title={cognitoTitle}
        className="signin-modal"
        open={showSignupCognito}
        onCancel={closeSignupCognito}
        footer={null}
        maskClosable={false}
      >
        <CognitoSignup isOpen={showSignupCognito} isshowSignin={isshowSignin} showForgotPassword={showForgotPassword} showPasswordReset={showPasswordReset}   setIsShowSignupCognito={setIsShowSignupCognito} setShowPasswordReset={setShowPasswordReset} setShowForgotPassword={setShowForgotPassword} setIsShowSignin={setIsShowSignin}  setCognitoTitle={setCognitoTitle} />
      </Modal>

      <Footer />
    </div>
  )
};

export default Home;
