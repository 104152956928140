import { Progress, Space } from "antd";
import { useState, useEffect } from 'react'
import "../../../Assets/Styles/AdminDasbord.css";
import { getallUser } from "../../../Services/AdminServices";
import AllUserFeedback from '../Admin/Feedback'
import { FaUserFriends, FaRegBuilding } from 'react-icons/fa'
import { getActiveCompony } from "../../../Services/AdminServices";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
import ImageSlider from "../../Reusable_Components/ImageSlider";
import prd1 from '../../../Assets/Images/alba_prd_1.png';
import prd2 from '../../../Assets/Images/alba_prd_2.png';
import prd3 from '../../../Assets/Images/alba_prd_3.png';
import prd4 from '../../../Assets/Images/alba_prd_4.png';


const AdminDashboard = () => {
  const [userCount, setUserCount] = useState()
  const [companyCount, setCompanyCount] = useState()
  const [error, setError] = useState()

  const Alluser = async () => {
    try {
      await getallUser().then((res) => {
        let response = res.data;
        setUserCount(response.length)
      });
    }
    catch (err) {
      setError(err)
    }
  }

  useEffect(() => {
    Alluser();
  }, []);

  const ActiveCompany = async () => {
    try {
      await getActiveCompony().then((res) => {
        let response = res.data.data;
        setCompanyCount(response.length);
      });
    }
    catch (err) {
      setError(err)
    }
  };

  useEffect(() => {
    ActiveCompany();
  }, []);

  const images = [
    prd1, prd2, prd3, prd4
  ]
  const images1 = [
    prd2, prd4, prd1, prd3
  ]
  const images2 = [
    prd4, prd3, prd2, prd1
  ]


  return (
    <>
      <ErrorCatch err={error} />
      <div className="container-fluid mt-2 px-4">
        <div className="d-flex col-md-12 col-lg-12 col-sm-12">
          <div className="col-md-4 col-lg-4 col-sm-12">
            <ImageSlider images={images} className={'slidecontainer'} />
          </div>
          <div className="col-md-4 col-lg-4 px-2 col-sm-12">
            <ImageSlider images={images1} className={'slidecontainer'} />
          </div>
          <div className="col-md-4 col-lg-4 col-sm-12">
            <ImageSlider images={images2} className={'slidecontainer'} />
          </div>
        </div>
      </div>
      <div class="container-fluid mt-3">
        <div className="row mt-3">
          <div className="col d-flex justify-content-center">
            <div class="card text-white bg-success mb-3 w-75 p-4 mb-3" >
              <div className="row ">
                <div className="col-md-4 d-flex justify-content-center">
                  <button className="btn btn-sm" style={{ backgroundColor: "#A2CDB0", color: "#fff" }}><FaUserFriends /></button>
                </div>
                {/* added md-4 to bring active users to center */}
                <div className="col-md-4">
                  <h6 className="text-center mt-2 abc"><b>Active Users</b></h6></div>
              </div>
              <h6 className="justify-content-center align-items-center text-center" > <b> {userCount}</b></h6>
              <div class="progress mt-3" style={{ border: "0.1px solid white" }}>
                <div
                  class="progress-bar bg-success"
                  role="progressbar"
                  style={{ width: "5%" }}
                  aria-valuenow={userCount}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
          <div className="col d-flex justify-content-center">
            <div class="card text-white active-company-bg mb-3 w-75 p-4 mb-3" >
              <div className="row d-flex">
                <div className="col d-flex justify-content-center mt-3">
                  <div>
                    <button className="btn btn-sm" style={{ backgroundColor: "#78C1F3", color: "#fff" }}>< FaRegBuilding /></button></div>
                </div>

                <div className="col mt-3"> <h6 style={{ whiteSpace: "nowrap" }}><b>Active Company</b></h6></div>
                <div className="col">
                  <Space wrap style={{ color: "white" }}>

                    <Progress type="circle" percent={companyCount} format={(percent) => `${percent}`} size={80} style={{ background: "#fff", borderRadius: 63 }} />
                  </Space>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="container-fluid px-4 mt-2">
          <div className="row"
            style={{ overflowY: "auto" }}
          >
            <AllUserFeedback />
          </div>
        </div>
      </div>


    </>
  );
};

export default AdminDashboard;
