import toast, { Toaster } from "react-hot-toast";
import { Formik } from "formik";
import * as Yup from "yup";
import TextField from '@mui/material/TextField';
import { allFaqList, getManualDetails, searchFaq, updateFaqfun } from "../../../Services/AdminServices";
import { AddFaqfun } from "../../../Services/AdminServices";
import FaqList from '../Admin/FaqList';
import React, { useEffect, useState } from "react";
import { messages } from "../../Messages";
import InputBox from "../../Reusable_Components/InputBox";
import BasicButton from "../../Reusable_Components/Button";
import Loader from "../../../utils/Loader/Loader";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
import { useSelector } from "react-redux";
import download from '../../../Assets/Images/download.png';
import env from "../../../Services/Environment";
import { PiDownloadSimple } from "react-icons/pi";


const AddFaq = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [faqData, setFAQData] = useState([]);
  const schema = () => {
    return Yup.object().shape({
      question: Yup.string().required(
        "You cant submit form without adding any question."
      ),
      answer: Yup.string().required("Answer of question is required."),
    });
  };

  // uam role object start
  const userDetails = useSelector((state) => state)
  const appsubmodulename = "FAQ"
  const appfunctionalityname = "FAQ"
  const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
  const userDetailsLog = userDetails.auth.userDetails
  const [appObjects, setAppObjects] = useState(
    {
      "Submit": false,
    }
  )
  const [isEdit, setIsEdit] = useState(false)
  const [faqQuestion, setFaqQuestion] = useState('')
  const [faqAnswer, setFaqAnswer] = useState('')
  const [faqId, setFaqId] = useState()

  const [manualDetails, setManualDetails] = useState([])

  console.log("userDetails", userDetails)

  const updateAppObjects = (arr) => {
    let objectsArray = []
    arr.map((obj) => {
      console.log("updateButtons", obj)
      // if (obj.objecttypename == "button") {
      objectsArray.push(obj)
      // }
    })
    const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
      acc[appobjectname] = true;
      return acc;
    }, {});
    setAppObjects({ ...appObjects, ...finalObject })
    console.log("objectsArray", objectsArray)
    console.log("finalObject", finalObject)
  }

  useEffect(() => {
    console.log("appObjects", appObjects)
  }, [appObjects])

  useEffect(() => {
    console.log("userRoleObjects", userRoleObjects)
    const filteredList = []
    userRoleObjects.filter((obj) => {
      if (obj.appsubmodulename == appsubmodulename && obj.appfunctionalityname == appfunctionalityname) {
        filteredList.push(obj)
      }
    })

    console.log("filteredList", filteredList)
    updateAppObjects(filteredList)

  }, [])

  // uam role object end

  const handleDownload = (fileName) => {
    console.log("handleDownload_")
    fetch(`${env.Backend_Url}/downloadmanual/${fileName}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    })
      .then(response => {
        if (response.ok) {
          return response.blob(); // Convert response to a blob
        }
        throw new Error('Network response was not ok.');
      })
      .then(blob => {
        // Create a URL for the blob and initiate the download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.pdf`); // Name the file
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }

  const fetchAllFAQ = async () => {
    setLoading(true)
    try {
      const response = await allFaqList()
      if (response.status == 200) {
        setFAQData(response.data.data.rows);
      }
      else {
        toast.error(messages.noDataFound)
      }
    }
    catch (err) {
      setError(err)
    }
    setIsEdit(false)
    setFaqAnswer('')
    setFaqQuestion('')
    setFaqId('')
    setLoading(false)
  };

  const CallingFAQ = () => {
    setTimeout(() => {
      fetchAllFAQ()
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (faqQuestion == "") {
      toast.error("Please provide the question.")
      return
    }
    if (faqAnswer == "") {
      toast.error("Please provide the answer.")
      return
    }
    setLoading(true)
    if (isEdit) {
      let payload = {
        "id": faqId,
        "question": faqQuestion,
        "answer": faqAnswer
      }
      await updateFaqfun(payload)
        .then((res) => {
          if (res.data.success === true) {
            toast.success("Faq's Updated Successfully.");
            fetchAllFAQ()
          } else {
            toast.error(messages.fillAllFields);
          }
        })
        .catch((error) => {
          // toast.error(error.message, {
          //   position: "top-right",
          //   autoClose: 5000,
          // });
          setError(error)
        });
    } else {
      let payload = {
        "question": faqQuestion,
        "answer": faqAnswer
      }
      await AddFaqfun(payload)
        .then((res) => {
          if (res.data.success === true) {
            toast.success("Faq's Added Successfully.");
            fetchAllFAQ()
          } else {
            toast.error(messages.fillAllFields);
          }
        })
        .catch((error) => {
          // toast.error(error.message, {
          //   position: "top-right",
          //   autoClose: 5000,
          // });
          setError(error)
        });
    }
    setLoading(false)
  };

  useEffect(() => {
    fetchAllFAQ()
    getManualDetailsByRole()
  }, [])

  useEffect(()=>{
    console.log("itemmanual_",manualDetails)
  },[manualDetails])

  const handleCancel = () => {
    setIsEdit(false)
    setFaqAnswer('')
    setFaqQuestion('')
    setFaqId('')
  }

  const getManualDetailsByRole = async () => {
    console.log("manualRoleId_", userDetailsLog);
    let payload = {
      role: userDetailsLog.role_id
    }
    let manualResponse = await getManualDetails(payload)
    console.log("manualResponse_", manualResponse)
    if (manualResponse.status == 200) {
      setManualDetails(manualResponse.data.data)
    }
  }

  return (
    <>
      <Loader loading={loading} />
      <Toaster position="top-center" reverseOrder={false} />
      <ErrorCatch err={error} />
      <div class="container-fluid">

        <div class="row mt-3">
          <div className="d-flex justify-content-between mt-3">
            <div className="px-4">
              <p className="page-title px-2">Frequently Asked Questions</p>
            </div>
            <div className="d-flex">
              <div className="d-flex">
                <p className="page-title">Latest Version</p>:<p className="colorblue"> V3.0.6</p>
              </div>
              <div className="d-flex px-3">
                <p className="page-title">Release Date</p>:<p className="colorblue">{`19/09/2024`}</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 order-md-2 mt-3">
            <div className="Formikform p-3 divStyle" style={{ backgroundColor: "white" }} >
              {/* <Formik
                validationSchema={schema}
                initialValues={initialValues}
                onSubmit={async (values, { resetForm }) => {
                  try {
                    await handleSubmit(values);
                    resetForm();
                  }
                  catch (err) {
                    setError(err)
                  }
                }}
                // onSubmit={handleSubmit()}
              > */}
              {/* {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => ( */}
              <div className="d-flex justify-content-start" >
                <div className="form container-fluid">
                  <form class="row g-2 p-2" onSubmit={handleSubmit}>
                    <div className="text-center"></div>

                    <h6 class="mt-2">
                      <b className="page-title">{isEdit ? `Edit FAQs` : `Add FAQs`}</b>
                    </h6>
                    <div>

                      <InputBox
                        label="*Question"
                        name="question"
                        fullWidth
                        onChange={(e) => setFaqQuestion(e.target.value)}
                        value={faqQuestion}
                      />

                      {/* <TextField
                            type="text"
                            variant="outlined"
                            name="question"
                            onChange={handleChange}
                            value={values.question}
                            label="*Questions"
                            size="small"
                            sx={{width:"100%",  borderRadius: "20px"}}

                          /> */}
                      {/* <input
                            type="text"
                            name="question"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.question}
                            placeholder="Add Questions"
                            className="form-control inp_text"
                          /> */}

                      {/* <p className="error text-danger">
                            {errors.question &&
                              touched.question &&
                              errors.question}
                          </p> */}
                    </div>

                    <div className='mt-3'>
                      <InputBox
                        label="*Answer"
                        name="answer"
                        fullWidth
                        multiline
                        rows={2}
                        onChange={(e) => setFaqAnswer(e.target.value)}
                        value={faqAnswer}
                      />

                      {/* <textarea
                            type="text"
                            name="answer"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.answer}
                            placeholder="*Answer"
                            className="form-control"
                          /> */}

                      {/* <p className="error text-danger">
                            {errors.answer && touched.answer && errors.answer}
                          </p> */}
                    </div>


                    <div className="d-flex justify-content-end">
                      {isEdit && <button class="btn btn-primary mx-2" onClick={handleCancel}>
                        Cancel
                      </button>}

                      {appObjects["Submit"] && <BasicButton
                        text={isEdit ? "Update" : "Submit"}
                        type="submit"
                      />}
                    </div>
                  </form>
                </div>
              </div>

              {/* )} */}
              {/* </Formik> */}
            </div>

            <div className="mt-3">
              <p className="page-title"
                style={{ fontSize: "16px" }}
              >Manuals</p>
            </div>
            <div className="card col-md-6 mt-2"
              style={{ width: "100%" }}
            >
              <div className="px-2 py-2"
                style={{ backgroundColor: "#094FA3", height: "40px" }}
              >
                <p className="page-title"
                  style={{ color: "#ffffff" }}
                >Manuals</p>
              </div>
              {manualDetails.length > 0 && manualDetails.map((item, inx) => {
                return (
                  <div className="">
                    <div className="px-2 d-flex py-2" onClick={() => handleDownload(item.name)}>
                      {/* <img src={download} className='downicon'></img> */}
                      <div className="downicon2">
                        <PiDownloadSimple />
                      </div>
                      <div className="manual px-2">
                        {item.name}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          <div class="col-md-6 order-md-1">
            <FaqList faqData={faqData} setFaqQuestion={setFaqQuestion} setFaqAnswer={setFaqAnswer} setFaqId={setFaqId} setFAQData={setFAQData} setIsEdit={setIsEdit} />
          </div>
        </div>
      </div>

    </>
  );
};

export default AddFaq;
