import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import env from "../../../../Services/Environment";
import { getAllParameterRules,setDocumentRules } from "../../../../Services/AdminServices";
import Loader from "../../../../utils/Loader/Loader";
import ErrorCatch from "../../../Reusable_Components/ErrorCatch";

function SetRulesForDocuments({handleOk,selectedRow}) {
  const [apiFormatState, setApiFormatState] = useState([]);
  const [apiFormatState1, setApiFormatState1] = useState([]);
  const [apiFormatState2, setApiFormatState2] = useState([]);
  const [apiFormatState3, setApiFormatState3] = useState([]);
  const [apiDetailState, setApiDetailState] = useState([]);
const [parameterValue, setParameterValue] = useState("")
const [paramDocValue, setParamDocValue] = useState("")
  const [parameter, setParameter] = useState({
    name: "Select a Parameter Type",
    value: ""
  })
  const [loading, setLoading] = useState(false)
  const [error,setError] = useState()
  const navigate = useNavigate();

  const initialValues = {
    // _format_id: "",
    // _document_id: "",
    // _paramvalue_dockey: "",
    _paramvalue_doc: "",
    _parameter_id: "",
    // _rule_id : selectedRow._rule_id,
    // _keys_parameter_id: "",
    // _documetkeys_id: "",
  };

  const [inputData, setInputData] = useState(initialValues);

  const getAPIFormat = async () => {
    try {
      const response = await axios.get(
        `${env.Backend_Url}/select_parameter_rule_apply_keys`
      );
      // console.log(response.data.data);
      setApiFormatState(response.data.data);
    } catch (error) {
      console.log(error);
      setError(error)
    }
  };
  const getAPIFormat1 = async () => {
    try {
      const response = await axios.get(`${env.Backend_Url}/documentkeys`);
      // console.log(response.data.data);
      setApiFormatState1(response.data.data);
    } catch (error) {
      console.log(error);
      setError(error)
    }
  };
  const getAPIFormat2 = async () => {
    try {
      const response = await axios.get(`${env.Backend_Url}/documents_name`);
      // console.log(response.data.data);
      setApiFormatState2(response.data.data);
    } catch (error) {
      console.log(error);
      setError(error)
    }
  };
  const getAPIFormat3 = async () => {
    try {
      const response = await axios.get(`${env.Backend_Url}/response_format`);
      // console.log(response.data.data);
      setApiFormatState3(response.data.data);
    } catch (error) {
      console.log(error);
      setError(error)
    }
  };

  const FetchApiDetails = async () => {
    try {
      const response = await getAllParameterRules()
      console.log("getParameterRules", response);
      let res = response.data.data
      let finalArr = []
      res.map((obj)=>{
        if (obj._paramtype == "Document wise"){
          finalArr.push(obj)
        }        
      })
      console.log("finalArr",finalArr)
      setApiDetailState(finalArr);
    } catch (error) {
      console.log(error);
      setError(error)
    }
  };

  useEffect(() => {
    FetchApiDetails();
    getAPIFormat1();
    getAPIFormat2();
    getAPIFormat3();
    getAPIFormat();

    setParameter({
      name: "Select a Parameter Type",
      value: ""
    })
    setParameterValue("")
    setParamDocValue()
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  const handleParameterValue = (e) => {
    const { name, value } = e.target;
    setParameterValue(value)
  };

  const handleparamDocValue = (e) => {
    const { name, value } = e.target;
    setParamDocValue(value)
  };

  

  const onSubmitRequestApi = async (e) => {
    setLoading(true)
    e.preventDefault();

    const {
      // _format_id,
      // _document_id,
      // _paramvalue_dockey,
      _paramvalue_doc,
      _parameter_id,
      _rule_id,
      // _keys_parameter_id,
      // _documetkeys_id,
    } = inputData;

    let sendData = {
      "_parameter_id":parameterValue,
      "_paramvalue_doc": paramDocValue,
      "_rule_id": selectedRow._rule_id
    }

    // if (
    //   !_paramvalue_doc ||
    //   !_parameter_id ||
    //   !_keys_parameter_id ||
    //   !_documetkeys_id ||
    //   !_paramvalue_dockey ||
    //   !_document_id ||
    //   !_format_id
    // ) {
    //   toast.error("Please fill all fields...");
    // } 
    // else {
      try {
        const response = await setDocumentRules(sendData)

        if (response.status === 200) {
          // setInputData(initialValues);
          toast.success("your request send successfully");
          handleOk()
        }
        // else {
        //   toast.error("Something went wrong, Can you please try again");
        // }
      } catch (error) {
        // setInputData(initialValues);
        // toast.error(error.response.data.msg, {
        //   position: "top-right",
        //   autoClose: 5000,
        // });
        setError(error)
      }
    // }
    setLoading(false)
  };

  return (
    <>
    <Loader loading={loading} />
    <ErrorCatch err={error}/>
      <div className="container">
        <div className="row">
          <div className="col text-center mb-3">
            <h6>
              <b>Set Document Rules</b>
            </h6>
          </div>
        </div>

        <div className="row">
          <form>
            <select
              name="_parameter_id"
              onChange={(e) => handleParameterValue(e)}
              class="form-control"
            >
              {/* <option>select</option> */}
              <option value={parameter.value} disabled selected hidden>{parameter.name}</option>
              {apiDetailState.map((val) => {
                const { _parameter_id, _paramname } = val;
                return <option value={_parameter_id}>{_paramname}</option>;
              })}
            </select>

            <div className="mt-3 mb-3">
              <input
                type="text"
                name="_paramvalue_doc"
                // value={inputData._paramvalue_doc}
                value={paramDocValue}
                // onChange={(e) => handleInput(e)}
                onChange={(e) => handleparamDocValue(e)}
                class="form-control"
                placeholder="Value"
              ></input>
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="submit"
                disabled={(parameterValue !=="") && (paramDocValue !== "") ? false : true}
                onClick={(e) => onSubmitRequestApi(e)}
                class="float-start btn btn-primary"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default SetRulesForDocuments;
