import { DataGrid } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import { userColumns } from "../../../Pages/CustomerAdmin/Employee/EmployeTabledata";
import UserStatusPage from "../../../Pages/Admin/UserStatus";
import { useEffect, useState } from "react";
import AssignCustomerRole from "./.../../../../Admin/AssignRole";
import { getAllEmployee } from "../../../../Services/UserServices";
import { Modal } from "antd";
import { GrFormView } from 'react-icons/gr';
import { FaUserLock } from 'react-icons/fa';
import { GiSightDisabled } from 'react-icons/gi';
import Loader from "../../../../utils/Loader/Loader";
import AgGridComponent from "../../../Reusable_Components/AgGridComponent";
import ImageSlider from "../../../Reusable_Components/ImageSlider";
import disablelogo from "../../../../Assets/Images/disable.png";
import pendinglogo from "../../../../Assets/Images/pendinglogo.png"

import prd1 from '../../../../Assets/Images/alba_prd_1.png';
import prd2 from '../../../../Assets/Images/alba_prd_2.png';
import prd3 from '../../../../Assets/Images/alba_prd_3.png';
import prd4 from '../../../../Assets/Images/alba_prd_4.png';
import { useSelector } from "react-redux";


const Customer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [RoleModal, setRoleModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [tableData, setTableData] = useState();
  const [setRole, setSetRole] = useState();
  const [userActive, setUserActive] = useState()
  const [data, setData] = useState([]);
  const [userRoute, setUserRoute] = useState(false);
  const [params, setParams] = useState([]);
  const [loading, setLoading] = useState(false)

  // uam role object start
  const userDetails = useSelector((state) => state)
  const appsubmodulename = "User"
  const appfunctionalityname = "User List"
  const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
  const [appObjects, setAppObjects] = useState(
    {
      "Action": false,
      "Active/Deactive": false
    }
  )
  console.log("userDetails", userDetails)

  const updateAppObjects = (arr) => {
    let objectsArray = []
    arr.map((obj) => {
      console.log("updateButtons", obj)
      // if (obj.objecttypename == "button") {
      objectsArray.push(obj)
      // }
    })
    const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
      acc[appobjectname] = true;
      return acc;
    }, {});
    setAppObjects({ ...appObjects, ...finalObject })
    console.log("objectsArray", objectsArray)
    console.log("finalObject", finalObject)
  }

  useEffect(() => {
    console.log("appObjects", appObjects)
  }, [appObjects])

  useEffect(() => {
    console.log("userRoleObjects", userRoleObjects)
    const filteredList = []
    userRoleObjects.filter((obj) => {
      if (obj.appsubmodulename == appsubmodulename && obj.appfunctionalityname == appfunctionalityname) {
        filteredList.push(obj)
      }
    })

    console.log("filteredList", filteredList)
    updateAppObjects(filteredList)

  }, [])

  // uam role object end

  const Alluser = async () => {
    setLoading(true)
    await getAllEmployee(localStorage.getItem("Logindata")).then((res) => {

      let response = res.data.data;
      // console.log("Employee...", res.data.data)
      response.map((item) => {
        if (item._status_id == 2) {
          item._status_id = "InActive";
        } else if (item._status_id == 1) {
          item._status_id = "Active";
        } else {
          item._status_id = "Pending";
        }
        return item;
      });
      response.map((item) => { });

      setData([...response]);
      const sortedData = response.sort((a, b) => {
        if (a._status_id.startsWith('P') && !b._status_id.startsWith('P')) {
          return -1; // a comes before b
        } else if (!a._status_id.startsWith('P') && b._status_id.startsWith('P')) {
          return 1; // b comes before a
        } else {
          return a._status_id.localeCompare(b._status_id); // alphabetical order
        }
      });
      setData(sortedData)
    });
    setLoading(false)
  };




  const AssigneRoleModal = (p) => {
    setRoleModal(true);
    // console.log("popup",p.row)
    setSetRole(p.row);
  };

  const AssigneRoleModalOk = () => {
    setRoleModal(false);
  };

  const AssigneRoleModalCancel = () => {
    setRoleModal(false);
  };

  const showModal = (per) => {
    console.log("per_", per)
    setIsModalOpen(true);
    setUserActive(per.data)
    console.log("111", userActive)
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  useEffect(() => {
    Alluser();
  }, []);


  let UserisAdmin = localStorage.getItem("Logindata")
  const actionColumn = [
    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 80,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: (params) => {
    //     if (params.row._email == UserisAdmin) {
    //       return (
    //         <div className="cellAction " >
    //           <a onClick={() => AssigneRoleModal(params)} class="btn  btn-secondary disabled">
    //             <GiSightDisabled />
    //           </a>
    //         </div>
    //       );
    //     }
    //     else {
    //       return (
    //         <div className="cellAction">
    //           <a onClick={() => AssigneRoleModal(params)} class="btn btn">
    //             <GrFormView />
    //           </a>
    //         </div>
    //       )
    //     }
    //   },
    // },
    {
      field: "action1",
      headerClassName: "super-app-theme--header",
      headerName: "Action",
      // width: 145,
      flex: 0.3,
      filter:false,
      cellRenderer: (params) => {
        console.log("params__", params.data)
        if (params.data._email == UserisAdmin) {
          return (
            <div className="cellAction">
              <a class="">
                {/* <FaUserLock /> */}
                <img src={disablelogo} />
              </a>
            </div>
          );

        }
        else {
          return (
            <div className="cellAction " >
              <a onClick={() => showModal(params)} class="">
                {/* <FaUserLock /> */}
                <img src={pendinglogo} />
              </a>
            </div>
          )
        }

      },
    },
  ];

  const requestSearch = (searchValue) => {
    const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
    const filteredRows = data.filter((o) => {
      return Object.keys(o).some((k) => {
        return searchRegex.test(o[k].toString());
      });
    });
    setTableData(filteredRows);
  };

  const cancelSearch = () => {
    setSearchText("");
    requestSearch(searchText);
  };

  const handleChangeCheckbox = (params) => {
    // console.log("handle check box", params);
    let colDef = params.field;
    let data = params.row;
    if (colDef === "action") {
      setParams(data);
      setUserRoute(true);
    }
  };

  const rowClassRules = {
    'even-row': (params) => params.node.rowIndex % 2 !== 0,
  };

  const images = [
    prd1, prd2, prd3, prd4
  ]
  const images1 = [
    prd2, prd4, prd1, prd3
  ]
  const images2 = [
    prd4, prd3, prd2, prd1
  ]

  return (
    <>
      <Loader loading={loading} />
      <div className="container-fluid mt-2 px-4">
        <div className="d-flex col-md-12 col-lg-12 col-sm-12">
          <div className="col-md-4 col-lg-4 col-sm-12">
            <ImageSlider images={images} className={'slidecontainer'} />
          </div>
          <div className="col-md-4 col-lg-4 px-2 col-sm-12">
            <ImageSlider images={images1} className={'slidecontainer'} />
          </div>
          <div className="col-md-4 col-lg-4 col-sm-12">
            <ImageSlider images={images2} className={'slidecontainer'} />
          </div>
        </div>
      </div>
      <div className="mt-3 container-fluid px-4">
        {data.length > 0 ? (
          <div className="col-md-12 justify-content-center mt-3">
            {/* <Box sx={{ height: "60vh", width: "100%", overflowY: "auto" }}> */}
            {/* <DataGrid
                sx={{
                  borderColor: "secondary",
                  "& .MuiDataGrid-cell:hover": {
                    color: "primary.main",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    backgroundColor: "#0E3386",
                    fontSize: "12px",
                    color: "white",
                    padding: "10px",

                    // Set your desired header background color
                  },
                  "& .MuiDataGrid-cell": {
                    fontSize: "10px",
                    padding: "10px",
                    borderRight: "2px solid #e0e0e0",

                    // Adjust the cell padding as needed
                  },
                }}
                getRowId={(data) => data._email}

                checkboxSelection={false}
                rows={data}
                componentsProps={{
                  toolbar: {
                    value: searchText,
                    onChange: (searchVal: string) => requestSearch(searchVal),
                    onCancelSearch: () => cancelSearch(),
                  },
                }}
                rowHeight={30}
                columns={userColumns.concat(actionColumn)}
                hideFooter
                onCellClick={(params) => handleChangeCheckbox(params)}

                sortModel={[
                  { field: "action1", sort: "asc" },
                  { field: "action", sort: "asc" },
                ]}
              /> */}
            <AgGridComponent
              rowData={data}
              columnDef={userColumns.concat(actionColumn)}
              rowClassRules={rowClassRules}
              height={400}
            />
            {/* </Box> */}
          </div>

        ) :
          <div className="container">
            <div className="row justify-content-center">
              <div className="col d-flex justify-content-center">
                {" "}
                Now Rows to show
              </div>
            </div>
          </div>
        }
      </div>
      <Modal
        className="p-2"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
      >
        <UserStatusPage data={userActive} handleCancel={handleCancel} Alluser={Alluser} />
      </Modal>

      <Modal
        className="p-2"
        open={RoleModal}
        onOk={AssigneRoleModalOk}
        onCancel={AssigneRoleModalCancel}
        footer={null}
        maskClosable={false}
      >
        <AssignCustomerRole data={setRole} AssigneRoleModalCancel={AssigneRoleModalCancel} />
      </Modal>
    </>
  );
};

export default Customer;
