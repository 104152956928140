import React, { useEffect } from "react";
import logo from "../../../Assets/Images/alba_logo.png";
import * as Yup from "yup";
// import bcrypt from 'bcryptjs'; // Import bcrypt
import toast, { Toaster } from "react-hot-toast";
import CryptoJS from 'crypto-js';
import {
  ForgotPasswordService,
  ResetPassword,
  ResetPasswordService,
  VerifyOTPPassword,
} from "../../../Services/UserServices";
import { Formik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import { Input } from "antd";
import { Modal } from "antd";
import { useState } from "react";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
const ForgotPassword = (props) => {
  //Forget password
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [error,setError] = useState()

  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleOk1 = () => {
    setIsModalOpen1(false);
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };

  const validationSchema = () => {
    return Yup.object().shape({
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
    });
  };
  // starts here
  // const hashPassword = async (password) => {
  //   try {
  //     const saltRounds = 10; // Number of salt rounds
  //     const hashedPassword = await bcrypt.hash(password, saltRounds);
  //     return hashedPassword;
  //   } catch (error) {
  //     throw error;
  //   }
  // };
  // const hashPassword = async (password) => {
  //   try {
  //     const saltRounds = 10; // Number of salt rounds
  //     const hashedPassword = await bcrypt.hash(password, saltRounds);
  //     return hashedPassword;
  //   } catch (error) {
  //     throw error;
  //   }
  // };
  const handleSubmit = async (value) => {
    // console.log(value)
    // Hash the password using bcrypt
    const hashedPassword = (value.password);

    // Use the hashed password in your request
    value.password = await hashedPassword;
    console.log("valuee", value.email);
    console.log("valuep", value.password);
    console.log("signinvalues1", value);
    // ends here

    await ForgotPasswordService(value)
      .then((res) => {
        // console.log("resetdata", res);
        if (res.status === 200) {
          toast.success("Otp send to your email successfully.");
          props.loginclose();
          localStorage.removeItem("email");
          localStorage.setItem("email", value.email);
          showModal1();
        }
      })
      .catch((error) => {
        // toast.error(error.response.data.msg, {
        //   position: "top-right",
        //   autoClose: 5000,
        // });
        setError(error)
      });
  };

  return (
    <div>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ email: "" }}
        onSubmit={async (values, { resetForm }) => {
          try {
            await handleSubmit(values);
            resetForm();
          }
          catch (error) {
            setError(error)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form class="row g-3 p-4" noValidate onSubmit={handleSubmit}>
            <h6 className="text-center">
              <text className="text-danger">Forgot Password? </text>
              <text className="text-dark">Generate new OTP to Reset Your password</text>
            </h6>

            <div class="col-12">
              <input
                type="text"
                class="form-control"
                placeholder="Email"
                aria-label="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                id="email"
                name="email"
              />
              <p className="error">
                {errors.email && touched.email && errors.email}
              </p>
            </div>

            <button type="submit" class="btn btn-primary ">
              Submit
            </button>
            <div className="">
              <text onClick={showModal1} className="text-dark float-end">
                Verify OTP
              </text>
            </div>
          </form>
        )}
      </Formik>

      <Modal
        className="p-2"
        open={isModalOpen1}
        onOk={handleOk1}
        onCancel={handleCancel1}
        footer={null}
      >
        <VerifyOTP handleCancel1={handleCancel1} />
      </Modal>
    </div>
  );
};

//============================= VerifyOTP ====================================//
//============================= Verify OTP Component for Reset Password ======//
const VerifyOTP = (props) => {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [emailState, setEmailState] = useState(localStorage.getItem("email"));
  const [error,setError] = useState()
  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleOk1 = () => {
    setIsModalOpen1(false);
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };

  const validationSchema = () => {
    return Yup.object().shape({
      OTP: Yup.string().required("OTP is required"),
    });
  };


  // ends here
  const handleSubmit = async (value) => {
    // console.log('OTP', value)
    await VerifyOTPPassword(value)
      .then((res) => {
        console.log(res.data.msg);
        console.log("valuep1", value.password);
        // console.log("resetdata", res);
        if (res.status === 200) {
          toast.success("Otp Verified successfully.");
          props.handleCancel1()
          showModal1();
        }
      })
      .catch((error) => {
        // toast.error(error.response.data.msg, {
        //   position: "top-right",
        //   autoClose: 5000,
        // });
        setError(error)
      });

  };

  return (
    <div>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ OTP: "", email: emailState }}
        onSubmit={async (values, { resetForm }) => {
          try {
            await handleSubmit(values);
            resetForm();
          }
          catch (error) {
            setError(error)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form class="row g-3 p-4" noValidate onSubmit={handleSubmit}>
            <div className="text-center">
              <img src={logo} alt="" />
            </div>
            <h6 className="text-center">
              <p className="pt-3">OTP sent to {localStorage.getItem("email")}.</p>
            </h6>

            <div class="col-12">
              <input
                type="text"
                class="form-control"
                placeholder="Verify OTP"
                aria-label="OTP"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.OTP}
                id="OTP"
                name="OTP"
              />
              <p className="error">{errors.OTP && touched.OTP && errors.OTP}</p>
            </div>

            <button type="submit" class="btn btn-primary ">
              Submit
            </button>
          </form>
        )}
      </Formik>
      <Modal
        className="p-2"
        open={isModalOpen1}
        onOk={handleOk1}
        onCancel={handleCancel1}
        footer={null}
      >
        <ResetPasswordCom handleCancel1={handleCancel1} />
      </Modal>
    </div>
  );
};

//============================= Reset Passwords ====================================//
//============================= Reset Password Component =====================//

const ResetPasswordCom = (props) => {
  const [emailState, setEmailState] = useState(localStorage.getItem("email"));
  const [error,setError] = useState()
  const validationSchema = () => {
    return Yup.object().shape({
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters"),
    });
  };
  // starts here
  // const hashPassword = async (password) => {
  //   try {
  //     const saltRounds = 10; // Number of salt rounds
  //     const hashedPassword = await bcrypt.hash(password, saltRounds);
  //     return hashedPassword; // Return the hashed password
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  const handleSubmit = async (value) => {
    // console.log(value)
    // Hash the password using bcrypt
    const hashedPassword = CryptoJS.SHA256(value.password).toString();

    // Use the hashed password in your request
    value.password = hashedPassword;
    console.log("valuee", value.email);
    console.log("valuep", value.password);
    console.log("signinvalues1", value)
    await ResetPasswordService(value)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.msg);
          localStorage.removeItem("email");
          props.handleCancel1()
          // props.Signinclose()
        }
      })
      .catch((error) => {
        // toast.error(error.response.data.msg, {
        //   position: "top-center",
        //   autoClose: 5000,
        // });
        setError(error)
      });
    
    await ResetPassword(value)
      .then((res) => {
        console.log("resetPasswordres",res)
        if (res.status === 200) {
          toast.success(res.data.msg);
          localStorage.removeItem("email");
          props.handleCancel1()
          // props.Signinclose()
        }
      })
      .catch((error) => {
        // toast.error(error.response.data.msg, {
        //   position: "top-center",
        //   autoClose: 5000,
        // });
        setError(error)
      });
    // ends here
  };

  return (
    <>
      <ErrorCatch err={error} />
      <div>
        <Formik
          validationSchema={validationSchema}
          initialValues={{ password: "", email: emailState }}
          onSubmit={async (values, { resetForm }) => {
            try {
              await handleSubmit(values);
              resetForm();
            }
            catch (error) {
              setError(error)
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form class="row g-3 p-4" noValidate onSubmit={handleSubmit}>
              <div className="text-center">
                <img src={logo} alt="" />
              </div>
              <h6 className="text-center">
                Reset Password
              </h6>

              <div class="col-12">
                <Input.Password
                  type="text"
                  class="form-control"
                  placeholder="Password"
                  aria-label="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  id="password"
                  name="password"
                />
                <p className="error">
                  {errors.password && touched.password && errors.password}
                </p>
              </div>

              <button type="submit" class="btn btn-primary ">
                Submit
              </button>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ForgotPassword;
