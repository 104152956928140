import { DataGrid } from "@mui/x-data-grid";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import RadioButton from "../../Reusable_Components/RadioButton";
import { IoMdArrowDropdown } from 'react-icons/io'
import { LuView } from "react-icons/lu";

import Box from '@mui/material/Box';
import {
  addNewCompany,
  CreateCustomerMapping,
  deleteCompany,
  deleteCustomerMappingDetail,
  getActiveCompony,
  getAlbaRule,
  getAllCompany,
  getCustomerMappingDetails,
  getDeActiveCompony,
  getPortalCompanyNames,
  getRuleFormat,
  getSoCompanyNames,
  insertLogs,
  updateCompanyDetails,
  updateCustomerMappingDetails,
  updateRuleFormat,
  userActiveDeactive,
} from "../../../Services/AdminServices";
import activeCompanyColumn from "./activeCompanyColumn";
import requestedCompanyColumn from "./requestedCompanyColumns";
import Warning from "./Warnigng";
import { Modal } from "antd";
import Loader from "../../../utils/Loader/Loader";
import toast, { Toaster } from "react-hot-toast";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
import companyMappingColumn from "./companyMappingColumn";
import { FaEdit } from 'react-icons/fa';
import { BsPlus, BsFillTrashFill } from 'react-icons/bs';
import SelectInput from "../../Reusable_Components/SelectInput";
import BasicButton from "../../Reusable_Components/Button";
import warningimg from "../../../Assets/Images/warning.png"
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material";
import AgGridComponent from "../../Reusable_Components/AgGridComponent";
import { useSelector } from "react-redux";
import ButtonComp from "../../Reusable_Components/ButtonComp";
import InputBox from "../../Reusable_Components/InputBox";

const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#28a745',
    '&:hover': {
      backgroundColor: alpha('#28a745', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#28a745',
  },
}));

const ActiveCompony = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [selectedValue, setSelectedValue] = useState(1);
  const [data, setData] = useState([]);
  const [userRoute, setUserRoute] = useState(false);
  const [params, setParams] = useState([]);
  const [companydetails, setCompanyDetails] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [newActionColumnCompanyMapping, setNewActionColumnCompanyMapping] = useState([])

  const [companyColumns, setCompanyColumns] = useState(activeCompanyColumn)
  const navigate = useNavigate();

  const [customerModalOpen, setCustomerModalOpen] = useState(false)
  const [selectedSoCompany, setSelectedSoCompany] = useState("")
  const [selectedPortalCompany, setSelectedPortalcompany] = useState("")
  const [soCompanyList, setSoCompanyList] = useState([])
  const [poCompanyList, setPoCompanyList] = useState([])
  const [soCompanyNames, setSoCompanyNames] = useState()
  const [selectedCustomerValue, setSelectedCustomerValue] = useState(1)

  const [iscompanyModal, setIsCompanyModal] = useState(false)
  const [companyName, setCompanyName] = useState('')

  const [customerStatusList, setCustomerStatusList] = useState([
    {
      label: "Active",
      value: 1,
      status: true
    },
    {
      label: "InActive",
      value: 0,
      status: false
    },
  ])

  const [isWarningModal, setIsWarningModal] = useState(false)
  const [isWarningModalActComp, setIsWarningModalActComp] = useState(false)
  const [isToggle, setIsToggle] = useState(false)
  const [customerMapId, setCustomerMapId] = useState()
  const [isActiveComp, setIsActiveComp] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [companyId, setCompanyId] = useState()
  const [selectedCompanyName,setSelectedCompanyName] = useState()
  const [ruleFormat, setRuleFormat] = useState()
  const [isRuleFormat, setIsRuleFormat] = useState(false)
  const [formatDetails, setFormatDetails] = useState()
  const [currentParams, setCurrentParams] = useState()
  // const [list, setList] = useState([{ "value": "1", "label": "ALBA-customised", "status": true }, { "value": "0", "label": "company-customised", "status": false }])
  // const [list, setList] = useState([{ "value": "1", "name": "ALBA-customised"}, { "value": "0", "name": "company-customised" }])
  const [list, setList] = useState([])
  const [selectedValueRb, setSelectedValueRb] = useState(1);
  const [albaRule, setAlbaRule] = useState()
  const [albaRuleList, setAlbaRuleList] = useState([])

  useEffect(() => {
    getRuleFormatForCompany()
    getAlbaRulesList()
  }, [])

  const getAlbaRulesList = async () => {
    setLoading(true)
    try {
      const response = await getAlbaRule()
      console.log("getAlbaRule", response)
      let data = response.data.data
      let finalArr = []
      data.map((obj) => {
        finalArr.push({
          name: obj.rule_name,
          value: obj.rule_id
        })
      })
      setAlbaRuleList(finalArr)
    }
    catch (err) {
      console.log("getAlbaRuleErr", err)
      setError(err)
    }
    setLoading(false)
  }

  const getRuleFormatForCompany = async () => {
    setLoading(true)
    try {
      const response = await getRuleFormat()
      console.log("getRuleFormatForCompany", response)
      let data = response.data.data
      let finalArr = []
      data.map((obj) => {
        finalArr.push({
          name: obj.formattype,
          value: obj.isalbaformat
        })
      })
      setList(finalArr)
    }
    catch (err) {
      console.log("getRuleFormatForCompanyErr", err)
      setError(err)
    }
    setLoading(false)
  }

  // uam role object start
  const userDetails = useSelector((state) => state)
  const appsubmodulename = "Company"
  const appfunctionalityname = "Company"
  const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
  const [appObjects, setAppObjects] = useState(
    {
      "Active Company": false,
      "Requested Company": false,
      "Change Status": false,
      "Company Mapping": false,
      "Mapping Status": false,
      "Company Status": true,
      "Delete": false,
      "Add New": false,
      "Add Company": true,
      "Delete Company": true
    }
  )
  console.log("userDetails", userDetails)

  const updateAppObjects = (arr) => {
    let objectsArray = []
    arr.map((obj) => {
      console.log("updateButtons", obj)
      // if (obj.objecttypename == "button") {
      objectsArray.push(obj)
      // }
    })
    const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
      acc[appobjectname] = true;
      return acc;
    }, {});
    setAppObjects({ ...appObjects, ...finalObject })
    console.log("objectsArray", objectsArray)
    console.log("finalObject", finalObject)
  }

  useEffect(() => {
    console.log("appObjects", appObjects)
  }, [appObjects])

  useEffect(() => {
    console.log("userRoleObjects", userRoleObjects)
    const filteredList = []
    userRoleObjects.filter((obj) => {
      if (obj.appsubmodulename == appsubmodulename) {
        filteredList.push(obj)
      }
    })

    console.log("filteredList", filteredList)
    updateAppObjects(filteredList)

  }, [])

  // uam role object end


  const ActiveCompany = async () => {
    setLoading(true)
    try {
      await getActiveCompony().then((res) => {
        let response = res.data.data;
        response.map((item) => {
          if (item.company_status_id === 1) {
            item.company_status_id = "Active";
          } else if (item.company_status_id === 2) {
            item.company_status_id = "Deactive";
          }
          return item;
        });
        response.sort((a, b) => b.comp_id - a.comp_id);
        setData([...response]);
      });
    }
    catch (err) {
      setError(err)
    }
    setLoading(false)
  };

  const getAllCompanyDetails = async () => {
    setLoading(true)
    try {
      await getAllCompany().then((res) => {
        let response = res.data.data;
        response.map((item) => {
          if (item.company_status_id === 1) {
            item.company_status_id = "Active";
          } else if (item.company_status_id === 2) {
            item.company_status_id = "Deactive";
          }
          return item;
        });
        response.sort((a, b) => b.comp_id - a.comp_id);
        setData([...response]);
      });
    }
    catch (err) {
      setError(err)
    }
    setLoading(false)
  };

  const getRequestedCompany = async () => {
    setLoading(true)
    try {
      await getDeActiveCompony().then((res) => {
        let response = res.data.data;
        response.map((item) => {
          if (item.company_status_id === 1) {
            item.company_status_id = "Active";
          } else if (item.company_status_id === 2) {
            item.company_status_id = "Deactive";
          }
          return item;
        });
        response.sort((a, b) => b.comp_id - a.comp_id);
        setData([...response]);
      });
    }
    catch (err) {
      setError(err)
    }
    setLoading(false)
  };

  useEffect(() => {
    // ActiveCompany();
    getAllCompanyDetails()

  }, []);

  const getCustomerMapDetails = async () => {
    setLoading(true)
    setSelectedSoCompany("")
    setSelectedPortalcompany("")
    await getCustomerMappingDetails().then((res) => {
      if (res.status == 200) {
        let response = res.data.data;

        response.map((item) => {
          if (item.company_status_id === 1) {
            item.company_status_id = "Active";
            setIsActiveComp(true)
            setSelectedCustomerValue(1)
          } else if (item.company_status_id === 0) {
            item.company_status_id = "Deactive";
            setSelectedCustomerValue(0)
            setIsActiveComp(false)
          }
          return item;
        });
        response.sort((a, b) => b.comp_id - a.comp_id);
        setData([...response]);
        getSoCompanyName(response)
      }
    });

    setLoading(false)
  }

  // useEffect(() => {
  //   ActiveCompany();
  // }, [companydetails]);

  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const handleClick = async (params, event) => {
    console.log("params", params);
    const { value } = event.target;
    let adminEmailValue = localStorage.getItem("Logindata");
    let statusId = value;
    const { _email, _status_datetime, _status_reason, _status_id } = params.row;
    // let sendData= {

    //     email: _email,
    //     status_datetime: _status_datetime,
    //     status_reason: _status_reason,
    //     status_id: statusId,
    //     adminEmail: adminEmailValue,
    //   }
    // working
    const res = await userActiveDeactive({
      email: _email,
      status_datetime: _status_datetime,
      status_reason: _status_reason,
      status_id: statusId,
      adminEmail: adminEmailValue,
    });


  };

  const NavigateComonay = (e) => {
    console.log(e.target.value);
    navigate(e.target.value);
  };

  const showModal = (p) => {
    setIsModalOpen(true);
    setCompanyDetails(p)
    console.log("showModal", p)
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const actionColumnReqComp = [
    {
      headerClassName: "super-app-theme--header",
      field: "action1",
      headerName: "Change Status",
      width: 150,
      filter: false,
      isAccess: appObjects["Change Status"],
      cellRenderer: (params) => {
        return (
          <>
            {appObjects["Change Status"] && <div class="">
              <button className="btn btn-sm  btn-danger" onClick={() => { showModal(params.data) }}>Inactive <IoMdArrowDropdown /></button>
            </div>}
          </>
        );
      },
    }
  ]

  const [newActionColumnReqComp, setNewActionColumnReqComp] = useState([])
  useEffect(() => {
    let { role } = userDetails.auth
    let finalArr = []
    // if (role == "super user") {
    actionColumnReqComp.filter((obj) => {
      // if (obj?.isSuperUser == true) {
      //   finalArr.push(obj)
      // }
      if (obj?.isAccess == true) {
        finalArr.push(obj)
      }
    })
    console.log("sidebarfinalArr", finalArr)
    setNewActionColumnReqComp(finalArr)
  }, [appObjects])

  const handleEditCompany = (params) => {
    console.log("EditParam", params, soCompanyList)
    console.log("addParams_")
    setIsEdit(true)
    if (params.status == 1) {
      setIsActiveComp(true)
      setSelectedSoCompany(params.socustomername)
      setSelectedPortalcompany(params.comp_id)
    } else {
      setIsActiveComp(false)
    }
    let updatedStatusList = customerStatusList.map((item) => {
      if (item.value == params.status) {
        item.status = true
      }
      else {
        item.status = false
      }
      return item
    })
    setCustomerMapId(params._id)
    setCustomerStatusList(updatedStatusList)
    setCustomerModalOpen(true)
  }

  const handleDeleteCompany = (params) => {
    console.log("params", params)
    let rowData = params.data
    setCustomerMapId(rowData._id)
    setIsWarningModal(true)
  }

  const handleDeleteCompanyActive = (params) => {
    console.log("handleDeleteCompanyActiveParams", params)
    let rowData = params.data
    setCompanyId(rowData.comp_id)
    setSelectedCompanyName(rowData.comp_name)
    setIsWarningModalActComp(true)
  }

  const handleDeleteCancelActComp = () => {
    setIsWarningModalActComp(false)
  }

  const companyDeletion = async () => {
    setLoading(true)
    try {
      const response = await deleteCompany(companyId)
      console.log("companyDeletionResponse", response)
      toast.success(response.data.message || "Deleted successfully", {
        duration: 4000
      })
      insertLogsForUser()
      setIsWarningModalActComp(false)
      getAllCompanyDetails()
    }
    catch (err) {
      console.log("companyDeletionResponse", err)
      setError(err)
    }
    setLoading(false)
  }

  const handleToggleChange = (e, params, selectedParam) => {
    console.log("toggleparams", e.target.checked, params, selectedValue)
    if (e.target.checked) {
      if (selectedParam == 1) {
        updateCompanyStatus(params, e.target.checked)
      } else if (selectedParam == 2) {
        updateCustomerMap(params, e.target.checked)
      }
    } else {
      if (selectedParam == 1) {
        setCompanyId(params.comp_id)
      } else if (selectedParam == 2) {
        setSelectedSoCompany(params.socustomername)
        setSelectedPortalcompany(params.comp_id)
        setCustomerMapId(params._id)
      }
      setIsActiveComp(e.target.checked)
      setIsToggle(true)
      setIsWarningModal(true)
    }
  }

  const openRuleFormat = (params,) => {
    console.log("handleRuleFormatparams", params)
    setCurrentParams(params.data)
    let { isalbaformat: value, formattype: name, ruleid } = params.data
    let format = list.filter((item) => {
      if (item.value == value) {
        return name
      }
    })
    console.log("handleRuleFormatFrmt", format)
    setFormatDetails(format)
    setRuleFormat(value)
    setIsRuleFormat(true)
    setAlbaRule(ruleid)
  }

  const closeRuleFormat = () => {
    setCurrentParams()
    setFormatDetails()
    setIsRuleFormat(false)
    setRuleFormat()
    setAlbaRule()
  }

  const radiohandleChange = (e) => {
    let value = e.target.value;
    // let name = e.target.name;
    let final = list.map(item => {
      if (item.value == value) {
        item.status = true
      } else {
        item.status = false
      }
      return item
    })
    setList(final)
    setSelectedValueRb(value)

  };

  const handleRuleFormat = (e) => {
    const { name, value } = e.target;
    let format = list.filter((item) => {
      if (item.value == value) {
        return name
      }
    })
    console.log("formatrule", format, "valueN", value)
    setFormatDetails(format)
    setRuleFormat(value)
  }

  const handleUpdateRuleFormat = async () => {
    console.log("handleUpdateRuleFormat", formatDetails)
    let params = {
      compid: currentParams.comp_id,
      isAlbaFormat: formatDetails ? formatDetails[0].value : null,
      formatType: formatDetails ? formatDetails[0].name : null,
      // defaultRuleId: ruleFormat == 1 ? albaRule : null
      defaultRuleId: albaRule
    }
    console.log("paramsURF", params)
    if (validatePayload(params)) {
      try {
        setLoading(true)
        let response = await updateRuleFormat(params)
        setLoading(false)
        console.log("updateRuleFormatResponse", response)
        toast.success(response.data.message)
        closeRuleFormat()
        getAllCompanyDetails()
      } catch (error) {
        console.log("updateRuleFormatError", error);
        setError(error)
      }
    }
  }

  const actionColumnAddComp = [
    {
      field: "Status",
      headerName: "Status",
      flex: 0.4,
      filter: false,
      isAccess: appObjects["Mapping Status"],
      cellRenderer: (params) => (
        <>
          {appObjects["Mapping Status"] &&
            <div>
              <span style={{ color: params.data.company_status_id == "Deactive" ? "#ed4f4a" : "#28a745" }}>
                {params.data.company_status_id == "Deactive" ? "Inactive" : "Active"}
              </span>
              <PinkSwitch
                checked={params.data.company_status_id == "Deactive" ? false : true}
                onChange={(event) =>
                  handleToggleChange(event, params.data, 1)
                }
              />
            </div>}
        </>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      field: "ruleFormat",
      headerName: "Rule Format",
      flex: 0.3,
      filter: false,
      isAccess: appObjects["Delete Company"],
      cellRenderer: (params) => {
        console.log("paramsRow_", params)
        return (
          <>
            {appObjects["Delete Company"] &&
              <div className="cellAction">
                {appObjects["Delete Company"] && <a onClick={() => openRuleFormat(params)} class="btn btn-light">
                  <LuView />
                </a>}
              </div>
            }
          </>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "deleteCompany",
      headerName: "Delete Company",
      // width: 70,
      flex: 0.3,
      filter: false,
      isAccess: appObjects["Delete Company"],
      cellRenderer: (params) => {
        console.log("paramsRow_", params)
        return (
          <>
            {appObjects["Delete Company"] &&
              <div className="cellAction">
                {/* <a onClick={() => handleDeleteCompanyActive(params)} style={{ color: "red", fontSize: "15px", cursor: 'pointer' }}>
                  <BsFillTrashFill />
                </a> */}
                {params?.data.status == 0 || params?.data.company_status_id == "Deactive" ? <a onClick={() => handleDeleteCompanyActive(params)} style={{ color: "red", fontSize: "15px", cursor: 'pointer' }}>
                  <BsFillTrashFill />
                </a> : <a style={{ color: "gray", fontSize: "15px" }}> <BsFillTrashFill /> </a>}
              </div>}
          </>
        );
      },
    },
  ]

  const actionColumnCompanyMapping = [
    {
      field: "Status",
      headerName: "Status",
      flex: 0.4,
      filter: false,
      isAccess: appObjects["Mapping Status"],
      cellRenderer: (params) => (
        <>
          {appObjects["Mapping Status"] &&
            <div>
              <span style={{ color: params.data.status == 0 ? "#ed4f4a" : "#28a745" }}>
                {params.data.status == 0 ? "Inactive" : "Active"}
              </span>
              <PinkSwitch
                checked={params.data.status == 0 ? false : true}
                onChange={(event) =>
                  handleToggleChange(event, params.data, 2)
                }
              />
            </div>}
        </>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      field: "delete",
      headerName: "",
      width: 70,
      filter: false,
      isAccess: appObjects["Delete"],
      cellRenderer: (params) => {
        console.log("paramsRow_", params)
        return (
          <>
            {appObjects["Delete"] &&
              <div className="cellAction">
                {params?.data.status == 0 || params?.data.company_status_id == "Deactive" ? <a onClick={() => handleDeleteCompany(params)} style={{ color: "red", fontSize: "15px", cursor: 'pointer' }}>
                  <BsFillTrashFill />
                </a> : <a style={{ color: "gray", fontSize: "15px" }}> <BsFillTrashFill /> </a>}
              </div>}
          </>
        );
      },
    },
    // {
    //   headerClassName: "super-app-theme--header",
    //   field: "toggle",
    //   headerName: "Active/InActive",
    //   width: 100,
    //   renderCell: (params) => {
    //     console.log("paramsRow_", params)
    //     return (
    //       <>
    //         <div className="cellAction">
    //           <Switch
    //             checked={params.row.status == 0 ? false : true}
    //             onChange={e => handleToggleChange(e,params.row)}
    //             inputProps={{ 'aria-label': 'controlled' }}
    //           />
    //         </div>
    //       </>
    //     );
    //   },
    // },
    // {
    //   headerClassName: "super-app-theme--header",
    //   field: "edit",
    //   headerName: "",
    //   width: 80,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <div className="cellAction">
    //           <a onClick={() => handleEditCompany(params.row)} class="" style={{ fontSize: "15px" }}>
    //             <FaEdit />
    //           </a>
    //         </div>
    //       </>
    //     );
    //   },
    // },
  ]


  useEffect(() => {
    let { role } = userDetails.auth
    let finalArr = []
    let finalArr1 = []
    // if (role == "super user") {
    actionColumnCompanyMapping.filter((obj) => {
      // if (obj?.isSuperUser == true) {
      //   finalArr.push(obj)
      // }
      if (obj?.isAccess == true) {
        finalArr.push(obj)
      }
    })
    actionColumnAddComp.filter((obj) => {
      if (obj?.isAccess == true) {
        finalArr1.push(obj)
      }
    })
    console.log("finalArr", finalArr)
    setNewActionColumnCompanyMapping(finalArr)
    setCompanyColumns(activeCompanyColumn.concat(finalArr1))
  }, [appObjects])

  // adding condition for radio button starts here
  const [statusList, setStatusList] = useState([
    {
      label: "Active Company",
      value: 1,
      status: true
    },
    // {
    //   label: "Requested Company",
    //   value: 2,
    //   status: false
    // },
    {
      label: "Company Mapping",
      value: 3,
      status: false
    },
  ])

  // onhandle event 
  const handleRadioButton = (e) => {
    console.log("Active", e.target)
    let value = e.target.value
    // navigate(e.target.value);
    console.log("item", statusList, value)
    console.log("called", value)

    if (value == 1) {
      setCompanyColumns(activeCompanyColumn.concat(actionColumnAddComp))
      // ActiveCompany()
      getAllCompanyDetails()
      setSelectedValue(1)

    }
    else if (value == 2) {
      setCompanyColumns(requestedCompanyColumn.concat(newActionColumnReqComp))
      getRequestedCompany()
    } else if (value == 3) {
      setCompanyColumns(companyMappingColumn.concat(newActionColumnCompanyMapping))
      setSelectedValue(3)
      // setData([
      //   {
      //     "ID": 1,
      //     "so_customer": "Customer 1",
      //     "portal_customer":"Rapidqube",
      //     "status": 1,
      //     "adminName": "vijay",
      //     "email": "vijayaragavesh.ravichandiran@rapidqube.com",
      //     "address": "address"
      //   },
      //   {
      //     "ID": 2,
      //     "so_customer": "Customer 2",
      //     "portal_customer":"VTech",
      //     "status": 0,
      //     "adminName": "vijay",
      //     "email": "vijayaragavesh.ravichandiran@rapidqube.com",
      //     "address": "address"
      //   }
      // ])
      getCustomerMapDetails()
      getPortalCompanyName()
    }

    let updatedStatusList = statusList.map((item) => {
      if (item.value == value) {
        item.status = true
      }
      else {
        item.status = false
      }
      return item
    })
    console.log("updatedStatusList", updatedStatusList)
    setStatusList(updatedStatusList)

  }

  const handleSoCompany = (e) => {
    console.log("selectedSO", e);
    setSelectedSoCompany(e.target.value)
  }

  const handlePoCompany = (e) => {
    console.log("selectedPO", e);
    setSelectedPortalcompany(e.target.value)
  }

  const handleCustomerSubmit = (param) => {
    if (selectedSoCompany == "") {
      toast.error("Please select SO company")
      return
    }
    if (selectedPortalCompany == "") {
      toast.error("Please select Portal company")
      return
    }
    console.log("submit")
    if (param == "add") {
      createCustomerMap()
    } else {
      updateCustomerMap()
    }
  }

  const handleCancelCustomer = () => {
    console.log("cancel")
    setCustomerModalOpen(false)
    setIsCompanyModal(false)
    setIsEdit(false)
  }

  const handleAddCompany = () => {
    console.log("AddCompany");
    setIsEdit(false)
    if (selectedValue == 3) {
      setCustomerModalOpen(true)
      setIsCompanyModal(false)
    } else {
      setIsCompanyModal(true)
      setCustomerModalOpen(false)
      setCompanyName('')
      setRuleFormat()
      setAlbaRule()
      setFormatDetails()
    }
  }

  const getSoCompanyName = async (rawdata) => {
    let soCompanyResponse = await getSoCompanyNames()
    console.log("soCompanyResponse", soCompanyResponse, rawdata)

    let finalArr = []
    if (soCompanyResponse.status == 200) {
      let soCompdata = soCompanyResponse.data.data
      setSoCompanyNames(soCompdata)
      let filterRawData = rawdata.filter(item => item.status == 0)
      console.log("filterRawData_", filterRawData,)
      let rawDataArr = rawdata.map((item) => item['socustomername'])
      let soDataArr = soCompdata.map((item) => item['customername'])
      console.log("rawData_", rawDataArr, soDataArr)
      let filteredArray = soDataArr.filter(value => !rawDataArr.includes(value));

      // if (!isEdit) {
      //   filteredArray.map((item) => {
      //     finalArr.push({
      //       "name": item,
      //       "value": item
      //     })
      //   })
      // } else {
      filteredArray.map((item) => {
        finalArr.push({
          "name": item,
          "value": item
        })
      })
      // }
      console.log("filteredArray_", filteredArray, finalArr);

      setSoCompanyList(finalArr)
    }
  }

  const getPortalCompanyName = async () => {
    let portalCompanyResponse = await getPortalCompanyNames()
    console.log("portalCompanyResponse", portalCompanyResponse)
    let finalArr = []
    if (portalCompanyResponse.status == 200) {
      let poCompdata = portalCompanyResponse.data.data
      poCompdata.map((item) => {
        finalArr.push({
          "name": item.comp_name,
          "value": item.comp_id
        })
        setPoCompanyList(finalArr)
      })
    }
  }

  const handleCustomerStatusRadio = (e) => {
    console.log("Active", e.target)
    let value = e.target.value
    // navigate(e.target.value);
    console.log("item", value, customerStatusList)
    let updatedStatusList = customerStatusList.map((item) => {
      if (item.value == value) {
        item.status = true
      }
      else {
        item.status = false
      }
      return item
    })
    console.log("updatedStatusList", updatedStatusList)
    setSelectedCustomerValue(value)
    setCustomerStatusList(updatedStatusList)
  }

  const createCustomerMap = async () => {
    let payload = {
      "soCompany": selectedSoCompany,
      "poCompanyId": selectedPortalCompany,
      "isActive": selectedCustomerValue == "1" ? 1 : 0,
      "funtionName": "createCustomerMapping"
    }
    console.log("Payload_", payload);
    let customerResponse = await CreateCustomerMapping(payload)
    console.log("customerResponse_", customerResponse)
    if (customerResponse.status == 200) {
      toast.success(customerResponse.resData.data.msg)
      setCustomerModalOpen(false)
      getCustomerMapDetails()
    }
  }

  const updateCustomerMap = async (params, isActive) => {
    console.log("rowParams_", params, isActive)
    let payload = {
      "id": customerMapId || params._id,
      "soCompany": selectedSoCompany || params.socustomername,
      "poCompanyId": selectedPortalCompany || params.comp_id,
      "isActive": isActive ? !params.status ? 1 : 0 : isActiveComp ? 1 : 0
    }
    console.log("update_param", payload)
    let customerMapResponse = await updateCustomerMappingDetails(payload)
    console.log("soDeleteResponse_", customerMapResponse);
    if (customerMapResponse.status == 200) {
      toast.success(customerMapResponse.data.msg)
      getCustomerMapDetails()
      setIsEdit(true)
      setIsWarningModal(false)
      setCustomerModalOpen(false)
      setIsToggle(false)
    } else {
      toast.error(customerMapResponse.data.msg)
    }
  }

  const updateCompanyStatus = async (params, isActive) => {
    console.log("params_", params, isActive)

    let payload = {
      compId: companyId || params.comp_id,
      status: isActive ? params.company_status_id == 'Active' ? 2 : 1 : isActiveComp ? 1 : 2

      // isActive?!params.status ? 1 : 0 : isActiveComp? 1: 0
    }
    console.log("payload_", payload);
    let updateCompResponse = await updateCompanyDetails(payload)
    console.log("updateCompResponse_", updateCompResponse);
    if (updateCompResponse.status == 200) {
      toast.success(updateCompResponse.data.msg)
      setIsEdit(true)
      setIsCompanyModal(false)
      setIsWarningModal(false)
      setIsToggle(false)
      getAllCompanyDetails()

    } else {
      toast.error(updateCompResponse.data.msg)
    }
  }

  const handleDelete = async () => {
    let customerMapResponse = await deleteCustomerMappingDetail(customerMapId)
    console.log("soDeleteResponse_", customerMapResponse);
    if (customerMapResponse.status == 200) {
      toast.success(customerMapResponse.data.msg)
      setIsWarningModal(false)
      getCustomerMapDetails()
    }
  }

  const handleDeleteCancel = () => {
    setIsWarningModal(false)
  }

  const handleAlbaRule = (e)=>{
    const { name, value } = e.target;
    let format = albaRuleList.filter((item) => {
      if (item.value == value) {
        return name
      }
    })
    console.log("formatrule", format, "valueN", value)
    
    setAlbaRule(value)
  }

  function validatePayload(payload) {
    for (const key in payload) {
      // if (payload[key] === undefined || payload[key] === null || payload[key] === '') {
        if (payload[key] === undefined || payload[key] === '') {
        // alert(`The key "${key}" cannot be empty, undefined, or null.`);
        toast.error("Please fill the required field")
        return false
      }
    }
    return true
  }

  const handleCompanySubmit = async () => {
    let payload = {
      'comp_name': companyName,
      'isAlbaFormat': formatDetails ? formatDetails[0]?.value : null,
      'formatType': formatDetails ? formatDetails[0]?.name : null,
      'defaultRuleId': ruleFormat == 1 ? albaRule : null
    }
    if (validatePayload(payload)) {
      try {
        setLoading(true)
        let addResponse = await addNewCompany(payload)
        setLoading(false)
        console.log("addResponse_", addResponse)
        if (addResponse.status != 200) {
          toast.error(addResponse.data.message)
          setIsCompanyModal(false)
          return
        }
        toast.success(addResponse.data.message)
        setIsCompanyModal(false)
        getAllCompanyDetails()
      }
      catch (err) {
        console.log("addNewCompanyErr", err)
        setError(err)
      }
    }
  }


  // ends here
  const rowClassRules = {
    'even-row': (params) => params.node.rowIndex % 2 !== 0,
  };

  const insertLogsForUser = async (action) => {
    let { email: loginUserEmail, user_id, name: loginUserName } = userDetails.auth.userDetails
    setLoading(true)
    try {
      let payload = {
        userId: user_id,
        userName: loginUserName,
        email: loginUserEmail,
        compId: companyId,
        compName: selectedCompanyName,
        actionType: "DeleteCompany",
        soNo: "",
        fileType: "",
        docType: "",
        profileName: data._name,
        title: data._title,
        designation: data._designation,
        department: data._department,
        userType: data._user_type,
        phoneNo: data._phone,
        address: data._address,
      }
      console.log("userDetailsLog_", payload);
      let logresponse = await insertLogs(payload)
      console.log("logresponse_", logresponse);
    }
    catch (err) {
      console.log("logresponseErr", err);
      setError(err)
    }
    setLoading(false)
  }

  return (
    <>
      <Loader loading={loading} />
      <Toaster position="top-center" reverseOrder={false} />
      <ErrorCatch err={error} />
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="row justify-content-between p-2">
            <div className="col-md-6">
              {/* <div className="card bg-transparent" style={{ border: "none" }}>
                {selectedValue == 3 ?

                  <p className="px-1 mt-3 page-title">Company Mapping</p>
                  : <p className="px-1 mt-3 page-title">Company List</p>}
              </div> */}
            </div>
            <div className="col-md-6 d-flex justify-content-end mt-2">
              {/* commenting  dropdown changing to radiobutton */}
              <div className=" bg-transparent">
                {/* commenting  dropdown changing to radiobutton */}

                {appObjects["Active Company"] &&
                  appObjects["Requested Company"] &&
                  appObjects["Company Mapping"] &&
                  <RadioButton
                    // radioButtonTitle="Status"
                    optionsList={statusList}
                    selectedValue={selectedValue}
                    handleChange={(e) => handleRadioButton(e)}
                  />}

                {/* <select
              className="form-select text-center"
              onChange={(e) => NavigateComonay(e)}
            >
              <option value={"/active-company"}>

                <h5 className="text-dark">
                  <b>Active Company</b>
                </h5>

              </option>


              <option value={"/requested-company"}>
                <h5 className="text-dark">
                  <b>Requested Company</b>
                </h5>
              </option>

            </select> */}


              </div>
            </div>
          </div>
        </div>

        {/* {userRoute && <Navigate to="/users/test" state={params} />} */}


        {data.length > 0 ? (
          <div
            className="datatable col-md-12 px-2"
          // style={{ height: "28rem", width: "100%", overflowY: "auto" }}
          >
            {selectedValue == 3 ?
              <div className="d-flex col-md-12 py-2 justify-content-end">
                {appObjects["Add New"] && <BasicButton
                  // className={'addNewbtn'}
                  text="Add New"
                  onClick={handleAddCompany}
                />}</div> :
              <div className="d-flex col-md-12 py-2 justify-content-end">
                {appObjects["Add Company"] && <BasicButton
                  // className={'addNewbtn'}
                  text="Add Company"
                  onClick={handleAddCompany}
                />}
              </div>
            }
            {/* <Box sx={{ height: "62vh", width: "100%", overflowY: "auto" }}> */}
            {/* <DataGrid
              sx={{
                borderColor: "secondary",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#0E3386",
                  fontSize: "12px",
                  color: "white",
                  padding: "10px",

                  // Set your desired header background color
                },
                "& .MuiDataGrid-cell": {
                  fontSize: "10px",
                  padding: "10px",
                  borderRight: "2px solid #e0e0e0",
                  // Adjust the font size as needed
                  // Adjust the cell padding as needed
                },
              }}
              className="datagrid"
              rows={data}
              // columns={userColumns}
              columns={companyColumns}
              pageSize={9}
              rowHeight={30}
              handleChangeCheckbox
              onCellClick={(params) => params}
              rowsPerPageOptions={[20]}
              getRowId={(row) => generateRandom()}
              checkboxSelection={false}
              hideFooter
            /> */}
            <AgGridComponent
              rowData={data}
              columnDef={companyColumns}
              rowClassRules={rowClassRules}
            // height={400}
            />
            {/* </Box> */}
          </div>
        ) : (
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col d-flex justify-content-center">
                {" "}
                No Rows to show
              </div>
            </div>
          </div>
        )}

        <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
          <Warning
            companydetails={companydetails}
            handleCancel={handleCancel}
            getRequestedCompany={getRequestedCompany}
          />
        </Modal>

        <Modal open={isWarningModal} onCancel={handleDeleteCancel} footer={null}>
          <div className="container">
            <div className="d-flex justify-content-center">
              <img src={warningimg} className="warnicon" alt="validation" />
            </div>
            {isToggle ? <p className="text-center mt-2"> <h6><b>Are you sure you want to InActive this company?</b></h6></p> :
              <p className="text-center mt-2"> <h6><b>Are you sure you want to  Delete this company?</b></h6></p>}
            <div className="d-flex justify-content-center gap-3 mt-3">
              <button className="btn btn-success btn-sm" onClick={isToggle ? selectedValue == 1 ? updateCompanyStatus : updateCustomerMap : handleDelete} >
                Yes
              </button>
              <button className="btn btn-danger btn-sm" value={"comp_status_02"}
                onClick={handleDeleteCancel}
              >
                No
              </button>
            </div>
          </div>
        </Modal>

        <Modal open={isWarningModalActComp} onCancel={handleDeleteCancelActComp} footer={null}>
          <div className="container">
            <div className="d-flex justify-content-center">
              <img src={warningimg} className="warnicon" alt="validation" />
            </div>
            <p className="text-center mt-2"> <h6><b>Are you sure you want to  Delete this company?</b></h6></p>
            <div className="d-flex justify-content-center gap-3 mt-3">
              <button className="btn btn-success btn-sm" onClick={companyDeletion} >
                Yes
              </button>
              <button className="btn btn-danger btn-sm" value={"comp_status_02"}
                onClick={handleDeleteCancelActComp}
              >
                No
              </button>
            </div>
          </div>
        </Modal>

        <Modal title={"Customer Mapping"}
          open={customerModalOpen} okText={isEdit ? "Update" : "Add"}
          // onOk={() => handleCustomerSubmit(isEdit ? "edit" : "add")}
          onCancel={handleCancelCustomer}
          style={{ minWidth: 200, maxWidth: 750 }}
          footer={null}
        >
          {isEdit && <div className="d-flex justify-content-end py-2">
            {isActiveComp ?
              <div className="statusActiveDiv" role="label">
                Active
              </div> :
              <div className="statusInactiveDiv" role="label">
                InActive
              </div>
            }
          </div>
          }

          {soCompanyList.length > 0 ?
            <>
              <div className="mt-2">
                <SelectInput
                  margin="0"
                  selectLabel="SO Company"
                  name="so_Comp"
                  disabled={isActiveComp ? true : false}
                  onChange={(e) => handleSoCompany(e)}
                  value={selectedSoCompany}
                  optionsList={soCompanyList}
                />
              </div>
              <div className="py-3">
                <SelectInput
                  margin="0"
                  selectLabel="Portal Company"
                  name="po_Comp"

                  disabled={isActiveComp ? true : false}
                  onChange={(e) => handlePoCompany(e)}
                  value={selectedPortalCompany}
                  optionsList={poCompanyList}
                />
              </div>
              <div>
                <RadioButton
                  // radioButtonTitle="Status"
                  optionsList={customerStatusList}
                  selectedValue={selectedCustomerValue}
                  handleChange={(e) => handleCustomerStatusRadio(e)}
                />
              </div>

              <div className="d-flex mt-4 justify-content-end p-2 gap-3">
                <ButtonComp
                  text="Add"
                  onClick={() => handleCustomerSubmit(isEdit ? "edit" : "add")}
                />
                <ButtonComp
                  text="Cancel"
                  type="secondary"
                  onClick={handleCancelCustomer}
                />
              </div>
            </>
            :
            <div className="mt-3">
              <h5>No SO company available for mapping </h5>
            </div>
          }
        </Modal>

        <Modal title={"Add New Company"}
          open={iscompanyModal} okText={"Add"}
          // onOk={() => handleCustomerSubmit(isEdit ? "edit" : "add")}
          onCancel={handleCancelCustomer}
          style={{ minWidth: 200, maxWidth: 750 }}
          footer={null}
        >

          <div className="mt-3">
            <InputBox
              label="*Company Name"
              name="_companyName"
              fullWidth
              onChange={(e) => setCompanyName(e.target.value)}
              value={companyName}
            />
          </div>
          <div className="mt-3">
            <SelectInput
              margin="0"
              selectLabel="Rule Format"
              name="rule_format"
              value={ruleFormat}
              onChange={(e) => handleRuleFormat(e)}
              optionsList={list}
            />
          </div>
          {ruleFormat == 1 ? <div className="mt-3">
            <SelectInput
              margin="0"
              selectLabel="Default Alba Rule"
              name="alba_rule"
              value={albaRule}
              onChange={handleAlbaRule}
              optionsList={albaRuleList}
            />
          </div> : <></>}
          <div className="d-flex mt-4 justify-content-end p-2 gap-3">
            <ButtonComp
              text="Add"
              onClick={() => handleCompanySubmit()}
            />
            <ButtonComp
              text="Cancel"
              type="secondary"
              onClick={handleCancelCustomer}
            />
          </div>
        </Modal>
      </div>

      <Modal
        title={"Update Rule Format"}
        open={isRuleFormat}
        onCancel={closeRuleFormat}
        style={{ minWidth: 200, maxWidth: 750 }}
        footer={null}
      >
        <div className="container">
          <div className="form ">
            <div className="row">
              <div className="col-md-12 mt-3 mb-3">
                {/* <RadioButton
                  // radioButtonTitle="Status"
                  optionsList={list}
                  selectedValue={selectedValueRb}
                  handleChange={radiohandleChange}
                /> */}

                <SelectInput
                  margin="0"
                  selectLabel="*Rule Format"
                  name="rule_format"
                  value={ruleFormat}
                  onChange={(e) => handleRuleFormat(e)}
                  optionsList={list}
                />
              </div>
              {ruleFormat == 1 ? <div className="col-md-12 mt-3 mb-3">
            <SelectInput
              margin="0"
              selectLabel="Default Alba Rule"
              name="alba_rule"
              value={albaRule}
              onChange={handleAlbaRule}
              optionsList={albaRuleList}
            />
          </div> : <></>}
            </div>

            <div className="pt-2 mt-3 d-flex justify-content-center">
              <button
                className="btn btn_submit"
                onClick={handleUpdateRuleFormat}
              >
                Submit
              </button>
            </div>
          </div>
        </div >
      </Modal>
    </>
  );
};

export default ActiveCompony;
