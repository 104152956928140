import React from "react";
import { useNavigate } from "react-router-dom"
// import "./ReadMore.css"

const ReadMore = () => {
  const navigate = useNavigate();
  return (
    <div className="container backcolor mt-3">
      <div class="row">
        <div class="col-sm-6">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">User Registration and Authentication</h5>
              <p class="card-text">
                
In our application, users register by providing their details and a secure password. The information is stored encrypted in a database. During authentication, users enter their credentials, which are compared with stored data. Successful authentication grants access to personalized features. Strong security measures protect user accounts and data integrity.

                Users can create an account by providing their personal
                information and creating login credentials.The system verifies the
                user's information and authenticates their identity during the
                login process.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title"> Data Conversion Solutions for Seamless File Format Transitions</h5>
              <p class="card-text">
                {" "}
                Our data conversion solutions provide efficient transitions between file formats. With expertise in diverse formats, we ensure accurate conversions while maintaining data integrity. Businesses can streamline format transitions, reduce manual effort, and enhance data management efficiency. Trust us for prompt service, cost-effectiveness, and exceptional support.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 mt-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Customer Feedback and Engagement</h5>
              <p class="card-text">
                Online COA emphasizes the importance of customer feedback and engagement. The platform allows businesses to gather feedback through surveys, reviews, and social media interactions, enabling them to understand customer sentiment and satisfaction levels. By actively listening to customer feedback, businesses can make data-driven decisions to continuously improve their products, services, and overall customer experience.
              </p>
            </div>
          </div>
        </div>
        {/* <div class="col-sm-6 mt-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Order Placement:</h5>
              <p class="card-text">
                Users proceed to the checkout process to finalize their purchases.
                The system collects shipping and payment details from the user.
                The order is created, and the user receives an order confirmation.
              </p>
            </div>
          </div>
        </div> */}
        {/* <div class="col-sm-6 mt-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Payment Processing:</h5>
              <p class="card-text">
                The system securely transmits the user's payment information to a
                payment gateway for processing. The payment gateway verifies the
                payment details and communicates with the user's bank to authorize
                the transaction. Once the payment is approved, the system confirms
                the payment and proceeds to fulfill the order.
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-6 mt-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Order Fulfillment:</h5>
              <p class="card-text">
                The system generates an order fulfillment request, including the
                user's shipping address and the ordered items. The fulfillment
                team prepares the order for shipment, including packaging and
                labeling. The order is handed over to the shipping carrier for
                delivery to the user's address.
              </p>
            </div>
          </div>
        </div> */}
        <div className="colo-sm-12 mt-4">
          <button onClick={() => navigate("/")} className="btn btn-primary">Back</button>
        </div>
      </div>
    </div>
  );
};

export default ReadMore;
