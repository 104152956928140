import { DataGrid } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Modal } from "antd";
import { LuView } from "react-icons/lu";
import { Input } from "antd";
import { JsonViewer } from "@textea/json-viewer";
import SetRulesForDocuments from "../Document/SetRulesforDocuments";
import SetRulesForKeys from '../Document/SetRulesForKeys';
import { getRequestedData, getUserRules, requestedRulesforRuleID } from "../../../../Services/AdminServices";
import { RulesColData } from "./RulesSourceData";
import Loader from "../../../../utils/Loader/Loader";
import AddNewRule from "./AddNewRule";
import { darken, lighten, styled } from '@mui/material/styles';
import ChangeRuleStatus from "../../Admin/ChangeRuleStatus";
import ErrorCatch from "../../../Reusable_Components/ErrorCatch";
import AgGridComponent from "../../../Reusable_Components/AgGridComponent";
import { useSelector } from "react-redux";

const SetRules = ({ rulesList }) => {
  console.log("rule__", rulesList)
  const [data, setData] = useState([]);
  const [isNewRuleModal, setIsNewRuleModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSetRuleModalOpen, setIsSetRuleModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [viewDataModal, setViewDataModal] = useState(false);
  const [rawData, setRawData] = useState()
  const [viewRuleData, setViewRuleData] = useState({
    "viewDocRules": "",
    "viewKeyRules": "",
    "viewRuleDetails": ""
  })

  const [jsonData, setJsonData] = useState([])
  const [userActive, setUserActive] = useState();
  const [isModaldataRefresh, setIsModaldataRefresh] = useState(false);
  const [isChangeRuleStatusModalOpen, setIsChangeRuleStatusModalOpen] = useState(false)
  const [input, setInput] = useState({
    _reason: "",
    _spec_status_id: "",
  });

  // uam role object start
  const userDetails = useSelector((state) => state)
  const appsubmodulename = "Services"
  const appfunctionalityname = "Rules"
  const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
  const [appObjects, setAppObjects] = useState(
    {
      "Rule Spec": true
    }
  )
  console.log("userDetails", userDetails)

  const updateAppObjects = (arr) => {
    let objectsArray = []
    arr.map((obj) => {
      console.log("updateButtons", obj)
      // if (obj.objecttypename == "button") {
      objectsArray.push(obj)
      // }
    })
    const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
      acc[appobjectname] = true;
      return acc;
    }, {});
    setAppObjects({ ...appObjects, ...finalObject })
    console.log("objectsArray", objectsArray)
    console.log("finalObject", finalObject)
  }

  useEffect(() => {
    console.log("appObjects", appObjects)
  }, [appObjects])

  useEffect(() => {
    console.log("userRoleObjects", userRoleObjects)
    const filteredList = []
    userRoleObjects.filter((obj) => {
      if (obj.appsubmodulename == appsubmodulename && obj.appfunctionalityname == appfunctionalityname) {
        filteredList.push(obj)
      }
    })

    console.log("filteredList", filteredList)
    updateAppObjects(filteredList)

  }, [])

  // uam role object end

  useEffect(() => {
    console.log("jsonData", jsonData)
  }, [jsonData])

  const showChangeRuleStatusModal = (act) => {
    console.log("Active", act.row);
    setInput({
      _reason: "", _spec_status_id: ""
    })
    setIsChangeRuleStatusModalOpen(true);
    setUserActive(act.row);
    setIsModaldataRefresh(true)
  };

  const handleChangeRuleStatusOk = () => {
    setIsChangeRuleStatusModalOpen(false);
    // getRulesList()
  };

  const handleChangeRuleStatusCancel = () => {
    setIsChangeRuleStatusModalOpen(false);
    setIsModaldataRefresh(false)
  };

  // Data background change
  const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

  const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

  const getSelectedBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

  const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .super-app-theme--Pending': {
      backgroundColor: getBackgroundColor(
        "#F7F5BC",
        // theme.palette.info.main, 
        theme.palette.mode),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(
          // "#362FD9",
          "#F7F5BC",
          // theme.palette.info.main,
          theme.palette.mode,
        ),
      },
      '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(
          // "#362FD9",
          "#F7F5BC",
          // theme.palette.info.main,
          theme.palette.mode,
        ),
        '&:hover': {
          backgroundColor: getSelectedHoverBackgroundColor(
            // "#362FD9",
            "#F7F5BC",
            // theme.palette.info.main,
            theme.palette.mode,
          ),
        },
      },
    },
    '& .super-app-theme--Approved': {
      backgroundColor: getBackgroundColor(
        // "#A2FF86",
        theme.palette.success.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(
          // "#A2FF86",
          theme.palette.success.main,
          theme.palette.mode,
        ),
      },
      '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(
          // "#A2FF86",
          theme.palette.success.main,
          theme.palette.mode,
        ),
        '&:hover': {
          backgroundColor: getSelectedHoverBackgroundColor(
            // "#A2FF86",
            theme.palette.success.main,
            theme.palette.mode,
          ),
        },
      },
    },
    '& .super-app-theme--Rejected': {
      backgroundColor: getBackgroundColor(
        // "#FF6666",
        theme.palette.warning.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(
          // "#FF6666",
          theme.palette.warning.main,
          theme.palette.mode,
        ),
      },
      '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(
          // "#FF6666",
          theme.palette.warning.main,
          theme.palette.mode,
        ),
        '&:hover': {
          backgroundColor: getSelectedHoverBackgroundColor(
            // "#FF6666",
            theme.palette.warning.main,
            theme.palette.mode,
          ),
        },
      },
    },
    '& .super-app-theme--Deactive': {
      backgroundColor: getBackgroundColor(
        // "#526D82",
        theme.palette.error.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(
          // "#526D82",
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
      '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(
          // "#526D82",
          theme.palette.error.main,
          theme.palette.mode,
        ),
        '&:hover': {
          backgroundColor: getSelectedHoverBackgroundColor(
            // "#526D82",
            theme.palette.error.main,
            theme.palette.mode,
          ),
        },
      },
    },
  }));

  // Databackground close

  const navigate = useNavigate();
  const [userRoute, setUserRoute] = useState(false);
  const [params, setParams] = useState([]);

  const showNewRuleModal = () => {
    setIsNewRuleModal(true);
  };

  const handleNewRuleOk = () => {
    setIsNewRuleModal(false);
    getUserRulesList()
  };


  const handleNewRuleCancel = () => {
    setIsNewRuleModal(false);
  };

  const showModal = (params) => {
    setSelectedRow(params.row)
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    getUserRulesList()
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showSetRules = (params) => {
    console.log("showSetRulesparams", params)
    setSelectedRow(params.row)
    setIsSetRuleModalOpen(true);
  };

  const handleSetRuleOk = () => {
    setIsSetRuleModalOpen(false);
    getUserRulesList()
  };

  const handleSetRuleCancel = () => {
    setIsSetRuleModalOpen(false);
  };

  const { Search } = Input;
  const handleDisplay = (url) => {
    localStorage.removeItem("api_url");
    localStorage.setItem("api_url", url);
    navigate("/api_format_data");
  };

  const getUserRulesList = async () => {
    setLoading(true)
    let userEmail = localStorage.getItem("Logindata")
    console.log("userEmail", userEmail)

    try {
      let res = await getUserRules(userEmail)
      console.log("getUserRulesres", res)

      if (res.status == 200) {
        let resdata = res.data.data
        console.log("resdata", resdata)
        let finalArr = []
        resdata.map((obj) => {
          if (obj._rulestype !== null && !obj._rulestype.includes("file")) {
            const trimmedRulestype = obj._rulestype.trim()
            if (trimmedRulestype !== "customfile" || trimmedRulestype !== "lambdafile") {
              finalArr.push(obj)
            }
          }
        })
        finalArr.sort((a, b) => b._rule_id - a._rule_id);
        setData(finalArr)
        console.log("getUserRulesList", data, finalArr)
        // data gets filled here

      }
    }
    catch (error) {
      // toast.error(error.response.data.data, {
      //   position: "top-right",
      //   autoClose: 5000,
      // });
      setError(error)
    }
    setLoading(false)
  }
  // console.log("data__,data")
  useEffect(() => {
    getUserRulesList()

  }, []);



  useEffect(() => {
    if (rulesList == true) {
      getUserRulesList()

    }
  }, [rulesList]);

  const viewRawData = async (p) => {
    console.log("viewRawDataparams", p)
    try {
      let ruleID = p.data._rule_id
      setViewDataModal(true);
      let response = await requestedRulesforRuleID(ruleID)

      if (response.status == 200) {
        let requestedRules = response.data
        let docRules = JSON.stringify(requestedRules.Doc.data, null, 1)
        let keyRules = JSON.stringify(requestedRules.Key.data, null, 1)
        let ruleData = JSON.stringify(requestedRules.Rule.data, null, 1)
        setViewRuleData({
          "viewDocRules": `${docRules}`,
          "viewKeyRules": `${keyRules}`,
          "viewRuleDetails": `${ruleData}`,
        })
        console.log("requestedRules", requestedRules)
        setJsonData(requestedRules)
        console.log("rules", docRules, keyRules, ruleData)
      }
    }
    catch (error) {
      setError(error)

    }

  };

  const viewRawDataCancel = () => {
    setViewDataModal(false);
    setViewRuleData({
      "viewDocRules": "",
      "viewKeyRules": "",
      "viewRuleDetails": ""
    })
  };



  const actionColumn = [


    {
      field: "_docvalue",
      headerName: "Rule Spec",
      // width: 120,
      flex: 0.2,
      headerClassName: "super-app-theme--header",
      cellRenderer: (params) => {
        return (
          <div className="cellAction">
            {appObjects["Rule Spec"] && <a onClick={() => viewRawData(params)} class="btn">
              <LuView />
            </a>}
          </div>
        );
      },
    },

  ];

  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const handleAddRules = () => {
    showNewRuleModal()
  }

  const rowClassRules = {
    'even-row': (params) => params.node.rowIndex % 2 !== 0,
  };

  return (
    <>
      <Loader loading={loading} />
      <Toaster position="top-center" reverseOrder={false} />
      <ErrorCatch err={error} />
      <div className="container-fluid">
        <div className="row">
          {/* <div className="col-sm-10">
            <div className="card bg-transparent" style={{ border: "none" }}>
              <p className="page-title">
                Rule List
              </p>
            </div>
          </div> */}

          {/* <div className="col-sm-2">  
            <div className="card bg-transparent" style={{ border: "none" }}>
              <button
                className="btn btn-primary"
                onClick={handleAddRules}
              >
                Add Rules
              </button>
            </div>
          </div> */}
        </div>

        {data.length > 0 ? (
          <div className="col-md-12 mt-2"
          // style={{ height: "25rem", width: "78%", }}
          >
            {/* <Box sx={{ height: "62vh", width: "100%", overflowY: "auto" }}>
              <StyledDataGrid
                sx={{

                  borderColor: "secondary",
                  "& .MuiDataGrid-cell:hover": {
                    color: "primary.main",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    backgroundColor: "#0E3386",
                    fontSize: "12px",
                    color: "white",
                    padding: "10px",


                  },
                  "& .MuiDataGrid-cell": {
                    fontSize: "10px",
                    padding: "10px",
                    borderRight: "2px solid #e0e0e0",

                  },
                }}
                rowHeight={30}
                initialState={{
                  sorting: {
                    sortModel: [{ field: '_rule_id', sort: 'desc' }],
                  },
                }}
                rows={data}
                //  data here
                getRowClassName={(params) => `super-app-theme--${params.row._spec_status_name}`}
                columns={RulesColData.concat(actionColumn)}
                handleChangeCheckbox
                onCellClick={(params) => params}
                rowsPerPageOptions={[]}
                labelRowsPerPage=''
                pagination="null"
                getRowId={(row) => generateRandom()}
                checkboxSelection={false}
                hideFooter
                options={{
                  paging: false,
                  rowsPerPageOptions: false
                }}
              />
            </Box> */}
            <AgGridComponent
              rowData={data}
              columnDef={RulesColData.concat(actionColumn)}
              rowClassRules={rowClassRules}
            />
          </div>
        ) :
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col d-flex justify-content-center">

                Now Rows to show
              </div>
            </div>
          </div>
        }
      </div>

      <AddNewRule
        isNewRuleModal={isNewRuleModal}
        handleNewRuleCancel={handleNewRuleCancel}
        handleNewRuleOk={handleNewRuleOk}
        getUserRulesList={getUserRulesList} />

      <Modal
        style={{ top: 20, minWidth: 300, maxWidth: 600 }}
        className="p-2"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <SetRulesForDocuments
          selectedRow={selectedRow}
          handleOk={handleOk}
          onCancel={handleCancel}
        />
      </Modal>

      <Modal
        style={{ top: 20, minWidth: 300, maxWidth: 600 }}
        width={800}
        className="p-2"
        open={isSetRuleModalOpen}
        onOk={handleSetRuleOk}
        onCancel={handleSetRuleCancel}
        footer={null}
      >
        <SetRulesForKeys
          selectedRow={selectedRow}
          isSetRuleModalOpen={isSetRuleModalOpen}
        />
      </Modal>

      <Modal
        style={{ top: 20, minWidth: 300, maxWidth: 600 }}
        title="Rule Details"
        className="p-2"
        open={viewDataModal}
        onCancel={viewRawDataCancel}
        footer={null}
      >
        <hr />
        {Object.keys(jsonData).length > 0 && <div style={{ height: "22rem", overflowY: "auto" }}>
          {/* <JsonViewer value={jsonData} /> */}
          <p><strong>Rule Name:</strong> {jsonData?.Rule[0].rule_name} </p>
          <p><strong>Rule Description:</strong> {jsonData?.Rule[0].description} </p>
        </div>}
      </Modal>

      <Modal
        style={{ top: 20, minWidth: 300, maxWidth: 600 }}
        className="p-2 w-25"
        open={isChangeRuleStatusModalOpen}
        onOk={handleChangeRuleStatusOk}
        onCancel={handleChangeRuleStatusCancel}
        footer={null}
      >
        <ChangeRuleStatus isModalOpen={isChangeRuleStatusModalOpen}
          input={input} setInput={setInput}
          data={userActive} handleOk={handleChangeRuleStatusOk} dataRefresh={isModaldataRefresh} />

      </Modal>
    </>
  );
};

export default SetRules;
