import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../../Assets/Styles/FAQ.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';                                 //
// import RemoveIcon from '@mui/icons-material/Remove';                                 //MC
import env from "../../../Services/Environment";
import { Button, TextField } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import Rating from "@mui/material/Rating";
import { allFaqList, createFaqFeedback, getManualDetails, searchFaq } from "../../../Services/AdminServices";
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { messages } from "../../Messages";
import InputBox from "../../Reusable_Components/InputBox";
import Loader from "../../../utils/Loader/Loader";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
import ImageSlider from "../../Reusable_Components/ImageSlider";
import prd1 from '../../../Assets/Images/alba_prd_1.png';
import prd2 from '../../../Assets/Images/alba_prd_2.png';
import prd3 from '../../../Assets/Images/alba_prd_3.png';
import prd4 from '../../../Assets/Images/alba_prd_4.png';
import download from '../../../Assets/Images/download.png';
import { useSelector } from "react-redux";
import { PiDownloadSimple } from "react-icons/pi";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AllFaq = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [faqData, setFAQData] = useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [searchInput, setSearchInput] = useState({
    search: "",
  });

  // uam role object start
  const userDetails = useSelector((state) => state)
  const appsubmodulename = "Customer FAQ"
  const appfunctionalityname = "Customer FAQ"
  const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
  const userDetailsLog = userDetails.auth.userDetails

  const [appObjects, setAppObjects] = useState(
    {
      "Send": false
    }
  )
  const [manualDetails, setManualDetails] = useState([])

  console.log("userDetails", userDetails)

  const updateAppObjects = (arr) => {
    let objectsArray = []
    arr.map((obj) => {
      console.log("updateButtons", obj)
      if (obj.objecttypename == "button") {
        objectsArray.push(obj)
      }
    })
    const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
      acc[appobjectname] = true;
      return acc;
    }, {});
    setAppObjects({ ...appObjects, ...finalObject })
    console.log("objectsArray", objectsArray)
    console.log("finalObject", finalObject)
  }

  useEffect(() => {
    console.log("appObjects", appObjects)
  }, [appObjects])

  useEffect(() => {
    console.log("userRoleObjects", userRoleObjects)
    const filteredList = []
    userRoleObjects.filter((obj) => {
      if (obj.appsubmodulename == appsubmodulename && obj.appfunctionalityname == appfunctionalityname) {
        filteredList.push(obj)
      }
    })

    console.log("filteredList", filteredList)
    updateAppObjects(filteredList)

  }, [])

  const [isOpen, setIsOpen] = useState(false)

  // uam role object end
  const handleChange = (panel) => (event, isExpanded) => {
    console.log("handleChange", panel)
    setExpanded(isExpanded ? panel : false);
    setIsOpen(!isOpen)
  };

  const fetchAllFAQ = async () => {
    setLoading(true)
    try {
      let response = await allFaqList()
      console.log("response", response);
      if (response.status == 200) {
        setFAQData(response.data.data.rows);
      }
      else {
        toast.error(messages.noDataFound)
      }
    }
    catch (err) {
      setLoading(false)
      // console.log("fetchAllFAQErr", err)
      // if("response" in err){
      //   toast.error(err.response.data.message)
      //   // if(err.response.status == 401){

      //   // }
      // }
      // else if("message" in err){
      //   toast.error(err.message)
      // }
      // else{
      //   toast.error("Error Occurred")
      // }
      // return <ErrorCatch err={err}/>
      // return err
      setError(err)
      // let newError =  new Error("Test Error")
      // setError(newError)

    }
    setLoading(false)
  };

  useEffect(() => {
    fetchAllFAQ();
    getManualDetailsByRole()
  }, []);

  const handleSearch = async (e) => {
    const { name, value } = e.target;
    setSearchInput({ ...searchInput, [name]: value });
    console.log("name_value", name, value, searchInput)
    let sendData = {
      "search": value
    }
    try {
      const response = await searchFaq(sendData)
      //   console.log(response.data.data.rows);
      setFAQData(response.data.data.rows);
    } catch (error) {
      setError(error)
    }
  };

  const submitSearch = async (e) => {
    e.preventDefault();
    fetchAllFAQ();
  };

  let initialValue = {
    feedback_data: "",
    rating: 0,
  };

  const [inputData, setInputData] = useState(initialValue);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  const handleRating = (event, value) => {
    const { name } = event.target;
    setInputData({ ...inputData, [name]: value });
  };

  const onSubmitCreateFeedBack = async (e) => {
    e.preventDefault();
    let getEmail = localStorage.getItem("Logindata");
    inputData.email = getEmail;
    const { email, feedback_data, rating } = inputData;

    if (!email || !feedback_data || !rating) {
      toast.error(messages.fillAllFields);

    } else {
      setLoading(true)
      setError(error)
      try {
        let response = await createFaqFeedback(inputData)
        if (response.data.success == true) {
          toast.success("Feedback sent successfully.");
          setInputData(initialValue);
        }
        else {
          toast.error(messages.somethingWrong)
        }
      }
      catch (error) {
        setError(error)
      }
      setLoading(false)
    }
  };

  const images = [
    prd1, prd2, prd3, prd4
  ]
  const images1 = [
    prd2, prd4, prd1, prd3
  ]
  const images2 = [
    prd4, prd3, prd2, prd1
  ]

  const handleKeyDown = (event) => {
    let element = event.target.nodeName
    if (event.key === 'Enter' && element !== "TEXTAREA") {
      console.log("handleKeyDown", event.key, event)
      event.preventDefault()
    }
  }

  const handleDownload = (fileName) => {
    console.log("handleDownload_")
    fetch(`${env.Backend_Url}/downloadmanual/${fileName}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    })
      .then(response => {
        if (response.ok) {
          return response.blob(); // Convert response to a blob
        }
        throw new Error('Network response was not ok.');
      })
      .then(blob => {
        // Create a URL for the blob and initiate the download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.pdf`); // Name the file
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }

  const getManualDetailsByRole = async () => {
    console.log("manualRoleId_", userDetailsLog);
    let payload = {
      role: userDetailsLog.role_id
    }
    let manualResponse = await getManualDetails(payload)
    console.log("manualResponse_", manualResponse)
    if (manualResponse.status == 200) {
      setManualDetails(manualResponse.data.data)
    }
  }

  return (
    <>

      <Loader loading={loading} />
      <Toaster position="top-center" reverseOrder={false} />
      <ErrorCatch err={error} />

      {/* <div className="container-fluid mt-2 px-4">
        <div className="d-flex col-md-12 col-lg-12 col-sm-12">
          <div className="col-md-4 col-lg-4 col-sm-12">
            <ImageSlider images={images} className={'slidecontainer'} />
          </div>
          <div className="col-md-4 col-lg-4 px-2 col-sm-12">
            <ImageSlider images={images1} className={'slidecontainer'} />
          </div>
          <div className="col-md-4 col-lg-4 col-sm-12">
            <ImageSlider images={images2} className={'slidecontainer'} />
          </div>
        </div>
      </div> */}
      <div className="container-fluid">
        <div className="col-md-12 d-flex mt-3">
          <div className="col-md-6 px-3">
            <p className="page-title">Frequently Asked Questions</p>
            <div className="col-md-12 col-lg-12 p-1 search-container">
              <Paper
                component="form"
                sx={{ display: 'flex', alignItems: 'center', borderRadius: "5px" }}
              >
                <IconButton type="button" sx={{ p: '10px', }} aria-label="search">
                  <SearchIcon />
                </IconButton>
                <input
                  type="text"
                  name="search"
                  onKeyDown={handleKeyDown}
                  value={searchInput.search}
                  className="form-control_Searchfild"
                  placeholder="How can we help you"                           //mc
                  style={{
                    border: "none",
                    boxShadow: 'none',
                    borderRadius: 'none',                                          //mc
                    // flexGrow: 1,
                    width: "90%"
                  }}
                  onChange={(e) => handleSearch(e)}
                />

              </Paper>
            </div>
            {faqData.length < 0 ? (
              ""
            ) : (
              <div className="col-md-12 px-1 mt-3" style={{ height: "24rem", overflowY: "auto", overflowX: "hidden" }}>
                <div className="Listitems" >
                  <div className="col-md-12 faq-list-container">
                    {faqData.map((data, index) => {
                      return (
                        <>

                          <Accordion
                            expanded={expanded === index}
                            onChange={handleChange(index)}
                            className="faq_list_container"
                            sx={{ border: "none" }}                                           //mc
                          >
                            <AccordionSummary
                              // expandIcon={isOpen ? <RemoveIcon/> : <AddIcon />}
                              // expandIcon={<AddIcon />}
                              expandIcon={<ExpandMoreIcon />}

                              aria-controls="panel2bh-content"
                              id="panel2bh-header"
                            >
                              <Typography sx={{ width: "auto", fontSize: "14px" }}>
                                <text>{data._question}</text>
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                sx={{ width: "100%", fontSize: "14px" }}
                                className="d-flex justify-content-start"
                              >
                                <text className="text-success"> {data._answer}</text>
                              </Typography>
                              <Typography
                                sx={{ width: "100%", marginTop: "10px" }}
                              // className="d-flex justify-content-end"
                              >
                                {/* <TextField
                            name={"feedback_data"}
                            value={inputData.feedback_data}
                            onChange={(e) => handleInput(e)}
                            id="standard-basic"
                            label="Feedback"
                            variant="standard"
                            sx={{fontSize:10}}
                          /> */}

                                <InputBox
                                  id="standard-basic"
                                  label="Feedback"
                                  name="feedback_data"
                                  fullWidth
                                  multiline
                                  rows={2}
                                  onChange={(e) => handleInput(e)}
                                  value={inputData.feedback_data}
                                />

                                <div className="row">
                                  <div className="col-md-3">
                                    <Rating
                                      className="mt-3"
                                      name="rating"
                                      value={inputData.rating}
                                      onChange={(event, newValue) => {
                                        handleRating(event, newValue);
                                      }}
                                    />
                                    &nbsp; &nbsp;
                                  </div>
                                  <div className="col-md-3">
                                    {appObjects["Send"] && <Button
                                      className="mt-3"
                                      variant="contained"
                                      onClick={(e) => onSubmitCreateFeedBack(e)}
                                      style={{ width: "5rem", height: "2rem" }}
                                    >
                                      Send
                                    </Button>}
                                  </div>
                                </div>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>

                        </>


                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* <div className="col-md-5">
            <div className="px-2 py-1"
              style={{ backgroundColor: "#094FA3", height: "33px"}}
            >
              <p className="page-title"
                style={{ color: "#ffffff" }}
              >Manuals</p>
            </div>
            <div className="card">
              <div className="px-2 d-flex pt-2" onClick={() => handleDownload('Online COA API Manual')}>
                <img src={download} className='downicon'></img>
                <div className="manual px-2">
                  Online COA API Manual
                </div>
              </div>
            </div>
          </div> */}

          <div className="col-md-6"
          // style={{ width: "100%" }}
          >
            <div className="mt-1">
              <p className="page-title" style={{ fontSize: "16px" }}>Manuals</p>
            </div>

            <div className="card">
              <div className="px-2 py-2" style={{ backgroundColor: "#094FA3", height: "40px" }}>
                <p className="page-title" style={{ color: "#ffffff" }}>Manuals</p>
              </div>
              {manualDetails.length > 0 && manualDetails.map((item, inx) => {
                return (
                  <div className="">
                    <div className="px-2 d-flex py-2" onClick={() => handleDownload(item.name)}>
                      {/* <img src={download} className='downicon'></img> */}
                      <div className="downicon2">
                        <PiDownloadSimple />
                      </div>
                      <div className="manual px-2">
                        {item.name}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllFaq;
