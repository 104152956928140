import logo from "../../../Assets/Images/alba_logo.png";
import React, { useState } from "react";
import { isVerified } from "../../../Services/UserServices.jsx";
import toast, { Toaster } from "react-hot-toast";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage, Form } from "formik";
import {useNavigate} from "react-router-dom"
import Loader from "../../../utils/Loader/Loader";
import ErrorCatch from "../../Reusable_Components/ErrorCatch.jsx";

const EmailVerification = (props) => {
  const [loading, setLoading] = useState(false)
  const [error,setError] = useState()
  const [otp, setOtp] = useState({ otp: "" });

  const getotppopupclose = () => {
    setTimeout(() => {
      props.onCancel();
    
    }, 5000);
  };
  console.log("__prop".props)
 const navigate = useNavigate()
  const Verifyotp = async (value) => {
    // otp.otp = value.otp;
    setOtp(otp);
    setLoading(true)
  await isVerified({ email: props.email, otp:value.otp})
      .then((res) => {
        console.log("OTP verification done", res);
        if (res.data.status === 200) {
          toast.success(res.data.msg);
          // navigate('/')
          props.onCancel();
        } else {
          toast.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.log("isVerifiederror",error);
        // toast.error(error.message, {
        //   position: "top-right",
        //   autoClose: 5000,
        // });
        setError(error)
      });
      setLoading(false)
  };

  const initialValues = {
    otp: "",
  };

  const validationSchema = () => {
    return Yup.object().shape({
      otp: Yup.string().required("otp is required"),
    });
  };

  return (
    <>
    <Loader loading={loading} />
    <ErrorCatch err={error}/>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        initialTouched={{ zip: true }}
        onSubmit={Verifyotp}
      >
        {({ handleChange }) => {
          return (
            <Form id="signupform">
              <div className="d-flex justify-content-center">
                <img src={logo} alt="" />
              </div>
              <br />

              <b className="text-center">
                Your OTP is send to {props.email}. please verify.
              </b>
              <div className="row pt-3">
                <div className=" col-md-12">
                  <div className="pt-2 inputbox">
                    <Field
                      type="text"
                      className="form-control"
                      name="otp"
                      placeholder="Enter otp"
                      style={{
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <ErrorMessage
                    name="otp"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <br />

              <div className="pt-2 col-md-12 text-center">
                <button class="btn btn-primary" type="submit">
                  Verify OTP
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>

      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default EmailVerification;
