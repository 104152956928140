import React from 'react';
import { Card } from 'antd';
const CardComp = ({ icon, heading, count,onClick }) => (
    <Card className='cardcomp' onClick={onClick}>
        <div>
            <div className='d-flex col-md-12 justify-content-center cardhead'>
                <div className=''>
                    <img src={icon}/>
                </div>
                <div className='px-2'>
                    {heading}
                </div>
            </div>
            <div className='mt-2 carddetails'>
                {count}
            </div>
        </div>
    </Card>
);
export default CardComp;