import React  from "react";

const ButtonComp = ({ text, onClick, type, disabled }) => {
    return (
        <button
            className={`${type == "secondary" ? "white-btn" : "red-btn"} px-3`}
            onClick={onClick}
            disabled={disabled}
        >
            {text}
        </button>
    )
}
export default ButtonComp