import React, { useState } from "react";
import { Modal } from "antd";
import "../../../Assets/Styles/Navbar.css";
import Signin from "../Homepages/Signin.jsx";
import HowItWorks from "../Homepages/HowitsWork.jsx";
import Featurespage from "../Homepages/Features";
import ContactUs from "../Homepages/ContactUs";
import alba_header from "../../../Assets/Images/alba_header.png";
import SignUp from "../Homepages/Signup.jsx";
import LandingpageFAQ from '../Homepages/FAQLandingpage';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { useNavigate } from "react-router-dom";

const Navbar = ({ onclick, onclickCognito }) => {
  const { instance } = useMsal();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [HowItsWork, setHowItsWork] = useState(false);
  const [Contactus, setContactus] = useState(false);
  const [Features, setFeatures] = useState(false);
  const [Faq, setFAQ] = useState(false);
  const navigate = useNavigate();

  const FAQModel = () => {
    setFAQ(true);
  };

  const CloseFAQ = () => {
    setFAQ(false);
  };

  const FeaturesModel = () => {
    setFeatures(true);
  };

  const CloseFeatures = () => {
    setFeatures(false);
  };

  const HowItsWorkModel = () => {
    setHowItsWork(true);
  };

  const CloseModel = () => {
    setHowItsWork(false);
  };

  //Contactus

  const ContactusModelopen = () => {
    setContactus(true);
  };

  const ContactusModelClose = () => {
    setContactus(false);
  };

  // const ShowLoginpopup = () => {
  //   setIsModalOpen(true);
  // };

  const handleLoginAzure = (loginType) => {
    console.log("handleLoginAzure", instance)
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).then((result) => {
        console.log("azureInstance", result)
        // localStorage.setItem("azureToken", result.accessToken)
        localStorage.setItem("isAzureLogin", true);
      }).catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).then((result) => {
        console.log("azureInstance", result)
      }).catch((e) => {
        console.log(e);
      });
    }
  };

  const Signinpopup = () => {
    setIsModalOpen(false);
  };

  const Signinclose = () => {
    setIsModalOpen(false);
  };

  // Pop up for Register
  const showModal1 = () => {
    setIsModalOpen1(true);
  };

  const handleOk1 = () => {
    setIsModalOpen1(false);
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };


  return (
    <>
      <nav className=" navbar navbar-expand-lg fixed-top">
        <div className="container-fluid">

          <img
            src={alba_header}
            alt="Logo"
            className="img-fluid"
            style={{ maxWidth: "208px", maxHeight: "33px" }}
          />

          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="landingNav navbar-nav mx-auto gap-4">
              <li className="nav-item">
                <a className="nav-link" onClick={HowItsWorkModel}>How It Works?</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={FeaturesModel}>Features</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={ContactusModelopen}>Contact Us</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={FAQModel}>FAQ</a>
              </li>
            </ul>
            <div className="d-flex gap-3">
              {/* <button className="btn red-btn mr-2" onClick={() => handleLoginAzure("popup")}>
                Admin Login
              </button> */}

              {/* Cognito Signin */}
              <button className="btn red-btn px-5"
                onClick={onclickCognito}
              >
                {/* Cognito Signin */}
                Login
              </button>

              {/* <button className="btn red-btn px-5"
                // onClick={ShowLoginpopup}
                onClick={onclick}
              >
                Login
              </button> */}
            </div>
          </div>
        </div>
      </nav>

      <Modal
        className="p-2"
        open={isModalOpen}
        onOk={Signinpopup}
        onCancel={Signinclose}
        footer={null}
        destroyOnClose
        maskClosable={false}
        style={{ top: 20, minWidth: 300, maxWidth: 600 }}
      >
        <Signin Signinpopup={Signinpopup} />
      </Modal>

      <Modal
        open={isModalOpen1}
        onOk={handleOk1}
        onCancel={handleCancel1}
        footer={null}
        maskClosable={false}
        destroyOnClose
        style={{ top: 20, minWidth: 300, maxWidth: 600 }}
      >
        <SignUp handleCancel1={handleCancel1} />
      </Modal>

      {/* How its work */}
      <Modal

        className="p-2"
        open={HowItsWork}
        onOk={CloseModel}
        onCancel={CloseModel}
        footer={null}
        maskClosable={false}
        style={{ top: 20, minWidth: 300, maxWidth: 600, background: "none", color: "none" }}
      >
        <HowItWorks />
      </Modal>

      {/* Contact us */}
      <Modal
        className="p-2"
        open={Contactus}
        onOk={ContactusModelClose}
        onCancel={ContactusModelClose}
        footer={null}
        maskClosable={false}
        style={{ top: 20, width: 300 }}
      >

        <ContactUs />
      </Modal>

      {/* Features us */}
      <Modal
        className="p-2"
        open={Features}
        onOk={CloseFeatures}
        onCancel={CloseFeatures}
        footer={null}
        maskClosable={false}
        style={{ top: 15, minWidth: 300, maxWidth: 600 }}
      >
        <Featurespage />
      </Modal>

      {/* FAQ us */}
      <Modal
        className="p-2"
        open={Faq}
        onOk={CloseFAQ}
        onCancel={CloseFAQ}
        footer={null}
        maskClosable={false}
        style={{ top: 20, minWidth: 300, maxWidth: 600 }}
      >
        <LandingpageFAQ />
      </Modal>
    </>
  );
};

export default Navbar;
