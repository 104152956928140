import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Formik, ErrorMessage, Form, Field } from "formik";
import * as Yup from "yup";
import { AddUser } from "../../../Services/UserServices.jsx";
import logo from "../../../Assets/Images/alba_logo.png";
import EmailVerification from "./Emailverificationcode";
import { Modal } from "antd";
import { Input } from "antd";
import CryptoJS from 'crypto-js';
const SignUp = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [arr, setArr] = useState([]);
  const [email, setemail] = useState();
  const [name, setName] = useState()


  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    props.handleCancel1();
  };


  const getSignupmodelclose = () => {
    setTimeout(() => {
      props.handleCancel1(); // Open new modal and close the previous modal
    }, 30000);
  };

  const getSignupmodelcloseonfail = () => {
    setTimeout(() => {
      props.handleCancel1(); // Open new modal and close the previous modal
    }, 4000);
  };

  const initialValues = {
    title: "",
    uname: "",
    email: "",
    phone: "",
    address: "",
    comp_name: "",
    department: "",
    designation: "",
    password: "",
  };



  const handleClick = (value) => {


    setemail(value.email);
    // setSignup(signup);
    console.log("adduservalue", value)
    AddUser(value)
      .then((res) => {
        console.log("res", res)
        if (res.status === 200) {
          toast.success("Otp send to your email successfully.");
          // getSignupmodelclose()

          showModal();
        } else {
          toast.error("Something went wrong, Check all fields and try again");
          getSignupmodelcloseonfail()

        }
      })
      .catch((error) => {
        console.log("res1", error)
        // console.log(error.response.data.msg);
        toast.error(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
        });

        // getSignupmodelcloseonfail()
      });
  };



  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const valuess = (/[^A-Za-z]/g, '');

  const validationSchema = () => {
    return Yup.object().shape({
      title: Yup.string().required("Title is required"),



      uname: Yup.string()
        .required("Name is required")
        .matches(/^[^\d]*$/, 'Name should not contain numbers')
        .min(5, "Please Enter Full Name")
        .max(25, "Name must not exceed 25 characters"),



      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),

      phone: Yup.string()
        .required("Phone Number is required")
        .matches(phoneRegExp, "Phone number must be in digit")
        .max(10, "Phone number must not exceed 10 digit"),



      designation: Yup.string().required("Designation is required"),

      comp_name: Yup.string().required("Company name is required"),

      department: Yup.string().required("Department  is required"),

      address: Yup.string().required("Address is required"),
      password: Yup.string()
        .required("Password is a required")
        .min(8, "Password must be at least 8 characters"),
    });
  };



  // console.log("__val",validationSchema)
  // // function for uppercase
  // function toTitle(str) {
  //   console.log("str",str);
  // const titleCase = str.spilt(' ').map(word =>{
  //   return word.charAt(0).toUpperCase() + word.slice(1);

  // })
  // .join(' ');

  // return titleCase;

  // }

  // console.log(toTitle('mark antony'));

  // let str = "markantony";
  // console.log("markantony",str
  //   .toLowerCase()
  // .spilt(' ')
  // .map(word =>{
  //   return word.charAt(0).toUpperCase() + word.slice(1);

  // })
  // .join(' '));
  // starts here
  // const hashPassword = async (password) => {
  //   try {
  //     const saltRounds = 10; // Number of salt rounds
  //     const hashedPassword = await bcrypt.hash(password, 10);
  //     return hashedPassword;
  //   } catch (error) {
  //     throw error;
  //   }
  // };
  // ends here
  return (
    <>
    <Toaster position="top-center" reverseOrder={false} />
    <div className="Signin">
      <div className="row ">
        <div className="col-md-12">
          <div className="d-flex flex-row justify-content-center">
            <div className="col-md-12 ">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}

                onSubmit={async (values, { resetForm }) => {
                  console.log('ASD', values.uname);
                  console.log('com', values.comp_name);
                  // adding function to capitalize every first letter of string  for name , company
                  const str_name = values.uname;
                  const arr = str_name.split(" ").map(word => {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                  });
                  const capitalizedStr = arr.join(" ");
                  console.log("__C", capitalizedStr);
                  // company code
                  const str_comp = values.comp_name;
                  const allCaps = str_comp.toUpperCase()
                  
                  const arr_com = str_comp.split(" ").map(word => {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                  });
                  const capitalizedStr_comp = arr_com.join(" ");
                  console.log("__n", capitalizedStr_comp,allCaps);
                  // ends here

                  console.log("value", values)
                  // adding condition to pass values and capitalized values to new final object 
                  // starts here for bcrypt password
                  // Hash the password using bcrypt
                  const hashedPassword = CryptoJS.SHA256(values.password).toString();
                  // Use the hashed password in your request
                  values.password = hashedPassword;
                  // ends here
                  console.log("valuee", values.email);
                  console.log("valuep", values.password)
                  console.log("signinvalues1", values)
                  // ends here
                  setArr(values);
                  let final_object =
                  {
                    title: values.title,
                    uname: capitalizedStr,
                    email: values.email,
                    phone: values.phone,
                    address: values.address,
                    // comp_name: capitalizedStr_comp,
                    comp_name: allCaps,
                    department: values.department,
                    designation: values.designation,
                    password: values.password,

                  }

                  console.log("__arr", final_object);
                  // passing object rather than values
                  // return
                  await handleClick(final_object);
                  // ends here
                  resetForm();

                }}

              >
                {({ handleChange, isValid, dirty, errors, touched, handleBlur, isValidating
                }) => {
                  return (
                    <Form id="signupform">
                      <div className="d-flex justify-content-center">
                        <img src={logo} alt="" />
                      </div>
                      <br />

                      <div className="row ">
                        <div className=" col-md-4">
                          <div className="pt-2 inputbox">
                            <select
                              name="title"
                              // className="form-control"
                              className={(errors.title && touched.title && errors.title) ? "form-control_inpt col-8" : "form-control col-8"}

                              onChange={handleChange}
                              style={{
                                borderRadius: "5px",

                              }}
                            >
                              <option value=""> Title </option>
                              <option value="Mr.">Mr</option>
                              <option value="Mrs.">Mrs</option>
                            </select>
                          </div>

                          {/* <p className="error">
                            {errors.title && touched.title && errors.title}
                          </p> */}



                        </div>

                        <div className=" col-md-8">
                          <div className="pt-2 inputbox">

                            {/* 
                            <Field
                              name="uname"
                              component={AlphabetInput}
                              placeholder="Enter name"
                            /> */}

                            {/* form-control col-8 */}
                            <input
                              // className="form-control col-8"
                              className={(errors.uname && touched.uname && errors.uname) ? "form-control_inpt col-8" : "form-control col-8"}
                              type="text"
                              name="uname"
                              maxLength={25}
                              minLength={2}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              style={{
                                borderRadius: "5px",
                              }}
                              placeholder="Name"
                            />

                          </div>

                          {/* <p className="error">
                            {errors.uname}
                          </p> */}


                          {/* <ErrorMessage name="uname" component="div" className="error-message" /> */}

                        </div>
                      </div>

                      <div className="pt-2 col-md-12">
                        <div className="pt-2 inputbox">
                          <Input
                            type="email"
                            name="email"
                            // className="form-control col-8"
                            className={(errors.email && touched.email && errors.email) ? "form-control_inpt col-8" : "form-control col-8"}

                            style={{
                              borderRadius: "5px",
                            }}
                            placeholder="Email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />


                          {/* <p className="error">
                            {errors.email && touched.email && errors.email}
                          </p> */}
                        </div>
                      </div>

                      <div
                        className="pt-2 col-lg-12 "
                        style={{ fontSize: "15px" }}
                      >
                        <div className="pt-2">
                          <div className=" col-12 d-flex flex-row gap-1 ">
                            <Input
                              name="phone"
                              type="tel"
                              // className="form-control col-8"
                              className={(errors.phone && touched.phone && errors.phone) ? "form-control_inpt col-8" : "form-control col-8"}

                              style={{
                                borderRadius: "5px",
                              }}
                              placeholder="Phone Number"
                              onChange={handleChange}
                              maxLength={10}
                              onBlur={handleBlur}
                            />
                          </div>
                          <div class="d-flex ">
                            &nbsp;&nbsp;
                            {/* <ErrorMessage
                              name="phone"
                              component="div"
                              className="text-danger"
                            /> */}

                            {/* <p className="error">
                              {errors.phone && touched.phone && errors.phone}
                            </p> */}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-4 mb-3">
                          <Input
                            name="comp_name"
                            // className="form-control col-8"
                            className={(errors.comp_name && touched.comp_name && errors.comp_name) ? "form-control_inpt col-8" : "form-control col-8"}

                            style={{
                              borderRadius: "5px",
                            }}
                            onBlur={handleBlur}
                            placeholder="Company Name"
                            onChange={handleChange}
                          />
                          {/* <ErrorMessage
                            name="comp_name"
                            component="div"
                            className="text-danger"
                          /> */}

                          {/* <p className="error">
                            {errors.comp_name && touched.comp_name && errors.comp_name}
                          </p> */}
                        </div>

                        <div className="col-md-4 mb-3">
                          <Input
                            name="department"
                            // className="form-control col-8"
                            className={(errors.department && touched.department && errors.department) ? "form-control_inpt col-8" : "form-control col-8"}

                            placeholder="Department"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{
                              borderRadius: "5px",
                            }}
                          />
                          {/* <ErrorMessage
                            name="department"
                            component="div"
                            className="text-danger"
                          /> */}

                          {/* <p className="error">
                            {errors.department && touched.department && errors.department}
                          </p> */}
                        </div>

                        <div className=" col-md-4">
                          <Input
                            name="designation"
                            // className="form-control col-8"
                            className={(errors.designation && touched.designation && errors.designation) ? "form-control_inpt col-8" : "form-control col-8"}

                            placeholder="Designation"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{
                              borderRadius: "5px",
                            }}
                          />
                          {/* <ErrorMessage
                            name="designation"
                            component="div"
                            className="text-danger"
                          /> */}

                          {/* <p className="error">
                            {errors.designation && touched.designation && errors.designation}
                          </p> */}
                        </div>
                      </div>

                      <div className="pt-2 col-md-12">
                        <div className="pt-2 inputbox">
                          <Input
                            type="address"
                            name="address"
                            placeholder="Address"
                            // className="form-control inp_text"
                            className={(errors.address && touched.address && errors.address) ? "form-control_inpt inp_text" : "form-control inp_text"}

                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{
                              borderRadius: "5px",
                            }}
                            maxLength={200}
                          />
                          {/* <ErrorMessage
                            name="address"
                            component="div"
                            className="text-danger"
                          /> */}
                          {/* <p className="error">
                            {errors.address && touched.address && errors.address}
                          </p> */}
                        </div>
                      </div>

                      <div className="pt-2 col-md-12">
                        <div className="pt-2 inputbox">
                          {/* {errors.password ?
                            <Input.Password
                              placeholder="Password"
                              type="password"
                              onBlur={handleBlur}
                              name="password"
                              className={"wrapper_inpt"}

                              onChange={handleChange}
                              style={{
                                borderRadius: "5px",
                              }}
                            /> : <Input.Password
                              placeholder="Password"
                              type="password"
                              onBlur={handleBlur}
                              name="password"
                              // className={errors.password ? "ant-input-affix-wrapper_inpt " : "ant-input-affix-wrapper"}

                              onChange={handleChange}
                              style={{
                                borderRadius: "5px",
                              }}
                            />} */}
                          <Input.Password
                            placeholder="Password"
                            type="password"
                            onBlur={handleBlur}
                            name="password"
                            className={(errors.password && touched.password && errors.password) ? "ant-input-affix-wrapper_inpt " : "ant-input-affix-wrapper"}

                            onChange={handleChange}
                            style={{
                              borderRadius: "5px",
                            }}
                          />
                          {/* <ErrorMessage
                            name="password"
                            component="div"
                            className="text-danger"
                          /> */}
                          {/* <p className="error">
                            {errors.password && touched.password && errors.password}
                          </p> */}
                        </div>
                      </div>

                      <div className="pt-2 col-md-12 text-center">
                        <button class="btn btn-primary" type="submit" disabled={!isValid || !dirty}>
                          SIGNUP
                        </button>
                      </div>


                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>

          <Modal
            className="p-2"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            maskClosable={false}
          >
            <EmailVerification email={email} onCancel={handleCancel} />
          </Modal>
        </div>
      </div>
    </div>
    </>
  );
};

export default SignUp;
