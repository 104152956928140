import env from "../Services/Environment";
import axios from "axios";
import axiosInstance from "../Services/axiosInstance"

const userLocal = localStorage.getItem("UserName")
const token = localStorage.getItem("token")
console.log("userLocal",userLocal,"token",token)



export const RequestAPI = async (newuser) => {
  return await axiosInstance.post(`${env.Backend_Url}/addApiRequest`, newuser);
};

export const ResetPassword = async (data) => {
  console.log(data);
  return await axios.post(`${env.Backend_Url}/updatePassword`, data);
};

//Signup
export const AddUser = async (newuser) => {
  return await axios.post(`${env.Backend_Url}/addUser`, newuser);
};

export const addUserByAdmin = async (newuser) => {
  return await axios.post(`${env.Backend_Url}/addUserAdmin`, newuser);
};

export const isVerified = async (data) => {
  return await axios.post(`${env.Backend_Url}/verifyemailotp`, data);
};

export const isTokenVerified = async (token) => {
  return await axios.get(`${env.Backend_Url}/isTokenVerified/${token}`);
};

export const LoginUser = async (data) => {
  return await axios.post(`${env.Backend_Url}/login`, data);
};

export const UpdatePrfrence = async (data) => {
  return await axiosInstance.put(`${env.Backend_Url}/updatePreference`, data);
};

export const getAllEmployee = async (email) => {
  return await axiosInstance.get(`${env.Backend_Url}/employeelist/${email}`);
};

export const getDeActiveAPI = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/active-api`);
};

export const ForgotPasswordService = async (data) => {
  console.log("ForgotPasswordService", data);
  return await axios.post(`${env.Backend_Url}/forgot-password`, data);
};

export const ResetPasswordService = async (data) => {
  console.log("ResetPasswordService", data);
  return await axios.put(`${env.Backend_Url}/reset-password`, data);
};

export const VerifyOTPPassword = async (data) => {
  console.log("VerifyOTPPassword", data);
  return await axios.post(`${env.Backend_Url}/verify-otp-password`, data);
};

export const updatePassword = async (data) => {
  console.log(data);
  return await axios.post(`${env.Backend_Url}/updatePassword`, data);
};

export const getMFADetails = async (email) => {
  return await axiosInstance.get(`${env.Backend_Url}/getMFA/${email}`);
};

export const updateMFADetails = async (data) => {
  return await axiosInstance.post(`${env.Backend_Url}/updateMFA`, data);
};

export const generateOTP = async (data) => {
  return await axiosInstance.post(`${env.Backend_Url}/generateOTP`, data);
};

export const verifyTOTP = async (data) => {
  return await axiosInstance.post(`${env.Backend_Url}/verifyTOTP`, data);
};

export const updateUserPhone = async (data) => {
  return await axiosInstance.post(`${env.Backend_Url}/updateUserPhone`, data);
};

export const generateOTPforMFASignin = async (data) => {
  return await axios.post(`${env.Backend_Url}/generateOTPforMFASignin`, data);
};

export const verifyTOTPforMFASignin = async (data) => {
  return await axios.post(`${env.Backend_Url}/verifyTOTPforMFASignin`, data);
};

export const addUserFeedback = async (data) => {
  return await axiosInstance.post(`${env.Backend_Url}/add-user-feedback`, data);
};


