import React, { useRef } from 'react'
import env from '../../../../Services/Environment'
import { BiSolidCopy } from "react-icons/bi";
import toast, { Toaster } from "react-hot-toast";

function ApiDetails({ isApiDetails, details }) {
    console.log("isApiDetails", isApiDetails)
    console.log("details", details)
    const apiParam = JSON.parse(details.api_param)
    console.log("apiParam", apiParam)

    const textRef = useRef(null);

    const copyText = () => {
        if (textRef.current) {
            textRef.current.select();
            document.execCommand('copy');
            toast.success("Copied")
        }
    };

    const flattenParams = (obj) => {
        const result = {};

        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (Array.isArray(obj[key])) {
                    obj[key].forEach((item, index) => {
                        for (const subKey in item) {
                            if (item.hasOwnProperty(subKey)) {
                                result[`${key}[${index}][${subKey}]`] = item[subKey];
                            }
                        }
                    });
                } else {
                    result[key] = obj[key];
                }
            }
        }

        return result;
    };

    const flatStructure = flattenParams(apiParam);
    const queryString = new URLSearchParams(flatStructure).toString();
    const apiUrl = `${env.Backend_Url}/RunAPI`
    const url = `${apiUrl}?${queryString}`;
    console.log("url", url)

    return (
        <>
            <Toaster position="top-center" reverseOrder={false} />
            {isApiDetails &&
                <div style={{ height: "28rem", overflowY: "auto", overflowX: "hidden" }}>
                    <p><strong>App Name:</strong> {apiParam.app_name} </p>
                    <p><strong>Email:</strong> {apiParam.email} </p>
                    <p><strong>Api URL:</strong> {details.api_url} </p>
                    <p><strong>Purpose:</strong> {details.purpose_data_consum}</p>
                    <p><strong>Sign token:</strong> {apiParam.sign_token} </p>
                    <p><strong>Secure rule identifier:</strong> {apiParam.secure_rule_identifier} </p>
                    <p><strong>URL for GET method</strong> </p>
                    <div className='row'>
                        <div className='col-md-9'>
                            <input
                                style={{
                                    border: "1px solid grey",
                                    borderRadius: "5px",
                                    width: "100%",
                                    height: "32px"
                                }}
                                type="text" value={url} ref={textRef} readOnly />
                        </div>
                        <div className='col-md-2'>
                            <button
                                style={{
                                    border: "none",
                                    background: "transparent",
                                    padding: "5px"
                                }}
                                onClick={copyText}>
                                <BiSolidCopy size={23} />
                            </button>
                        </div>
                    </div>

                </div>
            }
        </>
    )
}

export default ApiDetails