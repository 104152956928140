import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import APIStatusAction from "./APIAction";
import { Modal } from "antd";
import axios from "axios";
import env from "../../../Services/Environment";
import { darken, lighten, styled } from '@mui/material/styles';
import { getAllRequestedApi } from "../../../Services/AdminServices";
import approvere from "../../../Assets/Images/approverejeact.png"
import Loader from "../../../utils/Loader/Loader";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
import AgGridComponent from "../../Reusable_Components/AgGridComponent";
import { useSelector } from "react-redux";
import { LuView } from "react-icons/lu";
import ApiResponse from "../CustomerAdmin/API/ApiResponse";
import ApiDetails from "../CustomerAdmin/API/ApiDetails";
import TabsAntd from "../../Reusable_Components/TabsAntd";
import BasicButton from "../../Reusable_Components/Button";
import AddApiRequest from "../../Pages/CustomerAdmin/API/APIRequest";


// Dtata background change
const getBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .super-app-theme--api_active_03': {
    backgroundColor: getBackgroundColor("#F7F5BC", theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        "#F7F5BC",
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        "#F7F5BC",
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          "#F7F5BC",
          theme.palette.mode,
        ),
      },
    },
  },

  '& .super-app-theme--api_active_01': {
    backgroundColor: getBackgroundColor(
      theme.palette.success.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--api_active_02': {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
    },
  },
}));

// Databackground close

const AllApiRequestlist = ({ apiList }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState()
  const [apiSingleReqData, setApiSingleReqData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [displayAPI, setDisplayAPI] = useState(false);
  const [loading, setLoading] = useState(false)

  // uam role object start
  const userDetails = useSelector((state) => state)
  const appsubmodulename = "Services"
  const appfunctionalityname = "APIs"
  const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
  const [appObjects, setAppObjects] = useState(
    {
      "Action": false,
    }
  )
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isApiJson, setIsApiJson] = useState(false)
  const [isApiDetails, setIsApiDetails] = useState(false)
  const [apiParam, setApiParam] = useState([])

  const [isReqApi, setIsReqApi] = useState(false)

  console.log("userDetails", userDetails)

  const updateAppObjects = (arr) => {
    let objectsArray = []
    arr.map((obj) => {
      console.log("updateButtons", obj)
      // if (obj.objecttypename == "button") {
      objectsArray.push(obj)
      // }
    })
    const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
      acc[appobjectname] = true;
      return acc;
    }, {});
    setAppObjects({ ...appObjects, ...finalObject })
    console.log("objectsArray", objectsArray)
    console.log("finalObject", finalObject)
  }

  useEffect(() => {
    console.log("appObjects", appObjects)
  }, [appObjects])

  useEffect(() => {
    console.log("userRoleObjects", userRoleObjects)
    const filteredList = []
    userRoleObjects.filter((obj) => {
      if (obj.appsubmodulename == appsubmodulename && obj.appfunctionalityname == appfunctionalityname) {
        filteredList.push(obj)
      }
    })

    console.log("filteredList", filteredList)
    updateAppObjects(filteredList)

  }, [])

  // uam role object end

  const showModal = (p) => {
    console.log('requestedapirowdata', p.data);

    setApiSingleReqData(p.data);

    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    fetchData()
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (apiList == true) {
      fetchData();
    }
  }, [apiList]);

  const fetchData = async () => {

    setLoading(true)
    try {
      // const response = await axios.get(`${env.Backend_Url}/getAllApiUser`); // Replace with your API endpoint
      let response = await getAllRequestedApi()
      let responseData = response.data.data.rows
      let sortData = responseData.sort((a, b) => b._cust_req_id - a._cust_req_id);
      console.log("sortData_", sortData)
      setData(sortData);
      console.log("response hai", response);

      let DATAFORMAT = response.data.data.rows;
      let ApiName = response.data.data.rows;
      DATAFORMAT.map((item) => {
        if (item._api_format_id === 3) {
          item._api_format_id = "PDF";
        } else if (item._api_format_id === 2) {
          item._api_format_id = "CSV";
        } else if (item._api_format_id === 1) {
          item._api_format_id = "JSON";
        } else if (item._api_format_id === 4) {
          item._api_format_id = "TEXT";
        } else {
          item._api_format_id = "XML";
        }
        return item;
      });
      DATAFORMAT.map((item) => { });
      setData([...DATAFORMAT]);

      ApiName.map((item) => {
        if (item.api_id === 1) {
          item.api_id = "ALL USERS";
        }
        // else {
        //   item._api_format_id = "XML";
        // }
        return item;
      });
      ApiName.map((item) => { });
      setData([...ApiName]);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error)
    }
    setLoading(false)
  };

  const columns = [
    {
      field: "_cust_req_id",
      headerName: "ID",
      headerClassName: "super-app-theme--header",
      // width: 50,
      flex: 0.3,
      isAccess: true,
    },

    {
      field: "_requestor_name",
      headerName: "Name",
      headerClassName: "super-app-theme--header",
      // width: 150,
      flex: 0.5,
      isAccess: true,
    },
    {
      field: "_app_name",
      headerName: "Application",
      headerClassName: "super-app-theme--header",
      // width: 130,
      flex: 0.5,
      isAccess: true,
    },
    {
      field: "_email",
      headerName: "Email",
      // width: 250,
      flex: 0.5,
      isAccess: true,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "_purpose_data_consum",
      headerName: "Purpose",
      // width: 250,
      flex: 0.5,
      isAccess: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "_api_name",
      headerName: "Api Name",
      // width: 150,
      flex: 0.4,
      isAccess: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "rule_name",
      headerName: "Rule Name",
      // width: 150,
      flex: 0.5,
      isAccess: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "_api_status_name",
      headerName: "Status",
      // width: 80,
      flex: 0.5,
      isAccess: true,
      headerClassName: "super-app-theme--header",
      cellRenderer: (params) => {
        console.log("Coming ...", params);
        let val = params.value;

        if (val === "Active") {
          return (
            <div className="btnactive" role="button">
              Active
            </div>
          );
        } else if (val === "Deactive") {
          return (
            <div className="btnDeactive" role="button">
              InActive
            </div>
          );
        } else if (val === "In Progress") {
          return (
            <div className="btnenable" role="button">
              In Progress
            </div>
          );
        } else {
          return (
            <div className="btnpending" role="button">
              Pending
            </div>
          );
        }
      },
    },
    {
      field: "_request_datetime",
      headerName: "Date",
      // width: 100,
      flex: 0.4,
      isAccess: true,
      headerClassName: "super-app-theme--header",
      valueFormatter: (params) => convertDateFormat(params.value)
    },
    {
      field: "_Approve/Reject",
      headerClassName: "super-app-theme--header",
      flex: 0.3,
      isAccess: appObjects["Action"],
      filter: false,
      headerName: "Action",
      cellRenderer: (params) => {

        return (
          <div className="cellAction">
            {appObjects["Action"] && <img src={approvere} alt="icon"
              style={{ width: '1.2rem' }}
              onClick={() => showModal(params)}
            />}
          </div>
        );
      },
    },
    {
      field: "View",
      headerName: "View",
      // width: 100,
      isAccess:true,
      filter: false,
      flex: 0.3,
      cellRenderer: (params) => {
        console.log("params_", params)
        return (
          <div className="cellAction">
            <button
              className="btn btn-light"
              style={{ background: "transparent", borderStyle: "none" }}
              onClick={() => showModal1(params)}
              disabled={params.data._api_status_name == "Pending" || params.data._api_status_name == "Deactive" ? true : false}
            >
              <LuView />
            </button>
          </div>
        );
      },
    }
  ];

  const [newColumn, setNewColumn] = useState([])
  useEffect(() => {
    let { role } = userDetails.auth
    let finalArr = []
    // if (role == "super user") {
    columns.filter((obj) => {
      // if (obj?.isSuperUser == true) {
      //   finalArr.push(obj)
      // }
      if (obj?.isAccess == true) {
        finalArr.push(obj)
      }
    })
    console.log("sidebarfinalArr", finalArr)
    setNewColumn(finalArr)
    // }
    // else if (role == "super-admin") {
    //   setMenuItem2(menuItem)
    // }
  }, [appObjects])



  const handleOptionChange = (e) => {
    if (e.target.value == `API_Data`) {
      setDisplayAPI(!displayAPI);
    }
  };


  function convertDateFormat(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const rowClassRules = {
    'even-row': (params) => params.node.rowIndex % 2 !== 0,
  };

  const handleOk1 = () => {
    setIsModalOpen1(false);
    setApiParam([])
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
    setApiParam([])
  };

  const showModal1 = (p) => {
    console.log("Data_", p.data)
    // setIsModalOpen1(true);
    setApiParam(p.data)

    // setViewApiFormat(apiurl);
  };

  useEffect(() => {
    console.log("apiParam", apiParam)
    if (Object.keys(apiParam).length > 0) {
      setIsModalOpen1(true)
      setIsApiJson(true)
    }
  }, [apiParam])

  const tabItems = [
    {
      key: "1",
      label: `JSON`,
      children: isApiJson ? <ApiResponse isApiJson={isApiJson} data={apiParam} /> : null,
    },
    {
      key: "2",
      label: `Details`,
      children: <ApiDetails isApiDetails={isApiDetails} details={apiParam} />,
    },
  ]

  const onChangeTabs = (key) => {
    console.log("onChangeTabs", key);
    if (key == "1") {
      setIsApiJson(true)
      setIsApiDetails(false)
    }
    else if (key == "2") {
      setIsApiJson(false)
      setIsApiDetails(true)
    }
  }

  const handleAddApi = () => {
    setIsReqApi(true);
  };

  const handleOkAPi = () => {
    setIsReqApi(false)
    fetchData()
  }

  const handleCancelApi = () => {
    setIsReqApi(false);
  };

  return (
    <>
      <Loader loading={loading} />
      <ErrorCatch err={error} />
      {/* <div className="row mt-2 justify-content-end px-2">
        <div className="card bg-transparent d-flex col-md-2 " style={{ border: "none" }}>
          {appObjects["Request"] && <BasicButton
            text="Request"
            onClick={handleAddApi}
          />}
        </div>
      </div> */}
      <div className="container-fluid">
        <div
          className="col-md-12 mx-3"
          style={{ overflowY: "auto" }}
        >
          {/* <Box sx={{ height: "65vh", width: "100%", overflowY: "auto" }}>
            <StyledDataGrid
              sx={{

                borderColor: "secondary",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#0E3386",
                  fontSize: "12px",
                  color: "white",
                  padding: "10px",

                  // Set your desired header background color
                },
                "& .MuiDataGrid-cell": {
                  fontSize: "10px",
                  padding: "10px",
                  borderRight: "2px solid #e0e0e0",
                  // Adjust the font size as needed
                  // Adjust the cell padding as needed
                },
              }}
              rowHeight={30}
              columns={columns}
              rows={data}          
              hideFooter
              getRowClassName={(params) => `super-app-theme--${params.row._api_status_id }`}
              getRowId={(data) => data._cust_req_id}

            />
          </Box> */}
          <AgGridComponent rowData={data} columnDef={newColumn} rowClassRules={rowClassRules} />

        </div>
      </div>
      {/* <AddApiRequest
        isModalOpen={isReqApi}
        handleOk={handleOkAPi}
        handleCancel={handleCancelApi}
        isAdmin={true}
      /> */}
      <Modal
        style={{ top: 20, minWidth: 300, maxWidth: 600 }}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
      >
        <APIStatusAction isModalOpen={isModalOpen} data={apiSingleReqData} handleOk={handleOk} />
      </Modal>
      {/* View API Format  */}
      <Modal
        title="API Details"
        open={isModalOpen1}
        onOk={handleOk1}
        onCancel={handleCancel1}
        destroyOnClose
        footer={null}
        style={{ top: 20, minWidth: 500, maxWidth: 800 }}
      >
        <hr />
        <div>
          <TabsAntd
            defaultActiveKey="1"
            height="30rem"
            items={tabItems}
            onChange={(key) => onChangeTabs(key)}
            centered
          />
        </div>
        {/* <ApiResponse data={apiParam} /> */}
      </Modal>
      {console.log("_cust_req_id", data._cust_req_id)}

    </>
  );
};

export default AllApiRequestlist;
