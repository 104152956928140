import React, { useState, useEffect } from "react";
import env from '../../../Services/Environment';
import { UpdatePrfrence, generateOTP, getMFADetails, updateMFADetails, updateUserPhone, verifyTOTP } from "../../../Services/UserServices";
import toast, { Toaster } from "react-hot-toast";
import mail from '../../../Assets/Images/gmail.png';
import SMS from '../../../Assets/Images/sms.png';
import POPup from '../../../Assets/Images/active.png'
import axios from "axios";
import { Checkbox } from "antd";
import { Modal } from "antd";
import "../../../Assets/Styles/messagesettings.css";
import { messages } from "../../Messages";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
import { getSingleuser } from "../../../Services/AdminServices";
import CheckboxComp from "../../Reusable_Components/CheckBox";
import InputBox from "../../Reusable_Components/InputBox";
// import CountdownTimer from "../../Reusable_Components/CountdownTimer";
import Switch from '@mui/material/Switch';
import Loader from "../../../utils/Loader/Loader";
import SwitchComp from "../../Reusable_Components/SwitchComp";


//Prefrence
const MessagePrefrence = ({ isMessagePreference }) => {
  const email = localStorage.getItem("Logindata");
  const [preferences, setPreferences] = useState();
  const [error, setError] = useState()
  const [userDetails, setUserDetails] = useState()
  const [mfaDetails, setMfaDetails] = useState()
  const [isMFA, setIsMFA] = useState(null)
  const [isEmailMFA, setIsEmailMFA] = useState(true)
  const [isSmsMFA, setIsSmsMFA] = useState(null)
  const [isPhoneVerifyModal, setIsPhoneVerifyModal] = useState(false)
  const [isPhoneConfirmModal, setIsPhoneConfirmModal] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [otp, setOtp] = useState("")
  const [generatedOTP, setgeneratedOTP] = useState("")
  const [generatedSecret, setGeneratedSecret] = useState("")
  const [isConfirmNewPhoneNumber, setIsConfirmNewPhoneNumber] = useState(false);
  const details = { email, phoneNumber }
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (step > 0) {
      setTimeLeft(step);
    }
  }, [step]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          //   return step;
          clearInterval(interval);
          return 0
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [step]);

  const getSingleDataByEmail = async () => {
    try {
      const response = await getSingleuser(email)
      console.log("getSingleDataByEmail", response.data.data[0])
      setUserDetails(response.data.data[0])
      setPreferences(response.data.data[0]._pref_id)
    }
    catch (err) {
      setError(err)
    }
  }

  // useEffect(() => {
  //   if (isMessagePreference) {
  //     getSingleDataByEmail();
  //     fetchMFAdetails()
  //   }

  // }, [isMessagePreference]);

  useEffect(() => {
    getSingleDataByEmail();
    fetchMFAdetails()

  }, []);

  const fetchMFAdetails = async () => {
    setLoading(true)
    try {
      let response = await getMFADetails(email)
      console.log("fetchMFAdetailsresponse", response)
      let { ismfa, is_sms } = response.data.data
      setMfaDetails(response.data.data)
      setIsMFA(ismfa)
      setIsSmsMFA(is_sms)
    }
    catch (err) {
      console.log("fetchMFAdetailsErr", err)
    }
    setLoading(false)
  }

  const updateMFA = async (isMFA, isSmsMFA, email) => {
    setLoading(true)
    let data = {
      isMFA, isSmsMFA, email
    }
    try {
      let response = await updateMFADetails(data)
      console.log("updateMFAresponse", response)
      toast.success(response.data.message)
      fetchMFAdetails()
    }
    catch (err) {
      console.log("updateMFAErr", err)
    }
    setLoading(false)
  }

  const updatePhoneNumber = async () => {
    setLoading(true)
    let data = {
      phoneNumber, email
    }
    try {
      let response = await updateUserPhone(data)
      console.log("updatePhoneNumber", response)
      sendOTP(email, phoneNumber)
    }
    catch (err) {
      console.log("updatePhoneNumberErr", err)
    }
    setLoading(false)
  }

  const sendOTP = async (email, phoneNumber) => {
    setLoading(true)
    let data = {
      email, phoneNumber
    }
    try {
      let response = await generateOTP(data)
      console.log("sendOTP", response)
      setgeneratedOTP(response.data.totp)
      setGeneratedSecret(response.data.secret)
      setStep(response.data.step)
      setTimeLeft(response.data.step)
      // setPhoneNumber(mfaDetails.phone)
      setPhoneNumber(phoneNumber)
      setIsPhoneVerifyModal(true)
      setOtp("")
    }
    catch (err) {
      console.log("sendOTPErr", err)
      // toast.error(err?.response?.data?.message || err.message)
      setError(err)
      setIsSmsMFA(null)
    }
    setLoading(false)
  }

  // const onChange = async (e: CheckboxChangeEvent) => {
  //   console.log(`e.target.name = ${e.target.value}`);
  //   setPreferences(e.target.value);
  //   await UpdatePrfrence({
  //     email: localStorage.getItem("Logindata"),
  //     pref_id: e.target.value,
  //   })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         toast.success(messages.updatedSuccess);
  //       } else {
  //         toast.error("Something went wrong, Check all fields and try again");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       toast.error(error.message, {
  //         position: "top-right",
  //         autoClose: 5000,
  //       });
  //     });
  // };

  const handleIsMFA = (e) => {
    let value = e.target.checked
    console.log("handleIsMFA", e, value)
    setIsMFA(value)
    updateMFA(value, isSmsMFA, email)
  }

  const handleEmailMFA = (e) => {
    setIsEmailMFA(e.target.checked)
  }

  const handleSmsMFA = (e) => {
    console.log("handleSmsMFA", e)
    console.log("handleSmsMFAmfaDetails", mfaDetails)
    let value = e.target.checked
    setIsSmsMFA(value)
    if (mfaDetails.is_phone_verified == true) {
      updateMFA(isMFA, value, email)
      return
    }
    if (value == true) {
      if (mfaDetails.phone !== "") {
        if (mfaDetails.is_phone_verified == null || mfaDetails.is_phone_verified == false) {
          sendOTP(email, mfaDetails.phone)
        }
      }
      else if (mfaDetails.phone == "") {
        setIsConfirmNewPhoneNumber(true)
        setIsPhoneVerifyModal(true)
        setPhoneNumber("")
        setOtp("")
      }
    }
  }

  const handlePhoneNumber = (e) => {
    let value = e.target.value.replace(/[^0-9+]/g, '')
    setPhoneNumber(value)
  }

  const handleOTP = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, '')
    setOtp(value)
  }

  const closeOTPverificationModal = () => {
    setIsPhoneVerifyModal(false)
    setIsConfirmNewPhoneNumber(false)
    setStep(0)
    setTimeLeft(0)
    if (mfaDetails) {
      if (mfaDetails.is_phone_verified == null || mfaDetails.is_phone_verified == false) {
        setIsSmsMFA(false)
      }
    }
  }

  const verifyUserOTP = async () => {
    if (otp == "") {
      toast.error("Please enter OTP")
      return
    }
    setLoading(true)
    let data = {
      token: otp,
      secret: generatedSecret,
      email: email,
      phoneNumber: phoneNumber
    }
    try {
      let response = await verifyTOTP(data)
      console.log("verifyUserOTPresponse", response)
      updateMFA(isMFA, isSmsMFA, email)
      setIsPhoneVerifyModal(false)
    }
    catch (err) {
      console.log("verifyUserOTPErr", err)
      setStep(0)
      setTimeLeft(0)
      if ("response" in err) {
        toast.error(err.response.data.message)
      }
      else {
        toast.error(err.message)
      }
    }
    setLoading(false)
  }

  return (
    <>
      <Loader loading={loading} />
      <Toaster position="top-center" reverseOrder={false} />
      <ErrorCatch err={error} />
      {/* <br />
      <h6>Select Your Notifications preferences</h6>

      <div
        className=" pt-3">
        <div className="row  gap-3">
          <div className="col-3 Optioncard d-flex justify-content-center" >
            <div className="row d-flex justify-content-center flex-parent-element">
              <div className="flex-child-element checkbox">
                <Checkbox
                  onChange={onChange}
                  name="email"
                  value="p1"
                  checked={preferences === "p1" ? true : false}
                ></Checkbox>
              </div>
              <div className="flex-child-element imgntext">
                <img src={mail} alt="" style={{
                  width: "2rem",
                  marginTop: "unset",
                }} />
                <h6 className="mt-1" style={{
                  display: "inline",
                  marginLeft: "5px"
                }}
                > Email</h6>
              </div>
            </div>
          </div>


          <div className="col-3 Optioncard d-flex justify-content-center">
            <div className="row mt-2 d-flex justify-content-center flex-parent-element">
              <div className="flex-child-element checkbox">
                <Checkbox
                  onChange={onChange}
                  name="sms"
                  value="p2"
                  checked={preferences === "p2" ? true : false}
                ></Checkbox>
              </div>
              <div className="flex-child-element imgntext">
                <img src={SMS} alt="" style={{
                  width: "2rem",
                  marginTop: "unset",
                }} />
                <h6 className="mt-1" style={{
                  display: "inline",
                  marginLeft: "5px"
                }}
                > SMS</h6>
              </div>
            </div>
          </div>
          <div className="col-4 Optioncard d-flex justify-content-center">
            <div className="row mt-2 pb-1 d-flex justify-content-center flex-parent-element">
              <div className="flex-child-element checkbox">
                <Checkbox
                  onChange={onChange}
                  name="notifications"
                  value="p3"
                  checked={preferences === "p3" ? true : false}
                ></Checkbox>
              </div>
              <div className="flex-child-element imgntext">
                <img src={POPup} alt="" style={{
                  width: "2rem",
                  marginTop: "unset",
                }} />
                <h6 className="mt-1 text-center"
                  style={{
                    display: "inline",

                  }}
                >Notifications</h6>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <h6>Multifactor Authentication</h6> */}
      <div className="justify-content-center">
        <div className="row">
          <div className="col-md-7 p-2">
            <h6>Multifactor Authentication</h6>
          </div>
          <div className="col-md-3">
            {/* <Switch
              checked={isMFA}
              onChange={handleIsMFA}
            /> */}
            <SwitchComp
              checked={isMFA}
              onChange={handleIsMFA}
            />
          </div>
        </div>
        {isMFA &&
          <div>
            <div className="row">
              <CheckboxComp
                label="Email"
                isChecked={isEmailMFA}
                onChange={handleEmailMFA}
                disabled={true}
              />
            </div>
            <div className="row">
              <CheckboxComp
                label="SMS"
                isChecked={isSmsMFA}
                onChange={handleSmsMFA}
              />
            </div>
          </div>
        }
      </div>
      <Modal
        title="Verify Phone Number"
        style={{
          top: 20,
          // minWidth: 300, maxWidth: 600
        }}
        className="p-2 w-25"
        open={isPhoneVerifyModal}
        // onOk={isPhoneVerified}
        onCancel={closeOTPverificationModal}
        footer={null}
      >
        <div className="mt-2">
          <div className="row p-2">
            {/* <div className="col-md-7"> */}
            <InputBox
              label="*Phone Numbersss"
              name="phone_number"
              onChange={(e) => handlePhoneNumber(e)}
              value={phoneNumber}
              disabled={timeLeft == 0 ? false : true}
            />
            {/* </div> */}

            {/* {isConfirmNewPhoneNumber &&
              <div className="col-md-5">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={sendOTP}
                // disabled={timeLeft == 0 ? true : false}
                >
                  Generate OTP
                </button>
              </div>} */}

          </div>
          <div className="row p-2">
            {/* <div className="col-md-7"> */}
            <InputBox
              label="OTP"
              name="otp"
              onChange={(e) => handleOTP(e)}
              value={otp}
            />
            {/* </div> */}
          </div>
          {/* {step &&  */}
          <>
            <div className="row p-2">
              <div className="col-md-3">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={isConfirmNewPhoneNumber ? updatePhoneNumber : verifyUserOTP}
                // disabled={timeLeft == 0 ? true : false}
                >
                  Verify OTP
                </button>
              </div>
              {timeLeft == 0 ?
                <div className="col-md-6">
                  <button
                    className='btn btn-secondary btn-sm'
                    onClick={() => sendOTP(email, phoneNumber)}
                  >
                    Generate OTP
                  </button>
                </div>
                : <></>}
            </div>
            <p className="p-2">Time left for current OTP: {timeLeft} seconds</p>
            {/* <CountdownTimer step={step} sendOTP={sendOTP} data={details} /> */}
          </>
          {/* } */}
        </div>
      </Modal>
    </>
  );
};

export default MessagePrefrence