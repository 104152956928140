export const userColumn = [
    {
        headerClassName: "super-app-theme--header",
        field: "_user_id",
        headerName: "Id",
        // width: 100,
        flex: 0.2,
      },
      {
        field: "_name",
        headerClassName: "super-app-theme--header",
        headerName: "Name",
        // width: 120,
        flex: 0.5,
      },
  
      {
        field: "_email",
        headerClassName: "super-app-theme--header",
        headerName: "Email",
        // width: 260,
        flex: 0.5,
      },
  
      {
        field: "_designation",
        headerClassName: "super-app-theme--header",
        headerName: "Designation",
        // width: 130,
        flex: 0.4,
      },
  
      {
        field: "_user_type",
        headerClassName: "super-app-theme--header",
        headerName: "Role",
        flex: 0.3,
      },
  
      {
        field: "_address",
        headerClassName: "super-app-theme--header",
        headerName: "Address",
        // width: 250,
        flex: 0.5,
      },
]