export const selectionData = [
    {
        "castid": "T8506351",
        "bundleno": "1234568",
        "netweight": "1200.000",
        "product": "BILLET",
        "soitem": "000010",
        "deliverydate": "2023-07-31",
        "alloy": "6060.70",
        "si": "0.46",
        "fe": "0.20",
        "cu": "0.0",
        "mn": "0.04",
        "mg": "0.37",
        "cr": "0.0",
        "zn": "0.01",
        "ti": "0.016",
        "othe": "0.00",
        "tothersvalue": "0.04",
        "alvalue": "98.86",
        "mes_sono": "0004704576",
        "conversion_data": "{\"id\":\"T8506351\",\"cast_id\":\"T8506351\",\"document_type\":\"COA\",\"COA\":[{\"key\":\"castid\",\"value\":\"T8506351\"},{\"key\":\"bundleno\",\"value\":\"1234568\"},{\"key\":\"netweight\",\"value\":\"1200.000\"},{\"key\":\"product\",\"value\":\"BILLET\"},{\"key\":\"soitem\",\"value\":\"000010\"},{\"key\":\"deliverydate\",\"value\":\"2023-07-31\"},{\"key\":\"alloy\",\"value\":\"6060.70\"},{\"key\":\"si\",\"value\":\"0.46\"},{\"key\":\"fe\",\"value\":\"0.20\"},{\"key\":\"cu\",\"value\":\"0.0\"},{\"key\":\"mn\",\"value\":\"0.04\"},{\"key\":\"mg\",\"value\":\"0.37\"},{\"key\":\"cr\",\"value\":\"0.0\"},{\"key\":\"zn\",\"value\":\"0.01\"},{\"key\":\"ti\",\"value\":\"0.016\"},{\"key\":\"othe\",\"value\":\"0.00\"},{\"key\":\"tothersvalue\",\"value\":\"0.04\"},{\"key\":\"alvalue\",\"value\":\"98.86\"},{\"key\":\"mes_sono\",\"value\":\"0004704576\"},{\"key\":\"sifeAvg\",\"value\":0.33}],\"newKeyId\":\"T8506351COA\"}"
    },
    {
        "castid": "784942",
        "bundleno": "11030739",
        "netweight": "1706.000",
        "product": "Ringot",
        "soitem": "000010",
        "deliverydate": "2023-07-31",
        "alloy": "1999.00",
        "si": "0.0900",
        "fe": "0.1400",
        "cu": "0.0",
        "mn": "0.0200",
        "mg": "0.1000",
        "cr": "0.1000",
        "zn": "0.0",
        "ti": "0.0070",
        "othe": "0.0",
        "tothersvalue": "0.0400",
        "alvalue": "0.9963",
        "mes_sono": "0004030592",
        "conversion_data": "{\"id\":\"T8506351\",\"cast_id\":\"T8506351\",\"document_type\":\"COA\",\"COA\":[{\"key\":\"castid\",\"value\":\"T8506351\"},{\"key\":\"bundleno\",\"value\":\"1234568\"},{\"key\":\"netweight\",\"value\":\"1200.000\"},{\"key\":\"product\",\"value\":\"BILLET\"},{\"key\":\"soitem\",\"value\":\"10\"},{\"key\":\"deliverydate\",\"value\":\"2023-07-31\"},{\"key\":\"alloy\",\"value\":\"6060.70\"},{\"key\":\"si\",\"value\":\"0.46\"},{\"key\":\"fe\",\"value\":\"0.20\"},{\"key\":\"cu\",\"value\":\"0.0\"},{\"key\":\"Manganese\",\"value\":\"0.04\"},{\"key\":\"mg\",\"value\":\"0.37\"},{\"key\":\"cr\",\"value\":\"0.0\"},{\"key\":\"zn\",\"value\":\"0.01\"},{\"key\":\"ti\",\"value\":\"0.016\"},{\"key\":\"othe\",\"value\":\"0.00\"},{\"key\":\"tothersvalue\",\"value\":\"0.04\"},{\"key\":\"alvalue\",\"value\":\"98.86\"},{\"key\":\"mes_sono\",\"value\":\"0004704576\"}],\"SO no\":\"234567\"}"
    }
]

export const selectionData2 = [
    {
        "id": "T8506351",
        "cast_id": "T8506351",
        "COE": 
            [
                {
                    "key": "castid",
                    "value": "T8506351"
                },
                {
                    "key": "castid",
                    "value": "T8506351"
                },
                
            ] ,
        
        "COA": 
        [
            {
                "key": "castid",
                "value": "T8506351"
            },
            {
                "key": "bundleno",
                "value": "1234568"
            },
            {
                "key": "netweight",
                "value": "1200.000"
            },
            {
                "key": "product",
                "value": "BILLET"
            },
            {
                "key": "soitem",
                "value": "000010"
            },
            {
                "key": "deliverydate",
                "value": "2023-07-31"
            },
            {
                "key": "alloy",
                "value": "6060.70"
            },
            {
                "key": "si",
                "value": "0.46"
            },
            {
                "key": "The Iron",
                "value": "0.20"
            },
            {
                "key": "cu",
                "value": "0.0"
            },
            {
                "key": "mn",
                "value": "0.04"
            },
            {
                "key": "mg",
                "value": "0.37"
            },
            {
                "key": "cr",
                "value": "0.0"
            },
            {
                "key": "zn",
                "value": "0.01"
            },
            {
                "key": "Titanium",
                "value": "0.016"
            },
            {
                "key": "othe",
                "value": "0.00"
            },
            {
                "key": "tothersvalue",
                "value": "0.04"
            },
            {
                "key": "alvalue",
                "value": "98.86"
            },
            {
                "key": "mes_sono",
                "value": "0004704576"
            }
        ],
       
        "document_type": "COA"
    },
    {
        "id": "T8506351",
        "cast_id": "T8506351",
        "COA": [
            {
                "key": "castid",
                "value": "T8506351"
            },
            {
                "key": "bundleno",
                "value": "1234568"
            },
            {
                "key": "netweight",
                "value": "1200.000"
            },
            {
                "key": "product",
                "value": "BILLET"
            },
            {
                "key": "soitem",
                "value": "000010"
            },
            {
                "key": "deliverydate",
                "value": "2023-07-31"
            },
            {
                "key": "alloy",
                "value": "6060.70"
            },
            {
                "key": "si",
                "value": "0.46"
            },
            {
                "key": "The Iron",
                "value": "0.21"
            },
            {
                "key": "cu",
                "value": "0.0"
            },
            {
                "key": "mn",
                "value": "0.04"
            },
            {
                "key": "mg",
                "value": "0.37"
            },
            {
                "key": "cr",
                "value": "0.0"
            },
            {
                "key": "zn",
                "value": "0.01"
            },
            {
                "key": "Titanium",
                "value": "0.016"
            },
            {
                "key": "othe",
                "value": "0.00"
            },
            {
                "key": "tothersvalue",
                "value": "0.04"
            },
            {
                "key": "alvalue",
                "value": "98.86"
            },
            {
                "key": "mes_sono",
                "value": "0004704576"
            }
        ],
       
        "document_type": "COA"
    }
]