import React from "react";
import SideBarForSubAdmin from "../CustomerAdmin/CASideBar.jsx";

function SubAdminRoute({ component: C, appProps, ...rest }) {
  return (
    <SideBarForSubAdmin>
      <C {...appProps} />
    </SideBarForSubAdmin>
  );
}
export default SubAdminRoute;
