import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import { createTheme } from '@material-ui/core';
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
    components: {
        MuiSelect: {
            styleOverrides: {
                select: {
                    color: "#646464",
                    borderStyle: "none",
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
                    transition: "box-shadow 0.3s",
                    // height: "40px",
                    // padding: "7px",
                    padding: "7px 13px",
                    // fontFamily: "Montserrat, sans-serif",
                    fontFamily: "Nunito Sans, sans-serif",
                    fontWeight: "500",
                    fontSize: "14px",
                    "&:focus": {
                        outline: "none",
                        boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)",
                        // borderColor: "red"
                    }
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    // fontFamily: "Montserrat, sans-serif",
                    fontFamily: "Nunito Sans, sans-serif",
                    fontWeight: "500",
                    fontSize: "14px",
                    // borderRadius: "4px", // Add border radius for dropdown options
                    // border: "1px solid #ccc", // Add border color for dropdown options
                    // boxShadow: "none", // Remove box shadow from dropdown options
                    "&:hover": {
                        // backgroundColor: "#30506E" // Change the background color on hover
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#6E6E6E",
                    // fontFamily: "Montserrat, sans-serif",
                    fontFamily: "Nunito Sans, sans-serif",
                    fontWeight: "500",
                    // fontSize: "1rem",
                    fontSize: "14px",
                    lineHeight: "1.4375em",
                    letterSpacing: "0.00938em",
                    padding: 0,
                    position: "relative",
                    display: "block",
                    transformOrigin: "top left",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "calc(100% - 24px)",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    // top: "-10px",
                    // -webkit-transform: translate(14px, 16px) scale(1);
                    // -moz-transform: translate(14px, 16px) scale(1);
                    // -ms-transform: translate(14px, 16px) scale(1);
                    // transform: "translate(14px, 16px) scale(1)",
                    // -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
                    // transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
                    zIndex: 1,
                    pointerEvents: "none",
                    "&.Mui-focused": {
                        color: "#6E6E6E",
                        position: "absolute",
                        left: 0,
                        top: 0
                    }
                }
            }
        }
    }
})

function SelectInput(
    {
        selectLabel, id, name, value, onChange, optionsList,
        margin, disabled
    }
) {

    console.log("optionslist", optionsList)

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ m: 1, minWidth: 80, margin: { margin } }}>
                <FormControl sx={{ m: 1, minWidth: 120, margin: "unset" }} fullWidth size="small">
                    <InputLabel id="demo-select-small-label">{selectLabel}</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        name={name}
                        id={id}
                        //  id="demo-simple-select"
                        // id="demo-select-small"
                        value={value}
                        label={selectLabel}
                        onChange={(e) => onChange(e)}
                        disabled={disabled}
                    //  placeholder={placeholder}
                    //  displayEmpty={displayEmpty}
                    //  autoWidth
                    >
                        {optionsList.length > 0 &&
                            optionsList.map((obj) => {
                                let { value, name } = obj
                                return <MenuItem value={value}>{name}</MenuItem>

                            })
                        }

                    </Select>
                </FormControl>

                {/* // <Box sx={{m: 1, minWidth: 80, margin: {margin}}}>
        // <FormControl sx={{ m: 1, minWidth: 120 }}  size="small">
        //          <InputLabel id="demo-select-small-label">{selectLabel}</InputLabel>
        //          <Select
        //             // labelId="demo-select-small-label"
        //             // id="demo-select-small"
        //             value={value}
        //             label={selectLabel}
        //             onChange={(e) => onChange(e)}
        //         >
        //             {optionsList.length > 0 &&
        //                 optionsList.map((obj) => {
        //                     let { value, name } = obj
        //                     return <MenuItem value={value}>{name}</MenuItem>

        //                 })
        //             }


        //         </Select>
        //     </FormControl> */}
            </Box>
        </ThemeProvider>
    );
}

export default SelectInput