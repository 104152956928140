import { DataGrid } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Modal } from "antd";
import { LuView } from "react-icons/lu";
import key from "../../../../Assets/Images/fa-solid_key.png"
import DisplayAPIFormat from "../../CustomerAdmin/API/DisplayAPIFormat";
import { Input } from "antd";
import { activeAPIColumn } from "./DataSourceDisplayActiveAPIList";
import AddApiRequest from "./APIRequest";
import { getActiveApi, newToken } from "../../../../Services/AdminServices";
import ApiResponse from "./ApiResponse";
import Loader from "../../../../utils/Loader/Loader";
import BasicButton from "../../../Reusable_Components/Button";
import ErrorCatch from "../../../Reusable_Components/ErrorCatch";
import TabsAntd from "../../../Reusable_Components/TabsAntd";
import ApiDetails from "./ApiDetails";
import ImageSlider from "../../../Reusable_Components/ImageSlider";
import AgGridComponent from "../../../Reusable_Components/AgGridComponent";
import { useSelector } from "react-redux";


const DisplayActiveAPIList = ({ activeAPIList }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [data, setData] = useState([]);
  const [apiParam, setApiParam] = useState([])
  const [viewApiFormat, setViewApiFormat] = useState([]);
  const [isApiJson, setIsApiJson] = useState(false)
  const [isApiDetails, setIsApiDetails] = useState(false)
  const navigate = useNavigate();
  const [userRoute, setUserRoute] = useState(false);
  const [params, setParams] = useState([]);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // uam role object start
  const userDetails = useSelector((state) => state)
  const appsubmodulename = "Services"
  const appfunctionalityname = "APIs"
  const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
  const [appObjects, setAppObjects] = useState(
    {
      "Action": false,
      "Token": false,
      "View": false,
      "Request": false
    }
  )
  console.log("userDetails", userDetails)

  const updateAppObjects = (arr) => {
    let objectsArray = []
    arr.map((obj) => {
      console.log("updateButtons", obj)
      // if (obj.objecttypename == "button") {
      objectsArray.push(obj)
      // }
    })
    const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
      acc[appobjectname] = true;
      return acc;
    }, {});
    setAppObjects({ ...appObjects, ...finalObject })
    console.log("objectsArray", objectsArray)
    console.log("finalObject", finalObject)
  }

  useEffect(() => {
    console.log("appObjects", appObjects)
  }, [appObjects])

  useEffect(() => {
    console.log("userRoleObjects", userRoleObjects)
    const filteredList = []
    userRoleObjects.filter((obj) => {
      if (obj.appsubmodulename == appsubmodulename && obj.appfunctionalityname == appfunctionalityname) {
        filteredList.push(obj)
      }
    })

    console.log("filteredList", filteredList)
    updateAppObjects(filteredList)

  }, [])

  // uam role object end

  const showModal1 = (p) => {
    // setIsModalOpen1(true);
    setApiParam(p.data)
    // setViewApiFormat(apiurl);
  };

  useEffect(() => {
    console.log("apiParam", apiParam)
    if (Object.keys(apiParam).length > 0) {
      setIsModalOpen1(true)
      setIsApiJson(true)
    }
  }, [apiParam])

  const handleOk1 = () => {
    setIsModalOpen1(false);
    setApiParam([])
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
    setApiParam([])
  };

  ////generateToken////
  const generateToken = async (p) => {
    const { cust_req_id } = p
    let custId = { _cust_req_id: cust_req_id }
    // axios.put(`http://localhost:5888/regenerate/api/token`,custId)
    setLoading(true)
    try {
      const response = await newToken(custId)
      console.log("49", response);
      if (response.status == 200) {
        toast.success(response.data.msg);
      }
    } catch (error) {
      console.log("54", error)
      // toast.error(error.response.data.msg)
      setError(error)
    }
    // .then(response => {
    //   // Handle the response data here if needed
    //   console.log('API response:', response.data);
    //   toast.success(response.data.msg);
    // })
    // .catch(error => {
    //   // Handle any errors that occur during the API call
    //   console.error('Error sending data:', error.response.data.msg);
    //   toast.error(error.response.data.msg)
    // });
    setLoading(false)
  }
  ////generateToken end///



  const { Search } = Input;

  // const onSearch = (value: string) => console.log(value);

  const handleDisplay = (url) => {
    // console.log(url);

    localStorage.removeItem("api_url");

    localStorage.setItem("api_url", url);

    navigate("/services/apiformat");
  };

  const activeAPI = async () => {
    let email = localStorage.getItem("Logindata");

    setLoading(true)
    try {
      let res = await getActiveApi(email);
      console.log("getActiveApiresponse", res)
      if (res.status == 200) {
        let response = res.data.data;
        response.map((item) => {
          if (item.api_format_id === 3) {
            item.api_format_id = "PDF";
          }

          if (item.api_format_id === 2) {
            item.api_format_id = "CSV";
          }

          if (item.api_format_id === 1) {
            item.api_format_id = "JSON";
          }

          return item;
        })
        setData([...response]);
      }
    }
    catch (error) {
      console.log("getActiveApierror", error)
      setError(error)
    }

    setLoading(false)
  };

  useEffect(() => {
    activeAPI();
  }, []);

  useEffect(() => {
    if (activeAPIList == true) {
      activeAPI();
    }
  }, [activeAPIList]);

  const actionColumn = [

    {
      field: "View",
      headerName: "View",
      // width: 100,
      flex: 0.2,
      filter: false,
      cellRenderer: (params) => {
        return (
          <div className="cellAction">
            {appObjects["View"] && <button
              className="btn btn-light"
              style={{ background: "transparent", borderStyle: "none" }}
              onClick={() => showModal1(params)}
              disabled={params.data.api_status_name == "Pending" || params.data.api_status_name == "Deactive" || params.data.api_status_name == "In Progress" ? true : false}
            >
              <LuView />
            </button>}
          </div>
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      // width: 100,
      filter: false,
      flex: 0.2,
      cellRenderer: (params) => {
        return (
          <button className="cellAction"
            style={{ background: "transparent", borderStyle: "none" }}
            disabled={params.data.api_status_name == "Pending" || params.data.api_status_name == "Deactive" || params.data.api_status_name == "In Progress" ? true : false}
          >
            {appObjects["Token"] && <img src={key}
              alt="key" style={{ width: "1rem" }}
              onClick={() => generateToken(params.data)} />}
          </button>
        );
      },
    },
  ];

  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";

    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    return retVal;
  }


  const handleOk = () => {
    setIsModalOpen(false);
    activeAPI();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleAddApi = () => {
    setIsModalOpen(true);
  };

  const tabItems = [
    {
      key: "1",
      label: `JSON`,
      children: apiParam ? <ApiResponse isApiJson={isApiJson} data={apiParam} /> : null,
    },
    {
      key: "2",
      label: `Details`,
      children: apiParam ? <ApiDetails isApiDetails={isApiDetails} details={apiParam} /> : null,
    },
  ]

  const onChangeTabs = (key) => {
    console.log("onChangeTabs", key);
    if (key == "1") {
      setIsApiJson(true)
      setIsApiDetails(false)
    }
    else if (key == "2") {
      setIsApiJson(false)
      setIsApiDetails(true)
    }
  }

  const rowClassRules = {
    'even-row': (params) => params.node.rowIndex % 2 !== 0,
  };

  return (
    <>
      <Loader loading={loading} />
      <Toaster position="top-center" reverseOrder={false} />
      <ErrorCatch err={error} />
      <div className="container-fluid">
        <div className="row mt-3 justify-content-end px-1">
          <div className="card bg-transparent d-flex col-md-2 " style={{ border: "none" }}>
            {appObjects["Request"] && <BasicButton
              text="Request"
              onClick={handleAddApi}
            />}
          </div>
        </div>
        {data.length > 0 ? (
          <div className="col-md-12 mt-2"
          // style={{ height: "22rem", overflowY: "auto" }}
          >
            {/* <Box sx={{ height: "62vh", width: "100%", overflowY: "auto" }}>
              <DataGrid
                sx={{

                  borderColor: "secondary",
                  "& .MuiDataGrid-cell:hover": {
                    color: "primary.main",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    backgroundColor: "#0E3386",
                    fontSize: "12px",
                    color: "white",
                    padding: "10px",

                    // Set your desired header background color
                  },
                  "& .MuiDataGrid-cell": {
                    fontSize: "10px",
                    padding: "10px",
                    borderRight: "2px solid #e0e0e0",
                    // Adjust the font size as needed
                    // Adjust the cell padding as needed
                  },
                }}
                rowHeight={30}
                rows={data}
                columns={activeAPIColumn.concat(actionColumn)}
                pageSize={9}
                handleChangeCheckbox
                onCellClick={(params) => params}
                rowsPerPageOptions={[9]}
                getRowId={(row) => generateRandom()}
                checkboxSelection={false}
                hideFooter
              />
            </Box> */}
            <AgGridComponent rowData={data} columnDef={activeAPIColumn.concat(actionColumn)} rowClassRules={rowClassRules} />

          </div>
        ) : (
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col d-flex justify-content-center">
                {" "}
                No Rows to show
              </div>
            </div>
          </div>
        )}

        <AddApiRequest
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      </div>
      <div
      // style={{ height: "22rem" }}
      >
        {/* View API Format  */}
        <Modal
          title="API Details"
          open={isModalOpen1}
          onOk={handleOk1}
          onCancel={handleCancel1}
          destroyOnClose
          footer={null}
          style={{ top: 20, minWidth: 500, maxWidth: 800 }}
        >
          <hr />
          <div>
            <TabsAntd
              defaultActiveKey="1"
              height="30rem"
              items={tabItems}
              onChange={(key) => onChangeTabs(key)}
              centered
            />
          </div>
          {/* <ApiResponse data={apiParam} /> */}
        </Modal>
      </div>

    </>
  );
};

export default DisplayActiveAPIList;
