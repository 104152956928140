import React, { useState, useEffect } from "react";
import {
  createTheme,
  ThemeProvider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FaEdit } from 'react-icons/fa';
import { useSelector } from "react-redux";

const theme = createTheme({
  typography: {
    // fontFamily: 'Montserrat, sans-serif',
    fontFamily: 'Nunito Sans", sans-serif',
  },
  overrides: {
    MuiAccordion: {
      root: {
        borderRadius: '20px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        '&:focus': {
          boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.3)',
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        // fontFamily: 'Montserrat, sans-serif',
        fontFamily: 'Nunito Sans", sans-serif',
        fontWeight: '500',
        fontSize: '14px',
        color: '#646464',
      },
      expandIcon: {
        '&.Mui-expanded': {
          transform: 'rotate(180deg)',
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        // fontFamily: 'Montserrat, sans-serif',
        fontFamily: 'Nunito Sans", sans-serif',
        fontWeight: '400',
        fontSize: '14px',
      },
    },
  },
});

function CustomAccordion({ className, expanded, onChange, onClick,isEdit,question, answer }) {

  // uam role object start
  const userDetails = useSelector((state) => state)
  const appsubmodulename = "FAQ"
  const appfunctionalityname = "FAQ"
  const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
  const [appObjects, setAppObjects] = useState(
    {
      "Edit FAQ": false,
    }
  )
  console.log("userDetails", userDetails)
  const updateAppObjects = (arr) => {
    let objectsArray = []
    arr.map((obj) => {
      console.log("updateButtons", obj)
      if (obj.objecttypename == "button") {
      objectsArray.push(obj)
      }
    })
    const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
      acc[appobjectname] = true;
      return acc;
    }, {});
    setAppObjects({ ...appObjects, ...finalObject })
    console.log("objectsArray", objectsArray)
    console.log("finalObject", finalObject)
  }

  useEffect(() => {
    console.log("appObjects", appObjects)
  }, [appObjects])

  useEffect(() => {
    console.log("userRoleObjects", userRoleObjects)
    const filteredList = []
    userRoleObjects.filter((obj) => {
      if (obj.appsubmodulename == appsubmodulename && obj.appfunctionalityname == appfunctionalityname) {
        filteredList.push(obj)
      }
    })

    console.log("filteredList", filteredList)
    updateAppObjects(filteredList)

  }, [])

  // uam role object end

  return (
    <ThemeProvider theme={theme}>
      <Accordion className={className} expanded={expanded} onChange={onChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {/* <Typography>{question}</Typography> */}
          <div className='d-flex col-md-12 justify-content-between'>
            <Typography>{question}</Typography>
           {appObjects["Edit FAQ"] && isEdit && <FaEdit className='mt-1' onClick={onClick}/>}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{answer}</Typography>
        </AccordionDetails>
      </Accordion>
    </ThemeProvider>
  );
}

export default CustomAccordion;
