import React, { useState } from "react";
import CryptoJS from 'crypto-js';
import logo from "../../../Assets/Images/alba_logo.png";
// import bcrypt from 'bcryptjs'; // Import bcrypt
import { Modal } from "antd";
// import ForgotPassword from "../User/ForgotPassword";
import { Formik } from "formik";
import * as Yup from "yup";
import { Input } from "antd";
import toast, { Toaster } from "react-hot-toast";
import { LoginUser, generateOTPforMFASignin, verifyTOTPforMFASignin } from "../../../Services/UserServices";
import ForgetPassword from '../Homepages/ForgetPassword'
import { useNavigate } from "react-router-dom";
import Loader from "../../../utils/Loader/Loader";
import InputBox from "../../Reusable_Components/InputBox";
import { useDispatch, useSelector } from "react-redux";
import { setUserAuthenticated, setUserDetails, setUserRoleObjects } from "../../../redux/authSlice";
import { useEffect } from "react";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
import dayjs from 'dayjs';
import { getRoleObjectsByUser } from "../../../Services/UAMservices";
// import CryptoJS from 'crypto-js';
// import Login_page from "../User/Login";
// import CryptoJS from 'crypto-js';

const Signin = (props) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [contactMessage, setContactMessage] = useState("")
  const [isContactModal, setIsContactModal] = useState(false)
  const [loginData, setLoginData] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const authenticatedState = useSelector(setUserAuthenticated)
  // const authenticatedState = useSelector((state) => state.auth.isAuthenticated);
  let authStatus = authenticatedState.payload.auth
  console.log("authenticatedState", authenticatedState)

  const [isPhoneVerifyModal, setIsPhoneVerifyModal] = useState(false)
  const [step, setStep] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [generatedSecret, setGeneratedSecret] = useState("")
  const [otp, setOtp] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")

  useEffect(() => {
    if (step > 0) {
      setTimeLeft(step);
    }
  }, [step]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          //   return step;
          clearInterval(interval);
          return 0
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [step]);

  useEffect(() => {
    if (undefined !== loginData) {
      console.log("loginDataCheck", loginData)
      let { ismfa, email, phone, is_phone_verified } = loginData.data.data
      if (ismfa) {
        setIsPhoneVerifyModal(true)
        setEmail(email)
        setPhoneNumber(phone)
        sendOTP(email, phone)
        return
      }
      let { isAuthenticated, role } = authStatus
      if (isAuthenticated == true) {
        authorize(loginData)
      }
    }
  }, [loginData])
  //Forget password
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  }

  const handleContactModalOk = () => {
    setIsContactModal(false);
  }

  const handleContactModalCancel = () => {
    setIsContactModal(false);
  }

  const authorize = async (res) => {
    const { company_status_id, email, user, isverified } = res.data
    console.log("authorizeemail", email, "user", user, "statusId", company_status_id, "isverified", isverified)
    console.log("authorizeparamres", res)

    if (res.status == 200 && res.data.company_status_id == "1") {
      localStorage.setItem("userRole", res.data.user)
      localStorage.setItem("comp_id", res.data.comp_id)
      localStorage.setItem("token", res.data.token);
      const userRoleObjects = await getRoleObjectsByUser(res.data.user)
      console.log("userRoleObjects", userRoleObjects)
      dispatch(setUserRoleObjects(userRoleObjects.data.roleObjects))
    }

    if ((res.data.user === "super-admin" || res.data.user === "super-user") && res.status === 200 && res.data.company_status_id == "1") {
      toast.success("Login successful");
      localStorage.setItem("Logindata", res.data.email);
      navigate("/admindashboard");
    } else if (
      res.data.user === "customer-admin" &&
      res.status === 200 &&
      res.data.status !== 2 &&
      res.data.status !== 3
      && res.data.isverified == true
      && res.data.company_status_id == "1"
    ) {
      toast.success("Login successful");
      localStorage.setItem("Logindata", res.data.email);
      localStorage.setItem("UserName", res.data.data.name);
      navigate("/subadmin");
    } else if (
      res.data.user === "customer-user" &&
      res.status === 200 &&
      res.data.status !== 2 &&
      res.data.status !== 3
      && res.data.isverified == true
      && res.data.company_status_id == "1"
    ) {
      toast.success("Login successful.");
      localStorage.setItem("Logindata", res.data.email);
      navigate("/userdashboard");
    } else if (res.data.status == 3 && res.data.user === "customer-user") {

      // navigate("/pagenotfound");
      navigate(`/pagenotfound/${user}/${email}/${company_status_id}`)
    } else {
      // navigate("/pagenotfound");
      navigate(`/pagenotfound/${user}/${email}/${company_status_id}`)
    }
  }

  const schema = () => {
    return Yup.object().shape({
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
      password: Yup.string()
        .required("Password is a required field")
        .min(8, "Password must be at least 8 characters"),
    });
  };

  // for encrypting email and password starts here

  // const hashPassword = async (password) => {
  //   try {
  //     const saltRounds = 10; // Number of salt rounds
  //     const hashedPassword = await bcrypt.hash(password, saltRounds);
  //     return hashedPassword;
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  // ends here

  const handleSubmit = async (values) => {
    // let sendData = {
    //   email: values.email,
    //   password: CryptoJS.SHA256(values.password).toString()
    // }
    console.log("signinvalues", values)

    //starts here
    // Hash the password using bcrypt
    const hashedPassword = CryptoJS.SHA256(values.password).toString();
    // Use the hashed password in your request
    values.password = hashedPassword;
    // ends here
    console.log("1", values.email);
    console.log("2", values.password)
    console.log("signinvalues1", values)
    // console.log("signinvalues2", credentials)
    // ends here
    await LoginUser(values)
      .then((res) => {
        console.log("loginUserRes", res)
        console.log("email", values.email);
        console.log("password", values.password)
        console.log("signres", res.data.data.name)
        setLoginData(res)
        let loginTime = res.data.lastLogin
        const formattedDate = loginTime == null ? null : dayjs(loginTime).format('DD MMMM, YYYY h:mm:ss A');
        localStorage.setItem("lastLogin", formattedDate);
        const userRole = res.data.user
        // const userRole = 'user'
        dispatch(setUserAuthenticated(userRole))
        let userDetails = res.data.data
        console.log("userDetails", userDetails)
        let { name, email, comp_id, phone, user_type, role_id, is_email_enabled, user_id, last_login, department, designation } = userDetails
        let { comp_name } = res.data.isCompActive
        let userDataObj = { name, email, comp_id, comp_name, phone, user_type, role_id, user_id, department, designation }
        dispatch(setUserDetails(userDataObj))

        // if (res.data.user === "super-admin" && res.status === 200 && res.data.company_status_id == "1") {
        //   toast.success("Login successful.");
        //   localStorage.setItem("Logindata", res.data.email);
        //   navigate("/admindashboard");
        // } else if (
        //   res.data.user === "admin" &&
        //   res.status === 200 &&
        //   res.data.status !== 2 &&
        //   res.data.status !== 3
        //   && res.data.isverified == true
        //   && res.data.company_status_id == "1"
        // ) {
        //   toast.success("Login successful.");
        //   localStorage.setItem("Logindata", res.data.email);
        //   localStorage.setItem("UserName", res.data.data.name);
        //   navigate("/subadmin");
        // } else if (
        //   res.data.user === "user" &&
        //   res.status === 200 &&
        //   res.data.status !== 2 &&
        //   res.data.status !== 3
        //   && res.data.isverified == true
        //   && res.data.company_status_id == "1"
        // ) {
        //   toast.success("Login successful.");
        //   localStorage.setItem("Logindata", res.data.email);
        //   navigate("/userdashboard");
        //   navigate("/pagenotfound");
        // } else {
        //   navigate("/pagenotfound");
        // }

      })
      .catch((error) => {
        console.log("siginerror", error);

        if ("response" in error) {
          // toast.error(error.response.data.message, {
          //   position: "top-right",
          //   autoClose: 5000,

          // })

          // setError(error)

          if (error.response.data.message == "Invalid username or password") {
            setIsContactModal(false)
            toast.error(error.response.data.message)
          }
          else {
            // setIsContactModal(true)
            // setContactMessage(error.response.data.displayMessage)
            toast.error(error.response.data.message)
          }


          // if (error.response.data.message == "Sorry! user inactive") {
          //   if (userType._user_type == "admin") {
          //     setIsContactModal(true)
          //     setContactMessage(`Your account is inactive for the \n Reason: ${userType._status_reason}.\n 
          //     contact Admin at doct.superadmin@rapidqube.com`)
          //   }
          //   else if (userType._user_type == "user") {
          //     setIsContactModal(true)
          //     setContactMessage(`Your account is inactive for the \n Reason: ${userType._status_reason}. \n
          //     contact your Admin ${adminDetails.title} ${adminDetails.name} at ${adminDetails.email}`)
          //   }
          // }

        }

        else {
          toast.error(error.message)
        }
      });
  };

  const sendOTP = async (email, phoneNumber) => {
    setLoading(true)
    let data = {
      email, phoneNumber
    }
    try {
      let response = await generateOTPforMFASignin(data)
      console.log("sendOTP", response)
      setGeneratedSecret(response.data.secret)
      setStep(response.data.step)
      setTimeLeft(response.data.step)
      setIsPhoneVerifyModal(true)
      setOtp("")
    }
    catch (err) {
      console.log("sendOTPErr", err)
    }
    setLoading(false)
  }

  const verifyUserOTP = async () => {
    if (otp == "") {
      toast.error("Please enter OTP")
      return
    }
    setLoading(true)
    let data = {
      token: otp,
      secret: generatedSecret,
      email: email,
      phoneNumber: phoneNumber
    }
    try {
      let response = await verifyTOTPforMFASignin(data)
      console.log("verifyUserOTPresponse", response)
      setIsPhoneVerifyModal(false)
      let { isAuthenticated, role } = authStatus
      if (isAuthenticated == true) {
        authorize(loginData)
      }
    }
    catch (err) {
      console.log("verifyUserOTPErr", err)
      setStep(0)
      setTimeLeft(0)
      if ("response" in err) {
        toast.error(err.response.data.message)
      }
      else {
        toast.error(err.message)
      }
    }
    setLoading(false)
  }

  const closeOTPverificationModal = () => {
    setIsPhoneVerifyModal(false)
    setStep(0)
    setTimeLeft(0)
  }

  const handleOTP = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, '')
    setOtp(value)
  }

  return (
    <>
      <Loader loading={loading} />
      <ErrorCatch err={error} />
      <Formik
        validationSchema={schema}
        initialValues={{ email: "", password: "" }}
        onSubmit={async (values, { resetForm }) => {
          setLoading(true)
          await handleSubmit(values);
          resetForm();
          setLoading(false)
        }}
      >

        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <div className="login">
            <div className="form">
              <form class="row g-2 p-5" style={{}} onSubmit={handleSubmit}>
                <h6 class="mt-4">
                  <b className="text-center login-text">Login to your account</b>
                </h6>
                <div className="col-12 form-floating" >
                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    placeholder="Enter email "
                    className="form-control inp_text"
                    // id="email"
                    id="floatingInput"
                  />

                  <label for="floatingInput">Email address</label>
                  <p className="error">
                    {errors.email && touched.email && errors.email}
                  </p>
                </div>
                <div className="col-12 form-floating">

                  {/* <Input.Password
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    placeholder="Enter password"
                  // id="floatingPassword"
                  // className="form-control"
                  /> */}

                  <input
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    placeholder="Enter Password "
                    className="form-control inp_text"
                    id="floatingPassword"
                  />

                  <label for="floatingPassword">Password</label>
                  <p className="error">
                    {errors.password && touched.password && errors.password}
                  </p>
                </div>

                <div class="text-end mb-2">
                  <text class="text-primary forgot-text" onClick={showModal} >
                    Forgot Password?
                  </text>
                </div>
                <button type="submit" class="btn red-btn mt-5 p-3">
                  Login
                </button>
              </form>
            </div>
          </div>
        )}

      </Formik>
      <Modal
        className="p-2"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
      >
        <ForgetPassword loginclose={handleCancel} />
      </Modal>

      <Modal title="Contact Detailsss" open={isContactModal} onOk={handleContactModalOk} onCancel={handleContactModalCancel}
        style={{ top: "20%" }}
        footer={null} maskClosable={false}
      >
        <hr />
        <div
        >
          {/* <p>{contactMessage}</p> */}
          <p dangerouslySetInnerHTML={{ __html: contactMessage }} />
        </div>
      </Modal>

      <Modal
        title="Verify OTP"
        style={{ top: 20, minWidth: 300, maxWidth: 600 }}
        className="p-2 w-25"
        open={isPhoneVerifyModal}
        // onOk={isPhoneVerified}
        onCancel={closeOTPverificationModal}
        footer={null}
      >
        <div className="mt-2">
          <div className="row p-2">
            <InputBox
              label="OTP"
              name="otp"
              onChange={(e) => handleOTP(e)}
              value={otp}
            />
          </div>
          {/* {step &&  */}
          <>
            <div className="row p-2">
              <div className="col-md-3">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={verifyUserOTP}
                // disabled={timeLeft == 0 ? true : false}
                >
                  Verify OTP
                </button>
              </div>
              {timeLeft == 0 ?
                <div className="col-md-6">
                  <button
                    className='btn btn-secondary btn-sm'
                    onClick={() => sendOTP(email, phoneNumber)}
                  >
                    Generate OTP again
                  </button>
                </div>
                : <></>}
            </div>
            <p className="p-2">Time left for current OTP: {timeLeft} seconds</p>
          </>
          {/* } */}
        </div>
      </Modal>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default Signin;