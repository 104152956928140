import React from 'react';
import myPic from "../../../Assets/Images/main.png";
import myLogo from "../../../Assets/Images/alba_logo.png";

function Maintenance(){

    return(<div className='maintain'>

    <div className='div3'>    <img className='maintain1' src = {myLogo} height={40} width={95}/></div>

    {/* <div id="logo">

    <img src={myLogo} height={55} width={80}/>

</div> */}

<div className='div1'>We are updating our website</div>

<div className='div2'>We will be back soon.</div>

<img src= {myPic}  className='circle_maintain' alt="loading image"  height={371} width={557}/>

    </div>)

}

 

export default Maintenance;
