import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route } from 'react-router-dom';

const ProtectedRoute = ({ component: Component,role,isAuthenticated,routes, isAdmin, isSubAdmin, isUser, ...rest }) => {
  console.log("protectedroute",Component,isAdmin,isSubAdmin,isUser,rest)
  // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  // const userRole = useSelector((state) => state.auth.role); // Assuming you store the user's role in the auth state

  const hasAccess = (role) => {
    if (isAdmin && role === 'admin') {
      return true;
    }
    if (isSubAdmin && role === 'subadmin') {
      return true;
    }
    if (isUser && role === 'user') {
      return true;
    }
    return false;
  };

  return (
    <Route
    {...rest}
      // element={isAuthenticated && hasAccess(userRole) ? <Component/> : <Navigate to="/" /> }
      element={isAuthenticated ? <Component/> : (<Navigate to="/" />) }
      
      // render={(props) =>
      //   console.log("props",props)
      //   // isAuthenticated && hasAccess(userRole) ? (
      //   //   <Component {...props} />
      //   // ) : (
      //   //   <Navigate to="/" />
      //   // )
      // }
    />
  );
};

export default ProtectedRoute;
