import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserUnauthenticated } from "../../redux/authSlice";
import disableBackButtonAfterSignout from "../../redux/disableBackButton";
import toast, { Toaster } from "react-hot-toast";
import { useEffect } from "react";

const ErrorCatch = ({ err }) => {
  const dispatch = useDispatch()
  let navigate = useNavigate();

  const signout = () => {
    dispatch(setUserUnauthenticated())
    disableBackButtonAfterSignout()
    localStorage.removeItem("Logindata");
    localStorage.removeItem("SelectedMenuItem");
    localStorage.removeItem("UserName");
    navigate("/");
    // adding here
    window.history.pushState(null, null, window.location.href); window.onpopstate = function () {
      window.history.go(1);
    };
    // ends here
    localStorage.removeItem("userRole")
    localStorage.clear();
  };

  const displayErrorCatch = async (err) => {
    console.log("displayErrorCatch", err)
    if ("response" in err) {
      if (err.response.status == 401) {
        await signout()
        toast.error("Session Expired! Try signing in again", {
          duration: 4000,
        })
      }

      else {
        toast.error(err.response.data.message)
      }
    }
    else if ("__type" in err) {
      if (err.__type == "NotAuthorizedException") {
        await signout()
        toast.error(err.message +"." + " Please login again", {
          duration: 4000,
        })
      }
      else {
        toast.error(err.message, {
          duration: 4000,
        })
      }
    }
    else if ("message" in err) {
      toast.error(err.message)
    }
    else {
      toast.error("Error Occurred")
    }
  }

  useEffect(() => {
    if (err) {
      displayErrorCatch(err)
    }
  }, [err])
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  )
}
export default ErrorCatch