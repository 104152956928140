import Home from "./Pages/Homepages/LandingPage";
import SignUp from "./Pages/Homepages/Signup.jsx";
import Signin from "./Pages/Homepages/Signin.jsx";
import AdminDashboard from "./Pages/Admin/Dashboard";
import CustomerList from "./Pages/Admin/CustomerList";
import Services from "./Pages/Admin/Services";
import AddFAQ from "./Pages/Admin/FAQ";
import ActiveCompony from "./Pages/Admin/AllCompany";
import DeactiveCompony from "../Components/Pages/Admin/RequestedCompany";
import AdminProfile from "../Components/Pages/Admin/AdminProfile";
import FeedbacksList from "../Components/Pages/Admin/FeedbackList";
import SettingsScreen from "../Components/Pages/Admin/Settings";
import EditProfilePage from "../Components/Pages/Admin/EditProfile.jsx";
import MessagePrefrence from "../Components/Pages/Admin/Messagesettings";
import UserDashboard from "../Components/Pages/Users/UserDashboard";
import AllFaq from "../Components/Pages/Users/FAQList";
import AddFeedBack from "../Components/Pages/Users/AddFeedback";
import UserSettingsScreen from "../Components/Pages/Users/UserSettings";
import ViewDocument from "../Components/Pages/Users/ViewDocument";
import UserProfile from "../Components/Pages/Users/UserProfile";
import UserLogs from "../Components/Pages/Admin/Logs/Logs";
import CustomerAdmin from "../Components/Pages/CustomerAdmin/CADashBoard";
import SubSettingsScreen from "../Components/Pages/CustomerAdmin/CASettings";
import SubAdminProfile from "../Components/Pages/CustomerAdmin/Profile";
import EmployeeDetails from "../Components/Pages/CustomerAdmin/Employee/Employee";
import SubAdminServices from "../Components/Pages/CustomerAdmin/SubAdminServices";
import CreateRole from "../Components/Pages/Admin/role_creation/CreateRole";
import ReadMore from "../Components/Pages/Homepages/ReadMore";
import DisplayActiveAPIList from "../Components/Pages/CustomerAdmin/API/DisplayActiveAPIList";
import DisplayAPIFormat from "../Components/Pages/CustomerAdmin/API/DisplayAPIFormat"
import DocumentList  from './Pages/Admin/Documents/RequestedDocumentList'
import RegisterUser from "./Pages/Admin/RegisterUser.jsx";
import Reports from "./Pages/Reports";
export const ROUTES = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/howitswork",
    component: ReadMore,
  },
  {
    path: "/register",
    component: SignUp,
  },
  {
    path: "/admindashboard",
    component: AdminDashboard,
    isAdmin: true,
    isSuperUser: true,
    isITAdmin: true
  },
  {
    path: "/customerlist",
    component: CustomerList,
    isAdmin: true,
    isSuperUser: true,
    isITAdmin: true
  },
  {
    path: "/service",
    component: Services,
    isAdmin: true,
    isSuperUser: true,
    isITAdmin: true
  },
  {
    path: "/addfaq",
    component: AddFAQ,
    isAdmin: true,
    isSuperUser: true,
    isITAdmin: true
  },
  {
    path: "/activecompany",
    component: ActiveCompony,
    isAdmin: true,
    isSuperUser: true,
    isITAdmin: true
  },
  {
    path: "/requested-company",
    component: DeactiveCompony,
    isAdmin: true,
    isSuperUser: true,
    isITAdmin: true
  },
  {
    path: "/myprofile",
    component: AdminProfile,
    isAdmin: true,
    isSuperUser: true,
    isITAdmin: true

  },
  {
    path: "/feedbacklist",
    component: FeedbacksList,
    isAdmin: true,
    isSuperUser: true,
    isITAdmin: true
  },
  {
    path: "/adminsettings",
    component: SettingsScreen,
    isAdmin: true,
    isSuperUser: true,
    isITAdmin: true
  },
  {
    path: "/registeruser",
    component: RegisterUser,
    isAdmin: true,
    isSuperUser: true,
    isITAdmin: true
  },
  {
    path: "/profilesettings",
    component: EditProfilePage,
    isAdmin: true,
    isSuperUser: true,
    isITAdmin: true
  },
  {
    path: "/messageprefrence",
    component: MessagePrefrence,
    isAdmin: true,
    isSuperUser: true,
    isITAdmin: true
  },
  {
    path: "/userdashboard",
    component: CustomerAdmin,
    isUser: true,
    
  },
  {
    path: "/faqs",
    component: AllFaq,
    isUser: true,
  },
  {
    path: "/usersettings",
    component: UserSettingsScreen,
    isUser: true,
  },
  {
    path: "/addfeedback",
    component: AddFeedBack,
    isUser: true,
  },

  {
    path: "/viewfile",
    component: ViewDocument,
    isUser: true,
  },
  {
    path: "/userprofile",
    component: UserProfile,
    isUser: true,
  },
  {
    path: "/logs",
    component: UserLogs,
    isAdmin: true,
    isITAdmin: true
  },
  {
    path: "/role",
    component: CreateRole,
    isAdmin: true,
    isITAdmin: true
  },
  {
    path: "/subadmin",
    component: CustomerAdmin,
    isSubAdmin: true,
  },

  {
    path: "/subadminsettings",
    component: SubSettingsScreen,
    isSubAdmin: true,
  },
  {
    path: "/faqslist",
    component: AllFaq,
    isSubAdmin: true,
  },
  {
    path: "/customers",
    component: CustomerList,
    isSubAdmin: true,
  },
  {
    path: "/services",
    component: SubAdminServices,
    isSubAdmin: true,
  },
  {
    path: "/userservices",
    component: SubAdminServices,
    isUser: true,
  },
  {
    path: "/faqslists",
    component: AllFaq,
    isSubAdmin: true,
  },
  {
    path: "/newfeedback",
    component: AddFeedBack,
    isSubAdmin: true,
  },
  {
    path: "/profiles",
    component: SubAdminProfile,
    isSubAdmin: true,
  },
  {
    path: "/employee",
    component: EmployeeDetails,
    isSubAdmin: true,
  },
  {
    path: "/services/apiformat",
    component: DisplayAPIFormat,
    isSubAdmin: true,
  },
  {
    path: "/services/apirequestlist",
    component: DisplayActiveAPIList,
    isSubAdmin: true,
  },
  {
    path:"/requesteddocumentlist",
    component:DocumentList,
    isAdmin:true,
    isITAdmin: true
  },
  {
    path:"/reports",
    component:Reports,
    isAdmin:true,
    isITAdmin: true
  }

];
