export const appliedRulesHistoryColumnCOA = [
    {
      field: "versionno",
      headerName: "Version",
      flex: 0.1,
    },
    {
      field: "castid",
      headerName: "Cast ID",
      flex: 0.1,
    },
    {
      field: "bundleno",
      headerName: "Bundle Number",
      flex: 0.1,
    },
    
    {
      // id: "1",
      field: "email",
      headerName: "Username",
      flex: 0.3,
    },
    {
      // id: "2",
      // field: "historycreatedat",
      field: "history_created_date",
      headerName: "Created Date",
      flex: 0.25,
    },
    {
      // id: "2",
      field: "ruleName",
      headerName: "Rule Name",
      flex: 0.3,
    },
  ];
  

  export const appliedRulesHistoryColumnInvoice = [
    {
      field: "versionno",
      headerName: "Version",
      flex: 0.1,
    },
    {
      field: "invoiceno",
      headerName: "Invoice No",
      flex: 0.1,
    },
    {
      // id: "1",
      field: "email",
      headerName: "Username",
      flex: 0.3,
    },
    {
      // id: "2",
      // field: "historycreatedat",
      field: "history_created_date",
      headerName: "Created Date",
      flex: 0.25,
    },
    {
      // id: "2",
      field: "ruleName",
      headerName: "Rule Name",
      flex: 0.3,
    },
  ];
  
  
  