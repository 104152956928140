import axios from "axios";
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import toast, { Toaster } from "react-hot-toast";
import env from "../../../Services/Environment";
import "../../../Assets/Styles/User.css";
import { messages } from "../../Messages";
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded';
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
import { getSingleuser } from "../../../Services/AdminServices";
import ImageSlider from "../../Reusable_Components/ImageSlider";
import prd1 from '../../../Assets/Images/alba_prd_1.png';
import prd2 from '../../../Assets/Images/alba_prd_2.png';
import prd3 from '../../../Assets/Images/alba_prd_3.png';
import prd4 from '../../../Assets/Images/alba_prd_4.png';
import { addUserFeedback } from "../../../Services/UserServices";
import { useSelector } from "react-redux";

const customStyle = {
  "& .MuiRating-iconFilled": {
    border: "white",
  },
}

const AddFeedBack = () => {
  const [inputData, setInputData] = useState({
    name: localStorage.getItem("UserName"),
    email: localStorage.getItem("Logindata"),
    feedback_data: "",
    rating: 0,
  });
  const [icon, setIcon] = React.useState(<StarRoundedIcon />);
  const [error, setError] = useState()
  const [emptyIcon, setEmptyIcon] = React.useState(
    <StarRoundedIcon />
  );

  // uam role object start
  const userDetails = useSelector((state) => state)
  const appsubmodulename = "Customer Feedback"
  const appfunctionalityname = "Customer Feedback"
  const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
  const [appObjects, setAppObjects] = useState(
    {
      "Send Feedback": true
    }
  )
  console.log("userDetails", userDetails)

  const updateAppObjects = (arr) => {
    let objectsArray = []
    arr.map((obj) => {
      console.log("updateButtons", obj)
      if (obj.objecttypename == "button") {
        objectsArray.push(obj)
      }
    })
    const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
      acc[appobjectname] = true;
      return acc;
    }, {});
    setAppObjects({ ...appObjects, ...finalObject })
    console.log("objectsArray", objectsArray)
    console.log("finalObject", finalObject)
  }

  useEffect(() => {
    console.log("appObjects", appObjects)
  }, [appObjects])

  useEffect(() => {
    console.log("userRoleObjects", userRoleObjects)
    const filteredList = []
    userRoleObjects.filter((obj) => {
      if (obj.appsubmodulename == appsubmodulename && obj.appfunctionalityname == appfunctionalityname) {
        filteredList.push(obj)
      }
    })

    console.log("filteredList", filteredList)
    updateAppObjects(filteredList)

  }, [])

  // uam role object end

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  const handleRating = (event, value) => {
    const { name } = event.target;
    setInputData({ ...inputData, [name]: value });
  };

  const onSubmitCreateFeedBack = async (e) => {

    e.preventDefault();

    const { name, email, feedback_data, rating } = inputData;

    if (!name || !email || !feedback_data || !rating) {
      toast.error(messages.fillAllFields);

    } else {
      try {
        const response = await addUserFeedback(inputData)
        console.log("response", inputData);
        if (response.data.success == true) {
          toast.success("Thank you for your Feedback");
          setInputData({
            ...inputData,
            [feedback_data]: "", [rating]: 0

          });
        } else {
          toast.error("Something went wrong, try again");
        }
      }
      catch (err) {
        setError(err)
      }

    }
  };

  var email = localStorage.getItem("Logindata");
  const getSingleDataByEmail = async (email) => {
    try {
      const response = await getSingleuser(email)
      localStorage.setItem("UserName", response.data.data[0]._name)
    }
    catch (err) {
      setError(err)
    }
  };

  useEffect(() => {
    getSingleDataByEmail(email);
  }, []);

  const images = [
    prd1, prd2, prd3, prd4
  ]
  const images1 = [
    prd2, prd4, prd1, prd3
  ]
  const images2 = [
    prd4, prd3, prd2, prd1
  ]

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <ErrorCatch err={error} />
      <div className="container-fluid mt-2 px-4">
        <div className="d-flex col-md-12 col-lg-12 col-sm-12">
          <div className="col-md-4 col-lg-4 col-sm-12">
            <ImageSlider images={images} className={'slidecontainer'} />
          </div>
          <div className="col-md-4 col-lg-4 px-2 col-sm-12">
            <ImageSlider images={images1} className={'slidecontainer'} />
          </div>
          <div className="col-md-4 col-lg-4 col-sm-12">
            <ImageSlider images={images2} className={'slidecontainer'} />
          </div>
        </div>
      </div>

      <div className="container-fluid FeedbackForm " style={{ overflow: "hidden" }}>

        <div className="row mt-5 ">
          <div className="d-flex justify-content-center">
            <form>
              <div
                className="shadow p-4"
                style={{
                  backgroundColor: "white",
                  color: "white",
                  // borderRadius: "5%",
                  borderRadius: "20px",
                  border: "2px  solid #c5c3c3",
                  // start of change for email id display fully in Feedback screen
                  width: "83vw",
                  // height:"77%   "
                  // end of change
                }}
              >

                <div className="row mb-3 ">
                  <div className="col">
                    <h6
                      style={{ color: "black" }}
                    >Send Feedback</h6>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6 mb-3">
                    <input
                      type="text"
                      name="name"
                      value={inputData?.name}
                      onChange={(e) => handleInput(e)}
                      className="form-control "
                      placeholder="Name"
                      disabled
                    />
                  </div>

                  <div className="col-md-6">
                    <input
                      type="text"
                      name="email"
                      value={inputData?.email}
                      onChange={(e) => handleInput(e)}
                      className="form-control"
                      placeholder="Email"
                      disabled
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col" >
                    <textarea
                      type="text"
                      name="feedback_data"
                      value={inputData?.feedback_data}
                      onChange={(e) => handleInput(e)}
                      className="form-control"
                      placeholder="Let Us Know Your Feedback"
                      style={{ resize: "none", paddingBottom: "35px" }}
                    ></textarea>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6 mb-3">
                    <div className="d-flex justify-content-start">
                      <Box>
                        {/* <Typography component="legend">Give Rating</Typography> */}
                        <Rating
                          className="feedBack"
                          name="rating"
                          value={inputData?.rating}
                          onChange={(event, newValue) => {
                            handleRating(event, newValue);
                          }}
                        />
                        &nbsp; &nbsp;

                      </Box>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="d-flex justify-content-end">
                      {/* <button
                        type="Cancel"
                        className="btn_feedback"
                        style={{ padding: "5px 40px 4px 35px" }}
                      >
                        Cancel
                      </button> */}
                      {appObjects["Send Feedback"] && <button
                        type="send feedback"
                        onClick={onSubmitCreateFeedBack}
                        className="float-start"
                        style={{ backgroundColor: "#EF3E33", borderRadius: "10px", padding: "2%", border: "rgb(239, 62, 51)" }}
                      >
                        Send Feedback
                      </button>}
                    </div>
                  </div>
                </div>
              </div>

            </form>

          </div>
        </div>
      </div>

    </>
  );
};

export default AddFeedBack;
