import React, { useState, useEffect } from "react";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
const labels = ["January", "February", "March", "April", "May", "June"];

const data = {
  labels: labels,
  datasets: [
    {
      label: "Total API Calls",
      backgroundColor: "#13426B",
      borderColor: "#CC0000",
      data: [0, 10, 5, 2, 20, 30, 45,50,55,60,65],
    },
  ],
};

const options = {
  plugins: {
      legend: {
          labels: {
              color: '#13426B', // Set label text color here
          },
          font: {
            size: '15px', // Set label font size
            weight: 'bold', // Set label font weight
        },
      },
      // scales: {
      //   y: {
      //     beginAtZero: true,
      //     position: 'left', // Adjust y-axis label position
      //   },
      //   x: {
      //     position: 'bottom', // Adjust x-axis label position
      //   }
      // }
  },
};
function ActivityChart() {
  // const [chartData, setChartData] = useState({});
  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <>
      <div>
      <Line data={data} height={"60%"} options={options}/>
      </div>
    </>
  );
}

export default ActivityChart;
