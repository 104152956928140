import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { getSingleuser, insertLogs, updateEmailPreference, updateUserRole } from "../../../Services/AdminServices.jsx";
import Loader from "../../../utils/Loader/Loader.js";
import { Modal } from "antd";
import InputBox from "../../Reusable_Components/InputBox.jsx";
import TextField from '@mui/material/TextField';
import SelectInput from "../../Reusable_Components/SelectInput.jsx";
import RadioButton from "../../Reusable_Components/RadioButton";
import ErrorCatch from "../../Reusable_Components/ErrorCatch.jsx";
import CheckboxComp from "../../Reusable_Components/CheckBox.jsx";
import AWS from 'aws-sdk';
import { getObjectsByRoles } from "../../../Services/UAMservices.jsx";
import { getRoles } from "../../../Services/AdminServices.jsx";
import { useSelector } from "react-redux";
import { cognitoUpdateUserDetails } from "../../../Services/CognitoServices.jsx";

const AssignCustomerRole = ({ data, RoleModal, AssigneRoleModalCancel, AssigneRoleModalOk }) => {
  const userDetails = useSelector((state) => state)
  console.log("userDetails", userDetails, RoleModal)
  // Configure AWS SDK
  // AWS.config.update({ region: process.env.REACT_APP_AWS_COGNITO_REGION })
  AWS.config.update({
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_COGNITO_ACCESS_KEYID,
      secretAccessKey: process.env.REACT_APP_COGNITO_SECRET_ACCESS_KEY
    }
  })
  const cognito = new AWS.CognitoIdentityServiceProvider()

  console.log("data__", data);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [selectedValue, setSelectedValue] = useState(1);
  const [input, setInput] = useState({
    _reason: "",
    // status_id: data._status_id,
    status_id: selectedValue,
    _user_type: "customer-admin"
  });
  //const [input, setInput] = useState({});
  const [list, setList] = useState([{ "value": "1", "label": "Admin", "status": true }, { "value": "2", "label": "User", "status": false }])
  // adding for radiobutton
  const [statusList, setStatusList] = useState([
    {
      label: "Customer Admin",
      value: 1,
      stringValue: "customer-admin",
      status: true
    },
    {
      label: "Customer User",
      value: 2,
      stringValue: "customer-user",
      status: false
    }
  ])
  const [isEmailEnabled, setIsEmailEnabled] = useState(null)
  // ends here

  const [role, setRole] = useState("");
  const [roleName, setRoleName] = useState("");

  // const rolesList = [{ value: "admin", label: "Admin" }, { value: "user", label: "User" }]
  const [roleList, setRoleList] = useState([])

  const getSingleDataByEmail = async (email) => {
    setLoading(true)
    // console.log("email",email);
    try {
      let response = await getSingleuser(email)
      console.log("respo__", response);
      response.data.data[0]["_status_reason"] = ""
      response.data.data[0]["_user_type"] = "customer-admin"
      let responseData = response.data.data[0]
      setInput(responseData);
      setIsEmailEnabled(responseData._is_email_enabled)
    }
    catch (err) {
      setError(err)
    }
    setLoading(false)
  };
  //for dropdown
  useEffect(() => {
    console.log("rolemodal", RoleModal)
    if (RoleModal) {
      // setInput({ ...input, ["_status_reason"]: "", ["_user_type"]: "admin" });
      setSelectedValue(1)
      setStatusList([
        {
          label: "Customer Admin",
          value: 1,
          stringValue: "customer-admin",
          status: true
        },
        {
          label: "Customer User",
          value: 2,
          stringValue: "customer-user",
          status: false
        }
      ])
    }


  }, [RoleModal])

  useEffect(() => {
    // setInput({ ...input, ["_status_reason"]: "", ["_user_type"]: "admin" });
    setSelectedValue(1)
    setStatusList([
      {
        label: "Customer Admin",
        value: 1,
        stringValue: "customer-admin",
        status: true
      },
      {
        label: "Customer User",
        value: 2,
        stringValue: "customer-user",
        status: false
      }
    ])
  }, [])

  useEffect(() => {
    if (undefined !== data) {
      getSingleDataByEmail(data._email);
      getRoleList()
    }
  }, [data]);

  useEffect(() => {
    initialRoleSet()
  }, [roleList])

  const initialRoleSet = () => {
    if (roleList.length > 0) {
      let tempData = roleList.filter(obj => obj.name == data?._user_type)
      console.log("tempData", tempData)
      setRole(tempData[0].value)
      setRoleName(tempData[0].name)
    }
  }

  useEffect(() => {
    console.log("tempDatarole", role)

  }, [role])

  // starts here
  const handleRadioButton = (event) => {
    console.log("handleradio", event, data)
    let value = event.target.value
    console.log("item", statusList, value)

    let updatedStatusList = statusList.map((item) => {
      if (item.value == value) {
        item.status = true
        setInput({ ...input, ["_user_type"]: item.stringValue });
      }
      else {
        item.status = false
      }
      return item

    })
    console.log("updatedStatusList", updatedStatusList)
    setSelectedValue(value)
    setStatusList(updatedStatusList)
  }
  // ends here

  const handleEmailEnabled = (e) => {
    setIsEmailEnabled(e.target.checked)
  }

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    console.log("value_ll", name, value)

    // let final = list.map(item => {
    //   if (item.value == value) {
    //     item.status = true
    //   } else {
    //     item.status = false
    //   }
    //   return item
    // })
    // setList(final)
    console.log("value", value)
    setInput({ ...input, [name]: value });
    // console.log("input__", {...input, [name]: value})
    // setSelectedValue(value)
  };


  // const CloseModal = () => {
  //   setTimeout(() => {
  //     AssigneRoleModalCancel()
  //   }, 2000);

  // }

  const updateEmailEnabled = async () => {
    try {
      let { _email } = input
      let sendData = { _email, isEmailEnabled }
      const response = await updateEmailPreference(sendData)
      console.log("response", response)
    }
    catch (err) {
      console.log("err", err)
      setError(err)
    }
  }

  // --- frontend ---
  const cognitoUpdateUserDetailsfun = async () => {
    const { _email, _user_type } = input
    const params = {
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      Username: _email,
      UserAttributes: [
        {
          Name: 'custom:role',
          // Value: _user_type,
          Value: roleName
        },
      ]
    }
    setLoading(true)
    try {
      const response = await cognito.adminUpdateUserAttributes(params).promise();
      console.log('cognitoUpdateUserDetailsResponse', response);
      UpdateUserRole()
    } catch (error) {
      console.error('cognitoUpdateUserDetailsError', error);
      toast.error(error.message)
      // setError(error)
    }
    setLoading(false)
  }

  const insertLogsForUser = async () => {
    let { email: loginUserEmail, user_id, name: loginUserName, comp_id, comp_name } = userDetails.auth.userDetails
    setLoading(true)
    try {
      let payload = {
        userId: user_id,
        userName: loginUserName,
        email: loginUserEmail,
        compId: comp_id,
        compName: comp_name,
        actionType: "UserType",
        soNo: "",
        fileType: "",
        docType: "",
        profileName: data._name,
        title: data._title,
        designation: data._designation,
        department: data._department,
        // userType: data._user_type,
        userType: roleName,
        phoneNo: data._phone,
        address: data._address,
      }
      console.log("userDetailsLog_", payload);
      let logresponse = await insertLogs(payload)
      console.log("logresponse_", logresponse);
    }
    catch (err) {
      console.log("logresponseErr", err);
      setError(err)
    }
    setLoading(false)
  }

  // --- backend ---
  const updateUserDetailsCognito = async () => {
    const { _email, _user_type } = input
    const params = {
      _email: _email,
      userAttributes: [
        {
          Name: 'custom:role',
          // Value: _user_type,
          Value: roleName
        },
      ]
    }
    setLoading(true)
    try {
      const response = await cognitoUpdateUserDetails(params)
      console.log('cognitoUpdateUserDetailsResponse', response);
      UpdateUserRole()
      insertLogsForUser()

    } catch (error) {
      console.error('cognitoUpdateUserDetailsError', error);
      toast.error(error.message)
      // setError(error)
    }
    setLoading(false)
  }

  // Example usage
  // updateUserDetails('user@example.com', [
  //     { name: 'custom:role', value: 'admin' },
  //     { name: 'address', value: '123 Main St' },
  // ]);


  const UpdateUserRole = async () => {

    let adminEmailValue = localStorage.getItem("Logindata");

    const { _email, _status_datetime, _status_id, _status_reason, _user_type } =
      input;
    console.log("input", input)
    if (_status_reason == "") {
      toast.error("Please enter reason");
      return
    }
    else {
      let sendData = {
        email: _email,
        status_datetime: _status_datetime,
        status_reason: _status_reason,
        status_id: _status_id,
        // adding for testing
        // user_type: _user_type,
        user_type: roleName,
        // user_type:
        adminEmail: adminEmailValue,
      }
      setLoading(true)
      try {
        let response = await updateUserRole(sendData)
        if (response.data.success == true) {
          // toast.success("Role assigned successfully.");
          toast.success("Updated successfully.");
          console.log("updateuserroleresponse", response)
          updateEmailEnabled()
          setInput("");
        }
        else {
          toast.error(response.data.msg)
          setInput("");
        }
      }
      catch (err) {
        setError(err)
      }
    }
    AssigneRoleModalOk()
    setLoading(false)
  }

  const getRoleList = async () => {
    try {
      const response = await getRoles();
      console.log("getRoleList", response);
      let { result } = response.data
      let finalArr = []

      result.map((obj) => {
        if (data._user_type == "customer-admin" || data._user_type == "customer-user") {
          if (obj.role_name == "customer-admin" || obj.role_name == "customer-user") {
            finalArr.push({
              name: obj.role_name,
              value: obj.role_id
            })
          }
        }
        else if (data._user_type == "super-admin" || data._user_type == "super-user" || data._user_type == "IT-Admin") {
          if (obj.role_name == "super-admin" || obj.role_name == "super-user" || obj.role_name == "IT-Admin") {
            finalArr.push({
              name: obj.role_name,
              value: obj.role_id
            })
          }
        }

      })
      setRoleList(finalArr);

    } catch (error) {
      console.log("error", error);
      setError(error)
    }
  }

  const handleRoleName = (e) => {
    const { name, value } = e.target
    console.log("handleRoleName", value)
    setRole(value)
    let data = roleList.filter(obj => obj.value == value)
    console.log("exRoleData", data)
    setRoleName(data[0].name)
  }

  return (
    <>
      <Loader loading={loading} />
      <Toaster position="top-center" reverseOrder={false} />
      <ErrorCatch err={error} />
      <div className="container">
        <div className=" d-flex justify-content-center">
          <Modal
            RoleModal={RoleModal}
            open={RoleModal}
            maskClosable={false}
            onOk={AssigneRoleModalOk}
            onCancel={AssigneRoleModalCancel}
            footer={null}
            // style={{ top: 20, minWidth: 300, maxWidth: 600 }}
            style={{ top: 20, width: "70%" }}
          >

            <div className="row d-flex justify-content-center ">
              <div className="col">
                <div className="d-flex justify-content-center gap-3">
                  <h6 className="text-dark text-center heading-popup">
                    Modify Role
                  </h6>
                </div>

                {/* <hr className="text-dark" /> */}
                <div className="form mt-4">
                  <div className="row">
                    <div className="col">
                      {/* <input
                        type="text"
                        name="name"
                        onChange={handleChange}
                        class="form-control input-text-box"
                        placeholder="Name"
                        value={input?._name}
                        disabled={true}
                      /> */}

                      <InputBox
                        fullWidth
                        // id="outlined-disabled"
                        value={`${input?._name}`}
                        label="Name"
                        // className="input-text-box"
                        // onChange={handleChange}
                        disabled
                      />

                      {/* <TextField
                        required
                        disabled
                        id="outlined-required"
                        label="Required"
                        value={`${input?._name}`}
                      /> */}

                    </div>
                  </div>

                  <div className="row mt-4 mb-3">
                    <div className="col-md-12">
                      {/* <input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        class="form-control input-text-box"
                        placeholder="Email"
                        value={input?._email}
                        disabled={true}
                      /> */}

                      <InputBox
                        label="Email"
                        fullWidth
                        // className="input-text-box"
                        // onChange={handleChange}
                        value={`${input?._email}`}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mt-3 mb-3">
                      {/* <input
                        type="text"
                        name="designation"
                        onChange={handleChange}
                        class="form-control input-text-box"
                        placeholder="Designation"
                        value={input?._designation}
                        disabled={true}
                      /> */}

                      <InputBox
                        fullWidth
                        label="Designation"
                        // name="designation"
                        // onChange={handleChange}
                        value={`${input?._designation}`}
                        disabled={true}
                      />

                    </div>

                    <div className="col-md-6 mt-3 mb-3">
                      {/* <input
                        type="text"
                        name="department"
                        onChange={handleChange}
                        class="form-control input-text-box"
                        placeholder="Department"
                        disabled={true}
                        value={input?._department}
                      /> */}

                      <InputBox
                        fullWidth
                        label="Department"
                        name="department"
                        onChange={handleChange}
                        value={`${input?._department}`}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mt-3 mb-3">
                      {/* changing dropdown to radiobutton  */}
                      {/* <SelectInput
                        selectLabel="Role"
                        name="_user_type"
                        onChange={handleChange}
                        value={`${input?._user_type}`}
                        optionsList={rolesList}
                      /> */}
                      {console.log("status_id", input.status_id)}
                      <div>
                        {/* <RadioButton
                          // radioButtonTitle="Status"
                          optionsList={statusList}
                          selectedValue={selectedValue}
                          handleChange={(event) => handleRadioButton(event)}
                        /> */}

                        <SelectInput
                          margin="0"
                          selectLabel="*Role"
                          name="_role"
                          value={role}
                          onChange={handleRoleName}
                          optionsList={roleList}
                        />

                      </div>
                      {/* <select
                        name="_user_type"
                        class="form-select input-text-box"
                        onChange={handleChange}
                      >
                        <option> select</option>
                        <option
                          value={"user"}
                          selected={input?._user_type === "user" ? true : false}
                        >
                          User
                        </option>
                        <option
                          value={"admin"}
                          selected={input?._user_type === "admin" ? true : false}
                        >
                          Admin
                        </option>
                      </select> */}

                    </div>

                    {/* <div className="col-md-6 mt-3 mb-3" >
                      <button className="btn"> 
                      {input?._status_id == 1 ? (
                        <a class="btn active-disabled w-100 disabled">Active</a>
                      ) : input?._status_id == 2 ? (
                        <a class="btn btn-secondary w-100 disabled">Inactive</a>
                      ) : input?._status_id == 3 ? (
                        <a class="btn btn-warning w-100 disabled">Pending</a>
                      ) : input?._status_id == 4 ? (
                        <a class="btn btn-danger w-100 disabled">Rejected</a>
                      ) : ""
                      }
                      </button>
                    </div> */}

                    <div className="col-md-6 mt-3 mb-3" >
                      {input?._status_id == 1 ? (
                        <a class="btn div-fill active-disabled w-100 disabled">Active</a>
                      ) : input?._status_id == 2 ? (
                        <a class="btn btn-secondary w-100 disabled">Inactive</a>
                      ) : input?._status_id == 3 ? (
                        <a class="btn btn-warning w-100 disabled">Pending</a>
                      ) : input?._status_id == 4 ? (
                        <a class="btn btn-danger w-100 disabled">Rejected</a>
                      ) : ""
                      }
                    </div>
                  </div>

                  {/* status_reason,status_id */}

                  <div className="row">
                    <div className="col-md-6">
                      {isEmailEnabled !== null &&
                        <CheckboxComp
                          label="Email Enabled"
                          isChecked={isEmailEnabled}
                          onChange={handleEmailEnabled}
                        />}
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-12">
                      <InputBox
                        fullWidth
                        multiline
                        rows={2}
                        label="*Reason"
                        name="_status_reason"
                        onChange={handleChange}
                        value={input?._status_reason}
                      />

                      {/* <textarea
                        type="text"
                        name="_status_reason"
                        onChange={handleChange}
                        class="form-control input-text-area"
                        placeholder=" Status Reason"
                        value={input?._status_reason}
                      /> */}

                    </div>
                  </div>

                  <div className="pt-2 mt-3 d-flex justify-content-center">
                    <button
                      className="btn btn_submit"
                      // onClick={(e) => UpdateUserRole(e)}
                      onClick={updateUserDetailsCognito}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default AssignCustomerRole;
