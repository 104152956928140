// import * as React from 'react';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import dayjs from "dayjs";
// import TextField from '@mui/material/TextField';

// const theme = createTheme({
//   typography: {
//     color: "#646464",
//     fontFamily: "Montserrat, sans-serif",
//     fontWeight: "500",
//     fontSize: "14px",
//   },
//   components: {
//     MuiTextField: {
//       styleOverrides: {
//         root: {
//           fontWeight: "500",
//           fontSize: '15px',
//           borderStyle: "none",
//           boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
//           transition: "box-shadow 0.3s",
//         },
//       },
//     },
//     MuiInputBase: {
//       styleOverrides: {
//         root: {
//           // borderRadius: '8px',
//           border: "0px 3px 10px rgba(0, 0, 0, 0.6)",
//           boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Customize box shadow
//         },
//       },
//     },
//     MuiInput: {
//       styleOverrides: {
//         root: {
//           fontWeight: "500",
//           fontSize: "15px",
//           color: '#6E6E6E',
//         },
//       },
//     },
//     MuiInputLabel: {
//       styleOverrides: {
//         root: {
//           fontFamily: "Montserrat, sans-serif",
//           fontWeight: "500",
//           fontSize: '14px',
//           color: '#6E6E6E',
//         },
//       },
//     },
//     MuiOutlinedInput: {
//       styleOverrides: {
//         root: {
//           '&:focus': {
//             borderColor: '#6E6E6E',
//             boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)",
//           },
//           '& .MuiOutlinedInput-notchedOutline': {
//             borderColor: '#646464', // change border color here
//           },
//           '&:hover .MuiOutlinedInput-notchedOutline': {
//             borderColor: '#646464', // change hover border color here
//           },
//           '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//             boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
//             transition: 'box-shadow 0.3s',
//           },

//         },
//       },
//     },
//   },
// });

// export default function SelectDate({
//   value, onChange, label, minDate, disabled
// }) {
//   return (
//     <ThemeProvider theme={theme}>
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <DemoContainer components={['DatePicker']}>
//           <DatePicker
//             // sx={{
//             //   borderColor: "#6E6E6E",
//             //   boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)",
//             // }}
//             value={value}
//             onChange={onChange}
//             label={label}
//             minDate={minDate}
//             disabled={disabled}
//             // format="DD-MM-YYYY"
//             format="DD/MM/YYYY"
//             slotProps={{ textField: { size: "small", fullWidth: true } }}
//           // renderInput={(params) => <TextField {...params} InputProps={{...params.InputProps, style: { borderColor: '#646464', boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', transition: 'box-shadow 0.3s' }}} />}
//           />
//         </DemoContainer>
//       </LocalizationProvider>
//     </ThemeProvider>
//   );
// }


// ----------------------------------------------

import * as React from "react";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";

const theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-input': {
                        color: '#646464',
                        border: 'none',
                        // boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
                        // transition: 'box-shadow 0.3s',
                        //   fontFamily: 'Montserrat, sans-serif',
                        fontFamily: "Nunito Sans, sans-serif",
                        fontWeight: '500',
                        fontSize: '14px',
                        '&:focus': {
                            color: '#646464',
                            outline: 'none',
                            boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)',
                        },
                    },
                    '& .MuiInputLabel-root': {
                        color: '#646464',
                        // fontFamily: 'Montserrat, sans-serif',
                        fontFamily: "Nunito Sans, sans-serif",
                        fontWeight: "500",
                        fontSize: '14px',
                        "&.Mui-focused": {
                            color: "#6E6E6E",
                        },
                        // '&:focus': {
                        //     color: '#646464',
                        // },
                    },
                    '& .MuiInputBase-multiline': {
                        // boxShadow: "none"
                        // minHeight: '10px', // Set a minimum height for multiline
                    },

                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    // borderColor: '#646464', // Change the outline border color
                    // borderWidth: '1px', // Add a border width
                    '&:focus': {
                        // borderColor: '#646464',
                    },
                },
                input: {
                    '&:focus': {
                        boxShadow: "none !important"
                    }
                },
                inputSingle: {
                    // border: 'none', // Remove the inner border for single-line input
                    // borderRadius: '8px', // Add border radius
                    // padding: '10px', // Add padding
                },
                inputMultiline: {
                    border: 'none', // Remove the inner border for multiline input
                    // borderRadius: '8px', // Add border radius
                    // padding: '10px', // Add padding
                    // padding: "none"
                    boxShadow: "none"
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
                    transition: 'box-shadow 0.3s',
                }
            }
        }
    },
});

export default function SelectDate({ fullWidth, label, disabled, onChange, value, defaultValue, minDate, maxDate }) {

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TextField
                    fullWidth={fullWidth}
                    disabled={disabled}
                    onChange={onChange}
                    id="date"
                    label={label}
                    type="date"
                    size="small"
                    value={value}
                    defaultValue={defaultValue}
                    // minDate={minDate}
                    dateFormat="dd-MM-yyyy"
                    // defaultValue={""}
                    // sx={{
                    //   // width: 220,
                    //   "& input": { color: "pink" },
                    //   borderColor: "#6E6E6E",
                    //   boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)"
                    // }}
                    InputLabelProps={{
                        shrink: true
                    }}
                    inputProps={{
                        min: minDate,
                        max: maxDate
                    }}
                />
            </LocalizationProvider>
        </ThemeProvider>
    );
}
