import { DataGrid } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Input, Modal, Button } from "antd";
import { getDeActiveCompony } from "../../../Services/AdminServices";
import { IoMdArrowDropdown } from 'react-icons/io'
import Warning from "./Warnigng";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";

const DeactiveCompony = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [userRoute, setUserRoute] = useState(false);
  const [params, setParams] = useState([]);
  const [companydetails, setCompanyDetails] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error,setError] = useState()

  const showModal = (p) => {
    setIsModalOpen(true);
    // console.log("188",p)
    setCompanyDetails(p)

  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const { Search } = Input;

  const ActiveCompany = async () => {
    try{
      await getDeActiveCompony().then((res) => {
        let response = res.data.data;
        response.map((item) => {
          if (item.company_status_id === 1) {
            item.company_status_id = "Active";
          } else if (item.company_status_id === 2) {
            item.company_status_id = "Deactive";
          }
          return item;
        });
  
  
        setData([...response]);
      });
    }
    catch(err){
      setError(err)
    }
  };

  useEffect(() => {
    ActiveCompany();
  }, [companydetails]);



  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const actionColumn = [
    {
      headerClassName: "super-app-theme--header",
      field: "action1",
      headerName: "Change Status",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <div class="">
              <button className="btn btn-sm  btn-danger" onClick={() => { showModal(params.row) }}>Deactive <IoMdArrowDropdown /></button>

            </div>

          </>


        );
      },
    },
    // {
    //   headerClassName: "super-app-theme--header",
    //   field: "action2",
    //   headerName: "Active/Deactive",
    //   width: 200,
    //   renderCell: (params) => {
    //     return (
    //       <div class="">
    //         {/* form-check form-switch */}
    //         <select
    //           class="form-select"
    //           onChange={(event) => handleActiveDeactiveStatus(params, event)}
    //         >
    //           <option
    //             value={1}
    //             // disabled={true}
    //             selected={
    //               params.row.status_id === 1 ? true : false
    //             }
    //           >
    //             {"Approve"}
    //           </option>

    //           <option
    //             value={2}
    //             selected={
    //               params.row.status_id === 2 ? true : false
    //             }
    //           >
    //             {"Reject"}
    //           </option>
    //         </select>
    //       </div>
    //     );
    //   },
    // },
  ];

  const NavigateComonay = (e) => {
    console.log(e.target.value);

    navigate(e.target.value);
  };
  const handleChangeCheckbox = (params) => {
    // console.log("handle check box", params);
    let colDef = params.field;
    let data = params.row;
    if (colDef === "action") {
      setParams(data);
      setUserRoute(true);

    }
  };

  const userColumns = [
    {
      id: "1",
      field: "user_id",
      headerName: "User Id",
      // width: 80,
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    {
      id: "7",
      field: "comp_name",
      headerName: "Company Name",
      // width: 150,
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },

    {
      id: "8",
      field: "company_status_id",
      headerName: "company Status",
      // width: 150,
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },

    {
      id: "2",
      field: "name",
      headerName: "Name",
      // width: 150,
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },

    {
      id: "3",
      field: "email",
      headerName: "Email",
      // width: 200,
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },

    {
      id: "4",
      field: "phone",
      headerName: "Phone",
      // width: 120,
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },

    {
      id: "5",
      field: "address",
      headerName: "Address",
      // width: 200,
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
  ];

  return (
    <>
    <ErrorCatch err={error}/>
      <div className="container">
        <div className="col-md-12">
          <div className="row justify-content-between mt-3 p-2">
            <div className="col-sm-8">
              <div className="card bg-transparent" style={{ border: "none" }}>
                <h6 className="px-3 mt-3">
                  <b className="mt-5 ">Requested Company List</b>
                </h6>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="card bg-transparent">
                <select
                  className="form-select text-center"
                  onChange={(e) => NavigateComonay(e)}
                >
                  <option value={"/requested-company"}>
                    <h5 className="text-dark">
                      <b>Requested Company</b>
                    </h5>
                  </option>
                  <option value={"/activecompany"}>
                    <h5 className="text-dark">
                      <b>Active Company</b>
                    </h5>
                  </option>
                </select>
              </div>
            </div>

          </div>

          {data.length > 0 ? (
            <div className="datatable col-md-11 mt-3 px-2"
            // style={{ height: "28rem", width: "100%", overflowY: "auto" }}
            >
              <Box sx={{ height: "62vh", width: "100%", overflowY: "auto" }}>
                <DataGrid
                  rowHeight={30}
                  sx={{
                    borderColor: "secondary",
                    "& .MuiDataGrid-cell:hover": {
                      color: "primary.main",
                    },
                    "& .MuiDataGrid-columnHeader": {
                      backgroundColor: "#0E3386",
                      fontSize: "12px",
                      color: "white",
                      padding: "10px",

                      // Set your desired header background color
                    },
                    "& .MuiDataGrid-cell": {
                      fontSize: "10px",
                      padding: "10px",
                      borderRight: "2px solid #e0e0e0",
                      // Adjust the font size as needed
                      // Adjust the cell padding as needed
                    },
                  }}
                  // getRowId={(data) => data._email}
                  className="datagrid"
                  rows={data}
                  columns={userColumns.concat(actionColumn)}
                  // columns={userColumns}
                  pageSize={9}
                  onCellClick={(params) => handleChangeCheckbox(params)}
                  rowsPerPageOptions={[9]}
                  getRowId={(row) => generateRandom()}
                  checkboxSelection={false}
                  hideFooter
                />
              </Box>
            </div>
          ) :
            <div className="container">
              <div className="row justify-content-center">
                <div className="col d-flex justify-content-center"> No Rows to show</div>
              </div>
            </div>
          }

          <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
            <Warning companydetails={companydetails} handleCancel={handleCancel} />
          </Modal>
        </div>
      </div>
    </>
  );
};

export default DeactiveCompony;
