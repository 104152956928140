import {
  FaTh,
  FaUserAlt,
  FaThList,
  FaCommentAlt,
  FaAngleRight,
  FaAngleLeft,
  FaRegBuilding,
  FaCog,
  FaUserTag,
  FaUserClock
} from "react-icons/fa";
import { VscFeedback } from "react-icons/vsc";
import { BsActivity } from "react-icons/bs";
import { FiUserPlus } from "react-icons/fi";
import { NavLink, useLocation } from "react-router-dom";
import "../../../App.css";
import { useState } from "react";
import headerlogo from "../../../Assets/Images/alba_header.png";
import albalogo from "../../../Assets/Images/alba.png";
import AdminHeader from "../Admin/Header";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import UserDashboard from "../Users/UserDashboard";

const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [heading, setHeading] = useState("")
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();
  const location = useLocation();

  // uam role object start
  const userDetails = useSelector((state) => state)
  const appsubmodulename = "Services"
  // const appfunctionalityname = "Customer Feedback"
  const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
  const [appObjects, setAppObjects] = useState(
    {
      "Dashboard": false,
      "Register User": false,
      "User": false,
      "Services": false,
      "FAQ": false,
      "Company": false,
      "Feedback": false,
      "Settings": false,
      "Profile": false,
      "Logs": false,
      "User Logs": false,
      "API Logs": false,
      "APIs": false,
      "Rules": false,
      "Document": false,
      "Reports":true
    }
  )
  console.log("userDetails", userDetails)

  const updateAppObjects = (arr) => {
    let objectsArray = []
    arr.map((obj) => {
      console.log("updateButtons", obj)
      if (obj.objecttypename == "page") {
        objectsArray.push(obj)
      }
    })
    const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
      acc[appobjectname] = true;
      return acc;
    }, {});
    setAppObjects({ ...appObjects, ...finalObject })
    console.log("objectsArray", objectsArray)
    console.log("finalObject", finalObject)
  }

  useEffect(() => {
    console.log("appObjects", appObjects)
  }, [appObjects])

  useEffect(() => {
    console.log("userRoleObjects", userRoleObjects)
    const filteredList = []
    userRoleObjects.filter((obj) => {
      // if (obj.appsubmodulename !== appsubmodulename) {
      filteredList.push(obj)
      // }
    })

    console.log("filteredList", filteredList)
    updateAppObjects(filteredList)

  }, [])

  // uam role object end

  const menuItem = [
    {
      path: "/admindashboard",
      name: "Dashboard",
      icon: <FaTh />,
      isSuperUser: true,
      isAccess: appObjects["Dashboard"]
    },
    {
      path: "/registeruser",
      name: "Register",
      icon: <FiUserPlus />,
      isSuperUser: false,
      isAccess: appObjects["Register User"]
    },
    {
      path: "/customerlist",
      name: "User",
      icon: <FaUserAlt />,
      setSubMenus: true,
      isSuperUser: false,
      isAccess: appObjects["User"]
    },
    {
      path: "/service",
      name: "Services",
      icon: <FaThList />,
      isSuperUser: true,
      isAccess: appObjects["Services"]
    },
    {
      path: "/addfaq",
      name: "FAQ",
      icon: <FaCommentAlt />,
      isSuperUser: true,
      isAccess: appObjects["FAQ"]
    },
    {
      path: "/activecompany",
      name: "Company",
      icon: <FaRegBuilding />,
      isSuperUser: true,
      isAccess: appObjects["Company"]
    },

    {
      path: "/feedbacklist",
      name: "Feedback",
      icon: <VscFeedback />,
      isSuperUser: true,
      isAccess: appObjects["Feedback"]
    },
    // {
    //   path:"/role",
    //   name:"Role",
    //   icon:<FaUserTag/>
    // },
    {
      path: "/adminsettings",
      name: "Settings",
      icon: <FaCog />,
      isSuperUser: true,
      isAccess: appObjects["Settings"]
      // SubMenu: true,
      // SubMenus: [
      //   { name: "Profile", path: "/myprofile" },
      //   { name: "Logs", path: "/myprofile" },
      // ],
    },
    {
      path: "/logs",
      name: "Logs",
      icon: <BsActivity />,
      isSuperUser: false,
      isAccess: appObjects["Logs"]
    },
    {
      path: "/reports",
      name: "Usage",
      icon: <FaUserClock />,
      isSuperUser: false,
      isAccess: appObjects["Reports"]
    },

  ];

  const [menuItem2, setMenuItem2] = useState([])

  useEffect(() => {
    let { role } = userDetails.auth
    let finalArr = []
    // if (role == "super user") {
      menuItem.filter((obj) => {
        // if (obj?.isSuperUser == true) {
        //   finalArr.push(obj)
        // }
        if (obj?.isAccess == true) {
          finalArr.push(obj)
        }
      })
      console.log("sidebarfinalArr",finalArr)
      setMenuItem2(finalArr)
    // }
    // else if (role == "super-admin") {
    //   setMenuItem2(menuItem)
    // }
  }, [appObjects])

  useEffect(() => {
    console.log("header_", location.pathname)
    if (location.pathname == '/admindashboard') {
      setHeading('Dashboard')
    } else if (location.pathname == '/registeruser') {
      setHeading('Register User')
    } else if (location.pathname == '/customerlist') {
      setHeading('User List')
    } else if (location.pathname == '/service') {
      setHeading('Services')
    } else if (location.pathname == '/addfaq') {
      setHeading('FAQ')
    } else if (location.pathname == '/activecompany') {
      setHeading('Company')
    } else if (location.pathname == '/feedbacklist') {
      setHeading('Feedback')
    } else if (location.pathname == '/adminsettings') {
      setHeading('Account Settings')
    } else if (location.pathname == '/logs') {
      setHeading('Logs')
    } else if (location.pathname == '/reports') {
      setHeading('Usage')
    }
  }, [location])

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsOpen(false); // Close sidebar for smaller screens
    } else {
      setIsOpen(true);
    }
  }

  useEffect(() => {
    console.log("widthtest_", window.innerWidth)
    window.addEventListener('resize', handleResize)
  }, [isOpen])

  return (
    <>
      <div className="admincontainer">
        <div className="Sidebar" style={{ width: isOpen ? "277px" : "80px" }}>
          <div className="top_Section">
            {isOpen ?
              <h1 className="logo">
                <img src={headerlogo} onClick={toggle} alt="logo" className="img-fluid logoimg" />
              </h1> :
              <h1 className="logo">
                <img src={albalogo} onClick={toggle} alt="logo" className="img-fluid logoimgsmall" />
              </h1>
            }
          </div>
          {/* <hr className="hrrow" /> */}
          <div className="sidebar-content">
            <div
              className="bars p-0"
              style={{ justifyContent: isOpen ? 'end' : 'center' }}
            >
              {isOpen ? (
                <FaAngleLeft onClick={toggle} />

              ) : (
                <FaAngleRight onClick={toggle} />
              )}
            </div>
            {menuItem2.map((item, index) => {
              if (item.SubMenu === true) {
                return (
                  <>
                    <div class="settingsdropdown">
                      <div className="d-flex justify-content-center px-3">
                        <div className="icon">{item.icon}</div>
                        <text
                          className="link_text px-3"
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          {item.name}
                        </text>
                      </div>
                      <div class="settings-dropdown-options px-4">
                        {item.SubMenus.map((option) => {
                          return <a href={option.path}>{option.name}</a>;
                        })}
                      </div>
                    </div>
                  </>
                );
              } else {
                return (
                  <>
                    <NavLink
                      to={item.path}
                      key={index}
                      className="link "
                      activeclassname="active"
                    >
                      <div className="icon">{item.icon}</div>
                      <div
                        className="link_text"
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        {item.name}
                      </div>
                    </NavLink>
                  </>
                );
              }
            })}
          </div>
        </div>

        <main>
          <div className="header">
            <AdminHeader heading={heading} />
          </div>
          <div className="content">{children}</div>
        </main>

      </div>
    </>
  );
};

export default SideBar;
