export const apiColumn = [
    {
      headerClassName: "super-app-theme--header",
      // field: "_api_id",
      field: "access_api_logs_id",
      headerName: "API ID",
      // width: 100,
      flex: 0.2,
    },
    {
      // field: "_api_name",
      field: "api_name",
      headerClassName: "super-app-theme--header",
      headerName: "API Name",
      // width: 220,
      flex: 0.5,
    },
    {
      // field: "_app_name",
      field: "app_name",      
      headerClassName: "super-app-theme--header",
      headerName: "App Name",
      // width: 230,
      flex: 0.5,
    },
    {
      // field: "_requestor_name",
      field: "requestor_name",      
      headerClassName: "super-app-theme--header",
      headerName: "Requestor",
      // width: 230,
      flex: 0.5,
    },

    {
      // field: "_email",
      field: "email",
      headerClassName: "super-app-theme--header",
      headerName: "Email",
      // width: 300,
      flex: 0.5,
    },
]