import React from 'react';
import { Tabs } from 'antd';
import "antd/dist/reset.css";
import "./antdTab.css";
const { TabPane } = Tabs;

function TabsAntd({ defaultActiveKey, items, onChange, height }) {
  return (
    <Tabs
      defaultActiveKey={defaultActiveKey}
      centered
      onChange={onChange}
      //   accessKey={tab}
      //   type="card"
      className='custom-tabs'
      style={{
        width: "100%",
        height: height == "" ? "80vh" : height,
        fontFamily: 'Nunito Sans, sans-serif',
        fontWeight: '500',
        fontSize: '14px',
        color: '#646464',
        padding: '0px 10px'
      }}
    >
      {items.map((item) => (
        <TabPane tab={item.label} key={item.key}>
          {item.children}
        </TabPane>
      ))}
    </Tabs>
  );
}

export default TabsAntd;
