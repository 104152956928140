import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../Assets/Styles/FAQ.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import env from "../../../Services/Environment";
import toast, { Toaster } from "react-hot-toast";
import { allFaqList, searchFaq } from "../../../Services/AdminServices";
import Loader from "../../../utils/Loader/Loader";
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { messages } from "../../Messages";
import CustomAccordion from "../../Reusable_Components/CustomAccordion";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";

const AllFaq = ({ faqData, setFAQData, setIsEdit,setFaqAnswer,setFaqQuestion,setFaqId }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [expanded, setExpanded] = React.useState(false);
  const [searchInput, setSearchInput] = useState({
    search: "",
  });
  const [tags, setTags] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSearch = async (e) => {
    const { name, value } = e.target;
    setSearchInput({
      ...searchInput, [name]: value
    });
    let sendData = {
      "search": value
    }
    try {
      const response = await searchFaq(sendData)
      setFAQData(response.data.data.rows);
    } catch (error) {
      console.log(error);
      setError(error)
    }
  };

  let initialValue = {
    feedback_data: "",
    rating: 0,
  };

  const [inputData, setInputData] = useState(initialValue);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };

  const handleRating = (event, value) => {
    const { name } = event.target;
    setInputData({ ...inputData, [name]: value });
  };

  const onSubmitCreateFeedBack = async (e) => {
    e.preventDefault();
    let getEmail = localStorage.getItem("Logindata");
    inputData.email = getEmail;
    const { email, feedback_data, rating } = inputData;

    if (!email || !feedback_data || !rating) {
      toast.error(messages.fillAllFields);

    } else {
      try {
        const response = await axios.post(
          `${env.Backend_Url}/create-faq-eedback`,
          inputData
        );
        if (response.data.success == true) {
          toast.success("Feedback sent successfully.");
          setInputData(initialValue);
        }
      }
      catch (err) {
        setError(err)
      }
    }
  };

  const handleEdit = async (id) => {
    console.log("edit function")
    let FilterData = faqData.filter(obj => {
      return obj._id == id
    })
    console.log("FilterData_",FilterData)
    setFaqQuestion(FilterData[0]._question)
    setFaqAnswer(FilterData[0]._answer)
    setFaqId(id)
    setIsEdit(true)
  }

  return (
    <>
      <Loader loading={loading} />
      <Toaster position="top-center" reverseOrder={false} />
      <ErrorCatch err={error} />
      <div className="container-fluid" >
        <div className="row mt-3">

          <div className="col-md-12">
            <Paper
              component="form"
              sx={{ display: 'flex', alignItems: 'center', borderRadius: 10 }}
            >

              <input
                type="text"
                name="search"
                value={searchInput.search}
                className="form-control Searchfild "
                placeholder="Search"
                onChange={(e) => handleSearch(e)}

              />
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>

            {/* <input
              type="text"
              name="search"
              value={searchInput.search}
              className=" form-control w-75"
              placeholder="Search"
              style={{borderRadius:"20px"}}
              onChange={(e) => handleSearch(e)}
            /> */}


          </div>
        </div>


        <div class="faqdiv mt-3"
          style={{ height: "26rem", overflowY: "auto", overflowX: "hidden" }}
        >
          {faqData.length < 0 ? (
            ""
          ) : (
            <div className="myafqform">
              <div className="Listitems mt-2 p-1" style={{ borderRadius: "20px", border: "none", fontSize: "14px" }}>
                {faqData.map((data, index) => {
                  // console.log("index", data._id);

                  return (
                    <>
                      {/* <Accordion
                        expanded={expanded === index}
                        onChange={handleChange(index)}
                        sx={{ borderRadius: "20px", border: "none" }}
                        className="mb-3 "

                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2bh-content"
                          id="panel2bh-header"

                        >
                          <Typography sx={{ width: "auto", height: "50%", border: "none" }}>
                            <text>{data._question}</text>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            sx={{ width: "100%", border: "none" }}
                            className="d-flex justify-content-start"
                          >
                            <text className="text-success"> {data._answer}</text>
                          </Typography>
                        </AccordionDetails>
                      </Accordion> */}

                      <CustomAccordion
                        expanded={expanded === index}
                        onChange={handleChange(index)}
                        question={data._question}
                        answer={data._answer}
                        isEdit={true}
                        onClick={() => handleEdit(data._id)}
                      />

                    </>
                  );

                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AllFaq;
