import axios from "axios";
import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import { DataGrid } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import Loader from "../../../utils/Loader/Loader";
import RadioButton from "../../Reusable_Components/RadioButton";
import { getCustomerFeedBack, getCustomerFeedback, getFaqAllFeedback } from "../../../Services/AdminServices";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
import { useSelector } from "react-redux";

const FeedbacksList = () => {
  const [selectedValue, setSelectedValue] = useState(1);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [statusList, setStatusList] = useState([
    {
      label: "Customer Feedback",
      // value: "/feedbacklist",
      value: 1,
      status: true
    },
    {
      label: "FAQ Feedback",
      // value: "/faqfeedback",
      value: 2,
      status: false
    }
  ])
  useEffect(() => {
    fetchCustomerFeedback();
  }, []);

  // uam role object start
  const userDetails = useSelector((state) => state)
  const appsubmodulename = "Feedback"
  const appfunctionalityname = "Feedback"
  const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
  const [appObjects, setAppObjects] = useState(
    {
      "Customer Feedback": false,
      "FAQ Feedback": false,
    }
  )
  console.log("userDetails", userDetails)

  const updateAppObjects = (arr) => {
    let objectsArray = []
    arr.map((obj) => {
      console.log("updateButtons", obj)
      // if (obj.objecttypename == "button") {
      objectsArray.push(obj)
      // }
    })
    const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
      acc[appobjectname] = true;
      return acc;
    }, {});
    setAppObjects({ ...appObjects, ...finalObject })
    console.log("objectsArray", objectsArray)
    console.log("finalObject", finalObject)
  }

  useEffect(() => {
    console.log("appObjects", appObjects)
  }, [appObjects])

  useEffect(() => {
    console.log("userRoleObjects", userRoleObjects)
    const filteredList = []
    userRoleObjects.filter((obj) => {
      if (obj.appsubmodulename == appsubmodulename && obj.appfunctionalityname == appfunctionalityname) {
        filteredList.push(obj)
      }
    })

    console.log("filteredList", filteredList)
    updateAppObjects(filteredList)

  }, [])

  // uam role object end

  const fetchCustomerFeedback = async () => {
    setLoading(true)
    try {
      const response = await getCustomerFeedback()
      console.log("user_all_feedback", response.data.result);
      let feedbackData = response.data.result
      feedbackData.forEach((obj) => {
        obj["feedback_date"] = moment(obj.feedback_date).format("DD-MM-YYYY H:mm:ss")
      })
      console.log("fbd", feedbackData)
      setData(response.data.result);
      // setData(feedbackData);
    }
    catch (err) {
      setError(err)
    }
    setLoading(false)
  };

  const fetchFAQFeedback = async () => {
    setLoading(true)
    try {
      const response = await getFaqAllFeedback();
      let data = response.data.result;
      console.log("faqfeedbackdata", data);
      data.forEach((obj) => {
        obj["feedback_date"] = moment(obj.feedback_date).format(
          "DD-MM-YYYY H:mm:ss"
        );
      });

      console.log(response.data.result);
      setData(response.data.result);
    }
    catch (err) {
      setError(err)
    }
    setLoading(false)
  };

  // const NavigateComonay = (e) => {
  //   console.log("NavigateComonay", e.target.value);

  //   navigate(e.target.value);
  // };

  const userColumns = [
    {
      headerClassName: "super-app-theme--header",
      field: "feedback_id",
      headerName: "Id",
      // width: 63,
      flex: 0.1,
    },
    {
      field: "name",
      headerClassName: "super-app-theme--header",
      headerName: "Name",
      // width: 120,
      flex: 0.5,
    },

    {
      field: "email",
      headerClassName: "super-app-theme--header",
      headerName: "Email",
      // width: 230,
      flex: 0.5,
    },

    {
      field: "feedback_date",
      headerClassName: "super-app-theme--header",
      headerName: "Date",
      // width: 250,
      flex: 0.5,
    },

    {
      field: "feedback_data",
      headerClassName: "super-app-theme--header",
      headerName: "Response",
      // width: 200,
      flex: 0.5,
    },

    {
      field: "rating",
      headerClassName: "super-app-theme--header",
      headerName: "Rating",
      // width: 150,
      flex: 0.3,
      renderCell: (params) => (
        <>
          {Array.from(Array(params.value).keys()).map((i) => (
            <StarIcon style={{ color: "#FFDB58", border: "5px solid white" }} key={i} />
          ))}
        </>
      ),
    },
  ];
  // 
  // adding condition starts here


  // onhandle event 
  const handleRadioButton = (e) => {
    console.log("Active", e.target)
    let value = e.target.value
    // navigate(e.target.value);
    console.log("item", statusList, value)
    console.log("called", value)
    if (value == 1) {
      fetchCustomerFeedback()
    }
    else if (value == 2) {
      fetchFAQFeedback()
    }
    let updatedStatusList = statusList.map((item) => {
      if (item.value == value) {
        item.status = true
      }
      else {
        item.status = false
      }
      return item
    })
    console.log("updatedStatusList", updatedStatusList)
    setSelectedValue(value)
    setStatusList(updatedStatusList)

  }




  // ends here
  return (
    <>
      <Loader loading={loading} />
      <ErrorCatch err={error} />
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-md-8">
            <div className="card bg-transparent" style={{ border: "none" }}>
              {/* <h6 className="mt-2">
                <b className="mt-5 ">Customer Feedback</b>
              </h6> */}
              <p className="px-1 mt-3 page-title">Feedback</p>
            </div>
          </div>
          <div className="col-md-4 mt-2 d-flex justify-content-end"
          // style={{ paddingRight: "2.25rem" }}
          >
            <div className="bg-transparent">

              {/* commenting for converting dropdown to radiobutton */}
              {appObjects["Customer Feedback"] &&
                appObjects["FAQ Feedback"] &&
                <RadioButton
                  // radioButtonTitle="Status"
                  optionsList={statusList}
                  selectedValue={selectedValue}
                  handleChange={(e) => handleRadioButton(e)}
                />}
              {/* <select
                className="form-select text-center"
                onChange={(e) => NavigateComonay(e)}
              >
                <option value={"/feedbacklist"}>
                  <h5 className="text-dark">
                    <b>Customer Feedback</b>
                  </h5>
                </option>
                <option value={"/faqfeedback"}>
                  <h5 className="text-dark">
                    <b>FAQ Feedback</b>
                  </h5>
                </option>
              </select> */}
            </div>
          </div>
          {/* <div className="row"> */}
          <div className="mt-1 ">
            {data.length > 0 ? (
              <div
                className="col-md-12"
                style={{ height: "29rem", overflowY: "auto", overflowX: "hidden" }}
              >
                <Box sx={{ height: "47vh", width: "100%", overflowY: "auto", }}>
                  <DataGrid
                    sx={{
                      borderColor: "secondary",
                      "& .MuiDataGrid-cell:hover": {
                        color: "primary.main",
                      },
                      "& .MuiDataGrid-columnHeader": {
                        backgroundColor: "#0E3386",
                        fontSize: "12px",
                        color: "white",
                        padding: "10px",

                        // Set your desired header background color
                      },
                      "& .MuiDataGrid-cell": {
                        fontSize: "10px",
                        padding: "10px",
                        borderRight: "2px solid #e0e0e0",
                        // Adjust the font size as needed
                        // Adjust the cell padding as needed
                      },
                    }}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'feedback_id', sort: 'desc' }],
                      },
                    }}
                    getRowId={(data) => data.feedback_id}
                    className="datagrid"
                    rows={data}
                    pageSize={9}
                    rowHeight={30}
                    rowsPerPageOptions={[9]}
                    checkboxSelection={false}
                    hideFooter
                    columns={userColumns}
                  />
                </Box>
              </div>
            ) :
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col d-flex justify-content-center">
                    {" "}
                    No Rows to show
                  </div>
                </div>
              </div>
            }
          </div>
          {/* </div> */}
        </div>
      </div>

    </>
  );
};

export default FeedbacksList;
