import { isVerified } from "../../../Services/UserServices.jsx";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import Navbar from "./Navbar.jsx";

function VerifyOTP() {
  const { id, email } = useParams();
  const navigate = useNavigate();
  const HandleVerify = async (OTP, email) => {
    // otp.otp = value.otp;
    await isVerified({ email: email, otp: OTP })
      .then((res) => {
        console.log("OTP verification done", res);
        if (res.status == 200) {
          toast.success("otp verified successfully.");
          setTimeout(() => {
            navigate("/");
          }, 2000);
        } else {
          toast.error("Something went wrong, try again");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
        });
      });
  };

  useEffect(() => {
    HandleVerify(id, email);
  }, []);
  return (
    <>
    <ToastContainer />
      <Navbar />
      
    </>
  );
}

export default VerifyOTP;
