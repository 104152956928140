
import services from "../../../Assets/Images/customer.png";
const Featurespage = () => {
  return (
    <>
      <div className="row">
        <div className="col d-flex justify-content-center">
          <img src={services} alt="" style={{ width: "3rem" }} />
        </div>
        {/* <div className="col text-start"><h5>Features</h5></div> */}
      </div>
      <hr style={{ color: "grey" }}></hr>
      <ol className="OLList">
        <li className="listoptions">
          <h6>User Registration and Authentication:</h6>
          <p>
            Allow users to register and create an account.
            Implement authentication to secure user access.
          </p>
        </li>
        <li className="listoptions">
          <h6>Document Request:</h6>
          <p>
            Enable users to request specific documents or information.
            Capture user requirements and details for the document request.
          </p>
        </li>
        <li className="listoptions">
          <h6>Document Download in Various Formats:</h6>
          <p>
            Allow users to download the modified documents.
            Support multiple formats such as PDF, CSV, text, etc.
            Provide flexibility for users to choose their desired format.
          </p>
        </li>
        <li className="listoptions">
          <h6>API Access:</h6>
          <p>
            Grant users access to APIs for data retrieval and integration.
            Issue API credentials and provide documentation for usage.
          </p>
        </li>
        <li className="listoptions">
          <h6>Communication and Feedback:</h6>
          <p>
            Implement messaging or chat functionality for user-admin communication.
            Enable users to provide feedback, suggestions, or inquiries.
          </p>
        </li>
      </ol>
    </>
  );
};

export default Featurespage;
