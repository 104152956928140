import React, { useState } from "react";
import alba_products_1 from "../../../Assets/Images/alba_products_1.png";
import alba_products_2 from "../../../Assets/Images/alba_products_2.png";
import alba_products_3 from "../../../Assets/Images/alba_products_3.png";
import { Carousel } from 'antd';
import Signin from "./Signin";

const CarouselContainer = ({showSignin}) => {

    const contentStyle = {
        // height: '60%',
        objectFit: 'cover',
        // height: '160px',
        // color: '#fff',
        // lineHeight: '160px',
        // textAlign: 'center',
        // background: '#364d79',
    };


    // return (
    //     <div className="carousel-container">
    //         <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
    //             <div class="carousel-inner">
    //                 <div className="carousel-item active">
    //                     <img className="d-block w-100" src={alba_products_1} alt="First slide" />
    //                 </div>
    //                 <div className="carousel-item">
    //                     <img className="d-block w-100" src={alba_products_2} alt="Second slide" />
    //                 </div>
    //                 <div className="carousel-item">
    //                     <img className="d-block w-100" src={alba_products_3} alt="Third slide" />
    //                 </div>
    //             </div>
    //             <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
    //                 <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    //                 <span class="visually-hidden">Previous</span>
    //             </button>
    //             <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
    //                 <span class="carousel-control-next-icon" aria-hidden="true"></span>
    //                 <span class="visually-hidden">Next</span>
    //             </button>
    //         </div>
    //         <div className="overlay-text">
    //             <div className="container">
    //                 <div className="row">
    //                     <div className="col-md-6">
    //                         <h1>Welcome to Our Site</h1>
    //                         <p>Some introductory text goes here.</p>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // );
    return (
        <>
            <div className="carousel-container">
                <Carousel autoplay autoplaySpeed={5000}
                // adaptiveHeight={true}
                >
                    <img src={alba_products_1} style={contentStyle} alt="First slide" />
                    <img src={alba_products_2} style={contentStyle} alt="Second slide" />
                    <img src={alba_products_3} style={contentStyle} alt="Third slide" />
                </Carousel>
                <div className="overlay-text p-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <h1 className="landing-heading">Aluminium for the <span className="alba-red-text">World</span></h1>
                            </div>
                            <div className="p-2 col-md-6 col-sm-12">
                                {showSignin && <div className="card">
                                <Signin/>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default CarouselContainer;
