import React, { createRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { Modal } from "antd";
import Draggable from 'react-draggable';
// import { Resizable } from 'react-resizable';
// import 'react-resizable/css/styles.css';
import { DraggableModal, DraggableModalProvider } from 'ant-design-draggable-modal'
// import 'antd/dist/antd.css'

import 'antd/dist/reset.css'
import 'ant-design-draggable-modal/dist/index.css'
import { DataGrid } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import moment from "moment/moment";
import { JsonViewer } from "@textea/json-viewer";
import Loader from "../../../../utils/Loader/Loader";
import { AiFillFile } from 'react-icons/ai';
import { AiFillFilePdf } from 'react-icons/ai';
import { FaFileCsv } from 'react-icons/fa';
import { SiMicrosoftexcel } from 'react-icons/si';
import { appliedRulesHistoryColumnCOA, appliedRulesHistoryColumnInvoice } from "./appliedRulesHistoryColumn";
import { getBundleHistory, getCOAHistory, getInvoiceHistory } from "../../../../Services/AdminServices";
import { messages } from "../../../Messages";
import { useRef } from "react";
import ErrorCatch from "../../../Reusable_Components/ErrorCatch";
import TabsAntd from "../../../Reusable_Components/TabsAntd";
import RawDataTable from "../../Admin/RawDataTable";
import JsonComp from "../../Admin/JsonComp";


function AppliedRulesHistoryPopup({ docid, open, onOk, onCancel, data, pdf, csv, excel, transform }) {
    console.log("AppliedRulesHistoryPopupdata", docid, data)
    // appliedRulesHistoryColumn
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [column, setColumns] = useState([])
    const [selection, setSelection] = useState()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [convertData, setConvertData] = useState([])
    const [rowData, setRowData] = useState([])
    const draggleRef = createRef()
    const [isRawDataTable, setIsRawDataTable] = useState(false)
    const [isJsonComp, setIsJsonComp] = useState(false)
    // const draggleRef = useRef(null)
    const [bounds, setBounds] = useState({
        left: 0, top: 0, bottom: 0, right: 0
    })

    const onStart = (event, uiData) => {
        console.log("onStartevent", event, "UIData", uiData)
        const { clientWidth, clientHeight } = window?.document?.documentElement;

        const targetRect = draggleRef?.current?.getBoundingClientRect();

        // const targetWidth = draggleRef.current.clientWidth;
        // const targetHeight = draggleRef.current.clientHeight;
        // const maxX = clientWidth - targetWidth;
        // const maxY = clientHeight - targetHeight;

        // if (uiData.x > maxX || uiData.y > maxY) {
        //     event.preventDefault();
        // }

        setBounds({
            left: -targetRect?.left + uiData?.x,
            right: clientWidth - (targetRect?.right - uiData?.x),
            top: -targetRect?.top + uiData?.y,
            bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        });
    };

    useEffect(() => {
        data.forEach((obj) => {
            obj["history_created_date"] = moment(obj.history_created_date).format(
                "DD-MM-YYYY H:mm:ss"
            );
        });

        setRowData(data)

    }, [open])
    console.log("__row", rowData)

    const showAppliedRulesHistoryPopup = async (params) => {
        console.log("showAppliedRulesHistoryPopupparams", params)
        let response, sendData
        setLoading(true)
        if (docid == 1) {
            sendData = {
                castid: params[0].castid.trim(),
                bundleno: params[0].bundleno.trim()
            }
            try {
                response = await getBundleHistory(sendData)
            }
            catch (err) {
                setError(err)
            }
        }
        else if (docid == 2) {
            sendData = {
                invoiceno: params[0].invoiceno.trim()
            }
            try {
                response = await getInvoiceHistory(sendData)
            }
            catch (err) {
                setError(err)
            }
        }

        console.log("sendData", sendData)
        console.log("response", response)

        // let response = await getCOAHistory(`${params[0].castid.trim()}`)
        if (response.status == 200) {
            setRowData(response.data.data)
        }
        else {
            toast.error(messages.noDataFound)
        }
        setLoading(false)
    }

    console.log("__row", rowData)
    const showModal = (params) => {
        console.log("params", params)
        setIsModalOpen(true);
        let filterArr = params.row.conversion_data
        console.log("filterArr", filterArr)
        setConvertData(filterArr)

    }

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const downloadPDF = (params) => {
        console.log("params", params, rowData)
        let filterArr = []
        let fileName
        if (docid == 1) {
            fileName = "COA_" + params.row.bundleno.trim()
            // let filterArr = params.row.conversion_data
            rowData.filter((obj) => {
                if (params.row.bundledetails_history_id == obj.bundledetails_history_id) {
                    filterArr.push(obj.conversion_data)
                }
            })
        }
        else if (docid == 2) {
            fileName = "Invoice_" + params.row.invoiceno.trim()
            // let filterArr = params.row.conversion_data
            rowData.filter((obj) => {
                if (params.row.invoicedetails_history_id == obj.invoicedetails_history_id) {
                    filterArr.push(obj.conversion_data)
                }
            })
        }
        // filterArr.push(params.row.conversion_data)
        console.log("filterArr", filterArr)
        pdf(filterArr, fileName)
        // setRowData(rowData)
        showAppliedRulesHistoryPopup(rowData)
    }

    const downloadCSV = (params) => {
        console.log("params", params)
        let filterArr = []
        let fileName
        // let filterArr = params.row.conversion_data

        if (docid == 1) {
            fileName = "COA_" + params.row.bundleno.trim()
            rowData.filter((obj) => {
                if (params.row.bundledetails_history_id == obj.bundledetails_history_id) {
                    filterArr.push(obj.conversion_data)
                }
            })
        }
        else if (docid == 2) {
            fileName = "Invoice_" + params.row.invoiceno.trim()
            rowData.filter((obj) => {
                if (params.row.invoicedetails_history_id == obj.invoicedetails_history_id) {
                    filterArr.push(obj.conversion_data)
                }
            })
        }
        csv(filterArr, fileName)
        showAppliedRulesHistoryPopup(rowData)
    }

    const downloadExcel = (params) => {
        console.log("params", params)
        let filterArr = []
        let fileName
        // let filterArr = params.row.conversion_data
        if (docid == 1) {
            fileName = "COA_" + params.row.castid.trim()
            rowData.filter((obj) => {
                if (params.row.bundledetails_history_id == obj.bundledetails_history_id) {
                    filterArr.push(obj.conversion_data)
                }
            })
        }
        else if (docid == 2) {
            fileName = "Invoice_" + params.row.invoiceno.trim()
            rowData.filter((obj) => {
                if (params.row.invoicedetails_history_id == obj.invoicedetails_history_id) {
                    filterArr.push(obj.conversion_data)
                }
            })
        }

        let forExcel = transform(filterArr)
        excel(forExcel[0].analysisDataArrayForExcel, fileName)
        showAppliedRulesHistoryPopup(rowData)
    }
    // console.log("__check/",showAppliedRulesHistoryPopup)
    const handleCheckBox = (newSelectionModel) => {
        console.log("newSelectionModel==>", newSelectionModel, data);
        let finalArr = []
        // data.map(item => {
        //     newSelectionModel.filter(obj => {
        //         if (obj == item.castid) {
        //             finalArr.push(item)
        //         }
        //     })
        // })
        data.map(item => {
            newSelectionModel.filter(obj => {
                if (docid == 1) {
                    if (obj == item.bundledetails_history_id) {
                        finalArr.push(item)
                    }
                }
                else if (docid == 2) {
                    if (obj == item.invoicedetails_history_id) {
                        finalArr.push(item)
                    }
                }
            })
        })
        console.log("newSelectionModel_finalarr", finalArr)
        setSelection(finalArr)
    };
    console.log("__set");
    // const actionColumns = [
    //     {
    //         field: "file",
    //         headerName: "File",
    //         // width: "150px",ss
    //         flex: 0.1,
    //         renderCell: (params) => {
    //             console.log("convewrtateddata ", params)
    //             return (
    //                 <div className="cellAction">
    //                     <a class="btn" onClick={() => showModal(params)}>
    //                         {/* <img src={File} alt="fileicon" style={{ width: '0.8rem' }} /> */}
    //                         <AiFillFile />
    //                     </a>
    //                 </div>
    //             );
    //         }
    //     },
    //     {
    //         field: "pdf",
    //         headerName: "PDF",
    //         // width: "150px",ss
    //         flex: 0.1,
    //         renderCell: (params) => {
    //             console.log("convewrtateddata ", params)
    //             return (
    //                 <div className="cellAction">
    //                     <a class="btn" onClick={() => downloadPDF(params)}>
    //                         <AiFillFilePdf />
    //                     </a>
    //                 </div>
    //             );
    //         }
    //     },
    //     {
    //         field: "csv",
    //         headerName: "CSV",
    //         // width: "150px",ss
    //         flex: 0.1,
    //         renderCell: (params) => {
    //             console.log("convewrtateddata ", params)
    //             return (
    //                 <div className="cellAction">
    //                     <a class="btn" onClick={() => downloadCSV(params)}>
    //                         <FaFileCsv />
    //                     </a>
    //                 </div>
    //             );
    //         }
    //     },
    //     {
    //         field: "excel",
    //         headerName: "Excel",
    //         // width: "150px",ss
    //         flex: 0.1,
    //         renderCell: (params) => {
    //             console.log("convewrtateddata ", params)
    //             return (
    //                 <div className="cellAction">
    //                     <a class="btn" onClick={() => downloadExcel(params)}>
    //                         {/* <img src={File} alt="fileicon" style={{ width: '0.8rem' }} /> */}
    //                         <SiMicrosoftexcel />
    //                     </a>
    //                 </div>
    //             );
    //         }
    //     },

    // ]

    const actionColumns = [
        {
            field: "file",
            headerName: "File",
            // width: "150px",ss
            flex: 0.1,
            renderCell: (params) => {
                console.log("convewrtateddata ", params)
                return (
                    <div className="cellAction">
                        <a class="btn" onClick={() => showModal(params)}>
                            {/* <img src={File} alt="fileicon" style={{ width: '0.8rem' }} /> */}
                            <AiFillFile />
                        </a>
                    </div>
                );
            }
        },
        {
            field: 'actions',
            headerName: 'Download',
            flex: 0.2,
            headerAlign: 'center',
            renderCell: (params) => (
                <div className="cellAction">
                    <a className="btn" onClick={() => downloadPDF(params)}>
                        <AiFillFilePdf />
                    </a>
                    <a className="btn" onClick={() => downloadCSV(params)}>
                        <FaFileCsv />
                    </a>
                    <a className="btn" onClick={() => downloadExcel(params)}>
                        <SiMicrosoftexcel />
                    </a>
                </div>
            ),
        },
    ];

    const handleCellSelection = (value) => {
        console.log("handleCellSelectionValue", value, selection)
        if (undefined !== selection) {
            if (value.castid == selection.castid) {
                setSelection()
            }
        }
        else {
            setSelection(value)
        }
    }

    const selectHistoryID = (docid, data) => {
        let selectID = {
            1: data.bundledetails_history_id,
            2: data.invoicedetails_history_id
        }
        return selectID[docid] || toast.warn("No History ID available")
    }

    const selectColumns = (docid) => {
        let selectCol = {
            1: appliedRulesHistoryColumnCOA,
            2: appliedRulesHistoryColumnInvoice
        }
        // return selectCol[docid]
        setColumns(selectCol[docid])
    }

    useEffect(() => {
        console.log("docid", docid)
        selectColumns(docid)
    }, [docid])

    const width = "90%"

    useEffect(() => {
        console.log("rawDatadetails", convertData)
        if (Object.keys(convertData).length > 0) {
            setIsModalOpen(true)
            setIsRawDataTable(true)
        }
    }, [convertData])

    const tabItemsConvertedData = [
        {
            key: "1",
            label: `Table`,
            children: convertData ? <RawDataTable isRawDataTable={isRawDataTable} rawData={convertData} /> : null,
        },
        {
            key: "2",
            label: `JSON`,
            children: convertData ? <JsonComp isJsonComp={isJsonComp} rawData={convertData} /> : null,
        },
    ]

    const onChangeTabsConvertData = (key) => {
        console.log("onChangeTabsonChangeTabsConvertDataRawData", key);
        if (key == "1") {
            setIsJsonComp(false)
            setIsRawDataTable(true)
        }
        else if (key == "2") {
            setIsJsonComp(true)
            setIsRawDataTable(false)
        }
    }

    return (
        <>
            <Loader loading={loading} />
            <ErrorCatch err={error} />
            <div className="container"
            >
                <Modal
                    width={"90%"}
                    // width={400}
                    title="Applied Rules History"
                    className="p-2"
                    open={open}
                    onOk={onOk}
                    onCancel={onCancel}
                    footer={null}
                    destroyOnClose
                    modalRender={(modal) => (
                        <Draggable
                            //   disabled={disabled}
                            bounds={bounds}
                            onStart={(event, uiData) => onStart(event, uiData)}
                        >
                            {/* <Resizable
                                width={400}
                                // width={width}
                                height={400} // Set your desired initial height
                                minConstraints={[300, 200]} // Minimum width and height
                                maxConstraints={[800, 600]} // Maximum width and height
                                // handleWrapperClass="custom-modal" // Optional class for the resizing handle
                            > */}
                            <div ref={draggleRef} className="custom-modal">
                                {modal}
                            </div>
                            {/* </Resizable> */}
                            {/* <div aa="2" ref={draggleRef}>
                                {modal}
                            </div> */}
                        </Draggable>
                    )}

                >

                    {/* <DraggableModalProvider>
                    <DraggableModal
                        width={"80%"}
                        height={"50%"}
                        // style={{position: "absolute", top: "50%", left: "50%"}}
                        title="Applied Rules History"
                        open={open}
                        onOk={onOk}
                        onCancel={onCancel}
                        footer={null}
                    > */}
                    {console.log("LENGGTH", data?.length != 0)}

                    {data && data?.length != 0 ?

                        <Box sx={{
                            height: "38vh",
                            // width: "87vw", 
                            width: "100%",
                        }
                        }
                        >
                            <DataGrid
                                sx={{
                                    borderColor: "secondary",
                                    "& .MuiDataGrid-cell:hover": {
                                        color: "primary.main",
                                    },
                                    "& .MuiDataGrid-columnHeader": {
                                        backgroundColor: "#0E3386",
                                        fontSize: "12px",
                                        color: "white",
                                        padding: "10px",

                                        // Set your desired header background color
                                    },
                                    "& .MuiDataGrid-cell": {
                                        fontSize: "10px",
                                        padding: "10px",
                                        borderRight: "2px solid #e0e0e0",
                                        // Adjust the font size as needed
                                        // Adjust the cell padding as needed
                                    },
                                }}

                                // rows={data}
                                rows={rowData}
                                disableRowSelectionOnClick
                                // checkboxSelection
                                checkboxSelection={false}
                                disableSelectionOnClick
                                // disableMultipleRowSelection={true}
                                // handleChangeCheckbox
                                // onCellClick={(params) => handleCellSelection(params.row)}
                                // selectionModel={selection}
                                // getRowId={(data) => data.castid}
                                // getRowId={(data) => data.analysis_history_id}
                                // getRowId={(data) => data.bundledetails_history_id}
                                // getRowId={(data) => {
                                //     if(docid == 1){
                                //     return data.bundledetails_history_id
                                //     } 
                                // }}
                                getRowId={(data) => selectHistoryID(docid, data)}
                                onRowSelectionModelChange={(newRowSelectionModel) =>
                                    handleCheckBox(newRowSelectionModel)
                                }
                                // componentsProps={{
                                //     toolbar: {
                                //         value: searchText,
                                //         onChange: (searchVal: string) => requestSearch(searchVal),
                                //         onCancelSearch: () => cancelSearch(),
                                //     },
                                // }}
                                rowHeight={30}
                                // columns={appliedRulesHistoryColumn.concat(actionColumns)}
                                columns={column.concat(actionColumns)}
                                hideFooter
                                disableMultipleColumnsSorting={true}
                                sortModel={[
                                    { field: "action1", sort: "asc" },
                                    { field: "action", sort: "asc" },
                                ]}
                            />
                            {/* adding this for showing message when no data is present */}
                        </Box> : <div>
                            <p>No History data available</p>
                        </div>

                    }

                    {/* <Modal title="Converted data" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} style={{ top: 20, minWidth: 300, maxWidth: 600 }}>
                        <hr />
                        <JsonViewer value={convertData} />
                    </Modal> */}

                    <Modal title="Converted data" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                        style={{ top: 20, minWidth: 600, maxWidth: 800 }}
                        footer={null}
                    >
                        <hr />
                        <div
                        >
                            <TabsAntd
                                defaultActiveKey="1"
                                items={tabItemsConvertedData}
                                onChange={(key) => onChangeTabsConvertData(key)}
                                centered
                            />
                        </div>
                    </Modal>

                </Modal>
                {/* </DraggableModal>
                </DraggableModalProvider> */}


            </div>

        </>
    );
}

export default AppliedRulesHistoryPopup;
