import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { addUserByAdmin } from "../../../Services/UserServices.jsx";
import InputBox from "../../Reusable_Components/InputBox.jsx";
import SelectInput from "../../Reusable_Components/SelectInput.jsx";
import Loader from "../../../utils/Loader/Loader.js";
import RadioButton from "../../Reusable_Components/RadioButton.jsx";
import { getActiveCustomer, getRoles, getSingleuser, insertLogs } from "../../../Services/AdminServices.jsx";
import ErrorCatch from "../../Reusable_Components/ErrorCatch.jsx";
import ButtonComp from "../../Reusable_Components/ButtonComp.jsx";
import CheckboxComp from "../../Reusable_Components/CheckBox.jsx";
import { useSelector } from "react-redux";
import { cognitoAdminCreateUser, cognitoListUser } from "../../../Services/CognitoServices.jsx";
import AWS from 'aws-sdk';
import CryptoJS from 'crypto-js';

const RegisterUser = () => {
    const userDetails = useSelector((state) => state)
    console.log("userDetails", userDetails)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [titleList, setTitleList] = useState([
        { name: "Mr.", value: "Mr." }, { name: "Ms.", value: "Ms." }
    ])
    const [title, setTitle] = useState("")
    const [name, setName] = useState("")
    const [role, setRole] = useState("");
    const [roleName, setRoleName] = useState("");
    const [email, setEmail] = useState("");
    const [isEmailEnabled, setIsEmailEnabled] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [existingCompany, setExistingCompany] = useState("")
    const [existingCompanyName, setExistingCompanyName] = useState("")
    const [department, setDepartment] = useState("");
    const [designation, setDesignation] = useState("");
    const [address, setAddress] = useState("");

    const [selectedValue, setSelectedValue] = useState(1);
    const [statusList, setStatusList] = useState([
        {
            label: "Existing Company",
            value: 1,
            status: true
        },
        {
            label: "New Company",
            value: 2,
            status: false
        },
    ])
    const [roleList, setRoleList] = useState([])
    const [customerList, setCustomerList] = useState([])
    const [cognitoUserList, setCognitoUserList] = useState([])

    const userDetailsLog = userDetails.auth.userDetails



    // Configure AWS SDK
    // AWS.config.update({ region: process.env.REACT_APP_AWS_COGNITO_REGION })
    AWS.config.update({
        region: process.env.REACT_APP_AWS_COGNITO_REGION,
        credentials: {
            accessKeyId: process.env.REACT_APP_COGNITO_ACCESS_KEYID,
            secretAccessKey: process.env.REACT_APP_COGNITO_SECRET_ACCESS_KEY
        }
    })
    const cognito = new AWS.CognitoIdentityServiceProvider()

    // uam role object start
    const appsubmodulename = "Register"
    const appfunctionalityname = "Register User"
    const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
    const [appObjects, setAppObjects] = useState(
        {
            "Create User": false,
            // "Create User Cognito": false
        }
    )
    console.log("userDetails", userDetails)

    const updateAppObjects = (arr) => {
        let objectsArray = []
        arr.map((obj) => {
            console.log("updateButtons", obj)
            // if (obj.objecttypename == "button") {
            objectsArray.push(obj)
            // }
        })
        const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
            acc[appobjectname] = true;
            return acc;
        }, {});
        setAppObjects({ ...appObjects, ...finalObject })
        console.log("objectsArray", objectsArray)
        console.log("finalObject", finalObject)
    }

    useEffect(() => {
        console.log("appObjects", appObjects)
    }, [appObjects])

    useEffect(() => {
        console.log("userRoleObjects", userRoleObjects)
        const filteredList = []
        userRoleObjects.filter((obj) => {
            if (obj.appsubmodulename == appsubmodulename && obj.appfunctionalityname == appfunctionalityname) {
                filteredList.push(obj)
            }
        })

        console.log("filteredList", filteredList)
        updateAppObjects(filteredList)

    }, [])

    // uam role object end


    useEffect(() => {
        getRoleList()
        getActiveCustomerList()
        getAllUserWithCognito()
    }, [])

    const getAllUserWithCognito = async () => {
        try {
            let getAllResponse = await cognitoListUser()
            console.log("getAllResponse_", getAllResponse)
            let data = getAllResponse.data.result
            setCognitoUserList(data)

        } catch (error) {
            console.log("cognitoGetAllerror", error)
        }
    }

    const getRoleList = async () => {
        try {
            const response = await getRoles();
            console.log("getRoleList", response);
            let { result } = response.data
            let finalArr = []
            result.map((obj) => {
                finalArr.push({
                    name: obj.role_name,
                    value: obj.role_id
                })
            })
            setRoleList(finalArr);

        } catch (error) {
            console.log("error", error);
            setError(error)
        }
    }

    // const encrypt = (plaintext) => {
    //     console.log("encryptPlaintext", plaintext);
    //     let encryptedPassword = CryptoJS.AES.encrypt(plaintext, process.env.REACT_APP_SECRET_KEY).toString();
    //     return encryptedPassword
    // };

    const encrypt = (plaintext) => {
        console.log("encryptPlaintext", plaintext);
        return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(plaintext))
        .replace(/=+$/, '') // Remove padding
        .replace(/\+/g, '-') // Replace + with -
        .replace(/\//g, '_'); // Replace / with _
    };

    const getActiveCustomerList = async () => {
        try {
            let currentUserLoginEmail = encrypt(userDetailsLog.email)
            const response = await getActiveCustomer(currentUserLoginEmail);
            console.log("getActiveCustomer", response);
            if (response.status == 200) {
                let data = response.data.data
                let finalArr = []
                data.map((obj) => {
                    finalArr.push({
                        name: obj._customername,
                        value: obj.comp_id
                    })
                })
                setCustomerList(finalArr);
            }

        } catch (error) {
            console.log("error", error);
            setError(error)
        }
    }

    const handleRadioButton = (e) => {
        console.log("handleRadioButton", e.target)
        let value = e.target.value
        console.log("item", statusList, value)

        // if (value == 1) {
        //   setCompanyColumns(activeCompanyColumn)
        //   ActiveCompany()
        // }
        // else if (value == 2) {
        //   setCompanyColumns(requestedCompanyColumn.concat(actionColumnReqComp))
        //   getRequestedCompany()
        // }

        let updatedStatusList = statusList.map((item) => {
            if (item.value == value) {
                item.status = true
            }
            else {
                item.status = false
            }
            return item
        })
        console.log("updatedStatusList", updatedStatusList)
        setSelectedValue(value)
        setStatusList(updatedStatusList)
    }

    const handleRoleName = (e) => {
        const { name, value } = e.target
        console.log("handleRoleName", value)
        setRole(value)
        let data = roleList.filter(obj => obj.value == value)
        console.log("exRoleData", data)
        setRoleName(data[0].name)
        if (data[0].name == "super-admin" || data[0].name == "super-user") {
            let { comp_name } = userDetails.auth.userDetails
            setExistingCompanyName(comp_name)
        }
        else if (data[0].name == "customer-admin" || data[0].name == "customer-user") {
            setExistingCompany("")
            setExistingCompanyName("")
            setCompanyName("")
        }
    }



    const handleCustomerName = (e) => {
        const { name, value } = e.target;
        console.log("handleCustomerName", value)
        setExistingCompany(value)
        let data = customerList.filter(obj => obj.value == value)
        console.log("exComdata", data)
        setExistingCompanyName(data[0].name)
    };

    const handleTitle = (e) => {
        let value = e.target.value
        console.log("handleTitle", value)
        setTitle(value)
    }

    const handleName = (e) => {
        console.log("handleName", e)
        let value = e.target.value
        value = value.replace(/[^a-zA-Z\s]/g, '');
        console.log("handleNameValue", value)
        let arr = value.split(" ").map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
        let capitalizedStr = arr.join(" ");
        console.log("capitalizedStr", capitalizedStr)
        setName(capitalizedStr)
    }

    const handleEmail = (e) => {
        let value = e.target.value
        setEmail(value)
    }

    const handleEmailEnabled = (e) => {
        setIsEmailEnabled(e.target.checked)
    }

    const handlePhoneNumber = (e) => {
        let value = e.target.value.replace(/[^0-9+]/g, '')
        setPhoneNumber(value)
    }

    const handleCompanyName = (e) => {
        let value = e.target.value
        let allCaps = value.toUpperCase()
        console.log("handleCompanyName", value, allCaps)
        setCompanyName(allCaps)
    }

    const handleDepartment = (e) => {
        let value = e.target.value
        setDepartment(value)
    }

    const handleDesignation = (e) => {
        let value = e.target.value
        setDesignation(value)
    }

    const handleAddress = (e) => {
        let value = e.target.value
        setAddress(value)
    }

    const dataValidation = () => {
        if (title == "" || name == "" || email == "" || roleName == "" || ((selectedValue == 1 && existingCompanyName == "") || (selectedValue == 2 && companyName == "")) || department == "" || designation == "" || address == "") {
            toast.error("Please fill all the required fields")
            console.log("dataValidation1")
            return
        }

        let isNameFormat = name.length > 25 ? false : name.length > 3 ? true : false
        const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        let isEmailValid = emailRegExp.test(email);
        // const phoneRegExp = /^(\+?[1-9]{1,4}[ \-]?)?(\([0-9]{2,3}\)[ \-]?)?([0-9]{2,4}[ \-]?[0-9]{3,4}[ \-]?[0-9]{3,4})$/;
        const phoneRegExp = /^(?:\+?[1-9]\d{0,3}[ \-]?)?(?:\(?\d{2,4}\)?[ \-]?)?\d{2,4}[ \-]?\d{2,4}[ \-]?\d{2,4}$/;

        let isPhoneNumberFormat = phoneNumber.length > 9 && phoneRegExp.test(phoneNumber) ? true : false;
        // let isPhoneNumberFormat = phoneRegExp.test(phoneNumber)
        console.log("dataValidation", isNameFormat, isEmailValid)
        // console.log("isPhoneNumberFormat", isPhoneNumberFormat)

        if (!isNameFormat) {
            toast.error("Name must be atleast 4 letters & must not exceed 25 letters.")
            return false
        }
        if (!isEmailValid) {
            toast.error("Invalid Email. Please check")
            return false
        }
        if (phoneNumber !== "") {
            if (!isPhoneNumberFormat) {
                toast.error("Please enter a valid phone number.")
                return false
            }
        }
        return true
    }

    const handleCreateUser = async () => {
        let isValid = dataValidation()
        console.log("handleCreateUserisValid", isValid)
        if (undefined == isValid || isValid == false) {
            return
        }
        try {
            let final_object =
            {
                title: title,
                uname: name,
                roleId: role,
                user_type: roleName,
                email: email,
                isEmailEnabled: isEmailEnabled,
                phone: phoneNumber,
                address: address,
                // comp_name: roleName == "super-admin" || roleName == "super-user" ? existingCompanyName : selectedValue == 1 ? existingCompanyName : companyName,
                comp_name: roleName == "super-admin" || roleName == "super-user" ? existingCompanyName : companyName,
                department: department,
                designation: designation,
                // password: values.password,
                createdBy: "super-admin",
                isVerified: false,
                temporaryPassword: generateRandomPassword(12),
                isCognito: false
            }
            console.log("final_object", final_object);
            setLoading(true)
            let response = await addUserByAdmin(final_object)
            setLoading(false)
            console.log('response', response)
            if (response.status === 200) {
                toast.success("Invite link sent to user");
            } else {
                toast.error("Something went wrong, Check all fields and try again");
            }
            setTitle("")
            setName("")
            setEmail("")
            setPhoneNumber("")
            setCompanyName("")
            setDepartment("")
            setDesignation("")
            setAddress("")
        }
        catch (err) {
            console.log("err", err)
            toast.error(err.response.data.message, {
                // position: "top-right",
                autoClose: 5000,
            });
        }
        setLoading(false)
    }


    const generateRandomPassword = (length) => {
        const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
        const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const numberChars = '0123456789';
        // const specialChars = '!@#$%^&*()_+[]{}|;:,.<>?';
        const specialChars = '!@#$%^&*_+-';

        const allChars = lowercaseChars + uppercaseChars + numberChars + specialChars;

        let password = '';
        password += lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)];
        password += uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)];
        password += numberChars[Math.floor(Math.random() * numberChars.length)];
        password += specialChars[Math.floor(Math.random() * specialChars.length)];

        for (let i = 4; i < length; i++) {
            password += allChars[Math.floor(Math.random() * allChars.length)];
        }

        // return password
        return shuffleString(password);
    };

    const shuffleString = (string) => {
        const array = string.split('');
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array.join('');
    };

    // frontend api
    const handleCreateUserCognitoFrontend = async () => {
        let isValid = dataValidation()
        console.log("handleCreateUserisValid", isValid)
        if (undefined == isValid || isValid == false) {
            return
        }
        let final_object =
        {
            title: title,
            // username: name,
            uname: name,
            roleId: role,
            user_type: roleName,
            email: email,
            isEmailEnabled: isEmailEnabled,
            // phone_number: phoneNumber,
            phone: phoneNumber,
            address: address,
            comp_name: roleName == "super-admin" || roleName == "super-user" ? existingCompanyName : selectedValue == 1 ? existingCompanyName : companyName,
            department: department,
            designation: designation,
            // password: values.password,
            createdBy: "super-admin",
            // temporaryPassword: "Abc@12345678",
            isVerified: true,
            temporaryPassword: generateRandomPassword(12),
            isCognito: true
        }
        console.log("final_object", final_object);

        let { uname, temporaryPassword, user_type, phone, comp_name } = final_object

        const params = {
            DesiredDeliveryMediums: [
                "EMAIL"
            ],
            UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
            Username: email,
            TemporaryPassword: temporaryPassword,
            UserAttributes: [
                {
                    Name: 'email',
                    Value: email,
                },
                {
                    Name: 'email_verified',
                    Value: 'true',
                },
                {
                    Name: 'name',
                    Value: uname,
                },
                {
                    Name: 'phone_number',
                    // Value: phone_number,
                    Value: phone
                },
                {
                    Name: 'phone_number_verified',
                    Value: 'true',
                },
                {
                    Name: 'custom:role',
                    Value: user_type,
                },
                {
                    Name: 'custom:company_name',
                    Value: comp_name,
                },
                {
                    Name: 'custom:designation',
                    Value: designation,
                },
                {
                    Name: 'custom:department',
                    Value: department,
                }
            ],
            // MessageAction: 'SUPPRESS'
        }
        // if (phoneNumber !== "") {
        //     params.UserAttributes.push({
        //         Name: 'phone_number',
        //         // Value: phone_number,
        //         Value: phone
        //     },
        //         {
        //             Name: 'phone_number_verified',
        //             Value: 'true',
        //         },
        //     )
        // }
        console.log("params", params);
        setLoading(true)
        try {
            const response = await cognito.adminCreateUser(params).promise()
            console.log("handleCreateUserCognitoFrontendResponse", response)
            const response2 = await addUserByAdmin(final_object)
            console.log('response2', response2)
            toast.success("User created successfully")
            setTitle("")
            setName("")
            setRole("")
            setRoleName("")
            setEmail("")
            setPhoneNumber("")
            setCompanyName("")
            setExistingCompany("")
            setExistingCompanyName("")
            setDepartment("")
            setDesignation("")
            setAddress("")

            // if (response.status === 200) {
            //     toast.success("Invite link sent to user");
            // } else {
            //     toast.error("Something went wrong, Check all fields and try again");
            // }
            // toast.success("User created")

        }
        catch (err) {
            console.log("handleCreateUserCognitoFrontendResponseErr", err)
            toast.error(err.message)
        }
        setLoading(false)
    }

    const insertLogsForUser = async () => {
        let { email: loginUserEmail, user_id, name: loginUserName, comp_id } = userDetails.auth.userDetails
        setLoading(true)
        try {
            let payload = {
                userId: user_id,
                userName: loginUserName,
                email: loginUserEmail,
                compId: comp_id,
                // compName: userDetailsLog.comp_name,
                compName: roleName == "super-admin" || roleName == "super-user" ? existingCompanyName : selectedValue == 1 ? existingCompanyName : selectedValue == 2 ? companyName : "",
                actionType: "RegisterUser",
                soNo: "",
                fileType: "",
                docType: "",
                profileName: name,
                title: title,
                designation: designation,
                department: department,
                userType: roleName,
                phoneNo: phoneNumber,
                address: address,
            }
            console.log("userDetailsLog_", payload);
            let logresponse = await insertLogs(payload)
            console.log("logresponse_", logresponse);
        }
        catch (err) {
            console.log("logresponseErr", err);
            setError(err)
        }
        setLoading(false)
    }

    // backend api
    const handleCreateUserCognito = async () => {
        let isValid = dataValidation()
        console.log("handleCreateUserisValid", isValid)

        if (undefined == isValid || isValid == false) {
            return
        }
        let duplicate = cognitoUserList.some((obj) =>
            obj.Attributes.some((item) => item.Name == "email" && item.Value == email)
        )
        console.log("duplicate", duplicate)
        if (duplicate) {
            toast.error("User already exists in cognito")
            return
        }
        let dbUser = await getSingleuser(email)
        console.log("getSingleuserResponse", dbUser)
        if (dbUser.data.data.length == 1) {
            toast.error("User already exists in Database")
            return
        }

        try {
            let final_object =
            {
                title: title,
                username: name,
                roleId: role,
                user_type: roleName,
                email: email,
                isEmailEnabled: isEmailEnabled,
                phone_number: phoneNumber,
                address: address,
                comp_name: roleName == "super-admin" || roleName == "super-user" ? existingCompanyName : selectedValue == 1 ? existingCompanyName : selectedValue == 2 ? companyName : "",
                department: department,
                designation: designation,
                // password: values.password,
                createdBy: "super-admin",
                isVerified: true,
                // temporaryPassword: "12345678"
                temporaryPassword: generateRandomPassword(12),
                isCognito: true
            }
            if (final_object.comp_name == "") {
                toast.error("Please fill all the required fields")
                return
            }
            console.log("final_object", final_object);
            
            
            setLoading(true)
            let response = await cognitoAdminCreateUser(final_object)
            console.log('response', response)
            const response2 = await addUserByAdmin(final_object)
            console.log('response2', response2)
            setLoading(false)
            console.log('cognitoAdminCreateUserResponse', response2)
            if (response2.status === 200) {
                // toast.success("Invite link sent to user");
                insertLogsForUser()
                toast.success("User Created Successfully");
                
            } else {
                toast.error("Something went wrong, Check all fields and try again");
            }
            setTitle("")
            setName("")
            setEmail("")
            setPhoneNumber("")
            setCompanyName("")
            setDepartment("")
            setDesignation("")
            setAddress("")
        }
        catch (err) {
            console.log("err", err)
            toast.error(err.message)
            // toast.error(err.response.data.message, {
            //     // position: "top-right",
            //     autoClose: 5000,
            // });
        }
        setLoading(false)
    }

    return (
        <>
            <Loader loading={loading} />
            <Toaster position="top-center" reverseOrder={false} />
            <ErrorCatch err={error} />
            <div className="Signin mt-2">
                {/* <p className="px-3 mt-3 page-title">Register User</p> */}
                <div className="container-fluid">
                    <div className="row p-2">
                        <div className="col-md-2">
                            <SelectInput
                                margin="0"
                                selectLabel="*Title"
                                name="_title"
                                value={title}
                                onChange={(e) => handleTitle(e)}
                                optionsList={titleList}
                            />
                        </div>
                        <div className="col-md-4 mt-sm-3 mt-md-0">
                            <InputBox
                                label="*Name"
                                name="_name"
                                fullWidth
                                onChange={(e) => handleName(e)}
                                value={name}
                            />
                        </div>
                    </div>

                    <div className="row p-2">
                        <div className="col-md-6">
                            <SelectInput
                                margin="0"
                                selectLabel="*Role"
                                name="_role"
                                value={role}
                                onChange={handleRoleName}
                                optionsList={roleList}
                            />
                        </div>
                    </div>

                    <div className="row p-2">
                        <div className="col-md-6">
                            <InputBox
                                label="*Email"
                                name="_email"
                                fullWidth
                                onChange={(e) => handleEmail(e)}
                                value={email}
                            />
                        </div>
                    </div>

                    <div className="row col-md-3  p-2">
                        <CheckboxComp
                            label="Email Enabled"
                            isChecked={isEmailEnabled}
                            onChange={handleEmailEnabled}
                        />
                    </div>

                    <div className="row p-2">
                        <div className="col-md-6">
                            <InputBox
                                label="Phone Number with Country ID"
                                name="_phone"
                                fullWidth
                                onChange={(e) => handlePhoneNumber(e)}
                                value={phoneNumber}
                            />
                        </div>
                    </div>

                    {
                        roleName == "super-admin" || roleName == "super-user" ?
                            <></>
                            :
                            <>
                                <div className="row p-2">
                                    <div className="col-md-6">
                                        <RadioButton
                                            // radioButtonTitle="Status"
                                            optionsList={statusList}
                                            selectedValue={selectedValue}
                                            handleChange={handleRadioButton}
                                        />
                                    </div>
                                </div>

                                <div className="row p-2">
                                    <div className="col-md-6">
                                        {selectedValue == 1 ?
                                            <SelectInput
                                                margin="0"
                                                selectLabel="*Company"
                                                name="_comp_id"
                                                value={existingCompany}
                                                onChange={handleCustomerName}
                                                optionsList={customerList}
                                            />
                                            :
                                            <InputBox
                                                label="*Company Name"
                                                name="_companyName"
                                                fullWidth
                                                onChange={(e) => handleCompanyName(e)}
                                                value={companyName}
                                            />
                                        }
                                    </div>
                                </div>
                            </>
                    }

                    <div className="row p-2">
                        <div className="col-md-6">
                            <InputBox
                                label="*Department"
                                name="_department"
                                fullWidth
                                onChange={(e) => handleDepartment(e)}
                                value={department}
                            />
                        </div>
                    </div>

                    <div className="row p-2">
                        <div className="col-md-6">
                            <InputBox
                                label="*Designation"
                                name="_designation"
                                fullWidth
                                onChange={(e) => handleDesignation(e)}
                                value={designation}
                            />
                        </div>
                    </div>

                    <div className="row p-2">
                        <div className="col-md-6">
                            <InputBox
                                label="*Address"
                                name="_address"
                                fullWidth
                                onChange={(e) => handleAddress(e)}
                                value={address}
                            />
                        </div>
                    </div>

                    <div className="row mt-3 p-2">
                        <div className="col-md-5 d-flex gap-3">
                            {/* <button
                                className="btn btn-primary"
                                onClick={handleCreateUser}
                            >
                                Create User
                            </button> */}
                            {/* {appObjects["Create User"] && <ButtonComp
                                text="Create User"
                                onClick={handleCreateUser}
                            />} */}

                            {/* Cognito */}
                            {appObjects["Create User Cognito"] && <ButtonComp
                                text="Create User"
                                onClick={handleCreateUserCognito}
                            // onClick={handleCreateUserCognitoFrontend}
                            />}
                        </div>
                    </div>

                </div>
            </div >
        </>
    );
};

export default RegisterUser;