import "../../../../Assets/Styles/AdminDasbord.css";

export const userColumns = [
  {
    field: "_name",
    headerClassName: "super-app-theme--header",
    headerName: "Name",
    // width: 100,
    flex: 0.5,
  },
  {
    field: "_email",
    headerClassName: "super-app-theme--header",

    headerName: "Email",
    // width: 180,
    flex: 0.7,
  },

  {
    field: "_designation",
    headerClassName: "super-app-theme--header",

    headerName: "Designation",
    // width: 90,
    flex: 0.5,
  },
  {
    field: "_department",
    headerClassName: "super-app-theme--header",

    headerName: "Department",
    // width: 100,
    flex: 0.5,
  },

  {
    field: "_comp_name",
    headerClassName: "super-app-theme--header",

    headerName: "Company",
    // width: 100,
    flex: 0.5,
  },

  {
    field: "_user_type",
    headerName: "User Type",
    // width: 100,
    flex: 0.5,
    editable: true,
  },

  {
    field: "_status_id",
    headerName: "Status",
    // width: 100,
    flex: 0.5,
    cellRenderer: (params) => {
       console.log("Coming ...", params);
      let val = params.data._status_id;

      if (val ==="Active") {
        return (
          <div className="btnenable" role="button">
            Active
          </div>
        );
      } else if (val ==="InActive") {
        return (
          <div className="btnDeactive" role="button">
            InActive
          </div>
        );
      } else {
        return (
          <div className="btnpending" role="button">
            Pending
          </div>
        );
      }
    },
  },
];
