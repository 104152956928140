import axios from "axios";
import React, { useEffect, useState } from "react";
import env from "../../../../Services/Environment";
import { useNavigate } from 'react-router-dom';
import { JsonViewer } from '@textea/json-viewer'
import ErrorCatch from "../../../Reusable_Components/ErrorCatch";
const DisplayAPIFormat = (props) => {
  console.log("apiurl", props)
  const [urlState, setURLState] = useState(props.apiurl);
  const [data, setData] = useState();
  const [error, setError] = useState()

  const url = env.Backend_Url + urlState;
  // console.log("url1111", url);
  const getDataUsingURL = async () => {
    try {
      const url = env.Backend_Url + urlState;
      const response = await axios.get(url);
      console.log("res222", response);
      setData(response.data.data1);
    }
    catch (err) {
      setError(err)
    }
  };

  useEffect(() => {
    getDataUsingURL();
  }, [urlState]);

  const navigate = useNavigate()

  return (
    <>
      <ErrorCatch err={error} />
      <div className="conatiner">
        <div style={{ height: "22rem", overflowY: "auto" }}>

          {/* <JsonViewer value={data} /> */}
        </div>
      </div>
    </>
  );
};

export default DisplayAPIFormat;
