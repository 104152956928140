import "../../../Assets/Styles/Myprofile.css";
import profile from "../../../Assets/Images/Avtar.png"
import { getSingleuser } from "../../../Services/AdminServices";
import React, { useState, useEffect } from "react";
import { MdOutlineVerified } from "react-icons/md";
import EditProfilePage from "../Admin/EditProfile";
import MessagePrefrence from "../Admin/Messagesettings";
import MyLogs from '../Admin/AdminLogs';
import ActivityBoard from '../Admin/Activity'
import { Tabs } from 'antd';
import user_profile from "../../../Assets/Images/user_profile.png";
import ImageSlider from "../../Reusable_Components/ImageSlider";
import prd1 from '../../../Assets/Images/alba_prd_1.png';
import prd2 from '../../../Assets/Images/alba_prd_2.png';
import prd3 from '../../../Assets/Images/alba_prd_3.png';
import prd4 from '../../../Assets/Images/alba_prd_4.png';

const SettingsScreen = () => {
  const [MyDetails, setMyDetails] = useState();
  const email = localStorage.getItem("Logindata");
  const [isProfileSettings, setIsProfileSettings] = useState(true)
  const [isMessagePreference, setIsMessagePreference] = useState(true)

  const GetMyProfile = (email) => {
    getSingleuser(email).then((res) => {
      console.log("MyData", res.data.data)
      setMyDetails(res.data.data[0]);
    });
  };
  useEffect(() => {
    GetMyProfile(email);
  }, []);


  const onChange = (key) => {
    console.log("onChangeSettingTab", key);
    if (key == 1) {
      setIsProfileSettings(true)
      setIsMessagePreference(false)
    }
    else if (key == 3) {
      setIsMessagePreference(true)
      setIsProfileSettings(false)
    }
  };


  // return (
  //   <>
  //     <div className="container">
  //       {/* <h6 className="px-2 mt-3">
  //         <b className="mt-3 ">Account Settings </b>
  //       </h6> */}
  //       <p className="page-title mt-1">Account Setting</p>
  //       <div className="row  topCard mb-4 ">
  //         <div className="col-md-3  d-flex justify-content-center">
  //           <img src={profile} alt="avtar" className="img-fluid avtarimg" />
  //         </div>
  //         <div className="col-md-6 d-flex justify-content-start">
  //           <div className="toptext mt-3">
  //             <h5>
  //               <b>{MyDetails?._name}</b>
  //               <MdOutlineVerified />
  //             </h5>
  //             <text>{MyDetails?._email}</text>
  //             <h5>
  //               <b>{MyDetails?._designation}</b>
  //             </h5>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="row mt-5">
  //         <div className="col d-flex justify-content-center settingstab mt-3 pb-5">
  //         <Tabs defaultActiveKey="1"  centered items={items} onChange={onChange} className="w-50 text-center" />

  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
  const images = [
    prd1, prd2, prd3, prd4
  ]
  const images1 = [
    prd2, prd4, prd1, prd3
  ]
  const images2 = [
    prd4, prd3, prd2, prd1
  ]

  return (
    <>
      <div className="container-fluid mt-2 px-4">
        <div className="d-flex col-md-12 col-lg-12 col-sm-12">
          <div className="col-md-4 col-lg-4 col-sm-12">
            <ImageSlider images={images} className={'slidecontainer'} />
          </div>
          <div className="col-md-4 col-lg-4 px-2 col-sm-12">
            <ImageSlider images={images1} className={'slidecontainer'} />
          </div>
          <div className="col-md-4 col-lg-4 col-sm-12">
            <ImageSlider images={images2} className={'slidecontainer'} />
          </div>
        </div>
      </div>
      <div className="mt-3 container-fluid">
        <div className="col d-flex justify-content-center p-2">
          <img
            src={user_profile}
            alt="user profile"
            className="img-fluid"
            style={{ maxWidth: "35px", maxHeight: "33px" }}
          />
          <div className="px-3 heading-text">Profile Information</div>
        </div>
        <div className="col-md-12 justify-content-center px-4">
          <EditProfilePage isProfileSettings={isProfileSettings} />
        </div>
      </div>
    </>
  )

};
export default SettingsScreen;
