// Slideshow.js

import React, { useState, useEffect } from 'react';
import prd1 from '../../Assets/Images/alba_prd_1.png';
import prd2 from '../../Assets/Images/alba_prd_2.png';
import prd3 from '../../Assets/Images/alba_prd_3.png';
import prd4 from '../../Assets/Images/alba_prd_4.png';
import { Carousel } from 'antd';

const ImageSlider = ({ images, className }) => {

    return (
        <div className={className}>
            <Carousel autoplay={true} autoplaySpeed={5000}>
                {images?.map((img) => {
                    return (
                        <img src={img} className='slideimg'></img>
                    )
                })}
                {/* <img src={prd1} className='slideimg'></img>
                        <img src={prd2} className='slideimg'></img>
                        <img src={prd3} className='slideimg'></img>
                        <img src={prd4} className="slideimg" /> */}
            </Carousel>
        </div>
    );
};

export default ImageSlider;
