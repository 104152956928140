import { useEffect, useState } from "react";
import right from "../../../Assets/Images/accept.png"
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import env from "../../../Services/Environment";
import { useNavigate } from "react-router-dom";
import { messages } from "../../Messages";
import { activateCompany } from "../../../Services/AdminServices";
import Loader from "../../../utils/Loader/Loader";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";

const Warning = ({ companydetails, handleCancel, getRequestedCompany }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const Navigate = useNavigate();

    const ClosePopup = () => {
        handleCancel()
    }

    const Statusbtn = async (comp_status_01) => {
        let company_status_id = 1
        const {
            address,
            comp_id,
            comp_name,
            email,
            name,
            phone,
            status_id,
            user_id,
            user_type,

        } = companydetails

        let sendData = {
            address,
            comp_id,
            comp_name,
            company_status_id,
            email,
            name,
            phone,
            status_id,
            user_id,
            user_type,
        }

        console.log("sendData", sendData)
        setLoading(true)
        try {
            const res = await activateCompany(sendData)
            console.log("activatecompres", res)
            if (res.status == 200) {
                // toast.success("Company is now activated.")
                toast.success(res.data.msg)
                ClosePopup()
            }
            else {
                toast.error(messages.somethingWrong)
            }
        }
        catch (error) {
            console.log("activatecomperror", error)
            setError(error)
        }
        getRequestedCompany()
        setLoading(false)
    }

    return (
        <>
            <Loader loading={loading} />
            <Toaster position="top-center" reverseOrder={false} />
            <ErrorCatch err={error} />
            <div className="container">
                <div className="d-flex justify-content-center">
                    <img src={right} alt="validation" />
                </div>
                <p className="text-center mt-2"> <h6><b>Are you sure you want to activate this company?</b></h6></p>
                <div className="d-flex justify-content-center gap-3 mt-3">
                    <button className="btn btn-success btn-sm" onClick={() => { Statusbtn('comp_status_01') }} >
                        Yes
                    </button>
                    <button className="btn btn-danger btn-sm" value={"comp_status_02"}
                        onClick={handleCancel}
                    >
                        No
                    </button>
                </div>
            </div>
        </>
    )
}

export default Warning