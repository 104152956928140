import { useEffect, useState } from "react";

function TextPreviewer({ file }) {
    const [content, setContent] = useState('');

    useEffect(() => {
        const fetchBlob = async () => {
            if (file) {
                let blob;

                // If file is a Blob URL
                if (file.startsWith('blob:')) {
                    const response = await fetch(file); // Fetch the Blob URL
                    blob = await response.blob(); // Get the Blob from the response
                } else {
                    blob = file; // Assuming file is already a Blob
                }

                if (blob instanceof Blob) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        setContent(e.target.result);
                    };
                    reader.readAsText(blob);
                } else {
                    setContent('Invalid file or no file selected.');
                }
            }
        };

        fetchBlob();
    }, [file]);

    return (
        <div style={{ height: "500px" }}>
            <pre
                style={{ width: '100%', height: "inherit" }}
            >{content}</pre>
        </div>
    );
}

export default TextPreviewer;
