import React, { useState, useEffect, useCallback } from "react";
import { Form } from "antd";
import axios from "axios";
import env from "../../../Services/Environment";
import toast, { Toaster } from "react-hot-toast";
import { TextField } from "@mui/material";
import { getSingleuser, insertLogs, updateUserDetails } from "../../../Services/AdminServices";
import InputBox from "../../Reusable_Components/InputBox";
import BasicButton from "../../Reusable_Components/Button";
import Loader from "../../../utils/Loader/Loader";
import { messages } from "../../Messages";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
import SelectInput from "../../Reusable_Components/SelectInput";
// import ToasterComponent from "../../Reusable_Components/ToastContainer";
import debounce from 'lodash/debounce';
import MessagePrefrence from "./Messagesettings";
import ButtonComp from "../../Reusable_Components/ButtonComp";
import { useSelector } from "react-redux";
import AWS from 'aws-sdk';
import { cognitoUpdateUserDetails } from "../../../Services/CognitoServices";

const EditProfilePage = ({ isProfileSettings }) => {
  const [loading, setLoading] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const [isMessagePreference, setIsMessagePreference] = useState(true)
  const [error, setError] = useState()
  const email = localStorage.getItem("Logindata");
  const [inputData, setInputData] = useState({
    _name: "",
    _title: "",
    _designation: "",
    _department: "",
    _phone: "",
    _address: "",
    _email: "",
  });

  const [updateItems, setUpdatedItems] = useState({
    _name: false,
    _title: false,
    _designation: false,
    _department: false,
    _phone: false,
    _address: false,
  })

  const [titleId, setTitleId] = useState()
  const titleList = [{
    name: "Mr.",
    value: 1
  }, {
    name: "Ms.",
    value: 2
  }]
  let toastId = null

  // Configure AWS SDK
  // AWS.config.update({ region: process.env.REACT_APP_AWS_COGNITO_REGION })
  AWS.config.update({
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_COGNITO_ACCESS_KEYID,
      secretAccessKey: process.env.REACT_APP_COGNITO_SECRET_ACCESS_KEY
    }
  })
  const cognito = new AWS.CognitoIdentityServiceProvider()

  // uam role object start
  const userDetails = useSelector((state) => state)
  const appsubmodulename = "Settings"
  const appfunctionalityname = "Settings"
  const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
  const [appObjects, setAppObjects] = useState(
    {
      "Multifactor Authentication": false,
      "Submit": false
    }
  )
  console.log("userDetails", userDetails)

  const updateAppObjects = (arr) => {
    let objectsArray = []
    arr.map((obj) => {
      console.log("updateButtons", obj)
      // if (obj.objecttypename == "button") {
      objectsArray.push(obj)
      // }
    })
    const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
      acc[appobjectname] = true;
      return acc;
    }, {});
    setAppObjects({ ...appObjects, ...finalObject })
    console.log("objectsArray", objectsArray)
    console.log("finalObject", finalObject)
  }

  useEffect(() => {
    console.log("appObjects", appObjects)
  }, [appObjects])

  useEffect(() => {
    console.log("userRoleObjects", userRoleObjects)
    const filteredList = []
    userRoleObjects.filter((obj) => {
      if (obj.appsubmodulename == appsubmodulename && obj.appfunctionalityname == appfunctionalityname) {
        filteredList.push(obj)
      }
    })

    console.log("filteredList", filteredList)
    updateAppObjects(filteredList)

  }, [])

  // uam role object end

  // useEffect(() => {
  //   if (isProfileSettings) {
  //     getSingleDataByEmail();
  //   }
  // }, [isProfileSettings]);

  useEffect(() => {
    getSingleDataByEmail()

  }, []);

  const getSingleDataByEmail = async () => {
    setLoading(true)
    try {
      const response = await getSingleuser(email)
      console.log("getSingleDataByEmail", response)
      setInputData(response.data.data[0]);
      let titleData = titleList.filter(obj => obj.name === response.data.data[0]._title)[0].value
      console.log("titleData", titleData)
      setTitleId(titleData)
    }
    catch (err) {
      setError(err)
    }
    setLoading(false)
  };

  const [componentSize, setComponentSize] = useState("default");
  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };

  // const handleInput = (e) => {
  //   const { name, value } = e.target;
  //   setInputData({ ...inputData, [name]: value });
  // };

  const handleTitle = (e) => {
    const { name, value } = e.target;
    console.log("handleTitle", value)
    setTitleId(value)
    let data = titleList.filter(obj => obj.value == value)
    console.log("exComdata", data)
    setInputData({ ...inputData, "_title": data[0].name })
    setIsChanged(true)
    setUpdatedItems({ ...updateItems, _title: true })
  };

  const onChange = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    console.log("onChangeName", name)
    setIsChanged(true)
    if (name === "_phone") {
      value = value.replace(/[^0-9+]/g, '')
      setInputData({ ...inputData, [name]: value });
      setUpdatedItems({ ...updateItems, [name]: true })

    }
    else {
      // value = value.replace(/[^A-Za-z]/ig, '')
      value = value.replace(/[^A-Za-z ]*$/ig, '')
      setInputData({ ...inputData, [name]: value });
      setUpdatedItems({ ...updateItems, [name]: true })
    }

  }

  const onChangeforaddress = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    setInputData({ ...inputData, [name]: value });
    setUpdatedItems({ ...updateItems, [name]: true })
    setIsChanged(true)
  }

  const showSuccessToast = useCallback(debounce(() => {
    if (!toastId) {
      toastId = toast.success(messages.updatedSuccess, {
        id: 'update-success-toast',
        onClose: () => { toastId = null; }
      });
    }
  }, 300), []);

  // --- frontend ---
  const cognitoUpdateUserDetailsfun = async () => {
    if (!isChanged) {
      toast("There are no changes to update")
      return
    }
    console.log("inputData", inputData)
    let isEmpty = Object.values(inputData).some((obj) => typeof (obj) == "string" && obj == "")
    let isEmpty2 = Object.values(inputData).some((obj) => console.log("emptyobj", typeof (obj)))
    console.log("isEmpty", isEmpty)
    if (isEmpty) {
      toast.error(messages.fillAllFields);
      return
    }

    const { _email, _name, _phone, _designation, } = inputData
    const params = {
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      Username: _email,
      UserAttributes: [
        {
          Name: 'name',
          Value: _name,
        },
        {
          Name: 'phone_number',
          Value: _phone
        },
        {
          Name: 'custom:designation',
          Value: _designation,
        },
      ]
    }

    try {
      const response = await cognito.adminUpdateUserAttributes(params).promise();
      console.log('cognitoUpdateUserDetailsResponse', response);
      const response2 = await updateUserDetails(inputData)
      console.log("response2", response2)
      toast.success(messages.updatedSuccess, {
        id: "unique-id",
      });
    } catch (error) {
      console.error('cognitoUpdateUserDetailsError', error);
      setError(error)
    }
  }

  const insertLogsForUser = async () => {
    let { email: loginUserEmail, user_id, name: loginUserName, comp_id, comp_name } = userDetails.auth.userDetails
    let { _name, _title, _designation, _department, _phone, _address } = inputData
    let { _name: isName, _title: isTitle, _designation: isDesignation, _department: isDepartment, _phone: isPhone, _address: isAddress } = updateItems
    setLoading(true)
    try {
      let payload = {
        userId: user_id,
        userName: loginUserName,
        email: loginUserEmail,
        compId: comp_id,
        compName: comp_name,
        actionType: "Profile Change",
        soNo: "",
        fileType: "",
        docType: "",
        profileName: isName ? _name : "",
        title: isTitle ? _title : "",
        designation: isDesignation ? _designation : "",
        department: isDepartment ? _department : "",
        userType: "",
        phoneNo: isPhone ? _phone : "",
        address: isAddress ? _address : "",
      }
      console.log("userDetailsLog_", payload);
      let logresponse = await insertLogs(payload)
      console.log("logresponse_", logresponse);
    }
    catch (err) {
      console.log("logresponseErr", err);
      setError(err)
    }
    setLoading(false)
  }

  // --- backend ---
  const updateUserDetailsCognito = async () => {
    if (!isChanged) {
      toast("There are no changes to update")
      return
    }
    console.log("inputData", inputData)
    let isEmpty = Object.values(inputData).some((obj) => typeof (obj) == "string" && obj == "")
    let isEmpty2 = Object.values(inputData).some((obj) => console.log("emptyobj", typeof (obj)))
    console.log("isEmpty", isEmpty)
    if (isEmpty) {
      toast.error(messages.fillAllFields);
      return
    }

    const { _email, _name, _phone, _designation, } = inputData
    const params = {
      _email: _email,
      userAttributes: [
        {
          Name: 'name',
          Value: _name,
        },
        {
          Name: 'phone_number',
          Value: _phone
        },
        {
          Name: 'custom:designation',
          Value: _designation,
        },
      ]
    }
    
    try {
      const response = await cognitoUpdateUserDetails(params)
      console.log('cognitoUpdateUserDetailsResponse', response);
      if (response.status == 200) {
        const response2 = await updateUserDetails(inputData)
        console.log("response2", response2)
        if (response2.status == 200) {
          insertLogsForUser()
          toast.success(messages.updatedSuccess, {
            id: "unique-id",
          });
        }
      }

    } catch (error) {
      console.error('cognitoUpdateUserDetailsError', error);
      setError(error)
    }
  }

  const onSubmitCreateUpdate = async () => {
    if (!isChanged) {
      toast("There are no changes to update")
      return
    }
    // e.preventDefault();
    console.log("inputData", inputData)
    let isEmpty = Object.values(inputData).some((obj) => typeof (obj) == "string" && obj == "")
    let isEmpty2 = Object.values(inputData).some((obj) => console.log("emptyobj", typeof (obj)))
    console.log("isEmpty", isEmpty)
    if (isEmpty) {
      toast.error(messages.fillAllFields);
      return
    }
    setLoading(true)
    try {
      const response = await updateUserDetails(inputData)
      console.log("updateUserDetails", response)
      // showSuccessToast()
      toast.success(messages.updatedSuccess, {
        id: "unique-id",
        // onClose: () => { toastId = null; }
      });

      // if (!toastId) {
      //   toast.success(messages.updatedSuccess, {
      //     id: "unique-id",
      //     onClose: () => { toastId = null; }
      //   });
      // }

      // if (response.data.success === true) {


      // } else {
      //   toast.error(messages.somethingWrong);
      // }
    }
    catch (err) {
      setError(err)
    }
    setLoading(false)
  };

  return (
    <>
      <Loader loading={loading} />
      <Toaster position="top-center" reverseOrder={false} />
      {/* <ToasterComponent /> */}
      <ErrorCatch err={error} />
      <div className="card mb-2 p-4">
        <div className="col-md-12">
          {/* <InputBox
            label="*Title"
            name="_title"
            fullWidth
            onChange={(e) => onChange(e)}
            value={inputData?._title}
          /> */}
          <div className="row">
            {titleId &&
              <div className="col-md-3 p-3">
                <SelectInput
                  margin="0"
                  fullWidth
                  selectLabel="*Title"
                  name="_title"
                  value={titleId}
                  onChange={handleTitle}
                  optionsList={titleList}
                />
              </div>}
            <div className="col-md-9 p-3">
              <InputBox
                label="*Name"
                name="_name"
                fullWidth
                onChange={(e) => onChange(e)}
                value={inputData?._name}
              />
            </div>
          </div>

          {/* <div className="mt-4">
          <div className="row">
            <div className="col-md-12 p-2">
              <InputBox
                label="*Name"
                name="_name"
                fullWidth
                onChange={(e) => onChange(e)}
                value={inputData?._name}
              />
            </div>
          </div>
        </div> */}


          <div className="row">
            <div className="col-md-6 p-3">
              <InputBox
                label="Email"
                name="_email"
                fullWidth
                onChange={(e) => onChange(e)}
                value={inputData?._email}
                disabled
              />
            </div>
            <div className="col-md-6 p-3">
              <InputBox
                // label="*Role"
                label="*Designation"
                name="_designation"
                fullWidth
                onChange={(e) => onChange(e)}
                value={inputData?._designation}
              />
            </div>
          </div >

          <div className="row">
            <div className="col-md-6 p-3">
              <InputBox
                label="*Phone Number with Country Id"
                name="_phone"
                fullWidth
                // onChange={(e) => handleInput(e)}
                onChange={(e) => onChange(e)}
                value={inputData?._phone}
              />
            </div>
            <div className="col-md-6 p-3">
              <InputBox
                label="*Address"
                name="_address"
                fullWidth
                onChange={(e) => onChangeforaddress(e)}
                value={inputData?._address}
              />
            </div>
          </div >

          {/* <div className="row p-2">
            <div className="col-md-6">
              {appObjects["Multifactor Authentication"] && <MessagePrefrence isMessagePreference={isMessagePreference} />}
            </div>
          </div> */}

          <div className="mt-4 d-flex justify-content-end gap-3">
            {/* <BasicButton
              text="Submit"
              onClick={onSubmitCreateUpdate}
            /> */}

            {appObjects["Submit"] && <ButtonComp
              text="Submit"
              // onClick={onSubmitCreateUpdate}
              onClick={updateUserDetailsCognito}
            />}

            {/* <ButtonComp
              text="Cancel"
              type="secondary"
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfilePage;
