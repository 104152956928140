import "../../../Assets/Styles/Myprofile.css";
import profile from "../../../Assets/Images/Avtar.png";
import React, { useState, useEffect } from "react";
import { changePassword, getSingleuser } from "../../../Services/AdminServices";
import { MdOutlineVerified } from 'react-icons/md';
import { useNavigate } from 'react-router-dom'
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
import InputBox from "../../Reusable_Components/InputBox";
import Loader from "../../../utils/Loader/Loader";
import CryptoJS from 'crypto-js';
import { Modal } from "antd";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import AWS from 'aws-sdk';
import { cognitoChangeUserPassword, cognitoChangeUserPasswordAuthChallenge } from "../../../Services/CognitoServices";


const UserProfile = () => {
  const navigate = useNavigate()
  const [MyDetails, setMyDetails] = useState();
  const email = localStorage.getItem("Logindata");
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const [isPasswordModal, setIsPasswordModal] = useState(false)
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [isPasswordStrong, setIsPasswordStrong] = useState(false)
  const [isPasswordMatched, setIsPasswordMatched] = useState(false)
  const [confirmPasswordError, setConfirmPasswordError] = useState("")

  AWS.config.update({
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_COGNITO_ACCESS_KEYID,
      secretAccessKey: process.env.REACT_APP_COGNITO_SECRET_ACCESS_KEY
    }
  });

  const cognito = new AWS.CognitoIdentityServiceProvider();
  let challenge = localStorage.getItem("ChallengeName")
  let challengeSession = localStorage.getItem("ChallengeSession")
  let cognitoAcessToken = localStorage.getItem("cognitoAccessToken")

  // uam role object start
  const userDetails = useSelector((state) => state)
  const appsubmodulename = "Settings"
  const appfunctionalityname = "Profile"
  const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
  const [appObjects, setAppObjects] = useState(
    {
      "Change Password": false,
      "Edit Profile": false
    }
  )
  console.log("userDetails", userDetails)

  const updateAppObjects = (arr) => {
    let objectsArray = []
    arr.map((obj) => {
      console.log("updateButtons", obj)
      // if (obj.objecttypename == "button") {
      objectsArray.push(obj)
      // }
    })
    const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
      acc[appobjectname] = true;
      return acc;
    }, {});
    setAppObjects({ ...appObjects, ...finalObject })
    console.log("objectsArray", objectsArray)
    console.log("finalObject", finalObject)
  }

  useEffect(() => {
    console.log("appObjects", appObjects)
  }, [appObjects])

  useEffect(() => {
    console.log("userRoleObjects", userRoleObjects)
    const filteredList = []
    userRoleObjects.filter((obj) => {
      if (obj.appsubmodulename == appsubmodulename && obj.appfunctionalityname == appfunctionalityname) {
        filteredList.push(obj)
      }
    })

    console.log("filteredList", filteredList)
    updateAppObjects(filteredList)

  }, [])

  // uam role object end

  const getMyProfile = (email) => {
    setLoading(true)
    try {
      getSingleuser(email).then((res) => {
        // console.log("MyData", res.data.data)
        setMyDetails(res.data.data[0]);
      });
    }
    catch (err) {
      setError(err)
    }
    setLoading(false)
  };
  useEffect(() => {
    getMyProfile(email);
  }, []);

  const moveToEdit = () => {
    navigate('/usersettings')
  }

  const changePasswordModal = () => {
    setIsPasswordModal(true)
  }

  const closePasswordModal = () => {
    setIsPasswordModal(false)
    setCurrentPassword("")
    setNewPassword("")
    setConfirmPassword("")
    setPasswordError("")
    setIsPasswordStrong(false)
    setIsPasswordMatched(false)
    setConfirmPasswordError("")
  }

  const handleCurrentPassword = (e) => {
    console.log("handleCurrentPassword", e.target.value)
    let value = e.target.value
    setCurrentPassword(value)
  }

  const handleNewPassword = (e) => {
    console.log("handleNewPassword", e.target.value)
    let value = e.target.value
    setNewPassword(value)
    setIsPasswordStrong(checkPasswordStrength(value))
  }

  useEffect(() => {
    if (confirmPassword !== "") {
      setIsPasswordMatched(confirmPassword === newPassword ? true : false)
      setConfirmPasswordError(confirmPassword === newPassword ? "Match Confirmed" : "Passwords do not match.")
    }
    else {
      setIsPasswordMatched(false)
      setConfirmPasswordError("")
    }
  }, [newPassword])

  const handleConfirmPassword = (e) => {
    console.log("handleConfirmPassword", e.target.value)
    let value = e.target.value
    setConfirmPassword(value)
    setIsPasswordMatched(value === newPassword ? true : false)
    setConfirmPasswordError(value === newPassword ? "Match Confirmed" : value !== newPassword ? "Passwords do not match." : "")
  }

  // --- frontend --- 
  const changeUserPasswordAuthChallengeCognito = async () => {
    try {
      const challengeParams = {
        // ChallengeName: 'NEW_PASSWORD_REQUIRED',
        ChallengeName: challenge,
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        Session: challengeSession,
        ChallengeResponses: {
          NEW_PASSWORD: newPassword, // The new password provided by the user
          USERNAME: email,
          // Include any additional required attributes if necessary
        }
      }
      const respondToAuthChallengeResponse = await cognito.respondToAuthChallenge(challengeParams).promise();
      console.log("RespondToAuthChallengeResponse", respondToAuthChallengeResponse);
    }
    catch (err) {
      console.log("RespondToAuthChallengeErr", err);
    }
  }

  // const encrypt = (plaintext) => {
  //   let encryptedPassword = CryptoJS.AES.encrypt(plaintext, process.env.REACT_APP_SECRET_KEY).toString();
  //   return encryptedPassword
  // };

  const encrypt = (plaintext) => {
    console.log("encryptPlaintext", plaintext);
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(plaintext))
      .replace(/=+$/, '') // Remove padding
      .replace(/\+/g, '-') // Replace + with -
      .replace(/\//g, '_'); // Replace / with _
  };

  // --- backend --- 
  const cognitoChangePasswordAuthChallenge = async () => {
    try {
      const challengeParams = {
        challenge: challenge,
        challengeSession: challengeSession,
        newPassword: encrypt(newPassword), // The new password provided by the user
        email: email,
      }
      let response = await cognitoChangeUserPasswordAuthChallenge(challengeParams)
      console.log("passwordAuthchallenge_response_", response)
      if (response.status == 200) {
        toast.success("Password Updated Successfully")
      }
      if (response?.data?.data?.AuthenticationResult) {
        let { AccessToken, IdToken, RefreshToken } = response.data.data.AuthenticationResult
        localStorage.setItem("ChallengeName", '')
        localStorage.setItem("cognitoAccessToken", AccessToken)
        localStorage.setItem("cognitoIdToken", IdToken)
        localStorage.setItem("cognitoRefreshToken", RefreshToken)
      }
    } catch (error) {
      console.log("passwordChallengeError_", error)
      setError(error)
    }
  }

  // --- frontend ---
  const changeUserPasswordCognito = async () => {
    try {
      const params = {
        AccessToken: cognitoAcessToken,
        PreviousPassword: currentPassword,
        ProposedPassword: newPassword
      }
      const response = await cognito.changePassword(params).promise();
      console.log("changeUserPasswordCognitoResponse", response);
    }
    catch (err) {
      console.log("changeUserPasswordCognitoErr", err);
    }
  }

  // --- backend ---
  const cognitoUserPasswordChange = async () => {
    try {
      const params = {
        cognitoAcessToken: cognitoAcessToken,
        currentPassword: encrypt(currentPassword),
        newPassword: encrypt(newPassword)
      }
      let response = await cognitoChangeUserPassword(params)
      console.log("cognitouserpass_response", response);
      if (response.status == 200) {
        toast.success("Password Updated Successfully")
      }
    } catch (error) {
      console.log("changePasswordError_", error);
      setError(error)
    }
  }

  const changeUserPassword = async () => {
    if (currentPassword === newPassword) {
      toast.error("The new password and the current password are same. Please change the new password.")
      return
    }

    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match.")
      return
    }
    let sendData = {
      "email": email,
      "currentPassword": CryptoJS.SHA256(currentPassword).toString(),
      "newPassword": CryptoJS.SHA256(newPassword).toString()
    }
    setLoading(true)
    try {
      if (challenge == 'NEW_PASSWORD_REQUIRED') {
        const authChallengeResponse = await cognitoChangePasswordAuthChallenge()
        console.log("authChallengeResponse", authChallengeResponse)
        // const response = await changePassword(sendData)
        // console.log("changePasswordRes", response)
        // toast.success(response.data.message)
        // toast.success("Password changed successfully")
        closePasswordModal()
      }
      else if (challenge == '' || challenge !== 'NEW_PASSWORD_REQUIRED') {
        const changePasswordNormal = await cognitoUserPasswordChange()
        console.log("changePasswordNormalResponse", changePasswordNormal)
        // const response = await changePassword(sendData)
        // console.log("changePasswordRes", response)
        // toast.success(response.data.message)
        // toast.success("Password changed successfully")
        closePasswordModal()
      }
    }
    catch (err) {
      console.log("changePasswordErr", err)
      if (err?.response) {
        toast.error(err.response.data.message)
      }
      else {
        toast.error(err.message)
      }
    }
    setLoading(false)
  }

  const passwordRequirements = {
    minLength: 8,
    maxLength: 128,
    hasUpperCase: true,
    hasLowerCase: true,
    hasNumbers: true,
    hasSpecialChar: true,
  };

  function checkPasswordStrength(password) {
    const { minLength, maxLength, hasUpperCase, hasLowerCase, hasNumbers, hasSpecialChar } = passwordRequirements;

    if (currentPassword === password) {
      setPasswordError("The new password cannot be the same as the old password.")
      return false
    }

    if (password.length < minLength) {
      setPasswordError(`Password must be at least ${minLength} characters long.`);
      return false;
    }
    if (password.length > maxLength) {
      setPasswordError(`Password must be no more than ${maxLength} characters long.`);
      return false;
    }
    if (hasUpperCase && !/[A-Z]/.test(password)) {
      setPasswordError("Password must contain at least one uppercase letter.");
      return false;
    }
    if (hasLowerCase && !/[a-z]/.test(password)) {
      setPasswordError("Password must contain at least one lowercase letter.");
      return false;
    }
    if (hasNumbers && !/[0-9]/.test(password)) {
      setPasswordError("Password must contain at least one number.");
      return false;
    }
    if (hasSpecialChar && !/[!@#$%^&*_(),.?":{}|<>]/.test(password)) {
      setPasswordError("Password must contain at least one special character.");
      return false;
    }

    setPasswordError(password.length > 15 ? "Password strength is strong." : "Password strength is good.");
    return true;
  }

  return (
    <>
      <Loader loading={loading} />
      <Toaster position="top-center" reverseOrder={false} />
      <ErrorCatch err={error} />
      <div className="container">
        <h6>
          <b>Account Overviews</b>
        </h6>
        <div className="row  topCard mb-4 ">
          <div className="col-md-3  d-flex justify-content-center">
            <img src={profile} alt="avtar" className="img-fluid avtarimg" />
          </div>
          <div className="col-md-6 d-flex justify-content-start">
            <div className="toptext mt-3">

              <h5 >
                <b>{MyDetails?._name}</b>
                <MdOutlineVerified />
              </h5>
              <text>{MyDetails?._email}</text>
              <h5><b>{MyDetails?._designation}</b></h5>
            </div>
          </div>
        </div>
        <br />
        <div className="mt-3">

          <div class="card">
            <div class="card-header">
              <div className="row">
                <div className="col-md-6">
                  <b className="mt-5">Profile Details</b>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                  <div className="p-1">
                    {appObjects["Change Password"] && <button type="button"
                      class="btn btn-primary btn-sm"
                      onClick={changePasswordModal}
                    >Change Password</button>}
                  </div>
                  <div className="p-1">
                    {appObjects["Edit Profile"] && <button type="button"
                      class="btn btn-primary btn-sm"
                      onClick={moveToEdit}
                    >Edit Profile</button>}
                  </div>
                </div>
              </div>


            </div>
            <div class="card-body">
              <blockquote class="blockquote mb-0">
                <table class="table table-borderless">
                  <tr className="profiletable">
                    {" "}
                    <td className="profiletable">Full Name</td>
                    <td className="profiletable">{MyDetails?._name}</td>
                  </tr>
                  <tr className="profiletable">
                    <td className="profiletable">Designation</td>
                    <td className="profiletable"> {MyDetails?._designation}</td>
                  </tr>

                  <tr className="profiletable">
                    <td className="profiletable">Phone</td>
                    <td className="profiletable">{MyDetails?._phone}</td>
                  </tr>

                  <tr className="profiletable">
                    <td className="profiletable">Address</td>
                    <td className="profiletable">{MyDetails?._address}</td>
                  </tr>

                  <tr className="profiletable">
                    <td className="profiletable">Allow Changes</td>
                    <td className="profiletable">Yes</td>
                  </tr>
                </table>
              </blockquote>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="p-2"
        title="Update Password"
        open={isPasswordModal}
        // onOk={handleOk}
        onCancel={closePasswordModal}
        footer={null}
        maskClosable={false}
      >
        <div className="row mt-2 p-2">
          <InputBox
            fullWidth
            name="current_password"
            value={currentPassword}
            type={"password"}
            onChange={handleCurrentPassword}
            // onChange={(e) => handleParamName(e)}
            label="Current Password"
          />
        </div>
        <div className="row mt-3 p-2">
          <InputBox
            fullWidth
            name="new_password"
            value={newPassword}
            type={"password"}
            onChange={handleNewPassword}
            label="New Password"
          />
          <p className="ml-1"
            style={{ color: isPasswordStrong ? "green" : "red" }}
          >{passwordError}</p>
        </div>
        <div className="row p-2">
          <InputBox
            fullWidth
            name="confirm_password"
            value={confirmPassword}
            type={"password"}
            onChange={handleConfirmPassword}
            label="Confirm Password"
          />
          <p className="ml-1"
            style={{ color: isPasswordMatched ? "green" : "red" }}
          >{confirmPasswordError}</p>
        </div>
        <div className="row p-2">
          <div className="col-md-4">
            <button
              className="btn btn-primary btn-sm"
              onClick={changeUserPassword}
              disabled={currentPassword && isPasswordStrong && isPasswordMatched ? false : true}
            >Submit</button>
          </div>
        </div>
      </Modal>

    </>
  );
};

export default UserProfile;
