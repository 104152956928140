import user from "../../../Assets/Images/singleuser.png";
import setrole from "../../../Assets/Images/setrole.png";
import download from "../../../Assets/Images/download.png";
import { useNavigate } from "react-router-dom";
const HowItWorks = () => {
  const navigate = useNavigate();
  const ReadMorepage = () => {
    navigate("/howitswork");
  };
  return (
    <>
      <h6 className="d-flex justify-content-center">
      How it's Works
      </h6>

      <div className="row mt-5">
        <div className="col  justify-content-center">
          <p className="text-center">
            {" "}
            <img src={user} alt="" style={{ width: "3rem" }} />
          </p>
          <h6 className="justify-content-center">Create An Account</h6>
        </div>
        <div className="col ">
          <p className="text-center">
            <img src={setrole} alt="" style={{ width: "3rem" }} />
          </p>
          <h6 className="text-center">Set Rules</h6>
        </div>
        <div className="col">
          <p className="text-center">
            <img src={download} alt="" style={{ width: "3rem" }} />
          </p>
          <h6 className="text-center">Download</h6>
        </div>
      </div>
      <br />
      <hr className="mt-3 bt-5" />
      <div className="  mt-4 d-flex justify-content-center">
        <button type="button" class="btn btn-primary" onClick={ReadMorepage}>
       
          Read More
        </button>
      </div>
    </>
  );
};

export default HowItWorks;
