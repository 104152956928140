import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './Assets/Styles/Color.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import store, { persistor } from './redux/store/configureStore';
import { PersistGate } from 'redux-persist/integration/react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import ImageSlider from './Components/Reusable_Components/ImageSlider';

// import { LicenseManager } from 'ag-grid-enterprise';
// LicenseManager.setLicenseKey('CompanyName=Aluminium Bahrain,LicensedGroup=AlbaMES,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=0,AssetReference=AG-014907,ExpiryDate=14_April_2022_[v2]_MTY0OTg5MDgwMDAwMA==7ff5c77e86dee5377aee2cb4032197c8');

const root = ReactDOM.createRoot(document.getElementById('root'));
const msalInstance = new PublicClientApplication(msalConfig);

root.render(

  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
