import { DataGrid } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Modal } from "antd";
import { FcDocument, FcKey } from "react-icons/fc";
import { GrFormView } from "react-icons/gr";
import { Input } from "antd";
import { JsonViewer } from "@textea/json-viewer";
import { getRequestedData, getUserRules, requestedRulesforRuleID, getRuleListForSuperAdmin } from "../../../../Services/AdminServices";
import Loader from "../../../../utils/Loader/Loader";
import ReactJson from 'react-json-view'
import { LuView } from "react-icons/lu";
import { darken, lighten, styled } from '@mui/material/styles';
import approvereject from "../../../../Assets/Images/approverejeact.png"
import AdminAddNewRule from "./AdminAddNewRule";
import SetRulesForDocuments from "../../CustomerAdmin/Document/SetRulesforDocuments";
import SetRulesForKeys from "../../CustomerAdmin/Document/SetRulesForKeys";
import ChangeRuleStatus from "../ChangeRuleStatus";
import { RulesColData } from "../../CustomerAdmin/Document/RulesSourceData";
import ViewRule from "./ViewRule";
import { FaEdit } from 'react-icons/fa';
import { AiFillEdit } from 'react-icons/ai';
import { MdOutlinePreview } from "react-icons/md";


import AdminEditNewRule from "./AdminEditNewRule";
import SetDocumentHeaders from "../../CustomerAdmin/Document/SetDocumentHeaders";
import BasicButton from "../../../Reusable_Components/Button";
import InputBoxAntd from "../../../Reusable_Components/InputBoxAntd";
import ErrorCatch from "../../../Reusable_Components/ErrorCatch";
import AdminAddColumns from "./AdminAddColumns";
import AgGridComponent from "../../../Reusable_Components/AgGridComponent";
import { useSelector } from "react-redux";
import TabsAntd from "../../../Reusable_Components/TabsAntd";
import ViewRuleTable from "./ViewRuleTable";

const AdminSetRules = ({ createRulesList }) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState()
    const [isNewRuleModal, setIsNewRuleModal] = useState(false);
    const [isEditNewRuleModal, setIsEditNewRuleModal] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenRuleStatus, setIsModalOpenRuleStatus] = useState(false);
    const [isSetDocHeaderModalOpen, setIsDocHeaderModalOpen] = useState(false)
    const [isSetRuleModalOpen, setIsSetRuleModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState()
    const [loading, setLoading] = useState(false)
    const [viewDataModal, setViewDataModal] = useState(false);
    const [rawData, setRawData] = useState()
    const [viewRuleData, setViewRuleData] = useState({
        "viewDocRules": "",
        "viewKeyRules": "",
        "viewRuleDetails": ""
    })

    const [jsonData, setJsonData] = useState()
    const [userActive, setUserActive] = useState();
    const [isModaldataRefresh, setIsModaldataRefresh] = useState(false);
    const [isChangeRuleStatusModalOpen, setIsChangeRuleStatusModalOpen] = useState(false)
    const [input, setInput] = useState({
        _reason: "",
        _spec_status_id: "",
    });

    const [ViewModalOpen, setViewModalOpen] = useState(false);
    const [getRulesViewData, setGetRoulesViewData] = useState([]);


    const navigate = useNavigate();
    const [userRoute, setUserRoute] = useState(false);
    const [param, setParams] = useState([]);
    const [ruleID, setRuleID] = useState()
    const [compID, setCompID] = useState()
    const [docID, setDocID] = useState()
    const [typeOfRule, setTypeOfRule] = useState()

    const [isAddColumnModal, setIsAddColumnModal] = useState(false);
    const tableHeight = 380;
    const [isButtons, setIsButtons] = useState(true)

    // uam role object start
    const userDetails = useSelector((state) => state)
    const appsubmodulename = "Services"
    const appfunctionalityname = "Rules"
    const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
    const [appObjects, setAppObjects] = useState(
        {
            "Add Columns": false,
            "Add Rules": false,
            "Approve/Reject": false,
            "Edit": false,
            "Rule Spec": false,
            "View Rule": false
        }
    )
    console.log("userDetails", userDetails)

    const updateAppObjects = (arr) => {
        let objectsArray = []
        arr.map((obj) => {
            console.log("updateButtons", obj)
            // if (obj.objecttypename == "button") {
            objectsArray.push(obj)
            // }
        })
        const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
            acc[appobjectname] = true;
            return acc;
        }, {});
        setAppObjects({ ...appObjects, ...finalObject })
        console.log("objectsArray", objectsArray)
        console.log("finalObject", finalObject)
    }

    useEffect(() => {
        console.log("appObjects", appObjects)
    }, [appObjects])

    useEffect(() => {
        console.log("userRoleObjects", userRoleObjects)
        const filteredList = []
        userRoleObjects.filter((obj) => {
            if (obj.appsubmodulename == appsubmodulename && obj.appfunctionalityname == appfunctionalityname) {
                filteredList.push(obj)
            }
        })

        console.log("filteredList", filteredList)
        updateAppObjects(filteredList)

    }, [])

    // uam role object end

    const showChangeRuleStatusModal = (act) => {
        console.log("Active", act.row);
        setInput({
            _reason: "", _spec_status_id: ""
        })
        setIsChangeRuleStatusModalOpen(true);
        setUserActive(act.row);
        setIsModaldataRefresh(true)
    };

    const handleChangeRuleStatusOk = () => {
        setIsChangeRuleStatusModalOpen(false);
        // getRulesList()
    };

    const handleChangeRuleStatusCancel = () => {
        setIsChangeRuleStatusModalOpen(false);
        setIsModaldataRefresh(false)
    };

    // Data background change
    const getBackgroundColor = (color, mode) =>
        mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

    const getHoverBackgroundColor = (color, mode) =>
        mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

    const getSelectedBackgroundColor = (color, mode) =>
        mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    const getSelectedHoverBackgroundColor = (color, mode) =>
        mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        '& .super-app-theme--Pending': {
            backgroundColor: getBackgroundColor(
                "#F7F5BC",
                // theme.palette.info.main, 
                theme.palette.mode),
            '&:hover': {
                backgroundColor: getHoverBackgroundColor(
                    // "#362FD9",
                    "#F7F5BC",
                    // theme.palette.info.main,
                    theme.palette.mode,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: getSelectedBackgroundColor(
                    // "#362FD9",
                    "#F7F5BC",
                    // theme.palette.info.main,
                    theme.palette.mode,
                ),
                '&:hover': {
                    backgroundColor: getSelectedHoverBackgroundColor(
                        // "#362FD9",
                        "#F7F5BC",
                        // theme.palette.info.main,
                        theme.palette.mode,
                    ),
                },
            },
        },
        '& .super-app-theme--Approved': {
            backgroundColor: getBackgroundColor(
                // "#A2FF86",
                theme.palette.success.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getHoverBackgroundColor(
                    // "#A2FF86",
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: getSelectedBackgroundColor(
                    // "#A2FF86",
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
                '&:hover': {
                    backgroundColor: getSelectedHoverBackgroundColor(
                        // "#A2FF86",
                        theme.palette.success.main,
                        theme.palette.mode,
                    ),
                },
            },
        },
        '& .super-app-theme--Rejected': {
            backgroundColor: getBackgroundColor(
                // "#FF6666",
                theme.palette.warning.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getHoverBackgroundColor(
                    // "#FF6666",
                    theme.palette.warning.main,
                    theme.palette.mode,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: getSelectedBackgroundColor(
                    // "#FF6666",
                    theme.palette.warning.main,
                    theme.palette.mode,
                ),
                '&:hover': {
                    backgroundColor: getSelectedHoverBackgroundColor(
                        // "#FF6666",
                        theme.palette.warning.main,
                        theme.palette.mode,
                    ),
                },
            },
        },
        '& .super-app-theme--Deactive': {
            backgroundColor: getBackgroundColor(
                // "#526D82",
                theme.palette.error.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getHoverBackgroundColor(
                    // "#526D82",
                    theme.palette.error.main,
                    theme.palette.mode,
                ),
            },
            '&.Mui-selected': {
                backgroundColor: getSelectedBackgroundColor(
                    // "#526D82",
                    theme.palette.error.main,
                    theme.palette.mode,
                ),
                '&:hover': {
                    backgroundColor: getSelectedHoverBackgroundColor(
                        // "#526D82",
                        theme.palette.error.main,
                        theme.palette.mode,
                    ),
                },
            },
        },
    }));

    // Databackground close


    const RequestedRulesColumns = [
        {
            headerClassName: "super-app-theme--header",
            field: "_rule_id",
            headerName: "ID",
            // width: 60,
            flex: 0.2,
            isAccess: true
        },
        {
            headerClassName: "super-app-theme--header",
            // width: 50,
            headerName: "Rule Spec",
            flex: 0.3,
            isAccess: appObjects["Rule Spec"],
            filter:false,
            cellRenderer: (params) => {
                // console.log("kysa aarha hai?", params.row._rule_id)
                return (
                    <div>
                        {appObjects["Rule Spec"] && <a onClick={() => RulesView(params.data._rule_id)} class="btn">
                            <LuView />
                        </a>}
                    </div>
                );
            },
        },
        {
            field: "_rule_name",
            headerClassName: "super-app-theme--header",
            headerName: "Rule Name",
            // width: 170,
            flex: 0.5,
            isAccess: true
        },
        {
            field: "_description",
            headerClassName: "super-app-theme--header",
            headerName: "Description",
            // width: 270,
            flex: 0.5,
            isAccess: true
        },
        {
            field: "_customername",
            headerClassName: "super-app-theme--header",
            headerName: "Customer",
            // width: 150,
            flex: 0.4,
            isAccess: true
        },
        {
            field: "rulestype",
            headerClassName: "super-app-theme--header",
            headerName: "Rule Type",
            // width: 150,
            flex: 0.3,
            isAccess: true
        },
        {
            field: "_spec_status_name",
            headerClassName: "super-app-theme--header",
            headerName: "Status",
            // width: 80,
            flex: 0.4,
            isAccess: true,
            cellRenderer: (params) => {
                console.log("Coming ...", params);
                let val = params.data._spec_status_name;
                if (val === "Approved") {
                    return (
                        <div className="btnactive" role="button">
                            {val}
                        </div>
                    );
                } else if (val === "Deactive") {
                    return (
                        <div className="btnDeactive" role="button">
                            {val}
                        </div>
                    );
                } else {
                    return (
                        <div className="btnpending" role="button">
                            {val}
                        </div>
                    );
                }
            },
        },
        {
            field: "action1",
            headerClassName: "super-app-theme--header",
            headerName: "Approve/Reject",
            // width: 120,
            flex: 0.4,
            filter:false,
            isAccess: appObjects["Approve/Reject"],
            cellRenderer: (params) => {
                return (
                    <div>
                        {appObjects["Approve/Reject"] && <a onClick={() => showModal(params)} class="btn btn-ligh btn-sm  ">
                            <img src={approvereject} style={{ width: "1.2rem" }} />
                        </a>}
                    </div>
                );
            },
        },
        {
            field: "action2",
            headerClassName: "super-app-theme--header",
            headerName: "Edit",
            // width: 150,
            filter: false,
            flex: 0.2,
            isAccess: appObjects["Edit"],
            cellRenderer: (params) => {
                console.log("editparams", params)
                return (
                    <div>

                        {appObjects["Edit"] && <button
                            style={{ padding: "4px", background: "transparent", border: "none", color: "black" }}
                            disabled={params.data._spec_status_name == "Approved" ? true : false}
                            // id={id}
                            className="btn btn-danger btn-sm"
                            onClick={() => showEditModal(params)}
                        >
                            {<FaEdit />}
                        </button>}
                    </div>
                );
            },
        },
        {
            field: "previewRule",
            headerClassName: "super-app-theme--header",
            headerName: "View",
            // width: 150,
            filter: false,
            flex: 0.2,
            isAccess: appObjects["View Rule"],
            cellRenderer: (params) => {
                console.log("editparams", params)
                return (
                    <div>
                        {appObjects["View Rule"] && <button
                            style={{ padding: "4px", background: "transparent", border: "none", color: "black" }}
                            // disabled={params.data._spec_status_name == "Approved" ? true : false}
                            // id={id}
                            className="btn btn-danger btn-sm"
                            onClick={() => showEditModalForView(params)}
                        >
                            {<MdOutlinePreview />}
                        </button>}
                    </div>
                );
            },
        },
    ];

    const [newRulesColumn, setNewRulesColumn] = useState([])

  useEffect(() => {
    let { role } = userDetails.auth
    let finalArr = []
    // if (role == "super user") {
        RequestedRulesColumns.filter((obj) => {
        // if (obj?.isSuperUser == true) {
        //   finalArr.push(obj)
        // }
        if (obj?.isAccess == true) {
          finalArr.push(obj)
        }
      })
      console.log("sidebarfinalArr",finalArr)
      setNewRulesColumn(finalArr)
    
  }, [appObjects])



    const showNewRuleModal = () => {
        setIsNewRuleModal(true);
    };

    const showEditModal = (params) => {
        console.log("editModalParams", params)
        setIsEditNewRuleModal(true)
        setRuleID(params.data._rule_id)
        setCompID(params.data._comp_id)
        setDocID(params.data._document_id)
        setTypeOfRule(params.data.rulestype.trim())
        setIsButtons(true)
    }

    const showEditModalForView = (params) => {
        console.log("editModalParams", params)
        setIsEditNewRuleModal(true)
        setRuleID(params.data._rule_id)
        setCompID(params.data._comp_id)
        setDocID(params.data._document_id)
        setTypeOfRule(params.data.rulestype.trim())
        setIsButtons(false)
    }

    const handleNewRuleOk = () => {
        setIsNewRuleModal(false);
        getRulesList()
    };

    const handleNewRuleCancel = () => {
        setIsNewRuleModal(false);
    };

    const handleEditRuleOk = () => {
        setIsEditNewRuleModal(false);
        getRulesList()
    };

    const handleEditRuleCancel = () => {
        setIsEditNewRuleModal(false);
        getRulesList()
        setRuleID()
        setCompID()
        setDocID()
        setTypeOfRule()
    };

    const showModal = (act) => {
        // setSelectedRow(act.row)
        setInput({
            _reason: "",
            _spec_status_id: "",
        });
        setIsModalOpenRuleStatus(true);
        setUserActive(act.data);
        setIsModaldataRefresh(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        getRulesList()
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleOkRuleStatus = () => {
        setIsModalOpenRuleStatus(false);
        getRulesList()
    };

    const handleCancelRuleStatus = () => {
        setIsModalOpenRuleStatus(false);
    };


    const showSetRules = (params) => {
        console.log("showSetRulesparams", params)
        setSelectedRow(params.row)
        setIsSetRuleModalOpen(true);
    };

    const handleSetRuleOk = () => {
        setIsSetRuleModalOpen(false);
        getRulesList()
    };

    const handleSetRuleCancel = () => {
        setIsSetRuleModalOpen(false);
    };

    const handleSetDocHeaderOk = () => {
        setIsDocHeaderModalOpen(false);
        getRulesList()
    };

    const handleSetDocHeaderCancel = () => {
        setIsDocHeaderModalOpen(false);
    };

    const { Search } = Input;
    // const onSearch = (value: string) => console.log(value);

    const handleDisplay = (url) => {
        // console.log(url);
        localStorage.removeItem("api_url");
        localStorage.setItem("api_url", url);
        navigate("/api_format_data");
    };

    const getRulesList = async () => {
        setLoading(true);
        try {
            await getRuleListForSuperAdmin().then((res) => {
                let response = res.data.data;
                let filteredArray = []
                console.log("getRuleListForSuperAdminres", response);
                response.sort((a, b) => b._rule_id - a._rule_id);
                let response2 = response.forEach((obj) => {
                    if (obj.rulestype !== null) {
                        const trimmedRulestype = obj.rulestype.trim();
                        // if(trimmedRulestype  === "custom" || trimmedRulestype  === "rule" || trimmedRulestype === "lambda"){
                        //     filteredArray.push(obj)
                        // }
                        filteredArray.push(obj)
                    }
                })
                console.log("response__sort", response)
                filteredArray.map((item) => {
                    if (item._status_id === 2) {
                        item._status_id = "InActive";
                    } else if (item._status_id === 1) {
                        item._status_id = "Active";
                    } else {
                        item._status_id = "Pending";
                    }
                    return item;
                });
                setData(filteredArray);
            });
        }
        catch (err) {
            setError(err)
        }
        setLoading(false);
    };

    useEffect(() => {
        getRulesList()
        // requestedData()
    }, []);



    useEffect(() => {
        if (createRulesList == true) {
            getRulesList()

        }
    }, [createRulesList]);

    const viewRawData = async (p) => {
        console.log("viewRawDataparams", p)
        let ruleID = p.row._rule_id
        setViewDataModal(true);
        // setSetRole(p.row);
        // setRawData(`${p.row._docvalue}`)
        try {
            let response = await requestedRulesforRuleID(ruleID)
            if (response.status == 200) {
                let requestedRules = response.data
                let docRules = JSON.stringify(requestedRules.Doc.data, null, 1)
                let keyRules = JSON.stringify(requestedRules.Key.data, null, 1)
                let ruleData = JSON.stringify(requestedRules.Rule.data, null, 1)
                setViewRuleData({
                    "viewDocRules": `${docRules}`,
                    "viewKeyRules": `${keyRules}`,
                    "viewRuleDetails": `${ruleData}`,
                })
                setJsonData(requestedRules)
                console.log("rules", docRules, keyRules, ruleData)
            }
        }
        catch (err) {
            setError(err)
        }
    };

    const viewRawDataCancel = () => {
        setViewDataModal(false);
        setViewRuleData({
            "viewDocRules": "",
            "viewKeyRules": "",
            "viewRuleDetails": ""
        })
    };



    const actionColumn = [


        {
            field: "_docvalue",
            headerName: "Rule Spec",
            width: 180,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <a onClick={() => viewRawData(params)} class="btn">
                            <GrFormView />
                        </a>
                    </div>
                );
            },
        },
        // {
        //   field: "action1",
        //   headerClassName: "super-app-theme--header",
        //   headerName: "Approve/Reject",
        //   width: 180,
        //   renderCell: (params) => {
        //     return (
        //       <a onClick={() => showChangeRuleStatusModal(params)} class="btn">
        //         <img src={approvereject} style={{ width: "1.5rem", marginTop: "unset" }} />
        //       </a>
        //     );
        //   },
        // },
    ];

    function generateRandom() {
        var length = 8,
            charset =
                "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    const handleAddRules = () => {
        showNewRuleModal()
    }

    // Rule View Modal Functions

    const RulesView = (ruleid) => {
        setViewModalOpen(true);
        setGetRoulesViewData(ruleid);
    };
    const RulesViweOk = () => {
        setViewModalOpen(false);
    };

    const RulesViewCancel = () => {
        setViewModalOpen(false);
    };

    const handleChangeCheckbox = (params) => {
        // console.log("handle check box", params);
        let colDef = params.field;
        let data = params.row;
        if (colDef === "action") {
            setParams(data);
            setUserRoute(true);
        }
    };

    const handleAddColumn = () => {
        console.log("")
        setIsAddColumnModal(true)
    }

    const handleAddColumnCancel = () => {
        console.log("")
        setIsAddColumnModal(false)
    }

    const rowClassRules = {
        'even-row': (params) => params.node.rowIndex % 2 !== 0,
    };

    const tabItemsSpecData = [
        {
            key: "1",
            label: `JSON`,
            children: getRulesViewData ? <ViewRule ruleid={getRulesViewData} /> : null,
        },
        {
            key: "2",
            label: `Table`,
            children: getRulesViewData ? <ViewRuleTable ruleid={getRulesViewData} /> : null,
        },
    ]

    const onChangeTabsRawData = (key) => {
        console.log("onChangeTabsRawData", key);
        // if (key == "1") {
        //     setIsJsonComp(false)
        //     setIsRawDataTable(true)
        // }
        // else if (key == "2") {
        //     setIsJsonComp(true)
        //     setIsRawDataTable(false)
        // }
    }


    return (
        <>
            <Loader loading={loading} />
            <Toaster position="top-center" reverseOrder={false} />
            <ErrorCatch err={error} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8 d-flex align-items-center">
                        <p className="page-title">
                            Admin Rule List
                        </p>
                    </div>
                    <div className="col-sm-4 d-flex justify-content-end">
                        {/* <button
                            className="btn btn-primary"
                            onClick={handleAddRules}
                        >
                            Add Rules
                        </button> */}
                        <div className="px-2">
                            {appObjects["Add Columns"] && <BasicButton
                                text="Add Columns"
                                onClick={handleAddColumn}
                            />}
                        </div>
                        <div>
                            {appObjects["Add Rules"] && <BasicButton
                                text="Add Rules"
                                onClick={handleAddRules}
                            />}
                        </div>
                    </div>
                </div>

                {data.length > 0 ? (
                    <div className=" mt-2 "

                    >
                        {/* <StyledDataGrid
                            sx={{
                                height: "57vh", width: "100%",
                                overflowY: "auto",
                                borderColor: "secondary",
                                "& .MuiDataGrid-cell:hover": {
                                    color: "primary.main",
                                },
                                "& .MuiDataGrid-columnHeader": {
                                    backgroundColor: "#0E3386",
                                    fontSize: "12px",
                                    color: "white",
                                    // padding: "10px",

                                    // Set your desired header background color
                                },
                                "& .MuiDataGrid-cell": {
                                    fontSize: "10px",
                                    padding: "10px",
                                    borderRight: "2px solid #e0e0e0",
                                    // Adjust the font size as needed
                                    // Adjust the cell padding as needed
                                },
                            }}
                            rowHeight={30}
                            getRowId={(data) => data._rule_id}
                            className="datagrid"
                            rows={data}
                            getRowClassName={(params) =>
                                `super-app-theme--${params.row._spec_status_name}`
                            }
                            columns={RequestedRulesColumns}
                            checkboxSelection={false}
                            // pageSize={9}
                            onCellClick={(params) => handleChangeCheckbox(params)}
                            // rowsPerPageOptions={[9]}
                            hideFooter
                        /> */}
                        <AgGridComponent
                            rowData={data}
                            columnDef={newRulesColumn}
                            rowClassRules={rowClassRules}
                        />
                    </div>
                ) :
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col d-flex justify-content-center">
                                {" "}
                                Now Rows to show
                            </div>
                        </div>
                    </div>
                }
            </div>


            <AdminAddNewRule
                isNewRuleModal={isNewRuleModal}
                handleNewRuleCancel={handleNewRuleCancel}
                handleNewRuleOk={handleNewRuleOk}
                getRulesList={getRulesList}
                ruleList={data}
            />
            <AdminAddColumns
                isAddColumnModal={isAddColumnModal}
                handleAddColumnCancel={handleAddColumnCancel}
            />

            <><AdminEditNewRule
                isButtons={isButtons}
                createRulesList={createRulesList}
                typeOfRule={typeOfRule}
                compID={compID}
                theDocID={docID}
                ruleID={ruleID}
                isEditNewRuleModal={isEditNewRuleModal}
                handleEditRuleOk={handleEditRuleOk}
                handleEditRuleCancel={handleEditRuleCancel} />

                <Modal
                    className="p-2"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={null}
                    style={{ top: 20, minWidth: 300, maxWidth: 600 }}
                >
                    <SetRulesForDocuments
                        selectedRow={selectedRow}
                        handleOk={handleOk}
                        onCancel={handleCancel} />
                </Modal><Modal
                    width={800}
                    className="p-2"
                    open={isSetDocHeaderModalOpen}
                    onOk={handleSetDocHeaderOk}
                    onCancel={handleSetDocHeaderCancel}
                    footer={null}
                >
                    <SetDocumentHeaders
                        selectedRow={selectedRow}
                        isSetDocHeaderModalOpen={isSetDocHeaderModalOpen} />
                </Modal><Modal
                    width={800}
                    className="p-2"
                    open={isSetRuleModalOpen}
                    onOk={handleSetRuleOk}
                    onCancel={handleSetRuleCancel}
                    footer={null}
                    style={{ top: 20, minWidth: 300, maxWidth: 600 }}
                >
                    <SetRulesForKeys
                        selectedRow={selectedRow}
                        isSetRuleModalOpen={isSetRuleModalOpen} />
                </Modal><Modal
                    title="Rule Details"
                    className="p-2"
                    open={viewDataModal}
                    onCancel={viewRawDataCancel}
                    style={{ top: 20, minWidth: 300, maxWidth: 600 }}
                >
                    <hr />

                    <div style={{ height: tableHeight, width: "100%", overflowY: "auto" }}>
                        <JsonViewer value={jsonData} />
                    </div>
                </Modal><Modal
                    className="p-2 w-25"
                    open={isModalOpenRuleStatus}
                    onOk={handleOkRuleStatus}
                    onCancel={handleCancelRuleStatus}
                    footer={null}
                    maskClosable={false}
                    style={{ top: 20, minWidth: 300, maxWidth: 600 }}
                >
                    <ChangeRuleStatus data={userActive} handleOkRuleStatus={handleOkRuleStatus} handleCancel={handleCancelRuleStatus} />
                </Modal><Modal
                    title="View Rules"
                    maskClosable={false}
                    open={ViewModalOpen}
                    onOk={RulesViweOk}
                    onCancel={RulesViewCancel}
                    className="w-50"
                    style={{ top: 20, minWidth: 300,maxWidth: 600 }}
                >

                    <TabsAntd
                        defaultActiveKey="1"
                        items={tabItemsSpecData}
                        onChange={(key) => onChangeTabsRawData(key)}
                        centered
                    />

                    {/* <ViewRule ruleid={getRulesViewData} /> */}
                </Modal>
            </>
        </>
    );
};

export default AdminSetRules;
