import React, { useEffect } from "react";
import { JsonViewer } from "@textea/json-viewer";

const JsonComp = ({ isJsonComp, rawData }) => {
    console.log("isJsonComp", isJsonComp, "rawData", rawData)

    return (
        <>
            {isJsonComp &&

                <div
                    style={{
                        height: "65vh",
                        overflowY: "auto"
                    }}
                >
                    <JsonViewer value={rawData} />
                </div>
            }
        </>
    )
}

export default JsonComp