import mail from "../../../../Assets/Images/gmail.png";
import sms from "../../../../Assets/Images/sms.png";
import bell from "../../../../Assets/Images/active.png";
export const userLogsColumns = [
  // {
  //   field: "_name",
  //   headerClassName: "super-app-theme--header",
  //   headerName: "Name",
  //   width: 150,
  // },

  // {
  //   field: "_title",
  //   headerClassName: "super-app-theme--header",
  //   headerName: "Title",
  //   width: 80,
  // },

  // {
  //   field: "_comp_id",
  //   headerClassName: "super-app-theme--header",
  //   headerName: "Comp Id",
  //   width: 100,
  // },

  // {
  //   field: "_designation",
  //   headerClassName: "super-app-theme--header",
  //   headerName: "Designation",
  //   width: 170,
  // },

  // {
  //   field: "_department",
  //   headerName: "Department",
  //   headerClassName: "super-app-theme--header",
  //   width: 130,
  // },
  // {
  //   field: "_email",
  //   headerClassName: "super-app-theme--header",
  //   headerName: "Email",
  //   width: 250,
  // },

  // {
  //   field: "_phone",
  //   headerClassName: "super-app-theme--header",
  //   headerName: "Phone",
  //   width: 150,
  // },
  // {
  //   field: "_user_type",
  //   headerClassName: "super-app-theme--header",
  //   headerName: "User Type",
  //   width: 150,
  // },

  // {
  //   field: "_datetime",
  //   headerClassName: "super-app-theme--header",
  //   headerName: "Date & Time",
  //   width: 200,
  // },
  // {
  //   field: "_status_id",
  //   headerClassName: "super-app-theme--header",
  //   headerName: "Status",
  //   width: 100,
  //   renderCell: (params) => {
  //     let val = params.row._status_id;
  //     if (val == 1) {
  //       return (
  //         <button className="button-33" role="button">
  //           Active
  //         </button>
  //       );
  //     } else if (val == 2) {
  //       return (
  //         <button className="button-35" role="button">
  //           InActive
  //         </button>
  //       );
  //     } else if (val == 3) {
  //       return (
  //         <button className="button-32" role="button">
  //           Pending
  //         </button>
  //       );
  //     } else {
  //       return <button className="button-34">Rejected</button>;
  //     }
  //   },
  // },
  // {
  //   field: "_status_reason",
  //   headerClassName: "super-app-theme--header",
  //   headerName: "Status Reason",
  //   width: 250,
  // },

  {
    field: "_dml_type",
    headerClassName: "super-app-theme--header",
    headerName: "Action",
    width: 200,
  },

  {
    field: "_added_on",
    headerClassName: "super-app-theme--header",
    headerName: "Created On",
    width: 200,
  },

  {
    field: "_status_reason",
    headerClassName: "super-app-theme--header",
    headerName: "Description",
    width: 516,
  }
  // {
  //   field: "_status_datetime",
  //   headerClassName: "super-app-theme--header",
  //   headerName: "Status Date & Time",
  //   width: 200,
  // },
  // {
  //   field: "_pref_id",
  //   headerClassName: "super-app-theme--header",
  //   headerName: "Preference",
  //   width: 100,
  //   renderCell: (params) => {
  //     let val = params.row._status_id;
  //     if (val == 1) {
  //       return <img src={mail} alt="" style={{ width: "1.5rem" }} />;
  //     } else if (val == 2) {
  //       return <img src={sms} alt="" style={{ width: "1.5rem" }} />;
  //     } else {
  //       return <img src={bell} alt="" style={{ width: "1.5rem" }} />;
  //     }
  //   },
  // },
];
