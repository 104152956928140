import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import axios from "axios";
import env from "../../../../Services/Environment";
import { getResponseFormat, getRules, createNewRules, createOneRule, getAllParameterRules, getDocumentKeys, getDocumentName, getActiveCustomer, getUserRules, addNewItems, getCustomRules, getParameterDocSearch, getDefaultRules, getRawDataDetails, getSourceKeys, getCustomerList, getApiId, defaultRequestAPI } from "../../../../Services/AdminServices";
import { Modal, Button } from "antd";
import { Radio } from 'antd';
import { BsPlus, BsFillTrashFill } from 'react-icons/bs';
import Loader from "../../../../utils/Loader/Loader";
import { useStyleRegister } from "antd/es/theme/internal";
import SetDocumentHeaders from "../../CustomerAdmin/Document/SetDocumentHeaders";
import SelectInputWithAdd from "../../../Reusable_Components/SelectInputWithAdd";
import SelectInput from "../../../Reusable_Components/SelectInput";
import InputBox from "../../../Reusable_Components/InputBox";
import SelectInputAntd from "../../../Reusable_Components/SelectInputAntd";
import InputBoxAntd from "../../../Reusable_Components/InputBoxAntd";
import BasicButton from "../../../Reusable_Components/Button";
import RadioButton from "../../../Reusable_Components/RadioButton";
import { messages } from "../../../Messages";
import { useRef } from "react";
import Checkbox from "../../../Reusable_Components/CheckBox";
import CheckboxComp from "../../../Reusable_Components/CheckBox";
import ErrorCatch from "../../../Reusable_Components/ErrorCatch";
import warning from "../../../../Assets/Images/warning.png"
import { RequestAPI } from "../../../../Services/UserServices";
import { useSelector } from "react-redux";
import CryptoJS from 'crypto-js';


function AdminAddNewRule({ handleNewRuleOk, isNewRuleModal, handleNewRuleCancel, ruleList }) {
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state)
  const [customerList, setCustomerList] = useState([])
  const [selectedCustomerList, setSelectedCustomerList] = useState([])
  const [requesterUserName, setRequesterUserName] = useState("")
  const [requesterUserEmail, setRequesterUserEmail] = useState("")
  const [requesterUserId, setRequesterUserId] = useState("")
  const [selectedCustomerata, setSelectedCustomerData] = useState([]);
  const [error, setError] = useState()
  const [apiFormatState2, setApiFormatState2] = useState([]);
  const [responseFormat, setResponseFormat] = useState([])
  const [ruleFormat, setRuleFormat] = useState([])
  const [customerPlaceHolder, setCustomerPlaceholder] = useState({
    name: "Select Customer",
    value: ""
  })
  const [docType, setDocType] = useState({
    name: "Select a Document Type",
    value: ""
  })

  const [formatType, setFormatType] = useState({
    name: "Select a Format Type",
    value: ""
  })

  // const [keyType, setKeyType] = useState({
  //   name: "Key",
  //   value: ""
  // })

  const [ruleType, setRuleType] = useState({
    name: "Select a Rule Type",
    value: ""
  })

  const [files, setFiles] = useState("")
  const [parameterFilesList, setParameterFilesList] = useState([])
  const [isDefaultRule, setIsDefaultRule] = useState(false)
  const [isDefaultApi, setIsDefaultApi] = useState(false)
  const [defaultRule, setDefaultRule] = useState({
    ruleId: "", ruleName: "", ruleDescription: "",
  })
  const [defaultCheckModal, setDefaultCheckModal] = useState(false)
  const [defaultRulePayload, setDefaultRulePayload] = useState()
  const [isLambda, setIsLambda] = useState(false)
  const [filesList, setFilesList] = useState([
    {
      name: "Custom File",
      value: "customfile"
    },
    {
      name: "Lambda File",
      value: "lambdafile"
    }
  ])

  useEffect(() => {
    console.log("files", files)
  }, [files])

  const [keySpecData, setKeySpecData] = useState([])
  // const [paramterID, setParamterID] = useState("");
  const [keySpecField, setKeySpecField] = useState({
    // "operation": "KeyRule",
    "_paramvalue": "",
    "_parameter_id": "",
    "_documetkeys_id": "",
    // "_seq_no":""
  })

  const [docSpecData, setDocSpecData] = useState([])
  const [docSpecField, setDocSpecField] = useState({
    "_paramvalue": "",
    "_parameter_id": "",
    "_documetkeys_id": "",
    "_seq_no": ""
  })

  const [isError, setIsError] = useState(false)

  let email = localStorage.getItem("Logindata")

  const [inputData, setInputData] = useState();
  const [appName, setAppName] = useState();
  const [customerName, setCustomername] = useState("")
  const [requesterName, setRequesterName] = useState("")
  const [selectedCustomerId, setSelectedCustomerId] = useState("")
  const [docID, setDocID] = useState("")
  const [apiID, setApiID] = useState("")
  const [ruleName, setRuleName] = useState("")
  const [ruleDescription, setRuleDescription] = useState("")
  const [IDNo, setIDNo] = useState()
  const [formatID, setFormatID] = useState("")
  const [ruleID, setRuleID] = useState("")
  const [loading, setLoading] = useState(false)
  // const [salesOrderNo, setSalesOrderNo] = useState()

  const [isKeyUpdate, setIsKeyUpdate] = useState(false)
  const containerRef = useRef(null)
  const docContainerRef = useRef(null)
  const keyContainerRef = useRef(null)

  // expression changes
  const [expressionModal, setExpressionModal] = useState(false)
  const [isSubString, setIsSubString] = useState(false)
  const [isConcat, setIsConcat] = useState(false)
  const [isAddConst, setIsAddConst] = useState(false)
  const [isConstValue, setIsConstValue] = useState(false)
  const [isMap, setIsMap] = useState(false)
  const [isTrim, setIsTrim] = useState(false)
  const [isInt, setIsInt] = useState(false)


  const [expression, setExpression] = useState("")
  const [fromValue, setFromValue] = useState("")
  const [toValue, setToValue] = useState("")
  const [inx, setInx] = useState()
  const [paramValue, setParamValue] = useState("")
  const [selectedParam, setSelectedParam] = useState()

  const [isDocType, setIsDocType] = useState(false)

  const [sequenceNo, setSequenceNo] = useState()

  const userDetailsLog = userDetails.auth.userDetails



  const handleScrollToLastChild = () => {
    if (containerRef.current) {
      const lastChild = containerRef.current.lastChild
      lastChild.scrollIntoView({ behavior: "smooth" })
    }
  }

  const scrollToBottom = () => {
    const element = containerRef.current;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  };

  const scrollToBottomDoc = () => {
    let element = docContainerRef.current;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
    setIsKeyUpdate(false)
  };

  const scrollToBottomKey = () => {
    let element = keyContainerRef.current;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
    setIsKeyUpdate(false)
  };

  const handleDefaultRuleCheckBox = (e) => {
    setIsDefaultRule(e.target.checked)
  }
  const handleDefaultApiCheckBox = (e) => {
    setIsDefaultApi(!isDefaultApi)
  }

  const handleLambdaCheckBox = (e) => {
    setIsLambda(e.target.checked)
  }

  useEffect(() => {
    if (docSpecData.length > 0) {
      scrollToBottomDoc()
    }
  }, [docSpecData])

  useEffect(() => {
    if (docID !== "") {
      fetchRowDataDetails()
      fetchSourceKeys()
    }
  }, [docID])

  // useEffect(() => {
  //   if (keySpecData.length > 0) {
  //     scrollToBottom()
  //     scrollToBottomKey()
  //     // handleScrollToLastChild()
  //   }
  // }, [keySpecData])

  const handleAddField = () => {
    console.log("keySpecData_", keySpecData)
    let newDocSpecData = {
      "_paramvalue": "",
      "_parameter_id": "",
      "_documetkeys_id": "",
      "_seq_no": keySpecData.length + 1
    }
    setKeySpecData([...keySpecData, { ...newDocSpecData }])
    setKeySpecField({
      // "operation": "KeyRule",
      "_paramvalue": "",
      "_parameter_id": "",
      "_documetkeys_id": "",
      // "_seq_no":""
    })
    setNewKeyForDoc("")
    setNewKeyForKey("")
    scrollToBottomKey()
    scrollToBottom()
  }

  const handleDeleteField = (index) => {
    let newKeySpecData = [...keySpecData]
    newKeySpecData.splice(index, 1)
    console.log("newKeySpecData_", newKeySpecData);
    newKeySpecData.map((item, i) => {
      if (item._seq_no != i + 1) {
        item["_paramvalue"] = item._paramvalue
        item["_parameter_id"] = item._parameter_id
        item["_documetkeys_id"] = item._documetkeys_id
        item["_seq_no"] = i + 1
      }
    })
    setKeySpecData(newKeySpecData)
    setNewKeyForDoc("")
    setNewKeyForKey("")
  }

  const handleAddDocField = () => {
    console.log("docSpec_", docSpecData, docSpecField)
    let docSpecArray = []
    let newDocSpecData = {
      "_paramvalue": "",
      "_parameter_id": "",
      "_documetkeys_id": "",
      "_seq_no": docSpecData.length + 1
    }
    console.log("docSpecArray_", docSpecArray);
    setDocSpecData([...docSpecData, { ...newDocSpecData }])
    setDocSpecField({
      // "operation": "KeyRule",
      "_paramvalue": "",
      "_parameter_id": "",
      "_documetkeys_id": "",
      // "_seq_no":""
    })
    setNewKeyForDoc("")
    setNewKeyForKey("")
  }

  const handleDeleteDocField = (index) => {
    let newDocSpecData = [...docSpecData]
    newDocSpecData.splice(index, 1)
    // console.log("newSpecData__",newDocSpecData)

    newDocSpecData.map((item, i) => {
      if (item._seq_no != i + 1) {
        item["_paramvalue"] = item._paramvalue
        item["_parameter_id"] = item._parameter_id
        item["_documetkeys_id"] = item._documetkeys_id
        item["_seq_no"] = i + 1
      }
    })
    console.log("docarray_", newDocSpecData);
    setDocSpecData(newDocSpecData)
    setNewKeyForDoc("")
    setNewKeyForKey("")
  }


  const [apiDetailState, setApiDetailState] = useState([]);

  const [hideSelect, setHideSelect] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newRuleData, setNewRuleData] = useState({})
  const [parameterValue, setParameterValue] = useState("")
  const [paramDocValue, setParamDocValue] = useState("")
  const [parameter, setParameter] = useState({
    name: "Select a Parameter Type",
    value: ""
  })

  const [newKeysModal, setNewKeysModal] = useState(false)
  const [newKeys, setNewKeys] = useState()
  const [documentKeysList, setDocumentKeysList] = useState([])
  const [filterDocumentKeyList, setFilterDocumentKeyList] = useState([])
  const [parameterRulesList, setParameterRulesList] = useState([])
  const [newValueNo, setNewValueNo] = useState()
  const [isSetRuleModalOpen, setIsSetRuleModalOpen] = useState(false)

  const [sourceKeyLists, setSourceKeyLists] = useState([])

  const [docKeys, setDocKeys] = useState("")
  const [paramRule, setParamRule] = useState("")
  const handleNewValueNo = (e) => {
    const { name, value } = e.target;
    let val = e.target.value.replace(/[A-Za-z' `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/g, '');
    setNewValueNo(val)
  };

  const handleNewKeys = (e) => {
    const { name, value } = e.target;
    setNewKeys(value)
  }

  const [newParamNames, setNewParamNames] = useState()
  const handleNewParamNames = (e) => {
    const { name, value } = e.target;
    // setInputData({ ...inputData, [name]: value });
    setNewParamNames(value)
  }

  const [ruleSpec, setRuleSpec] = useState([])

  const [radioSelection, setRadioSelection] = useState(1)
  const [customRule, setCustomRule] = useState("")
  const [customRuleName, setCustomRuleName] = useState("")
  const [customRuleList, setCustomRuleList] = useState([])
  const [radioOptions, setRadioOptions] = useState([
    {
      label: "Document",
      value: 1,
      status: true
    },
    {
      label: "Header/Details",
      value: 2,
      status: false
    },
    // {
    //   label: "SP",
    //   value: 3,
    //   status: false
    // }
  ])

  const [selectedKeyFrom, setSelectdeKeyFrom] = useState("")
  const [selectedKeyTo, setSelectdeKeyTo] = useState("")
  const [assignValue, setAssignValue] = useState("")
  const [rawData, setRawData] = useState([])
  const [isDeleteAllModal, setIsDeleteAllModal] = useState(false)
  const [concatRadioOption, setConcatRadioOption] = useState([
    {
      label: "Suffix",
      value: 1,
      status: true
    },
    {
      label: "Prefix",
      value: 2,
      status: false
    },
  ])
  const [concatRadioSelection, setConcatRadioSelection] = useState(1)
  const [selectedConcattKey, setSelectedConcattKey] = useState([])


  useEffect(() => {
    if (isNewRuleModal == true && radioSelection == 2) {
      getDocumentKeysList()
      getParameterRulesList()
      // fetchRowDataDetails()
    }
  }, [radioSelection])

  useEffect(() => {
    if (isNewRuleModal) {
      setRuleName("")
      setRuleDescription("")
      setDocID("")
      setCustomername("")
      // getDocumentKeysList()
      // getParameterRulesList()
      // getCustomRulesList()
      setCustomRule("")
      setFiles("")
      setIsDefaultRule(false)
      setIsLambda(false)
      setParameterFilesList([])
      setCustomRuleName("")
      setDocSpecData([])
      setKeySpecData([])
      setRadioSelection(2)
      setRadioOptions([
        {
          label: "Document",
          value: 1,
          status: false
        },
        {
          label: "Header/Details",
          value: 2,
          status: true
        },
        // {
        //   label: "SP",
        //   value: 3,
        //   status: false
        // }
      ])
    }
  }, [isNewRuleModal])

  useEffect(() => {
    if (isNewRuleModal == false) {
      return
    }
    if (customerName !== "" && docID !== "") {
      // let compID = 0
      // getCustomRulesList(compID)
      // getCustomRulesList(customerName, docID)
    }

  }, [customerName, docID, isNewRuleModal])

  useEffect(() => {
    if (isModalOpen) {
      // setParameter({
      //   name: "Select a Parameter Type",
      //   value: ""
      // })
      setParameterValue("")
      setParamDocValue("")
      FetchApiDetails()
    }
  }, [isModalOpen])

  // const encrypt = (plaintext) => {
  //   console.log("encryptPlaintext", plaintext);
  //   let encryptedPassword = CryptoJS.AES.encrypt(plaintext, process.env.REACT_APP_SECRET_KEY).toString();
  //   return encryptedPassword
  // };

  const encrypt = (plaintext) => {
    console.log("encryptPlaintext", plaintext);
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(plaintext))
      .replace(/=+$/, '') // Remove padding
      .replace(/\+/g, '-') // Replace + with -
      .replace(/\//g, '_'); // Replace / with _
  };

  const getActiveCustomerList = async () => {
    try {
      let currentUserLoginEmail = encrypt(userDetailsLog.email)
      const response = await getActiveCustomer(currentUserLoginEmail);
      console.log("getActiveCustomer", response);
      if (response.status == 200) {
        let data = response.data.data
        let finalArr = []
        data.map((obj) => {
          finalArr.push({
            name: obj._customername,
            value: obj.comp_id
          })
        })
        setCustomerList(finalArr);
      }

    } catch (error) {
      console.log("error", error);
      setError(error)
    }
  };

  const getCustomerListdata = async () => {
    console.log("selectedCustomerId_", selectedCustomerId)
    try {
      const id = selectedCustomerId;
      console.log("id_", id, selectedCustomerId)
      if (id) {
        const response = await getCustomerList(id);
        console.log("getCustomerList", response);
        if (response?.status == 200) {
          let data = response?.data?.data
          setSelectedCustomerData(data)
          let finalArr = []
          data.map((obj) => {
            finalArr.push({
              name: obj.email,
              value: obj.user_id
            })
          })
          setSelectedCustomerList(finalArr);
        } else {
          console.log("Requestor id not found")
        }
      }

    } catch (error) {
      console.log("error", error);
      setError(error)
    }
  };


  const getAPIFormat2 = async () => {
    try {
      const response = await getDocumentName();
      console.log("getAPIFormat2", response.data.data);
      if (response.status == 200) {
        let data = response.data.data
        let finalArr = []
        data.map((obj) => {
          finalArr.push({
            name: obj._docname,
            value: obj._document_id
          })
        })
        setApiFormatState2(finalArr);
      }

    } catch (error) {
      console.log("error", error);
      setError(error)
    }
  };

  const getDocumentResponseFormat = async () => {
    try {
      const response = await getResponseFormat()
      console.log("getDocumentResponseFormat", response.data.data);
      setResponseFormat(response.data.data);
    } catch (error) {
      console.log(error);
      setError(error)
    }
  };

  const getRuleType = async () => {
    try {
      const response = await getRules()
      console.log("getRules", response.data.data);
      setRuleFormat(response.data.data);
    } catch (error) {
      console.log(error);
      setError(error)
    }
  };

  useEffect(() => {
    getCustomerListdata()
  }, [selectedCustomerId, isDefaultApi])

  useEffect(() => {
    getDocumentResponseFormat()
    getActiveCustomerList()
    getAPIFormat2();
    // getRuleType()
    setCustomerPlaceholder({
      name: "Select Customer",
      value: ""
    })
    setDocType({
      name: "Select a Document Type",
      value: ""
    })
    setRuleName("")
    setRuleDescription("")
    setDocID("")
    setCustomername("")
    // setIDNo()
  }, []);

  useEffect(() => {
    getDocumentKeysList()
    getParameterRulesList()

    // setKeyType({
    //   name: "Keys",
    //   value: ""
    // })
    setFormatType({
      name: "Param Rule",
      value: ""
    })
    setDocKeys("")
    setParamRule("")
    setIDNo()
  }, [])

  useEffect(() => {
    if (radioSelection == 2 && customerName !== "" && docID !== "") {
      getDocumentKeysList()
    }

  }, [customerName, docID])

  const initialValues = {
    email: localStorage.getItem("Logindata"),
    // _document_id: "",
    // _documetkeys_id: "",

  };


  const handleCustomerName = (e) => {
    const { name, value } = e.target;
    console.log(e.target, "_customer")
    setInputData({ ...inputData, [name]: value });
    setCustomername(value)
    setSelectedCustomerId(value)
    console.log(value, "_value")
  };
  const handleAppName = (e) => {
    const { name, value } = e.target;
    console.log(e.target, "_customer")
    setInputData({ ...inputData, [name]: value });
    setAppName(value)
    // setCustomername(value)
    // setSelectedCustomerId(value)
    console.log(value, "_value")
  };
  const handleRequesterList = (e) => {
    const { name, value } = e.target;
    console.log(e.target, selectedCustomerata, "_customer")
    const requester = selectedCustomerata.filter((item) => item?.user_id == value);
    console.log(requester, "_requester")
    if (requester) {
      setRequesterUserEmail(requester[0]?.email);
      setRequesterUserName(requester[0]?.name)
      setRequesterUserId(requester[0]?.user_id)
    }
    setInputData({ ...inputData, [name]: value });
    setRequesterName(value);
  };

  const handleDocumentType = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
    setDocID(value)
    getApiIdData(value)
    setDocSpecData([])
    setKeySpecData([])
  };

  const getApiIdData = async (value) => {
    try {
      const id = value;
      if (id) {
        const response = await getApiId(id);
        console.log(response, "_getApiid")
        if (response?.status == 200) {
          const id = response?.data?.data[0]?.api_id;
          setApiID(id)
        }
      } else {
        console.log("Doc id not found.")
      }
    } catch (error) {
      console.log(error, "error_")
    }
  }
  console.log("doc", docID)
  const handleRuleName = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
    setRuleName(value)
  };

  const handleRuleDescription = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
    setRuleDescription(value)
  };


  const handleIDNo = (e) => {
    const { name, value } = e.target;
    let val = e.target.value.replace(/[A-Za-z' `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/g, '');
    setInputData({ ...inputData, [name]: val });
    setIDNo(val)
  };

  const [newKeyForDoc, setNewKeyForDoc] = useState("")
  const [newKeyForKey, setNewKeyForKey] = useState("")

  const onEnterNewItemForDoc = (param, followUpId) => {
    console.log("onEnterNewItemForDoc", param, followUpId, "rowdataforDoc", param.target.value)
    setNewKeyForDoc(param.target.value)
  }

  const handleAddNewItemForDoc = async () => {
    if (undefined == newKeyForDoc || newKeyForDoc == "") {
      toast.error("Please add items")
      return
    }
    let sendData = {
      "data": {
        "addDynamicKeys": [
          {
            "key": newKeyForDoc,
            "description": "",
            "key_type": "document_wise",
            "comp_id": customerName,
            "docid": docID
          }
        ]
      }
    }
    console.log("handleAddNewItemForDocsenddata", sendData)

    setLoading(true)
    try {
      let response = await addNewItems(sendData)
      console.log("responseaddnewitems", response)
      if (response.status == 201) {
        toast.success(response.statusText)
      }
      else {
        toast.error(response.statusText)
      }
    }
    catch (error) {
      console.log("erroraddnewitems", error)
      // if ("response" in error) {
      //   toast.error(error.response.data.msg)
      // }
      // else {
      //   toast.error(error.message)
      // }
      setError(error)

    }
    setNewKeyForDoc("")
    setNewKeyForKey("")
    getDocumentKeysList()
    setLoading(false)
  }

  const onEnterNewItemForKey = (param, followUpId) => {
    console.log("onEnterNewItemForKey", param, followUpId, "rowdataforDoc")
    setNewKeyForKey(param.target.value)
  }

  const handleAddNewItemForKey = async () => {
    if (undefined == newKeyForKey || newKeyForKey == "") {
      toast.error("Please add items")
      return
    }
    let sendData = {
      "data": {
        "addDynamicKeys": [
          {
            "key": newKeyForKey,
            "description": "",
            "key_type": "key_wise",
            "comp_id": customerName,
            "docid": docID
          }
        ]
      }
    }

    setLoading(true)
    try {
      let response = await addNewItems(sendData)
      console.log("responseaddnewitems", response)
      if (response.status == 201) {
        toast.success(response.statusText)

      }
      else {
        toast.error(response.statusText)
      }
    }
    catch (error) {
      console.log("erroraddnewitems", error)
      // if ("response" in error) {
      //   toast.error(error.response.data.msg)
      // }
      // else {
      //   toast.error(error.message)
      // }
      setError(error)
    }
    setNewKeyForKey("")
    getDocumentKeysList()
    setLoading(false)
  }



  // truncate - 9
  // rename - 10
  // position - 11
  // hide - 12

  // check duplicate truncate for same elements
  const containsDuplicateTruncate = (arr) => {
    console.log("arr", arr)
    let dupElements = arr.map((obj) => {
      if (obj._parameter_id == "9") {
        return obj._documetkeys_id
      }
    })
      .filter((obj) => undefined !== obj)
    console.log("dupElements", dupElements)
    let hasDupElementsForTruncate = false
    if (dupElements.length > 1) {
      hasDupElementsForTruncate = dupElements.some((obj, index) => {
        return dupElements.indexOf(obj) !== index
      })
    }
    console.log("hasDupElementsForTruncate", hasDupElementsForTruncate)
    if (hasDupElementsForTruncate) {
      toast.error("Duplicate Truncate not allowed")
      return true
    }
    return false
  }

  // check duplicate rename for same or different elements
  const containsDuplicateRename = (arr) => {
    console.log("arr", arr)

    //check duplicateElements for Rename
    let dupElements = arr.map((obj) => {
      if (obj._parameter_id == "10") {
        return obj._documetkeys_id
      }
    })
      .filter((obj) => undefined !== obj)
    console.log("dupElements", dupElements)
    let hasDupElementsForRename = false
    if (dupElements.length > 1) {
      hasDupElementsForRename = dupElements.some((obj, index) => {
        return dupElements.indexOf(obj) !== index
      })
    }
    console.log("hasDupElementsForRename", hasDupElementsForRename)

    // if(isRenameExist){
    let valuesOfRename = arr.map((obj) => {
      if (obj._parameter_id == "10") {
        return obj._paramvalue
      }
    })
    valuesOfRename = valuesOfRename.filter((obj) => undefined !== obj)
    console.log("valuesOfRename", valuesOfRename)
    // }
    let hasDuplicateRenameValue = false
    if (valuesOfRename.length > 1) {
      hasDuplicateRenameValue = valuesOfRename.some((obj, index) => {
        return valuesOfRename.indexOf(obj) !== index
      })
    }
    console.log("hasDuplicateRenameValue", hasDuplicateRenameValue)

    if (hasDupElementsForRename || hasDuplicateRenameValue) {
      toast.error("Duplicate Rename not allowed", {
        position: "top-center",
        autoClose: 5000,
      })
      return true
    }
    return false

  }

  // check duplicate assign for same or different elements
  const containsDuplicateAssign = (arr) => {
    console.log("arr", arr)

    //check duplicateElements for Assign
    let dupElements = arr.map((obj) => {
      if (obj._parameter_id == "22") {
        return obj._documetkeys_id
      }
    })
      .filter((obj) => undefined !== obj)
    console.log("dupElements", dupElements)
    let hasDupElementsForAssign = false
    if (dupElements.length > 1) {
      hasDupElementsForAssign = dupElements.some((obj, index) => {
        return dupElements.indexOf(obj) !== index
      })
    }
    console.log("hasDupElementsForAssign", hasDupElementsForAssign)

    // if(isAssignExist){
    let valuesOfAssign = arr.map((obj) => {
      if (obj._parameter_id == "22") {
        return obj._paramvalue
      }
    })
    valuesOfAssign = valuesOfAssign.filter((obj) => undefined !== obj)
    console.log("valuesOfAssign", valuesOfAssign)
    // }
    let hasDuplicateAssignValue = false
    if (valuesOfAssign.length > 1) {
      hasDuplicateAssignValue = valuesOfAssign.some((obj, index) => {
        return valuesOfAssign.indexOf(obj) !== index
      })
    }
    console.log("hasDuplicateAssignValue", hasDuplicateAssignValue)

    // if (hasDupElementsForAssign || hasDuplicateAssignValue) {
    if (hasDupElementsForAssign) {
      toast.error("Duplicate Assign not allowed", {
        position: "top-center",
        autoClose: 5000,
      })
      return true
    }
    return false
  }

  // check if the value of position is only a number
  const containsPositionNotANumber = (arr) => {
    console.log("arr", arr)
    let isPositionExist = arr.some((obj) => obj._parameter_id == "11")
    console.log("isPositionExist", isPositionExist)

    let valuesOfPosition = arr.map((obj) => {
      if (obj._parameter_id == "11") {
        return obj._paramvalue
      }
    })
      .filter((obj) => undefined !== obj)
    console.log("valuesOfPosition", valuesOfPosition)
    let wholeNumberPattern = /^\d+$/;
    let isWholeNumber = valuesOfPosition.every((str) => wholeNumberPattern.test(str))
    console.log("isWholeNumber", isWholeNumber)
    if (isWholeNumber == false) {
      toast.error("Position must be a positive whole number")
      return true
    }
    return false

  }

  // check if the value of truncate is only a number
  const containsTruncateNotANumber = (arr) => {
    console.log("arr", arr)
    let isTruncateExist = arr.some((obj) => obj._parameter_id == "9")
    console.log("isTruncateExist", isTruncateExist)

    let valuesOfTruncate = arr.map((obj) => {
      if (obj._parameter_id == "9") {
        return obj._paramvalue
      }
    })
      .filter((obj) => undefined !== obj)
    console.log("valuesOfTruncate", valuesOfTruncate)
    let wholeNumberPattern = /^\d+$/;
    let isWholeNumber = valuesOfTruncate.every((str) => wholeNumberPattern.test(str))
    console.log("isWholeNumber", isWholeNumber)
    if (isWholeNumber == false) {
      toast.error("Truncate must be a positive whole number")
      return true
    }
    return false

  }

  // check duplicate position for same or different elements
  const containsDuplicateKeysPosition = (arr) => {
    console.log("arr", arr)
    // isDuplicateExist =  keySpecData.some((obj,index)=>{
    //   keySpecData.findIndex((item)=>(item._parameter_id == obj._parameter_id) == index)
    // })
    // console.log("isDuplicateExist",isDuplicateExist)

    //check if position exist
    let isPositionExist = arr.some((obj) => obj._parameter_id == "11")
    console.log("isPositionExist", isPositionExist)

    //check duplicateElements for positions
    let dupElements = arr.map((obj) => {
      if (obj._parameter_id == "11") {
        return obj._documetkeys_id
      }
    })
      .filter((obj) => undefined !== obj)
    console.log("dupElements", dupElements)
    let hasDupElementsForPosition = false
    if (dupElements.length > 1) {
      hasDupElementsForPosition = dupElements.some((obj, index) => {
        return dupElements.indexOf(obj) !== index
      })
    }
    console.log("hasDupElementsForPosition", hasDupElementsForPosition)

    // if(isPositionExist){
    let valuesOfPosition = arr.map((obj) => {
      if (obj._parameter_id == "11") {
        return obj._paramvalue
      }
    })
    valuesOfPosition = valuesOfPosition.filter((obj) => undefined !== obj)
    console.log("valuesOfPosition", valuesOfPosition)
    // }
    let numberOfElements = documentKeysList.length

    let isPositionGreater = valuesOfPosition.some((obj) => obj > numberOfElements)
    let invalidPositions = false
    invalidPositions = valuesOfPosition.some((obj) => {
      if (obj > numberOfElements || obj <= 0) {
        return true
      }
    })
    console.log("isPositionGreater", isPositionGreater, invalidPositions)
    // if (isPositionGreater || valuesOfPosition.includes("0")) {
    //   toast.error("Invalid Positions")
    //   return true
    // }
    if (invalidPositions) {
      toast.error("Invalid Positions")
      return true
    }
    let hasDuplicatePositionValue = false
    if (valuesOfPosition.length > 1) {
      hasDuplicatePositionValue = valuesOfPosition.some((obj, index) => {
        return valuesOfPosition.indexOf(obj) !== index
      })
    }
    console.log("hasDuplicatePositionValue", hasDuplicatePositionValue)

    if (hasDupElementsForPosition || hasDuplicatePositionValue) {
      toast.error("Duplicate Positions not allowed")
      return true
    }

    return false

    // isDuplicateExist = keySpecData.some((obj) => {
    //   if (keySpecData.indexOf(obj) !== keySpecData.lastIndexOf(obj)) {
    //     return true
    //   }
    //   return false

    // })
    // console.log("isDuplicateExist", isDuplicateExist)

  }

  // check duplicate hide for same elements
  const containsDuplicateHide = (arr) => {
    console.log("arr", arr)
    let dupElements = arr.map((obj) => {
      if (obj._parameter_id == "12") {
        return obj._documetkeys_id
      }
    })
      .filter((obj) => undefined !== obj)
    console.log("dupElements", dupElements)
    let hasDupElementsForHide = false
    if (dupElements.length > 1) {
      hasDupElementsForHide = dupElements.some((obj, index) => {
        return dupElements.indexOf(obj) !== index
      })
    }
    console.log("hasDupElementsForHide", hasDupElementsForHide)
    if (hasDupElementsForHide) {
      toast.error("Duplicate Hide not allowed")
      return true
    }
    return false
  }

  // useEffect(() => {
  //   console.log("inputData", inputData, docID, IDNo)
  // }, [inputData, IDNo, docID])

  // useEffect(() => {
  //   errorCheck()
  // }, [isError])

  // const errorCheck = ()=>{
  //   if (isError) {
  //     console.log("errorcheck",isError)
  //     toast.error(messages.fillAllFields, {
  //       position: "top-center",
  //       autoClose: 5000,
  //     })
  //   }
  // }

  const switchDefaultRule = async () => {
    setLoading(true)
    try {
      if ("defaultRuleData" in defaultRulePayload.data1) {
        let response = await createOneRule(defaultRulePayload)
        console.log("switchDefaultRule", response)
        if (response.status === 200) {
          toast.success(messages.addedSuccess, {
            position: "top-center",
            autoClose: 5000,
          });
        }
        else {
          toast.error(messages.somethingWrong);
        }
      }
    }
    catch (err) {
      console.log("switchDefaultRuleErr", err)

    }
    finally {
      setRuleName("")
      setRuleDescription("")
      setDocID("")
      setCustomername("")
      setParameterValue("")
      setParamDocValue("")
      setFiles("")
      setIsDefaultRule(false)
      setIsLambda(false)
      setKeySpecData([])
      setDocSpecData([])
      handleNewRuleOk()
      setNewKeysModal(false);
      setIsModalOpen(false);
      handleCancel()
      setDefaultCheckModal(false)
      setDefaultRulePayload()
      setLoading(false)
    }
  }

  const onSubmitRequestApi = async () => {

    console.log("onSubmitDetails_", docSpecData.length, keySpecData.length)
    if (docSpecData.length > 0 && docSpecData.some((obj) => obj._documetkeys_id === "" || obj._parameter_id === "")) {
      setIsError(true)
      toast.error(messages.fillAllFields, {
        position: "top-center",
        autoClose: 5000,
      })

      return
    }
    else if (keySpecData.length > 0 && keySpecData.some((obj) => obj._documetkeys_id === "" || obj._parameter_id === "")) {
      setIsError(true)
      toast.error(messages.fillAllFields, {
        position: "top-center",
        autoClose: 5000,
      })

      return
    }
    else {
      setIsError(false)
    }

    if (docSpecData.length > 0) {
      let isPositionNotANumber2 = containsPositionNotANumber(docSpecData)
      let isTruncateNotANumber2 = containsTruncateNotANumber(docSpecData)
      let isDuplicatePositionExist2 = containsDuplicateKeysPosition(docSpecData)
      let isDuplicateTruncateExist2 = containsDuplicateTruncate(docSpecData)
      let isDuplicateHideExist2 = containsDuplicateHide(docSpecData)
      let isDuplicateRenameExist2 = containsDuplicateRename(docSpecData)
      let isDuplicateAssignExist2 = containsDuplicateAssign(docSpecData)
      if (isPositionNotANumber2 || isTruncateNotANumber2 || isDuplicatePositionExist2 || isDuplicateTruncateExist2 || isDuplicateHideExist2 || isDuplicateRenameExist2 || isDuplicateAssignExist2) {
        return
      }
    }

    if (keySpecData.length > 0) {
      let isPositionNotANumber = containsPositionNotANumber(keySpecData)
      let isTruncateNotANumber = containsTruncateNotANumber(keySpecData)
      let isDuplicatePositionExist = containsDuplicateKeysPosition(keySpecData)
      let isDuplicateTruncateExist = containsDuplicateTruncate(keySpecData)
      let isDuplicateHideExist = containsDuplicateHide(keySpecData)
      let isDuplicateRenameExist = containsDuplicateRename(keySpecData)
      let isDuplicateAssignExist = containsDuplicateAssign(keySpecData)
      if (isPositionNotANumber || isTruncateNotANumber || isDuplicatePositionExist || isDuplicateTruncateExist || isDuplicateHideExist || isDuplicateRenameExist || isDuplicateAssignExist) {
        // toast.error("Duplicate Not Allowed",{
        //   position: "top-center",
        //   autoClose: 5000,
        // })
        return
      }
    }


    // const { document_id, customer_id, doc_type, email, } =
    //   inputData;
    // let sendData = {
    //   _document_id: inputData._document_id,
    //   email: initialValues.email,
    //   _documetkeys_id: inputData._documetkeys_id,
    //   // _documetkeys_id: docID,
    // }

    // let sendData = {
    //   // inputData._document_id,
    //   //   "id": IDNo,
    //   //   "email": email,
    //   "_document_id": docID,
    //   "operation": "CreateRule",
    //   "_rule_name": ruleName,
    //   "_description": ruleDescription,
    //   "useremail": initialValues.email,

    //   "docSpec": {
    //     "operation": "DocRule",
    //     "_parameter_id": parameterValue,
    //     "_paramvalue_doc": paramDocValue,
    //   },

    //   "keyrulepayload": keySpecData
    // }

    if (radioSelection == 1 && customRule == "") {
      toast.error(messages.fillRequiredFields)
      return
    }
    console.log("paramterID==>", customRule, customRuleList)
    let parameterID = customRuleList.filter(item => item.value == customRule)
    // return

    let sendData = {
      // inputData._document_id,
      //   "id": IDNo,
      //   "email": email,

      "data1": {
        // "operation": "CreateRule",
        "_document_id": docID,
        "_comp_id": customerName,
        "_rule_name": ruleName,
        "_description": ruleDescription,
        "useremail": initialValues.email,
        // "_rule_type": isLambda == true && radioSelection == 1 ? "lambdacustom" : isLambda == false && radioSelection == 2 ? "lambdarule" : radioSelection == 1 && files == "customfile" ? "custom" : radioSelection == 1 && files == "lambdafile" ? "lambda" : "rule",
        // "_rule_type": "rule",
        "_custom_rule_id": (customRule == "" || radioSelection == 2) ? null : customRule,
        "_custom_rule_name": (customRuleName == "" || radioSelection == 2) ? null : customRuleName,
        parameter: radioSelection == 1 ? parameterID[0].name : "",
        // lambda: isLambda == true ? 1 : 0
        isDefaultRule: isDefaultRule,
        isLambda: isLambda == true ? 1 : 0,
        isDocument: (radioSelection == 1) ? 1 : 0,
        isHeader: radioSelection == 2 ? 1 : 0,
        isFile: 0
      },
      "data2": {
        // "operation": "DocRule",
        // "_parameter_id": parameterValue,
        // "_paramvalue_doc": paramDocValue,
        "docrulepayload": docSpecData
      },
      "data3": {
        // "operation": "KeyRule",
        "keyrulepayload": keySpecData
      }
    }

    if ((radioSelection == 1 || radioSelection == 3) && customRule !== "") {
      delete sendData.data2
      delete sendData.data3
    }

    console.log("sendData", sendData)
    if (sendData.data1._rule_name == "" || sendData.data1._description == "" || sendData.data1._comp_id == "" || sendData.data1._document_id == "") {
      toast.error(messages.fillAllFields)
      return
    }

    if (isDefaultRule == true) {
      setLoading(true)
      try {
        let defaultRulesForComp = await getDefaultRules(customerName, isDefaultRule)
        console.log("defaultRulesForComp", defaultRulesForComp)
        let defaultRulesData = defaultRulesForComp.data.data
        if (defaultRulesData.length > 0) {
          let { rule_id, rule_name, description } = defaultRulesData[0]
          setDefaultRule({
            ruleId: rule_id, ruleName: rule_name, ruleDescription: description,
          })
          setDefaultCheckModal(true)
          sendData.data1["defaultRuleData"] = defaultRulesData[0]
          setDefaultRulePayload(sendData)
          // setLoading(false)
          const rule = defaultRulesData[0]?.rule_id
          if (isDefaultApi && rule) {
            createDefaultApi(rule)
          }
          return
        }
      }
      catch (err) {
        console.log("err", err)
      }
      // setLoading(false)
    }
    // else {
    setLoading(true)
    console.log("sendData_", sendData);
    try {
      // const response = await createNewRules(sendData)
      const response = await createOneRule(sendData)
      console.log("createNewRulesres", response)
      if (response.status === 200) {
        // setInputData(initialValues);
        toast.success(messages.addedSuccess, {
          position: "top-center",
          autoClose: 5000,
        });

        console.log(response?.data?.data, "_response")
        const rule = response?.data?.data;
        if (isDefaultApi && rule) {
          createDefaultApi(rule)
        }
      }
      else {
        toast.error(messages.somethingWrong);
      }
    } catch (error) {
      console.log("sapsingledataerror", error)
      setLoading(false)
      // setInputData(initialValues);

      // if ("response" in error) {
      //   toast.error(error.response.data.message, {
      //     position: "top-center",
      //     autoClose: 5000,
      //   });
      // } else {
      //   toast.error(error.message, {
      //     position: "top-center",
      //     autoClose: 5000,
      //   });
      // }

      if ("response" in error) {
        if (error.response.status == 403 && error.response.data.defaultRule.length > 1) {
          let { rule_id, rule_name, description } = error.response.data.defaultRule[0]
          setDefaultRule({
            ruleId: rule_id, ruleName: rule_name, ruleDescription: description
          })
          return
        }
      }
      else {
        setError(error)
      }
      // setError(error)
    }
    // }

    // return
    // if (_document_id == "" || customer_id || doc_type || email == "" || _format_id == ""  ) {
    //   toast.error("Please fill all fields...");
    // } 
    // else {
    // let inputData2 = { "document_id": "100", "email": "pallav.panda@rapidqube.com", "_document_id": "", "doc_type": "PDF", }

    // }
    setRuleName("")
    setRuleDescription("")
    setDocID("")
    setCustomername("")
    setParameterValue("")
    setParamDocValue("")
    setFiles("")
    setIsDefaultRule(false)
    setIsLambda(false)
    setKeySpecData([])
    setDocSpecData([])
    handleNewRuleOk()
    setNewKeysModal(false);
    setIsModalOpen(false);
    handleCancel()
    // getUserRulesList()
    setDefaultRulePayload()
    setLoading(false)
  };

  useEffect(() => {
    setHideSelect(true)
  }, [handleNewRuleCancel])

  const createDefaultApi = async (rule) => {
    try {
      console.log(rule, "DefaultApiPayload_")
      const data = {
        "api_id": apiID,
        "app_name": appName,
        "docid": docID,
        "email": requesterUserEmail,
        "requesterId": requesterUserId,
        "purposedataconsum": "Default Api",
        "requestor_name": requesterUserName,
        "rule_id": rule,
        "_api_format_id": 1,
      }
      console.log(data, "payoadData")
      try {
        // const response=await RequestAPI(data)
        // console.log(response,"_apiresponse")
        //     if (response.status === 200) {
        //       toast.success(messages.requestSent, {
        //         //   position: "top-center",
        //         // autoClose: 5000,
        //       });


        //     } else {
        //       toast.error(messages.somethingWrong);
        //     }
        await defaultRequestAPI(data)
          .then((res) => {
            if (res.status === 200) {
              toast.success(messages.requestSent, {
                //   position: "top-center",
                // autoClose: 5000,
              });

            } else {
              toast.error(messages.somethingWrong);
            }
          })

      }
      catch (error) {
        console.log("error", error)
        setInputData(initialValues);
        // toast.error(error.response.data.msg, {
        //   position: "top-right",
        //   autoClose: 5000,
        // });
        setError(error)
      }
    } catch (error) {
      console.log("error_", error)
    }
  }

  const FetchApiDetails = async () => {
    try {
      const response = await getAllParameterRules()
      console.log("getParameterRules", response);
      let res = response.data.data
      let finalArr = []
      finalArr.push(
        {

          "_paramname": "Select",
          "_paramtype": "Select",
        })
      res.map((obj) => {
        if (obj._paramtype == "Document wise") {
          finalArr.push(obj)
        }
      })
      console.log("finalArr", finalArr)

      // setApiDetailState()
      setApiDetailState(finalArr);
      // setHideSelect(false)
    } catch (error) {
      console.log(error);
      setError(error)
    }
  };



  const getDocumentKeysList = async () => {

    setLoading(true)
    let sendData = {
      comp_id: customerName,
      docid: docID
    }
    // const response = await getDocumentKeys(customerName)
    try {
      const response = await getDocumentKeys(sendData)
      console.log("getDocumentKeys", response);

      if (response.status !== 200) {
        setDocumentKeysList([])
        setLoading(false)
        // toast.error(response.message)
        return
      }
      let res = response.resData
      let data = res.data.data
      let finalArr = []
      console.log("rawData_", rawData)
      data.map((obj) => {
        finalArr.push({
          label: obj?._key,
          value: obj?._documetkeys_id,
          type: obj?._key_type,
          seq: obj?._seq_no
        })
      })
      // console.log("finalArr_",finalArr)
      setDocumentKeysList(finalArr);
    }
    catch (err) {
      setError(err)

    }
    setLoading(false)
  }

  useEffect(() => {

    let documentWiseKeySeqNull = documentKeysList.filter((obj) => obj.type == "document_wise" && obj.seq == null)
    let documentWiseKeySeqNotNull = documentKeysList.filter((obj) => obj.type == "document_wise" && obj.seq !== null)

    let keySpecWiseSeqNull = documentKeysList.filter((obj) => obj.type == "key_wise" && obj.seq == null)
    let keySpecWiseSeqNotNull = documentKeysList.filter((obj) => obj.type == "key_wise" && obj.seq !== null)

    console.log("documentWiseKey_", documentWiseKeySeqNull, documentWiseKeySeqNotNull)
    let docSpecArray = []
    let keySpecArray = []
    if (documentWiseKeySeqNotNull.length > 0 || documentWiseKeySeqNull.length > 0) {
      documentWiseKeySeqNotNull.map((item, i) => {
        console.log("documentWiseKey__", item)
        // newDocSpecData[i]._documetkeys_id = item.value
        // newDocSpecData[i]._parameter_id = 28
        // newDocSpecData[i]._paramvalue = item.value
        let newDocSpecData = {
          "_paramvalue": item.label,
          "_parameter_id": 28,
          "_documetkeys_id": item.value,
          "_seq_no": item.seq
        }
        docSpecArray.push(newDocSpecData)
      })
      console.log("docSpecArray_", docSpecArray)

      documentWiseKeySeqNull.map((item, i) => {
        console.log("documentWiseKey__", item)
        // newDocSpecData[i]._documetkeys_id = item.value
        // newDocSpecData[i]._parameter_id = 28
        // newDocSpecData[i]._paramvalue = item.value
        let newDocSpecData = {
          "_paramvalue": item.label,
          "_parameter_id": 28,
          "_documetkeys_id": item.value,
          "_seq_no": docSpecArray.length + 1
        }
        docSpecArray.push(newDocSpecData)
      })

      console.log("docSpecArray_1", docSpecArray)
      docSpecArray.sort((a, b) => a._seq_no - b._seq_no)
      setSelectedParam(28)
      setDocSpecData(docSpecArray)
    }

    if (keySpecWiseSeqNotNull.length > 0 || keySpecWiseSeqNull.length > 0) {
      keySpecWiseSeqNotNull.map((item, i) => {
        console.log("keySpecWise_", item)
        // newDocSpecData[i]._documetkeys_id = item.value
        // newDocSpecData[i]._parameter_id = 28
        // newDocSpecData[i]._paramvalue = item.value
        let newKeySpecData = {
          "_paramvalue": item.label,
          "_parameter_id": 28,
          "_documetkeys_id": item.value,
          "_seq_no": item.seq
        }
        console.log("keySpecArray_", keySpecArray);
        keySpecArray.push(newKeySpecData)
      })
      keySpecWiseSeqNull.map((item, i) => {
        console.log("keySpecWise_", item)
        // newDocSpecData[i]._documetkeys_id = item.value
        // newDocSpecData[i]._parameter_id = 28
        // newDocSpecData[i]._paramvalue = item.value

        let newKeySpecData = {
          "_paramvalue": item.label,
          "_parameter_id": 28,
          "_documetkeys_id": item.value,
          "_seq_no": keySpecArray.length + 1
        }
        keySpecArray.push(newKeySpecData)
      })
      console.log("keySpecArray_1", keySpecArray)
      // let filterKeySpec= keySpecArray.filter(item => item._seq_no == null)
      keySpecArray.sort((a, b) => a._seq_no - b._seq_no)
      setSelectedParam(28)
      setKeySpecData(keySpecArray)
    }

    // setDocSpecData([...docSpecData, { ...docSpecField }])
    // setDocSpecField({
    //   // "operation": "KeyRule",
    //   "_paramvalue": "",
    //   "_parameter_id": "",
    //   "_documetkeys_id": "",
    // })


  }, [documentKeysList])

  console.log("key", documentKeysList)
  const getParameterRulesList = async () => {
    setLoading(true)
    try {
      // const response = await getParameterRules()
      const response = await getAllParameterRules()
      console.log("getParameterRules", response);
      let res = response.data.data
      let finalArr = []
      res.map((obj) => {
        if (obj._paramtype == "keys wise") {
          finalArr.push({
            label: obj._paramname,
            value: obj._parameter_id
          })
        }
      })
      console.log("finalArr", finalArr)
      setParameterRulesList(finalArr);
    } catch (error) {
      console.log(error);
      setError(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    FetchApiDetails()
    getDocumentKeysList()
  }, [])

  const showModal = () => {
    // setSelectedRow(params.row)
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    // setParameter({
    //   name: "Select a Parameter Type",
    //   value: ""
    // })
    setParameterValue("")
    setParamDocValue("")
    setApiDetailState([])
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    // setParameter({
    //   name: "Select a Parameter Type",
    //   value: ""
    // })
    setParameterValue("")
    setParamDocValue("")
    setApiDetailState([])

  };

  const newRuleModalCancel = () => {
    setNewKeysModal(false);
  };

  const handleParameterValue = (e) => {
    const { name, value } = e.target;
    console.log("parametervalue", value, name)
    if (value == "Select") {
      setParameterValue("")
      return
    }
    setParameterValue(value)
  };

  const handleparamDocValue = (e) => {
    const { name, value } = e.target;
    setParamDocValue(value)
  };

  const handleAfterNewRule = () => {
    setNewRuleData({
      "_document_id": docID,
      "_comp_id": customerName,
      "_rule_name": ruleName,
      "_description": ruleDescription,
      "useremail": initialValues.email,
      // "docSpec": {
      //   "_parameter_id": parameterValue,
      //   "_paramvalue_doc": paramDocValue,
      // },
      // "keySpec": ruleSpec
    })
    // setHideSelect(true)
    // let cloneApiDetailState = _.clon
    setParameterValue("")
    setParamDocValue("")
    setApiDetailState([])
    setApiDetailState([...apiDetailState])
    setIsModalOpen(true)
    setNewKeyForDoc("")
    setNewKeyForKey("")
    setDocSpecData([])
    setKeySpecData([])
  }

  const handleAfterDocSpec = () => {

    let isDuplicatePositionExist = containsDuplicateKeysPosition(docSpecData)
    let isDuplicateTruncateExist = containsDuplicateTruncate(docSpecData)
    let isDuplicateHideExist = containsDuplicateHide(docSpecData)
    let isDuplicateRenameExist = containsDuplicateRename(docSpecData)
    if (isDuplicatePositionExist || isDuplicateTruncateExist || isDuplicateHideExist || isDuplicateRenameExist) {
      return
    }

    if (docSpecData.some((obj) => obj._documetkeys_id === "" || obj._parameter_id === "" || obj._paramvalue === "")) {
      setIsError(true)
      toast.error(messages.fillAllFields, {
        position: "top-center",
        autoClose: 5000,
      })
      setLoading(false)
      return
    }

    setNewKeysModal(true)
    setNewKeys("")
    setNewParamNames("")
    setNewValueNo("")

    setNewRuleData({
      ...newRuleData,
      "docSpec": {
        "_parameter_id": parameterValue,
        "_paramvalue_doc": paramDocValue,
      },
      // "keySpec": ruleSpec

    })
  }

  const handleSelectKey = (e, index) => {
    console.log("addNewRules_", e)
    setIsDocType(false)
    let newKeySpecData = [...keySpecData]
    // newKeySpecData[index]._documetkeys_id = e.target.value
    newKeySpecData[index]._documetkeys_id = e
    setKeySpecData(newKeySpecData)
  }

  const handleSelectParameters = (e, index) => {
    console.log("selectedFrom", e)
    setIsDocType(false)
    setSelectedParam(e)
    let newKeySpecData = [...keySpecData]
    // newKeySpecData[index]._parameter_id = e.target.value
    newKeySpecData[index]._parameter_id = e
    // setParamterID(e)
    // newKeySpecData[index]._paramvalue = e == 12 ? "Hidden" : newKeySpecData[index]._paramvalue
    setInx(index)

    if (e == 26) {
      setIsSubString(true)
      setExpressionModal(true)
    } else if (e == 27) {
      setIsConcat(true)
      setExpressionModal(true)
    } else if (e == 28) {
      setIsMap(true)
      setExpressionModal(true)
    } else if (e == 30) {
      setIsTrim(true)
      setExpressionModal(true)
    } else if (e == 31) {
      setIsInt(true)
      setExpressionModal(true)
    }
    newKeySpecData[index]._paramvalue = e == 12 ? "Hidden" : e !== 12 && newKeySpecData[index]._paramvalue == "Hidden" ? "" : ""
    setKeySpecData(newKeySpecData)
  }

  const handleKeyParamValue = (e, index) => {
    console.log("index_", e)
    setIsDocType(false)
    let newKeySpecData = [...keySpecData]
    newKeySpecData[index]._paramvalue = e.target.value
    setKeySpecData(newKeySpecData)
  }

  const handleSeqNo = (e, index, type) => {
    console.log("index_", e.target.value)
    // setIsDocType(false)
    setSequenceNo(e.target.value)
    if (type == "keySpec") {
      let newKeySpecData = [...keySpecData]
      newKeySpecData[index]._seq_no = e.target.value
      setKeySpecData(newKeySpecData)
    } else {
      let newDocSpecData = [...docSpecData]
      newDocSpecData[index]._seq_no = e.target.value
      setDocSpecData(newDocSpecData)
    }
  }



  const handleSelectDoc = (e, index) => {
    console.log("handleSelectDoc", e)
    setIsDocType(true)
    let newDocSpecData = [...docSpecData]
    // newDocSpecData[index]._documetkeys_id = e.target.value
    newDocSpecData[index]._documetkeys_id = e
    setDocSpecData(newDocSpecData)
  }

  const handleSelectDocParameters = (e, index) => {
    console.log("handleSelectDocParameters", e, index)
    setIsDocType(true)
    setInx(index)

    if (e == 26) {
      setIsSubString(true)
      setExpressionModal(true)
    } else if (e == 27) {
      setIsConcat(true)
      setExpressionModal(true)
    } else if (e == 28) {
      setIsMap(true)
      setExpressionModal(true)
    } else if (e == 30) {
      // let str = "0052.00"
      // console.log("convert to int",parseInt(str))
      setIsTrim(true)
      setExpressionModal(true)
    } else if (e == 31) {
      setIsInt(true)
      setExpressionModal(true)
    }
    let newDocSpecData = [...docSpecData]
    // // newDocSpecData[index]._parameter_id = e.target.value
    newDocSpecData[index]._parameter_id = e

    // newDocSpecData[index]._paramvalue = e == 12 ? "Hidden" : newDocSpecData[index]._paramvalue
    newDocSpecData[index]._paramvalue = e == 12 ? "Hidden" : e !== 12 && newDocSpecData[index]._paramvalue == "Hidden" ? "" : ""
    setDocSpecData(newDocSpecData)
  }

  const handleDocParamValue = (e, index) => {
    let newDocSpecData = [...docSpecData]
    newDocSpecData[index]._paramvalue = e.target.value
    setDocSpecData(newDocSpecData)
  }

  console.log("newRule", newRuleData)


  const OnRadioChange = (e) => {
    console.log('radio_checked', e.target.value);
    let value = e.target.value

    let updatedStatusList = radioOptions.map((item) => {
      if (item.value == value) {
        item.status = true
      }
      else {
        item.status = false
      }
      return item
    })
    setRadioSelection(value)
    setRadioOptions(updatedStatusList)

  };



  const onConcatRadioChange = (e) => {
    console.log('radio_checked', e.target.value);
    let value = e.target.value

    let updatedStatusList = concatRadioOption.map((item) => {
      if (item.value == value) {
        item.status = true
      }
      else {
        item.status = false
      }
      return item
    })
    setConcatRadioSelection(value)
    setConcatRadioOption(updatedStatusList)

  };


  const getCustomRulesList = async (compID, docID) => {
    console.log("compID", compID)
    setLoading(true)
    try {
      await getCustomRules(compID, docID).then((res) => {
        console.log("getcustomruleres", res)
        if (res.status == 200) {
          let data = res.data.data
          let finalArr = []
          data.map((obj) => {
            finalArr.push({
              name: obj._rule_name,
              value: obj._rule_id,
              rulestype: obj.rulestype
            })
          })
          setCustomRuleList(finalArr)
        }
      })
    }
    catch (error) {
      console.log("getcustomrulereserror", error)
      setError(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (radioSelection == 2) {
      return
    }
    updateParameterList()
    setCustomRule("")
  }, [files, isLambda, customRuleList, radioSelection])

  const updateParameterList = () => {
    console.log("updateParam_", customRuleList)
    if (customRuleList.length > 0) {
      let finalArr = []
      customRuleList.forEach((obj) => {
        if (obj.rulestype !== null) {
          let trimmedRulestype = obj.rulestype.trim()
          // if (trimmedRulestype == files) {
          //   finalArr.push(obj)
          // }
          if (isLambda) {
            if (trimmedRulestype == "lambdafile") {
              finalArr.push(obj)
            }
          }
          else if (radioSelection == 3) {
            if (trimmedRulestype == "spfile") {
              finalArr.push(obj)
            }
          } else {
            if (trimmedRulestype == "customfile") {
              finalArr.push(obj)
            }
          }
        }
      })
      setParameterFilesList(finalArr)
    }
  }

  const handleFilesType = (e) => {
    console.log("handleFilesType", e, filesList, customRuleList)
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
    setFiles(value)
    // updateParameterList(value)
  }

  const handleCustomRule = (e) => {
    console.log("handlecustomRule", e, customRuleList)
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
    setCustomRule(value)

    let customName = customRuleList.filter((obj) => obj.value == value)[0]["name"]
    console.log("customName", customName)
    setCustomRuleName(customName)
  };

  const handleDefaultCheckClose = () => {
    setDefaultRule({
      ruleId: "", ruleName: "", ruleDescription: "",
    })
    setDefaultCheckModal(false)
  }

  const handleExpression = (e) => {
    console.log("exp_", e.target.value)
    setExpression(e.target.value)
  }

  const handleAddExp = () => {
    console.log("Addbutton");
  }

  const handleSelectedKeyFrom = (e) => {
    console.log("selectedKey", e)
    // let newKeySpecData = [...keySpecData]
    // newKeySpecData[inx]._documetkeys_id = e.target.value

    setSelectdeKeyFrom(e)
    setAssignValue("")

  }



  const handleConcatSelected = (e) => {
    console.log("selectedKey", e)
    setSelectedConcattKey(e)
  }

  const handleSelectedKeyTo = (e) => {
    console.log("selectedKey", e)
    setSelectdeKeyTo(e)
    setAssignValue("")
  }

  const handleExpCancel = () => {
    console.log("cancel")
    setSelectdeKeyFrom("")
    setSelectdeKeyTo("")
    setExpression("")
    setFromValue("")
    setToValue("")
    setAssignValue("")
    setSelectedConcattKey([])
    setExpressionModal(false)
    setIsSubString(false)
    setIsConcat(false)
    setIsMap(false)
    setIsInt(false)
    setIsTrim(false)
    setIsAddConst(false)
  }

  const onSubmitExpression = () => {
    console.log("submit_")
    let valueconcat
    // let filterobject = documentKeysList.filter((obj) => isDocType ? obj.type == "document_wise" : obj.type == "key_wise")
    let filterobject = documentKeysList
    let filterKey = filterobject.filter((obj) => obj.value == selectedKeyFrom)
    let filterToKey = filterobject.filter((obj) => obj.value == selectedKeyTo)
    console.log("newKeySpecData_", filterKey, filterToKey, isConcat)

    if (isSubString) {
      valueconcat = filterKey[0].label + ',' + fromValue + ',' + toValue
    } else if (isConcat) {
      console.log("filterobject__", filterobject, selectedConcattKey)
      let filterToKeyconcat = filterobject.filter((obj) => selectedConcattKey.includes(obj.value))
      console.log("filterobject_", filterToKeyconcat)
      let finalfilter = []
      selectedConcattKey.map((item) => {
        filterToKeyconcat.map((obj) => {
          if (item == obj.value) {
            finalfilter.push(obj)
          }
        })
      })
      console.log("finalfilter_", finalfilter);
      // valueconcat = assignValue ? filterKey[0].label + ',' + assignValue : filterKey[0].label + ',' + filterToKey[0].label
      valueconcat = finalfilter.map((item) => { return item.label }).join(',')
      if (isAddConst) {
        valueconcat = (concatRadioSelection == 1) ? valueconcat + ',' + assignValue : assignValue + ',' + valueconcat
        setIsConstValue(true)
      } else {
        setIsConstValue(false)
      }
      console.log("valueconcat_", valueconcat)
    } else {
      valueconcat = assignValue ? assignValue : filterToKey[0].label
    }
    setParamValue(valueconcat)
    console.log("concatValue", valueconcat, isDocType);
    let newKeySpecData
    if (isDocType) {
      newKeySpecData = [...docSpecData]
      newKeySpecData[inx]._paramvalue = valueconcat
      console.log("newKeySpecData_", newKeySpecData);
      setDocSpecData(newKeySpecData)
    } else {
      newKeySpecData = [...keySpecData]
      newKeySpecData[inx]._paramvalue = valueconcat
      console.log("newKeySpecData_", newKeySpecData);
      setKeySpecData(newKeySpecData)
    }
    handleExpCancel()
  }


  const handleInputClick = (inx, type, paramValue, paramId) => {
    console.log("inputClick_", selectedParam, paramValue, isDocType, paramId, filterDocumentKeyList)
    // let filterobject = documentKeysList.filter((obj) => type ? obj.type == "document_wise" : obj.type == "key_wise")
    let filterobject = filterDocumentKeyList
    setIsDocType(type)
    setInx(inx)
    console.log("filterObject_", filterobject);

    let splitData
    if (paramId == 26) {
      splitData = paramValue.split(',')
      console.log("newKey_", splitData);
      let finalValue = capitalizeAllWords(splitData[0])
      let filterKey = filterobject.filter((obj) => obj.label == finalValue)
      console.log("filterKey_", filterKey[0]?.value)
      setSelectdeKeyFrom(filterKey[0].value)
      setFromValue(splitData[1])
      setToValue(splitData[2])
      setIsSubString(true)
      setExpressionModal(true)
    } else if (paramId == 27) {
      splitData = paramValue.split(',')
      // let firstkey = capitalizeAllWords(splitData[0])
      // let secondKey = capitalizeAllWords(splitData[1])
      // let filterKey = filterobject.filter((obj) => obj.label == firstkey)
      // let filterToKey = filterobject.filter((obj) => obj.label == secondKey)
      splitData = splitData.filter((item) => item !== "")
      console.log("constData_", splitData.length, splitData, assignValue);

      let filterkey = []
      splitData.map((item) => {
        filterobject.map((obj) => {
          if (obj.label == item) {
            filterkey.push(obj.value)
          }
        })
      })

      console.log("filterKeyFinal_", filterkey, concatRadioSelection)
      let constData
      if (isConstValue) {
        setIsAddConst(true)
        if (concatRadioSelection == 1) {
          constData = splitData[splitData.length - 1]
        } else {
          constData = splitData[0]
        }
        console.log("constData_", constData);
      }

      setSelectedConcattKey(filterkey)
      setAssignValue(constData)
      setIsConcat(true)
      setExpressionModal(true)
    } else if (paramId == 28) {
      splitData = paramValue.split(',')
      let finalValue = capitalizeAllWords(splitData[0])
      let filterToKey = filterobject.filter((obj) => obj.label == finalValue)
      console.log("filterToKey_", splitData, splitData[0].charAt(0).toUpperCase(), filterToKey, finalValue)
      if (filterToKey.length > 0) {
        setSelectdeKeyTo(filterToKey[0].value)
      } else {
        setAssignValue(splitData[0])
      }
      setIsMap(true)
      setExpressionModal(true)
    } else if (paramId == 30) {
      splitData = paramValue.split(',')
      let finalValue = capitalizeAllWords(splitData[0])
      let filterToKey = filterobject.filter((obj) => obj.label == finalValue)
      console.log("filterToKey_", splitData, splitData[0].charAt(0).toUpperCase(), filterToKey, finalValue)
      if (filterToKey.length > 0) {
        setSelectdeKeyTo(filterToKey[0].value)
      } else {
        setAssignValue(splitData[0])
      }
      setIsTrim(true)
      setExpressionModal(true)
    } else if (paramId == 31) {
      splitData = paramValue.split(',')
      let finalValue = capitalizeAllWords(splitData[0])
      let filterToKey = filterobject.filter((obj) => obj.label == finalValue)
      console.log("filterToKey_", splitData, splitData[0].charAt(0).toUpperCase(), filterToKey, finalValue)
      if (filterToKey.length > 0) {
        setSelectdeKeyTo(filterToKey[0].value)
      } else {
        setAssignValue(splitData[0])
      }
      setIsInt(true)
      setExpressionModal(true)
    }
    else {
      return
    }
  }

  function capitalizeAllWords(string) {
    return string.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }

  // useEffect(() => {
  //   if (rawData.length > 0 && documentKeysList.length > 0) {
  //     let filterRawData = []
  //       rawData.map((item, i) => {
  //           filterRawData.push({
  //             label: item?._key,
  //             value: item?._documetkeys_id,
  //             type: item?._key_type,
  //             seq: item?._seq_no
  //           })
  //       })
  //     setFilterDocumentKeyList(filterRawData)
  //   }
  // }, [rawData])

  const fetchRowDataDetails = async () => {

    let filterRawData = []
    try {
      let payload = {
        DocId: docID
      }
      const response = await getRawDataDetails(payload)
      let res = response.resData
      let data = res.data.data
      data.map((obj) => {
        filterRawData.push({
          label: obj?.key,
          value: obj?.documetkeys_id,
          type: obj?.key_type,
          seq: obj?.sequence
        })
      })
      setFilterDocumentKeyList(filterRawData)
      console.log("getRawDataDetails", response, data);
    } catch {
      console.log("catch error")
    }
  }

  const fetchSourceKeys = async () => {

    let filterRawData = []
    try {
      const response = await getSourceKeys()
      console.log("response_source", response)
      let res = response.data
      let data = res.data
      data.map((obj) => {
        filterRawData.push({
          label: obj?.key,
          value: obj?.documetkeys_id,
          type: obj?.key_type,
        })
      })
      setSourceKeyLists(filterRawData)
      console.log("getRawDataDetails", filterRawData);
    } catch {
      console.log("catch error")
    }
  }

  const handleDeleteAllField = (param) => {
    setIsDeleteAllModal(true)
    if (param == "Doc") {
      setIsDocType(true)
    } else {
      setIsDocType(false)
    }
  }

  const handleDeleteAllCancel = () => {
    setIsDeleteAllModal(false)
  }

  const handleDeleteAllSubmit = () => {
    if (isDocType) {
      setDocSpecData([])
    } else {
      setKeySpecData([])
    }
    setIsDeleteAllModal(false)
  }

  const handleIsConstant = (e) => {
    setIsAddConst(e.target.checked)
    setAssignValue("")
    setConcatRadioSelection(1)
  }

  return (
    <>
      <Loader loading={loading} />
      <Toaster position="top-center" reverseOrder={false} />
      <ErrorCatch err={error} />

      {/* default rule check starts */}
      <Modal
        className="p-2"
        // zIndex= {1000}
        open={defaultCheckModal}
        // style={{ top: 20, minWidth: 900, maxWidth: 1200 }}
        onCancel={handleDefaultCheckClose}
        maskClosable={false}
        footer={[
          <div className="d-flex justify-content-end gap-1">
            <Button
              className="btn btn-primary btn-sm"
              type="primary"
              onClick={switchDefaultRule}
            >
              Submit
            </Button>
            <Button className="btn btn-secondary btn-sm" type="secondary" onClick={handleDefaultCheckClose}>
              Cancel
            </Button>
          </div>
        ]}
      >
        <div class="row">
          <div className="col text-center">
            <p className="heading-popup">Default Rule</p>
          </div>
        </div>

        <div className="">
          <p>
            <strong>Default Rule Name:</strong> {defaultRule.ruleName} <br />
            <strong>Description:</strong> {defaultRule.ruleDescription}
          </p>
          <p>
            The above rule already exists as a default rule for this company.<br />
            Would you like to set this new rule as default ?
          </p>
        </div>
      </Modal>
      {/* default rule check ends */}

      {/* Delete all confirmation starts*/}
      <Modal
        className="p-2"
        // zIndex= {1000}
        open={isDeleteAllModal}
        // style={{ top: 20, minWidth: 900, maxWidth: 1200 }}
        onCancel={handleDeleteAllCancel}
        maskClosable={false}
        footer={[
          <div className="d-flex justify-content-end gap-1">
            <Button
              className="btn btn-primary btn-sm"
              type="primary"
              onClick={handleDeleteAllSubmit}
            >
              Submit
            </Button>
            <Button className="btn btn-secondary btn-sm" type="secondary" onClick={handleDeleteAllCancel}>
              Cancel
            </Button>
          </div>
        ]}
      >
        <div class="row">
          <div className="col text-center">
            <img src={warning} className="imgicon"></img>
          </div>
        </div>

        <div className=" text-center py-2">
          <h3>
            Do you want to Delete All the Fields
          </h3>
        </div>
      </Modal>
      {/* Delete all confirmation ends */}
      <div className="container"
      >
        <Modal
          // width={500}
          className="p-2"
          zIndex={900}
          open={isNewRuleModal}
          onOk={handleNewRuleOk}
          onCancel={handleNewRuleCancel}
          footer={null}
          maskClosable={false}
          style={{ top: 20, minWidth: 920, maxWidth: 1200 }}
          bodyStyle={{ height: `${radioSelection == 2 && 500}` }}
          destroyOnClose
        >
          <div class="row">
            <div className="col text-center">
              {/* <h6>
                <b>Add New Rules</b>
              </h6> */}
              <p className="heading-popup">Add New Rule</p>
            </div>
          </div>

          <div className="container"
            ref={containerRef}
            style={{
              // height: radioSelection == 2 ? "28rem" : "19rem",
              height: radioSelection == 2 ? "32rem" : "28rem",
              overflowY: "auto", overflowX: "hidden"
            }}
          >
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <p className="heading-popup">Rule Details</p>
              </AccordionSummary>
              <AccordionDetails>

                <div class="row mb-3 mt-2">
                  <div className="col-md-6">

                    <SelectInput
                      margin="0"
                      selectLabel="*Customer"
                      name="_comp_id"
                      value={customerName}
                      onChange={(e) => handleCustomerName(e)}
                      optionsList={customerList}
                    />

                    {/* <select
                name="_comp_id"
                onChange={(e) => handleCustomerName(e)}
                class="form-control"
              >
                <option value={customerPlaceHolder.value} disabled selected hidden>{customerPlaceHolder.name}</option>
                {customerList.map((val) => {
                  const { comp_id, _customername } = val;
                  return <option value={comp_id}>{_customername}</option>;
                })}
              </select> */}
                  </div>
                  <div className="col-md-6">

                    <SelectInput
                      margin="0"
                      selectLabel="*Document Type"
                      name="_documetkeys_id"
                      value={docID}
                      onChange={(e) => handleDocumentType(e)}
                      optionsList={apiFormatState2}
                    />
                  </div>
                </div>

                <div class="row mb-3 d-flex justify-content-center">
                  <div className="col-md-12">

                    <InputBox
                      label="*Rule Name"
                      name="_rule_name"
                      fullWidth
                      onChange={(e) => handleRuleName(e)}
                      value={ruleName}
                    />

                    {/* <input
                type="text"
                name="_rule_name"
                className="form-control"
                // value={inputData._document_id}
                value={ruleName}
                // onChange={(e) => handleInput(e)}
                onChange={(e) => handleRuleName(e)}
                class="form-control"
                placeholder={"Rule Name"}
              /> */}
                  </div>
                </div>

                {/* <div class="row mb-3 d-flex justify-content-center">
                <div className="col-md-6">
                  <input
                    type="text"
                    name="_description"
                    className="form-control"
                    // value={inputData._document_id}
                    value={ruleDescription}
                    onChange={(e) => handleRuleDescription(e)}
                    class="form-control"
                    placeholder={"Rule Description"}
                  />
                </div>
              </div> */}

                <div class="row mb-3 d-flex justify-content-center">
                  <div className="col-md-12">
                    <InputBox
                      label="*Rule Description"
                      name="_description"
                      fullWidth
                      multiline
                      rows={2}
                      onChange={(e) => handleRuleDescription(e)}
                      value={ruleDescription}
                    />

                    {/* <textarea
                type="text"
                name="_description"
                className="form-control"
                // value={inputData._document_id}
                value={ruleDescription}
                onChange={(e) => handleRuleDescription(e)}
                class="form-control"
                placeholder={"Rule Description"}
              /> */}

                  </div>
                </div>

                <div className="row">
                  <CheckboxComp
                    label="Default Rule"
                    isChecked={isDefaultRule}
                    onChange={handleDefaultRuleCheckBox}
                  />

                  <CheckboxComp
                    label="Default Api"
                    isChecked={isDefaultApi}
                    onChange={handleDefaultApiCheckBox}
                    disabled={customerName !== "" ? false : true}
                  />

                  {isDefaultApi &&

                    <div className="row mt-3">
                      <div className="col-md-6">
                        <SelectInput
                          margin="0"
                          selectLabel="*Customer List"
                          name="_comp_id"
                          value={requesterName}
                          onChange={(e) => handleRequesterList(e)}
                          optionsList={selectedCustomerList}
                        />

                      </div>
                      <div className="col-md-6">
                        <InputBox
                          label="*App Name"
                          name="_app_name"
                          fullWidth

                          onChange={(e) => handleAppName(e)}
                          value={appName}
                        />
                      </div>
                    </div>
                  }
                </div>

                {/* <div className="row">
              <CheckboxComp
                label="Lambda"
                isChecked={isLambda}
                onChange={handleLambdaCheckBox}
                disabled={radioSelection == 3 ? true : false}
              />
            </div> */}

                <div className="mt-2">
                  {/* <text style={{ fontSize: "12px", color: "grey" }}>Download File</text> */}
                  {/* <div className="col-3 mt-2"> */}

                  {/* <Radio.Group name="radiogroup" defaultValue={1} style={{ display: "flex" }}
                onChange={OnRadioChange}
              >
                <Radio value={1} style={{ fontSize: "14px" }} >Document</Radio>
                <Radio value={2} style={{ fontSize: "14px" }} >Header/Details</Radio>
              </Radio.Group> */}

                  <RadioButton
                    optionsList={radioOptions}
                    selectedValue={radioSelection}
                    handleChange={(event) => OnRadioChange(event)}
                  />
                  {/* </div> */}
                </div>


                {/* custom rule start */}
                {(radioSelection == 1 || radioSelection == 3) &&

                  <div className="row mt-3">

                    {/* <div className="col-md-6">
                  <SelectInput
                    margin="0"
                    selectLabel="*Rule Type"
                    name="_rule_type"
                    value={files}
                    onChange={(e) => handleFilesType(e)}
                    optionsList={filesList}
                    disabled={customerName !== "" && docID !== "" ? false : true}
                  />
                </div> */}

                    <div className="col-md-6">
                      <SelectInput
                        margin="0"
                        selectLabel="*Parameter"
                        name="_documetkeys_id"
                        value={customRule}
                        onChange={(e) => handleCustomRule(e)}
                        // optionsList={customRuleList}
                        optionsList={parameterFilesList}
                        // disabled={customerName !== "" && docID !== "" && files !== "" ? false : true}
                        disabled={customerName !== "" && docID !== "" ? false : true}
                      />

                    </div>
                  </div>
                }

                {/* custom rule end */}
              </AccordionDetails>
            </Accordion>



            {/* header/details start */}
            {/* document header start */}

            {radioSelection == 2 &&
              <div
              // ref={containerRef}
              // className="container"
              // style={{ height: "15rem", overflowY: "auto", overflowX: "hidden" }}
              >
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    {/* Document headers */}
                    <p className="heading-popup">Set Document headers</p>
                  </AccordionSummary>
                  {/* {undefined == docID || docID == "" && */}
                  <AccordionDetails>
                    <div
                    // className="container"
                    >
                      <div className="row justify-content-end">

                        <div className="col-sm-6 mt-1">
                          {/* <p className="heading-popup">Set Document headers</p> */}
                        </div>

                        <div className="col-sm-6 mt-1 d-flex justify-content-end">
                          <div style={{ marginRight: "10px" }}>
                            <button
                              // style={{ padding: "0 5px" }}
                              // onClick={onSubmitRequestApi}
                              onClick={() => handleDeleteAllField("Doc")}
                              class="btn btn-danger btn-sm"
                              disabled={(undefined == customerName || customerName == "") ? true : (undefined == docID || docID == "") ? true : docSpecData.length == 0 ? true : false}
                            >
                              Delete All
                            </button>
                          </div>
                          <div style={{ marginRight: "10px" }}>
                            <button
                              // style={{ padding: "0 5px" }}
                              // onClick={onSubmitRequestApi}
                              onClick={handleAddDocField}
                              class="btn btn-primary btn-sm"
                              disabled={(undefined == customerName || customerName == "") ? true : (undefined == docID || docID == "") ? true : false}
                            // disabled={true}
                            // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                            >
                              {/* <BsPlus />  */}
                              Add
                            </button>
                          </div>
                        </div>
                      </div>

                      <div
                        ref={docContainerRef}
                        className="row mt-2"
                        style={{
                          height: docSpecData.length > 2 ? "9rem" : "",
                          overflowY: "auto", overflowX: "hidden"
                        }}
                      >
                        {docSpecData.map((obj, index) => (
                          <div key={index} className="row mt-2">
                            <div className="col-md-4 mt-2">

                              {/* <SelectInputWithAdd
                            selectInputValue={obj._documetkeys_id}
                            handleSelectInput={(e) => { handleSelectDoc(e, index) }}
                            onNameChange={(e) => onEnterNewItemForDoc(e)}
                            optionsList={documentKeysList.filter((obj) => obj.type == "document_wise")}
                            newItem={newKeyForDoc}
                            onAddItem={(e) => handleAddNewItemForDoc(e)}
                            placeholder="Keys"
                          /> */}
                              <SelectInputAntd
                                value={obj._documetkeys_id}
                                onChange={(e) => handleSelectDoc(e, index)}
                                optionsList={documentKeysList.filter((obj) => obj.type == "document_wise")}
                                placeholder="Keys"
                              />
                            </div>

                            <div className="col-md-2 mt-2">

                              <SelectInputAntd
                                value={obj._parameter_id}
                                onChange={(e) => handleSelectDocParameters(e, index)}
                                optionsList={parameterRulesList}
                                placeholder="Parameter"
                              />
                            </div>

                            <div className="col-md-3 mt-2">

                              <InputBoxAntd
                                name="newValueNo"
                                value={obj._paramvalue}
                                onChange={(e) => handleDocParamValue(e, index)}
                                placeholder="Values"
                                disabled={obj._parameter_id == 12 ? true : false}
                                onClick={() => handleInputClick(index, true, obj._paramvalue, obj._parameter_id)}
                              />
                            </div>

                            <div className="col-md-2 mt-2">
                              <InputBoxAntd
                                name="seqNo"
                                value={obj._seq_no}
                                onChange={(e) => handleSeqNo(e, index, "docSpec")}
                                placeholder="Sequence No"
                                disabled={obj._parameter_id == 12 ? true : false}
                              // onClick={() => handleInputClick(index, false, obj._paramvalue)}
                              />
                            </div>

                            <div className="col-md-1 d-flex justify-content-end mt-2">
                              <div
                              // style={{ marginRight: "10px" }}
                              >
                                <button
                                  // style={{ padding: "0 5px" }}
                                  onClick={() => handleDeleteDocField(index)}
                                  class="btn btn-danger btn-sm"
                                // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                                >
                                  <BsFillTrashFill />
                                </button>
                              </div>
                            </div>

                          </div>
                        ))}
                      </div>
                      {/* <hr className="text-secondary"></hr> */}
                    </div>

                    {/* document header end */}
                  </AccordionDetails>
                  {/* } */}
                </Accordion>

                {/* key spec start */}


                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <p className="heading-popup">Set Key Specifications</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                    // className="container"
                    >

                      {/* <div className="row">
                  <div className="col text-center mb-1">
                    <p className="heading-popup">Set Key Specification</p>
                  </div>
                </div> */}

                      <div className="row justify-content-end">

                        <div className="col-sm-6 mt-1">
                          {/* <p className="heading-popup">Set Key Specifications</p> */}
                        </div>

                        <div className="col-sm-6 mt-1 d-flex justify-content-end">
                          <div style={{ marginRight: "10px" }}>
                            <button
                              // style={{ padding: "0 5px" }}
                              // onClick={onSubmitRequestApi}
                              onClick={() => handleDeleteAllField("Key")}
                              class="btn btn-danger btn-sm"
                              disabled={(undefined == customerName || customerName == "") ? true : (undefined == docID || docID == "") ? true : keySpecData.length == 0 ? true : false}
                            >
                              Delete All
                            </button>
                          </div>
                          <div style={{ marginRight: "10px" }}>
                            <button
                              // style={{ padding: "0 5px" }}
                              // onClick={onSubmitRequestApi}
                              onClick={handleAddField}
                              class="btn btn-primary btn-sm"
                              disabled={(undefined == customerName || customerName == "") ? true : (undefined == docID || docID == "") ? true : false}
                            // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                            >
                              {/* <BsPlus />  */}
                              Add
                            </button>
                          </div>
                        </div>
                      </div>

                      <div
                        ref={keyContainerRef}
                        className="row mt-2"
                        style={{
                          // height: keySpecData.length > 2 ? "9rem" : "",
                          height: keySpecData.length > 6 ? "18rem" : "",
                          overflowY: "auto", overflowX: "hidden"
                        }}
                      >
                        {keySpecData.map((obj, index) => (
                          <div key={index} className="row mt-2">
                            <div className="col-md-4 mt-2">
                              {/* <SelectInputWithAdd
                            selectInputValue={obj._documetkeys_id}
                            handleSelectInput={(e) => { handleSelectKey(e, index) }}
                            onNameChange={(e) => onEnterNewItemForKey(e)}
                            optionsList={documentKeysList.filter((obj) => obj.type == "key_wise")}
                            newItem={newKeyForKey}
                            onAddItem={(e) => handleAddNewItemForKey(e)}
                            placeholder="Keys"
                          /> */}
                              <SelectInputAntd
                                value={obj._documetkeys_id}
                                onChange={(e) => handleSelectKey(e, index)}
                                optionsList={documentKeysList.filter((obj) => obj.type == "key_wise")}
                                placeholder="Keys"
                              />

                            </div>

                            <div className="col-md-2 mt-2">

                              <SelectInputAntd
                                value={obj._parameter_id}
                                onChange={(e) => handleSelectParameters(e, index)}
                                optionsList={parameterRulesList}
                                placeholder="Parameter"
                              />
                            </div>

                            <div className="col-md-3 mt-2">
                              <InputBoxAntd
                                name="newValueNo"
                                value={obj._paramvalue}
                                onChange={(e) => handleKeyParamValue(e, index)}
                                placeholder={obj._parameter_id == 29 ? "Blank" : "Values"}
                                disabled={obj._parameter_id == 12 || obj._parameter_id == 29 ? true : false}
                                onClick={() => handleInputClick(index, false, obj._paramvalue, obj._parameter_id)}
                              />
                            </div>
                            <div className="col-md-2 mt-2">
                              <InputBoxAntd
                                name="seqNo"
                                value={obj._seq_no}
                                onChange={(e) => handleSeqNo(e, index, "keySpec")}
                                placeholder="Sequence No"
                                disabled={obj._parameter_id == 12 ? true : false}
                              // onClick={() => handleInputClick(index, false, obj._paramvalue)}
                              />
                            </div>

                            <div className="col-md-1 d-flex justify-content-end mt-2">
                              <div
                                style={{ marginRight: "10px" }}
                              >
                                <button
                                  // style={{ padding: "0 5px" }}
                                  onClick={() => handleDeleteField(index)}
                                  class="btn btn-danger btn-sm"
                                // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                                >
                                  <BsFillTrashFill />
                                </button>
                              </div>
                            </div>

                          </div>
                        ))}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>


              </div>
            }
          </div>


          {/* key spec end */}

          {/* header/details end */}

          {/* <div className="row mb-3 ">
              <div className="col d-flex justify-content-center gap-3 mt-3">
                <button
                  disabled={(docID !== "") && (ruleName !== "") && (ruleDescription !== "") ? false : true }
                  type="submit"
                  onClick={(e) => onSubmitRequestApi(e)}
                  class="float-start btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </div> */}

          <div className="row mb-3 ">
            {/* <div className="col d-flex justify-content-center gap-3 mt-3">

              <button
                disabled={(docID !== "") && (customerName !== "") && (ruleName !== "") && (ruleDescription !== "") ? false : true}
                // type="submit"
                onClick={handleAfterNewRule}
                class="float-start btn btn-primary"
              >
                Next
              </button>
            </div> */}


            {(radioSelection == 1 || radioSelection == 3) &&
              <div className="col d-flex justify-content-center gap-3 mt-5">

                {/* <button
                       // style={{ padding: "0 5px" }}
                       onClick={onSubmitRequestApi}
                       class="btn btn-danger btn-sm"
                       disabled={radioSelection == 1 && customRule !== ""}
                     >
                       Submit
                     </button> */}

                <BasicButton
                  text="Submit"
                  onClick={onSubmitRequestApi}
                // disabled={customRule == "" ? true : false}
                />
              </div>
            }


            {radioSelection == 2 &&
              <div className="col d-flex justify-content-center gap-3 mt-5">

                {/* <button
                  // style={{ padding: "0 5px" }}
                  onClick={onSubmitRequestApi}
                  class="btn btn-danger btn-sm"
                  // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                  disabled={keySpecData.length > 0 ? false : true}
                // disabled={keySpecData.some((obj) => (obj._documetkeys_id === "" || obj._parameter_id === "" || obj._paramvalue === "")) ? true : false}
                >
                  Submit
                </button> */}

                <BasicButton
                  text="Submit"
                  onClick={onSubmitRequestApi}
                  disabled={(docSpecData.length > 0 || keySpecData.length > 0) ? false : false}
                />
              </div>
            }

            {/* </div> */}
          </div>
        </Modal >

        {/* enter document spec */}

        < Modal
          className="p-2"
          // zIndex={910}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          // width={750}
          style={{ top: 20, minWidth: 900, maxWidth: 1200 }
          }
          footer={null}
          maskClosable={false}
        >
          {/* <div className="container">
            <div className="row">
              <div className="col text-center mb-3">
                <h6>
                  <b>Set Document Rules</b>
                </h6>
              </div>
            </div>

            <div className="">
              <select
                name="_parameter_id"
                onChange={(e) => handleParameterValue(e)}
                class="form-control"
              >
                {
                  apiDetailState.map((val) =>
                    // const { _parameter_id, _paramname } = val;
                    <option value={val._parameter_id}>{val._paramname}</option>
                  )
                }
              </select>

              <div className="mt-3 mb-3">
                <input
                  type="text"
                  name="_paramvalue_doc"
                  // value={inputData._paramvalue_doc}
                  value={paramDocValue}
                  // onChange={(e) => handleInput(e)}
                  onChange={(e) => handleparamDocValue(e)}
                  class="form-control"
                  placeholder="Value"
                ></input>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  // type="submit"
                  disabled={(parameterValue !== "") && (paramDocValue !== "") ? false : true}
                  onClick={handleAfterDocSpec}
                  class="float-start btn btn-primary"
                >
                  Next
                </button>
              </div>
            </div>
          </div> */}


          < div className="container" >
            <div className="row">
              <div className="col text-center mb-3">
                {/* <h6>
                  <b>Set Document Header</b>
                </h6> */}
                <p className="heading-popup">Set Document Header</p>
              </div>
            </div>

            <div className="row justify-content-end">
              <div className="col-sm-1 mt-2">
                <div style={{ marginRight: "10px" }}>
                  <button
                    // style={{ padding: "0 5px" }}
                    // onClick={onSubmitRequestApi}
                    onClick={handleAddDocField}
                    class="btn btn-primary btn-sm"
                  // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                  >
                    {/* <BsPlus />  */}
                    Add
                  </button>

                </div>
              </div>

              <div className="col-sm-1 mt-2 justify-content-end">
                <div style={{ marginRight: "10px" }}>
                  <button
                    // style={{ padding: "0 5px" }}
                    // onClick={onSubmitRequestApi}
                    onClick={handleAfterDocSpec}
                    class="btn btn-danger btn-sm"
                    // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                    disabled={docSpecData.length > 0 ? false : true}
                  // disabled={keySpecData.some((obj) => (obj._documetkeys_id === "" || obj._parameter_id === "" || obj._paramvalue === "")) ? true : false}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-3"
              style={{ height: "15rem", overflowY: "auto", overflowX: "hidden" }}
            >
              {docSpecData.map((obj, index) => (
                <div key={index} className="row mt-2">
                  <div className="col-md-4 mt-2">

                    {/* <SelectInputWithAdd
                      selectInputValue={obj._documetkeys_id}
                      handleSelectInput={(e) => { handleSelectDoc(e, index) }}
                      onNameChange={(e) => onEnterNewItemForDoc(e)}
                      optionsList={documentKeysList.filter((obj) => obj.type == "document_wise")}
                      newItem={newKeyForDoc}
                      onAddItem={(e) => handleAddNewItemForDoc(e)}
                      placeholder="Keys"
                    /> */}

                    <SelectInputAntd
                      value={obj._documetkeys_id}
                      onChange={(e) => handleSelectDoc(e, index)}
                      optionsList={documentKeysList.filter((obj) => obj.type == "document_wise")}
                      placeholder="Keys"
                    />

                    {/* <select
                      style={{ height: "28px" }}
                      value={obj._documetkeys_id}
                      onChange={(e) => { handleSelectDoc(e, index) }}

                    >
                      <option value={""} disabled selected hidden>{"Items"}</option>
                      {documentKeysList.map((val, inx) => {
                        if(val._key_type == "document_wise"){
                          const { _documetkeys_id, _key } = val;
                        return <option key={inx} value={_documetkeys_id}>{_key}</option>;
                        }
                        
                      })}
                    </select> */}
                  </div>

                  <div className="col-md-2 mt-2">

                    <SelectInputAntd
                      value={obj._parameter_id}
                      onChange={(e) => handleSelectDocParameters(e, index)}
                      optionsList={parameterRulesList}
                      placeholder="Parameter"
                    />

                    {/* <select
                      style={{ height: "28px", width: "100%" }}
                      value={obj._parameter_id}
                      onChange={(e) => { handleSelectDocParameters(e, index) }}

                    >
                      <option value={""} disabled selected hidden>{"Parameters"}</option>
                      {parameterRulesList.map((val, inx) => {
                        const { _parameter_id, _paramname } = val;
                        return <option key={inx} value={_parameter_id}>{_paramname}</option>;
                      })}
                    </select> */}
                  </div>

                  <div className="col-md-3 mt-2">

                    <InputBoxAntd
                      name="newValueNo"
                      value={obj._paramvalue}
                      onChange={(e) => handleDocParamValue(e, index)}
                      placeholder="Values"
                      onClick={() => handleInputClick(index, true, obj._paramvalue, obj._parameter_id)}
                    />

                    {/* <input
                      style={{ height: "28px" }}
                      type="text"
                      name="newValueNo"
                      className="w-50"
                      value={obj._paramvalue}
                      onChange={(e) => { handleDocParamValue(e, index) }}

                      class="form-control"
                      placeholder="value"

                    /> */}
                  </div>
                  <div className="col-md-2 mt-2">
                    <InputBoxAntd
                      name="seq"
                      value={obj._seq_no}
                      onChange={(e) => handleSeqNo(e, index, "docSpec")}
                      placeholder="Sequence No"
                      autocomplete={'off'}
                    // onClick={() => handleInputClick(index, false, obj._paramvalue)}
                    />
                  </div>

                  <div className="col-md-1 d-flex justify-content-end mt-2">
                    <div
                    // style={{ marginRight: "10px" }}
                    >
                      <button
                        // style={{ padding: "0 5px" }}
                        onClick={() => handleDeleteDocField(index)}
                        class="btn btn-danger btn-sm"
                      // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                      >
                        <BsFillTrashFill />
                      </button>
                    </div>
                  </div>

                </div>
              ))}
            </div>

          </div >
        </Modal >



        {/* enter key spec */}

        {/* <div
          style={{ zIndex: "930 !important" }}
        > */}
        <Modal
          className="p-2"
          // zIndex= {920}
          open={newKeysModal}
          style={{ top: 20, minWidth: 900, maxWidth: 1200 }}
          // open={isSetRuleModalOpen}
          onCancel={newRuleModalCancel}
          footer={null}
          maskClosable={false}
        >
          <div className="container">
            <div className="row">
              <div className="col text-center mb-3">
                {/* <h6>
                  <b>Set Key Specification</b>
                </h6> */}
                <p className="heading-popup">Set Key Specification</p>
              </div>
            </div>

            <div className="row justify-content-end">

              <div className="col-sm-1 mt-2">
                <div style={{ marginRight: "10px" }}>
                  <button
                    // style={{ padding: "0 5px" }}
                    // onClick={onSubmitRequestApi}
                    onClick={handleAddField}
                    class="btn btn-primary btn-sm"
                  // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                  >
                    {/* <BsPlus />  */}
                    Add
                  </button>

                </div>
              </div>

              <div className="col-sm-1 mt-2  justify-content-end">
                <div style={{ marginRight: "10px" }}>
                  <button
                    // style={{ padding: "0 5px" }}
                    // onClick={onSubmitRequestApi}
                    class="btn btn-danger btn-sm"
                    // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                    disabled={(docSpecData.length > 0 && keySpecData.length > 0) ? false : true}
                  // disabled={docSpecData.length > 0 ? false : keySpecData.length > 0 ? false : true}
                  // disabled={keySpecData.some((obj) => (obj._documetkeys_id === "" || obj._parameter_id === "" || obj._paramvalue === "")) ? true : false}
                  >
                    Submits
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-3"
              style={{ height: "15rem", overflowY: "auto", overflowX: "hidden" }}
            >
              {keySpecData.map((obj, index) => (
                <div key={index} className="row mt-2">
                  <div className="col-md-4 mt-2">
                    {/* <SelectInputWithAdd
                      selectInputValue={obj._documetkeys_id}
                      handleSelectInput={(e) => { handleSelectKey(e, index) }}
                      onNameChange={(e) => onEnterNewItemForKey(e)}
                      optionsList={documentKeysList.filter((obj) => obj.type == "key_wise")}
                      newItem={newKeyForKey}
                      onAddItem={(e) => handleAddNewItemForKey(e)}
                      placeholder="Keys"
                    /> */}
                    <SelectInputAntd
                      value={obj._documetkeys_id}
                      onChange={(e) => handleSelectKey(e, index)}
                      optionsList={documentKeysList.filter((obj) => obj.type == "key_wise")}
                      placeholder="Keys"
                    />
                    {/* <select
                      style={{ height: "28px" }}
                      // id={obj._ruleskeys_id}
                      // value={newKeys}
                      // onChange={(e) => handleNewKeys(e)}
                      value={obj._documetkeys_id}
                      onChange={(e) => { handleSelectKey(e, index) }}

                    >
                      <option value={""} disabled selected hidden>{"keys"}</option>
                      {documentKeysList.map((val, inx) => {
                        if(val._key_type == "key_wise"){
                          const { _documetkeys_id, _key } = val;
                          return <option key={inx} value={_documetkeys_id}>{_key}</option>;
                        }
                        
                      })}
                    </select> */}
                  </div>

                  <div className="col-md-2 mt-2">

                    <SelectInputAntd
                      value={obj._parameter_id}
                      onChange={(e) => handleSelectParameters(e, index)}
                      optionsList={parameterRulesList}
                      placeholder="Parameter"
                    />

                    {/* <select
                      style={{ height: "28px", width: "100%" }}
                      // id={obj._ruleskeys_id}
                      // value={newParamNames}
                      // onChange={(e) => handleNewParamNames(e)}
                      value={obj._parameter_id}
                      onChange={(e) => { handleSelectParameters(e, index) }}

                    >
                      <option value={""} disabled selected hidden>{"Parameters"}</option>
                      {parameterRulesList.map((val, inx) => {
                        const { _parameter_id, _paramname } = val;
                        return <option key={inx} value={_parameter_id}>{_paramname}</option>;
                      })}
                    </select> */}
                  </div>

                  <div className="col-md-3 mt-2">

                    <InputBoxAntd
                      name="newValueNo"
                      value={obj._paramvalue}
                      onChange={(e) => handleKeyParamValue(e, index)}
                      placeholder="Values"
                      autocomplete={'off'}
                      onClick={() => handleInputClick(index, false, obj._paramvalue, obj._parameter_id)}
                    />
                  </div>

                  <div className="col-md-2 mt-2">
                    <InputBoxAntd
                      name="seq"
                      value={obj._seq_no}
                      onChange={(e) => handleSeqNo(e, index, "keySpec")}
                      placeholder="Sequence No"
                      autocomplete={'off'}
                    // onClick={() => handleInputClick(index, false, obj._paramvalue)}
                    />
                  </div>

                  {/* <div className="col-sm-2 mt-2">
                <div style={{ marginRight: "10px" }}>
                  <button
                    // style={{ padding: "0 5px" }}
                    // onClick={onSubmitRequestApi}
                    onClick={handleAddField}
                    class="btn btn-primary btn-sm"
                  // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                  >
                    <BsPlus />
                  </button>

                </div>
              </div> */}

                  <div className="col-md-1 d-flex justify-content-end mt-2">
                    <div
                      style={{ marginRight: "10px" }}
                    >
                      <button
                        // style={{ padding: "0 5px" }}
                        onClick={() => handleDeleteField(index)}
                        class="btn btn-danger btn-sm"
                      // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                      >
                        <BsFillTrashFill />
                      </button>
                    </div>
                  </div>

                </div>
              ))}
            </div>
          </div>

        </Modal>
        {/* </div> */}

        {/* expression modal changes */}
        <Modal
          className="p-2"
          // zIndex={900}
          open={expressionModal}
          style={{ top: 60, minWidth: 500, maxWidth: 700 }}
          // open={isSetRuleModalOpen}
          onCancel={handleExpCancel}
          footer={null}
          maskClosable={false}
        >
          <div className="container">
            <div className="row">
              <div className="col text-center mb-2">
                <p className="heading-popup">{isSubString ? "Substring" : isConcat ? "Concatenation" : isMap ? "Mapping" : isTrim ? "Trim" : "Convert to Integer"}</p>
              </div>
            </div>
            {/* <div className="col-md-12">
              <SelectInput
                margin="0"
                selectLabel="*Expression"
                name="Expression"
                value={expression}
                onChange={(e) => handleExpression(e)}
                optionsList={expressionList}
              />
            </div> */}
            {isSubString && < div className="mt-3">
              <div className="px-2">
                <SelectInputAntd
                  value={selectedKeyFrom}
                  onChange={(e) => handleSelectedKeyFrom(e)}
                  optionsList={filterDocumentKeyList}
                  placeholder="Select key to substring"
                />
              </div>
              <div className="d-flex col-md-12 mt-3 ">
                <div className="col-md-6 px-2">
                  <InputBoxAntd
                    name="fromvalue"
                    value={fromValue}
                    onChange={e => setFromValue(e.target.value)}
                    placeholder="Index from"
                    autocomplete={"off"}
                  />
                </div>
                <div className="col-md-6 px-2 ">
                  <InputBoxAntd
                    name="tovalue"
                    value={toValue}
                    onChange={e => setToValue(e.target.value)}
                    placeholder="Index to"
                    autocomplete={"off"}
                  />
                </div>
                {/* <div>
                  <button
                    // style={{ padding: "0 5px" }}
                    // onClick={onSubmitRequestApi}
                    onClick={handleAddExp}
                    class="btn btn-primary btn-sm"
                  // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                  >
                    Add
                  </button>
                </div> */}
              </div>
              {/* <div className="mt-3">
                <div className="px-2">
                  <InputBoxAntd
                    value={assignValue}
                    onChange={(e) => setAssignValue(e.target.value)}
                    // optionsList={documentKeysList.filter((obj) => obj.type == "key_wise")}
                    placeholder="Assign to"
                  />
                </div>
              </div> */}
            </div>}
            {isConcat &&
              <div>
                <div>
                  <label>
                    Select keys to concat
                  </label>
                  <SelectInputAntd
                    value={selectedConcattKey}
                    // onChange={(e) => handleSelectedKeyFrom(e)}
                    onChange={(e) => handleConcatSelected(e)}
                    optionsList={filterDocumentKeyList}
                    // placeholder="Select first key to concat *"
                    mode={"multiple"}
                  />
                </div>
                <div>
                  <CheckboxComp
                    label="Add Constant"
                    isChecked={isAddConst}
                    onChange={handleIsConstant}
                  />
                </div>
                {/* <div className="mt-3">
                  <SelectInputAntd
                    value={selectedKeyTo}
                    onChange={(e) => handleSelectedKeyTo(e)}
                    optionsList={filterDocumentKeyList.filter((obj) => isDocType ? obj.type == "document_wise" : obj.type == "key_wise")}
                    placeholder="Select second key to concat"
                  />
                </div> */}
                {/* <div className="d-flex col-md-12 justify-content-center">
                  or
                </div> */}
                {isAddConst &&
                  <div>
                    <div className="">
                      <InputBoxAntd
                        value={assignValue}
                        onChange={(e) => setAssignValue(e.target.value)}
                        // optionsList={documentKeysList.filter((obj) => obj.type == "key_wise")}
                        placeholder="value"
                      />
                    </div>
                    <div>
                      <RadioButton
                        optionsList={concatRadioOption}
                        selectedValue={concatRadioSelection}
                        handleChange={(event) => onConcatRadioChange(event)}
                      />
                    </div>
                  </div>
                }
              </div>
            }
            {isMap &&
              <div>
                {/* <div>
                  <SelectInputAntd
                    value={selectedKeyFrom}
                    onChange={(e) => handleSelectedKeyFrom(e)}
                    optionsList={documentKeysList.filter((obj) => obj.type == "key_wise")}
                    placeholder="Select key*"
                  />
                </div> */}
                <div className="mt-3">
                  <SelectInputAntd
                    value={selectedKeyTo}
                    onChange={(e) => handleSelectedKeyTo(e)}
                    optionsList={sourceKeyLists}
                    placeholder="Select key to map"
                  />
                </div>
                <div className="d-flex col-md-12 justify-content-center">
                  or
                </div>
                <div className="">
                  <InputBoxAntd
                    value={assignValue}
                    onChange={(e) => setAssignValue(e.target.value)}
                    // optionsList={documentKeysList.filter((obj) => obj.type == "key_wise")}
                    placeholder="value"
                  />
                </div>
              </div>
            }

            {isTrim &&
              <div>
                {/* <div>
                  <SelectInputAntd
                    value={selectedKeyFrom}
                    onChange={(e) => handleSelectedKeyFrom(e)}
                    optionsList={documentKeysList.filter((obj) => obj.type == "key_wise")}
                    placeholder="Select key*"
                  />
                </div> */}
                <div className="mt-3">
                  <SelectInputAntd
                    value={selectedKeyTo}
                    onChange={(e) => handleSelectedKeyTo(e)}
                    optionsList={sourceKeyLists}
                    placeholder="Select key to trim"
                  />
                </div>
                {/* <div className="d-flex col-md-12 justify-content-center">
                  or
                </div> */}
                {/* <div className="mt-2">
                  <InputBoxAntd
                    value={assignValue}
                    onChange={(e) => setAssignValue(e.target.value)}
                    // optionsList={documentKeysList.filter((obj) => obj.type == "key_wise")}
                    placeholder="value"
                  />
                </div> */}
              </div>
            }

            {isInt &&
              <div>
                {/* <div>
                  <SelectInputAntd
                    value={selectedKeyFrom}
                    onChange={(e) => handleSelectedKeyFrom(e)}
                    optionsList={documentKeysList.filter((obj) => obj.type == "key_wise")}
                    placeholder="Select key*"
                  />
                </div> */}
                <div className="mt-3">
                  <SelectInputAntd
                    value={selectedKeyTo}
                    onChange={(e) => handleSelectedKeyTo(e)}
                    optionsList={sourceKeyLists}
                    placeholder="Select key to change"
                  />
                </div>
                <div className="mt-1" style={{ color: "red" }}>
                  Conversion to integer is only applicable to numerical data not alphanumeric data *
                </div>
                {/* <div className="d-flex col-md-12 justify-content-center">
                  or
                </div> */}
                {/* <div className="mt-2">
                  <InputBoxAntd
                    value={assignValue}
                    onChange={(e) => setAssignValue(e.target.value)}
                    // optionsList={documentKeysList.filter((obj) => obj.type == "key_wise")}
                    placeholder="value"
                  />
                </div> */}
              </div>
            }


            <div className="d-flex justify-content-center mt-3">
              <button
                // style={{ padding: "0 5px" }}
                onClick={onSubmitExpression}
                class="btn btn-danger btn-sm"
              >
                Submit
              </button>
            </div>

          </div>

        </Modal >

        <Toaster position="top-center" reverseOrder={false} />
      </div >
    </>
  );
}

export default AdminAddNewRule;
