import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { documentApproval } from "../../../Services/AdminServices";
import Loader from "../../../utils/Loader/Loader";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";

const ChangeDocStatus = ({ data, handleOk, input, setInput, isModalOpen, dataRefresh }) => {
    console.log("datacheck", isModalOpen, data);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    useEffect(() => {
        console.log("asd==>", isModalOpen);
        setInput({ ...input });
        if (dataRefresh) {
            setInput({
                _reason: "",
                _spec_status_id: ""
            })
        }

    }, [isModalOpen])

    const handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        setInput({ ...input, [name]: value });
    };

    const updateUserStatus = async (e) => {
        setLoading(true)
        e.preventDefault();

        let adminEmailValue = localStorage.getItem("Logindata");
        const { _reason, _spec_status_id } = input;
        let sendData = {
            _email: adminEmailValue,
            _docdetails_id: data._docdetails_id,
            _reason: _reason,
            _spec_status_id: _spec_status_id,
        };
        try {
            const res = await documentApproval(sendData)
            console.log("documentApproval", res.data.success)
            if (res.data.success == true) {
                toast.success(res.data.msg, {
                    position: "top-center",
                    autoClose: 5000
                });

            }
            else {
                toast.error(res.data.data)
            }
        }
        catch (err) {
            setError(err)
        }
        handleOk()
        setLoading(false)
    }

    return (
        <>
            <Loader loading={loading} />
            <Toaster position="top-center" reverseOrder={false} />
            <ErrorCatch err={error}/>
                <div className="conatiner">
                    <form>
                        <h6 className="text-center">
                            <b>Update Document Status</b>
                        </h6>

                        <div className="row">
                            <div className="mb-3 mt-3">
                                <select
                                    class="form-select"
                                    name="_spec_status_id"
                                    value={input._spec_status_id}
                                    onChange={(event) => handleChange(event)}
                                >
                                    <option value="" selected disabled hidden>{"select"}</option>

                                    <option
                                        className="text-success"
                                        value={1}
                                        selected={input._spec_status_id === "Approve" ? true : false}
                                    >
                                        <text className="text-success"> Approve</text>
                                    </option>
                                    <option
                                        className="text-danger"
                                        value={2}
                                        selected={input._spec_status_id === "Deactive" ? true : false}
                                    >
                                        <text className="text-danger"> Rejected</text>
                                    </option>

                                </select>
                            </div>

                            <div className="mb-3 mt-3">
                                <input
                                    type="text-area"
                                    placeholder="*Reason"
                                    class="form-control"
                                    name="_reason"
                                    value={input._reason}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="text-center">
                                <button
                                    // type="button"
                                    type="submit"
                                    class="btn btn-primary"
                                    disabled={(input._spec_status_id !== "") && (input._reason !== "") ? false : true}
                                    onClick={(e) => updateUserStatus(e)}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
            </>
            );
};

            export default ChangeDocStatus;
