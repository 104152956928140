import React, { createRef, useCallback, useEffect, useRef, useState } from "react";
import RadioButton from "../../../Reusable_Components/RadioButton";
import { useNavigate } from "react-router-dom";
import _ from "lodash"
import toast, { Toaster } from "react-hot-toast";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import axios from "axios";
import env from "../../../../Services/Environment";
import { getResponseFormat, getRules, createNewRules, createOneRule, updateRule, getAllParameterRules, getDocumentKeys, getDocumentName, getActiveCustomer, getUserRules, ViewRules, deleteKeyRules, editPreviousKeyRules, addNewItemsForEdit, getCustomRules, getMaxDocSpecID, getMaxRuleKeysID, getDefaultRules, getSourceKeys } from "../../../../Services/AdminServices";
import { Modal, Button } from "antd";
import { BsPlus, BsFillTrashFill } from 'react-icons/bs';
import Loader from "../../../../utils/Loader/Loader";
import { useStyleRegister } from "antd/es/theme/internal";
import AppliedRulesList from "../../CustomerAdmin/Document/AppliedRulesList";
import SelectInput from "../../../Reusable_Components/SelectInput";
import InputBox from "../../../Reusable_Components/InputBox";


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { messages } from "../../../Messages";
import CheckboxComp from "../../../Reusable_Components/CheckBox";
import ErrorCatch from "../../../Reusable_Components/ErrorCatch";
import warning from "../../../../Assets/Images/warning.png"
import { useSelector } from "react-redux";
import CryptoJS from 'crypto-js';


function AdminEditNewRule({ isButtons, typeOfRule, ruleID, compID, theDocID, createRulesList, handleEditRuleOk, isEditNewRuleModal, handleEditRuleCancel }) {
    const navigate = useNavigate();
    const userDetails = useSelector((state) => state)
    const scrollToRef = useRef();
    const [error, setError] = useState()
    // const isRule = undefined !== typeOfRule ? typeOfRule : ""
    const [isRule, setIsRule] = useState(undefined !== typeOfRule ? typeOfRule.trim() : "")
    console.log("editparamseditrule", isRule, typeOfRule)
    const [customerList, setCustomerList] = useState([])
    const [apiFormatState2, setApiFormatState2] = useState([]);
    const [responseFormat, setResponseFormat] = useState([])
    const [ruleFormat, setRuleFormat] = useState([])
    const [customerPlaceHolder, setCustomerPlaceholder] = useState({
        name: "Select Customer",
        value: ""
    })
    const [docType, setDocType] = useState({
        name: "Select a Document Type",
        value: ""
    })

    const [formatType, setFormatType] = useState({
        name: "Select a Format Type",
        value: ""
    })

    const initialValues = {
        email: localStorage.getItem("Logindata"),
        // _document_id: "",
        // _documetkeys_id: "",

    };

    // const [keyType, setKeyType] = useState({
    //   name: "Key",
    //   value: ""
    // })

    const [ruleType, setRuleType] = useState({
        name: "Select a Rule Type",
        value: ""
    })

    const [keySpecData, setKeySpecData] = useState([])
    const [keySpecField, setKeySpecField] = useState({
        // "operation": "KeyRule",
        "_paramvalue": "",
        "_parameter_id": "",
        "_documetkeys_id": "",
    })

    const [docSpecData, setDocSpecData] = useState([])
    const [docSpecField, setDocSpecField] = useState({
        // "operation": "KeyRule",
        "_paramvalue": "",
        "_parameter_id": "",
        "_documetkeys_id": "",
    })



    const [keySpecFieldForEdit, setKeySpecFieldForEdit] = useState({
        // "documentKeys": "",
        // "parameterRules": "",
        // "isEnabled": true,
        // "ruleskeys_id": "",
        // "paramvalue": "",
        // "parameter_id": "",
        // "documetkeys_id": "",
    })
    const [docSpecFieldForEdit, setDocSpecFieldForEdit] = useState({
        // "documentKeys": "",
        // "parameterRules": "",
        // "isEnabled": true,
        // "docspec_id": "",
        // "paramvalue": "",
        // "parameter_id": "",
        // "documetkeys_id": "",
    })

    const [isError, setIsError] = useState(false)
    const [parameterFilesList, setParameterFilesList] = useState([])
    const [isDefaultRule, setIsDefaultRule] = useState(false)
    const [defaultRule, setDefaultRule] = useState({
        ruleId: "", ruleName: "", ruleDescription: "",
    })
    const [defaultCheckModal, setDefaultCheckModal] = useState(false)
    const [defaultRulePayload, setDefaultRulePayload] = useState()
    const [isLambda, setIsLambda] = useState(false)
    const [editRuleMasterData, setEditRuleMasterData] = useState([])
    const [editRuleDetails, setEditRuleDetails] = useState()
    const [editRuleDoc, setEditRuleDoc] = useState()
    const [editRuleKey, setEditRuleKey] = useState([])

    const [dupRowData, setDupRowData] = useState([])
    const [isUpdateOpen, setIsUpdateOpen] = useState(false)
    const [radioSelection, setRadioSelection] = useState()
    const [radioOptions, setRadioOptions] = useState([
        {
            label: "Document",
            value: 1,
            status: true
        },
        {
            label: "Header/Details",
            value: 2,
            status: false
        },
        // {
        //     label: "SP",
        //     value: 3,
        //     status: false
        // }
    ])


    // AppliedRulesList start

    const [rowData, setRowData] = useState([])
    const [oldRecord, setOldRecord] = useState([])
    const [previousRowData, setPreviousRowData] = useState([])
    const [isEditable, setIsEditable] = useState(false)
    const [keyRulesList, setKeyRulesList] = useState([])
    const [paramRulesList, setParamRulesList] = useState([])
    // const [filterDocumentKeyList, setFilterDocumentKeyList] = useState([])
    var isEnabled = []

    const [rowDataForDoc, setRowDataForDoc] = useState([])
    const [oldRecordForDoc, setOldRecordForDoc] = useState([])
    const [docRulesList, setDocRulesList] = useState([])
    var isEnabledForDoc = []


    const [apiDetailState, setApiDetailState] = useState([]);

    const [hideSelect, setHideSelect] = useState(true);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newRuleData, setNewRuleData] = useState({})
    const [parameterValue, setParameterValue] = useState("")
    const [paramDocValue, setParamDocValue] = useState("")
    const [parameter, setParameter] = useState({
        name: "Select a Parameter Type",
        value: ""
    })

    const [newKeysModal, setNewKeysModal] = useState(false)
    const [newKeys, setNewKeys] = useState()
    const [documentKeysList, setDocumentKeysList] = useState([])
    const [parameterRulesList, setParameterRulesList] = useState([])
    const [newValueNo, setNewValueNo] = useState()
    const [isSetRuleModalOpen, setIsSetRuleModalOpen] = useState(false)

    const [docKeys, setDocKeys] = useState("")
    const [paramRule, setParamRule] = useState("")

    const userDetailsLog = userDetails.auth.userDetails


    // const ref = createRef<HTMLDivElement>
    // const scrollToBottomOfList = useCallback(()=>{
    //     ref.current.scrollIntoView({
    //         behavior: "smooth",
    //         block: "end"
    //     })
    // },[ref])

    // const handleScroll = useCallback(()=>{
    //     scrollToBottomOfList()
    // },[])

    const scrollHere = useRef()

    const handleScroll = () => {
        // scrollToRef.current.scrollIntoView()
        // const element = document.getElementById("scrollBox");
        // element.scrollIntoView({ behavior: "smooth", block: "end" });
        scrollHere.current?.scrollIntoView({
            behavior: "smooth",
            block: "top",
            // inline: "nearest"
        })
    }

    const [isKeyUpdate, setIsKeyUpdate] = useState(false)
    const containerRef = useRef(null)
    const docContainerRef = useRef(null)
    const keyContainerRef = useRef(null)

    // uam role object start
    const appsubmodulename = "Services"
    const appfunctionalityname = "Rules"
    const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
    const [appObjects, setAppObjects] = useState(
        {
            "Delete All": false,
            "Add": false,
            "Delete": false,
            "Edit spec": false,
            "Submit": false,
            "Cancel": false
        }
    )
    console.log("userDetails", userDetails)

    const updateAppObjects = (arr) => {
        let objectsArray = []
        arr.map((obj) => {
            console.log("updateButtons", obj)
            if (obj.objecttypename == "button") {
                objectsArray.push(obj)
            }
        })
        const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
            acc[appobjectname] = true;
            return acc;
        }, {});
        setAppObjects({ ...appObjects, ...finalObject })
        console.log("objectsArray", objectsArray)
        console.log("finalObject", finalObject)
    }

    useEffect(() => {
        console.log("appObjects", appObjects)
    }, [appObjects])

    useEffect(() => {
        console.log("userRoleObjects", userRoleObjects)
        const filteredList = []
        userRoleObjects.filter((obj) => {
            if (obj.appsubmodulename == appsubmodulename && obj.appfunctionalityname == appfunctionalityname) {
                filteredList.push(obj)
            }
        })

        console.log("filteredList", filteredList)
        updateAppObjects(filteredList)

    }, [isEditNewRuleModal])

    // uam role object end


    const handleScrollToLastChild = () => {
        if (containerRef.current) {
            const lastChild = containerRef.current.lastChild
            lastChild.scrollIntoView({ behavior: "smooth" })
        }
    }

    const scrollToBottom = () => {
        let element = containerRef.current;
        if (element) {
            element.scrollTop = element.scrollHeight;
        }
        setIsKeyUpdate(false)
    };

    const scrollToBottomDoc = () => {
        let element = docContainerRef.current;
        if (element) {
            element.scrollTop = element.scrollHeight;
        }
        setIsKeyUpdate(false)
    };

    const scrollToBottomKey = () => {
        let element = keyContainerRef.current;
        if (element) {
            element.scrollTop = element.scrollHeight;
        }
        setIsKeyUpdate(false)
    };

    useEffect(() => {
        if (rowDataForDoc.length > 0) {
            // scrollToBottomDoc()
        }
    }, [rowDataForDoc])

    // useEffect(()=>{
    //     if(isKeyUpdate == true){
    //         handleScrollToLastChild()
    //     }

    // },[isKeyUpdate])

    useEffect(() => {
        if (rowData.length > 0 && isKeyUpdate == true) {
            // handleScroll()
            // handleScrollToLastChild()
            scrollToBottom()
            scrollToBottomKey()
        }

    }, [rowData])

    useEffect(() => {
        console.log("oldrecordmonitor", oldRecord)
    }, [oldRecord])

    const [inputData, setInputData] = useState();
    const [customerName, setCustomername] = useState("")
    const [docID, setDocID] = useState("")
    const [ruleName, setRuleName] = useState("")
    const [ruleDescription, setRuleDescription] = useState("")
    const [IDNo, setIDNo] = useState()
    const [formatID, setFormatID] = useState("")
    const [loading, setLoading] = useState(false)
    // const [salesOrderNo, setSalesOrderNo] = useState()

    const [customRule, setCustomRule] = useState("")
    const [customRuleName, setCustomRuleName] = useState("")
    const [customRuleList, setCustomRuleList] = useState([])
    const [isDeleteAllModal, setIsDeleteAllModal] = useState(false)
    const [isDocType, setIsDocType] = useState(false)




    // useEffect(() => {
    //     if (undefined == customerName || customerName == "") {
    //         let compID = 0
    //         getCustomRulesList(compID)

    //     } else {
    //         // getDocumentKeysList()
    //         getCustomRulesList(customerName)
    //     }

    // }, [customerName])

    const getCustomRulesList = async (compID, docID) => {
        console.log("compID", compID)
        setLoading(true)
        try {
            await getCustomRules(compID, docID).then((res) => {
                console.log("getcustomruleres", res)
                if (res.status == 200) {
                    let data = res.data.data
                    let finalArr = []
                    data.map((obj) => {
                        finalArr.push({
                            name: obj._rule_name,
                            value: obj._rule_id,
                            rulestype: obj.rulestype,
                            customruleid: obj.customruleid
                        })
                    })
                    setCustomRuleList(finalArr)
                }
            })
        }
        catch (error) {
            console.log("getcustomrulereserror", error)
            setError(error)
        }
        setLoading(false)
    }

    const getAppliedKeyRules = () => {
        console.log("editRuleKey", editRuleKey, "previousRowData", previousRowData)
        let finalArr = []
        rowData.map((obj) => {
            obj["documentKeys"] = documentKeysList
            obj["parameterRules"] = parameterRulesList
            obj.isEnabled = true
            finalArr.push(obj)
        })
        finalArr.sort((a, b) => a.seq_no - b.seq_no)
        console.log("finalArr", finalArr)
        setRowData(finalArr)

        let finalArrDoc = []
        rowDataForDoc.map((obj) => {
            obj["documentKeys"] = documentKeysList
            obj["parameterRules"] = parameterRulesList
            obj.isEnabledForDoc = true
            finalArrDoc.push(obj)
        })
        finalArrDoc.sort((a, b) => a.seq_no - b.seq_no)
        console.log("finalArrDoc", finalArrDoc)
        setRowDataForDoc(finalArrDoc)

        let keyListArr = []
        documentKeysList.map((obj) => {
            if (obj._key_type == "key_wise") {
                keyListArr.push({
                    "label": obj._key,
                    "value": obj._documetkeys_id
                })
            }
        })
        console.log("keyListArr", keyListArr)
        setKeyRulesList(keyListArr)

        let docListArr = []
        documentKeysList.map((obj) => {
            if (obj._key_type == "document_wise") {
                docListArr.push({
                    "label": obj._key,
                    "value": obj._documetkeys_id
                })
            }
        })
        console.log("docListArr", docListArr)
        setDocRulesList(docListArr)

        let paramListArr = []
        parameterRulesList.map((obj) => {
            paramListArr.push({
                "label": obj._paramname,
                "value": obj._parameter_id
            })
        })
        setParamRulesList(paramListArr)
    }

    const getPreviousAppliedKeyRules = () => {
        console.log("editRuleKey", editRuleKey, "previousRowData", previousRowData)
        // let finalArr = []
        // previousRowData.map((obj) => {
        //     obj["documentKeys"] = documentKeysList
        //     obj["parameterRules"] = parameterRulesList
        //     obj.isEnabled = true
        //     finalArr.push(obj)
        // })

        // console.log("finalArr", finalArr)
        // setRowData(finalArr)

        let keyListArr = []
        documentKeysList.map((obj) => {
            keyListArr.push({
                "label": obj._key,
                "value": obj._documetkeys_id
            })
        })
        console.log("keyListArr", keyListArr)
        setKeyRulesList(keyListArr)

        let paramListArr = []
        parameterRulesList.map((obj) => {
            paramListArr.push({
                "label": obj._paramname,
                "value": obj._parameter_id
            })
        })
        setParamRulesList(paramListArr)
    }

    const updateDocRules = async (param, followUpId) => {
        console.log("param_updateDocRules", param, followUpId, "rowDataForDoc", rowDataForDoc)
        isEnabledForDoc = rowDataForDoc.map((obj, i) => {
            // let paramKey = rowDataForDoc[0].documentKeys.filter((item) => item._documetkeys_id == param.target.value)
            let paramKey = rowDataForDoc[0].documentKeys.filter((item) => item._documetkeys_id == param)
            console.log("paramKey", paramKey)
            if (obj.docspec_id == followUpId) {
                // obj.key = param.target.label
                obj.key = paramKey[0]._key
                // obj["documetkeys_id"] = param.target.value
                obj["documetkeys_id"] = param
            }
            return obj
        })

        let isEnabledToUpdate = isEnabledForDoc.filter((obj) => {
            if (obj.docspec_id == followUpId) {
                return obj
            }
        })

        console.log("param_followupid", param, followUpId, isEnabledForDoc, isEnabledToUpdate)

        if (isEnabledToUpdate[0].isEnabledForDoc == false) {
            console.log("false", "false is present")
            setRowDataForDoc(isEnabledForDoc)
            return
        }

    }

    const updateKeyRules = async (param, followUpId) => {
        console.log("param_updateKeyRules", param, followUpId, "rowdata", rowData)
        isEnabled = rowData.map((obj, i) => {
            // let paramKey = rowData[0].documentKeys.filter((item) => item._documetkeys_id == param.target.value)
            let paramKey = rowData[0].documentKeys.filter((item) => item._documetkeys_id == param)
            console.log("paramKey", paramKey)
            if (obj.ruleskeys_id == followUpId) {
                // obj.key = param.target.label
                obj.key = paramKey[0]._key
                // obj["documetkeys_id"] = param.target.value
                obj["documetkeys_id"] = param
            }
            return obj
        })

        let isEnabledToUpdate = isEnabled.filter((obj) => {
            if (obj.ruleskeys_id == followUpId) {
                return obj
            }
        })

        console.log("param_followupid", param, followUpId, isEnabled, isEnabledToUpdate)

        if (isEnabledToUpdate[0].isEnabled == false) {
            console.log("false", "false is present")
            setRowData(isEnabled)
            return
        }

    }

    const updateParamRulesForDoc = async (param, followUpId) => {
        console.log("param_updateParamRulesForDoc", param, followUpId, "rowDataForDoc", rowDataForDoc)
        isEnabledForDoc = rowDataForDoc.map((obj, i) => {
            // let paramName = rowDataForDoc[0].parameterRules.filter((item) => item._parameter_id == param.target.value)
            let paramName = rowDataForDoc[0].parameterRules.filter((item) => item._parameter_id == param)
            if (obj.docspec_id == followUpId) {
                // obj.paramname = param.label
                obj.paramname = paramName[0]._paramname
                // obj["parameter_id"] = param.target.value
                obj["parameter_id"] = param

                // obj["paramvalue"] = param == 12 ? "Hidden" : obj["paramvalue"] 
                obj["paramvalue"] = param == 12 ? "Hidden" : param !== 12 && obj["paramvalue"] == "Hidden" ? "" : obj["paramvalue"]
            }
            return obj
        })

        let isEnabledToUpdate = isEnabledForDoc.filter((obj) => {
            if (obj.docspec_id == followUpId) {
                return obj
            }
        })

        console.log("param_followupid", param, followUpId, isEnabledForDoc, isEnabledToUpdate)

        if (isEnabledToUpdate[0].isEnabledForDoc == false) {
            console.log("false", "false is present")
            setRowDataForDoc(isEnabledForDoc)
            return
        }

    }

    const updateParamRules = async (param, followUpId) => {
        console.log("param_updateParamRules", param, followUpId, "rowdata", rowData)
        isEnabled = rowData.map((obj, i) => {
            // let paramName = rowData[0].parameterRules.filter((item) => item._parameter_id == param.target.value)
            let paramName = rowData[0].parameterRules.filter((item) => item._parameter_id == param)
            if (obj.ruleskeys_id == followUpId) {
                // obj.paramname = param.label
                obj.paramname = paramName[0]._paramname
                // obj["parameter_id"] = param.target.value
                obj["parameter_id"] = param

                // obj["paramvalue"] = param == 12 ? "Hidden" : obj["paramvalue"] 
                obj["paramvalue"] = param == 12 ? "Hidden" : param !== 12 && obj["paramvalue"] == "Hidden" ? "" : param == 29 ? "" : obj["paramvalue"]
            }
            return obj
        })

        let isEnabledToUpdate = isEnabled.filter((obj) => {
            if (obj.ruleskeys_id == followUpId) {
                return obj
            }
        })

        console.log("param_followupid", param, followUpId, isEnabled, isEnabledToUpdate)

        if (isEnabledToUpdate[0].isEnabled == false) {
            console.log("false", "false is present")
            setRowData(isEnabled)
            return
        }

    }

    const onInputChangeForDoc = (param, followUpId) => {
        console.log("onInputChangeforDoc", param, followUpId, "rowdataforDoc", rowDataForDoc)
        isEnabledForDoc = rowDataForDoc.map((obj, i) => {
            if (obj.docspec_id == followUpId) {
                // obj.paramvalue = Number(param)
                obj.paramvalue = param
            }
            return obj
        })
        setRowDataForDoc(isEnabledForDoc)
    }

    const handleSeqNoForDoc = (param, followUpId) => {
        console.log("handleSeqNo", param, followUpId, "rowdata", rowData)

        isEnabledForDoc = rowDataForDoc.map((obj, i) => {
            if (obj.docspec_id == followUpId) {
                // obj.paramvalue = Number(param)
                obj.seq_no = param
            }
            return obj
        })
        setRowDataForDoc(isEnabledForDoc)
    }


    const [newKeyForDoc, setNewKeyForDoc] = useState("")
    const [newKeyForKey, setNewKeyForKey] = useState("")

    const onEnterNewItemForDoc = (param, followUpId) => {
        console.log("onEnterNewItemForDoc", param, followUpId, "rowdataforDoc", rowDataForDoc)
        setNewKeyForDoc(param)
    }

    const handleAddNewItemForDoc = async () => {
        if (undefined == newKeyForDoc || newKeyForDoc == "") {
            toast.error("Please add items")
            return
        }
        let sendData = {
            "data": {
                "addDynamicKeys": [
                    {
                        "key": newKeyForDoc,
                        "description": "",
                        "key_type": "document_wise",
                        "comp_id": compID,
                        "docid": theDocID
                    }
                ]
            }
        }

        setLoading(true)
        try {
            let response = await addNewItemsForEdit(sendData)
            console.log("responseaddnewitems", response)
            if (response.status == 201) {
                toast.success(response.statusText)
            }
            else {
                toast.error(response.statusText)
            }
        }
        catch (error) {
            console.log("erroraddnewitems", error)
            // toast.error(error.response.data.msg)
            setError(error)
        }
        setNewKeyForDoc("")
        getDocumentKeysList()
        setLoading(false)
    }

    const onEnterNewItemForKey = (param, followUpId) => {
        console.log("onEnterNewItemForKey", param, followUpId, "rowdataforDoc", rowDataForDoc)
        setNewKeyForKey(param)
    }

    const handleAddNewItemForKey = async () => {
        if (undefined == newKeyForKey || newKeyForKey == "") {
            toast.error("Please add items")
            return
        }
        let sendData = {
            "data": {
                "addDynamicKeys": [
                    {
                        "key": newKeyForKey,
                        "description": "",
                        "key_type": "key_wise",
                        "comp_id": compID,
                        "docid": theDocID
                    }
                ]
            }
        }

        setLoading(true)
        try {
            let response = await addNewItemsForEdit(sendData)
            console.log("responseaddnewitems", response)
            if (response.status == 201) {
                toast.success(response.statusText)
            }
            else {
                toast.error(response.statusText)
            }
        }
        catch (error) {
            console.log("erroraddnewitems", error)
            // toast.error(error.msg)
            setError(error)
        }
        setNewKeyForKey("")
        getDocumentKeysList()
        setLoading(false)
    }



    const onInputChange = (param, followUpId) => {
        console.log("onInputChange", param, followUpId, "rowdata", rowData)
        isEnabled = rowData.map((obj, i) => {
            if (obj.ruleskeys_id == followUpId) {
                // obj.paramvalue = Number(param)
                obj.paramvalue = param
            }
            return obj
        })
        setRowData(isEnabled)
    }

    const handleSeqNo = (param, followUpId) => {
        console.log("handleSeqNo", param, followUpId, "rowdata", rowData)

        isEnabled = rowData.map((obj, i) => {
            if (obj.ruleskeys_id == followUpId) {
                // obj.paramvalue = Number(param)
                obj.seq_no = param
            }
            return obj
        })
        setRowData(isEnabled)
    }

    const onEditClickedForDoc = (param, isEditable) => {

        console.log("onEditClickedForDocparam", param, isEditable, rowDataForDoc)
        if (isEditable) {
            console.log("isEditableonEditClicked", isEditable, dupRowData)

            let emptyData = rowDataForDoc.some((obj) => obj.documetkeys_id == "" || obj.parameter_id == "" || obj.paramvalue == "")
            console.log("emptydataupdaterecord", emptyData)
            // if (emptyData == true) {
            //     toast.error(messages.fillAllFields, {
            //         position: "top-center",
            //         autoClose: 5000,
            //     })
            //     setLoading(false)
            //     return
            // }

            let clonedRowData = _.cloneDeepWith(rowDataForDoc)
            clonedRowData.map((obj) => {
                obj.paramvalue = obj.oldParamsValue
                obj.documetkeys_id = obj.oldDocumentKey
                obj.parameter_id = obj.oldParameterKey
                obj.isEnabledForDoc = true

            })
            console.log("clonedrowdata", clonedRowData)
            setRowDataForDoc([])
            setRowDataForDoc([...clonedRowData])
            setIsUpdateOpen(false)
            // getPreviousAppliedKeyRules()
            return
        }

        if (isUpdateOpen == true) {
            toast.error("Please save to continue", {
                position: "top-center",
                autoClose: 5000,
            })
            return
        }

        isEnabledForDoc = rowDataForDoc.map((obj, i) => {
            if (obj.docspec_id == param) {
                obj.isEnabledForDoc = false
            }
            else {
                obj.isEnabledForDoc = true
            }
            return obj
        })
        setRowDataForDoc(isEnabledForDoc)
        let oldData = rowDataForDoc.filter((obj) => obj.docspec_id == param)
        setOldRecordForDoc(oldData)

        // oldData = isEnabled.filter((obj) => obj.docspec_id == param)
        setIsUpdateOpen(true)

        // console.log("onEditClicked", param, isEnabled, oldDataForDoc)
    }

    const onEditClicked = (param, isEditable) => {
        console.log("onEditClickedparam", param, isEditable, rowData)
        if (isEditable) {
            console.log("isEditableonEditClicked", isEditable, dupRowData)


            let emptyData = rowData.some((obj) => obj.documetkeys_id == "" || obj.parameter_id == "")
            console.log("emptydataupdaterecord", emptyData)
            // if (emptyData == true) {
            //     toast.error(messages.fillAllFields, {
            //         position: "top-center",
            //         autoClose: 5000,
            //     })
            //     setLoading(false)
            //     return
            // }

            // let finalArr = []
            // previousRowData.map((obj) => {
            //     obj["documentKeys"] = documentKeysList
            //     obj["parameterRules"] = parameterRulesList
            //     obj.isEnabled = true
            //     finalArr.push(obj)
            // })
            // let inx = finalArr.findIndex((obj) => obj.ruleskeys_id == param)
            // // finalArr[inx].paramvalue = "Hi"
            // finalArr[inx] = oldRecord[0]
            // // finalArr[inx] = oldData[0]
            // finalArr[inx].isEnabled = true
            // console.log("finalarreditclicked", finalArr, "inx", inx, "oldrecord", oldRecord)


            let clonedRowData = _.cloneDeepWith(rowData)
            clonedRowData.map((obj) => {
                obj.paramvalue = obj.oldParamsValue
                obj.documetkeys_id = obj.oldDocumentKey
                obj.parameter_id = obj.oldParameterKey
                obj.isEnabled = true

            })
            console.log("clonedrowdata", clonedRowData)
            setRowData([])
            setRowData([...clonedRowData])
            setIsUpdateOpen(false)
            // getPreviousAppliedKeyRules()
            return
        }

        if (isUpdateOpen == true) {
            toast.error("Please save to continue", {
                position: "top-center",
                autoClose: 5000,
            })
            return
        }

        isEnabled = rowData.map((obj, i) => {
            if (obj.ruleskeys_id == param) {
                obj.isEnabled = false
            }
            else {
                obj.isEnabled = true
            }
            return obj
        })
        setRowData(isEnabled)
        let oldData = rowData.filter((obj) => obj.ruleskeys_id == param)
        setOldRecord(oldData)

        // oldData = isEnabled.filter((obj) => obj.ruleskeys_id == param)
        setIsUpdateOpen(true)

        console.log("onEditClicked", param, isEnabled, oldData)

    }

    const onDeleteClickedForDoc = async (param) => {
        if (isUpdateOpen == true) {
            toast.error("Please save to continue", {
                position: "top-center",
                autoClose: 5000,
            })
            return
        }
        console.log("ondelclickedparam", param)
        // if (rowDataForDoc.length == 1) {
        //     toast.error("Please edit to continue", {
        //         position: "top-center",
        //         autoClose: 5000,
        //     })
        //     return
        // }
        let finalArr = []
        finalArr = rowDataForDoc.filter((obj) => obj.docspec_id !== param)
        // finalArr.sort((a, b) => a.docspec_id - b.docspec_id);
        finalArr.sort((a, b) => a.seq_no - b.seq_no);
        console.log("finalArr_", finalArr)
        finalArr.map((item, i) => {
            if (item.seq_no != i + 1) {
                item['paramvalue'] = item.paramvalue
                item['parameter_id'] = item.parameter_id
                item['documetkeys_id'] = item.documetkeys_id
                item['docspec_id'] = item.docspec_id
                item['seq_no'] = i + 1
            }
        })
        setRowDataForDoc(finalArr)

        let docSpecArr = []
        finalArr.map((obj) => {
            docSpecArr.push({
                "_paramvalue": obj.paramvalue,
                "_parameter_id": obj.parameter_id,
                "_documetkeys_id": obj.documetkeys_id,
                "_docspec_id": obj.docspec_id,
                "_seq_no": obj.seq_no
            })
        })
        setDocSpecData(docSpecArr)
        setLoading(false)
    }

    const onDeleteClicked = async (param) => {
        if (isUpdateOpen == true) {
            toast.error("Please save to continue", {
                position: "top-center",
                autoClose: 5000,
            })
            return
        }
        console.log("ondelclickedparam", param)
        // if (rowData.length == 1) {
        //     toast.error("Please edit to continue", {
        //         position: "top-center",
        //         autoClose: 5000,
        //     })
        //     return
        // }
        let finalArr = []
        finalArr = rowData.filter((obj) => obj.ruleskeys_id !== param)
        finalArr.sort((a, b) => a.seq_no - b.seq_no);
        console.log("finalArr_", finalArr);
        finalArr.map((item, i) => {
            if (item.seq_no != i + 1) {
                item['paramvalue'] = item.paramvalue
                item['parameter_id'] = item.parameter_id
                item['documetkeys_id'] = item.documetkeys_id
                item['docspec_id'] = item.docspec_id
                item['seq_no'] = i + 1
            }
        })
        setRowData(finalArr)

        let keySpecArr = []
        finalArr.map((obj) => {
            keySpecArr.push({
                "_paramvalue": obj.paramvalue,
                "_parameter_id": obj.parameter_id,
                "_documetkeys_id": obj.documetkeys_id,
                "_ruleskeys_id": obj.ruleskeys_id,
                "_seq_no": obj.seq_no
            })
        })
        console.log("finalarr_", keySpecArr);
        setKeySpecData(keySpecArr)
        setLoading(true)

        // try {
        //     let response = await deleteKeyRules(param)
        //     console.log("delresponse", response)
        //     if (response.status == 200) {
        //         // toast.success("your request sent successfully");
        //         toast.success(response.data.msg)
        //     } else {
        //         toast.error("Something went wrong, Can you please try again");
        //     }
        // }
        // catch (error) {
        //     console.log("delkeyruleerror", error)
        //     toast.error(error.response.data.data, {
        //         position: "top-right",
        //         autoClose: 5000,
        //     });
        // }
        // finally {
        //     getAppliedKeyRules()
        // }

        setLoading(false)
    }

    const updateRecordForDoc = async (param) => {
        setLoading(true)
        console.log("updateRecordForDoc", param, rowDataForDoc)
        let rowDataToUpdate = rowDataForDoc.filter((obj) => {
            return obj.docspec_id == param
        })
        console.log("rowDataToUpdate", rowDataToUpdate, "editRuleDetails", editRuleDetails)
        let emptyData = rowDataForDoc.some((obj) => obj.documetkeys_id == "" || obj.parameter_id == "" || obj.paramvalue == "")
        console.log("emptydataupdaterecord", emptyData)
        if (emptyData == true) {
            toast.error(messages.fillAllFields, {
                position: "top-center",
                autoClose: 5000,
            })
            setLoading(false)
            return
        }
        delete rowDataToUpdate[0].isEnabledForDoc

        let docSpecArr = []
        rowDataForDoc.map((obj) => {
            docSpecArr.push({
                "_paramvalue": obj.paramvalue,
                "_parameter_id": obj.parameter_id,
                "_documetkeys_id": obj.documetkeys_id,
                "_docspec_id": obj.docspec_id,
                "_seq_no": obj.seq_no
            })
        })
        // rowDataForDoc.sort((a, b) => a.docspec_id - b.docspec_id);
        rowDataForDoc.sort((a, b) => a.seq_no - b.seq_no);

        setRowDataForDoc(rowDataForDoc)
        // setPreviousRowData(rowData)
        setDocSpecData(docSpecArr)
        setIsUpdateOpen(false)
        console.log("docSpecArr", docSpecArr, "docSpecData", docSpecData)
        console.log("rowDataToUpdatekeyspecdata", rowDataToUpdate, docSpecData)

        // let sendData = {
        //     "data1": {
        //         // "operation": "CreateRule",
        //         "_document_id": docID,
        //         "_comp_id": editRuleDetails.comp_id,
        //         "_rule_name": editRuleDetails.rule_name,
        //         "_description": editRuleDetails.description,
        //         "useremail": initialValues.email,
        //     },

        //     "data2": {
        //         // "operation": "DocRule",
        //         // "_parameter_id": parameterValue,
        //         // "_paramvalue_doc": paramDocValue,
        //         docrulepayload: 
        //     },

        //     "data3": {
        //         // "operation": "KeyRule",
        //         "keyrulepayload": rowDataToUpdate
        //     }
        // }

        // console.log("editPreviousKeyRulessendata", sendData)

        getAppliedKeyRules()
        setLoading(false)
    }

    const updateRecord = async (param) => {
        setLoading(true)
        console.log("updateRecord", param, rowData)
        let rowDataToUpdate = rowData.filter((obj) => {
            return obj.ruleskeys_id == param
        })
        console.log("rowDataToUpdate", rowDataToUpdate, "editRuleDetails", editRuleDetails)
        let emptyData = rowData.some((obj) => obj.documetkeys_id == "" || obj.parameter_id == "")
        console.log("emptydataupdaterecord", emptyData)
        if (emptyData == true) {
            toast.error(messages.fillAllFields, {
                position: "top-center",
                autoClose: 5000,
            })
            setLoading(false)
            return
        }
        // rowDataToUpdate[0].key = rowDataToUpdate[0].key
        delete rowDataToUpdate[0].isEnabled

        let keySpecArr = []
        rowData.map((obj) => {
            keySpecArr.push({
                "_paramvalue": obj.paramvalue,
                "_parameter_id": obj.parameter_id,
                "_documetkeys_id": obj.documetkeys_id,
                "_ruleskeys_id": obj.ruleskeys_id,
                "_seq_no": obj.seq_no
            })
        })
        rowData.sort((a, b) => a.seq_no - b.seq_no);
        setRowData(rowData)
        setPreviousRowData(rowData)
        setKeySpecData(keySpecArr)
        setIsUpdateOpen(false)
        console.log("keyspecarr", keySpecArr, "keySpecData", keySpecData)
        console.log("rowDataToUpdatekeyspecdata", rowDataToUpdate, keySpecData)

        let sendData = {
            "data1": {
                // "operation": "CreateRule",
                "_document_id": docID,
                "_comp_id": editRuleDetails.comp_id,
                "_rule_name": editRuleDetails.rule_name,
                "_description": editRuleDetails.description,
                "useremail": initialValues.email,
            },

            "data2": {
                // "operation": "DocRule",
                "_parameter_id": parameterValue,
                "_paramvalue_doc": paramDocValue,
            },

            "data3": {
                // "operation": "KeyRule",
                "keyrulepayload": rowDataToUpdate
            }
        }

        // let sendData = {
        //     // "docdetails_id": selectedRow._docdetails_id,
        //     // "docdetails_id": rowDataToUpdate[0]._docdetails_id,
        //     "formType": "key rules",
        //     "keyrulepayload": [{
        //         "_paramvalue": rowDataToUpdate[0]._paramvalue,
        //         "_parameter_id": rowDataToUpdate[0]._parameter_id,
        //         "_documetkeys_id": rowDataToUpdate[0]._documetkeys_id,
        //         "ruleskeys_id": rowDataToUpdate[0].ruleskeys_id
        //     }]
        // }
        console.log("editPreviousKeyRulessendata", sendData)

        // try {
        //     const response = await editPreviousKeyRules(sendData)

        //     console.log("editPreviousKeyRules", response)
        //     if (response.status == 201) {
        //         toast.success(response.data.msg);
        //     } else {
        //         toast.error("Something went wrong, Can you please try again");
        //     }
        // }
        // catch (error) {
        //     console.log("sapsingledataerror", error)
        //     toast.error(error.response.data.data, {
        //         position: "top-right",
        //         autoClose: 5000,
        //     });
        // }
        getAppliedKeyRules()
        setLoading(false)
    }


    // AppliedRulesList end

    // useEffect(() => {
    //     console.log("ruleID", ruleID)
    //     if (undefined !== ruleID) {
    //         getRuleData()
    //     }
    // }, [ruleID])

    // useEffect(() => {
    //     if (createRulesList) {
    //         getDocumentKeysList()
    //     }
    // }, [createRulesList])

    // useEffect(() => {
    //     if (undefined !== compID) {
    //         console.log("compid", compID)
    //         // getDocumentKeysList()
    //         getCustomRulesList(compID)
    //     }
    // }, [compID])

    // useEffect(() => {
    //     if (undefined !== compID && undefined !== theDocID) {
    //         console.log("compid", compID)
    //         getDocumentKeysList()
    //         // getCustomRulesList(compID,theDocID)
    //     }
    // }, [compID, theDocID])

    const retainRuleData = () => {
        setEditRuleDetails(editRuleDetails)
        let ruleKey = editRuleMasterData.Key.data
        let ruleDoc = editRuleMasterData.Doc.data

        let keySpecArr = []
        ruleKey.map((obj) => {
            keySpecArr.push({
                "_paramvalue": obj.paramvalue,
                "_parameter_id": obj.parameter_id,
                "_documetkeys_id": obj.documetkeys_id
            })
        })
        setKeySpecData(keySpecArr)

        let docSpecArr = []
        ruleDoc.map((obj) => {
            docSpecArr.push({
                "_paramvalue": obj.paramvalue,
                "_parameter_id": obj.parameter_id,
                "_documetkeys_id": obj.documetkeys_id
            })
        })
        setDocSpecData(docSpecArr)
    }

    useEffect(() => {
        if (undefined !== ruleID) {
            getRuleData()
            // retainRuleData()
        }
    }, [documentKeysList])

    const handleDefaultRuleCheckBox = (e) => {
        setIsDefaultRule(e.target.checked)
    }

    const handleLambdaCheckBox = (e) => {
        let value = e.target.checked
        console.log("handleLambdaCheckBox", value)
        setIsLambda(value)
        setCustomRule("")
        updateParameterList(value)
    }

    const getRuleData = async () => {
        setLoading(true)
        try {
            setIsUpdateOpen(false)
            let response = await ViewRules(ruleID)
            console.log("editruledata", response, typeOfRule.trim())

            if (response.status == 200) {
                setEditRuleMasterData(response.data)
                setEditRuleDetails(response.data.Rule[0])
                let ruleDetailsResponse = response.data.Rule[0]
                let lambdaStatus = ruleDetailsResponse["islambda"]
                let defaultRuleStatus = ruleDetailsResponse["is_default_rule"]
                console.log("lambdaStatus", lambdaStatus)
                console.log("defaultRuleStatus", defaultRuleStatus)
                setIsDefaultRule(defaultRuleStatus)
                setIsLambda(lambdaStatus == 0 ? false : lambdaStatus == 1 ? true : false)
                setEditRuleDoc(response.data.Doc[0])
                // setDocID(response.data.Rule[0].document_id)

                if (typeOfRule.trim() == "custom" || typeOfRule.trim() == "sprule" || typeOfRule.trim() == "lambdacustom") {
                    let customFilesRuleId = ruleDetailsResponse["customeruleid"]
                    // let customFileName = customRuleList.filter((obj) => obj.value == customFilesRuleId)[0]["name"]

                    // let customFileName = customRuleList.filter((obj) => obj.customruleid == customFilesRuleId)
                    console.log("customRule_", customRuleList)
                    let parameterFileArr = []
                    if (customRuleList.length > 0) {
                        let finalArr = []
                        customRuleList.forEach((obj) => {
                            if (obj.rulestype !== null) {
                                let trimmedRulestype = obj.rulestype.trim()
                                // if (trimmedRulestype == files) {
                                //   finalArr.push(obj)
                                // }
                                if (lambdaStatus == 1) {
                                    if (trimmedRulestype == "lambdafile") {
                                        // finalArr.push(obj)
                                        parameterFileArr.push(obj)
                                    }
                                } else if (typeOfRule.trim() == "custom") {
                                    if (trimmedRulestype == "customfile") {
                                        console.log("spfile_1", obj)
                                        parameterFileArr.push(obj)
                                    }
                                }
                                else {
                                    if ((trimmedRulestype == "spfile")) {
                                        console.log("spfile_", obj)
                                        parameterFileArr.push(obj)
                                    }
                                }
                            }
                        })
                        setParameterFilesList(parameterFileArr)
                    }

                    console.log("parameterFileArr_", parameterFileArr, customFilesRuleId);

                    // let customFileName = parameterFilesList.filter((obj) => obj.customruleid == customFilesRuleId)
                    let customFileName = parameterFileArr.filter((obj) => obj.customruleid == customFilesRuleId)
                    console.log("customFileName", customFileName, parameterFilesList)
                    setInputData({ ...inputData, ["_documetkeys_id"]: customFilesRuleId });
                    setCustomRule(customFilesRuleId)
                    setEditRuleDetails({ ...response.data.Rule[0], ["_documetkeys_id"]: customFilesRuleId })
                    setCustomRuleName(customFileName)
                    //radio button update
                    if (typeOfRule.trim() == "custom") {
                        let updatedStatusList = radioOptions.map((item) => {
                            if (item.value == 1) {
                                item.status = true
                            }
                            else {
                                item.status = false
                            }
                            return item
                        })
                        setRadioSelection(1)
                        setRadioOptions(updatedStatusList)
                    } else if (typeOfRule.trim() == "sprule") {
                        console.log("working", typeOfRule)
                        //radio button update
                        let updatedStatusList = radioOptions.map((item) => {
                            if (item.value == 3) {
                                item.status = true
                            }
                            else {
                                item.status = false
                            }
                            return item
                        })
                        setRadioSelection(3)
                        setRadioOptions(updatedStatusList)
                    }
                }
                else if (typeOfRule.trim() == "rule" || typeOfRule.trim() == "lambdarule") {
                    console.log("working", typeOfRule)
                    //radio button update
                    let updatedStatusList = radioOptions.map((item) => {
                        if (item.value == 2) {
                            item.status = true
                        }
                        else {
                            item.status = false
                        }
                        return item
                    })
                    setRadioSelection(2)
                    setRadioOptions(updatedStatusList)
                }

                let ruleKey = response.data.Key.data
                let ruleDoc = response.data.Doc.data
                setEditRuleKey(ruleKey)
                console.log("ruleKey", response.data.Key.data, "ruleDoc", response.data.Doc.data)
                let finalArr = []
                ruleKey.map((obj) => {
                    obj["documentKeys"] = documentKeysList
                    obj["parameterRules"] = parameterRulesList
                    obj["oldParamsValue"] = obj.paramvalue
                    obj["oldDocumentKey"] = obj.documetkeys_id
                    obj["oldParameterKey"] = obj.parameter_id
                    obj.isEnabled = true
                    finalArr.push(obj)
                })
                finalArr.sort((a, b) => a.seq_no - b.seq_no);
                console.log("finalArr", finalArr)
                setRowData(finalArr)
                setDupRowData(finalArr)
                setPreviousRowData(finalArr)

                let finalArrDoc = []
                ruleDoc.map((obj) => {
                    obj["documentKeys"] = documentKeysList
                    obj["parameterRules"] = parameterRulesList
                    obj["oldParamsValue"] = obj.paramvalue
                    obj["oldDocumentKey"] = obj.documetkeys_id
                    obj["oldParameterKey"] = obj.parameter_id

                    obj.isEnabledForDoc = true
                    finalArrDoc.push(obj)
                })
                // finalArrDoc.sort((a, b) => a.docspec_id - b.docspec_id);
                finalArrDoc.sort((a, b) => a.seq_no - b.seq_no);

                console.log("finalArrDoc", finalArrDoc)
                setRowDataForDoc(finalArrDoc)

                let keySpecArr = []
                ruleKey.map((obj) => {
                    keySpecArr.push({
                        "_paramvalue": obj.paramvalue,
                        "_parameter_id": obj.parameter_id,
                        "_documetkeys_id": obj.documetkeys_id,
                        "_ruleskeys_id": obj.ruleskeys_id,
                        "_seq_no": obj.seq_no
                    })
                })
                setKeySpecData(keySpecArr)

                let docSpecArr = []
                ruleDoc.map((obj) => {
                    docSpecArr.push({
                        "_paramvalue": obj.paramvalue,
                        "_parameter_id": obj.parameter_id,
                        "_documetkeys_id": obj.documetkeys_id,
                        "_docspec_id": obj.docspec_id,
                        "_seq_no": obj.seq_no
                    })
                })
                setDocSpecData(docSpecArr)

                let keyListArr = []
                documentKeysList.map((obj) => {
                    if (obj._key_type == "key_wise") {
                        keyListArr.push({
                            "label": obj._key,
                            "value": obj._documetkeys_id
                        })
                    }
                })
                console.log("keyListArr", keyListArr, documentKeysList, parameterRulesList)
                setKeyRulesList(keyListArr)

                let docListArr = []
                documentKeysList.map((obj) => {
                    if (obj._key_type == "document_wise") {
                        docListArr.push({
                            "label": obj._key,
                            "value": obj._documetkeys_id
                        })
                    }
                })
                console.log("docListArr", docListArr, documentKeysList, parameterRulesList)
                setDocRulesList(docListArr)

                let paramListArr = []
                parameterRulesList.map((obj) => {
                    paramListArr.push({
                        "label": obj._paramname,
                        "value": obj._parameter_id
                    })
                })
                setParamRulesList(paramListArr)
            }
            else {
                toast.error(messages.noDataFound)
            }
        }
        catch (err) {
            setError(err)
        }
        setLoading(false)
    }

    // useEffect(() => {
    //     if (editRuleMasterData.length == 0) {
    //         return
    //     }
    //     console.log("editRuleMasterData", editRuleMasterData)
    //     console.log("editRuleDetails", editRuleDetails)
    // }, [editRuleMasterData])

    const handleAddField = () => {
        setKeySpecData([...keySpecData, { ...keySpecField }])
        setKeySpecField({
            "_paramvalue": "",
            "_parameter_id": "",
            "_documetkeys_id": "",
        })
    }

    const handleDeleteField = (index) => {
        let newKeySpecData = [...keySpecData]
        newKeySpecData.splice(index, 1)
        setKeySpecData(newKeySpecData)
    }

    const handleAddFieldInEditForDoc = async () => {
        console.log("handleAddFieldInEditForDocrowdata", rowDataForDoc)
        let emptyData = rowDataForDoc.some((obj) => obj.documetkeys_id == "" || obj.parameter_id == "" || obj.paramvalue == "")
        if (emptyData == true) {
            toast.error(messages.fillAllFields, {
                position: "top-center",
                autoClose: 5000,
            })
            return
        }
        if (isUpdateOpen == true) {
            toast.error("Please save to continue", {
                position: "top-center",
                autoClose: 5000,
            })
            return
        }

        let newFollowUp, dupFollowUp, newFollowUpValue
        try {
            setLoading(true)
            let maxDocSpecID = await getMaxDocSpecID()
            setLoading(false)
            if (maxDocSpecID.status == 200) {
                newFollowUp = maxDocSpecID.data.data[0]["max_docspec_id"] + 1
                dupFollowUp = rowDataForDoc.length > 0 ? Math.max(...rowDataForDoc.map((obj) => obj.docspec_id)) : 0
                // newFollowUpValue = newFollowUp == dupFollowUp ? dupFollowUp + 1 : newFollowUp
                newFollowUpValue = newFollowUp == dupFollowUp ? dupFollowUp + 1 : newFollowUp < dupFollowUp ? dupFollowUp + 1 : newFollowUp
            }
            else {
                toast.error("Unable to fetch Doc Spec ID")
                return
            }

            // newFollowUp = rowDataForDoc[rowDataForDoc.length - 1].docspec_id + 1

            console.log("newFollowUp", newFollowUp, dupFollowUp, newFollowUpValue)
            let addField = {
                "documentKeys": documentKeysList,
                "parameterRules": parameterRulesList,
                "isEnabledForDoc": false,
                "docspec_id": newFollowUpValue,
                "paramvalue": "",
                "parameter_id": "",
                "documetkeys_id": "",
                "seq_no": rowDataForDoc.length + 1
            }
            // setRowData([...rowData, { ...keySpecFieldForEdit }])
            // rowDataForDoc.sort((a, b) => a.docspec_id - b.docspec_id);
            rowDataForDoc.sort((a, b) => a.seq_no - b.seq_no);

            setRowDataForDoc([...rowDataForDoc, { ...addField }])
            // setPreviousRowData([...rowData, { ...addField }])
            setDocSpecFieldForEdit({
                "documentKeys": documentKeysList,
                "parameterRules": parameterRulesList,
                "isEnabledForDoc": true,
                "docspec_id": newFollowUpValue,
                "paramvalue": "",
                "parameter_id": null,
                "documetkeys_id": null,
            })
            setIsUpdateOpen(true)
            // handleScroll()
        }
        catch (error) {
            console.error('Error fetching maxRuleKeysID:', error);
            setError(error)
        }
    }

    const handleAddFieldInEdit = async () => {
        console.log("handleAddFieldInEditrowdata", rowData)
        let emptyData = rowData.some((obj) => obj.documetkeys_id == "" || obj.parameter_id == "")
        if (emptyData == true) {
            toast.error(messages.fillAllFields, {
                position: "top-center",
                autoClose: 5000,
            })
            return
        }
        if (isUpdateOpen == true) {
            toast.error("Please save to continue", {
                position: "top-center",
                autoClose: 5000,
            })
            return
        }
        let newFollowUp, dupFollowUp, newFollowUpValue
        try {
            setLoading(true)
            const maxRuleKeysID = await getMaxRuleKeysID()
            setLoading(false)
            if (maxRuleKeysID.status == 200) {
                newFollowUp = maxRuleKeysID.data.data[0]["max_ruleskeys_id"] + 1
                dupFollowUp = rowData.length > 0 ? Math.max(...rowData.map((obj) => obj.ruleskeys_id)) : 0
                newFollowUpValue = newFollowUp == dupFollowUp ? dupFollowUp + 1 : newFollowUp < dupFollowUp ? dupFollowUp + 1 : newFollowUp
            }
            else {
                toast.error("Unable to fetch Rule Keys ID")
                return
            }
            //  newFollowUp = rowData[rowData.length - 1].ruleskeys_id + 1
            console.log("newFollowUp", newFollowUp, dupFollowUp, newFollowUpValue)
            let addField = {
                "documentKeys": documentKeysList,
                "parameterRules": parameterRulesList,
                "isEnabled": false,
                "ruleskeys_id": newFollowUpValue,
                "paramvalue": "",
                "parameter_id": "",
                "documetkeys_id": "",
                "seq_no": rowData.length + 1
            }
            // setRowData([...rowData, { ...keySpecFieldForEdit }])
            rowData.sort((a, b) => a.seq_no - b.seq_no);
            setRowData([...rowData, { ...addField }])
            setPreviousRowData([...rowData, { ...addField }])
            setKeySpecFieldForEdit({
                "documentKeys": documentKeysList,
                "parameterRules": parameterRulesList,
                "isEnabled": true,
                "ruleskeys_id": newFollowUpValue,
                "paramvalue": "",
                "parameter_id": null,
                "documetkeys_id": null,
            })
            setIsUpdateOpen(true)
            // handleScroll()
            // handleScrollToLastChild()
            setIsKeyUpdate(true)
            // scrollToBottom()
        }
        catch (error) {
            console.error('Error fetching maxRuleKeysID:', error);
            setError(error)
        }

    }



    const handleNewValueNo = (e) => {
        const { name, value } = e.target;
        let val = e.target.value.replace(/[A-Za-z' `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/g, '');
        setNewValueNo(val)
    };

    const handleNewKeys = (e) => {
        const { name, value } = e.target;
        setNewKeys(value)
    }

    const [newParamNames, setNewParamNames] = useState()
    const handleNewParamNames = (e) => {
        const { name, value } = e.target;
        // setInputData({ ...inputData, [name]: value });
        setNewParamNames(value)
    }

    const [ruleSpec, setRuleSpec] = useState([])

    // useEffect(() => {
    //     if (isRule == "custom") {
    //         if (undefined !== editRuleMasterData) {
    //             let ruleDetails = editRuleDetails.parameter
    //             // if (ruleDetails !== "") {
    //             let cr = customRuleList.filter((obj) => obj.name == ruleDetails)
    //             console.log("crl", cr)
    //             setCustomRule(cr[0].value)
    //             setCustomRuleName(ruleDetails)
    //             // }
    //         }
    //     }
    // }, [editRuleMasterData])

    useEffect(() => {
        if (isModalOpen) {
            // setParameter({
            //   name: "Select a Parameter Type",
            //   value: ""
            // })
            setParameterValue("")
            setParamDocValue("")
            FetchApiDetails()
            setNewKeyForDoc("")
            setNewKeyForKey("")
        }
    }, [isModalOpen])

    // const encrypt = (plaintext) => {
    //     console.log("encryptPlaintext", plaintext);
    //     let encryptedPassword = CryptoJS.AES.encrypt(plaintext, process.env.REACT_APP_SECRET_KEY).toString();
    //     return encryptedPassword
    // };

    const encrypt = (plaintext) => {
        console.log("encryptPlaintext", plaintext);
        return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(plaintext))
            .replace(/=+$/, '') // Remove padding
            .replace(/\+/g, '-') // Replace + with -
            .replace(/\//g, '_'); // Replace / with _
    };

    const getActiveCustomerList = async () => {
        try {
            let currentUserLoginEmail = encrypt(userDetailsLog.email)
            let response = await getActiveCustomer(currentUserLoginEmail);
            console.log("getActiveCustomer", response);
            if (response.status == 200) {
                let data = response.data.data
                let finalArr = []
                data.map((obj) => {
                    finalArr.push({
                        "name": obj._customername,
                        "value": obj.comp_id
                    })
                })
                setCustomerList(finalArr);
            }

        } catch (error) {
            console.log(error);
            setError(error)
        }
    };

    const getAPIFormat2 = async () => {
        try {
            const response = await getDocumentName();
            console.log("getAPIFormat2", response.data.data);
            let data = response.data.data
            let finalArr = []
            data.map((obj) => {
                finalArr.push({
                    "name": obj._docname,
                    "value": obj._document_id
                })
            })
            setApiFormatState2(finalArr);
        }
        catch (error) {
            console.log("getAPIFormat2error", error);
            setError(error)
        }
    };

    const getDocumentResponseFormat = async () => {
        try {
            const response = await getResponseFormat()
            console.log("getDocumentResponseFormat", response.data.data);
            setResponseFormat(response.data.data);
        } catch (error) {
            console.log(error);
            setError(error)
        }
    };

    const getRuleType = async () => {
        try {
            const response = await getRules()
            console.log("getRules", response.data.data);
            setRuleFormat(response.data.data);
        } catch (error) {
            console.log(error);
            setError(error)
        }
    };

    const OnRadioChange = (e) => {
        console.log('radio_checked', e.target.value);
        let value = e.target.value
        if (value == 1) {
            getCustomRulesList(compID, theDocID)
        }
        setIsRule(value == 1 ? "custom" : value == 2 ? "rule" : value == 3 ? "sprule" : "")
        let updatedStatusList = radioOptions.map((item) => {
            if (item.value == value) {
                item.status = true
            }
            else {
                item.status = false
            }
            return item
        })
        setRadioSelection(value)
        setRadioOptions(updatedStatusList)
    }

    useEffect(() => {
        getDocumentResponseFormat()
        getActiveCustomerList()
        getAPIFormat2();
        // getRuleType()
        setCustomerPlaceholder({
            name: "Select Customer",
            value: ""
        })
        setDocType({
            name: "Select a Document Type",
            value: ""
        })
        setRuleName("")
        setRuleDescription("")
        setDocID("")
        setCustomername("")
        setNewKeyForDoc("")
        setNewKeyForKey("")
        // setIDNo()
    }, []);

    useEffect(() => {
        // getDocumentKeysList()
        getParameterRulesList()

        // setKeyType({
        //   name: "Keys",
        //   value: ""
        // })
        setFormatType({
            name: "Param Rule",
            value: ""
        })
        setDocKeys("")
        setParamRule("")
        setIDNo()
    }, [])

    // useEffect(() => {
    //     if (editRuleMasterData.length > 0) {
    //         updateParameterList(isLambda)
    //         // setCustomRule("")
    //     }
    // }, [isLambda])

    const updateCustomRule = () => {
        console.log("editRuleDetails", editRuleDetails)
        let customFilesRuleId = editRuleDetails["customeruleid"]
        // let customFileName = customRuleList.filter((obj) => obj.value == customFilesRuleId)[0]["name"]
        let customFileName = customRuleList.filter((obj) => obj.customruleid == customFilesRuleId)
        // let customFileName = parameterFilesList.filter((obj) => obj.customruleid == customFilesRuleId)
        console.log("customFileName", customFileName, parameterFilesList)
        setInputData({ ...inputData, ["_documetkeys_id"]: customFilesRuleId });
        setCustomRule(customFilesRuleId)
        setEditRuleDetails({ ...editRuleDetails, ["_documetkeys_id"]: customFilesRuleId })
        setCustomRuleName(customFileName)
    }

    // useEffect(() => {
    //     if(parameterFilesList.length > 0){
    //         updateCustomRule()
    //     }
    // }, [parameterFilesList])

    const updateParameterList = (lambdaFile) => {
        console.log("customRuleList", customRuleList)
        if (customRuleList.length > 0) {
            let finalArr = []
            customRuleList.forEach((obj) => {
                if (obj.rulestype !== null) {
                    let trimmedRulestype = obj.rulestype.trim()
                    // if (trimmedRulestype == files) {
                    //   finalArr.push(obj)
                    // }

                    // if (isLambda) {
                    if (lambdaFile) {
                        if (trimmedRulestype == "lambdafile") {
                            finalArr.push(obj)
                        }
                    }
                    else {
                        if (trimmedRulestype == "customfile") {
                            finalArr.push(obj)
                        } else if (trimmedRulestype == "spfile") {
                            finalArr.push(obj)
                        }
                    }

                }
            })
            setParameterFilesList(finalArr)
        }
    }

    useEffect(() => {
        if (isEditNewRuleModal) {
            getDocumentKeysList()
            getCustomRulesList(compID, theDocID)
            getParameterRulesList()
            setRadioOptions([
                {
                    label: "Document",
                    value: 1,
                    status: true
                },
                {
                    label: "Header/Details",
                    value: 2,
                    status: false
                },
                // {
                //     label: "SP",
                //     value: 3,
                //     status: false
                // }
            ])
        }
    }, [isEditNewRuleModal])

    // useEffect(() => {
    //     if (isEditNewRuleModal == true) {
    //         if (undefined !== editRuleDetails && customRuleList.length > 0 && (undefined !== typeOfRule && typeOfRule.trim() == "custom" || typeOfRule.trim() == "lambdacustom")) {
    //             console.log("editruledetails", editRuleDetails)
    //             let customFilesRuleId = editRuleDetails["customeruleid"]
    //             let customFileName = customRuleList.filter((obj) => obj.value == customFilesRuleId)[0]["name"]
    //             console.log("customFileName", customFileName)
    //             setInputData({ ...inputData, ["_documetkeys_id"]: customFilesRuleId });
    //             setCustomRule(customFilesRuleId)
    //             setEditRuleDetails({ ...editRuleDetails, ["_documetkeys_id"]: customFilesRuleId })
    //             setCustomRuleName(customFileName)
    //         }
    //     }
    // }, [isEditNewRuleModal])

    const handleCustomerName = (e) => {
        const { name, value } = e.target;
        console.log("name_value", name, value, e)
        setInputData({ ...inputData, [name]: value });
        setCustomername(value)
        setEditRuleDetails({ ...editRuleDetails, ["comp_id"]: value })
    };

    const handleChange = (event) => {
        // setAge(event.target.value);
    };

    const handleDocumentType = (e) => {
        const { name, value } = e.target;
        setInputData({ ...inputData, [name]: value });
        setDocID(value)
        setEditRuleDetails({ ...editRuleDetails, ["document_id"]: value })
    };

    const handleRuleName = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setInputData({ ...inputData, [name]: value });
        setRuleName(value)
        setEditRuleDetails({ ...editRuleDetails, ["rule_name"]: value })
    };

    const handleRuleDescription = (e) => {
        const { name, value } = e.target;
        setInputData({ ...inputData, [name]: value });
        setRuleDescription(value)
        setEditRuleDetails({ ...editRuleDetails, ["description"]: value })
    };

    const handleCustomRule = (e) => {
        console.log("handlecustomRule", e, customRuleList)
        const { name, value } = e.target;
        setInputData({ ...inputData, [name]: value });
        setCustomRule(value)
        setEditRuleDetails({ ...editRuleDetails, ["_documetkeys_id"]: value })

        let customName = customRuleList.filter((obj) => obj.value == value)[0]["name"]
        console.log("customName", customName)
        setCustomRuleName(customName)
    };


    const handleIDNo = (e) => {
        const { name, value } = e.target;
        let val = e.target.value.replace(/[A-Za-z' `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/g, '');
        setInputData({ ...inputData, [name]: val });
        setIDNo(val)
    };

    // truncate - 9
    // rename - 10
    // position - 11
    // hide - 12

    // check duplicate truncate for same elements
    const containsDuplicateTruncate = (arr) => {
        console.log("arr", arr)
        let dupElements = arr.map((obj) => {
            if (obj._parameter_id == "9") {
                return obj._documetkeys_id
            }
        })
            .filter((obj) => undefined !== obj)
        console.log("dupElements", dupElements)
        let hasDupElementsForTruncate = false
        if (dupElements.length > 1) {
            hasDupElementsForTruncate = dupElements.some((obj, index) => {
                return dupElements.indexOf(obj) !== index
            })
        }
        console.log("hasDupElementsForTruncate", hasDupElementsForTruncate)
        if (hasDupElementsForTruncate) {
            toast.error("Duplicate Truncate not allowed")
            return true
        }
        return false
    }

    // check duplicate rename for same or different elements
    const containsDuplicateRename = (arr) => {
        console.log("arr", arr)

        //check duplicateElements for Rename
        let dupElements = arr.map((obj) => {
            if (obj._parameter_id == "10") {
                return obj._documetkeys_id
            }
        })
            .filter((obj) => undefined !== obj)
        console.log("dupElements", dupElements)
        let hasDupElementsForRename = false
        if (dupElements.length > 1) {
            hasDupElementsForRename = dupElements.some((obj, index) => {
                return dupElements.indexOf(obj) !== index
            })
        }
        console.log("hasDupElementsForRename", hasDupElementsForRename)

        // if(isRenameExist){
        let valuesOfRename = arr.map((obj) => {
            if (obj._parameter_id == "10") {
                return obj._paramvalue
            }
        })
        valuesOfRename = valuesOfRename.filter((obj) => undefined !== obj)
        console.log("valuesOfRename", valuesOfRename)
        // }
        let hasDuplicateRenameValue = false
        if (valuesOfRename.length > 1) {
            hasDuplicateRenameValue = valuesOfRename.some((obj, index) => {
                return valuesOfRename.indexOf(obj) !== index
            })
        }
        console.log("hasDuplicateRenameValue", hasDuplicateRenameValue)

        if (hasDupElementsForRename || hasDuplicateRenameValue) {
            toast.error("Duplicate Rename not allowed")
            return true
        }
        return false
    }

    // check duplicate assign for same or different elements
    const containsDuplicateAssign = (arr) => {
        console.log("arr", arr)

        //check duplicateElements for Assign
        let dupElements = arr.map((obj) => {
            if (obj._parameter_id == "22") {
                return obj._documetkeys_id
            }
        })
            .filter((obj) => undefined !== obj)
        console.log("dupElements", dupElements)
        let hasDupElementsForAssign = false
        if (dupElements.length > 1) {
            hasDupElementsForAssign = dupElements.some((obj, index) => {
                return dupElements.indexOf(obj) !== index
            })
        }
        console.log("hasDupElementsForAssign", hasDupElementsForAssign)

        // if(isAssignExist){
        let valuesOfAssign = arr.map((obj) => {
            if (obj._parameter_id == "22") {
                return obj._paramvalue
            }
        })
        valuesOfAssign = valuesOfAssign.filter((obj) => undefined !== obj)
        console.log("valuesOfAssign", valuesOfAssign)
        // }
        let hasDuplicateAssignValue = false
        if (valuesOfAssign.length > 1) {
            hasDuplicateAssignValue = valuesOfAssign.some((obj, index) => {
                return valuesOfAssign.indexOf(obj) !== index
            })
        }
        console.log("hasDuplicateAssignValue", hasDuplicateAssignValue)

        // if (hasDupElementsForAssign || hasDuplicateAssignValue) {
        if (hasDupElementsForAssign) {
            toast.error("Duplicate Assign not allowed", {
                position: "top-center",
                autoClose: 5000,
            })
            return true
        }
        return false
    }

    // check if the value of position is only a number
    const containsPositionNotANumber = (arr) => {
        console.log("arr", arr)
        let isPositionExist = arr.some((obj) => obj._parameter_id == "11")
        console.log("isPositionExist", isPositionExist)

        let valuesOfPosition = arr.map((obj) => {
            if (obj._parameter_id == "11") {
                return obj._paramvalue
            }
        })
            .filter((obj) => undefined !== obj)
        console.log("valuesOfPosition", valuesOfPosition)
        let wholeNumberPattern = /^\d+$/;
        let isWholeNumber = valuesOfPosition.every((str) => wholeNumberPattern.test(str))
        console.log("isWholeNumber", isWholeNumber)
        if (isWholeNumber == false) {
            toast.error("Position must be a positive whole number")
            return true
        }
        return false
    }

    // check if the value of truncate is only a number
    const containsTruncateNotANumber = (arr) => {
        console.log("arr", arr)
        let isTruncateExist = arr.some((obj) => obj._parameter_id == "9")
        console.log("isTruncateExist", isTruncateExist)

        let valuesOfTruncate = arr.map((obj) => {
            if (obj._parameter_id == "9") {
                return obj._paramvalue
            }
        })
            .filter((obj) => undefined !== obj)
        console.log("valuesOfTruncate", valuesOfTruncate)
        let wholeNumberPattern = /^\d+$/;
        let isWholeNumber = valuesOfTruncate.every((str) => wholeNumberPattern.test(str))
        console.log("isWholeNumber", isWholeNumber)
        if (isWholeNumber == false) {
            toast.error("Truncate must be a positive whole number")
            return true
        }
        return false
    }

    // check duplicate position for same or different elements
    const containsDuplicateKeysPosition = (arr) => {
        console.log("arr", arr)
        // isDuplicateExist =  keySpecData.some((obj,index)=>{
        //   keySpecData.findIndex((item)=>(item._parameter_id == obj._parameter_id) == index)
        // })
        // console.log("isDuplicateExist",isDuplicateExist)

        //check if position exist
        let isPositionExist = arr.some((obj) => obj._parameter_id == "11")
        console.log("isPositionExist", isPositionExist)

        //check duplicateElements for positions
        let dupElements = arr.map((obj) => {
            if (obj._parameter_id == "11") {
                return obj._documetkeys_id
            }
        })
            .filter((obj) => undefined !== obj)
        console.log("dupElements", dupElements)
        let hasDupElementsForPosition = false
        if (dupElements.length > 1) {
            hasDupElementsForPosition = dupElements.some((obj, index) => {
                return dupElements.indexOf(obj) !== index
            })
        }
        console.log("hasDupElementsForPosition", hasDupElementsForPosition)

        // if(isPositionExist){
        let valuesOfPosition = arr.map((obj) => {
            if (obj._parameter_id == "11") {
                return obj._paramvalue
            }
        })
        valuesOfPosition = valuesOfPosition.filter((obj) => undefined !== obj)
        console.log("valuesOfPosition", valuesOfPosition)
        // }
        let numberOfElements = documentKeysList.length

        let isPositionGreater = valuesOfPosition.some((obj) => obj > numberOfElements)
        let invalidPositions = false
        invalidPositions = valuesOfPosition.some((obj) => {
            if (obj > numberOfElements || obj <= 0) {
                return true
            }
        })
        console.log("isPositionGreater", isPositionGreater, invalidPositions)
        // if (isPositionGreater || valuesOfPosition.includes("0")) {
        //   toast.error("Invalid Positions")
        //   return true
        // }
        if (invalidPositions) {
            toast.error("Invalid Positions")
            return true
        }
        let hasDuplicatePositionValue = false
        if (valuesOfPosition.length > 1) {
            hasDuplicatePositionValue = valuesOfPosition.some((obj, index) => {
                return valuesOfPosition.indexOf(obj) !== index
            })
        }
        console.log("hasDuplicatePositionValue", hasDuplicatePositionValue)

        if (hasDupElementsForPosition || hasDuplicatePositionValue) {
            toast.error("Duplicate Positions not allowed")
            return true
        }

        return false

        // isDuplicateExist = keySpecData.some((obj) => {
        //   if (keySpecData.indexOf(obj) !== keySpecData.lastIndexOf(obj)) {
        //     return true
        //   }
        //   return false

        // })
        // console.log("isDuplicateExist", isDuplicateExist)

    }

    // check duplicate hide for same elements
    const containsDuplicateHide = (arr) => {
        console.log("arr", arr)
        let dupElements = arr.map((obj) => {
            if (obj._parameter_id == "12") {
                return obj._documetkeys_id
            }
        })
            .filter((obj) => undefined !== obj)
        console.log("dupElements", dupElements)
        let hasDupElementsForHide = false
        if (dupElements.length > 1) {
            hasDupElementsForHide = dupElements.some((obj, index) => {
                return dupElements.indexOf(obj) !== index
            })
        }
        console.log("hasDupElementsForHide", hasDupElementsForHide)
        if (hasDupElementsForHide) {
            toast.error("Duplicate Hide not allowed")
            return true
        }
        return false
    }

    // useEffect(() => {
    //   console.log("inputData", inputData, docID, IDNo)
    // }, [inputData, IDNo, docID])

    // useEffect(() => {
    //   errorCheck()
    // }, [isError])

    // const errorCheck = ()=>{
    //   if (isError) {
    //     console.log("errorcheck",isError)
    //     toast.error("Please fill all field", {
    //       position: "top-center",
    //       autoClose: 5000,
    //     })
    //   }
    // }

    // var keysToDelete = ["documentKeys","isEnabled", "key","parameterRules","paramname","rule_id","ruleskeys_id" ]
    // const deleteObjectKeys = (array,keys)=>{
    //     for(let i = 0; i<array.length; i++){
    //         array[i]
    //     }
    // }

    const switchDefaultRule = async () => {
        setLoading(true)
        try {
            if ("defaultRuleData" in defaultRulePayload.data1) {
                let response = await updateRule(defaultRulePayload, editRuleDetails.rule_id)
                console.log("switchDefaultRule", response)
                if (response.status === 200) {
                    toast.success(messages.addedSuccess, {
                        position: "top-center",
                        autoClose: 5000,
                    });

                }
                else {
                    toast.error(messages.somethingWrong);
                }
            }
        }
        catch (err) {
            console.log("switchDefaultRuleErr", err)

        }
        finally {
            setRuleName("")
            setRuleDescription("")
            setParameterValue("")
            setParamDocValue("")
            setKeySpecData([])
            setCustomRule("")
            setIsLambda(false)
            setIsDefaultRule(false)
            setDefaultCheckModal(false)
            handleEditRuleOk()
            setNewKeysModal(false);
            setIsModalOpen(false);
            // handleCancel()
            // getUserRulesList()
            setDefaultRulePayload()
            setLoading(false)
        }
    }

    const getDefaultRulesData = async () => {
        setLoading(true)
        try {
            let defaultRulesData = await getDefaultRules(editRuleDetails.comp_id, true)
            console.log("defaultRulesData", defaultRulesData)
            return defaultRulesData.data.data[0]
        }
        catch (err) {
            console.log("err", err)
        }
        setLoading(false)
    }

    const onSubmitRequestApi = async (e) => {
        let isEditEnabled = false, isEditEnabledForDoc = false, emptyData = false, emptyDataForDoc = false
        isEditEnabled = rowData.some((obj) => obj.isEnabled == false)
        isEditEnabledForDoc = rowDataForDoc.some((obj) => obj.isEnabledForDoc == false)
        // let isEditEnabledForDoc = rowDataForDoc.some((obj) => obj.isEnabledForDoc == false)

        emptyData = rowData.some((obj) => (undefined == obj.documetkeys_id || obj.documetkeys_id == "") || (undefined == obj.parameter_id || obj.parameter_id == ""))
        emptyDataForDoc = rowDataForDoc.some((obj) => (undefined == obj.documetkeys_id || obj.documetkeys_id == "") || (undefined == obj.parameter_id || obj.parameter_id == ""))
        console.log("rowdataonsubmit", rowData, rowDataForDoc, emptyData, "isEditEnabled", isEditEnabled)
        if ((isEditEnabled || isEditEnabledForDoc) == true) {
            toast.error("Please save all fields", {
                position: "top-center",
                autoClose: 5000,
            })
            return
        }
        if ((emptyData || emptyDataForDoc) == true) {
            toast.error(messages.fillAllFields, {
                position: "top-center",
                autoClose: 5000,
            })
            return
        }

        if (keySpecData.length > 0) {
            let isPositionNotANumber = containsPositionNotANumber(keySpecData)
            let isTruncateNotANumber = containsTruncateNotANumber(keySpecData)
            let isDuplicatePositionExist = containsDuplicateKeysPosition(keySpecData)
            let isDuplicateTruncateExist = containsDuplicateTruncate(keySpecData)
            let isDuplicateHideExist = containsDuplicateHide(keySpecData)
            let isDuplicateRenameExist = containsDuplicateRename(keySpecData)
            let isDuplicateAssignExist = containsDuplicateAssign(keySpecData)
            if (isPositionNotANumber || isTruncateNotANumber || isDuplicatePositionExist || isDuplicateTruncateExist || isDuplicateHideExist || isDuplicateRenameExist || isDuplicateAssignExist) {
                return
            }
        }

        if (docSpecData.length > 0) {
            let isPositionNotANumberExistForDoc = containsPositionNotANumber(docSpecData)
            let isTruncateNotANumberExistForDoc = containsTruncateNotANumber(docSpecData)
            let isDuplicatePositionExistForDoc = containsDuplicateKeysPosition(docSpecData)
            let isDuplicateTruncateExistForDoc = containsDuplicateTruncate(docSpecData)
            let isDuplicateHideExistForDoc = containsDuplicateHide(docSpecData)
            let isDuplicateRenameExistForDoc = containsDuplicateRename(docSpecData)
            let isDuplicateAssignExistForDoc = containsDuplicateAssign(docSpecData)
            if (isPositionNotANumberExistForDoc || isTruncateNotANumberExistForDoc || isDuplicatePositionExistForDoc || isDuplicateTruncateExistForDoc || isDuplicateHideExistForDoc || isDuplicateRenameExistForDoc || isDuplicateAssignExistForDoc) {
                return
            }
        }

        if (keySpecData.length > 0 && keySpecData.some((obj) => obj._documetkeys_id === "" || obj._parameter_id === "")) {
            setIsError(true)
            toast.error(messages.fillAllFields, {
                position: "top-center",
                autoClose: 5000,
            })
            setLoading(false)
            return
        }
        else if (docSpecData.length > 0 && docSpecData.some((obj) => obj._documetkeys_id === "" || obj._parameter_id === "")) {
            setIsError(true)
            toast.error(messages.fillAllFields, {
                position: "top-center",
                autoClose: 5000,
            })
            setLoading(false)
            return
        }
        else {
            setIsError(false)
        }

        // const { document_id, customer_id, doc_type, email, } =
        //   inputData;
        // let sendData = {
        //   _document_id: inputData._document_id,
        //   email: initialValues.email,
        //   _documetkeys_id: inputData._documetkeys_id,
        //   // _documetkeys_id: docID,
        // }

        // let sendData = {
        //   // inputData._document_id,
        //   //   "id": IDNo,
        //   //   "email": email,
        //   "_document_id": docID,
        //   "operation": "CreateRule",
        //   "_rule_name": ruleName,
        //   "_description": ruleDescription,
        //   "useremail": initialValues.email,

        //   "docSpec": {
        //     "operation": "DocRule",
        //     "_parameter_id": parameterValue,
        //     "_paramvalue_doc": paramDocValue,
        //   },

        //   "keyrulepayload": keySpecData
        // }


        let sendData = {

            "data1": {
                "_document_id": editRuleDetails.document_id,
                "_comp_id": editRuleDetails.comp_id,
                "_rule_name": editRuleDetails.rule_name,
                "_description": editRuleDetails.description,
                "useremail": initialValues.email,
                // "parameter": customRuleName
                "parameter_rule_id": customRule,
                "_custom_rule_id": (customRule == "" || radioSelection == 2) ? null : customRule,
                "_custom_rule_name": (customRuleName == "" || radioSelection == 2) ? null : customRuleName,
                isDefaultRule: isDefaultRule,
                isLambda: isLambda == true ? 1 : 0,
                isDocument: radioSelection == 1 ? 1 : 0,
                isHeader: radioSelection == 2 ? 1 : 0,
                isFile: 0
            },

            "data2": {
                // "_parameter_id": editRuleDoc.parameter_id,
                // "_paramvalue_doc": editRuleDoc.paramvalue,
                "docrulepayload": docSpecData
            },

            "data3": {
                "keyrulepayload": keySpecData
            }
        }

        if ((radioSelection == 1 || radioSelection == 3) && customRule !== "") {
            delete sendData.data2
            delete sendData.data3
        }

        console.log("sendData", sendData)

        if (sendData.data1._rule_name == "" || sendData.data1._description == "" || sendData.data1._comp_id == "" || sendData.data1._document_id == "") {
            toast.error(messages.fillAllFields, {
                position: "top-center",
                autoClose: 5000,
            })
            return
        }

        let ruleID = editRuleDetails.rule_id

        if (isRule == "custom" || isRule == "lambdacustom") {
            delete sendData.data2
            delete sendData.data3
        }


        let defaultData = await getDefaultRulesData()
        console.log("defaultData", defaultData)
        console.log("editRuleDetails", editRuleDetails)
        if (undefined !== defaultData && defaultData.rule_id !== editRuleDetails.rule_id) {
            let { rule_id, rule_name, description } = defaultData
            setDefaultRule({
                ruleId: rule_id, ruleName: rule_name, ruleDescription: description,
            })
            setDefaultCheckModal(true)
            sendData.data1["defaultRuleData"] = defaultData
            setDefaultRulePayload(sendData)
            setLoading(false)
            return
        }

        // setLoading(false)
        // return

        // if (_document_id == "" || customer_id || doc_type || email == "" || _format_id == ""  ) {
        //   toast.error("Please fill all fields...");
        // } 
        // else {
        // let inputData2 = { "document_id": "100", "email": "pallav.panda@rapidqube.com", "_document_id": "", "doc_type": "PDF", }
        setLoading(true)
        try {
            // const response = await createNewRules(sendData)
            const response = await updateRule(sendData, ruleID)
            console.log("createNewRulesres", response)
            if (response.status === 200) {
                // setInputData(initialValues);
                toast.success(messages.updatedSuccess, {
                    position: "top-center",
                    autoClose: 5000,
                });

            }
            else {
                toast.error("Something went wrong, Can you please try again");
            }
        } catch (error) {
            console.log("sapsingledataerror", error)
            // setInputData(initialValues);

            // toast.error(error.response.data.data, {
            //     position: "top-center",
            //     autoClose: 5000,
            // });

            setError(error)
        }
        // }
        setRuleName("")
        setRuleDescription("")
        setParameterValue("")
        setParamDocValue("")
        setKeySpecData([])
        setCustomRule("")
        setIsLambda(false)
        setIsDefaultRule(false)
        setDefaultCheckModal(false)
        handleEditRuleOk()
        setNewKeysModal(false);
        setIsModalOpen(false);
        // handleCancel()
        // getUserRulesList()
        setLoading(false)
    };

    // useEffect(() => {
    //     setHideSelect(true)
    //     setEditRuleMasterData([])
    //     setEditRuleDetails([])
    // }, [handleEditRuleCancel])



    const FetchApiDetails = async () => {
        try {
            const response = await getAllParameterRules()
            console.log("getParameterRules", response);
            let res = response.data.data
            let finalArr = []
            finalArr.push(
                {

                    "_paramname": "Select",
                    "_paramtype": "Select",
                })
            res.map((obj) => {
                if (obj._paramtype == "Document wise") {
                    finalArr.push(obj)
                }
            })
            console.log("finalArr", finalArr)
            setApiDetailState(finalArr);
            // setHideSelect(false)
        } catch (error) {
            console.log(error);
            setError(error)
        }
    };

    const getDocumentKeysList = async () => {
        setLoading(true)
        let sendData = {
            comp_id: undefined == compID ? "" : compID,
            // docid: docID
            docid: undefined == theDocID ? "" : theDocID
            // docid: undefined !== editRuleDetails && editRuleDetails.document_id
        }
        try {
            // const response = await getDocumentKeys(compID)
            const response = await getDocumentKeys(sendData)
            console.log("getDocumentKeys", response);
            if (response.status !== 200) {
                toast.error(response.message)
                setDocumentKeysList([])
                setLoading(false)
                return
            }
            let res = response.resData
            setDocumentKeysList(res.data.data);
        }
        catch (error) {
            console.log("documentkeyserror", error);
            setError(error)
        }


        setLoading(false)
    }

    const getParameterRulesList = async () => {
        setLoading(true)
        try {
            // const response = await getParameterRules()
            const response = await getAllParameterRules()
            console.log("getParameterRules", response);
            let res = response.data.data
            let finalArr = []
            res.map((obj) => {
                if (obj._paramtype == "keys wise") {
                    finalArr.push(obj)
                }
            })
            console.log("finalArr", finalArr)
            setParameterRulesList(finalArr);
        } catch (error) {
            console.log(error);
            setError(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        FetchApiDetails()
    }, [])

    const showModal = () => {
        // setSelectedRow(params.row)
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        // setParameter({
        //   name: "Select a Parameter Type",
        //   value: ""
        // })
        setParameterValue("")
        setParamDocValue("")
        setApiDetailState([])
    };

    const handleCancel = () => {
        // setIsModalOpen(false);
        // setParameter({
        //   name: "Select a Parameter Type",
        //   value: ""
        // })
        setParameterValue("")
        setParamDocValue("")
        setApiDetailState([])
        setEditRuleMasterData([])
        setEditRuleDetails()
        setCustomRule("")
        setCustomRuleName("")
        setEditRuleKey([])
        handleEditRuleCancel()
        setIsRule()
        setIsLambda(false)
        setIsDefaultRule(false)
    };

    const newRuleModalCancel = () => {
        setNewKeysModal(false);
    };

    const handleParameterValue = (e) => {
        const { name, value } = e.target;
        console.log("parametervalue", value, name)
        if (value == "Select") {
            setParameterValue("")
            return
        }
        setParameterValue(value)
    };

    const handleparamDocValue = (e) => {
        const { name, value } = e.target;
        setParamDocValue(value)
    };

    const handleAfterNewRule = () => {
        setNewRuleData({
            "_document_id": docID,
            "_comp_id": customerName,
            "_rule_name": ruleName,
            "_description": ruleDescription,
            "useremail": initialValues.email,
            // "docSpec": {
            //   "_parameter_id": parameterValue,
            //   "_paramvalue_doc": paramDocValue,
            // },
            // "keySpec": ruleSpec
        })
        // setHideSelect(true)
        // let cloneApiDetailState = _.clon
        setParameterValue("")
        setParamDocValue("")
        setApiDetailState([])
        setApiDetailState([...apiDetailState])
        setIsModalOpen(true)

    }

    const handleAfterDocSpec = () => {
        setNewKeysModal(true)
        setNewKeys("")
        setNewParamNames("")
        setNewValueNo("")

        setNewRuleData({
            ...newRuleData,
            "docSpec": {
                "_parameter_id": parameterValue,
                "_paramvalue_doc": paramDocValue,
            },
            // "keySpec": ruleSpec

        })
    }

    const handleSelectKey = (e, index) => {
        let newKeySpecData = [...keySpecData]
        newKeySpecData[index]._documetkeys_id = e.target.value
        setKeySpecData(newKeySpecData)
    }

    const handleSelectParameters = (e, index) => {
        let newKeySpecData = [...keySpecData]
        newKeySpecData[index]._parameter_id = e.target.value
        setKeySpecData(newKeySpecData)
    }

    const handleKeyParamValue = (e, index) => {
        let newKeySpecData = [...keySpecData]
        newKeySpecData[index]._paramvalue = e.target.value
        setKeySpecData(newKeySpecData)
    }

    console.log("newRule", newRuleData)

    const handleDefaultCheckClose = () => {
        setDefaultRule({
            ruleId: "", ruleName: "", ruleDescription: "",
        })
        setDefaultCheckModal(false)
    }

    const handleDeleteAllField = (param) => {
        setIsDeleteAllModal(true)
        if (param == "Doc") {
            setIsDocType(true)
        } else {
            setIsDocType(false)
        }
    }

    const handleDeleteAllCancel = () => {
        setIsDeleteAllModal(false)
    }

    const handleDeleteAllSubmit = () => {

        if (isDocType) {
            let finalArr = []
            finalArr.map((item, i) => {
                item['paramvalue'] = ''
                item['parameter_id'] = ''
                item['documetkeys_id'] = ''
                item['docspec_id'] = ''
                item['seq_no'] = 1
            })
            setRowDataForDoc(finalArr)

            let docSpecArr = {
                "_paramvalue": "",
                "_parameter_id": "",
                "_documetkeys_id": "",
                "_docspec_id": "",
                "_seq_no": 1
            }
            setDocSpecData(docSpecArr)
            // setRowDataForDoc([])
            // setDocSpecData([])
        } else {
            let finalArr = []
            finalArr.map((item, i) => {
                item['paramvalue'] = ''
                item['parameter_id'] = ''
                item['documetkeys_id'] = ''
                item['docspec_id'] = ''
                item['seq_no'] = 1
            })
            setRowData(finalArr)
            let keySpecarray = {
                "_paramvalue": '',
                "_parameter_id": '',
                "_documetkeys_id": '',
                "_ruleskeys_id": '',
                "_seq_no": 1
            }
            setKeySpecData(keySpecarray)
        }
        setIsDeleteAllModal(false)
    }



    return (
        <>
            <Loader loading={loading} />
            <Toaster position="top-center" reverseOrder={false} />
            <ErrorCatch err={error} />

            {/* default rule check starts */}
            <Modal
                className="p-2"
                // zIndex= {1000}
                open={defaultCheckModal}
                // style={{ top: 20, minWidth: 900, maxWidth: 1200 }}
                onCancel={handleDefaultCheckClose}
                maskClosable={false}
                footer={[
                    <div className="d-flex justify-content-end gap-1">
                        <Button
                            className="btn btn-primary btn-sm"
                            type="primary"
                            onClick={switchDefaultRule}
                        >
                            Submit
                        </Button>
                        <Button className="btn btn-secondary btn-sm" type="secondary" onClick={handleDefaultCheckClose}>
                            Cancel
                        </Button>
                    </div>
                ]}
            >
                <div class="row">
                    <div className="col text-center">
                        <p className="heading-popup">Default Rule</p>
                    </div>
                </div>

                <div className="">
                    <p>
                        <strong>Default Rule Name:</strong> {defaultRule.ruleName} <br />
                        <strong>Description:</strong> {defaultRule.ruleDescription}
                    </p>
                    <p>
                        The above rule already exists as a default rule for this company.<br />
                        Would you like to set this new rule as default ?
                    </p>
                </div>
            </Modal>
            {/* default rule check ends */}
            {/* Delete all confirmation starts*/}
            <Modal
                className="p-2"
                // zIndex= {1000}
                open={isDeleteAllModal}
                // style={{ top: 20, minWidth: 900, maxWidth: 1200 }}
                onCancel={handleDeleteAllCancel}
                maskClosable={false}
                footer={[
                    <div className="d-flex justify-content-end gap-1">
                        <Button
                            className="btn btn-primary btn-sm"
                            type="primary"
                            onClick={handleDeleteAllSubmit}
                        >
                            Submit
                        </Button>
                        <Button className="btn btn-secondary btn-sm" type="secondary" onClick={handleDeleteAllCancel}>
                            Cancel
                        </Button>
                    </div>
                ]}
            >
                <div class="row">
                    <div className="col text-center">
                        <img src={warning} className="imgicon"></img>
                    </div>
                </div>

                <div className=" text-center py-2">
                    <h3>
                        Do you want to Delete All the Fields
                    </h3>
                </div>
            </Modal>
            {/* Delete all confirmation ends */}
            <Modal
                // width={500}
                // className="p-2 w-75"
                style={{ top: 20, minWidth: 920, maxWidth: 1200 }}
                bodyStyle={{ height: `${radioSelection == 2 && 500}` }}
                maskClosable={false}
                open={isEditNewRuleModal}
                onOk={handleEditRuleOk}
                onCancel={handleCancel}
                // destroyOnClose
                footer={[
                    <div className="d-flex justify-content-end gap-1">
                        {appObjects["Submit"] && isButtons && <Button
                            className="btn btn-primary btn-sm"
                            type="primary"
                            onClick={onSubmitRequestApi}
                            disabled={radioSelection == 2 && (docSpecData.length > 0 || keySpecData.length > 0) ? false : (radioSelection == 1 || radioSelection == 3) && customRule !== "" ? false : true}
                        >
                            Submit
                        </Button>}
                        {appObjects["Cancel"] && isButtons && <Button className="btn btn-secondary btn-sm" type="secondary" onClick={handleCancel}>
                            Cancel
                        </Button>}
                    </div>
                ]}

            >
                <div className="container-fluid"
                >
                    <div class="row">
                        <div className="col text-center">
                            <p className="heading-popup">{isButtons ? "Edit Rule" : "View Rule"}</p>
                        </div>
                    </div>
                    {/* <hr className="text-secondary"></hr> */}

                    <div
                        ref={containerRef}
                        style={{
                            // height: radioSelection == 2 ? "28rem" : "19rem",
                            height: radioSelection == 2 ? "32rem" : "28rem",
                            overflowY: "auto", overflowX: "hidden"
                        }}
                    >

                        <Accordion defaultExpanded>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <p className="heading-popup">Rule Details</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div class="row mb-3">
                                    <div className="col-md-6 ">
                                        <SelectInput
                                            selectLabel="*Customer"
                                            id="customerName"
                                            name="_comp_id"
                                            onChange={(e) => handleCustomerName(e)}
                                            value={undefined !== editRuleDetails && editRuleDetails.comp_id}
                                            optionsList={customerList}
                                            disabled={true}
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <SelectInput
                                            selectLabel="*Document Type"
                                            id="documentKeysId"
                                            name="_documetkeys_id"
                                            onChange={(e) => handleDocumentType(e)}
                                            value={undefined !== editRuleDetails && editRuleDetails.document_id}
                                            optionsList={apiFormatState2}
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div class="row mb-3 mt-2 d-flex justify-content-center">
                                    <div className="col-md-12">
                                        <InputBox
                                            fullWidth
                                            label="*Rule Name"
                                            id="ruleName"
                                            name="_rule_name"
                                            value={undefined !== editRuleDetails && editRuleDetails.rule_name}
                                            onChange={(e) => handleRuleName(e)}
                                            disabled={isButtons ? false : true}
                                        />
                                    </div>
                                </div>
                                <div class="row mb-3 mt-2 d-flex justify-content-center">
                                    <div className="col-md-12">
                                        <InputBox
                                            fullWidth
                                            multiline
                                            rows={2}
                                            label="*Rule Description"
                                            id="ruleDescription"
                                            name="_description"
                                            value={undefined !== editRuleDetails && editRuleDetails.description}
                                            onChange={(e) => handleRuleDescription(e)}
                                            disabled={isButtons ? false : true}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <CheckboxComp
                                        label="Default Rule"
                                        isChecked={isDefaultRule}
                                        onChange={handleDefaultRuleCheckBox}
                                        disabled={isButtons ? false : true}
                                    />
                                </div>

                                <div className="mt-2">
                                    {isButtons && <RadioButton
                                        optionsList={radioOptions}
                                        selectedValue={radioSelection}
                                        handleChange={(event) => OnRadioChange(event)}
                                    />}
                                </div>
                                {/* <hr /> */}

                                {(isRule == "custom" || isRule == "sprule" || isRule == "lambdacustom" || radioSelection == 1 || radioSelection == 3) &&
                                    <div className="row mt-3">
                                        <div className="col-md-6">
                                            <SelectInput
                                                margin="0"
                                                selectLabel="*Parameter"
                                                name="_documetkeys_id"
                                                value={customRule}
                                                onChange={(e) => handleCustomRule(e)}
                                                // optionsList={customRuleList}
                                                optionsList={parameterFilesList}
                                            />
                                        </div>
                                    </div>
                                }
                            </AccordionDetails>
                        </Accordion>

                        {/* enter document spec */}

                        <div className="container"
                            style={{ display: "none" }}
                        >

                            <div className="row">
                                <div className="col mb-3">
                                    <h6>
                                        Document Rules
                                    </h6>
                                </div>
                            </div>

                            <div className="">
                                <select
                                    name="_parameter_id"
                                    onChange={(e) => handleParameterValue(e)}
                                    class="form-control"
                                    value={undefined !== editRuleDoc && editRuleDoc.parameter_id}
                                >

                                    {/* <option value={editRuleDoc.parameter_id} disabled selected hidden></option> */}
                                    {
                                        apiDetailState.map((val) =>
                                            // const { _parameter_id, _paramname } = val;
                                            <option value={val._parameter_id}>{val._paramname}</option>
                                        )
                                    }
                                </select>

                                <div className="mt-3 mb-3">
                                    <input
                                        type="text"
                                        name="_paramvalue_doc"
                                        // value={inputData._paramvalue_doc}
                                        value={undefined !== editRuleDoc && editRuleDoc.paramvalue}
                                        // onChange={(e) => handleInput(e)}
                                        onChange={(e) => handleparamDocValue(e)}
                                        class="form-control"
                                        placeholder="Value"
                                    ></input>
                                </div>
                                {/* <div className="d-flex justify-content-center">
                                    <button
                                        // type="submit"
                                        disabled={(parameterValue !== "") && (paramDocValue !== "") ? false : true}
                                        onClick={handleAfterDocSpec}
                                        class="float-start btn btn-primary"
                                    >
                                        Next
                                    </button>
                                </div> */}
                            </div>
                            {/* <hr /> */}
                        </div>


                        {/* ----------------- */}

                        {(isRule == "rule" || isRule == "lambdarule" || radioSelection == 2) &&
                            <div id="hideForCustom">
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        {/* Document headers */}
                                        <p className="heading-popup">Set Document headers</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div>
                                            <div className="row justify-content-end">
                                                <div className="col-sm-6 mt-2">
                                                    {/* <h6>Document header</h6> */}
                                                </div>
                                                <div className="col-sm-6 mt-2 d-flex justify-content-end">
                                                    <div style={{ marginRight: "10px" }}>
                                                        {appObjects["Delete All"] && isButtons && <button
                                                            // style={{ padding: "0 5px" }}
                                                            // onClick={onSubmitRequestApi}
                                                            onClick={() => handleDeleteAllField("Doc")}
                                                            class="btn btn-danger btn-sm"
                                                            disabled={rowDataForDoc.length == 0 ? true : false}
                                                        >
                                                            Delete All
                                                        </button>}
                                                    </div>
                                                    <div style={{ marginRight: "10px" }}>
                                                        {appObjects["Add"] && isButtons && <button
                                                            // style={{ padding: "0 5px" }}
                                                            // onClick={onSubmitRequestApi}
                                                            onClick={handleAddFieldInEditForDoc}
                                                            class="btn btn-primary btn-sm"
                                                        // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                                                        >
                                                            {/* <BsPlus />  */}
                                                            Add
                                                        </button>}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* editable keys for doc header AppliedRulesList start */}

                                            <div
                                                ref={docContainerRef}
                                                className="row mt-2"
                                                style={{
                                                    height: rowDataForDoc.length > 2 ? "9rem" : "",
                                                    overflowY: "auto", overflowX: "hidden"
                                                }}
                                            >
                                                {console.log("rowDataForDoc_", rowDataForDoc)}
                                                {rowDataForDoc.length > 0 ?
                                                    rowDataForDoc.map((obj, inx) => (
                                                        <AppliedRulesList
                                                            isButtons={isButtons}
                                                            isEditable={!obj.isEnabledForDoc}
                                                            followUpId={obj.docspec_id}
                                                            onEditClicked={onEditClickedForDoc}
                                                            onDeleteClicked={onDeleteClickedForDoc}
                                                            onInputChange={onInputChangeForDoc}
                                                            onEnterNewItem={onEnterNewItemForDoc}
                                                            onAddNewItem={handleAddNewItemForDoc}
                                                            handleSeqNo={handleSeqNoForDoc}
                                                            newItem={newKeyForDoc}
                                                            onSaveClicked={updateRecordForDoc}
                                                            keyRulesList={docRulesList}
                                                            paramRulesList={paramRulesList}
                                                            ruleType={obj.documetkeys_id}
                                                            paramType={obj.parameter_id}
                                                            paramValue={obj.paramvalue}
                                                            sequenceNo={obj.seq_no}
                                                            updateKeyRules={updateDocRules}
                                                            updateParamRules={updateParamRulesForDoc}
                                                            inx={inx}
                                                            creatableIsDisabled={false}
                                                            showSaveButton={!obj.isEnabledForDoc}
                                                            isEnabled={obj.isEnabledForDoc}
                                                        // keyRulesListSet={setKeyRulesList}
                                                        />
                                                    )) :
                                                    <div> No records to show </div>
                                                }
                                                {/* <div id="bottom-of-list" ref={ref}></div> */}
                                                {/* <div ref={scrollHere}></div> */}

                                            </div>

                                            {/* editable keys AppliedRulesList end */}

                                            {/* <hr /> */}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                                {/* ---------------- */}



                                {/* enter key spec */}
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <p className="heading-popup">Set Key Specifications</p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div
                                        // className="container"
                                        // style={{ zIndex: "930 !important" }}
                                        >
                                            <div className="row justify-content-end">
                                                <div className="col-sm-6">
                                                    {/* <h6>Keys Spec</h6> */}
                                                </div>
                                                <div className="col-md-6 d-flex justify-content-end">
                                                    <div style={{ marginRight: "10px" }}>
                                                        {appObjects["Delete All"] && isButtons && <button
                                                            // style={{ padding: "0 5px" }}
                                                            // onClick={onSubmitRequestApi}
                                                            onClick={() => handleDeleteAllField("Key")}
                                                            class="btn btn-danger btn-sm"
                                                            disabled={rowData.length == 0 ? true : false}
                                                        >
                                                            Delete All
                                                        </button>}
                                                    </div>
                                                    <div style={{ marginRight: "10px" }}>
                                                        {appObjects["Add"] && isButtons && <button
                                                            // style={{ padding: "0 5px" }}
                                                            // onClick={onSubmitRequestApi}
                                                            onClick={handleAddFieldInEdit}
                                                            class="btn btn-primary btn-sm"
                                                        // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                                                        >
                                                            {/* <BsPlus />  */}
                                                            Add
                                                        </button>}
                                                    </div>
                                                </div>

                                                {/* <div className="col-sm-2 mt-2 d-flex justify-content-end">
                                    <div style={{ marginRight: "10px" }}>
                                        <button
                                            // style={{ padding: "0 5px" }}
                                            onClick={onSubmitRequestApi}
                                            class="btn btn-danger btn-sm"
                                            // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                                            disabled={keySpecData.length > 0 ? false : true}
                                        // disabled={keySpecData.some((obj) => (obj._documetkeys_id === "" || obj._parameter_id === "" || obj._paramvalue === "")) ? true : false}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div> */}

                                            </div>

                                            {/* editable keys AppliedRulesList start */}

                                            <div
                                                ref={keyContainerRef}
                                                className="row mt-2"
                                                style={{
                                                    // height: rowData.length > 3 ? "9rem" : "",
                                                    height: rowData.length > 2 ? "18rem" : "",
                                                    overflowY: "auto", overflowX: "hidden"
                                                }}
                                            >

                                                {/* <div className="col-sm-3">
                                    {rowData.length > 0 && "Keys"}
                                </div>
                                <div className="col-sm-4">
                                    {rowData.length > 0 && "Parameter"}
                                </div>
                                <div className="col-sm-3">
                                    {rowData.length > 0 && "Value"}
                                </div> */}

                                                {console.log("rowData_", rowData)}

                                                {rowData.length > 0 ?
                                                    rowData.map((obj, inx) => (
                                                        <AppliedRulesList
                                                            isButtons={isButtons}
                                                            isEditable={!obj.isEnabled}
                                                            followUpId={obj.ruleskeys_id}
                                                            // ruleskeysid={obj["ruleskeys_id"]}
                                                            onEditClicked={onEditClicked}
                                                            onDeleteClicked={onDeleteClicked}
                                                            onInputChange={onInputChange}
                                                            handleSeqNo={handleSeqNo}
                                                            onEnterNewItem={onEnterNewItemForKey}
                                                            onAddNewItem={handleAddNewItemForKey}
                                                            newItem={newKeyForKey}
                                                            onSaveClicked={updateRecord}
                                                            keyRulesList={keyRulesList}
                                                            paramRulesList={paramRulesList}
                                                            // ruleType={obj.key}
                                                            ruleType={obj.documetkeys_id}
                                                            // paramType={obj.paramname}
                                                            paramType={obj.parameter_id}
                                                            paramValue={obj.paramvalue}
                                                            sequenceNo={obj.seq_no}
                                                            // optionType={optionType}
                                                            updateKeyRules={updateKeyRules}
                                                            updateParamRules={updateParamRules}
                                                            inx={inx}
                                                            creatableIsDisabled={false}
                                                            showSaveButton={!obj.isEnabled}
                                                            isEnabled={obj.isEnabled}
                                                            keyRulesListSet={setKeyRulesList}
                                                        />
                                                    )) :
                                                    <div> No records to show </div>
                                                }
                                                {/* <div id="bottom-of-list" ref={ref}></div> */}
                                                {/* <div ref={scrollHere} id="box"></div> */}
                                                <div ref={scrollToRef}></div>
                                                {/* <div ref={containerRef}></div> */}

                                            </div>

                                            {/* editable keys AppliedRulesList end */}

                                            {/* <div className="mt-3"
                            >
                                {keySpecData.map((obj, index) => (
                                    <div key={index} className="row mt-2">
                                        <div className="col-sm-2 mt-2">
                                            <select
                                                style={{ height: "28px" }}
                                                // id={obj.ruleskeys_id}
                                                // value={newKeys}
                                                // onChange={(e) => handleNewKeys(e)}
                                                value={obj._documetkeys_id}
                                                onChange={(e) => { handleSelectKey(e, index) }}

                                            >
                                                <option value={""} disabled selected hidden>{"keys"}</option>
                                                {documentKeysList.map((val, inx) => {
                                                    const { _documetkeys_id, _key } = val;
                                                    return <option key={inx} value={_documetkeys_id}>{_key}</option>;
                                                })}
                                            </select>
                                        </div>

                                        <div className="col-sm-3 mt-2">
                                            <select
                                                style={{ height: "28px" }}
                                                // id={obj.ruleskeys_id}
                                                // value={newParamNames}
                                                // onChange={(e) => handleNewParamNames(e)}
                                                value={obj._parameter_id}
                                                onChange={(e) => { handleSelectParameters(e, index) }}

                                            >
                                                <option value={""} disabled selected hidden>{"Parameters"}</option>
                                                {parameterRulesList.map((val, inx) => {
                                                    const { _parameter_id, _paramname } = val;
                                                    return <option key={inx} value={_parameter_id}>{_paramname}</option>;
                                                })}
                                            </select>
                                        </div>

                                        <div className="col-sm-5 mt-2">
                                            <input
                                                style={{ height: "28px" }}
                                                type="text"
                                                name="newValueNo"
                                                className="w-50"
                                                // id={"docKeys"}
                                                // value={newValueNo}
                                                // onChange={(e) => handleNewValueNo(e)}
                                                value={obj._paramvalue}
                                                onChange={(e) => { handleKeyParamValue(e, index) }}

                                                class="form-control"
                                                placeholder="value"

                                            />
                                        </div>

                                        <div className="col-sm-2 mt-2">
                                            <div style={{ marginRight: "10px" }}>
                                                <button
                                                    // style={{ padding: "0 5px" }}
                                                    onClick={() => handleDeleteField(index)}
                                                    class="btn btn-danger btn-sm"
                                                // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                                                >
                                                    <BsFillTrashFill />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div> */}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        }
                    </div>
                </div >
            </Modal>
        </>
    );
}

export default AdminEditNewRule;
