import React, { useEffect, useState } from "react";

import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import env from "../../../../Services/Environment";
import { RequestAPI } from "../../../../Services/UserServices";
import { getAllDocFormats, getApiPurpose, getRules } from "../../../../Services/AdminServices";
import Loader from "../../../../utils/Loader/Loader";
import { Modal } from "antd";
import { messages } from "../../../Messages";
import InputBox from "../../../Reusable_Components/InputBox";
import SelectInput from "../../../Reusable_Components/SelectInput";
import BasicButton from "../../../Reusable_Components/Button";
import ErrorCatch from "../../../Reusable_Components/ErrorCatch";
// import { inputCSS } from "react-select/dist/declarations/src/components/Input";

function AddApiRequest({ isModalOpen, handleOk, handleCancel }) {
  const [apiFormatState, setApiFormatState] = useState([]);
  const [apiDetailState, setApiDetailState] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');

  const [loading, setLoading] = useState(false)
  const [error,setError] = useState()
  const [ruleID, setRuleID] = useState("")
  const [rulesList, setRulesList] = useState([])
  const [ruleFormat, setRuleFormat] = useState([])
  const [ruleType, setRuleType] = useState({
    name: "Select a Rule Type",
    value: ""
  })

  const email = localStorage.getItem("Logindata");
  const initialValues = {
    app_name: "",
    email: localStorage.getItem("Logindata"),
    purposedataconsum: "",
    requestor_name: "",
    api_id: "",
    rule_id: "",
    docid: "",
    // _api_format_id: "",
    _api_format_id: 1,
  }
  const [docid, setDocid] = useState(1)
  const [inputData, setInputData] = useState(initialValues);

  useEffect(() => {
    if (isModalOpen) {
      setInputData(initialValues)
      getRuleType(email,1)
    }
  }, [isModalOpen])

  const fetchRuleListByApiDetails = (apiID) => {
    let finalArr = []
    finalArr = rulesList.filter((obj) => {
      if (apiID == 9) {
        return obj.ruleType == "rule"
      }
      else if (apiID == 10) {
        return obj.ruleType == "lambda"
      }
    })
    console.log("fetchRuleListByApiDetailsarr", finalArr)
    setRuleFormat(finalArr)
  }

  const getRuleType = async (email, docid) => {
    setLoading(true)

    // let {docid} = initialValues
    try {
      let response = await getRules(email, docid)
      console.log("getRules", response.data.data);
      if (response.status == 200) {
        let finalArr = []
        response.data.data.map((obj) => {
          if (obj._rulestype !== null) {
            const trimmedRulestype = obj._rulestype.trim()

            // if (obj._spec_status_name == "Approved" && trimmedRulestype !== "custom") {
              if (obj._spec_status_name == "Approved") {
              finalArr.push({
                name: obj._rule_name,
                value: obj._rule_id,
                ruleType: trimmedRulestype
              })
            }
          }

        })
        // setRuleFormat(response.data.data);
        setRulesList(finalArr)
        setRuleFormat(finalArr)
      }

    } catch (error) {
      console.log("getRuleserror", error);
      setError(error)
    }
    setLoading(false)
  };



  const getAPIFormat = async () => {
    try {
      let response = await getAllDocFormats()
      console.log("apiformatresponse", response)
      if (response.status == 200) {
        setApiFormatState(response.data.data);
      }

    } catch (error) {
      console.log("apiformaterror", error);
      setError(error)
    }
  };

  console.log("apiFormatState", apiFormatState)

  const FetchApiDetails = async () => {
    setLoading(true)
    try {
      let response = await getApiPurpose()
      console.log("apidetailsresponse", response)
      if (response.status == 200) {
        let finalArr = []
        response.data.data.map((obj) => {
          finalArr.push({
            name: obj._api_name,
            value: obj._api_id,
            docid: obj.docid
          })
        })
        setApiDetailState(finalArr)
        // setApiDetailState(response.data.data);
      }

    } catch (error) {
      console.log("apidetailserror", error);
      setError(error)
    }
    setLoading(false)
  };

  useEffect(() => {
    getRuleType(email, docid)
  }, [docid])

  useEffect(() => {
    FetchApiDetails();
    // getRuleType()
    // getAPIFormat();

    setRuleType({
      name: "Select a Rule Type",
      value: ""
    })
  }, []);

  const handleRuleType = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
    setRuleID(value)
  };

  const handleInput = (e) => {
    console.log("handleInput", e.target.value)
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });

    if (name == "api_id") {
      console.log("ruleformat", ruleFormat)
      let docID = apiDetailState.filter((obj) => {
        if (obj.value == value) {
          return obj.docid
        }
      })[0].docid
      setDocid(docID)
      console.log("docID", docID, apiDetailState)
      setInputData({ ...inputData, [name]: value, ["rule_id"]: "", ["docid"]: docID });
      // fetchRuleListByApiDetails(value)
    }
  };

  const closeModal = () => {
    setTimeout(() => {
      handleOk()
    }, 3000)
  }

  const onSubmitRequestApi = async (e) => {
    setLoading(true)
    // e.preventDefault()
    console.log("inputdata", inputData)
    console.log("initialValues", initialValues)
    try {
      await RequestAPI(inputData)
        .then((res) => {
          if (res.status === 200) {
            toast.success(messages.requestSent, {
              //   position: "top-center",
              // autoClose: 5000,
            });

          } else {
            toast.error(messages.somethingWrong);
          }
        })
    }
    catch (error) {
      console.log("error",error)
      setInputData(initialValues);
      // toast.error(error.response.data.msg, {
      //   position: "top-right",
      //   autoClose: 5000,
      // });
      setError(error)
    }
    // closeModal()
    handleOk()
    setDocid(1)
    setLoading(false)
  };

  const handleSelect = (event) => {
    setSelectedItem(event.target.value);

  };

  return (
    <>
      <Loader loading={loading} />
      <Toaster position="top-center" reverseOrder={false} />
      <ErrorCatch err={error}/>
      <div className="container">
        <Modal
          width={"50%"}
          className="p-2"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          maskClosable={false}
          destroyOnClose
        >

          {/* <form> */}
          <div className="">
            <div class="row mb-3">
              {/* <div className="col text-center mb-3"> */}
              <p className="col text-center mb-3 heading-popup">Request API</p>
              {/* </div> */}
            </div>

            <div class="row  ">
              <div className="col-md-12 mb-3">
                {/* <input
                  type="text"
                  name="app_name"
                  value={inputData.app_name}
                  onChange={(e) => handleInput(e)}
                  class="form-control"
                  placeholder="App Name"
                /> */}

                <InputBox
                  label="*App Name"
                  name="app_name"
                  fullWidth
                  onChange={(e) => handleInput(e)}
                  value={inputData.app_name}
                />

              </div>
            </div>
            <div class="row">
              <div className="col-md-6 mb-3">
                {/* <input
                  type="text"
                  name="requestor_name"
                  value={inputData.requestor_name}
                  onChange={(e) => handleInput(e)}
                  class="form-control"
                  placeholder="Requestor Name"
                ></input> */}

                <InputBox
                  label="*Requestor Name"
                  name="requestor_name"
                  fullWidth
                  onChange={(e) => handleInput(e)}
                  value={inputData.requestor_name}
                />

              </div>
              <div className="col-md-6 mb-3">
                {/* <input
                  type="text"
                  name="email"
                  disabled={true}
                  value={inputData.email}
                  onChange={(e) => handleInput(e)}
                  class="form-control"
                  placeholder="Requestor Name"
                ></input> */}

                <InputBox
                  label="Email"
                  name="email"
                  fullWidth
                  onChange={(e) => handleInput(e)}
                  value={inputData.email}
                  disabled={true}
                />

              </div>
            </div>

            <div class="row mb-3">
              <div className="col-md-6">
                {/* <select
                  name="api_id"
                  onChange={(e) => handleInput(e)}
                  class="form-control"
                >
                  <option value={""} disabled selected hidden>{"Select Details"}</option>
                  {apiDetailState.map((val) => {
                    const { _api_id, _api_name } = val;
                    console.log("val", val)
                    return <option value={_api_id}>{_api_name}</option>;
                  })}
                </select> */}

                <SelectInput
                  margin="0"
                  selectLabel="*Details"
                  name="api_id"
                  value={inputData.api_id}
                  onChange={(e) => handleInput(e)}
                  optionsList={apiDetailState}
                />

              </div>

              <div className="col-md-6">
                {/* <select
                  name="rule_id"
                  className="form-control custom-select"
                  // value={selectedItem}
                  onChange={handleInput}
                >
                  <option value={ruleType.value} disabled selected hidden>{ruleType.name}</option>
                  {ruleFormat.map((val) => {
                    if (val._spec_status_name == "Approved") {
                      const { _rule_id, _rule_name } = val;
                      return <option value={_rule_id}>{_rule_name}</option>;
                    }

                  })}
                </select> */}

                <SelectInput
                  margin="0"
                  selectLabel="*Select Rule"
                  name="rule_id"
                  value={inputData.rule_id}
                  onChange={(e) => handleInput(e)}
                  optionsList={ruleFormat}
                />

              </div>
            </div>

            <div className="row mb-2">
              <div className="col">
                {/* <textarea
                  type="text"
                  name="purposedataconsum"
                  value={inputData.purposedataconsum}
                  onChange={(e) => handleInput(e)}
                  class="form-control"
                  placeholder="Purpose of Data Consume"
                ></textarea> */}

                <InputBox
                  label="*Purpose of Data Consume"
                  name="purposedataconsum"
                  fullWidth
                  multiline
                  rows={2}
                  onChange={(e) => handleInput(e)}
                  value={inputData.purposedataconsum}
                />

              </div>
            </div>

            <div className="row mb-3 ">
              <div className="col d-flex justify-content-center gap-3 mt-3">
                {/* <button
                  disabled={(Object.keys(inputData).some((key) => inputData[key].length == 0)) ? true : false}
                  // type="submit"
                  onClick={(e) => onSubmitRequestApi(e)}
                  class="float-start btn btn-primary"
                >
                  Submit
                </button> */}

                <BasicButton
                  text="Submit"
                  onClick={onSubmitRequestApi}
                  disabled={(Object.keys(inputData).some((key) => inputData[key].length == 0)) ? true : false}
                />

              </div>
            </div>
          </div>
          {/* </form> */}
        </Modal>
      </div>
    </>
  );
}

export default AddApiRequest;
