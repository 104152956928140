import { FaUsers, FaFolder } from "react-icons/fa";
import { useEffect, useState } from "react";
import adminavtar from "../../../Assets/Images/AdminAvtar.png";
import { useNavigate } from "react-router-dom";
import ActivityChart from "../CustomerAdmin/ChartforActivity";
import { getAllEmployee } from "../../../Services/UserServices";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "../CustomerAdmin/Employee/EmployeTabledata";
import Loader from "../../../utils/Loader/Loader";
import settings from "../../../Assets/Images/settings.png";
import { getAllDocumentCounts, getAllSalesOrder, getRequestedData, getUserRules } from "../../../Services/AdminServices";
import toast, { Toaster } from "react-hot-toast";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
import ImageSlider from "../../Reusable_Components/ImageSlider";
import CardComp from "../../Reusable_Components/CardComp";
import group from "../../../Assets/Images/Group.png";
import rules from "../../../Assets/Images/rules.png";
import file from "../../../Assets/Images/file.png";
import user from "../../../Assets/Images/user.png";

import prd1 from '../../../Assets/Images/alba_prd_1.png';
import prd2 from '../../../Assets/Images/alba_prd_2.png';
import prd3 from '../../../Assets/Images/alba_prd_3.png';
import prd4 from '../../../Assets/Images/alba_prd_4.png';

const CustomerAdmin = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false);
  const [totalUsers, setTotalUsers] = useState()
  const [totalDocument, setTotalDocument] = useState()
  const [allUsersData, setAllUsersData] = useState()
  const [allRulesData, setAllRulesData] = useState()
  const [allDocumentsData, setAllDocumentsData] = useState()
  const [allSalesOrderData, setAllSalesOrderData] = useState()
  const [companyId, setCompanyId] = useState()

  useEffect(() => {
    Alluser();
  }, [])

  // display customer table
  const Alluser = async () => {
    setLoading(true)
    try {
      await getAllEmployee(localStorage.getItem("Logindata")).then((res) => {

        let response = res.data.data;
        console.log("Employee...", res.data.data)
        const id=response[0]._comp_id;
        setCompanyId(id)
        response.map((item) => {
          if (item._status_id == 2) {
            item._status_id = "InActive";
          } else if (item._status_id == 1) {
            item._status_id = "Active";
          } else {
            item._status_id = "Pending";
          }
          return item;
        });
        response.map((item) => { });

        setData([...response]);
        setTotalUsers(response.length)
        const sortedData = response.sort((a, b) => {
          if (a._status_id.startsWith('P') && !b._status_id.startsWith('P')) {
            return -1; // a comes before b
          } else if (!a._status_id.startsWith('P') && b._status_id.startsWith('P')) {
            return 1; // b comes before a
          } else {
            return a._status_id.localeCompare(b._status_id); // alphabetical order
          }
        });
        setData(sortedData)
      });
    }
    catch (err) {
      console.log("getAllEmployeeErr", err)

      // if("response" in err){
      //   toast.error(err.response.data.message)
      // }
      // else if("message" in err){
      //   toast.error(err.message)

      // }
      // else{
      //   toast.error("Error Occurred")
      // }

      setError(err)
    }
    setLoading(false)
  };

  ///show Total Documents
  let email = localStorage.getItem("Logindata");
  const requestedData = async () => {

    try {
      let res = await getRequestedData(email);
      if (res.status == 200) {
        let resdata = res.data.data;
        setTotalDocument(resdata.length);
      }
    }
    catch (err) {
      setError(err)
    }

  };

  useEffect(() => {
    // activeAPI();
    // requestedData();
  }, []);

  console.log("68", totalDocument);
  // useEffect(() => {
  //   fetchAllDATA();
  // }, []);

  const navigate = useNavigate();
  const MovetoProfile = () => {
    navigate("/profiles");
  };

  const handleCustomersToday = () => {
    Alluser();
  };

  const handleTotalDocuments = () => {
    // fetchAllDATA();
  };

  const images = [
    prd1, prd2, prd3, prd4
  ]
  const images1 = [
    prd2, prd4, prd1, prd3
  ]
  const images2 = [
    prd4, prd3, prd2, prd1
  ]

  const AlluserData = async () => {
    const response=await getAllEmployee(localStorage.getItem("Logindata"))

    console.log(response,"_activeusers")
      let users = response?.data?.data?.length;
      setAllUsersData(users)
  
  };

  const getUserRulesList = async () => {
    setLoading(true)
    let userEmail = localStorage.getItem("Logindata")
    console.log("userEmail", userEmail)

    try {
      let res = await getUserRules(userEmail)
      console.log("getUserRulesres", res)

      if (res.status == 200) {
        let resdata = res?.data?.data?.length
        setAllRulesData(resdata)
        console.log("resdata", resdata)
      }
    }
    catch (error) {
      // toast.error(error.response.data.data, {
      //   position: "top-right",
      //   autoClose: 5000,
      // });
      setError(error)
    }
    setLoading(false)
  }

  const getAllDocumentCountsData=async()=>{
    try {
      const id=companyId;
        if(id){
          const response=await getAllDocumentCounts(id);
        console.log(response,"_getDocumentsid")
      if(response?.status==200){
        const id=response?.data?.data?.rows[0].count
        setAllDocumentsData(id)
      }else{
        console.log("err_:company id not found")
      }
        }
    } catch (error) {
      console.log(error,"error_")
    }
  }

  const getAllSalesOrderData=async()=>{
    try {
      const id=companyId;
       if(id){
        const response=await getAllSalesOrder(id);
        console.log(response,"_getsalesorder")
      if(response?.status==200){
        const id=response?.data?.data?.rows[0].count
        setAllSalesOrderData(id)
      }else{
        console.log("err_:company id not found")
      }
       }
    } catch (error) {
      console.log(error,"error_")
    }
  }

  useEffect(()=>{
    AlluserData();
    getUserRulesList();
    getAllDocumentCountsData();
    getAllSalesOrderData();
  },[companyId])

  return (
    <>
      <Loader loading={loading} />
      <Toaster position="top-center" reverseOrder={false} />
      <ErrorCatch err={error} />
      <div className="container-fluid mt-2 px-4">
        <div className="d-flex col-md-12 col-lg-12 col-sm-12">
          <div className="col-md-4 col-lg-4 col-sm-12">
            <ImageSlider images={images} className={'slidecontainer'} />
          </div>
          <div className="col-md-4 col-lg-4 px-2 col-sm-12">
            <ImageSlider images={images1} className={'slidecontainer'} />
          </div>
          <div className="col-md-4 col-lg-4 col-sm-12">
            <ImageSlider images={images2} className={'slidecontainer'} />
          </div>
        </div>
      </div>
      <div className="container-fluid mt-3 px-4">
        <div className="row justify-content-between px-3">
          <CardComp onClick={handleCustomersToday} icon={user} heading={"Active Users"} count={allUsersData} />
          <CardComp onClick={handleTotalDocuments} icon={file} heading={"Total Documents"} count={allDocumentsData} />
          <CardComp icon={rules} heading={"Number of Rules"} count={allRulesData} />
          <CardComp icon={group} heading={"Total Sales Order"} count={allSalesOrderData} />
        </div>
      </div>
      {/* <div className="container-fluid mt-3 px-4">
        <ActivityChart />
      </div> */}
    </>
  );
};

export default CustomerAdmin;
