import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

function PdfPreviewer({ file }) {
    console.log("PdfPreviewer",file)
    return (
        <div 
        style={{height:"500px"}}
        >
            {/* <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
        <Viewer fileUrl={file} />
      </Worker> */}

            <iframe
                src={file}
                style={{ width: '100%',height:"inherit" }}
                title="PDF Preview"
            ></iframe>
        </div>
    );
}
export default PdfPreviewer
