export const roleColumn = [
    {
      headerClassName: "super-app-theme--header",
      field: "role_id",
      headerName: "Role ID",
      width:70,
    },
    {
      field: "role_name",
      headerClassName: "super-app-theme--header",
      headerName: "Role Name",
      width:120
    },
  
    {
      field: "_description",
      headerClassName: "super-app-theme--header",
      headerName: "Description",
      width:250
    },
  
    {
      field: "_created_datetime",
      headerClassName: "super-app-theme--header",
      headerName: "Date",
      width:200,
    },
  
    // {
    //   field: "_designation",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Designation",
    //   width:150
    // },
  
    // {
    //   field: "_user_type",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Role",
    // },
  
    // {
    //   field: "_address",
    //   headerClassName: "super-app-theme--header",
    //   headerName: "Address",
    //   width:180
    // },
  ];
  