import React, { useState, useEffect } from "react";
// import { Form } from "react-bootstrap";
import Creatable from "react-select/creatable"
import { BsFillTrashFill } from 'react-icons/bs';
import { FaEdit } from 'react-icons/fa';
import { MdOutlineDownloadDone } from 'react-icons/md';
import { GiCancel } from 'react-icons/gi';
import SelectInputWithAdd from "../../../Reusable_Components/SelectInputWithAdd";
import SelectInputAntd from "../../../Reusable_Components/SelectInputAntd";
import InputBoxAntd from "../../../Reusable_Components/InputBoxAntd";
import { Modal } from "antd";
import CheckboxComp from "../../../Reusable_Components/CheckBox";
import RadioButton from "../../../Reusable_Components/RadioButton";
import { getSourceKeys } from "../../../../Services/AdminServices";
import { useSelector } from "react-redux";

const AppliedRulesList = (
    {
        data, id, inx, ruleskeysid, showSaveButton, followUpId, isEditable, creatableIsDisabled, onEditClicked, onSaveClicked, onInputChange, keyRulesList, paramRulesList, keyRulesListSet, paramValue, ruleType, paramType,
        updateKeyRules, updateParamRules, isEnabled, onDeleteClicked,
        onEnterNewItem, newItem, onAddNewItem, sequenceNo, handleSeqNo,isButtons
    }
) => {

    const customStyles = {
        control: base => ({
            ...base,
            height: 30,
            minHeight: 30,
            fontSize: 15,
            alignContent: "center",
            backgroundColor: "#ffffff",
            zIndex: 30000
        })
    }

    console.log("data", data)
    console.log("keyRulesList", keyRulesList, "paramRulesList", paramRulesList)
    //     let keyRulesListName = []
    //     useEffect(()=>{
    // keyRulesList.map((obj)=>{
    //     keyRulesListName.push({
    //         label: obj.label,
    //         value: obj.label 
    //     })
    // })
    //     },[keyRulesList])
    const [rulesData, setRulesData] = useState(data)

    // expression changes
    const [expressionModal, setExpressionModal] = useState(false)
    const [selectedKeyFrom, setSelectdeKeyFrom] = useState("")
    const [selectedKeyTo, setSelectdeKeyTo] = useState("")
    const [assignValue, setAssignValue] = useState("")
    const [isSubString, setIsSubString] = useState(false)
    const [isConcat, setIsConcat] = useState(false)
    const [isMap, setIsMap] = useState(false)
    const [isTrim, setIsTrim] = useState(false)
    const [isInt, setIsInt] = useState(false)
    const [expression, setExpression] = useState("")
    const [fromValue, setFromValue] = useState("")
    const [toValue, setToValue] = useState("")
    const [selectedParam, setSelectedParam] = useState()

    const [concatRadioOption, setConcatRadioOption] = useState([
        {
            label: "Suffix",
            value: 1,
            status: true
        },
        {
            label: "Prefix",
            value: 2,
            status: false
        },
    ])
    const [concatRadioSelection, setConcatRadioSelection] = useState(1)
    const [selectedConcattKey, setSelectedConcattKey] = useState([])
    const [isAddConst, setIsAddConst] = useState(false)
    const [isConstValue, setIsConstValue] = useState(true)
    const [sourceKeyLists, setSourceKeyLists] = useState([])

    // uam role object start
    const userDetails = useSelector((state) => state)
    const appsubmodulename = "Services"
    const appfunctionalityname = "Rules"
    const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
    const [appObjects, setAppObjects] = useState(
        {
            "Delete": false,
            "Edit spec": false,
        }
    )
    console.log("userDetails", userDetails)

    const updateAppObjects = (arr) => {
        let objectsArray = []
        arr.map((obj) => {
            console.log("updateButtons", obj)
            if (obj.objecttypename == "button") {
                objectsArray.push(obj)
            }
        })
        const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
            acc[appobjectname] = true;
            return acc;
        }, {});
        setAppObjects({ ...appObjects, ...finalObject })
        console.log("objectsArray", objectsArray)
        console.log("finalObject", finalObject)
    }

    useEffect(() => {
        console.log("appObjects", appObjects)
    }, [appObjects])

    useEffect(() => {
        console.log("userRoleObjects", userRoleObjects)
        const filteredList = []
        userRoleObjects.filter((obj) => {
            if (obj.appsubmodulename == appsubmodulename && obj.appfunctionalityname == appfunctionalityname) {
                filteredList.push(obj)
            }
        })

        console.log("filteredList", filteredList)
        updateAppObjects(filteredList)

    }, [])

    // uam role object end

    // const fetchRulesList = () => {
    //     let finalArr = []
    //     data[0].documentKeys.map((obj) => {
    //         let item = {
    //             "label": obj._key,
    //             "value": obj._documetkeys_id
    //         }
    //         finalArr.push(item)
    //     })
    //     console.log("finalarr",finalArr)
    //     setKeyRulesList(finalArr)
    // }

    useEffect(() => {
        fetchSourceKeys()
    }, [])

    const fetchSourceKeys = async () => {

        let filterRawData = []
        try {
            const response = await getSourceKeys()
            console.log("response_source", response)
            let res = response.data
            let data = res.data

            data.map((obj) => {
                filterRawData.push({
                    "label": obj?.key,
                    "value": obj?.documetkeys_id,
                    "type": obj?.key_type,
                })
            })
            setSourceKeyLists(filterRawData)
            console.log("getRawDataDetails", filterRawData, data);
        } catch {
            console.log("catch error")
        }
    }

    const handleIDNo = (e) => {
        const { name, value } = e.target;
        let val = e.target.value.replace(/[A-Za-z' `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/g, '');
        // setInputData({ ...inputData, [name]: val });
        // setIDNo(val)
        setRulesData({ ...data, [e.target.id]: e.target.value })
    };

    const handleKeyRules = (e) => {
        console.log("handleKeyRules", e)
        updateKeyRules(e, followUpId)
    }

    const handleParamRules = (e) => {
        console.log("handleParamRules", e)
        if (e == 26) {
            setIsSubString(true)
            setExpressionModal(true)
            // setInx(index)
        } else if (e == 27) {
            setIsConcat(true)
            setExpressionModal(true)
            // setInx(index)
        } else if (e == 28) {
            setIsMap(true)
            setExpressionModal(true)
            // setInx(index)
        } else if (e == 30) {
            setIsTrim(true)
            setExpressionModal(true)
        } else if (e == 31) {
            setIsInt(true)
            setExpressionModal(true)
        }
        updateParamRules(e, followUpId)
    }

    const handleInputChange = (e) => {
        onInputChange(e.target.value, followUpId)
        console.log("handleInputChange", e.target.value)
    }

    const handleSeqChange = (e) => {
        let value = parseInt(e.target.value)
        value = isNaN(value) ? "" : value
        console.log("value_", value)
        handleSeqNo(value, followUpId)
        console.log("handleInputChange", e.target.value)
    }

    const handleEditClicked = () => {
        onEditClicked(followUpId, isEditable)
    }

    const handleDeleteClicked = () => {
        onDeleteClicked(followUpId)
    }

    const handleSaveClicked = () => {
        onSaveClicked(followUpId)
    }

    const handleNameChange = (e) => {
        console.log("handleNameChange", e)
        onEnterNewItem(e.target.value, followUpId)
    }

    const handleAddItem = (e) => {
        onAddNewItem()
    }

    const handleSelectedKeyFrom = (e) => {
        console.log("selectedKey", e)
        // let newKeySpecData = [...keySpecData]
        // newKeySpecData[inx]._documetkeys_id = e.target.value

        setSelectdeKeyFrom(e)
    }

    const handleSelectedKeyTo = (e) => {
        console.log("selectedKey", e)
        setSelectdeKeyTo(e)
    }

    const handleExpCancel = () => {
        console.log("cancel")
        setSelectdeKeyFrom("")
        setSelectdeKeyTo("")
        setExpression("")
        setFromValue("")
        setToValue("")
        setAssignValue("")
        setExpressionModal(false)
        setIsSubString(false)
        setIsConcat(false)
        setIsMap(false)
        setIsInt(false)
        setIsTrim(false)
    }

    const onSubmitExpression = () => {
        console.log("submit_")
        let valueconcat
        // let filterobject = paramRulesList.filter((obj) => obj.type == "key_wise")
        let filterKey = sourceKeyLists.filter((obj) => obj.value == selectedKeyFrom)
        let filterToKey = sourceKeyLists.filter((obj) => obj.value == selectedKeyTo)
        console.log("newKeySpecData_", filterKey, filterToKey, isConcat)

        if (isSubString) {
            valueconcat = filterKey[0].label + ',' + fromValue + ',' + toValue
        } else if (isConcat) {
            console.log("concatValue_", valueconcat, keyRulesList);

            let filterToKeyconcat = sourceKeyLists.filter((obj) => selectedConcattKey.includes(obj.value))
            console.log("filterobject_", filterToKeyconcat)
            let finalfilter = []
            selectedConcattKey.map((item) => {
                filterToKeyconcat.map((obj) => {
                    if (item == obj.value) {
                        finalfilter.push(obj)
                    }
                })
            })
            console.log("finalfilter_", finalfilter);
            // valueconcat = assignValue ? filterKey[0].label + ',' + assignValue : filterKey[0].label + ',' + filterToKey[0].label
            valueconcat = finalfilter.map((item) => { return item.label }).join(',')
            if (isAddConst) {
                valueconcat = (concatRadioSelection == 1) ? valueconcat + ',' + assignValue : assignValue + ',' + valueconcat
                setIsConstValue(true)
            } else {
                setIsConstValue(false)
            }
            console.log("valueconcat_", valueconcat)
            // valueconcat = assignValue ? filterKey[0].label + ',' + assignValue : filterKey[0].label + ',' + filterToKey[0].label
        } else {
            valueconcat = assignValue ? assignValue : filterToKey[0].label
        }
        // setParamValue(valueconcat)
        // console.log("concatValue", valueconcat);
        // let newKeySpecData = [...keySpecData]
        // newKeySpecData[inx]._paramvalue = valueconcat
        // setKeySpecData(newKeySpecData)
        onInputChange(valueconcat, followUpId)
        handleExpCancel()
    }

    const handleInputClick = () => {
        console.log("inputClick_", isConcat, isMap, paramValue, isSubString, paramType, keyRulesList, paramRulesList)
        let filterobject = paramRulesList.filter((obj) => obj.type == "key_wise")

        let splitData
        if (paramType == 26) {
            splitData = paramValue.split(',')
            console.log("newKey_", splitData);
            let filterKey = keyRulesList.filter((obj) => obj.label == splitData[0])
            console.log("filterKey_", filterKey[0].value)
            setSelectdeKeyFrom(filterKey[0].value)
            setFromValue(splitData[1])
            setToValue(splitData[2])
            setIsSubString(true)
            setExpressionModal(true)
        } else if (paramType == 27) {
            // splitData = paramValue.split(',')
            // let filterKey = keyRulesList.filter((obj) => obj.label == splitData[0])
            // let filterToKey = keyRulesList.filter((obj) => obj.label == splitData[1])
            // setSelectdeKeyFrom(filterKey[0].value)
            // if (filterToKey.length > 0) {
            //     setSelectdeKeyTo(filterToKey[0].value)
            // } else {
            //     setAssignValue(splitData[1])
            // }
            // setIsConcat(true)
            // setExpressionModal(true)
            splitData = paramValue.split(',')
            splitData = splitData.filter((item) => item !== "")
            console.log("constData_", splitData.length, splitData, assignValue, filterobject);

            let filterkey = []
            splitData.map((item) => {
                keyRulesList.map((obj) => {
                    if (obj.label == item) {
                        filterkey.push(obj.value)
                    }
                })
            })

            console.log("filterKeyFinal_", filterkey, concatRadioSelection, isConstValue)
            let constData
            if (isConstValue) {
                setIsAddConst(true)
                if (concatRadioSelection == 1) {
                    constData = splitData[splitData.length - 1]
                } else {
                    constData = splitData[0]
                }
                console.log("constData_", constData);
            }
            setSelectedConcattKey(filterkey)
            setAssignValue(constData)
            setIsConcat(true)
            setExpressionModal(true)
        } else if (paramType == 28) {
            splitData = paramValue.split(',')
            let filterToKey = keyRulesList.filter((obj) => obj.label == splitData[0])
            if (filterToKey.length > 0) {
                setSelectdeKeyTo(filterToKey[0].value)
            } else {
                setAssignValue(splitData[0])
            }
            setIsMap(true)
            setExpressionModal(true)
        } else if (paramType == 30) {
            splitData = paramValue.split(',')
            let filterToKey = keyRulesList.filter((obj) => obj.label == splitData[0])
            if (filterToKey.length > 0) {
                setSelectdeKeyTo(filterToKey[0].value)
            }
            setIsTrim(true)
            setExpressionModal(true)
        } else if (paramType == 31) {
            splitData = paramValue.split(',')
            let filterToKey = keyRulesList.filter((obj) => obj.label == splitData[0])
            if (filterToKey.length > 0) {
                setSelectdeKeyTo(filterToKey[0].value)
            }
            setIsInt(true)
            setExpressionModal(true)
        }
        else {
            return
        }
    }

    const onConcatRadioChange = (e) => {
        console.log('radio_checked', e.target.value);
        let value = e.target.value

        let updatedStatusList = concatRadioOption.map((item) => {
            if (item.value == value) {
                item.status = true
            }
            else {
                item.status = false
            }
            return item
        })
        setConcatRadioSelection(value)
        setConcatRadioOption(updatedStatusList)
    };
    const handleIsConstant = (e) => {
        setIsAddConst(e.target.checked)
        setAssignValue("")
        setConcatRadioSelection(1)
    }
    const handleConcatSelected = (e) => {
        console.log("selectedKey", e)
        setSelectedConcattKey(e)
    }


    return (
        <div className="row mt-2"
        // style={{borderStyle}}
        >

            <div className="col-md-4 mt-2">

                {/* <SelectInputWithAdd
                    disabled={isEnabled}
                    selectInputId={followUpId}
                    selectInputValue={ruleType}
                    handleSelectInput={(e) => handleKeyRules(e)}
                    onNameChange={(e) => handleNameChange(e)}
                    optionsList={keyRulesList}
                    newItem={newItem}
                    onAddItem={(e) => handleAddItem(e)}
                    placeholder="Keys"
                /> */}

                <SelectInputAntd
                    value={ruleType}
                    onChange={(e) => handleKeyRules(e)}
                    optionsList={keyRulesList}
                    placeholder="Keys"
                    disabled={isEnabled}
                />

                {/* <select
                style={{height: "28px"}}
                    disabled={isEnabled}
                    label={inx == 0 ? "Keys" : ""}
                    // as="select"
                    // onChange={(e)=>handleKeys(e)}
                    // id={obj._ruleskeys_id}
                    // value={obj._keys}
                    id={followUpId}
                    value={ruleType}
                    onChange={(e) => handleKeyRules(e)}
                >
                    <option value="" disabled selected hidden>{"Key"}</option>
                    {keyRulesList.map((val) => {
                        const { value, label } = val;
                        return <option value={value}>{label}</option>;
                    })}
                </select> */}

                {/* <Creatable
                    styles={customStyles}
                    id={followUpId}
                    // isEditable={false}
                    // className={inx !== 0 && "mt-4"}
                    label={inx == 0 ? "Keys" : ""}
                    onChange={(e) => handleKeyRules(e)}
                    value={{ "label": ruleType }}
                    // options={keyRulesList}
                    options={keyRulesList}
                    // isDisabled={creatableIsDisabled}
                    isDisabled={isEnabled}
                /> */}

            </div>
            <div className="col-md-2 mt-2">

                <SelectInputAntd
                    disabled={isEnabled}
                    id={followUpId}
                    value={paramType}
                    onChange={(e) => handleParamRules(e)}
                    optionsList={paramRulesList}
                    placeholder="Parameter"
                />

                {/* <select
                    style={{ height: "28px", width: "100%" }}
                    disabled={isEnabled}
                    // id={obj._ruleskeys_id}
                    // value={obj._keys}
                    id={followUpId}
                    value={paramType}
                    onChange={(e) => handleParamRules(e)}

                >
                    <option value="" disabled selected hidden>{"Parameter"}</option>
                    {paramRulesList.map((val) => {
                        const { value, label } = val;
                        return <option value={value}>{label}</option>;
                    })}
                </select> */}

                {/* <Creatable
                    styles={customStyles}
                    id={followUpId}
                    // isEditable={false}
                    // className={inx !== 0 && "mt-4"}
                    label={inx == 0 ? "Params" : ""}
                    onChange={(e) => handleParamRules(e)}
                    value={{ "label": paramType }}
                    options={paramRulesList}
                    // isDisabled={creatableIsDisabled}
                    isDisabled={isEnabled}

                /> */}

            </div>

            <div className="col-md-2 mt-2">
                {/* <p>{obj._paramvalue} </p> */}

                <InputBoxAntd
                    name="_paramvalue"
                    id={followUpId}
                    value={paramValue}
                    onChange={(e) => handleInputChange(e)}
                    // disabled={isEnabled}
                    disabled={isEnabled || (paramType == 12 || paramType == 29 ? true : false)}
                    placeholder={paramType == 29 ? "Blank" : "Values"}
                    onClick={handleInputClick}
                />
                {/* <input
                    style={{ height: "30px" }}
                    type="text"
                    name="_paramvalue"
                    className="form-control"
                    // id={obj._ruleskeys_id}
                    id={followUpId}
                    value={paramValue}
                    onChange={(e) => handleInputChange(e)}
                    class="form-control"
                    disabled={isEnabled}
                /> */}

            </div>
            <div className="col-md-2 mt-2">
                <InputBoxAntd
                    name="seq"
                    disabled={isEnabled}
                    value={sequenceNo}
                    onChange={(e) => handleSeqChange(e)}
                    placeholder="Sequence No"
                    autocomplete={'off'}
                    type={"Number"}
                // onClick={() => handleInputClick(index, false, obj._paramvalue)}
                />
            </div>

            {showSaveButton &&
                <div className="col-md-1 mt-2" id={id}>
                    <button
                        style={{ paddingTop: "2px" }}
                        id={id}
                        className="btn btn-danger btn-sm"
                        onClick={handleSaveClicked}
                    >
                        {/* Save */}
                        <MdOutlineDownloadDone />
                    </button>
                </div>
            }

            <div className="col-md-1 mt-2" id={id}>
                {appObjects["Edit spec"] && isButtons && <button
                    style={{ paddingTop: "2px" }}
                    id={id}
                    className="btn btn-danger btn-sm"
                    onClick={handleEditClicked}
                >
                    {/* {isEditable ? "Cancel" : "Edit"} */}
                    {isEditable ? <GiCancel /> : <FaEdit />}
                </button>}
            </div>

            {!isEditable &&
                <div className="col-sm-1 mt-2 ml-1" id={id}>
                    {appObjects["Delete"] && isButtons && <button
                        style={{ paddingTop: "2px" }}
                        id={id}
                        className="btn btn-danger btn-sm"
                        onClick={handleDeleteClicked}
                    >
                        {/* Delete */}
                        <BsFillTrashFill />
                    </button>}
                </div>
            }

            <Modal
                className="p-2"
                // zIndex={900}
                open={expressionModal}
                style={{ top: 60, minWidth: 500, maxWidth: 700 }}
                // open={isSetRuleModalOpen}
                onCancel={handleExpCancel}
                footer={null}
                maskClosable={false}
            >
                <div className="container">
                    <div className="row">
                        <div className="col text-center mb-2">
                            <p className="heading-popup">{isSubString ? "Substring" : isConcat ? "Concatenation" : "Mapping"}</p>
                        </div>
                    </div>
                    {/* <div className="col-md-12">
              <SelectInput
                margin="0"
                selectLabel="*Expression"
                name="Expression"
                value={expression}
                onChange={(e) => handleExpression(e)}
                optionsList={expressionList}
              />
            </div> */}
                    {isSubString && < div className="mt-3">
                        <div className="px-2">
                            {/* <SelectInputAntd
                                value={selectedKeyFrom}
                                onChange={(e) => handleSelectedKeyFrom(e)}
                                optionsList={paramRulesList.filter((obj) => obj.type == "key_wise")}
                                placeholder="Select key to substring"
                            /> */}
                            <SelectInputAntd
                                value={selectedKeyFrom}
                                onChange={(e) => handleSelectedKeyFrom(e)}
                                optionsList={sourceKeyLists}
                                placeholder="Select key to substring"
                            />
                        </div>
                        <div className="d-flex col-md-12 mt-3 ">
                            <div className="col-md-6 px-2">
                                <InputBoxAntd
                                    name="fromvalue"
                                    value={fromValue}
                                    onChange={e => setFromValue(e.target.value)}
                                    placeholder="Index from"
                                    autocomplete={"off"}
                                />
                            </div>
                            <div className="col-md-6 px-2 ">
                                <InputBoxAntd
                                    name="tovalue"
                                    value={toValue}
                                    onChange={e => setToValue(e.target.value)}
                                    placeholder="Index to"
                                    autocomplete={"off"}
                                />
                            </div>
                            {/* <div>
                  <button
                    // style={{ padding: "0 5px" }}
                    // onClick={onSubmitRequestApi}
                    onClick={handleAddExp}
                    class="btn btn-primary btn-sm"
                  // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                  >
                    Add
                  </button>
                </div> */}
                        </div>
                        {/* <div className="mt-3">
                <div className="px-2">
                  <InputBoxAntd
                    value={assignValue}
                    onChange={(e) => setAssignValue(e.target.value)}
                    // optionsList={paramRulesList.filter((obj) => obj.type == "key_wise")}
                    placeholder="Assign to"
                  />
                </div>
              </div> */}
                    </div>}
                    {isConcat &&
                        <div>
                            <div>
                                <label>
                                    Select keys to concat
                                </label>
                                <SelectInputAntd
                                    value={selectedConcattKey}
                                    // onChange={(e) => handleSelectedKeyFrom(e)}
                                    onChange={(e) => handleConcatSelected(e)}
                                    optionsList={sourceKeyLists}
                                    // placeholder="Select first key to concat *"
                                    mode={"multiple"}
                                />
                            </div>
                            <div>
                                <CheckboxComp
                                    label="Add Constant"
                                    isChecked={isAddConst}
                                    onChange={handleIsConstant}
                                />
                            </div>
                            {isAddConst &&
                                <div>
                                    <div className="">
                                        <InputBoxAntd
                                            value={assignValue}
                                            onChange={(e) => setAssignValue(e.target.value)}
                                            // optionsList={documentKeysList.filter((obj) => obj.type == "key_wise")}
                                            placeholder="value"
                                        />
                                    </div>
                                    <div>
                                        <RadioButton
                                            optionsList={concatRadioOption}
                                            selectedValue={concatRadioSelection}
                                            handleChange={(event) => onConcatRadioChange(event)}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {isMap &&
                        <div>
                            {/* <div>
                  <SelectInputAntd
                    value={selectedKeyFrom}
                    onChange={(e) => handleSelectedKeyFrom(e)}
                    optionsList={paramRulesList.filter((obj) => obj.type == "key_wise")}
                    placeholder="Select key*"
                  />
                </div> */}
                            <div className="mt-3">
                                <SelectInputAntd
                                    value={selectedKeyTo}
                                    onChange={(e) => handleSelectedKeyTo(e)}
                                    optionsList={sourceKeyLists}
                                    placeholder="Select key to map"
                                />
                            </div>
                            <div className="d-flex col-md-12 justify-content-center">
                                or
                            </div>
                            <div className="">
                                <InputBoxAntd
                                    value={assignValue}
                                    onChange={(e) => setAssignValue(e.target.value)}
                                    // optionsList={paramRulesList.filter((obj) => obj.type == "key_wise")}
                                    placeholder="value"
                                />
                            </div>
                        </div>
                    }

                    {isTrim &&
                        <div>
                            <div className="mt-3">
                                <SelectInputAntd
                                    value={selectedKeyTo}
                                    onChange={(e) => handleSelectedKeyTo(e)}
                                    optionsList={sourceKeyLists}
                                    placeholder="Select key to trim"
                                />
                            </div>
                        </div>
                    }

                    {isInt &&
                        <div>
                            <div className="mt-3">
                                <SelectInputAntd
                                    value={selectedKeyTo}
                                    onChange={(e) => handleSelectedKeyTo(e)}
                                    optionsList={sourceKeyLists}
                                    placeholder="Select key to change"
                                />
                            </div>
                            <div className="mt-1" style={{ color: "red" }}>
                                Conversion to integer is only applicable to numerical data not alphanumeric data *
                            </div>
                            {/* <div className="d-flex col-md-12 justify-content-center">
                  or
                </div> */}
                            {/* <div className="mt-2">
                  <InputBoxAntd
                    value={assignValue}
                    onChange={(e) => setAssignValue(e.target.value)}
                    // optionsList={documentKeysList.filter((obj) => obj.type == "key_wise")}
                    placeholder="value"
                  />
                </div> */}
                        </div>
                    }


                    <div className="d-flex justify-content-center mt-3">
                        <button
                            // style={{ padding: "0 5px" }}
                            onClick={onSubmitExpression}
                            class="btn btn-danger btn-sm"
                        >
                            Submit
                        </button>
                    </div>

                </div>

            </Modal >
        </div>
    )
}

export default AppliedRulesList
