import { useState,useEffect } from "react";
import alba_logo from "../../../Assets/Images/alba_logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { getAdmin, getSingleuser } from "../../../Services/AdminServices";
import Loader from "../../../utils/Loader/Loader";

function NotFound() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { user, email, company_status_id } = useParams();
  const [contactMessage, setContactMessage] = useState("please contact our support team")
  
  useEffect(() => {
    console.log("NotFounddata", user, email, company_status_id)
    getUserDetails(email)
  }, [user])

  const getUserDetails = async(email)=>{
    setLoading(true)
    try{
      const response = await getSingleuser(email)
      console.log("getUserDetailsResponse",response)
      let userDetails = response.data.data[0]
      if(userDetails._user_type == "customer-user"){
        getAdminDetails(userDetails._comp_id)
      }
    }
    catch(e){
      console.log("e",e)
    }
    setLoading(false)
  }

  const getAdminDetails = async(compID)=>{
    try{
      const response = await getAdmin(compID)
      console.log("getAdminDetailsResponse",response)
      let adminDetails = response.data.response
      setContactMessage(`please contact your admin ${adminDetails.title}${adminDetails.name} at ${adminDetails.email}`)
    }
    catch(e){
      console.log("e",e)
    }
  }

  return (
    <>
    <Loader loading={loading} />
      <div className="text-center">
        <div className="page-container">
          <section className="content-section pt-3 ">
            <header >
              <img src={alba_logo} alt="Project Logo" />
            </header>
            <h2>Account Approval Required</h2>
            <p>
              Thank you for signing up! Your account is currently pending
              approval by the admin.
            </p>
            <p>Please wait for the admin to review your account information.</p>
            <p>
              If you have any questions or need further assistance, 
              {contactMessage}
              {/* please contact our support team at alba.config@alba.com.bh */}
              {/* [rapidqube.com/02225673235]. */}
            </p>
            <button onClick={() => navigate("/")} className="btn btn-primary">Go to Landing Page</button>
          </section>
        </div>
      </div>
    </>
  );
}

export default NotFound;
