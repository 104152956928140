import mail from "../../../../Assets/Images/gmail.png";
import sms from "../../../../Assets/Images/sms.png";
import bell from "../../../../Assets/Images/active.png";
export const userApiLogsColumns = [
    {
        field: "_user_id",
        headerClassName: "super-app-theme--header",
        headerName: "User ID",
        width: 100,
    },
    {
        field: "_app_name",
        headerClassName: "super-app-theme--header",
        headerName: "App Name",
        width: 200,
    },

    {
        field: "_dml_type",
        headerClassName: "super-app-theme--header",
        headerName: "DML Type",
        width: 100,
    },

    {
        field: "_requestor_name",
        headerClassName: "super-app-theme--header",
        headerName: "Requestor",
        width: 200,
    },

    {
        field: "_email",
        headerClassName: "super-app-theme--header",
        headerName: "Email",
        width: 200,
    },

    {
        field: "_purpose_data_consum",
        headerName: "Purpose",
        headerClassName: "super-app-theme--header",
        width: 250,
    },
    // {
    //     field: "_status_by",
    //     headerClassName: "super-app-theme--header",
    //     headerName: "Status",
    //     width: 130,
    // },

    {
        field: "_statusreason",
        headerClassName: "super-app-theme--header",
        headerName: "Status Reason",
        width: 150,
    },

    // {
    //     field: "_status_datetime",
    //     headerClassName: "super-app-theme--header",
    //     headerName: "Status date time",
    //     width: 200,
    // },
    {
        field: "_api_status_id",
        headerClassName: "super-app-theme--header",
        headerName: "Status",
        width: 100,
        renderCell: (params) => {
            let val = params.row._api_status_id;
            if (val == 1) {
                return (
                    <button className="button-33" role="button">
                        Active
                    </button>
                );
            } else if (val == 2) {
                return (
                    <button className="button-35" role="button">
                        InActive
                    </button>
                );
            } else if (val == 3) {
                return (
                    <button className="button-32" role="button">
                        Pending
                    </button>
                );
            } else {
                return <button className="button-34">Rejected</button>;
             }
        },
    },

    {
        field: "_pref_id",
        headerClassName: "super-app-theme--header",
        headerName: "Preference",
        width: 200,
        renderCell: (params) => {
            let val = params.row._status_id;
            if (val == 1) {
                return <img src={mail} alt="" style={{ width: "1.5rem" }} />;
            } else if (val == 2) {
                return <img src={sms} alt="" style={{ width: "1.5rem" }} />;
            } else {
                return <img src={bell} alt="" style={{ width: "1.5rem" }} />;
            }
        },
    },
];
