import React, { useState } from "react";
import { Routes, Route, useNavigate, useLocation, Navigate } from "react-router-dom";
import AdminRouter from "./Components/Pages/Admin/AdminRauting.jsx";
import { ROUTES } from './Components/Route.jsx'
import Home from "./Components/Pages/Homepages/LandingPage.jsx";
import UserRoute from "./Components/Pages/Users/UserRouting.jsx";
import VerifyOTP from "./Components/Pages/Homepages/VerifyOTP.jsx"
import SubAdminRoute from "./Components/Pages/CustomerAdmin/CARoute"
import NotFound from "./Components/Pages/Homepages/NotFound.jsx";
import ReadMore from "./Components/Pages/Homepages/ReadMore.jsx";
import Maintenance from "./Components/Pages/Admin/Maintenance.js";
import { Provider } from "react-redux";
import store from "./redux/store/configureStore.js";
import { routes } from "./routes/routes.js";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from 'react-redux';
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from './authConfig';
import { callMsGraph } from './graph';

import ProtectedRoute from "./routes/ProtectedRoute.jsx";
import UserDashboard from "./Components/Pages/Users/UserDashboard.jsx";
import { useEffect } from "react";
import { getSingleuserForAuth } from "./Services/AdminServices.jsx";
import { setUserAuthenticated, setUserUnauthenticated } from "./redux/authSlice.js";
import Loader from "./utils/Loader/Loader.js";
// import Maintenance from "./Components/Pages/Admin/Maintain.js";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import disableBackButtonAfterSignout from "./redux/disableBackButton.js";
import ErrorCatch from "./Components/Reusable_Components/ErrorCatch.jsx";
import InviteLandingPage from "./Components/Pages/Homepages/InviteLandingPage.jsx";

// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
// import "ag-grid-community/dist/styles/ag-theme-balham.css";

function App() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const navigate = useNavigate();
  const location = useLocation()
  const dispatch = useDispatch()
  const authenticatedState = useSelector(setUserAuthenticated)
  console.log("ROUTES_APP", ROUTES)
  console.log("location", location)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const userRole = useSelector((state) => state.auth.role)
  // console.log("isAuthenticated",isAuthenticated)

  // const isAuthenticated = useIsAuthenticated();
  const isAuthenticatedForAzure = useIsAuthenticated();
  const [isAuthenticatedAzure, setIsAuthenticatedAzure] = useState()
  // const [userRole, setUserRole] = useState("")
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState();
  function RequestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(response.accessToken).then((response) => setGraphData(response));
      });
  }

  const unknownRoute = () => {
    let isUnknownRoute = false
    for (const obj of ROUTES) {
      if (userRole === "super-admin" && obj.isAdmin && obj.path !== location.pathname) {
        isUnknownRoute = true;
      } else if (userRole === "customer-admin" && obj.isSubAdmin && obj.path !== location.pathname) {
        isUnknownRoute = true;
      } else if (userRole === "customer-user" && obj.isUser && obj.path !== location.pathname) {
        isUnknownRoute = true;
      }
    }
    return isUnknownRoute;
  }

  function extractToken(url) {
    const regex = /\/invitation\/([^\/]+)/;
    const match = url.match(regex);
    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  }

  function analyzeJWT(token) {
    const parts = token.split('.');

    if (parts.length !== 3) {
      return { error: "Invalid token format" };
    }

    const [header, payload, signature] = parts;

    const isValidAlphanumeric = (str) => /[a-z]/.test(str) && /[A-Z]/.test(str) && /[0-9]/.test(str);
    const characterCount = (str) => str.length;

    const result = {
      header: {
        content: header,
        length: characterCount(header),
        containsAlphanumeric: isValidAlphanumeric(header)
      },
      payload: {
        content: payload,
        length: characterCount(payload),
        containsAlphanumeric: isValidAlphanumeric(payload)
      },
      signature: {
        content: signature,
        length: characterCount(signature),
        containsAlphanumeric: isValidAlphanumeric(signature)
      },
    };

    result.final = {
      totalCount: result.header.length + result.payload.length + result.signature.length,
      isFalsePresent: result.header.containsAlphanumeric === false || result.payload.containsAlphanumeric === false || result.signature.containsAlphanumeric === false
    };

    return result.final;
  }

  useEffect(() => {
    console.log("isAuthenticated", isAuthenticated,"isAuthenticatedForAzure",isAuthenticatedForAzure, "isAuthenticatedAzure", isAuthenticatedAzure, "userRole", userRole, unknownRoute())
    let hasInvitation = location.pathname.includes("/invitation/")
    if (hasInvitation) {
      let invitationToken = extractToken(location.pathname)
      let analyzedToken = analyzeJWT(invitationToken)
      if (analyzedToken.totalCount > 120 && analyzedToken.isFalsePresent == false) {
        return
      }
    }
    if (!isAuthenticated) {
      navigate("/")
    }

    // if (isAuthenticated == true) {
    //   RequestProfileData()
    // }

    // if(isAuthenticatedForAzure){
    //   RequestProfileData()
    // }

    // else if (location.pathname == "/testroute") {
    //   navigate(-1)
    // }
    // else if (isAuthenticated && unknownRoute()) {
    //   navigate(-1)
    // }
  }, [isAuthenticated])

  useEffect(()=>{
    if(isAuthenticatedForAzure){
      RequestProfileData()
    }
  },[isAuthenticatedForAzure])

  useEffect(() => {
    console.log("graphData", graphData)
    if (undefined !== graphData) {
      getUserDetails()
    }
  }, [graphData])

  useEffect(() => {
    console.log("userRole", userRole)
  }, [userRole])

  const getUserDetails = async () => {
    setLoading(true)
    let azureAuthEmail = graphData.mail
    try {
      let response = await getSingleuserForAuth(azureAuthEmail)
      let singleUser = response.data.data
      if (singleUser.length > 0) {
        let { _email, _user_type, _status_id } = singleUser[0]
        if (_email == azureAuthEmail) {
          let token = response.data.token
          // setUserRole(_user_type)
          // setIsAuthenticatedAzure(isAuthenticated)
          setIsAuthenticatedAzure(isAuthenticatedForAzure)
          dispatch(setUserAuthenticated(_user_type))
          authorize(_email, _user_type, _status_id,token)
        }
      }
      else {
        toast.error("No user found")
      }

      console.log("getUserDetails", response)
    }
    catch (e) {
      setLoading(false)
      console.log("getUserDetailserror", e)
      setError(e)
    }
    setLoading(false)
  }

  const authorize = (email, userType, statusId,token) => {
    console.log("authorizeemail", email, "usertype", userType, "statusId", statusId)
    if (statusId == "1") {
      localStorage.setItem("userRole", userType)
      localStorage.setItem("Logindata", email);
      localStorage.setItem("token", token);
      toast.success("Login successful")
      if (userType === "super-admin" || userType === "super-user") {
        navigate("/admindashboard");
      }
      else if (userType === "customer-admin") {
        navigate("/subadmin");
      }
      else if (userType === "customer-user") {
        navigate("/userdashboard");
      }
    }
    else {
      localStorage.clear();
      navigate("/pagenotfound");
    }
  }

  const handleSignout = () => {
    console.log("storage", localStorage, sessionStorage)

    dispatch(setUserUnauthenticated())
    disableBackButtonAfterSignout()
    localStorage.removeItem("Logindata");
    localStorage.removeItem("SelectedMenuItem");
    localStorage.removeItem("UserName");
    navigate("/");
    // adding for sign out issue
    window.history.pushState(null, null, window.location.href); window.onpopstate = function () {
      window.history.go(1);
    };
    // ends here
    localStorage.removeItem("userRole")
    localStorage.clear();
  };

  useEffect(() => {
    const token = localStorage.getItem("token")
    console.log("token", token)
    let hasInvitation = location.pathname.includes("/invitation/")
    if (hasInvitation) {
      let invitationToken = extractToken(location.pathname)
      let analyzedToken = analyzeJWT(invitationToken)
      if (analyzedToken.totalCount > 120 && analyzedToken.isFalsePresent == false) {
        return
      }
    }
    if (!token) {
      console.log("handleSignout")
      handleSignout()
    }
  }, [])

  return (
    <>
      <Loader loading={loading} />
      <Toaster position="top-center" reverseOrder={false} />
      <ErrorCatch err={error} />

      {/* <Provider store={store}> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pagenotfound/:user/:email/:company_status_id" element={<NotFound />} />
        <Route path="/verifyOTP/:id/:email" exact element={<VerifyOTP />} />
        <Route path="/invitation/:token" exact element={<InviteLandingPage />} />
        <Route path="/verifyemailotp/:email/:otp" element={<Home />} />
        <Route path="/howitswork" exact element={<ReadMore />} />
        <Route path="/maintain" exact element={<Maintenance />} />

        {/* </Routes> */}

        {/* {isAuthenticatedAzure == true && */}
        {/* {isAuthenticated && */}
        {(isAuthenticated || isAuthenticatedAzure) &&
          ROUTES?.map((route) => {
            if (route?.isAdmin && userRole == "super-admin") {
              return <Route key={route?.path} path={route?.path} element={<AdminRouter component={route?.component} />} />
            }
            else if (route?.isSuperUser && userRole == "super-user") {
              return <Route key={route?.path} path={route?.path} element={<AdminRouter component={route?.component} />} />
            }
            else if (route?.isITAdmin && userRole == "IT-Admin") {
              return <Route key={route?.path} path={route?.path} element={<AdminRouter component={route?.component} />} />
            }
            else if (route?.isUser && userRole == "customer-user") {
              return <Route key={route?.path} path={route?.path} element={<UserRoute component={route?.component} />} />
            }
            else if (route?.isSubAdmin && userRole == "customer-admin") {
              return <Route key={route?.path} path={route?.path} element={<SubAdminRoute component={route?.component} />} />
            }
            else {
              return <Route path="/" element={<Home />} />
            }
          })
        }

        {/* {
          ROUTES?.map((route) => {
             (
              // <Routes
              //   element={
                  <ProtectedRoute
                    key={route?.path}
                    path={route?.path}
                    component={route?.isAdmin ? AdminRouter : route?.isSubAdmin ? SubAdminRoute : route?.isUser ? UserRoute : null}
                    // role={route?.isAdmin ? 'admin' : route?.isSubAdmin ? "subadmin" : route?.isUser ? 'user' : ""}
                    // role={'user'}
                  // isAdmin={route?.isAdmin}
                  // isSubAdmin={route?.isSubAdmin}
                  // isUser={route?.isUser}
                  />
              //   }
              // />
            )
          })
        } */}
      </Routes>
      {/* </Provider> */}
    </>
  );
}

export default App;
