import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { DataGrid } from "@mui/x-data-grid";

const RawDataTable = ({ isRawDataTable, rawData }) => {
    console.log("isRawDataTable", isRawDataTable, "rawData", rawData)
    const [data, setData] = useState(null)
    const [headerKeysData, setHeaderKeysData] = useState([])
    const [documentKeysData, setDocumentKeysData] = useState([])
    const [customerData, setCustomerData] = useState([])
    const [tableData, setTableData] = useState([])

    useEffect(() => {
        console.log("rawDataforTable", rawData)
        if (undefined !== rawData) {
            setData(rawData)
        }
    }, [rawData])

    // useEffect(() => {
    //     console.log("dataForTable", data)
    //     if (undefined !== data) {
    //         let headerData = []
    //         data?.header_keys.map((obj, inx) => {
    //             let newObj = { ...obj, id: inx + 1 }
    //             console.log("hknewObj", newObj)
    //             headerData.push(newObj)
    //         })
    //         setHeaderKeysData(headerData)

    //         let documentData = []
    //         data?.document_keys.map((obj, inx) => {
    //             let newObj = { ...obj, id: inx + 1 }
    //             console.log("dknewObj", newObj)
    //             documentData.push(newObj)
    //         })
    //         setDocumentKeysData(documentData)
    //         console.log("documentData", documentData)

    //         let customerDetails = []
    //         data?.customer_Data.map((obj, inx) => {
    //             let newObj = { ...obj, id: inx + 1 }
    //             console.log("cdnewObj", newObj)
    //             customerDetails.push(newObj)
    //         })
    //         setCustomerData(customerDetails)
    //     }
    // }, [data])

    useEffect(() => {
        console.log("dataForTable", data)
        if (undefined !== data || data !== null || data?.length > 0) {
            let finalData = []

            let { header_keys = [], document_keys = [], customer_Data = [] } = data || {};
            let newArr = [header_keys, document_keys, customer_Data]
            let newArrFlat = newArr.flat()
            console.log("newArr", newArr)
            console.log("newArrFlat", newArrFlat)

            newArrFlat.map((obj, inx) => {
                let newObj = { ...obj, id: inx + 1 }
                console.log("newObj", newObj)
                finalData.push(newObj)
            })

            setTableData(finalData)
        }
    }, [data])

    const rawDataColumn = [
        {
            headerName: "Name",
            field: "key",
            flex: 0.3,
        },
        {
            headerName: "Value",
            field: "value",
            flex: 0.3,
        }
    ]

    return (
        <>
                <div
                    style={{ height: "65vh", overflowY: "auto" }}
                >
                    
                        <div className="p-2">
                            <Box sx={{
                                // height: "65vh",
                                // height: "auto",
                                // width: "87vw", 
                            }}>
                          {tableData?.length > 0 ?      <DataGrid
                                    sx={{
                                        borderColor: "secondary",
                                        "& .MuiDataGrid-cell:hover": {
                                            color: "primary.main",
                                        },
                                        "& .MuiDataGrid-columnHeader": {
                                            backgroundColor: "#0E3386",
                                            fontSize: "12px",
                                            color: "white",
                                            padding: "10px",

                                        },
                                        "& .MuiDataGrid-cell": {
                                            fontSize: "10px",
                                            padding: "10px",
                                            borderRight: "2px solid #e0e0e0",

                                        },
                                        '&.Mui-selected': {

                                            '&:hover': {
                                                backgroundColor: '#FF0000',
                                            },
                                        },
                                    }}

                                    rows={tableData}
                                    getRowId={(docData) => {
                                        return docData.id

                                    }}
                                    rowHeight={30}
                                    columns={rawDataColumn}
                                    hideFooter
                                    disableMultipleColumnsSorting={true}
                                    sortModel={[
                                        { field: "action1", sort: "asc" },
                                        { field: "action", sort: "asc" },
                                    ]}
                                /> : <div className="p-2 text-center">No converted data available</div>
                            }
                            </Box>
                        </div>
                    {/* <div className="p-2">
                        <p>Header keys</p>
                        <Box sx={{
                            // height: "65vh",
                            // height: "auto",
                            // width: "87vw", 
                        }}>
                            <DataGrid
                                sx={{
                                    borderColor: "secondary",
                                    "& .MuiDataGrid-cell:hover": {
                                        color: "primary.main",
                                    },
                                    "& .MuiDataGrid-columnHeader": {
                                        backgroundColor: "#0E3386",
                                        fontSize: "12px",
                                        color: "white",
                                        padding: "10px",

                                    },
                                    "& .MuiDataGrid-cell": {
                                        fontSize: "10px",
                                        padding: "10px",
                                        borderRight: "2px solid #e0e0e0",

                                    },
                                    '&.Mui-selected': {

                                        '&:hover': {
                                            backgroundColor: '#FF0000',
                                        },
                                    },
                                }}

                                rows={headerKeysData}
                                getRowId={(docData) => {
                                    return docData.id

                                }}
                                rowHeight={30}
                                columns={rawDataColumn}
                                hideFooter
                                disableMultipleColumnsSorting={true}
                                sortModel={[
                                    { field: "action1", sort: "asc" },
                                    { field: "action", sort: "asc" },
                                ]}
                            />
                        </Box>
                    </div>
                    <div className="mt-1 p-2">
                    <p>Document keys</p>
                        <Box sx={{
                            // height: "65vh",
                            // width: "87vw", 
                        }}>
                            <DataGrid
                                sx={{
                                    borderColor: "secondary",
                                    "& .MuiDataGrid-cell:hover": {
                                        color: "primary.main",
                                    },
                                    "& .MuiDataGrid-columnHeader": {
                                        backgroundColor: "#0E3386",
                                        fontSize: "12px",
                                        color: "white",
                                        padding: "10px",

                                    },
                                    "& .MuiDataGrid-cell": {
                                        fontSize: "10px",
                                        padding: "10px",
                                        borderRight: "2px solid #e0e0e0",

                                    },
                                    '&.Mui-selected': {

                                        '&:hover': {
                                            backgroundColor: '#FF0000',
                                        },
                                    },
                                }}

                                rows={documentKeysData}
                                getRowId={(docData) => {
                                    return docData.id

                                }}
                                rowHeight={30}
                                columns={rawDataColumn}
                                hideFooter
                                disableMultipleColumnsSorting={true}
                                sortModel={[
                                    { field: "action1", sort: "asc" },
                                    { field: "action", sort: "asc" },
                                ]}
                            />
                        </Box>
                    </div>
                    <div className="mt-1 p-2">
                    <p>Customer Data</p>
                        <Box sx={{
                            // height: "65vh",
                            // width: "87vw", 
                        }}>
                            <DataGrid
                                sx={{
                                    borderColor: "secondary",
                                    "& .MuiDataGrid-cell:hover": {
                                        color: "primary.main",
                                    },
                                    "& .MuiDataGrid-columnHeader": {
                                        backgroundColor: "#0E3386",
                                        fontSize: "12px",
                                        color: "white",
                                        padding: "10px",

                                    },
                                    "& .MuiDataGrid-cell": {
                                        fontSize: "10px",
                                        padding: "10px",
                                        borderRight: "2px solid #e0e0e0",

                                    },
                                    '&.Mui-selected': {

                                        '&:hover': {
                                            backgroundColor: '#FF0000',
                                        },
                                    },
                                }}

                                rows={customerData}
                                getRowId={(docData) => {
                                    return docData.id

                                }}
                                rowHeight={30}
                                columns={rawDataColumn}
                                hideFooter
                                disableMultipleColumnsSorting={true}
                                sortModel={[
                                    { field: "action1", sort: "asc" },
                                    { field: "action", sort: "asc" },
                                ]}
                            />
                        </Box>
                    </div> */}
                </div>
        </>
    )
}

export default RawDataTable