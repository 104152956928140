import env from "./Environment";
import axios from "axios";
import axiosInstance from "./axiosInstance";

export const RequestAPI = async (newuser) => {
  return await axiosInstance.post(`${env.Backend_Url}/addApiRequest`, newuser);
};

export const defaultRequestAPI = async (newuser) => {
  return await axiosInstance.post(`${env.Backend_Url}/addDefaultApiRequest`, newuser);
};

export const convertOnRule = async (value) => {
  return await axiosInstance.post(`${env.Backend_Url}/ConvertOnRule`, value);
};

export const convertOnDoc = async (value) => {
  return await axiosInstance.post(`${env.Backend_Url}/document/level/conversion`, value);
};

export const getActiveApi = async (email) => {
  return await axiosInstance.get(`${env.Backend_Url}/active-api/${email}`);
}

export const getAllRequestedApi = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/getAllApiUser`);
};

export const getallUser = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/allUsers`);
};

export const getSingleuser = async (email) => {
  return await axios.get(`${env.Backend_Url}/get-single-user/${email}`);
};

export const getSingleuserForAuth = async (email) => {
  return await axios.get(`${env.Backend_Url}/getSingleUserForAuth/${email}`);
};

export const updateUserRole = async (values) => {
  return await axiosInstance.put(`${env.Backend_Url}/update-user-role`, values);
};

export const updateEmailPreference = async (values) => {
  return await axiosInstance.put(`${env.Backend_Url}/updateEmailPreference`, values);
};

export const getSingleDocData = async (id) => {
  return await axiosInstance.get(`${env.Backend_Url}/getSingleDocData/${id}`);
};

export const searchFaq = async (value) => {
  return await axiosInstance.post(`${env.Backend_Url}/search-faq`, value);
};

export const AddFaqfun = async (data) => {
  console.log("addFaq", data);
  return await axiosInstance.post(`${env.Backend_Url}/add-faq`, data);
};

export const updateFaqfun = async (values) => {
  return await axiosInstance.put(`${env.Backend_Url}/update-faq`, values);
};

export const getCustomerFeedback = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/user-all-feedback`);
};

export const getFaqAllFeedback = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/faq-all-feedback`);
};

export const getActiveCompony = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/active-company`);
};
export const getDeActiveCompony = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/deactive-company`);
};

export const activateCompany = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/move-company-deactive-to-active`, values);
};

export const createRoleService = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/create-role`, values);
};

export const setDocumentRules = async (values) => {
  return await axios.post(`${env.Backend_Url}/stored/documentSpecification`, values);
};

export const requestDocument = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/sap-data/singledata`, values);
};

export const addNewKeysForRules = async (values) => {
  return await axios.post(`${env.Backend_Url}/add/new/keys`, values);
};

export const getUserLogs = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/user-logs`);
};

export const getUniqueNameUserFromLogs = async (data) => {
  return await axiosInstance.get(`${env.Backend_Url}/get-logs-user-name/${data}`, {
    email: data,
  });

};

export const getSingleUserFromLogs = async (data) => {
  console.log("getSingleUserFromLogs", data);
  return await axiosInstance.get(`${env.Backend_Url}/single-user-logs/${data}`, {
    email: data,
  });
};

export const getSingleUserFromLogsByDate = async (data) => {
  console.log("getSingleUserFromLogs", data);
  return await axiosInstance.get(`${env.Backend_Url}/single-user-logs-bydate/${data.email}/${data.fromdate}/${data.todate}`)
};

export const getapilogs = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/api-logs`);
};

// export const getapilogs = async () => {
//   return await axiosInstance.get(`${env.Backend_Url}/get/api-logs`);
// };

export const getAllDocFormats = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/api-all-format`);
};

export const getApiPurpose = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/api-details`);
};

export const getSingleUserApiLogs = async (data) => {
  console.log("zahir", data);
  return await axiosInstance.get(`${env.Backend_Url}/single-user-api-logs/${data}`, {
    email: data,
  });
};

export const getActiveCustomer = async (email) => {
  console.log("getActiveCustomerapi",email)
  let encodedData = encodeURIComponent(email)
  console.log("encodedData",encodedData)
  return await axiosInstance.get(`${env.Backend_Url}/get/active/customer/${encodedData}`);
};

// export const getActiveCustomer = async (email) => {
//   console.log("getActiveCustomerapi",email)
//   let encodedData = encodeURIComponent(email)
//   console.log("encodedData",encodedData)
//   return await axiosInstance.get(`${env.Backend_Url}/get/active/customer/${(encodedData)}`);
// };

export const getAllCustomerList = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/getAllCustomerList`);
};

export const getCustomerList = async (id) => {
  return await axiosInstance.get(`${env.Backend_Url}/getCustomersList/${id}`);
};
export const getApiId = async (id) => {
  return await axiosInstance.get(`${env.Backend_Url}/getApiId/${id}`);
};

export const getDocumentName = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/documents_name`);
};

export const getResponseFormat = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/response_format`);
};

export const getRules = async (email, docid) => {
  return await axiosInstance.get(`${env.Backend_Url}/get/rules/${email}/${docid}`);
};

export const getAlbaRule = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/getAlbaRule`);
}

export const getRuleFormat = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/getRuleFormat`);
}

export const getRulesForAdmin = async (compid, docid,userid) => {
  let encodedCompid = encodeURIComponent(compid)
  let encodedDocid = encodeURIComponent(docid)
  let encodedUserid = encodeURIComponent(userid)
  return await axiosInstance.get(`${env.Backend_Url}/get/rulesForAdmin/${encodedCompid}/${encodedDocid}/${encodedUserid}`);
}

export const getDocumentKeys = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/documentkeys`, values).then(response => {
    // callback(response.data.message);
    console.log("getDocumentKeysresponse", response);
    return { status: 200, resData: response }
  }).catch(err => {
    console.log("getDocumentKeyserr", err);
    // return { status: 201, message: "Failed to get Rules" }
  })
};

export const getParameterRules = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/select_parameter_rule_apply_keys`);
};

export const getParameterDocSearch = async (data) => {
  return await axiosInstance.get(`${env.Backend_Url}/getParameter/${data}`);
};

export const getAllParameterRules = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/select_parameter_rule_apply_doc`);
};

export const getRequestedData = async (data) => {
  return await axiosInstance.get(`${env.Backend_Url}/get/requested/data/${data}`);
};

export const getCOAHistory = async (id) => {
  return await axiosInstance.get(`${env.Backend_Url}/getCOAHistory/${id}`);
};

export const getBundleHistory = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/getBundleHistory`, values);
};

export const getInvoiceHistory = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/getInvoiceHistory`, values);
};

export const getCustomFileRules = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/getCustomFileRules`);
};

export const getUserRules = async (data) => {
  return await axiosInstance.get(`${env.Backend_Url}/get/user/rules/${data}`);
};

export const createNewRules = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/create/rules`, values);
};

export const createOneRule = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/CreateOneRule`, values);
};

export const updateRuleFormat = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/updateRuleFormat`, values);
};

export const updateRule = async (values, rule_id) => {
  return await axiosInstance.put(`${env.Backend_Url}/edit/CreateOneRule/rule/${rule_id}`, values);
};

// export const adminCreateRule = async (values) => {
//   return await axiosInstance.post(`${env.Backend_Url}/create/rules/superadmin`, values);
// };

export const addNewItems = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/add/keys/dynamically`, values);
};

export const addNewItemsForEdit = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/v2/add/keys/dynamically`, values);
};

export const getKeyRulesDataforDocID = async (data) => {
  return await axiosInstance.get(`${env.Backend_Url}/get/previous/rule/apply/${data}`);
};

export const editPreviousKeyRules = async (values) => {
  return await axiosInstance.put(`${env.Backend_Url}/edit/previous/rule/apply`, values);
};

export const deleteKeyRules = async (_ruleskeys_id) => {
  return await axiosInstance.delete(`${env.Backend_Url}/delete/previous/rule/apply/${_ruleskeys_id}`);
};

export const getRuleListForSuperAdmin = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/get/rule/approval`);
};

export const getMaxDocSpecID = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/getMaxDocSpecID`);
};

export const getMaxRuleKeysID = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/getMaxRuleKeysID`);
};

export const ruleApproval = async (values) => {
  return await axiosInstance.put(`${env.Backend_Url}/approval/rule`, values);
};

export const documentApproval = async (values) => {
  return await axiosInstance.put(`${env.Backend_Url}/approval/req/doc`, values);
};

export const ViewRules = async (ruleid) => {
  console.log("rulde id mill gya??", ruleid)
  return await axiosInstance.get(`${env.Backend_Url}/combined-data/${ruleid}`)
}
export const userActiveDeactive = async (values) => {
  return await axiosInstance.put(`${env.Backend_Url}/user_active_deactive`, values);
};

export const apiActionUpdateStatus = async (values) => {
  return await axiosInstance.put(`${env.Backend_Url}/updateStatus`, values);
};

export const addDocSpecFilter = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/filter`, values);
};

export const requestedRulesforRuleID = async (values) => {
  return await axiosInstance.get(`${env.Backend_Url}/combined-data/${values}`);
};

export const allFaqList = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/all-faq-list`);
};

export const createFaqFeedback = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/create-faq-eedback`, values);
};
// export const getCustomRules = async () => {
//   return await axiosInstance.get(`${env.Backend_Url}/get/custome/rules/name`);
// };

export const getCustomRules = async (comp_id, docid) => {
  return await axiosInstance.get(`${env.Backend_Url}/get/custome/rules/name/${comp_id}/${docid}`);
};

export const getDefaultRules = async (compId, isDefaultRule) => {
  return await axiosInstance.get(`${env.Backend_Url}/get/default/rules/${compId}/${isDefaultRule}`);
};

export const getSoCustomerDetails = async (docid, soNo, userCompId) => {
  return await axiosInstance.get(`${env.Backend_Url}/getSoCustomerDetails/${docid}/${soNo}/${userCompId}`);
};

export const getParamColDetails = async (id) => {
  return await axiosInstance.get(`${env.Backend_Url}/getParamColDetails/${id}`);
};

export const createCustomRules = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/super/admin/create/custom/rules`, values);
};

export const getDocByParameter = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/getDocByParameter`, values).then(response => {
    // callback(response.data.message);
    console.log("getDocByParameterresponse", response);
    return { status: 200, resData: response }
  }).catch(err => {
    console.log("getDocByParametererr", err);
    return {
      status: 201,
      // message: "Failed to Apply Rule" 
      message: err.response.data.message || err.message
    }
  })
};

// export const getDocForAdmin = async (values) => {
//   return await axiosInstance.post(`${env.Backend_Url}/getDocForAdmin`, values).then(response => {
//     console.log("getDocForAdminResponse",response);
//     return {resData: response }
//   }).catch(err => {
//     console.log("getDocForAdminErr",err);
//     return { status: 201, 
//       // message: "Failed to Apply Rule" 
//       message: err.response.data.message || err.message
//   }
//   })
// };

export const getDocForAdmin = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/getDocForAdmin`, values)
};

export const applyRuleToDoc = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/docSearchScreenForRule`, values);
};

export const applyRuleToDocAWS = async (values) => {
  return await axiosInstance.post(`https://awqy6ejf8f.execute-api.ap-south-1.amazonaws.com/dev/customruletest`, values);
};


export const updateUserDetails = async (values) => {
  return await axiosInstance.put(`${env.Backend_Url}/updateUserDetails`, values);
}

export const newToken = async (custId) => {
  console.log("190", custId);
  return await axiosInstance.put(`${env.Backend_Url}/regenerate/api/token`, custId);
}

export const getAdmin = async (comp_id) => {
  return await axiosInstance.get(`${env.Backend_Url}/getAdmin/${comp_id}`);
};

export const getSoNoforCompany = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/getSONumber`, values).then(response => {
    // callback(response.data.message);
    console.log("getSoDetailsforCompany", response);
    return { status: 200, resData: response }
  }).catch(err => {
    console.log("getSoDetailsforCompany", err);
    return {
      status: 201,
      // message: "Failed to Apply Rule" 
      message: err.response.data.message || err.message
    }
  })
};

export const getSODetails = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/getSoDetails`, values).then(response => {
    // callback(response.data.message);
    console.log("getSoDetailsforCompany", response);
    return { status: 200, resData: response }
  }).catch(err => {
    console.log("getSoDetailsforCompany", err);
    return {
      status: 201,
      // message: "Failed to Apply Rule" 
      message: err.response.data.message || err.message
    }
  })
}

export const CreateSoDetails = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/createSoDetails`, values).then(response => {
    // callback(response.data.message);
    console.log("addResponse", response);
    return { status: 200, resData: response }
  }).catch(err => {
    console.log("addResponse", err);
    return {
      status: 201,
      message: err.response.data.message || err.message
    }
  })
}

export const deleteSoDetail = async (value) => {
  return await axiosInstance.delete(`${env.Backend_Url}/deleteSoDetail/${value}`)
};

export const updateSoDetails = async (values) => {
  return await axiosInstance.put(`${env.Backend_Url}/updateSoDetails`, values);
};

export const getCustomerMappingDetails = async () => {
  return await axios.get(`${env.Backend_Url}/getCustomerMapDetails`);
};

export const getSoCompanyNames = async () => {
  return await axios.get(`${env.Backend_Url}/getSoCustomerName`);
};

export const getPortalCompanyNames = async () => {
  return await axios.get(`${env.Backend_Url}/getPortalCustomerName`);
};

export const CreateCustomerMapping = async (values) => {
  return await axios.post(`${env.Backend_Url}/createCustomerMapping`, values).then(response => {
    // callback(response.data.message);
    console.log("addResponse", response);
    return { status: 200, resData: response }
  }).catch(err => {
    console.log("addResponse", err);
    return {
      status: 201,
      message: err.response.data.msg || err.message
    }
  })
}

export const deleteCustomerMappingDetail = async (value) => {
  return await axios.delete(`${env.Backend_Url}/deleteCustomerMapDetail/${value}`)
};

export const updateCustomerMappingDetails = async (values) => {
  return await axios.put(`${env.Backend_Url}/updateCutomerMapDetails`, values);
};

export const getRawDataDetails = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/getRawData`, values).then(response => {
    // callback(response.data.message);
    console.log("getTargetColKeysResponse", response);
    return { status: 200, resData: response }
  }).catch(err => {
    console.log("getDocumentKeyserr", err);
    // return { status: 201, message: "Failed to get Rules" }
  });
};


export const getTargetColKeys = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/getTargetColKeys`, values).then(response => {
    // callback(response.data.message);
    console.log("getTargetColKeysResponse", response);
    return { status: 200, resData: response }
  }).catch(err => {
    console.log("getDocumentKeyserr", err);
    // return { status: 201, message: "Failed to get Rules" }
  })
};

export const deleteTargetColKeys = async (value) => {
  return await axios.delete(`${env.Backend_Url}/deleteTargetColKeys/${value}`)
};

export const changePassword = async (values) => {
  return await axiosInstance.post(`${env.Backend_Url}/changePassword`, values)
};

export const getRoles = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/get-created-role`)
};

export const getSourceKeys = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/getsourcekey`);
};

export const getAllDocumentCounts = async (id) => {
  return await axiosInstance.get(`${env.Backend_Url}/getAllDocumentCounts/${id}`);
};

export const getAllSalesOrder = async (id) => {
  return await axiosInstance.get(`${env.Backend_Url}/getAllSalesOrder/${id}`);
};

export const deleteUser = async (value) => {
  return await axiosInstance.delete(`${env.Backend_Url}/delete-user/${value}`)
};

export const addNewCompany = async (value) => {
  return await axiosInstance.post(`${env.Backend_Url}/addNewCompany`, value)
}

export const getAllCompany = async () => {
  return await axiosInstance.get(`${env.Backend_Url}/all-company`);
}

export const getUsersByCompId = async (compId) => {
  return await axiosInstance.get(`${env.Backend_Url}/getUsersByCompId/${compId}`);
}

export const updateCompanyDetails = async (values) => {
  return await axiosInstance.put(`${env.Backend_Url}/updateCompanyStatus`, values);
};

export const deleteCompany = async (values) => {
  return await axiosInstance.delete(`${env.Backend_Url}/deleteCompany/${values}`);
};

export const insertLogs = async (value) => {
  return await axiosInstance.post(`${env.Backend_Url}/insertlogs`, value)
}

export const getLoginUserDetails = async (param) => {
  return await axiosInstance.get(`${env.Backend_Url}/logs/userLogin/${param.dml_type}/${param.comp_id}/${param.type}/${param.fromDate}/${param.toDate}`);
};

export const getManualDetails = async (param) => {
  return await axiosInstance.get(`${env.Backend_Url}/getmanual/${param.role}`);
};

// export const emailDocs = async (value) => {
//   return await axiosInstance.post(`${env.Backend_Url}/emailDocs`, value, {
//     headers: {
//       'Content-Type': 'multipart/form-data'
//     }
//   })
// }

export const emailDocs = async (value) => {
  return await axiosInstance.post(`${env.Backend_Url}/emailDocs`, value)
}

export const sendFile = async (value) => {
  return await axiosInstance.post(`${env.Backend_Url}/sendFile`, value)
}

// export const RequestAPI = async (value) =>{
//   console.log("Value getting", value)
//   return await axiosInstance.post(`${env.Backend_Url}/addApiRequest`,value);
// }

// /create-role
// export const moveDeActiveComponytoActiveCompany = async () => {

//   return await axiosInstance.get(`${env.Backend_Url}/deactive-company`)

// }
