import React from "react";
import gmail from "../../../Assets/Images/gmail.png";
import phone from "../../../Assets/Images/phone.png"
function ContactUs() {
  return (
    <>
      <div className="container">
       <h6 className="text-center">Contact Us</h6>
       <hr style={{color:"grey"}}></hr>
        <div className="row p-2">
          <div className="col-md-2">
            <img src={gmail} alt="email box" style={{ width: "2rem" }} />
          </div>
          <div className="col mt-3">
            <text>info@alba.com.bh</text>
          </div>
        </div>
        <div className="row p-2">
          <div className="col-md-2">
            <img src={phone} alt="email box" style={{ width: "2rem" }} />
          </div>
          <div className="col mt-3">
            <text>(+973) 1783 0083</text>
          </div>
        </div>

      </div>
    </>
  );
}

export default ContactUs;
