import env from "./Environment";
import axiosInstance from "./axiosInstance";

export const cognitoSignup = async (values) => {
    return await axiosInstance.post(`${env.Backend_Url}/cognitoSignup`, values);
};

export const cognitoConfirmSignup = async (values) => {
    return await axiosInstance.post(`${env.Backend_Url}/cognitoConfirmSignup`, values);
};

export const cognitoSignIn = async (values) => {
    return await axiosInstance.post(`${env.Backend_Url}/cognitoSignIn`, values);
};

export const cognitoAdminCreateUser = async (values) => {
    return await axiosInstance.post(`${env.Backend_Url}/cognitoAdminCreateUser`, values);
};


//new 
export const cognitoListUser = async () => {
    return await axiosInstance.get(`${env.Backend_Url}/cognitoListUser`);
};

export const cognitoChangeUserPasswordAuthChallenge = async (values) => {
    return await axiosInstance.post(`${env.Backend_Url}/cognitoChangeUserPasswordAuthChallenge`, values);
};

export const cognitoChangeUserPassword = async (values) => {
    return await axiosInstance.post(`${env.Backend_Url}/cognitoChangeUserPassword`, values);
};

export const cognitoEnableUser = async (values) => {
    return await axiosInstance.post(`${env.Backend_Url}/cognitoEnableUser`, values);
};

export const cognitoDisableUser = async (values) => {
    return await axiosInstance.post(`${env.Backend_Url}/cognitoDisableUser`, values);
};

export const cognitoDeleteUser = async (values) => {
    return await axiosInstance.put(`${env.Backend_Url}/cognitoDeleteUser`, values);
};

export const cognitoUpdateUserDetails = async (values) => {
    return await axiosInstance.post(`${env.Backend_Url}/cognitoUpdateUserDetails`, values);
};

export const cognitoInitiatePasswordReset = async (values) => {
    return await axiosInstance.post(`${env.Backend_Url}/cognitoInitiatePasswordReset`, values);
};

export const cognitoConfirmNewPassword = async (values) => {
    return await axiosInstance.post(`${env.Backend_Url}/cognitoConfirmNewPassword`, values);
};

