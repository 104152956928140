import env from "./Environment";
import axiosInstance from "./axiosInstance";

export const getAllRolesObject = async () => {
    return await axiosInstance.get(`${env.Backend_Url}/uam/getAllRolesObject`);
}

// export const getObjectsByRoles = async () => {
//     return await axiosInstance.get(`${env.Backend_Url}/uam/getObjectsByRoles`);
// }

// export const getRoleObjectsByUser = async (role_name) => {
//     return await axiosInstance.get(`${env.Backend_Url}/uam/getRoleObjectsByUser/${role_name}`);
// }

export const getObjectsByRoles = async () => {
    return await axiosInstance.get(`${env.Backend_Url}/uam/getAllAppObjects`);
}

export const getRoleObjectsByUser = async (role_name) => {
    return await axiosInstance.get(`${env.Backend_Url}/uam/getAllAppObjectsByUser/${role_name}`);
}