import React from "react";
// import logo from "../Assets/Images/alba_logo.png";
import toast, { Toaster } from "react-hot-toast";
import { DataGrid } from "@mui/x-data-grid";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment/moment";
import {
  AddFaqfun,
  createRoleService,
} from "../../../../Services/AdminServices";
import env from "../../../../Services/Environment";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../../../../utils/Loader/Loader";
import { roleColumn } from "./roleColumn";
import { messages } from "../../../Messages";
import ErrorCatch from "../../../Reusable_Components/ErrorCatch";

const CreateRole = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [error,setError] = useState()
  const schema = () => {
    return Yup.object().shape({
      role_name: Yup.string().required(
        "Role Name is required."
      ),
      description: Yup.string().required("Description is required."),
      page_access_url: Yup.string().required("Access URL is required."),
    });
  };

  const handleSubmit = async (values) => {
    // console.log(values);
    await createRoleService(values)
      .then((res) => {
        // console.log(res);
        if (res.data.success === true) {
          toast.success("Role Created Successfully.");
          fetchAllFeedback();
        } else {
          toast.error(messages.fillAllFields);
        }
      })
      .catch((error) => {
        // if (error.response.data.message) {
        //   toast.error(error.response.data.message, {
        //     position: "top-right",
        //     autoClose: 5000,
        //   });
        // }
        setError(error)
      });
  };

  const [getRoleData, setgetRoleData] = useState([]);

  const fetchAllFeedback = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`${env.Backend_Url}/get-created-role`);
      console.log("getcreatedrole", response);
      let feedbackData = response.data.result
      feedbackData.forEach((obj) => {
        obj["_created_datetime"] = moment(obj._created_datetime).format("DD-MM-YYYY H:mm:ss")
      })
      console.log("fbd", feedbackData)
      setgetRoleData(response.data.result);
    }
    catch (err) {
      setError(err)
    }
    setLoading(false)
  };

  useEffect(() => {
    fetchAllFeedback();
  }, []);

  return (
    <>
      <Loader loading={loading} />
      <Toaster position="top-center" reverseOrder={false} />
      <ErrorCatch err={error} />
      <div className="">
        <div className="row p-2">
          <div className="col-sm-4">
            <div className="">
              <Formik
                validationSchema={schema}
                initialValues={{
                  role_name: "",
                  description: "",
                  page_access_url: "",
                  adminEmail: localStorage.getItem("Logindata"),
                }}
                onSubmit={async (values, { resetForm }) => {
                  setLoading(true)
                  try {
                    await handleSubmit(values);
                    resetForm();
                  }
                  catch (err) {
                    setError(err)
                  }
                  setLoading(false)
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <div className="">
                    <div className="form">
                      <form
                        class="g-2 p-3 mt-5"
                        onSubmit={handleSubmit}
                        style={{
                          // width: "320px",
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        }}
                      >
                        {/* <div className="text-center">
                                                    <img src={logo} alt="" />
                                                </div> */}

                        <h4 class="mt-2">
                          <b className="text-center text-dark">Create Role</b>
                        </h4>
                        <div col-md-6>
                          <input
                            type="text"
                            name="role_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.role_name}
                            placeholder="Role"
                            className="form-control inp_text"
                          />

                          <p className="error">
                            {errors.role_name &&
                              touched.role_name &&
                              errors.role_name}
                          </p>
                        </div>

                        <div col-md-6>
                          <input
                            type="text"
                            name="description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                            placeholder="Description"
                            className="form-control"
                          />

                          <p className="error">
                            {errors.description &&
                              touched.description &&
                              errors.description}
                          </p>
                        </div>

                        <div col-md-6>
                          <input
                            type="text"
                            name="page_access_url"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.page_access_url}
                            placeholder="Page Access URL"
                            className="form-control"
                          />

                          <p className="error">
                            {errors.page_access_url &&
                              touched.page_access_url &&
                              errors.page_access_url}
                          </p>
                        </div>

                        {/* <div col-md-6>
                                                <input
                                                    value={values.adminEmail}
                                                    placeholder="Page Access URL"
                                                    className="form-control"
                                                    disabled={false}                                                />
                                                <p className="text-dark">{values.adminEmail}</p>

                                                 <p className="error">
                                                    {errors.adminEmail && touched.adminEmail && errors.adminEmail}
                                                </p> 
                                          </div> */}

                        <p>
                          <button class="btn btn-primary" type="submit">
                            Create
                          </button>
                        </p>
                      </form>
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          </div>

          <div className="col-sm-8 mt-5">
            <div className="">
              <div className="datatable" style={{ height: '50%', width: "100%" }}>
                <DataGrid
                  getRowId={(data) => data.role_id}
                  className="datagrid"
                  rows={getRoleData}
                  // componentsProps={{
                  //   toolbar: {
                  //     value: searchText,
                  //     onChange: (searchVal: string) => requestSearch(searchVal),
                  //     onCancelSearch: () => cancelSearch(),
                  //   },
                  // }}

                  // columns={userColumns.concat(actionColumn)}
                  columns={roleColumn}
                  pageSize={9}
                  // onCellClick={(params) => handleChangeCheckbox(params)}
                  rowsPerPageOptions={[9]}
                  // initialState={{
                  //   ...data.initialState,
                  //   pagination: { paginationModel: { pageSize: 7 } },
                  // }}
                  // pageSizeOptions={[5, 10, 25]}
                  checkboxSelection={false}
                  hidefooter
                  sortModel={[
                    { field: "action1", sort: "asc" },
                    { field: "action", sort: "asc" },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateRole;
