const env = {
    // local
    // Backend_Url: "http://localhost:5889"

    //QA
    // Backend_Url: "http://172.24.99.13:8000"

    //Prod
    Backend_Url:"https://coa-system.alba-aws.com"

    // QA
    // Frontend : "http://172.24.99.13:80"
    
    // Dev
    // Backend_Url: "http://106.201.236.153:5888"

    // Dev
    // Frontend : "http://106.201.236.153:3000"


}
export default env;