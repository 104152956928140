import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { ruleApproval } from "../../../Services/AdminServices";
import Loader from "../../../utils/Loader/Loader";
import { Formik, Form, } from 'formik';
import * as Yup from "yup";
import SelectInput from "../../Reusable_Components/SelectInput";
import InputBox from "../../Reusable_Components/InputBox";
import RadioButton from "../../Reusable_Components/RadioButton";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
const ChangeRuleStatus = ({ data, handleOkRuleStatus, handleCancel }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [selectedValue, setSelectedValue] = useState(1);
    const [input, setInput] = useState({
        _reason: "",
        // status_id: data._status_id,
        status_id: selectedValue
    });
    const [statusList, setStatusList] = useState([
        {
            label: "Approve",
            value: 1,
            status: true
        },
        {
            label: "Reject",
            value: 2,
            status: false
        }
    ])

    const validationSchema = Yup.object().shape({
        _reason: Yup.string().required('Reason is required'),

    });


    const updateUserStatus = async (value) => {
        console.log("aaaaaaaaarha hai ", value)
        let adminEmailValue = localStorage.getItem("Logindata");
        let sendData = {
            _email: adminEmailValue,
            _rule_id: data._rule_id,
            _reason: value._reason,
            // _spec_status_id: value._spec_status_id,
            _spec_status_id: selectedValue,
        };
        setLoading(true)
        try {
            const res = await ruleApproval(sendData)
            setLoading(false)
            if (res.data.success == true) {
                toast.success(res.data.msg);
                // ModuleClose()
                // setInput("");
            }
            else {
                toast.error(res.data.data)
            }
            setInput("");
            handleOkRuleStatus()
            setStatusList([
                {
                    label: "Approve",
                    value: 1,
                    status: true
                },
                {
                    label: "Reject",
                    value: 2,
                    status: false
                }
            ])
        }
        catch (err) {
            setError(err)
        }
    };

    useEffect(() => {
        setSelectedValue(1)
        setInput({
            _reason: "",
            status_id: selectedValue
        })
        setStatusList([
            {
                label: "Approve",
                value: 1,
                status: true
            },
            {
                label: "Reject",
                value: 2,
                status: false
            }
        ])
    }, [])

    useEffect(() => {
        setSelectedValue(1)
        setInput({
            _reason: "",
            status_id: selectedValue
        })
        setStatusList([
            {
                label: "Approve",
                value: 1,
                status: true
            },
            {
                label: "Reject",
                value: 2,
                status: false
            }
        ])
    }, [data])




    const handleRadioButton = (event) => {
        console.log("handleradio", event.target)
        let value = event.target.value
        console.log("item", statusList, value)

        let updatedStatusList = statusList.map((item) => {
            if (item.value == value) {
                item.status = true
            }
            else {
                item.status = false
            }
            return item
        })
        console.log("updatedStatusList", updatedStatusList)
        setSelectedValue(value)
        setStatusList(updatedStatusList)

    }


    // const handleInputChange = (e) =>{
    //     let value = e.target.value
    //     setInput(...[input],)
    // }
    return (
        <>
            <Loader loading={loading} />
            <Toaster position="top-center" reverseOrder={false} />
            <ErrorCatch err={error} />
            <div className="conatiner">
                {/* <h6 className="text-center mb-3">
                   Update Rule Status
                </h6> */}
                <p className="text-center mb-3 heading-popup">
                    Update Rule Status
                </p>
                {/* <hr className="text-secondary"></hr> */}
                <Formik
                    initialValues={{ _spec_status_id: '', _reason: '' }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { resetForm }) => {
                        try {
                            await updateUserStatus(values);
                            resetForm();
                        }
                        catch (err) {
                            setError(err)
                        }

                    }}
                >
                    {({ values, handleChange, errors, touched, handleBlur,
                    }) => {
                        return (
                            <Form>
                                <div className="mt-2">

                                    {/* <SelectInput
                                        margin="0"
                                        selectLabel="Status"
                                        name="_spec_status_id"
                                        value={values._spec_status_id}
                                        onChange={handleChange}
                                        optionsList={statusList}
                                    /> */}

                                    {/* <select
                                        class="form-select"
                                        name="_spec_status_id"
                                        value={values._spec_status_id}
                                        onChange={handleChange}
                                    >
                                        <option value=""> Select </option>
                                        <option value={1} className="text-success" >Approve</option>
                                        <option value={2} className="text-danger"  >Reject</option>
                                    </select> */}

                                    <RadioButton
                                        // radioButtonTitle="Status"
                                        optionsList={statusList}
                                        selectedValue={selectedValue}
                                        handleChange={(event) => handleRadioButton(event)}
                                    />
                                </div>

                                <p className="error">
                                    {errors.status_id && touched.status_id && errors.status_id}
                                </p>

                                <div className="mb-3 mt-3">

                                    {/* <InputBox
                                        label="Reason"
                                        name="_reason"
                                        fullWidth
                                        multiline
                                        rows={2}
                                        onChange={handleChange}
                                        value={values._reason}
                                        // placeholder="reason"
                                    /> */}

                                    <textarea
                                        type="text-area"
                                        placeholder="*Reason"
                                        class="form-control"
                                        name="_reason"
                                        value={values._reason}
                                        onChange={handleChange}
                                    />

                                </div>
                                <p className="error">
                                    {errors._reason && touched._reason && errors._reason}
                                </p>

                                <div className="mt-3 mb-3 d-flex justify-content-center">
                                    <button type="submit" className="btn btn-primary ">Submit</button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>

            </div>
        </>
    );
};

export default ChangeRuleStatus;
