const activeCompanyColumn = [
    {
      id: "6",
      field: "comp_id",
      headerName: "Id",
      // width: 70,
      flex: 0.2,
      headerClassName: "super-app-theme--header",
    },

    {
      id: "7",
      field: "comp_name",
      headerName: "Company Name",
      // width: 155,
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },

    // {
    //   field: "company_status_id",
    //   headerName: "Company Status",
    //   headerClassName: "super-app-theme--header",
    //   // width: 120,
    //   flex: 0.4,
    //   cellRenderer: (params) => {
    //     console.log("params_",params)
    //     return (
    //       <button className="btnactive" role="button">
    //         {params.value}
    //       </button>
    //     );
    //   },
    // },

    // {
    //   id: "2",
    //   field: "name",
    //   headerName: "Admin Name",
    //   // width: 170,
    //   flex: 0.5,
    //   headerClassName: "super-app-theme--header",
    // },

    // {
    //   id: "3",
    //   field: "email",
    //   headerName: "Email",
    //   // width: 250,
    //   flex: 0.5,
    //   headerClassName: "super-app-theme--header",
    // },

    // {
    //   id: "4",
    //   field: "phone",
    //   headerName: "Phone",
    //   // width: 110,
    //   flex: 0.3,
    //   headerClassName: "super-app-theme--header",
    // },

    // {
    //   id: "5",
    //   field: "address",
    //   headerName: "Address",
    //   // width: 250,
    //   flex: 0.5,
    //   headerClassName: "super-app-theme--header",
    // },
  ];

  export default activeCompanyColumn