import React, { useState } from 'react'
import { JsonViewer } from '@textea/json-viewer'
function ApiResponse(props) {
  console.log("ApiResponseProps",props.data.api_param)
  const data = JSON.parse(props.data.api_param)
  return (
    <>
      {
        props.isApiJson &&
        // <div className="conatiner">
          <div style={{ height: "22rem", overflowY: "auto" }}>
            <JsonViewer value={data} />
          </div>
        // </div>
        }
    </>
  )
}

export default ApiResponse