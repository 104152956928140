import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuthenticated: false,
        role: '',
        userDetails: {},
        userRoleObjects: []
    },
    reducers: {
        setUserAuthenticated: (state, action) => {
            state.isAuthenticated = true;
            state.role = action.payload;
        },
        setUserUnauthenticated: (state) => {
            state.isAuthenticated = false;
            state.role = ''
            state.userDetails = {}
            state.userRoleObjects = []
        },
        setUserDetails: (state, action) => {
            console.log("setUserDetailsRedux", state, action)
            state.userDetails = action.payload
        },
        setUserRoleObjects: (state, action) => {
            console.log("setUserRoleObjectsRedux", state, action)
            state.userRoleObjects = action.payload
        }
    }
})

export const { setUserAuthenticated, setUserUnauthenticated, setUserDetails, setUserRoleObjects } = authSlice.actions
export default authSlice.reducer