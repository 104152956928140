import React from 'react';
import { Input } from 'antd';
const InputBoxAntd = (
    {
        placeholder, id, value, onChange, type,
        name, disabled, autocomplete, onClick
    }
) => {
    return (
        <Input
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            type={type}
            disabled={disabled}
            placeholder={placeholder}
            autoComplete={autocomplete}
            onClick={onClick}
        />
    )

}
export default InputBoxAntd;