import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const LandingpageFAQ = () => {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <div className="justify-content-center">
        <h6 className="text-center">How can we help you?</h6>
        <hr style={{color:"grey"}}></hr>
        <Accordion

          onChange={handleChange()}

        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ width: "auto", height: "50%", fontSize:"14px" }}>
              <text>How Online COA works?</text>
            </Typography>

          </AccordionSummary>


          <AccordionDetails>
            <Typography
              sx={{ width: "100%", fontSize:"14px" }}
              className="d-flex justify-content-start"
            >
              <text className="text-success">Create account with valid data.and wait for admin to verify your details.and then user can create ruls, and they  apply  on requested documents and API's. </text>
            </Typography>


          </AccordionDetails>
        </Accordion>


        {/* 2nd questions */}
        <Accordion

          onChange={handleChange()}

        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ width: "auto", height: "50%", fontSize:"14px" }}>
              <text> How can i verify my OTP again?</text>
            </Typography>

          </AccordionSummary>


          <AccordionDetails>
            <Typography
              sx={{ width: "100%" , fontSize:"14px"}}
              className="d-flex justify-content-start"
            >
              <text className="text-success" style={{fontSize:"14px"}}>Yes, we are sending one URL while sending OTP. You needs to click on that link to verify your otp. URL is only valid for 60 Seconds, try to use before that or u need to redo signup process. </text>
            </Typography>


          </AccordionDetails>
        </Accordion>



        {/* 3rd questions */}
        <Accordion

          onChange={handleChange()}

        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ width: "auto", height: "50%", fontSize:"14px" }}>
              <text style={{fontSize:"14px"}}>What is process for requesting new rules?</text>
            </Typography>

          </AccordionSummary>


          <AccordionDetails>
            <Typography
              sx={{ width: "100%",fontSize:"14px" }}
              className="d-flex justify-content-start"
            >
              <text className="text-success" style={{fontSize:"14px"}}>Go to service page and click on request new ruls button now one form will appears just put your requirments and detail for rule creation where we have options like Replace parameters name , positions replacement , Truncate values, etc. and then you need to get approval from Admin then you will able to access that rule over documents and APIs.  </text>
            </Typography>


          </AccordionDetails>
        </Accordion>

      </div>
    </>
  );
};

export default LandingpageFAQ;
