import React, { useEffect } from "react";
import "../../../Assets/Styles/Landingpage.css";
import { useNavigate, useParams } from "react-router-dom";
import { LoginUser, isTokenVerified } from "../../../Services/UserServices";
import { useState } from "react";
import { Modal } from "antd";
import toast, { Toaster } from "react-hot-toast";
import CryptoJS from 'crypto-js';
import Loader from "../../../utils/Loader/Loader";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
import logo from "../../../Assets/Images/alba_logo.png";
import InputBox from "../../Reusable_Components/InputBox";
import { useDispatch, useSelector } from "react-redux";
import { setUserAuthenticated } from "../../../redux/authSlice";

const InviteLandingPage = () => {
  const { token } = useParams();
  const [loginData, setLoginData] = useState()
  const dispatch = useDispatch()
  const authenticatedState = useSelector(setUserAuthenticated)
  let authStatus = authenticatedState.payload.auth
  console.log("authenticatedState", authenticatedState)
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [isAllowLogin, setIsAllowLogin] = useState(false)
  const [status, setStatus] = useState("")
  const [isContactModal, setIsContactModal] = useState(false)
  const [contactMessage, setContactMessage] = useState("")
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    console.log("InviteLandingPage", token)
    setTimeout(() => {
      handleVerify(token)
    }, 3000);
    // handleVerify(token)
  }, [token])

  const handleVerify = async (inviteToken) => {
    setLoading(true)
    await isTokenVerified(inviteToken)
      .then((res) => {
        console.log("isTokenVerified", res);
        setStatus(res.data.message)
        setIsAllowLogin(true)
      })
      .catch((error) => {
        console.log("handleVerifyError", error);
        setStatus(error.response.data.message)
        setIsAllowLogin(false)
        // setError(error)
      });
    setLoading(false)
  }

  const handleEmail = (e) => {
    let value = e.target.value
    setEmail(value)
  }

  const handlePassword = (e) => {
    let value = e.target.value
    setPassword(value)
  }

  const dataValidation = () => {
    if (email == "" || password == "") {
      toast.error("Please fill all the fields")
      return
    }
    const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let isEmailValid = emailRegExp.test(email);
    let isPasswordFormat = password.length > 7 ? true : false
    console.log("dataValidation", isEmailValid, isPasswordFormat)

    if (!isEmailValid) {
      toast.error("Invalid Email. Please check")
      return false
    }
    if (!isPasswordFormat) {
      toast.error("Password must be atleast 8 characters.")
      return false
    }
    return true
  }

  const handleSubmit = async () => {
    let isValid = dataValidation()
    console.log("dataValidation", isValid)
    if (undefined == isValid || isValid == false) {
      return
    }

    // Hash the password using bcrypt
    const hashedPassword = CryptoJS.SHA256(password).toString();

    // Use the hashed password in your request
    let values = {
      email: email,
      password: hashedPassword
    }

    setLoading(true)
    await LoginUser(values)
      .then((res) => {
        console.log("loginUserRes", res)
        setLoginData(res)
        const userRole = res.data.user
        dispatch(setUserAuthenticated(userRole))
      })
      .catch((error) => {
        console.log("siginerror", error);
        if ("response" in error) {
          if (error.response.data.message == "Invalid username or password") {
            setIsContactModal(false)
            toast.error(error.response.data.message)
          }
          else {
            setIsContactModal(true)
            setContactMessage(error.response.data.displayMessage)
          }
        }
        else {
          toast.error(error.message)
        }
      });
    setLoading(false)
  }

  useEffect(() => {
    if (undefined !== loginData) {
      let { isAuthenticated } = authStatus
      if (isAuthenticated == true) {
        authorize(loginData)
      }
    }
  }, [loginData])

  const authorize = (res) => {
    const { company_status_id, email, user } = res.data
    console.log("authorizeparamres", res)

    if (res.status === 200 && res.data.company_status_id == "1") {
      localStorage.setItem("userRole", res.data.user)
      localStorage.setItem("comp_id", res.data.comp_id)
      localStorage.setItem("token", res.data.token);

    }

    if (res.data.user === "super-admin" && res.status === 200 && res.data.company_status_id == "1") {
      toast.success("Login successful");
      localStorage.setItem("Logindata", res.data.email);
      navigate("/admindashboard");
    } else if (
      res.data.user === "customer-admin" &&
      res.status === 200 &&
      res.data.status !== 2 &&
      res.data.status !== 3
      && res.data.isverified == true
      && res.data.company_status_id == "1"
    ) {
      toast.success("Login successful");
      localStorage.setItem("Logindata", res.data.email);
      localStorage.setItem("UserName", res.data.data.name);
      navigate("/subadmin");
    } else if (
      res.data.user === "customer-user" &&
      res.status === 200 &&
      res.data.status !== 2 &&
      res.data.status !== 3
      && res.data.isverified == true
      && res.data.company_status_id == "1"
    ) {
      toast.success("Login successful.");
      localStorage.setItem("Logindata", res.data.email);
      navigate("/userdashboard");
    } else if (res.data.status == 3 && res.data.user === "customer-user") {

      // navigate("/pagenotfound");
      navigate(`/pagenotfound/${user}/${email}/${company_status_id}`)
    } else {
      // navigate("/pagenotfound");
      navigate(`/pagenotfound/${user}/${email}/${company_status_id}`)
    }
  }

  const handleContactModalOk = () => {
    setIsContactModal(false);
  }

  const handleContactModalCancel = () => {
    setIsContactModal(false);
  }

  return (
    <>
      <Loader loading={loading} />
      <nav class="navbar navbar-expand-lg navbar-light bg-light  ">
        <div class="container-fluid bg-darkrow">
          <div class="col-4 d-flex justify-content-start ">
            <a class="navbar-brand  " href="/">
              <img src={logo} class="image-fluid" style={{ width: "7rem" }} alt="logo" />
            </a>
          </div>
        </div>
      </nav>

        <div className="text-center mb-3 p-3"
          style={{ width: "50%", margin: "0 auto" }}
        >
          <h5 className="font style">{status}</h5>
        </div>
        {
          isAllowLogin &&
          <div className="container-fluid text-center d-flex justify-content-center align-items-center"
            style={{ width: "60%" }}
          >
            <div className="w-50">
              <div className="mb-3">
                <InputBox
                  label="*Email"
                  name="_email"
                  fullWidth
                  onChange={(e) => handleEmail(e)}
                  value={email}
                />
              </div>
              <div className="mb-3">
                <InputBox
                  label="*Password"
                  name="_password"
                  fullWidth
                  onChange={(e) => handlePassword(e)}
                  value={password}
                />
              </div>
              <div className="mt-3">
                <button
                  className="btn btn-primary w-50"
                  onClick={handleSubmit}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        }

        <Modal title="Contact Details" open={isContactModal} onOk={handleContactModalOk} onCancel={handleContactModalCancel}
          style={{ top: "20%" }}
          footer={null} maskClosable={false}
        >
          <hr />
          <div
          >
            {/* <p>{contactMessage}</p> */}
            <p dangerouslySetInnerHTML={{ __html: contactMessage }} />
          </div>
        </Modal>
        <Toaster position="top-center" reverseOrder={false} />
      </>
      )
};

      export default InviteLandingPage;
