import axios from "axios";
import React, { useEffect, useState } from "react";
import Rating from "@mui/material/Rating";
import env from "../../../Services/Environment";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../../../utils/Loader/Loader";
import { getCustomerFeedback } from "../../../Services/AdminServices";
import { messages } from "../../Messages";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
const AllUserFeedback = () => {
  const [feedbackData, setFeedbackData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [error,setError] = useState()

  useEffect(() => {
    fetchAllFeedback();
  }, []);

  const fetchAllFeedback = async () => {
    setLoading(true)
    // const response = await axios.get(`${env.Backend_Url}/user-all-feedback`);
    try{
      const response = await getCustomerFeedback()
      console.log("getCustomerFeedback", response)
      if(response.status == 200){
        setFeedbackData(response.data.result);  
      }
      else{
        toast.warn(messages.somethingWrong)
      }
    }
    catch(err){
      setError(err)
    }

    // let temp = response.data.result.sort((a, b) => b.rating - a.rating);
    // setFeedbackData(temp.slice(0, 5))
    setLoading(false)
  }

  // const latestReviews = feedbackData.reduce((latest, review) => {
  //   const existingReview = latest.find(item => item.user_id === review.user_id);
  //   if (!existingReview || review.timestamp > existingReview.timestamp) {
  //     return [...latest.filter(item => item.user_id !== review.user_id), review];
  //   }
  //   return latest;
  // }, []);

  const reviewsByUser = feedbackData.reduce((acc, review) => {
    console.log("reviewsByUser",feedbackData,acc,review)
    if (!acc[review.user_id]) {
      acc[review.user_id] = [];
    }
    acc[review.user_id].push(review);

    return acc;
  }, {});

  // Find the latest review for each user
  const latestReviews = Object.values(reviewsByUser).map(reviews =>
    reviews.reduce((latest, review) => {
      console.log("latestReviews",reviewsByUser,latest,review)
      if (!latest || review.timestamp > latest.timestamp) {
        return review;
      }     
      return latest;
    })
  )
  console.log("late__", latestReviews);
  return (
    <>
      <Loader loading={loading} />
      <Toaster position="top-center" reverseOrder={false} />
      <ErrorCatch err={error}/>
      <div class="row gy-2" style={{backgroundColor:"white"}}>

        <div className="col-md-12">

          <div class="mb-3">
            {/* adding no data message when no comments are there*/}

            {/* {latestReviews.length == 0 ? <b>No Feedback to display</b> : */}
            {feedbackData.length == 0 ? <b>No Feedback to display</b> :
              <b>Feedback</b>}
            {/* ends here */}
          </div>
          <div class="card-body h-50">

            {/* adding no data message when no comments are there */}
            {/* {latestReviews.length == 0 && <div className="col d-flex justify-content-start">No Feedback to display</div>} */}
            {/* ends here */}
            
            {feedbackData.map((data) => {
            // {latestReviews.map((data) => {
              return (
                <>
                  <div className="row d-flex justify-content-center p-2 mb-3" style={{ boxShadow: "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px", borderRadius: "7px",backgroundColor:"#ECECEC"}}>
                    <div className="col-md-3 d-flex justify-content-start">

                      <strong>{data.name}</strong>
                    </div>
                    {/* adding feedback here */}
                    <div className="col-md-6 d-flex" >{data.feedback_data}</div>
                    <div className="col-md-3 d-flex justify-content-end">
                      <Rating
                        name="read-only"
                        value={data.rating}
                        readOnly
                        style={{ border: "white" }}
                      />
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default AllUserFeedback;
