import { DataGrid } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import { userColumn } from "./userColumn";
import { apiColumn } from "./apiColumn";
import { LuView } from "react-icons/lu";
import SpecificUserLogs from "../Logs/SpecificUserLog";
import {
  getallUser,
  getSingleUserFromLogs,
  getapilogs,
  getSingleUserApiLogs,
} from "../../../../Services/AdminServices";
import RadioButton from "../../../Reusable_Components/RadioButton";
import Loader from "../../../../utils/Loader/Loader";
import ErrorCatch from "../../../Reusable_Components/ErrorCatch";
import AgGridComponent from "../../../Reusable_Components/AgGridComponent";
import { useSelector } from "react-redux";

const UserLogs = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [data, setData] = useState([]);
  const [uniqueNameUserdata, setUniqueNameUserdata] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalForApiOpen, setIsModalForApiOpen] = useState(false);
  const [sendEmail, setSendEmail] = useState();
  const [userRoute, setUserRoute] = useState(false);
  const [params, setParams] = useState([]);
  const [showUserLogs, setUserLogs] = useState(false);
  const [showApiLogs, setShowApiLogs] = useState(false);
  const [logType, setLogType] = useState("userlogs");
  const [logName, setLogName] = useState("User Logs");
  const [selectedValue, setSelectedValue] = useState("userlogs");

  const navigate = useNavigate();

  // uam role object start
  const userDetails = useSelector((state) => state)
  const appsubmodulename = "Logs"
  const appfunctionalityname = "Logs"
  const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
  const [appObjects, setAppObjects] = useState(
    {
      "User Logs": false,
      "API Logs": false
    }
  )
  console.log("userDetails", userDetails)

  const updateAppObjects = (arr) => {
    let objectsArray = []
    arr.map((obj) => {
      console.log("updateButtons", obj)
      // if (obj.objecttypename == "button") {
      objectsArray.push(obj)
      // }
    })
    const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
      acc[appobjectname] = true;
      return acc;
    }, {});
    setAppObjects({ ...appObjects, ...finalObject })
    console.log("objectsArray", objectsArray)
    console.log("finalObject", finalObject)
  }

  useEffect(() => {
    console.log("appObjects", appObjects)
  }, [appObjects])

  useEffect(() => {
    console.log("userRoleObjects", userRoleObjects)
    const filteredList = []
    userRoleObjects.filter((obj) => {
      if (obj.appsubmodulename == appsubmodulename && obj.appfunctionalityname == appfunctionalityname) {
        filteredList.push(obj)
      }
    })

    console.log("filteredList", filteredList)
    updateAppObjects(filteredList)

  }, [])

  // uam role object end

  const showModal = (p) => {
    console.log("showmodalp", p);
    setIsModalOpen(true);
    setSendEmail(p);
  };

  const actionColumnForUser = [
    {
      field: "_status_id",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      // width: 130,
      flex: 0.3,
      cellRenderer: (params) => {
        let val = params.data._status_id;

        if (val === "Active") {
          return (
            <button className="btnactive" role="button">
              Active
            </button>
          );
        } else if (val === "InActive") {
          return (
            <button className="btnDeactive" role="button">
              {params.value}
            </button>
          );
        } else {
          return (
            <button className="btnpending" role="button">
              {params.value}
            </button>
          );
        }
      },
    },
    {
      field: "logs1",
      headerName: "Logs",
      headerClassName: "super-app-theme--header",
      // width: 100,
      filter: false,
      flex: 0.3,
      cellRenderer: (params) => {
        let val = params.data._email;
        return (
          <div>
            {/* {appObjects["User Logs"] && */}
            <button
              className="btn btn-light"
              style={{ background: "transparent", borderStyle: "none" }}
              onClick={() => showModal(val)}>
              <LuView />
            </button>
            {/* } */}
          </div>
        );
      },
    },
  ];

  const actionColumnForApi = [

    {
      field: "_logs",
      headerName: "Logs",
      headerClassName: "super-app-theme--header",
      // width: 100,
      filter: false,
      flex: 0.2,
      cellRenderer: (params) => {
        let val = params.data._email;
        console.log("logsview", val);
        return (
          <div>
            {/* {appObjects["API Logs"] &&  */}
            <button
              className="btn btn-light"
              style={{ background: "transparent", borderStyle: "none" }}
              onClick={() => showModalForApiLogs(val)}
            >
              <LuView />
            </button>
            {/* } */}
          </div>
        );
      },
    },
  ];

  const userCol = userColumn.concat(actionColumnForUser);
  const userApiCol = apiColumn.concat(actionColumnForApi);
  const [column, setColumn] = useState(userCol);

  const handleLogs = (e) => {
    console.log("handleLogsevent", e.target.value);
    let val = e.target.value;
    setLogType(val);
    if (val == "userlogs") {
      setLogName("User Logs");
      setColumn(userCol);
      setUserLogs(true);
      setShowApiLogs(false);
      alluser();
    } else if (val == "apilogs") {
      setLogName("API Logs");
      setColumn(userApiCol);
      setUserLogs(false);
      setShowApiLogs(true);
      apilogs();
    }
  };

  const showModalForApiLogs = (val) => {
    console.log("showmodalparams", val);
    setIsModalOpen(true);
    setSendEmail(val);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Get UserList

  const alluser = async () => {
    setLoading(true)
    await getallUser().then((res) => {
      let response = res.data;
      response.map((item) => {
        if (item._status_id === 2) {
          item._status_id = "InActive";
        } else if (item._status_id === 1) {
          item._status_id = "Active";
        } else {
          item._status_id = "Pending";
        }
        return item;
        console.log("mydata", response);
      });
      response.sort((a, b) => b._user_id - a._user_id);

      setData(response);
      // const sortedData = response.sort((a, b) => {
      //   if (a._status_id.startsWith("P") && !b._status_id.startsWith("P")) {
      //     return -1; // a comes before b
      //   } else if (
      //     !a._status_id.startsWith("P") &&
      //     b._status_id.startsWith("P")
      //   ) {
      //     return 1; // b comes before a
      //   } else {
      //     return a._status_id.localeCompare(b._status_id); // alphabetical order
      //   }
      // });

      // setData(sortedData);
    })
      .catch((err) => {
        console.log("getallUsererr", err)
        setError(err)
      })
    setLoading(false)
  };

  const apilogs = async () => {
    setLoading(true)
    await getapilogs().then((res) => {
      let response = res.data.data;
      console.log("apilogsresponse", response);
      response.sort((a, b) => b.access_api_logs_id - a.access_api_logs_id);
      setData(response);
    })
      .catch((err) => {
        console.log("apilogserr", err)
        setError(err)
      })
    setLoading(false)
  };



  useEffect(() => {
    // getLogs();
    alluser();
  }, []);

  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }


  const getSingleUserLogs = async (e) => {
    try {
      await getSingleUserFromLogs(e.target.value)
        .then((res) => {
          // console.log('single',res.data.data);
          let response = res.data.data;
          setData([...response]);
        })
        .catch((error) => { });
    } catch (error) {
      setError(error)
    }
  };

  const NavigateAPILogs = () => {
    navigate("/apilogs");
  };


  const [statusList, setStatusList] = useState([
    {
      label: "User Logs",
      value: "userlogs",
      status: true
    },
    // {
    //   label: "API Logs",
    //   value: "apilogs",
    //   status: false
    // }
  ])

  const handleRadioButton = (e) => {

    console.log("handleLogsevent", e.target.value);
    let val = e.target.value;
    setLogType(val);
    if (val == "userlogs") {
      setLogName("User Logs");
      setColumn(userCol);
      setUserLogs(true);
      setShowApiLogs(false);
      alluser();
    } else if (val == "apilogs") {
      setLogName("API Logs");
      setColumn(userApiCol);
      setUserLogs(false);
      setShowApiLogs(true);
      apilogs();
    }

    let updatedStatusList = statusList.map((item) => {
      if (item.value == val) {
        item.status = true
      }
      else {
        item.status = false
      }
      return item
    })
    setSelectedValue(val)
    setStatusList(updatedStatusList)
  };

  const rowClassRules = {
    'even-row': (params) => params.node.rowIndex % 2 !== 0,
  };

  return (
    <>
      <Loader loading={loading} />
      <ErrorCatch err={error} />
      <div className="container-fluid">
        <div className="px-3">
          <div className="row mt-3 mb-3">
            <div className="col-md-8">
              {/* <h6><b>User Logs</b></h6> */}
              {/* <h6>
                <b>{logName}</b>
              </h6> */}

              <p className="page-title mt-1">{logName}</p>
            </div>
            <div className="col-md-4">
              <div className="d-flex justify-content-end">

                {/* {appObjects["User Logs"] &&
                  appObjects["API Logs"] &&
                  <RadioButton
                    // radioButtonTitle="Status"
                    optionsList={statusList}
                    selectedValue={selectedValue}
                    handleChange={(e) => handleRadioButton(e)}
                  />} */}

                {/* <select
                className="form-select w-50 text-center"
                onChange={(e) => handleLogs(e)}
                style={{fontSize:"14px"}}
              >
                <option value={"userlogs"}>
                  <h5>
                    <b>User Logs</b>
                  </h5>
                </option>
                <option value={"apilogs"}>
                  <h5>
                    <b>API Logs</b>
                  </h5>
                </option>
              </select> */}

              </div>
            </div>

          </div>
          <div className="row">
            {data.length > 0 ? (
              <div className="col-md-12">
                {/* <Box sx={{ height: "62vh", width: "100%", overflowY: "auto" }}>
                  <DataGrid
                    sx={{
                      borderColor: "secondary",
                      "& .MuiDataGrid-cell:hover": {
                        color: "primary.main",
                      },
                      "& .MuiDataGrid-columnHeader": {
                        backgroundColor: "#0E3386",
                        fontSize: "12px",
                        color: "white",
                        padding: "10px",

                        // Set your desired header background color
                      },
                      "& .MuiDataGrid-cell": {
                        fontSize: "10px",
                        padding: "10px",
                        borderRight: "2px solid #e0e0e0",
                        // Adjust the font size as needed
                        // Adjust the cell padding as needed
                      },
                    }}
                    rowHeight={30}
                    className="datagrid"
                    rows={data}
                    getRowId={(row) => generateRandom()}
                    columns={column}
                    pageSize={3}
                    disableMultipleRowSelection={true}
                    handleChangeCheckbox
                    onCellClick={(params) => params}
                    rowsPerPageOptions={[5]}
                    checkboxSelection={false}
                    hideFooter
                  />
                </Box> */}
                <AgGridComponent
                  rowData={data}
                  columnDef={column}
                  rowClassRules={rowClassRules}
                />
              </div>
            ) :
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col d-flex justify-content-center">
                    {" "}
                    No Rows to show
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <Modal
        // width={1000}
        width={1000}
        className="p-2"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        style={{ top: 20, minWidth: 800, maxWidth: 1000 }}
      >
        <SpecificUserLogs
          logType={logType}
          email={sendEmail}
          handleCancel={handleCancel}
        />
      </Modal>
    </>
  );
};

export default UserLogs;
