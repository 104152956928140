import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { DataGrid } from "@mui/x-data-grid";
import { ViewRules } from "../../../../Services/AdminServices";

const ViewRuleTable = (props) => {
    console.log("isRawDataTable", "rawData", props)
    const [data, setData] = useState(null)
    const [tableData, setTableData] = useState([])
    const [error, setError] = useState()

    useEffect(() => {
        console.log("dataForTable", data)
        if (undefined !== data || data !== null || data?.length > 0) {
            let finalData = []

            let { Doc = [], Key = [], rule = [] } = data || {};
            let newArr = [Doc.data, Key.data]
            let newArrFlat = newArr.flat()
            console.log("newArr", newArr)
            console.log("newArrFlat", newArrFlat)

            newArrFlat.map((obj, inx) => {
                let newObj = { ...obj, id: inx + 1 }
                console.log("newObj", newObj)
                finalData.push(newObj)
            })

            console.log("finalData_", finalData)
            setTableData(finalData)
        }
    }, [data])

    const rawDataColumn = [
        {
            headerName: "Key",
            field: "key",
            flex: 0.4,
        },
        {
            headerName: "Value",
            field: "paramvalue",
            flex: 0.4,
        },
        {
            headerName: "Parameter",
            field: "paramname",
            flex: 0.3,
        },
        {
            headerName: "Sequence",
            field: "seq_no",
            flex: 0.2,
        }
    ]

    const ViewRulesData = async () => {
        // console.log("id mil gye 11111", props.ruleid)
        try {
            await ViewRules(props.ruleid).then((res) => {
                console.log("Rules View", res.data)
                setData(res.data)
            })
        }
        catch (err) {
            setError(err)
        }
    }

    useEffect(() => {
        ViewRulesData()
    }, [props.ruleid])

    return (
        <>
            <div
                style={{ height: "65vh", overflowY: "auto" }}
            >
                <h6><b>ID:</b> <text>{props.ruleid}</text></h6>
                <hr/>
                <div className="p-2">
                    <Box sx={{
                        // height: "65vh",
                        // height: "auto",
                        // width: "87vw", 
                    }}>
                        {tableData?.length > 0 ? <DataGrid
                            sx={{
                                borderColor: "secondary",
                                "& .MuiDataGrid-cell:hover": {
                                    color: "primary.main",
                                },
                                "& .MuiDataGrid-columnHeader": {
                                    backgroundColor: "#0E3386",
                                    fontSize: "12px",
                                    color: "white",
                                    padding: "10px",

                                },
                                "& .MuiDataGrid-cell": {
                                    fontSize: "10px",
                                    padding: "10px",
                                    borderRight: "2px solid #e0e0e0",

                                },
                                '&.Mui-selected': {

                                    '&:hover': {
                                        backgroundColor: '#FF0000',
                                    },
                                },
                            }}

                            rows={tableData}
                            getRowId={(docData) => {
                                return docData.id

                            }}
                            rowHeight={30}
                            columns={rawDataColumn}
                            hideFooter
                            disableMultipleColumnsSorting={true}
                            sortModel={[
                                { field: "action1", sort: "asc" },
                                { field: "action", sort: "asc" },
                            ]}
                        /> : <div className="p-2 text-center">No converted data available</div>
                        }
                    </Box>
                </div>
            </div>
        </>
    )
}

export default ViewRuleTable;