import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "../Admin/CustomerTableData";
import UserStatusPage from "../Admin/UserStatus";
import { getallUser, deleteUser } from "../../../Services/AdminServices";
import { useEffect, useState } from "react";
import AssignCustomerRole from "../Admin/AssignRole";
import { Modal } from "antd";
import { FaUserLock } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import Loader from "../../../utils/Loader/Loader";
import { LuView } from "react-icons/lu";
import Box from '@mui/material/Box';
import { genHoverStyle } from "antd/es/input/style";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
import AgGridComponent from "../../Reusable_Components/AgGridComponent";
import { useSelector } from "react-redux";
import AWS from 'aws-sdk';
import toast, { Toaster } from "react-hot-toast";
import ButtonComp from "../../Reusable_Components/ButtonComp";
import { cognitoDeleteUser, cognitoListUser } from "../../../Services/CognitoServices";

const AllUserList = ({ isAllUserList }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [RoleModal, setRoleModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [tableData, setTableData] = useState();
  const [userData, setUserData] = useState([]);
  const [setRole, setSetRole] = useState();
  const [userActive, setUserActive] = useState();
  const [data, setData] = useState([]);
  const [userRoute, setUserRoute] = useState(false);
  const [params, setParams] = useState([]);
  const [sortOrder, setSortOrder] = useState("des"); // Sorting order: 'asc' or 'desc' 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState()

  // Configure AWS SDK
  // AWS.config.update({ region: process.env.REACT_APP_AWS_COGNITO_REGION })
  AWS.config.update({
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_COGNITO_ACCESS_KEYID,
      secretAccessKey: process.env.REACT_APP_COGNITO_SECRET_ACCESS_KEY
    }
  })
  const cognito = new AWS.CognitoIdentityServiceProvider()

  // uam role object start
  const userDetails = useSelector((state) => state)
  const appsubmodulename = "User"
  const appfunctionalityname = "User List"
  const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
  const [appObjects, setAppObjects] = useState(
    {
      "Action": false,
      "Active/Deactive": false,
      "Delete User": false
    }
  )
  console.log("userDetails", userDetails)

  const updateAppObjects = (arr) => {
    let objectsArray = []
    arr.map((obj) => {
      console.log("updateButtons", obj)
      // if (obj.objecttypename == "button") {
      objectsArray.push(obj)
      // }
    })
    const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
      acc[appobjectname] = true;
      return acc;
    }, {});
    setAppObjects({ ...appObjects, ...finalObject })
    console.log("objectsArray", objectsArray)
    console.log("finalObject", finalObject)
  }

  useEffect(() => {
    console.log("appObjects", appObjects)
  }, [appObjects])

  useEffect(() => {
    console.log("userRoleObjects", userRoleObjects)
    const filteredList = []
    userRoleObjects.filter((obj) => {
      if (obj.appsubmodulename == appsubmodulename && obj.appfunctionalityname == appfunctionalityname) {
        filteredList.push(obj)
      }
    })

    console.log("filteredList", filteredList)
    updateAppObjects(filteredList)

  }, [])

  // uam role object end

  // front-end
  const cognitoGetAllUser = async () => {
    const params = {
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    }

    try {
      const response = await cognito.listUsers(params).promise();
      console.log('cognitoGetAllUserResponse', response)
    } catch (error) {
      console.error('cognitoGetAllUserError', error);
      toast.error(error.message)
    }
  }

  // --- back-end ---
  const getAlluserWithCognito = async () => {
    try {
      let getAllResponse = await cognitoListUser()
      console.log("getAllResponse_", getAllResponse)
    } catch (error) {
      console.log("cognitoGetAllerror", error)
    }
  }
  // ----- end ---
  const Alluser = async () => {
    setLoading(true);
    try {
      await getallUser().then((res) => {
        let response = res.data;
        console.log("alluser", res.data);
        response.map((item) => {
          if (item._status_id === 2) {
            item._status_id = "InActive";
          } else if (item._status_id === 1) {
            item._status_id = "Active";
          } else {
            item._status_id = "Pending";
          }
          return item;
        });
        response.sort((a, b) => b._user_id - a._user_id);

        setData([...response]);

      });
    }
    catch (err) {
      setError(err)
    }
    setLoading(false);
  };

  const AssigneRoleModal = (p) => {
    setRoleModal(true);
    // console.log("popup",p.row)
    setSetRole(p.data);
  };

  const AssigneRoleModalOk = () => {
    fetchAllDATA();
    Alluser()
    cognitoGetAllUser()
    setRoleModal(false);
  };

  const AssigneRoleModalCancel = () => {
    setRoleModal(false);
  };

  const showModal = (act) => {
    setIsModalOpen(true);
    setUserActive(act.data);
  };

  const showUserDeleteModal = (act) => {
    console.log("showUserDeleteModal", act)
    setIsDeleteModalOpen(true);
    setUserActive(act.data);
  };

  const handleOk = () => {
    Alluser()
    cognitoGetAllUser()
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDeleteOk = () => {
    Alluser()
    cognitoGetAllUser()
    setIsDeleteModalOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const fetchAllDATA = async () => {
    setLoading(true);
    try {
      const response = await getallUser();
      setUserData(response.data);
    }
    catch (err) {
      setError(err)
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllDATA();
  }, []);

  useEffect(() => {
    if (isAllUserList) {
      Alluser();
      cognitoGetAllUser()
    }
  }, [isAllUserList]);

  let UserIsAdmin = localStorage.getItem("Logindata");
  // const actionColumn = []
  const actionColumn = [

    {
      field: "action",
      headerName: "Action",
      // width: 68,
      filter: false,
      flex: 0.3,
      isAccess: appObjects["Action"],
      headerClassName: "super-app-theme--header",
      cellRenderer: (params) => {
        if (params.data._email == UserIsAdmin) {
          return (
            <div className="cellAction">
              {appObjects["Action"] && <a
                onClick={() => AssigneRoleModal(params)}
                class="btn btn-secondary  disabled"
              >
                <LuView />
              </a>}
            </div>
          );
        } else {
          return (
            <div className="cellAction">
              {appObjects["Action"] && <a onClick={() => AssigneRoleModal(params)} class="btn btn-light">
                <LuView />
              </a>}
            </div>
          );
        }
      },
    },
    {
      field: "action1",
      headerClassName: "super-app-theme--header",
      headerName: "Active/Deactive",
      filter: false,
      // width: 100,
      flex: 0.5,
      isAccess: appObjects["Active/Deactive"],
      cellRenderer: (params) => {
        if (params.data._email == UserIsAdmin) {
          return (
            <div>
              {appObjects["Active/Deactive"] && <a
                onClick={() => showModal(params)}
                class="btn btn-secondary disabled"
              >
                <FaUserLock />
              </a>}
            </div>
          );
        } else {
          return (
            <div>
              {appObjects["Active/Deactive"] && <a onClick={() => showModal(params)} class="btn btn-light">
                <FaUserLock />
              </a>}
            </div>
          );
        }
      },
    },
    {
      field: "delete",
      headerClassName: "super-app-theme--header",
      headerName: "Delete",
      filter: false,
      // width: 100,
      flex: 0.3,
      isAccess: appObjects["Delete User"],
      cellRenderer: (params) => {
        {
          if (params.data._email == UserIsAdmin) {
            return (
              <div>
                {appObjects["Delete User"] && <a
                  onClick={() => showUserDeleteModal(params)}
                  class="btn btn-secondary disabled"
                >
                  <RiDeleteBin6Fill />
                </a>}
              </div>
            );
          } else {
            return (
              <div>
                {appObjects["Delete User"] && <a onClick={() => showUserDeleteModal(params)} class="btn btn-light">
                  <RiDeleteBin6Fill />
                </a>}
              </div>
            );
          }
        }
      },
    }
  ]

  const [actionColumn2, setActionColumn2] = useState([])

  useEffect(() => {
    let { role } = userDetails.auth
    let finalArr = []
    // if (role == "super user") {
    actionColumn.filter((obj) => {
      // if (obj?.isSuperUser == true) {
      //   finalArr.push(obj)
      // }
      if (obj?.isAccess == true) {
        finalArr.push(obj)
      }
    })
    setActionColumn2(finalArr)

  }, [appObjects])

  const requestSearch = (searchValue) => {
    const searchRegex = new RegExp(`.*${searchValue}.*`, "ig");
    const filteredRows = data.filter((o) => {
      return Object.keys(o).some((k) => {
        return searchRegex.test(o[k].toString());
      });
    });
    setTableData(filteredRows);
  };

  const cancelSearch = () => {
    setSearchText("");
    requestSearch(searchText);
  };

  const handleChangeCheckbox = (params) => {
    let colDef = params.field;
    let data = params.row;
    if (colDef === "action") {
      setParams(data);
      setUserRoute(true);
    }
  };

  const rowClassRules = {
    'even-row': (params) => params.node.rowIndex % 2 !== 0,
  };

  // --- frontend ---
  const cognitoDeleteUserfun = async () => {
    const params = {
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      Username: userActive._email,
    };
    setLoading(true)
    try {
      const response = await cognito.adminDeleteUser(params).promise();
      console.log('cognitoDeleteUserResponse', response);
      deleteActiveUser()
      toast.success("User deleted successfully")
      setUserActive()
      handleDeleteOk()
    }
    catch (error) {
      console.error('cognitoDeleteUserError', error);
      setError(error)
    }
    setLoading(false)
  }

  // --- backend ---
  const deleteUserCognito = async () => {
    const params = {
      email: userActive._email,
    };
    setLoading(true)
    try {
      const response = await cognitoDeleteUser(params)
      console.log('cognitoDeleteUserResponse', response);
      if(response.status == 200){
        deleteActiveUser()
        toast.success("User deleted successfully")
        setUserActive()
        handleDeleteOk()
      }
    }
    catch (error) {
      console.error('cognitoDeleteUserError', error);
      setError(error)
    }
    setLoading(false)
  }

  const deleteActiveUser = async () => {
    try {
      const response = await deleteUser(userActive._user_id)
      console.log("deleteActiveUserResponse", response)
    }
    catch (err) {
      console.log("deleteActiveUserErr", err)
    }
  }

  return (
    <>
      <Loader loading={loading} />
      <Toaster position="top-center" reverseOrder={false} />
      <ErrorCatch err={error} />
      <div className="container-fluid px-4 mt-3">
        {data.length > 0 ? (<>

          <div className="col-md-12"
          // className="datatable px-3"
          // style={{ height: "82vh", width: "100%",overflowY:"auto" }}
          >
            {/* <Box sx={{ height: "78vh", width: "100%", overflowY: "auto" }}>
              <DataGrid
                sx={{
                  borderColor: "secondary",
                  "& .MuiDataGrid-cell:hover": {
                    color: "primary.main",
                  },
                  "& .MuiDataGrid-columnHeader": {
                    background: "#0E3386",
                    fontSize: "12px",
                    color: "white",
                    padding: "10px",

                    // Set your desired header background color
                  },
                  "&.MuiDataGrid-columnHeader:hover": {
                    color:"white",
                    background: "red",
                  },
      
                  "& .MuiDataGrid-cell": {
                    fontSize: "10px",
                    padding: "10px",
                    borderRight: "2px solid #e0e0e0",
                    // Adjust the font size as needed
                    // Adjust the cell padding as needed
                  }
                }
                }
                rows={data}
                rowHeight={30}
                className="datagrid"
                columns={userColumns.concat(actionColumn)}
                getRowId={(data) => data._email}
                // initialState={{
                //   pagination: {
                //     paginationModel: {
                //       pageSize: 5,
                //     },
                //   },
                // }}
                // pageSizeOptions={[5]}
                checkboxSelection={false}
                hideFooter
                disableRowSelectionOnClick
              />
            </Box> */}
            <AgGridComponent
              rowData={data}
              // columnDef={userColumns.concat(actionColumn)}
              columnDef={userColumns.concat(actionColumn2)}
              rowClassRules={rowClassRules}
            />
          </div>
        </>
        ) : (
          <div className="container">
            <div className="row justify-content-center">
              <div className="col d-flex justify-content-center">
                {" "}
                No Rows to show
              </div>
            </div>
          </div>
        )}
      </div>
      <div className=" d-flex justify-content-center">
        <Modal
          isModalOpen={isModalOpen}
          open={isModalOpen}
          maskClosable={false}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          style={{ top: 20, minWidth: 200, maxWidth: 400 }}
        >
          <UserStatusPage
            isModalOpen={isModalOpen}
            data={userActive}
            Alluser={Alluser}
            handleCancel={handleCancel}
          />
        </Modal>
      </div>

      <AssignCustomerRole
        data={setRole}
        RoleModal={RoleModal}
        Alluser={Alluser}
        AssigneRoleModalCancel={AssigneRoleModalCancel}
        AssigneRoleModalOk={AssigneRoleModalOk}
      />

      <Modal
        // title="Delete User"
        isModalOpen={isDeleteModalOpen}
        open={isDeleteModalOpen}
        maskClosable={false}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
        footer={null}
        style={{ top: 30, minWidth: 200, maxWidth: 500 }}
      >
        <div className="container fluid mt-3">
          <div className="row mb-2">
            <h3>Are you sure you want to delete the user ?</h3>
          </div>
          <div className="d-flex mt-4 justify-content-end p-2 gap-3">
            <ButtonComp
              text="Ok"
              onClick={deleteUserCognito}
            />
            <ButtonComp
              text="Cancel"
              type="secondary"
              onClick={handleDeleteCancel}
            />
          </div>
        </div>

      </Modal>

    </>
  );
};

export default AllUserList;
