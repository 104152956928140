import React, { Component } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import LoaderCSS from "./Loader.module.css"

const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "blue",

};

function Loader({ loading }) {

    return (
        <div className={loading ? `${LoaderCSS.loading_overlay}` : ""}>
            <div className={loading ? `${LoaderCSS.loader}` : ""}>
                <BeatLoader
                    css={override}
                    sizeUnit={"px"}
                    size={20}
                    color={"#213555"}
                    loading={loading}
                />
            </div>
        </div>
    )
}


export default Loader;