import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { LuView } from "react-icons/lu";
import { useSelector } from "react-redux";
import Loader from "../../utils/Loader/Loader";
import ErrorCatch from "../Reusable_Components/ErrorCatch";
import AgGridComponent from "../Reusable_Components/AgGridComponent";
import SelectDate from "../Reusable_Components/DatePicker";
import { getLoginUserDetails } from "../../Services/AdminServices";
import TabsAntd from "../Reusable_Components/TabsAntd";
import SpecificUserLogs from "./Admin/Logs/SpecificUserLog";

function Reports() {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [data, setData] = useState([]);
    const [logName, setLogName] = useState("User Engagement Summary");
    const [logType, setLogType] = useState("userlogs");
    const [sendEmail, setSendEmail] = useState();

    const [column, setColumn] = useState([
        // {
        //     headerClassName: "super-app-theme--header",
        //     field: "user_id",
        //     headerName: "Id",
        //     // width: 100,
        //     flex: 0.2,
        // },
        {
            field: "username",
            headerClassName: "super-app-theme--header",
            headerName: "Name",
            // width: 120,
            flex: 0.5,
        },

        {
            field: "email",
            headerClassName: "super-app-theme--header",
            headerName: "Email",
            // width: 260,
            flex: 0.5,
        },
        {
            field: "companyname",
            headerClassName: "super-app-theme--header",
            headerName: "Company Name",
            // width: 260,
            flex: 0.5,
        },
        {
            field: "_logs",
            headerName: "Logs",
            headerClassName: "super-app-theme--header",
            // width: 100,
            filter: false,
            flex: 0.2,
            cellRenderer: (params) => {
                let val = params.data.email;
                console.log("logsview", val);
                return (
                    <div>
                        {/* {appObjects["API Logs"] &&  */}
                        <button
                            className="btn btn-light"
                            style={{ background: "transparent", borderStyle: "none" }}
                            onClick={() => showModal(val)}
                        >
                            <LuView />
                        </button>
                        {/* } */}
                    </div>
                );
            },
        },


        // {
        //     field: "_designation",
        //     headerClassName: "super-app-theme--header",
        //     headerName: "Designation",
        //     // width: 130,
        //     flex: 0.4,
        // },

        // {
        //     field: "_user_type",
        //     headerClassName: "super-app-theme--header",
        //     headerName: "Role",
        //     flex: 0.3,
        // },

        // {
        //     field: "_address",
        //     headerClassName: "super-app-theme--header",
        //     headerName: "Address",
        //     // width: 250,
        //     flex: 0.5,
        // }
    ]);
    const [selectedFromDate, setSelectedFromDate] = useState('')
    const [selectedToDate, setSelectedToDate] = useState('')

    const [defaultSelectedDate, setDefaultSelectedDate] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);


    const userDetails = useSelector((state) => state)
    const userDetailsLog = userDetails.auth.userDetails


    const rowClassRules = {
        'even-row': (params) => params.node.rowIndex % 2 !== 0,
    };

    const showModal = (val) => {
        console.log("showmodalparams", val);
        setIsModalOpen(true);
        setSendEmail(val);
    };

    useEffect(() => {
        defaultDate()
        console.log("userDetails_", userDetails)
    }, [])

    useEffect(() => {
        if (selectedFromDate !== '' || selectedToDate !== '') {
            getAllLoginUserDetails(selectedFromDate, selectedToDate)
        }
    }, [selectedFromDate, selectedToDate])

    const getAllLoginUserDetails = async (paramFromDate, paramToDate) => {
        let params = {
            comp_id: userDetailsLog.comp_id,
            dml_type: 'Login',
            type: "super-admin",
            fromDate: paramFromDate,
            toDate: paramToDate
        }
        console.log("params_", params)
        let response = await getLoginUserDetails(params)
        console.log("response_", response)
        let responseData = response.data.data
        // let filterData =[...new Set(responseData)]
        let filterData = Array.from(new Map(responseData.map(item => [item.user_id, item])).values());
        console.log("filterData_", filterData);
        if (response.status == 200) {
            setData(filterData)
        }
    }

    const defaultDate = () => {
        let todaysDate = new Date()
        let day = todaysDate.getDate().toString().padStart(2, '0')
        let month = (todaysDate.getMonth() + 1).toString().padStart(2, '0')
        let year = todaysDate.getFullYear()
        let defaultdate = `${year}-${month}-${day}`
        setSelectedFromDate(defaultdate)
        setSelectedToDate(defaultdate)
        setDefaultSelectedDate(defaultdate)
        // getAllLoginUserDetails(defaultdate)
    }
    const handleFromDateChange = (dates) => {
        let formattedDate = dates.target.value
        console.log("formattedDate", formattedDate);
        setSelectedFromDate(formattedDate)
    }

    const handleToDateChange = (dates) => {
        let formattedDate = dates.target.value
        console.log("formattedDate", formattedDate);
        setSelectedToDate(formattedDate)
    }

    // const items = [
    //     {
    //       key: "1",
    //       label: `API's `,
    //       children:  appObjects["APIs"] ? <AllApiRequestlist apiList={apiList} /> : <div><h3>No Access Provided</h3> </div>
    //     },
    //     // {
    //     //   key: "4",
    //     //   label: `Rules`,
    //     //   children: appObjects["Rules"] ? <AdminSetRules createRulesList={createRulesList} /> : <div><h3>No Access Provided</h3> </div>,
    //     // },
    //     // {
    //     //   key: "6",
    //     //   label: `Register`,
    //     //   children: appObjects["Register"] ? <CustomRulesSuperAdmin customRules={customRules} /> : <div><h3>No Access Provided</h3> </div>,
    //     // },
    //     // {
    //     //   key: "7",
    //     //   label: `Document`,
    //     //   children: appObjects["Document"] ? <AdminDoc isAdminDoc={isAdminDoc} /> : <div><h3>No Access Provided</h3> </div>,
    //     // },
    //   ];

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div>
            <Loader loading={loading} />
            <ErrorCatch err={error} />
            {/* <div>
                <TabsAntd
                    defaultActiveKey="7"
                    items={items}
                    onChange={(key) => onChange(key)}
                    centered
                />
            </div> */}

            <Modal
                // width={1000}
                width={1000}
                className="p-2"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
                style={{ top: 20, minWidth: 800, maxWidth: 1000 }}
            >
                <SpecificUserLogs
                    logType={logType}
                    handleCancel={handleCancel}
                    email={sendEmail}
                    isUsage={true}
                    fromDate ={selectedFromDate}
                    toDate={selectedToDate}
                />
            </Modal>

            <div className="container-fluid">
                <div className="px-3">
                    <div className="row mt-3 mb-3">
                        <div className="col-md-8">
                            <p className="page-title mt-1">{logName}</p>
                        </div>
                        <div className="col-md-2">
                            <SelectDate class="a1"
                                fullWidth
                                // style={{Color: rgb(0, 0, 0, 0.6)}}
                                // defaultValue={today}
                                label="From"
                                // value={fromDate == "" ? "" : fromDate
                                value={selectedFromDate}
                                maxDate={defaultSelectedDate}
                                onChange={(value) => handleFromDateChange(value)}
                                disabled={false}
                            />
                        </div>
                        <div className="col-md-2">
                            <SelectDate class="a1"
                                fullWidth
                                // style={{Color: rgb(0, 0, 0, 0.6)}}
                                // defaultValue={today}
                                label="To"
                                // value={fromDate == "" ? "" : fromDate
                                value={selectedToDate}
                                maxDate={defaultSelectedDate}
                                onChange={(value) => handleToDateChange(value)}
                                disabled={false}
                            />
                        </div>
                    </div>

                    <div className="row mt-2">
                        {data.length > 0 ? (
                            <div className="col-md-12">
                                <AgGridComponent
                                    rowData={data}
                                    columnDef={column}
                                    rowClassRules={rowClassRules}
                                />
                            </div>
                        ) :
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col d-flex justify-content-center">
                                        {" "}
                                        No Rows to show
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Reports;