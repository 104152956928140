import React from 'react';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#4caf50', // Green toggle button when checked
        '&:hover': {
            backgroundColor: 'rgba(76, 175, 80, 0.1)', // Light green hover when checked
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#4caf50', // Green background when checked
    },
    '& .MuiSwitch-switchBase': {
        color: '#CC0000', // Red toggle button when unchecked
        '&:hover': {
            backgroundColor: 'rgba(244, 67, 54, 0.1)', // Light red hover when unchecked
        },
    },
    '& .MuiSwitch-track': {
        backgroundColor: '#f44336',  // background when unchecked
    },
}));

const SwitchComp = ({ checked, onChange }) => {
    return (
        <>
            <CustomSwitch
                checked={checked}
                onChange={onChange}
            />
        </>
    )

}
export default SwitchComp


