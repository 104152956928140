import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { alpha, styled } from '@mui/material/styles';


const StyledButton = styled(Button)(({ theme }) => ({
    // width: 300,
    background: "#EF3E33", 
    color: "#FFFFFF",
    // fontFamily: "Montserrat, sans-serif",
    fontFamily:"Nunito Sans, sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    textTransform: "none",
    // borderRadius: "10px"
    // color: theme.palette.success.main,
    
  }));

function BasicButton({text,onClick,disabled,className,type}) {
  return (
    // <Stack spacing={2} direction="row">
      <StyledButton 
      // style={{textTransform: "none"}}
      className={className}
      type={type}
      size="small"
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      >
        {text}
        </StyledButton>
    // </Stack>
  );
}

export default BasicButton