import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import RadioButton from "../../Reusable_Components/RadioButton";
import { apiActionUpdateStatus } from "../../../Services/AdminServices";
import Loader from "../../../utils/Loader/Loader";
import { messages } from "../../Messages";
import InputBox from "../../Reusable_Components/InputBox.jsx";
import ErrorCatch from "../../Reusable_Components/ErrorCatch.jsx";
const APIStatusAction = ({ isModalOpen, data, handleOk }) => {
  console.log("_data", data)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const updateOptions = [{
    name: "Active",
    value: "Active"
  },
  {
    name: "Deactive",
    value: "Deactive"
  }]


  const [selectedValue, setSelectedValue] = useState(3);
  const [input, setInput] = useState({
    status_reason: "",
    status_id: selectedValue,
  });
  const [list, setList] = useState([
    { value: 1, label: "Active", status: false },
    { value: 2, label: "Deactive", status: false },
    { value: 3, label: "In Progress", status: true }
  ])

  const [inputData, setInputData] = useState({
    _requestor_name: data._requestor_name,
    api_status_name: list[0].label,
    _cust_req_id: data._cust_req_id,
    AlbaAdmin: "",
    status_reason: "",
    // api_status_name: "",
    responseDataTime: new Date().toLocaleDateString(),

  });
  useEffect(() => {
    console.log("data_", data)
    if (undefined !== data || isModalOpen == true) {
      setInputData({
        _requestor_name: data._requestor_name,
        _cust_req_id: data._cust_req_id,
        api_status_name: list[0].label,
        AlbaAdmin: localStorage.getItem("Logindata"),
        status_reason: "",
        // api_status_name: "",
        responseDataTime: new Date().toLocaleDateString(),
      });
      if (data._api_status_name == "Active") {
        setList([
          { value: 1, label: "Active", status: true },
          { value: 2, label: "Deactive", status: false },
          { value: 3, label: "In Progress", status: false }
        ])
        setSelectedValue(1)
      } else if (data._api_status_name == "In Progress" || data._api_status_name == "Pending") {
        setList([
          { value: 1, label: "Active", status: false },
          { value: 2, label: "Deactive", status: false },
          { value: 3, label: "In Progress", status: true }
        ])
        setSelectedValue(3)
      } else {

        setList([
          { value: 1, label: "Active", status: false },
          { value: 2, label: "Deactive", status: true },
          { value: 3, label: "In Progress", status: false }
        ])
        setSelectedValue(2)
      }

    }
  }, [data, isModalOpen]);

  // input area condition
  const handleInput = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
    // console.log("dropdown",inputdata.api_status_name)
  };

  const ModalClose = () => {
    // setTimeout(() => {
    handleOk()
    // }, 1000)
  }

  const onSubmitResponse = async (e) => {
    e.preventDefault();
    const {
      _cust_req_id,
      AlbaAdmin,
      status_reason,
      responseDataTime,
      api_status_name,
    } = inputData;

    console.log("__const", inputData, e)

    if (
      !_cust_req_id ||
      !AlbaAdmin ||
      (status_reason == "") ||
      !responseDataTime ||
      (api_status_name == "")
    ) {
      toast.error(messages.fillAllFields);

      return
    } else {
      try {
        setLoading(true)
        const response = await apiActionUpdateStatus(inputData)
        if (response.status == 200) {
          toast.success(messages.updatedSuccess);
          ModalClose()

        }
        setLoading(false)
      } catch (error) {
        // toast.error(error.response.data.msg);
        setError(error)

      }
    }


  };

  // adding radiobutton handlechange 
  // starts here
  const handleRadioButton = (event) => {
    console.log("handleradio", event, data)
    let value = event.target.value
    console.log("item", list, value)
    let updatedStatusList = list.map((item) => {
      if (item.value == value) {
        item.status = true
        setInputData({ ...inputData, ["api_status_name"]: item.label });
      }
      else {
        item.status = false
      }
      return item

    })
    console.log("updatedStatusList", updatedStatusList)
    console.log("__select", selectedValue)
    setSelectedValue(value)
    setList(updatedStatusList)
    //  setInputData({...inputData,["api_status_name"]:selectedValue})

  }
  // ends here
  // adding handlechange for radiobutton
  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    setInputData({ ...inputData, [name]: value });
    console.log("__select1", value)
  };

  return (
    <>
      <Loader loading={loading} />
      <Toaster position="top-center" reverseOrder={false} />
      <ErrorCatch err={error} />
      {/* <form> */}
      <div className=" ">
        <div class="row mb-3">
          <div className="col-12">
            <h6 className="float-center">API Request Response</h6>
          </div>
          <hr className="text-secondary"></hr>
        </div>
        <div className="row mb-2">
          <div className="col">
            {/* <div>Name:</div> */}
            <input
              type="text"
              disabled={true}
              name="AlbaAdmin"
              value={inputData._requestor_name}
              // onChange={(e) => handleInput(e)}
              class="form-control"
              placeholder="Name"
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            {/* <div>Email ID:</div> */}
            <input
              type="text"
              disabled={true}
              name="AlbaAdmin"
              value={inputData.AlbaAdmin}
              onChange={(e) => handleInput(e)}
              class="form-control"
              placeholder="AlbaAdmin"
            />
          </div>
        </div>

        {/* <div className="row mb-2">
                <div className="col">
                  <input
                    type="text"
                    name="_cust_req_id"
                    value={inputData?._cust_req_id}
                    onChange={(e) => handleInput(e)}
                    class="form-control"
                    disabled={true}
                  />
                </div>
              </div> */}

        <div className="row mb-2">
          {/* <div className="col-6">
              <input
                id=" responseDataTime"
                type="datetime-local"
                name=" responseDataTime"
                //defaultValue={responseDataTime}
                class="form-control"
                //value={inputData?.responseDataTime}
                onChange={(e) => handleInput(e)}
              />
            </div> */}
          <div className="col">
            {/* <select
                id="api_status_name"
                name="api_status_name"
                value={inputData.api_status_name}
                onChange={(e) => handleInput(e)}
                class="form-control"
              >
                <option value={""} disabled selected hidden>{"Update Status"}</option>
                {updateOptions.map((obj) => {
                  let { name, value } = obj
                  if (data.api_status_name == "Pending") {
                    return <option value={value}>{name}</option>;
                  }
                  else if (data.api_status_name !== name) {
                    return <option value={value}>{name}</option>;
                  }
                })} */}
            {/* already commented */}
            {/* <option value="Active">Active</option>
                <option value="Deactive">Deactive</option> */}
            {/* already commented */}
            {/* </select> */}
            {/* adding radiobutton here */}

            <RadioButton
              // radioButtonTitle="Status"
              optionsList={list}
              selectedValue={selectedValue}
              handleChange={(event) => handleRadioButton(event)}
            />
            {/* ends here */}
          </div>
        </div>

        <div className="row mb-2">
          <div className="col">
            <InputBox
              fullWidth
              multiline
              rows={2}
              label="*Reason"
              name="status_reason"
              onChange={(e) => handleInput(e)}
              value={inputData?.status_reason}
            />

            {/* <textarea
                type="text"
                name="status_reason"
                value={inputData?.status_reason}
                onChange={(e) => handleInput(e)}
                class="form-control"
                placeholder="*Reason"
              ></textarea> */}
          </div>
        </div>
        <div className="col d-flex justify-content-center">
          {console.log("kgf", ((input.status_id == "") || (inputData.status_reason == "")))}
          <button
            type="button"
            disabled={(inputData.status_reason == "") ? true : false}
            onClick={(e) => onSubmitResponse(e)}
            className="btn btn-primary"
          >
            Submit
          </button>
        </div>
      </div>
      {/* </form> */}

    </>
  );
};

export default APIStatusAction;
