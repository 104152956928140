import React, { useState } from "react";
import CryptoJS from 'crypto-js';
import logo from "../../../Assets/Images/alba_logo.png";
import { Modal } from "antd";
import toast, { Toaster } from "react-hot-toast";
import { LoginUser, ResetPasswordService, generateOTPforMFASignin, verifyTOTPforMFASignin } from "../../../Services/UserServices";
import ForgetPassword from '../Homepages/ForgetPassword'
import { useNavigate } from "react-router-dom";
import Loader from "../../../utils/Loader/Loader";
import InputBox from "../../Reusable_Components/InputBox";
import { useDispatch, useSelector } from "react-redux";
import { setUserAuthenticated, setUserDetails, setUserRoleObjects } from "../../../redux/authSlice";
import { useEffect } from "react";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
import { getRoleObjectsByUser } from "../../../Services/UAMservices";
import { cognitoConfirmNewPassword, cognitoConfirmSignup, cognitoInitiatePasswordReset, cognitoSignIn, cognitoSignup } from "../../../Services/CognitoServices";
import { getSingleuser, getSingleuserForAuth, insertLogs } from "../../../Services/AdminServices";
import AWS from 'aws-sdk';
import dayjs from 'dayjs';
// import CryptoJS from 'crypto-js';
// import Login_page from "../User/Login";
// import CryptoJS from 'crypto-js';

const CognitoSignup = (props) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [contactMessage, setContactMessage] = useState("")
    const [isContactModal, setIsContactModal] = useState(false)
    const [loginData, setLoginData] = useState()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const authenticatedState = useSelector(setUserAuthenticated)
    // const authenticatedState = useSelector((state) => state.auth.isAuthenticated);
    let authStatus = authenticatedState.payload.auth
    console.log("authenticatedState", authenticatedState)

    // Configure AWS SDK
    // AWS.config.update({ region: process.env.REACT_APP_AWS_COGNITO_REGION })
    AWS.config.update({
        region: process.env.REACT_APP_AWS_COGNITO_REGION,
        credentials: {
            accessKeyId: process.env.REACT_APP_COGNITO_ACCESS_KEYID,
            secretAccessKey: process.env.REACT_APP_COGNITO_SECRET_ACCESS_KEY
        }
    })
    const cognito = new AWS.CognitoIdentityServiceProvider()

    const [isPhoneVerifyModal, setIsPhoneVerifyModal] = useState(false)
    const [step, setStep] = useState(0);
    const [timeLeft, setTimeLeft] = useState(0);
    const [generatedSecret, setGeneratedSecret] = useState("")
    const [otp, setOtp] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")

    const [userDetails1, setUserDetails1] = useState({
        formattedName: "", username: "", password: "", email: ""
    })

    const [confirmationCode, setConfirmationCode] = useState("")
    const [showSignup, setShowSignup] = useState(false)
    const [showConfirmCode, setShowConfirmCode] = useState(false)
    const [isshowSignin, setShowSignin] = useState(props.setIsShowSignin)
    const [showPasswordReset, setShowPasswordReset] = useState(props.showPasswordReset)
    const [showForgotPassword, setShowForgotPassword] = useState(props.showForgotPassword)

    useEffect(() => {
        if (props.showForgotPassword == true) {
            setConfirmationCode("")
            setUserDetails1(prevDetails => ({
                ...prevDetails,
                password: ""
            }));
        }
    }, [props.showForgotPassword])

    useEffect(() => {
        if (props.isshowSignin == true) {
            setUserDetails1(prevDetails => ({
                ...prevDetails,
                password: ""
            }));
        }
    }, [props.isshowSignin])

    useEffect(() => {
        if (props.isOpen) {
            setShowSignup(false)
            setShowConfirmCode(false)
            props.setIsShowSignin(true)
            props.setShowForgotPassword(false)
            props.setShowPasswordReset(false)

            setConfirmationCode("")
            setUserDetails1({ formattedName: "", username: "", password: "", email: "" })
        }
    }, [props.isOpen])

    const userSignin = () => {
        setShowSignup(false)
        setShowConfirmCode(false)
        props.setIsShowSignin(true)
        props.setShowForgotPassword(false)
        props.setShowPasswordReset(false)
        props.setCognitoTitle("Sign In")
    }

    const userSignup = () => {
        setShowSignup(true)
        setShowConfirmCode(false)
        props.setIsShowSignin(false)
        props.setCognitoTitle("Signup")
    }

    const userForgotPassword = () => {
        setShowSignup(false)
        setShowConfirmCode(false)
        props.setIsShowSignin(false)
        props.setShowPasswordReset(true)
        props.setShowForgotPassword(false)
        props.setCognitoTitle("Forgot Password")
    }

    // --- frontend ---
    const cognitoInitiatePasswordResetfun = async () => {
        let { username } = userDetails1
        if (username == "") {
            toast.error("Please enter user email")
            return
        }
        const params = {
            ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
            Username: username,
        }
        setLoading(true)
        try {
            const response1 = await getSingleuser(username)
            console.log("getSingleuserResponse", response1)
            if (response1.data.data.length == 1) {
                const response = await cognito.forgotPassword(params).promise();
                console.log("cognitoInitiatePasswordResetResponse", response)
                toast.success("Confirmation code sent to your email")
                props.setShowForgotPassword(true)
                props.setShowPasswordReset(false)
            }
            else {
                toast.error("No user found")
            }

        }
        catch (err) {
            console.log("cognitoInitiatePasswordResetErr", err)
        }
        setLoading(false)
    }

    //--- backend ---
    const initiatePasswordResetCognito = async () => {
        let { username } = userDetails1
        if (username == "") {
            toast.error("Please enter user email")
            return
        }
        const params = {
            email: username,
        }
        setLoading(true)
        try {
            const response1 = await getSingleuser(username)
            console.log("getSingleuserResponse", response1)
            if (response1.data.data.length == 1) {
                const response = await cognitoInitiatePasswordReset(params)
                console.log("cognitoInitiatePasswordResetResponse", response)
                if (response.status == 200) {
                    toast.success("Confirmation code sent to your email")
                    props.setShowForgotPassword(true)
                    props.setShowPasswordReset(false)
                }
                // props.setShowForgotPassword(true)
                // props.setShowPasswordReset(false)
            }
            else {
                toast.error("No user found")
            }

        }
        catch (err) {
            console.log("cognitoInitiatePasswordResetErr", err)
            setError(err)
        }
        setLoading(false)
    }

    // --- frontend ---
    const cognitoConfirmNewPasswordfun = async () => {
        let { username, password } = userDetails1
        if (confirmationCode == "") {
            toast.error("Please provide the confirmation code.")
            return
        }
        if (password == "") {
            toast.error("Please provide a password")
            return
        }
        const params = {
            ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
            Username: username,
            ConfirmationCode: confirmationCode,
            Password: password
        }
        const hashedPassword = CryptoJS.SHA256(password).toString();
        const sendData = {
            email: username,
            password: hashedPassword
        }
        setLoading(true)
        try {
            const response = await cognito.confirmForgotPassword(params).promise();
            console.log("cognitoConfirmNewPasswordResponse", response)
            // const response2 = await ResetPasswordService(sendData)
            // console.log("response2", response2)
            toast.success("Password Reset Successfully")
            setShowSignup(false)
            setShowConfirmCode(false)
            props.setIsShowSignin(true)
            props.setShowForgotPassword(false)
            props.setShowPasswordReset(false)
            setConfirmationCode("")
            props.setCognitoTitle("Sign In")
            setUserDetails1({ formattedName: "", username: "", password: "", email: "" })
        }
        catch (err) {
            console.log("cognitoConfirmNewPasswordErr", err)
        }
        setLoading(false)
    }

    // --- backend ---
    const confirmNewPasswordCognito = async () => {
        let { username, password } = userDetails1
        if (confirmationCode == "") {
            toast.error("Please provide the confirmation code.")
            return
        }
        if (password == "") {
            toast.error("Please provide a password")
            return
        }

        const params = {
            email: username,
            confirmationCode: confirmationCode,
            password: encrypt(password)
        }
        const hashedPassword = CryptoJS.SHA256(password).toString();
        const sendData = {
            email: username,
            password: hashedPassword
        }
        setLoading(true)
        try {
            const response = await cognitoConfirmNewPassword(params)
            console.log("cognitoConfirmNewPasswordResponse", response)
            // const response2 = await ResetPasswordService(sendData)
            // console.log("response2", response2)
            toast.success("Password Reset Successfully")
            setShowSignup(false)
            setShowConfirmCode(false)
            props.setIsShowSignin(true)
            props.setShowForgotPassword(false)
            props.setShowPasswordReset(false)
            setConfirmationCode("")
            props.setCognitoTitle("Sign In")
            setUserDetails1({ formattedName: "", username: "", password: "", email: "" })
        }
        catch (err) {
            console.log("cognitoConfirmNewPasswordErr", err)
            setError(err)
        }
        setLoading(false)
    }


    const handleUserDetails = (e) => {
        let { name, value } = e.target
        if (name == "username") {
            setUserDetails1({ ...userDetails1, [name]: value, ["email"]: value })
        }
        else {
            setUserDetails1({ ...userDetails1, [name]: value })
        }
    }


    useEffect(() => {
        if (undefined !== loginData) {
            console.log("loginDataCheck", loginData)
            let { ismfa, email, phone, is_phone_verified } = loginData.data.data
            if (ismfa) {
                setIsPhoneVerifyModal(true)
                setEmail(email)
                setPhoneNumber(phone)
                // sendOTP(email, phone)
                return
            }
            let { isAuthenticated, role } = authStatus
            if (isAuthenticated == true) {
                authorize(loginData)
            }
        }
    }, [loginData])
    //Forget password
    const [isModalOpen, setIsModalOpen] = useState(false);

    const insertLogsForUser = async (params) => {
        let { user_id, name, email, comp_id, comp_name } = params
        setLoading(true)
        try {
            let payload = {
                userId: user_id,
                userName: name,
                email: email,
                compId: comp_id,
                compName: comp_name,
                actionType: "Login",
                soNo: "",
                fileType: "",
                docType: "",
                profileName: "",
                title: "",
                designation: "",
                department: "",
                userType: "",
                phoneNo: "",
                address: "",
            }
            console.log("userDetailsLog_", payload);
            let logresponse = await insertLogs(payload)
            console.log("logresponse_", logresponse);
        }
        catch (err) {
            console.log("logresponseErr", err);
            setError(err)
        }
        setLoading(false)
    }

    const authorize = async (res, cognitoResponse) => {
        // const { _status_id: status_id, _email: email, _user_type: user_type, _comp_id: comp_id, _name:name } = res.data.data
        const { status_id, email, user_type, comp_id, name } = res.data.data
        console.log("authorizeemail", email, "user_type", user_type, "statusId", status_id)
        console.log("authorizeparamres", res)
        let { ChallengeName } = cognitoResponse.data.data

        if (res.status == 200 && status_id == "1") {
            localStorage.setItem("userRole", user_type)
            localStorage.setItem("comp_id", comp_id)
            localStorage.setItem("token", res.data.token);
            const userRoleObjects = await getRoleObjectsByUser(user_type)
            console.log("userRoleObjects", userRoleObjects)
            dispatch(setUserRoleObjects(userRoleObjects.data.roleObjects))
        }

        if ((user_type === "super-admin" || user_type === "super-user" || user_type === "IT-Admin") && res.status === 200 && status_id == "1") {

            localStorage.setItem("Logindata", email);
            if (ChallengeName === 'NEW_PASSWORD_REQUIRED') {
                toast.success("Login successful. Please change your temporary password", {
                    duration: 5000
                })
                navigate("/myprofile")
            }
            else {
                toast.success("Login successful");
                navigate("/admindashboard");
            }

        } else if (
            user_type === "customer-admin" &&
            res.status === 200 &&
            res.data.status !== 2 &&
            res.data.status !== 3
            // && res.data.isverified == true
            && status_id == "1"
        ) {
            toast.success("Login successful");
            localStorage.setItem("Logindata", email);
            localStorage.setItem("UserName", name);
            if (ChallengeName === 'NEW_PASSWORD_REQUIRED') {
                toast.success("Login successful. Please change your temporary password", {
                    duration: 5000
                })
                navigate("/profiles")
            }
            else {
                toast.success("Login successful");
                navigate("/subadmin");
            }

        } else if (
            user_type === "customer-user" &&
            res.status === 200 &&
            res.data.status !== 2 &&
            res.data.status !== 3
            // && res.data.isverified == true
            && status_id == "1"
        ) {
            toast.success("Login successful.");
            localStorage.setItem("Logindata", email);
            if (ChallengeName === 'NEW_PASSWORD_REQUIRED') {
                toast.success("Login successful. Please change your temporary password", {
                    duration: 5000
                })
                navigate("/userprofile")
            }
            else {
                toast.success("Login successful");
                navigate("/userdashboard");
            }

        } else if (res.data.status == 3 && user_type === "customer-user") {
            console.log("pagenotfound1", res)
            // navigate("/pagenotfound");
            navigate(`/pagenotfound/${user_type}/${email}/${status_id}`)
        } else {
            console.log("pagenotfound2", res)
            // navigate("/pagenotfound");
            navigate(`/pagenotfound/${user_type}/${email}/${status_id}`)
        }
    }

    // frontend api
    const handleCognitoSignupFrontend = async () => {
        let { username, email, password, formattedName } = userDetails1
        const params = {
            ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
            Username: username,
            Password: password,
            UserAttributes: [
                {
                    Name: 'email',
                    Value: email,
                },
                {
                    Name: 'name',
                    Value: formattedName
                }
            ]
        }
        setLoading(true)
        try {
            const response = await cognito.signUp(params).promise();
            console.log("handleCognitoSignupFrontendResponse", response)
            toast.success('Signup successful! Please check your email to confirm.')
            if (response?.data?.data?.UserConfirmed == false) {
                setShowConfirmCode(true)
                setShowSignup(false)
                props.setIsShowSignin(false)
                props.setShowForgotPassword(false)
                props.setShowPasswordReset(false)
            }
        } catch (err) {
            console.log("handleCognitoSignupFrontendErr", err)
            setError(err.message || 'Signup failed. Please try again.')
            if (err?.response?.data?.message?.message == "User already exists") {
                setShowConfirmCode(true)
                setShowSignup(false)
                props.setIsShowSignin(false)
                props.setShowForgotPassword(false)
                props.setShowPasswordReset(false)
            }
        }
        setLoading(false)
    }


    // backend api
    const handleCognitoSignup = async (values) => {
        console.log("userDetails1", userDetails1)
        setLoading(true)
        try {
            const response = await cognitoSignup(userDetails1)
            console.log("cognitoSignupResponse", response)
            if (response?.data?.data?.UserConfirmed == false) {
                setShowConfirmCode(true)
                setShowSignup(false)
                props.setIsShowSignin(false)
            }
        }
        catch (err) {
            console.log("handleCognitoSignupErr", err)
            toast.error(err.message)
            if (err?.response?.data?.message?.message == "User already exists") {
                setShowConfirmCode(true)
                setShowSignup(false)
                props.setIsShowSignin(false)
            }
        }
        setLoading(false)
    }

    const handleConfirmationCode = (e) => {
        let { value } = e.target
        const numericValue = value.replace(/[^0-9]/g, '');
        setConfirmationCode(numericValue);
    }

    // frontend api
    const handleCognitoConfirmSignupFrontend = async () => {
        const params = {
            ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
            Username: userDetails1.username,
            ConfirmationCode: confirmationCode,
        }
        setLoading(true)
        try {
            const response = await cognito.confirmSignUp(params).promise()
            console.log("handleCognitoConfirmSignupResponse", response)
            setShowConfirmCode(false)
            setShowSignup(false)
            props.setIsShowSignin(true)
        }
        catch (err) {
            console.log("handleCognitoConfirmSignupFrontendErr", err)
            toast.error(err.message)
        }
        setLoading(false)
    }

    // backend api
    const handleCognitoConfirmSignup = async () => {
        let sendData = { username: userDetails1.username, confirmationCode: confirmationCode }
        setLoading(true)
        try {
            const response = await cognitoConfirmSignup(sendData)
            console.log("handleCognitoConfirmSignupResponse", response)
            setShowConfirmCode(false)
            setShowSignup(false)
            props.setIsShowSignin(true)
        }
        catch (err) {
            console.log("handleCognitoConfirmSignupErr", err)
            toast.error(err.message)
        }
        setLoading(false)
    }

    // frontend api
    const handleCognitoSigninFrontend = async () => {
        let { username, password } = userDetails1
        const params = {
            AuthFlow: 'USER_PASSWORD_AUTH',
            ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
            AuthParameters: {
                USERNAME: username,
                PASSWORD: password,
            }
        }
        setLoading(true)
        try {
            const response = await cognito.initiateAuth(params).promise()
            console.log("handleCognitoSigninFrontendResponse", response)
            localStorage.setItem("ChallengeName", '');
            localStorage.setItem("ChallengeSession", response.Session);
            if (response.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
                localStorage.setItem("ChallengeName", 'NEW_PASSWORD_REQUIRED');
            }
            if (response?.AuthenticationResult) {
                let { AccessToken, IdToken, RefreshToken } = response.AuthenticationResult
                localStorage.setItem("cognitoAccessToken", AccessToken)
                localStorage.setItem("cognitoIdToken", IdToken)
                localStorage.setItem("cognitoRefreshToken", RefreshToken)
            }

            const hashedPassword = CryptoJS.SHA256(userDetails1.password).toString();
            let sendData = {
                email: userDetails1.email,
                password: hashedPassword
            }

            // const userData = await getSingleuserForAuth(userDetails.email)
            const userData = await LoginUser(sendData)
            console.log("userData", userData)
            let singleUser = userData.data.data
            let loginTime = userData.data.lastLogin
            const formattedDate = loginTime == null ? null : dayjs(loginTime).format('DD MMMM, YYYY h:mm:ss A');
            localStorage.setItem("lastLogin", formattedDate);
            const userRole = userData.data.user
            dispatch(setUserAuthenticated(userRole))
            let userDetails2 = userData.data.data
            let { name, email, comp_id, phone, user_type, role_id, is_email_enabled, user_id, last_login, department, designation } = userDetails2
            let { comp_name } = userData.data.isCompActive
            let userDataObj = { name, email, comp_id, comp_name, phone, user_type, role_id, user_id, department, designation }
            dispatch(setUserDetails(userDataObj))
            authorize(userData)
            let token = userData.data.token
        }
        catch (error) {
            console.log("handleCognitoSigninFrontendErr", error)
            if ("response" in error) {
                if (error.response.data.message == "Invalid username or password") {
                    toast.error(error.response.data.message)
                }
                else {
                    toast.error(error.response.data.message)
                }
            }
            else {
                toast.error(error.message)
            }
        }
        setLoading(false)
    }

    // const encrypt = (plaintext) => {
    //     let encryptedPassword = CryptoJS.AES.encrypt(plaintext, process.env.REACT_APP_SECRET_KEY).toString();
    //     return encryptedPassword
    // };
    const encrypt = (plaintext) => {
        console.log("encryptPlaintext", plaintext);
        return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(plaintext))
            .replace(/=+$/, '') // Remove padding
            .replace(/\+/g, '-') // Replace + with -
            .replace(/\//g, '_'); // Replace / with _
    };

    // backend api
    const handleCognitoSignin = async () => {
        let { formattedName, username, password, email } = userDetails1
        let newObj = {
            formattedName: formattedName, username: username,
            password: encrypt(userDetails1.password), email: email
        }
        setLoading(true)
        try {
            const response = await cognitoSignIn(newObj)
            console.log("handleCognitoSigninResponse", response)
            localStorage.setItem("ChallengeName", '');
            localStorage.setItem("ChallengeSession", response.data.data.Session);
            if (response?.data?.data?.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
                localStorage.setItem("ChallengeName", 'NEW_PASSWORD_REQUIRED');
            }
            if (response?.data?.data?.AuthenticationResult) {
                let { AccessToken, IdToken, RefreshToken } = response.data.data.AuthenticationResult
                localStorage.setItem("cognitoAccessToken", AccessToken)
                localStorage.setItem("cognitoIdToken", IdToken)
                localStorage.setItem("cognitoRefreshToken", RefreshToken)
            }

            // let response = { data: { data: { ChallengeName: "" } } }
            let sendData = {
                email: userDetails1.email,
                // password: hashedPassword
            }
            const userData = await LoginUser(sendData)
            console.log("userData", userData)
            let singleUser = userData.data.data
            let loginTime = userData.data.lastLogin
            const formattedDate = loginTime == null ? null : dayjs(loginTime).format('DD MMMM, YYYY h:mm:ss A');
            localStorage.setItem("lastLogin", formattedDate);
            const userRole = userData.data.user
            dispatch(setUserAuthenticated(userRole))
            let userDetails2 = userData.data.data
            let { name, email, comp_id, phone, user_type, role_id, is_email_enabled, user_id, last_login, department, designation } = userDetails2
            let { comp_name } = userData.data.isCompActive
            let userDataObj = { name, email, comp_id, comp_name, phone, user_type, role_id, user_id, department, designation }
            dispatch(setUserDetails(userDataObj))
            authorize(userData, response)
            insertLogsForUser(userDataObj)
            let token = userData.data.token

            // const userData = await getSingleUserDetails(userDetails1.email)
            // console.log("userData", userData)
            // let singleUser = userData.data.data
            // if (singleUser.length > 0) {
            //     let { email, user_type, status_id } = singleUser[0]
            //     dispatch(setUserAuthenticated(user_type))
            //     authorize(userData)
            //     let token = userData.data.token
            // }
            // else{
            //     toast.error("No user found")
            // }
        }
        catch (err) {
            console.log("handleCognitoSigninErr", err)
            if ("response" in err) {
                toast.error(err.response.data.message)
            }
            else {
                toast.error(err.message)
            }
        }
        setLoading(false)
    }

    return (
        <>
            <Loader loading={loading} />
            <Toaster position="top-center" reverseOrder={false} />
            <ErrorCatch err={error} />
            {/* {showSignup &&
                <div className="container-fluid">
                    <div className="row p-3">
                        <InputBox
                            fullWidth
                            label="Name"
                            onChange={handleUserDetails}
                            name="formattedName"
                            value={userDetails1.formattedName}
                        />
                    </div>
                    <div className="row p-3">
                        <InputBox
                            fullWidth
                            label="Email"
                            onChange={handleUserDetails}
                            name="username"
                            value={userDetails1.username}
                        />
                    </div>
                    <div className="row p-3">
                        <InputBox
                            fullWidth
                            label="Password"
                            onChange={handleUserDetails}
                            type={"password"}
                            name="password"
                            value={userDetails1.password}
                        />
                    </div>
                    <div className="row px-4">
                        <button
                            // onClick={handleCognitoSignup}
                            onClick={handleCognitoSignupFrontend}
                            className="btn red-btn mt-5 p-3"
                        >
                            Signup
                        </button>
                    </div>
                    <div className="p-3 d-flex justify-content-end">
                        <a
                            onClick={userSignin}
                        >
                            Signin
                        </a>
                    </div>
                </div>} */}

            {/* {showConfirmCode &&
                <div className="container-fluid">
                    <div className="row p-3">
                        <p>Kindly check your mail for confirmation code</p>
                    </div>
                    <div className="row p-3">
                        <InputBox
                            fullWidth
                            label="Confirmation Code"
                            onChange={handleConfirmationCode}
                            name="confirmationCode"
                            value={confirmationCode}
                        />
                    </div>
                    <div className="p-3">
                        <button
                            // onClick={handleCognitoConfirmSignup}
                            onClick={handleCognitoConfirmSignupFrontend}
                            className="btn red-btn mt-2"
                        >
                            Confirm
                        </button>
                    </div>
                </div>} */}

            {props.isshowSignin &&
                <div className="container-fluid">
                    <div className="row p-3">
                        <InputBox
                            fullWidth
                            label="Email"
                            onChange={handleUserDetails}
                            name="username"
                            value={userDetails1.username}
                        />
                    </div>
                    <div className="row p-3">
                        <InputBox
                            fullWidth
                            label="Password"
                            onChange={handleUserDetails}
                            type={"password"}
                            name="password"
                            value={userDetails1.password}
                        />
                    </div>
                    <div className="row px-4">
                        <button
                            onClick={handleCognitoSignin}
                            // onClick={handleCognitoSigninFrontend}
                            className="btn red-btn mt-5 p-3"
                        >
                            Sign In
                        </button>
                    </div>
                    {/* <div className="p-3 d-flex justify-content-end">
                        <a
                            onClick={userSignup}
                        >
                            Signup
                        </a>
                    </div> */}
                    <div className="p-3 d-flex justify-content-end">
                        <a
                            onClick={userForgotPassword}
                        >
                            Forgot Password
                        </a>
                    </div>
                </div>
            }

            {props.showPasswordReset &&
                <div className="container-fluid">
                    <div className="row p-3">
                        <InputBox
                            fullWidth
                            label="Email"
                            onChange={handleUserDetails}
                            name="username"
                            value={userDetails1.username}
                        />
                    </div>

                    <div className="row px-4">
                        <button
                            onClick={initiatePasswordResetCognito}
                            className="btn red-btn mt-5 p-3"
                        >
                            Send Confirmation Code
                        </button>
                    </div>
                    <div className="p-3 d-flex justify-content-end">
                        <a
                            onClick={userSignin}
                        >
                            Sign In
                        </a>
                    </div>
                </div>
            }

            {props.showForgotPassword &&
                <div className="container-fluid">
                    <div className="row p-3">
                        <InputBox
                            fullWidth
                            label="Email"
                            onChange={handleUserDetails}
                            name="username"
                            value={userDetails1.username}
                        />
                    </div>
                    <div className="row p-3">
                        <InputBox
                            fullWidth
                            label="Confirmation Code"
                            onChange={handleConfirmationCode}
                            name="confirmationCode"
                            value={confirmationCode}
                        />
                    </div>
                    <div className="row p-3">
                        <InputBox
                            fullWidth
                            label="New Password"
                            onChange={handleUserDetails}
                            type={"password"}
                            name="password"
                            value={userDetails1.password}
                        />
                    </div>
                    <div className="row px-4">
                        <button
                            onClick={confirmNewPasswordCognito}
                            className="btn red-btn mt-5 p-3"
                        >
                            Change Password
                        </button>
                    </div>
                    <div className="p-3 d-flex justify-content-end">
                        <a
                            onClick={userSignin}
                        >
                            Sign In
                        </a>
                    </div>
                </div>
            }

        </>
    );
}

export default CognitoSignup;