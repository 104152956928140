
import { useEffect, useState } from "react";
import AllUserList from "./AllUserList";
import TabsAntd from "../../Reusable_Components/TabsAntd";
import RoleAccess from "./RoleAccess";
import { useSelector } from "react-redux";

const CustomerList = () => {
  const [isAllUserList, setIsAllUserList] = useState(true)
  const [isRoleAccess, setIsRoleAccess] = useState(false)

  // uam role object start
  const userDetails = useSelector((state) => state)
  const appsubmodulename = "User"
  // const appfunctionalityname = "Customer Feedback"
  const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
  const [appObjects, setAppObjects] = useState(
    {
      "User List": false,
      "Role Access": false
    }
  )
  console.log("userDetails", userDetails)

  const updateAppObjects = (arr) => {
    let objectsArray = []
    arr.map((obj) => {
      console.log("updateButtons", obj)
      if (obj.objecttypename == "tab") {
        objectsArray.push(obj)
      }
    })
    const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
      acc[appobjectname] = true;
      return acc;
    }, {});
    setAppObjects({ ...appObjects, ...finalObject })
    console.log("objectsArray", objectsArray)
    console.log("finalObject", finalObject)
  }

  useEffect(() => {
    console.log("appObjects", appObjects)
  }, [appObjects])

  useEffect(() => {
    console.log("userRoleObjects", userRoleObjects)
    const filteredList = []
    userRoleObjects.filter((obj) => {
      if (obj.appsubmodulename == appsubmodulename) {
        filteredList.push(obj)
      }
    })

    console.log("filteredList", filteredList)
    updateAppObjects(filteredList)

  }, [])

  // uam role object end

  const items = [
    {
      key: "1",
      label: `User List`,
      children: appObjects["User List"] ? <AllUserList isAllUserList={isAllUserList} /> : <div><h3>No Access Provided</h3> </div>,
      isAccess: appObjects["User List"]
    },
    {
      key: "2",
      label: `Role Access`, 
      children: appObjects["Role Access"] ? <RoleAccess isRoleAccess={isRoleAccess} /> : <div><h3>No Access Provided</h3> </div>,
      isAccess: appObjects["Role Access"]
    },
  ]

  const [items2,setItems2] = useState([])

  useEffect(() => {
    let { role } = userDetails.auth
    let finalArr = []
    // if (role == "super user") {
      items.filter((obj) => {
      // if (obj?.isSuperUser == true) {
      //   finalArr.push(obj)
      // }
      if (obj?.isAccess == true) {
        finalArr.push(obj)
      }
    })
    setItems2(finalArr)

  }, [appObjects])

  const onChange = (key) => {
    console.log("onChangetab", key);
    if (key == "1") {
      setIsAllUserList(true)
      setIsRoleAccess(false)
    }
    else if (key == "2") {
      setIsAllUserList(false)
      setIsRoleAccess(true)
    }
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row mt-2">
          <div className="col d-flex justify-content-center">
            <TabsAntd
              defaultActiveKey="1"
              // items={items}
              items={items2}
              onChange={(key) => onChange(key)}
              centered
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerList;
