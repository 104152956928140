import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Formik, Form, } from 'formik';
import * as Yup from "yup";
import { insertLogs, userActiveDeactive } from "../../../Services/AdminServices";
import Loader from "../../../utils/Loader/Loader";
import SelectInput from "../../Reusable_Components/SelectInput";
import InputBox from "../../Reusable_Components/InputBox";
import { messages } from "../../Messages";
import RadioButton from "../../Reusable_Components/RadioButton";
import ErrorCatch from "../../Reusable_Components/ErrorCatch";
import AWS from 'aws-sdk';
import { useSelector } from "react-redux";
import { cognitoDisableUser, cognitoEnableUser } from "../../../Services/CognitoServices";

const UserStatusPage = ({ isModalOpen, data, Alluser, handleCancel }) => {
  const userDetails = useSelector((state) => state)
  const [selectedValue, setSelectedValue] = useState(1);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  // const [input, setInput] = useState({
  //   status_reason: "",
  //   // status_id: data._status_id,
  //   status_id: "",
  // });

  const [input, setInput] = useState({
    status_reason: "",
    status_id: selectedValue,

  });
  const [list, setList] = useState([{ "value": "1", "label": "Active", "status": true }, { "value": "2", "label": "Deactive", "status": false }])

  const [statusPlaceholder, setStatusPlaceholder] = useState({
    name: "Select",
    value: ""
  })

  useEffect(() => {
    console.log("selectedValue", selectedValue)

  }, [selectedValue])
  // Configure AWS SDK
  // AWS.config.update({ region: process.env.REACT_APP_AWS_COGNITO_REGION })
  AWS.config.update({
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_COGNITO_ACCESS_KEYID,
      secretAccessKey: process.env.REACT_APP_COGNITO_SECRET_ACCESS_KEY
    }
  })
  const cognito = new AWS.CognitoIdentityServiceProvider()

  useEffect(() => {
    console.log("data", data)
    // setInput({
    //   status_reason: "",
    //   status_id: "",
    // })
    setList([{ "value": "1", "label": "Active", "status": true }, { "value": "2", "label": "Deactive", "status": false }])
    setInput({ ...input, ["status_reason"]: "", ["status_id"]: "" });
    setStatusPlaceholder({
      name: "Select",
      value: ""
    })
  }, [data, handleCancel])



  useEffect(() => {
    console.log("data", data)
    // setInput({
    //   status_reason: "",
    //   status_id: "",
    // })
    // setInput({ ...input, ["status_reason"]: "", ["status_id"]: "" });
    setStatusPlaceholder({
      name: "Select",
      value: ""
    })
  }, [])

  const handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    // setList(final)

    setInput({ ...input, [name]: value });
    console.log("__select1", input, name);
    // setSelectedValue(value)
  };
  // adding condition for radiobutton
  const radiohandleChange = (e) => {
    let value = e.target.value;
    // let name = e.target.name;
    let final = list.map(item => {
      if (item.value == value) {
        item.status = true
      } else {
        item.status = false
      }
      return item
    })
    setList(final)

    setSelectedValue(value)
    console.log("__Select", selectedValue)
    setInput({ ...input, ["status_id"]: value });
  };
  // ends here
  const validationSchema = Yup.object().shape({
    status_reason: Yup.string().required('Status Reason is required'),
    status_id: Yup.string().required('Status is required'),
  });

  const cognitoDeleteUser = async () => {
    const params = {
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      Username: data._email,
    };

    try {
      const response = await cognito.adminDeleteUser(params).promise();
      console.log('Delete User Response:', response);

    } catch (error) {
      console.error('Error deleting user:', error);
      setError(error)
    }
  }

  // --- frontend ---
  const cognitoEnableUserfun = async () => {
    if (input.status_reason == "") {
      toast.error(messages.fillAllFields);
      return
    }

    const params = {
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      Username: data._email,
    };

    try {
      const response = await cognito.adminEnableUser(params).promise();
      console.log('User enabled successfully:', response);
      UpdateUserStatus()

    } catch (error) {
      console.error('Error enabling user:', error);
      setError(error)
    }
  }

  const insertLogsForUser = async (action) => {
    let { email: loginUserEmail, user_id, name: loginUserName, comp_id, comp_name } = userDetails.auth.userDetails
    setLoading(true)
    try {
      let payload = {
        userId: user_id,
        userName: loginUserName,
        email: loginUserEmail,
        compId: comp_id,
        compName: comp_name,
        actionType: action,
        soNo: "",
        fileType: "",
        docType: "",
        profileName: data._name,
        title: data._title,
        designation: data._designation,
        department: data._department,
        userType: data._user_type,
        phoneNo: data._phone,
        address: data._address,
      }
      console.log("userDetailsLog_", payload);
      let logresponse = await insertLogs(payload)
      console.log("logresponse_", logresponse);
    }
    catch (err) {
      console.log("logresponseErr", err);
      setError(err)
    }
    setLoading(false)
  }

  // --- backend ---
  const enableUserCognito = async () => {
    if (input.status_reason == "") {
      toast.error(messages.fillAllFields);
      return
    }
    try {
      const params = {
        email: data._email,
      };
      let response = await cognitoEnableUser(params)
      console.log("enableuserresponse_", response)
      if (response.status == 200) {
        UpdateUserStatus()
        insertLogsForUser("Active")
      }
    } catch (error) {
      console.log("enableUserCognitoresponse_", error);
      setError(error)
    }
  }

  // --- frontend ---
  const cognitoDisableUserfun = async () => {
    if (input.status_reason == "") {
      toast.error(messages.fillAllFields);
      return
    }

    const params = {
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      Username: data._email,
    };

    try {
      const response = await cognito.adminDisableUser(params).promise();
      console.log('Disable User Response:', response);
      UpdateUserStatus()
    } catch (error) {
      console.error('Error disabling user:', error);
      setError(error)
    }
  }

  // --- backend ---
  const disableUserCognito = async () => {
    if (input.status_reason == "") {
      toast.error(messages.fillAllFields);
      return
    }
    
    try {
      const params = {
        email: data._email,
      };
      let response = await cognitoDisableUser(params)
      console.log("enableuserresponse_", response)
      if (response.status == 200) {
        UpdateUserStatus()
        insertLogsForUser("InActive")
      }
    } catch (error) {
      console.log("enableUserCognitoresponse_", error);
      setError(error)
    }
  }


  const UpdateUserStatus = async (values) => {
    setLoading(true)
    let adminEmailValue = localStorage.getItem("Logindata");

    // const CloseModal = () => {
    //   setTimeout(() => {
    //     handleCancel()
    //   }, 2300);

    // }

    // let sendData = {
    //   email: data._email,
    //   status_datetime: new Date(),
    //   status_reason: values.status_reason,
    //   status_id: values.status_id,
    //   adminEmail: adminEmailValue,
    // }

    const { _email, _status_datetime, status_reason, status_id } =
      input;
    console.log("input===>", input)

    if (status_reason == "") {
      toast.error(messages.fillAllFields);
      setLoading(false)
      return
    }
    else {
      let sendData = {
        email: data._email,
        // status_datetime: _status_datetime,
        status_datetime: new Date(),
        status_reason: status_reason,
        status_id: selectedValue,
        adminEmail: adminEmailValue,
      }
      // adding toast message error
      try {
        const res = await userActiveDeactive(sendData)
        console.log("responseuserstatus", res)
        if (res.data.success == true) {
          toast.success(res.data.msg);
        }
      }
      catch (error) {
        setLoading(false)
        console.log("useractivedeactiveeerror", error)
        // if("response" in error){
        //   toast.error(error.response.data.msg)
        // }else{
        //   toast.error(error.message)
        // }

        setError(error)

      }
      // ends here
      setInput({});
      handleCancel()
      Alluser()
      setLoading(false)
    }
  }

  const handleUserUpdate = () => {
    if (selectedValue == 1) {
      // cognitoEnableUser()
      enableUserCognito()
    }
    else if (selectedValue == 2) {
      // cognitoDisableUser()
      disableUserCognito()
    }
  }
  // changes begins here 4/9/2023

  // ends here
  return (
    <>
      <Loader loading={loading} />
      <Toaster position="top-center" reverseOrder={false} />
      <ErrorCatch err={error} />
      <div className="container">
        <h6 className="text-center heading-popup mb-3">
          Update Status
        </h6>
        {/* <hr className="text-dark" /> */}
        {/* <Formik
          initialValues={{ status_id: '', status_reason: '' }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            await UpdateUserStatus(values);
            resetForm();

          }}
        >
          {({ values, handleChange, errors, touched, handleBlur,
          }) => {
            return (
              <Form>
                <div>
                  <select
                    name="status_id"
                    className="form-control"
                    onChange={handleChange}
                    style={{
                      borderRadius: "5px",
                      fontSize: "14px"
                    }}
                  >
                    <option value="" disabled selected hidden>Select</option>
                    <option value={1}>Active</option>
                    <option value={2}>InActive</option>
                  </select>
                </div>

                <p className="error">
                  {errors.status_id && touched.status_id && errors.status_id}
                </p>

                <div className="mb-3 mt-3">

                  <textarea
                    type="text-area"
                    placeholder="Reason"
                    class="form-control"
                    name="status_reason"
                    value={values.status_reason}

                    onChange={handleChange}
                  />

                </div>
                <p className="error">
                  {errors.status_reason && touched.status_reason && errors.status_reason}
                </p>

                <div className="mt-3 mb-3 d-flex justify-content-center">
                  <button type="submit" className="btn btn-primary ">Submit</button>
                </div>
              </Form>
            );
          }}
        </Formik> */}

        <div className="form ">
          <div className="row">
            <div className="col-md-12 mt-3 mb-3">
              {/* commenting for changing dropdown to radiobutton 4/9/2023 */}
              {/* <SelectInput
                name="status_id"
                selectLabel="Status"
                onChange={handleChange}
                value={input.status_id}
                optionsList={list}
              />  */}

              {/* adding radiobutton */}

              <RadioButton
                // radioButtonTitle="Status"
                optionsList={list}
                // selectedValue={input.status_id}
                selectedValue={selectedValue}
                handleChange={radiohandleChange}
              // name="status_id"

              />
              {/* <select
                name="status_id"
                class="form-select input-text-box"
                onChange={handleChange}
                value={input.status_id}
              >
                <option value={statusPlaceholder.value} disabled selected hidden>{statusPlaceholder.name}</option>
                {list.map((item) => {
                  return <option value={item.value}>{item.label}</option>
                })} */}

              {/* <option value="Select" disabled selected hidden>Select</option>
                <option value={"1"}
                // selected={input?.status_id === "1" ? true : false}
                >Active</option>
                <option value={"2"}
               
                // selected={input?.status_id === "2" ? true : false}
                >InActive</option> */}

              {/* </select> */}
            </div>
          </div>

          {/* status_reason,status_id */}

          <div className="col-md-12">

            <InputBox
              label="*Reason"
              multiline
              rows={2}
              fullWidth
              name="status_reason"
              onChange={handleChange}
              value={input?.status_reason}
            />

            {/* <textarea
            type="text"
            name="status_reason"
            onChange={handleChange}
            class="form-control input-text-box"
            placeholder=" Status Reason"
            value={input?.status_reason}
          /> */}

          </div>

          <div className="pt-2 mt-3 d-flex justify-content-center">
            <button
              className="btn btn_submit"
              // onClick={(e) => UpdateUserStatus(e)}
              onClick={handleUserUpdate}
            >
              Submit
            </button>
          </div>
        </div>

      </div >
    </>
  );
};

export default UserStatusPage;
