import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { DataGrid } from "@mui/x-data-grid";
import Box from '@mui/material/Box';
import { darken, lighten, styled } from '@mui/material/styles';
import { createCustomRules, createOneRule, getActiveCustomer, getCustomFileRules, getCustomRules, getDocumentName } from "../../../../Services/AdminServices";
import Loader from "../../../../utils/Loader/Loader";
import BasicButton from "../../../Reusable_Components/Button";
import InputBox from "../../../Reusable_Components/InputBox";
import SelectInput from "../../../Reusable_Components/SelectInput";
import { messages } from "../../../Messages";
import dayjs from "dayjs";
import ErrorCatch from "../../../Reusable_Components/ErrorCatch";
import { useSelector } from "react-redux";
import CryptoJS from 'crypto-js';


// Dtata background change
const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .super-app-theme--api_active_03': {
        backgroundColor: getBackgroundColor("#F7F5BC", theme.palette.mode),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                "#F7F5BC",
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                "#F7F5BC",
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    "#F7F5BC",
                    theme.palette.mode,
                ),
            },
        },
    },

    '& .super-app-theme--api_active_01': {
        backgroundColor: getBackgroundColor(
            theme.palette.success.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.success.main,
                    theme.palette.mode,
                ),
            },
        },
    },
    '& .super-app-theme--api_active_02': {
        backgroundColor: getBackgroundColor(
            theme.palette.error.main,
            theme.palette.mode,
        ),
        '&:hover': {
            backgroundColor: getHoverBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
        },
        '&.Mui-selected': {
            backgroundColor: getSelectedBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode,
            ),
            '&:hover': {
                backgroundColor: getSelectedHoverBackgroundColor(
                    theme.palette.error.main,
                    theme.palette.mode,
                ),
            },
        },
    },
}));


const CustomRulesSuperAdmin = ({ customRules }) => {
    let email = localStorage.getItem("Logindata")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [item, setItem] = useState({
        "_documetkeys_id": "",
        "_rule_name": "",
        "_description": "",
        "_comp_id": "",
        "_custom_rule_id": "",
        "email": email,
        // "_rule_type": "custom",
        "_rule_type": "",
        "_lambda_url": ""
    })
    const [customerList, setCustomerList] = useState([])
    const [customRuleList, setCustomRuleList] = useState([])
    const [customRuleName, setCustomRuleName] = useState("")
    const [apiFormatState2, setApiFormatState2] = useState([]);
    const [rowData, setRowData] = useState([])
    const userDetails = useSelector((state) => state)


    const userDetailsLog = userDetails.auth.userDetails

    const [rulesType, setRulesType] = useState([
        {
            name: "Custom File",
            value: "customfile"
        },
        // {
        //     name: "Lambda File",
        //     value: "lambdafile"
        // },
        // {
        //     name: "Sp File",
        //     value: "spfile"
        // }
    ])

    // uam role object start
    const appsubmodulename = "Services"
    const appfunctionalityname = "Register"
    const userRoleObjects = userDetails.auth.userRoleObjects[0].objectAccess
    const [appObjects, setAppObjects] = useState(
        {
            "Submit": false,
        }
    )
    console.log("userDetails", userDetails)

    const updateAppObjects = (arr) => {
        let objectsArray = []
        arr.map((obj) => {
            console.log("updateButtons", obj)
            // if (obj.objecttypename == "button") {
            objectsArray.push(obj)
            // }
        })
        const finalObject = objectsArray.reduce((acc, { appobjectname }) => {
            acc[appobjectname] = true;
            return acc;
        }, {});
        setAppObjects({ ...appObjects, ...finalObject })
        console.log("objectsArray", objectsArray)
        console.log("finalObject", finalObject)
    }

    useEffect(() => {
        console.log("appObjects", appObjects)
    }, [appObjects])

    useEffect(() => {
        console.log("userRoleObjects", userRoleObjects)
        const filteredList = []
        userRoleObjects.filter((obj) => {
            if (obj.appsubmodulename == appsubmodulename && obj.appfunctionalityname == appfunctionalityname) {
                filteredList.push(obj)
            }
        })

        console.log("filteredList", filteredList)
        updateAppObjects(filteredList)

    }, [])

    // uam role object end

    const getAPIFormat2 = async () => {
        try {
            const response = await getDocumentName();
            console.log("getAPIFormat2", response.data.data);
            if (response.status == 200) {
                let data = response.data.data
                let finalArr = []
                data.map((obj) => {
                    finalArr.push({
                        name: obj._docname,
                        value: obj._document_id
                    })
                })
                setApiFormatState2(finalArr);
            }

        } catch (error) {
            console.log("error", error);
            setError(error)
        }
    };

    const encrypt = (plaintext) => {
        console.log("encryptPlaintext", plaintext);
        return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(plaintext))
        .replace(/=+$/, '') // Remove padding
        .replace(/\+/g, '-') // Replace + with -
        .replace(/\//g, '_'); // Replace / with _
    };

    const getActiveCustomerList = async () => {
        setLoading(true)
        try {
            let currentUserLoginEmail = encrypt(userDetailsLog.email)
            const response = await getActiveCustomer(currentUserLoginEmail);
            console.log("getActiveCustomer", response);
            if (response.status == 200) {
                let data = response.data.data
                let finalArr = []
                data.map((obj) => {
                    finalArr.push({
                        name: obj._customername,
                        value: obj.comp_id
                    })
                })
                setCustomerList(finalArr);
            }

        } catch (error) {
            console.log("error", error);
            setError(error)
        }
        setLoading(false)
    };

    const getCustomRulesList = async (compID) => {

        setLoading(true)
        try {
            await getCustomRules(compID).then((res) => {
                console.log("getcustomruleres", res)
                if (res.status == 200) {
                    let data = res.data.data
                    let finalArr = []
                    data.map((obj) => {
                        finalArr.push({
                            name: obj._rule_name,
                            value: obj._rule_id
                            // value: obj._rule_name

                        })
                    })
                    setCustomRuleList(finalArr)
                }
            })
        }
        catch (error) {
            console.log("getcustomrulereserror", error)
            setError(error)
        }
        setLoading(false)
    }


    const columns = [
        {
            field: "rule_name",
            headerName: "Rule Name",
            headerClassName: "super-app-theme--header",
            // width: 50,
            flex: 0.3,
        },
        {
            field: "description",
            headerName: "Description",
            // width: 250,
            flex: 0.5,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "comp_name",
            headerName: "Customer",
            headerClassName: "super-app-theme--header",
            // width: 150,
            flex: 0.4,
        },
        {
            field: "rulestype",
            headerName: "Rule Type",
            headerClassName: "super-app-theme--header",
            // width: 150,
            flex: 0.2,
        },
        {
            field: "created_date",
            headerName: "Created Date",
            headerClassName: "super-app-theme--header",
            // width: 130,
            flex: 0.2,
        },
        // {
        //   field: "_request_datetime",
        //   headerName: "Date",
        //   // width: 100,
        //   flex: 0.4,
        //   headerClassName: "super-app-theme--header",
        //   valueFormatter: (params) => convertDateFormat(params.value)
        // },
        // {
        //   field: "_Approve/Reject",
        //   headerClassName: "super-app-theme--header",
        //   flex: 0.3,
        //   headerName: "Action",
        //   renderCell: (params) => {

        //     return (
        //       <div className="cellAction">
        //         <img src={approvere} alt="icon" style={{ width: '1.2rem' }} onClick={() => showModal(params)} />
        //       </div>
        //     );
        //   },
        // },
    ];


    const getCustomFile = async () => {
        setLoading(true)
        try {
            let response = await getCustomFileRules()
            console.log("getCustomFileresponse", response)
            if (response.status == 200) {
                let data = response.data.data
                data.forEach((obj) => {
                    obj["created_date"] = dayjs(obj.created_date).format(
                        "DD-MM-YYYY H:mm:ss"
                    );
                });
                data.sort((a, b) => b.rule_id - a.rule_id);
                console.log("data_", data)
                setRowData(data)
            }
        }
        catch (error) {
            console.log("getCustomFileerror", error)
            setError(error)
        }
        setLoading(false)
    }

    const handleInput = (e) => {
        console.log("handleInputevent", e.target, customRuleList)
        let { name, value } = e.target
        setItem({ ...item, [name]: value })
        if (name == "_custom_rule_id") {
            let ruleName = customRuleList.filter((obj) => obj.value == value)[0]["name"]
            console.log("ruleName", ruleName)
            setCustomRuleName(ruleName)
        }
    }

    const [docType, setDocType] = useState({
        name: "Select",
        value: ""
    })

    useEffect(() => {
        setDocType({
            name: "Select",
            value: ""
        })
        setItem({
            "_documetkeys_id": "",
            "_rule_name": "",
            "_description": "",
            "_comp_id": "",
            "_custom_rule_id": "",
            "email": email,
            // "_rule_type": "custom"
            "_rule_type": "",
            "_lambda_url": ""
        })
        setCustomRuleName("")
    }, [])

    useEffect(() => {
        if (customRules == true) {
            setDocType({
                name: "Select",
                value: ""
            })

            setItem({
                "_documetkeys_id": "",
                "_rule_name": "",
                "_description": "",
                "_comp_id": "",
                "_custom_rule_id": "",
                "email": email,
                // "_rule_type": "custom"
                "_rule_type": "",
                "_lambda_url": ""
            })
            setCustomRuleName("")

            getAPIFormat2()
            // getCustomRulesList()
            getActiveCustomerList()
            getCustomFile()
        }

    }, [customRules]);

    // useEffect(() => {
    //     if (customRules == false) {
    //         return
    //     }
    //     let { _comp_id } = item
    //     if (undefined == _comp_id || _comp_id == "") {
    //         _comp_id = 0
    //         getCustomRulesList(_comp_id)

    //     } else {
    //         getCustomRulesList(_comp_id)
    //     }

    // }, [customRules, item._comp_id])

    // const checkIfURLExist = ()=>{

    // }

    const onSubmitApi = async () => {
        console.log("item", item)
        const { _rule_name, _description, _documetkeys_id, _comp_id, _custom_rule_id, email, _rule_type, _lambda_url } = item
        if (_rule_name == "" || _description == "" || _documetkeys_id == "" || _rule_type == "") {
            toast.error(messages.fillRequiredFields)
            return
        }

        if (_rule_type == "lambdafile" && _lambda_url == "") {
            toast.error(messages.fillRequiredFields)
            return
        }
        if (_rule_type == "lambdafile" && _lambda_url.length > 0) {
            if (_lambda_url.includes("amazonaws.com") == false) {
                toast.error("URL is not valid")
                return
            }
        }

        // let sendData = {
        //     item
        // }

        let sendData =
        {
            "data1": {
                "_document_id": _documetkeys_id,
                "_comp_id": _comp_id == "" ? null : _comp_id,
                "_rule_name": _rule_name,
                "_description": _description,
                "useremail": email,
                // "_custom_rule_id": _custom_rule_id == "" ? null : _custom_rule_id,
                "_custom_rule_id": null,
                "_custom_rule_name": customRuleName,
                // "_rule_type": "customfile"
                "_rule_type": _rule_type,
                // "_lambda_url": _rule_type !== "lambdafile" ? "" : _lambda_url
                "url": _rule_type !== "lambdafile" ? "" : _lambda_url,
                isLambda: _rule_type == "lambdafile" ? 1 : 0,
                isDocument: _rule_type == "customfile" ? 1 : 0,
                isHeader: 0,
                isFile: 1
            }
        }

        // if (_rule_type !== "lambdafile") {
        //     delete sendData.data1._lambda_url
        // }

        console.log("senddata", sendData)
        setLoading(true)
        try {
            // await createCustomRules(sendData)
            await createOneRule(sendData)
                .then((response) => {
                    console.log("responsecreatecustomrule", response)
                    if (response.status == (200 || 201)) {
                        // toast.success(response.statusText)
                        toast.success(messages.addedSuccess)
                        getCustomFile()
                    }
                    else {
                        toast.error(response.statusText)
                    }
                })
        }
        catch (error) {
            console.log("errorcreatecustomrule", error)
            // if ("response" in error) {
            //     toast.error(error.response.data.msg, {
            //         position: "top-center",
            //         autoClose: 5000,
            //     });
            // } else {
            //     toast.error(error.message, {
            //         position: "top-center",
            //         autoClose: 5000,
            //     });
            // }
            setError(error)
        }

        setItem({
            "_documetkeys_id": "",
            "_rule_name": "",
            "_description": "",
            "_comp_id": "",
            "_custom_rule_id": "",
            "email": email,
            // "_rule_type": "custom"
            "_rule_type": "",
            "_lambda_url": ""
        })
        setCustomRuleName("")
        setLoading(false)
    }

    return (
        <>
            <Loader loading={loading} />
            <Toaster position="top-center" reverseOrder={false} />
            <ErrorCatch err={error} />
            <div className="container-fluid">
                {/* <div className="row d-flex justify-content-end">
                    <div className="col-sm-3 d-flex justify-content-end">
                        <button
                            className="btn btn-primary"
                        onClick={handleAddRules}
                        >
                            Add Items
                        </button>
                    </div>
                </div> */}

                <div className="row">
                    <div className="col-md-10">
                        <div className="card bg-transparent" style={{ border: "none" }}>
                            <p className="page-title">
                                Register Custom Parameter
                            </p>
                        </div>
                    </div>
                    {/* <div className="col-md-2 d-flex justify-content-end gap-3"> */}
                    {/* <button
                            onClick={onSubmitApi}
                            class="float-start btn btn-primary"
                        >
                            Submit
                        </button> */}

                    {/* <div className="col-md-2 py-2 d-flex justify-content-end">
                        <BasicButton
                            text="Submit"
                            onClick={onSubmitApi}
                        />
                    </div> */}

                    {/* </div> */}
                </div>

                <div class="row mb-3">
                    <div className="col-md-2 py-1">
                        <SelectInput
                            margin="0"
                            selectLabel="*Rule Type"
                            name="_rule_type"
                            value={item._rule_type}
                            onChange={(e) => handleInput(e)}
                            optionsList={rulesType}
                        />
                    </div>

                    <div className="col-md-2 py-1">
                        <SelectInput
                            margin="0"
                            selectLabel="*Document"
                            name="_documetkeys_id"
                            value={item._documetkeys_id}
                            onChange={(e) => handleInput(e)}
                            optionsList={apiFormatState2}
                        />
                    </div>

                    <div className="col-md-2 py-1 form-floating">
                        {/* <input
                            id="parameterName"
                            type="text"
                            name="purpose"
                            // className="form-control"
                            value={item.purpose}
                            onChange={(e) => handleInput(e)}
                            class="form-control inp_text"
                            placeholder={"Parameter"}
                        />
                        <label style={{ marginLeft: "5px" }} for="parameterName">Parameter</label> */}

                        <InputBox
                            // id="_rule_name"
                            name="_rule_name"
                            label="*Parameter"
                            fullWidth
                            // className="input-text-box"
                            onChange={(e) => handleInput(e)}
                            value={item._rule_name}
                        />

                    </div>

                    {/* <div className="col-md-4">
                        <input
                            type="text"
                            name="description"
                            className="form-control"
                            value={item.description}
                            onChange={(e) => handleInput(e)}
                            class="form-control"
                            placeholder={"Rule Name"}
                        />
                    </div> */}

                    <div className="col-md-3 py-1 form-floating">

                        {/* <select
                            id="rulesName"
                            name="rules_name"
                            onChange={(e) => handleInput(e)}
                            class="form-control"
                            value={item.rules_name}
                        >
                            <option value={docType.value} disabled selected hidden>{docType.name}</option>
                            {customRuleList.length > 0 && customRuleList.map((val) => {
                                const { custom_rules_id, rules_name } = val;
                                return <option value={rules_name}>{rules_name}</option>;
                            })}
                        </select>
                        <label style={{ marginLeft: "5px" }} for="rulesName">Custom Rule</label> */}

                        <InputBox
                            // id="_description"
                            name="_description"
                            label="*Description"
                            fullWidth
                            // className="input-text-box"
                            onChange={(e) => handleInput(e)}
                            value={item._description}
                        />

                    </div>

                    {/* <div className="col-md-2">
                        <SelectInput
                            margin="0"
                            selectLabel="Custom Rule"
                            // id="_custom_rule_id"
                            name="_custom_rule_id"
                            value={item._custom_rule_id}
                            onChange={(e) => handleInput(e)}
                            optionsList={customRuleList}
                        />
                    </div> */}

                    <div className="col-md-3 py-1">
                        <SelectInput
                            margin="0"
                            selectLabel="Customer"
                            // id="_comp_id"
                            name="_comp_id"
                            value={item._comp_id}
                            onChange={(e) => handleInput(e)}
                            optionsList={customerList}
                        />
                    </div>

                    {/* <div className="col-md-2 gap-3">
                        <button
                            // disabled={(docID !== "") && (customerName !== "") && (ruleName !== "") && (ruleDescription !== "") ? false : true}
                            // type="submit"
                            onClick={onSubmitApi}
                            class="float-start btn btn-primary"
                        >
                            Submit
                        </button>
                    </div> */}

                    {/* <div className="col-md-2 py-1 d-flex justify-content-end">
                        <BasicButton
                            text="Submit"
                            onClick={onSubmitApi}
                        />
                    </div> */}
                </div>

                <div className="row mb-3">
                    <div className="col-md-8">
                        {/* {item._rule_type == "lambdafile" &&
                            <InputBox
                                name="_lambda_url"
                                label="*URL"
                                fullWidth
                                onChange={(e) => handleInput(e)}
                                value={item._lambda_url}
                            />
                        } */}
                    </div>

                    <div className="col-md-4 d-flex justify-content-end">
                        {appObjects["Submit"] && <BasicButton
                            text="Submit"
                            onClick={onSubmitApi}
                        />}
                    </div>
                </div>

                <div className="row">
                    <div
                        className="col-md-12"
                    // style={{ height: "24rem", overflowY: "auto" }}
                    >
                        <Box sx={{ height: "46vh", width: "100%", overflowY: "auto", overflowX: "hidden" }}>
                            <StyledDataGrid
                                sx={{

                                    borderColor: "secondary",
                                    "& .MuiDataGrid-cell:hover": {
                                        color: "primary.main",
                                    },
                                    "& .MuiDataGrid-columnHeader": {
                                        backgroundColor: "#0E3386",
                                        fontSize: "12px",
                                        color: "white",
                                        padding: "10px",

                                        // Set your desired header background color
                                    },
                                    "& .MuiDataGrid-cell": {
                                        fontSize: "10px",
                                        padding: "10px",
                                        borderRight: "2px solid #e0e0e0",
                                        // Adjust the font size as needed
                                        // Adjust the cell padding as needed
                                    },
                                }}
                                rowHeight={30}
                                columns={columns}
                                rows={rowData}
                                hideFooter
                                // getRowClassName={(params) => `super-app-theme--${params.row._api_status_id}`}
                                getRowId={(data) => data.rule_id}

                            />
                        </Box>
                    </div>
                </div>

            </div>
        </>
    );
};

export default CustomRulesSuperAdmin;
