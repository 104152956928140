import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import env from "../../../../Services/Environment";
import { getResponseFormat, getRules, createNewRules, createOneRule, getAllParameterRules, getDocumentKeys, getDocumentName, getActiveCustomer, getUserRules, addNewItems, getCustomRules, getParameterDocSearch, getDefaultRules, getRawDataDetails, addNewItemsForEdit, getTargetColKeys, deleteTargetColKeys } from "../../../../Services/AdminServices";
import { Modal, Button } from "antd";
import { Radio } from 'antd';
import Loader from "../../../../utils/Loader/Loader";
import { useStyleRegister } from "antd/es/theme/internal";
import SetDocumentHeaders from "../../CustomerAdmin/Document/SetDocumentHeaders";
import SelectInputWithAdd from "../../../Reusable_Components/SelectInputWithAdd";
import SelectInput from "../../../Reusable_Components/SelectInput";
import InputBox from "../../../Reusable_Components/InputBox";
import SelectInputAntd from "../../../Reusable_Components/SelectInputAntd";
import InputBoxAntd from "../../../Reusable_Components/InputBoxAntd";
import BasicButton from "../../../Reusable_Components/Button";
import RadioButton from "../../../Reusable_Components/RadioButton";
import { messages } from "../../../Messages";
import { useRef } from "react";
import Checkbox from "../../../Reusable_Components/CheckBox";
import CheckboxComp from "../../../Reusable_Components/CheckBox";
import ErrorCatch from "../../../Reusable_Components/ErrorCatch";
import AgGridComponent from "../../../Reusable_Components/AgGridComponent";
import { BsFillTrashFill } from 'react-icons/bs';
import { DataGrid } from "@mui/x-data-grid";
import warning from "../../../../Assets/Images/warning.png"
import { useSelector } from "react-redux";
import CryptoJS from 'crypto-js';


function AdminAddColumns({ handleAddColumnOk, isAddColumnModal, handleAddColumnCancel, ruleList }) {
    const navigate = useNavigate();
    const userDetails = useSelector((state) => state)
    const [customerList, setCustomerList] = useState([])
    const [error, setError] = useState()
    const [apiFormatState2, setApiFormatState2] = useState([]);
    const [responseFormat, setResponseFormat] = useState([])
    const [ruleFormat, setRuleFormat] = useState([])
    const [customerPlaceHolder, setCustomerPlaceholder] = useState({
        name: "Select Customer",
        value: ""
    })
    const [docType, setDocType] = useState({
        name: "Select a Document Type",
        value: ""
    })

    const [formatType, setFormatType] = useState({
        name: "Select a Format Type",
        value: ""
    })

    // const [keyType, setKeyType] = useState({
    //   name: "Key",
    //   value: ""
    // })

    const [ruleType, setRuleType] = useState({
        name: "Select a Rule Type",
        value: ""
    })

    const [files, setFiles] = useState("")
    const [parameterFilesList, setParameterFilesList] = useState([])
    const [isDefaultRule, setIsDefaultRule] = useState(false)
    const [defaultRule, setDefaultRule] = useState({
        ruleId: "", ruleName: "", ruleDescription: "",
    })
    const [defaultCheckModal, setDefaultCheckModal] = useState(false)
    const [defaultRulePayload, setDefaultRulePayload] = useState()
    const [isLambda, setIsLambda] = useState(false)
    const [filesList, setFilesList] = useState([
        {
            name: "Custom File",
            value: "customfile"
        },
        {
            name: "Lambda File",
            value: "lambdafile"
        }
    ])

    const [columnName, setColumnName] = useState("")
    const [selectedType, setSelectedType] = useState("")
    const [typeList, setTypeList] = useState([
        {
            label: "Header",
            value: "document_wise"
        },
        {
            label: "Key",
            value: "key_wise"
        }
    ])

    // useEffect(() => {
    //     setSelectedType()
    // }, [])

    const [keySpecData, setKeySpecData] = useState([])
    // const [paramterID, setParamterID] = useState("");
    const [keySpecField, setKeySpecField] = useState({
        // "operation": "KeyRule",
        "_paramvalue": "",
        "_parameter_id": "",
        "_documetkeys_id": "",
        // "_seq_no":""
    })

    const [docSpecData, setDocSpecData] = useState([])
    const [docSpecField, setDocSpecField] = useState({
        "_paramvalue": "",
        "_parameter_id": "",
        "_documetkeys_id": "",
        // "_seq_no": ""
    })

    const [isError, setIsError] = useState(false)

    let email = localStorage.getItem("Logindata")

    const [inputData, setInputData] = useState();
    const [customerName, setCustomername] = useState("")
    const [docID, setDocID] = useState("")
    const [ruleName, setRuleName] = useState("")
    const [ruleDescription, setRuleDescription] = useState("")
    const [IDNo, setIDNo] = useState()
    const [formatID, setFormatID] = useState("")
    const [ruleID, setRuleID] = useState("")
    const [loading, setLoading] = useState(false)
    // const [salesOrderNo, setSalesOrderNo] = useState()

    const [isKeyUpdate, setIsKeyUpdate] = useState(false)
    const containerRef = useRef(null)
    const docContainerRef = useRef(null)
    const keyContainerRef = useRef(null)

    // expression changes
    const [expressionModal, setExpressionModal] = useState(false)
    const [isSubString, setIsSubString] = useState(false)
    const [isConcat, setIsConcat] = useState(false)
    const [isMap, setIsMap] = useState(false)

    const [expression, setExpression] = useState("")
    const [fromValue, setFromValue] = useState("")
    const [toValue, setToValue] = useState("")
    const [inx, setInx] = useState()
    const [paramValue, setParamValue] = useState("")
    const [selectedParam, setSelectedParam] = useState()

    const [isDocType, setIsDocType] = useState(false)

    const [sequenceNo, setSequenceNo] = useState()
    const [colRowData, setColRowData] = useState([])
    const [isDeleteModal, setIsDeleteModal] = useState(false)
    const [deleteData, setDeleteData] = useState([])

    const userDetailsLog = userDetails.auth.userDetails



    const handleScrollToLastChild = () => {
        if (containerRef.current) {
            const lastChild = containerRef.current.lastChild
            lastChild.scrollIntoView({ behavior: "smooth" })
        }
    }

    const scrollToBottom = () => {
        const element = containerRef.current;
        if (element) {
            element.scrollTop = element.scrollHeight;
        }
    };

    const scrollToBottomDoc = () => {
        let element = docContainerRef.current;
        if (element) {
            element.scrollTop = element.scrollHeight;
        }
        setIsKeyUpdate(false)
    };

    const scrollToBottomKey = () => {
        let element = keyContainerRef.current;
        if (element) {
            element.scrollTop = element.scrollHeight;
        }
        setIsKeyUpdate(false)
    };

    const handleLambdaCheckBox = (e) => {
        setIsLambda(e.target.checked)
    }

    useEffect(() => {
        if (docSpecData.length > 0) {
            scrollToBottomDoc()
        }
    }, [docSpecData])

    // useEffect(() => {
    //   if (keySpecData.length > 0) {
    //     scrollToBottom()
    //     scrollToBottomKey()
    //     // handleScrollToLastChild()
    //   }
    // }, [keySpecData])

    function generateRandom() {
        var length = 8,
            charset =
                "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    const handleAddField = () => {
        console.log("keySpecData_", keySpecData)
        setKeySpecData([...keySpecData, { ...keySpecField }])
        setKeySpecField({
            // "operation": "KeyRule",
            "_paramvalue": "",
            "_parameter_id": "",
            "_documetkeys_id": "",
            // "_seq_no":""
        })
        setNewKeyForDoc("")
        setNewKeyForKey("")
        scrollToBottomKey()
        scrollToBottom()
    }

    const handleDeleteField = (index) => {
        let newKeySpecData = [...keySpecData]
        newKeySpecData.splice(index, 1)
        setKeySpecData(newKeySpecData)
        setNewKeyForDoc("")
        setNewKeyForKey("")
    }

    const handleAddDocField = () => {
        console.log("docSpec_", docSpecData, docSpecField)
        setDocSpecData([...docSpecData, { ...docSpecField }])
        setDocSpecField({
            // "operation": "KeyRule",
            "_paramvalue": "",
            "_parameter_id": "",
            "_documetkeys_id": "",
            // "_seq_no":""
        })
        setNewKeyForDoc("")
        setNewKeyForKey("")
    }

    const handleDeleteDocField = (index) => {
        let newDocSpecData = [...docSpecData]
        newDocSpecData.splice(index, 1)
        setDocSpecData(newDocSpecData)
        setNewKeyForDoc("")
        setNewKeyForKey("")
    }


    const [apiDetailState, setApiDetailState] = useState([]);

    const [hideSelect, setHideSelect] = useState(true);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newRuleData, setNewRuleData] = useState({})
    const [parameterValue, setParameterValue] = useState("")
    const [paramDocValue, setParamDocValue] = useState("")
    const [parameter, setParameter] = useState({
        name: "Select a Parameter Type",
        value: ""
    })

    const [newKeysModal, setNewKeysModal] = useState(false)
    const [newKeys, setNewKeys] = useState()
    const [documentKeysList, setDocumentKeysList] = useState([])
    const [filterDocumentKeyList, setFilterDocumentKeyList] = useState([])
    const [parameterRulesList, setParameterRulesList] = useState([])
    const [newValueNo, setNewValueNo] = useState()
    const [isSetRuleModalOpen, setIsSetRuleModalOpen] = useState(false)

    const [docKeys, setDocKeys] = useState("")
    const [paramRule, setParamRule] = useState("")
    const handleNewValueNo = (e) => {
        const { name, value } = e.target;
        let val = e.target.value.replace(/[A-Za-z' `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/g, '');
        setNewValueNo(val)
    };

    const handleNewKeys = (e) => {
        const { name, value } = e.target;
        setNewKeys(value)
    }

    const [newParamNames, setNewParamNames] = useState()
    const handleNewParamNames = (e) => {
        const { name, value } = e.target;
        // setInputData({ ...inputData, [name]: value });
        setNewParamNames(value)
    }

    const [ruleSpec, setRuleSpec] = useState([])

    const [radioSelection, setRadioSelection] = useState(1)
    const [customRule, setCustomRule] = useState("")
    const [customRuleName, setCustomRuleName] = useState("")
    const [customRuleList, setCustomRuleList] = useState([])
    const [radioOptions, setRadioOptions] = useState([
        {
            label: "Document",
            value: 1,
            status: true
        },
        {
            label: "Header/Details",
            value: 2,
            status: false
        },
        {
            label: "SP",
            value: 3,
            status: false
        }
    ])

    const [selectedKeyFrom, setSelectdeKeyFrom] = useState("")
    const [selectedKeyTo, setSelectdeKeyTo] = useState("")
    const [assignValue, setAssignValue] = useState("")
    const [rawData, setRawData] = useState([])

    const columnData = [
        {
            field: "key",
            headerName: "Name",
            // width: 70,
            flex: 0.5,
            headerClassName: "super-app-theme--header",
        },
        {
            headerName: "Type",
            field: "key_type",
            // width: 150,
            flex: 0.5,
            headerClassName: "super-app-theme--header",
        },
        {
            headerClassName: "super-app-theme--header",
            field: "delete",
            headerName: "Delete",
            // width: 70,
            flex: 0.2,
            cellRenderer: (params) => {
                console.log("paramsRow_", params)
                return (
                    <>
                        {params.data.isdelete == 1 && <div className="cellAction">
                            <a onClick={() => handleDeletModal(params)} style={{ color: "red", fontSize: "15px", cursor: 'pointer' }}>
                                <BsFillTrashFill />
                            </a>
                        </div>
                        }
                    </>
                );
            },
        },

    ]

    const handleDeletModal = (params) => {
        setIsDeleteModal(true)
        setDeleteData(params)
    }

    // const handleDeleteCol = async (params) => {
    //     console.log("params", params.data)
    //     let rowData = params.data
    //     let deleteColResponse = await deleteTargetColKeys(params.data.documetkeys_id)
    //     console.log("deleteColResponse", deleteColResponse);
    //     if (deleteColResponse.status == 200) {
    //         toast.success(deleteColResponse.data.msg)
    //         getDocumentKeysList()
    //     }
    // }


    useEffect(() => {
        if (isAddColumnModal) {
            setRuleName("")
            setRuleDescription("")
            setDocID("")
            setCustomername("")
            setCustomRule("")
            setFiles("")
            setIsDefaultRule(false)
            setIsLambda(false)
            setParameterFilesList([])
            setCustomRuleName("")
            setDocSpecData([])
            setKeySpecData([])
            setColumnName("")
            setSelectedType("key_wise")
            setColRowData([])
        }
    }, [isAddColumnModal])

    useEffect(() => {
        if (isAddColumnModal == false) {
            return
        }

    }, [customerName, docID, isAddColumnModal])

    useEffect(() => {
        if (isModalOpen) {
            // setParameter({
            //   name: "Select a Parameter Type",
            //   value: ""
            // })
            setParameterValue("")
            setParamDocValue("")
            FetchApiDetails()
        }
    }, [isModalOpen])

    // const encrypt = (plaintext) => {
    //     console.log("encryptPlaintext", plaintext);
    //     let encryptedPassword = CryptoJS.AES.encrypt(plaintext, process.env.REACT_APP_SECRET_KEY).toString();
    //     return encryptedPassword
    // };

    const encrypt = (plaintext) => {
        console.log("encryptPlaintext", plaintext);
        return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(plaintext))
        .replace(/=+$/, '') // Remove padding
        .replace(/\+/g, '-') // Replace + with -
        .replace(/\//g, '_'); // Replace / with _
    };

    const getActiveCustomerList = async () => {
        try {
            let currentUserLoginEmail = encrypt(userDetailsLog.email)
            const response = await getActiveCustomer(currentUserLoginEmail);
            console.log("getActiveCustomer", response);
            if (response.status == 200) {
                let data = response.data.data
                let finalArr = []
                data.map((obj) => {
                    finalArr.push({
                        name: obj._customername,
                        value: obj.comp_id
                    })
                })
                setCustomerList(finalArr);
            }

        } catch (error) {
            console.log("error", error);
            setError(error)
        }
    };

    const getAPIFormat2 = async () => {
        try {
            const response = await getDocumentName();
            console.log("getAPIFormat2", response.data.data);
            if (response.status == 200) {
                let data = response.data.data
                let finalArr = []
                data.map((obj) => {
                    finalArr.push({
                        name: obj._docname,
                        value: obj._document_id
                    })
                })
                setApiFormatState2(finalArr);
            }

        } catch (error) {
            console.log("error", error);
            setError(error)
        }
    };

    const getDocumentResponseFormat = async () => {
        try {
            const response = await getResponseFormat()
            console.log("getDocumentResponseFormat", response.data.data);
            setResponseFormat(response.data.data);
        } catch (error) {
            console.log(error);
            setError(error)
        }
    };

    const getRuleType = async () => {
        try {
            const response = await getRules()
            console.log("getRules", response.data.data);
            setRuleFormat(response.data.data);
        } catch (error) {
            console.log(error);
            setError(error)
        }
    };

    useEffect(() => {
        getDocumentResponseFormat()
        getActiveCustomerList()
        getAPIFormat2();
        // getRuleType()
        setCustomerPlaceholder({
            name: "Select Customer",
            value: ""
        })
        setDocType({
            name: "Select a Document Type",
            value: ""
        })
        setRuleName("")
        setRuleDescription("")
        setDocID("")
        setCustomername("")
        // setIDNo()
    }, []);


    useEffect(() => {
        if (customerName !== "" && docID !== "") {
            getDocumentKeysList()
        }

    }, [customerName, docID])

    const initialValues = {
        email: localStorage.getItem("Logindata"),
        // _document_id: "",
        // _documetkeys_id: "",

    };


    const handleCustomerName = (e) => {
        const { name, value } = e.target;
        setInputData({ ...inputData, [name]: value });
        setCustomername(value)
        setDocSpecData([])
        setKeySpecData([])
    };

    const handleDocumentType = (e) => {
        const { name, value } = e.target;
        setInputData({ ...inputData, [name]: value });
        setDocID(value)
        setDocSpecData([])
        setKeySpecData([])
    };




    const handleIDNo = (e) => {
        const { name, value } = e.target;
        let val = e.target.value.replace(/[A-Za-z' `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/g, '');
        setInputData({ ...inputData, [name]: val });
        setIDNo(val)
    };

    const [newKeyForDoc, setNewKeyForDoc] = useState("")
    const [newKeyForKey, setNewKeyForKey] = useState("")

    const onEnterNewItemForDoc = (param, followUpId) => {
        console.log("onEnterNewItemForDoc", param, followUpId, "rowdataforDoc", param.target.value)
        setNewKeyForDoc(param.target.value)
    }

    const handleAddNewItemForDoc = async () => {
        if (undefined == newKeyForDoc || newKeyForDoc == "") {
            toast.error("Please add items")
            return
        }
        let sendData = {
            "data": {
                "addDynamicKeys": [
                    {
                        "key": newKeyForDoc,
                        "description": "",
                        "key_type": "document_wise",
                        "comp_id": customerName,
                        "docid": docID
                    }
                ]
            }
        }
        console.log("handleAddNewItemForDocsenddata", sendData)

        setLoading(true)
        try {
            let response = await addNewItems(sendData)
            console.log("responseaddnewitems", response)
            if (response.status == 201) {
                toast.success(response.statusText)
            }
            else {
                toast.error(response.statusText)
            }
        }
        catch (error) {
            console.log("erroraddnewitems", error)
            // if ("response" in error) {
            //   toast.error(error.response.data.msg)
            // }
            // else {
            //   toast.error(error.message)
            // }
            setError(error)

        }
        setNewKeyForDoc("")
        setNewKeyForKey("")
        getDocumentKeysList()
        setLoading(false)
    }

    const onEnterNewItemForKey = (param, followUpId) => {
        console.log("onEnterNewItemForKey", param, followUpId, "rowdataforDoc")
        setNewKeyForKey(param.target.value)
    }

    const handleAddNewItemForKey = async () => {
        if (undefined == newKeyForKey || newKeyForKey == "") {
            toast.error("Please add items")
            return
        }
        let sendData = {
            "data": {
                "addDynamicKeys": [
                    {
                        "key": newKeyForKey,
                        "description": "",
                        "key_type": "key_wise",
                        "comp_id": customerName,
                        "docid": docID
                    }
                ]
            }
        }

        setLoading(true)
        try {
            let response = await addNewItems(sendData)
            console.log("responseaddnewitems", response)
            if (response.status == 201) {
                toast.success(response.statusText)

            }
            else {
                toast.error(response.statusText)
            }
        }
        catch (error) {
            console.log("erroraddnewitems", error)
            // if ("response" in error) {
            //   toast.error(error.response.data.msg)
            // }
            // else {
            //   toast.error(error.message)
            // }
            setError(error)
        }
        setNewKeyForKey("")
        getDocumentKeysList()
        setLoading(false)
    }



    // truncate - 9
    // rename - 10
    // position - 11
    // hide - 12

    // check duplicate truncate for same elements
    const containsDuplicateTruncate = (arr) => {
        console.log("arr", arr)
        let dupElements = arr.map((obj) => {
            if (obj._parameter_id == "9") {
                return obj._documetkeys_id
            }
        })
            .filter((obj) => undefined !== obj)
        console.log("dupElements", dupElements)
        let hasDupElementsForTruncate = false
        if (dupElements.length > 1) {
            hasDupElementsForTruncate = dupElements.some((obj, index) => {
                return dupElements.indexOf(obj) !== index
            })
        }
        console.log("hasDupElementsForTruncate", hasDupElementsForTruncate)
        if (hasDupElementsForTruncate) {
            toast.error("Duplicate Truncate not allowed")
            return true
        }
        return false
    }

    // check duplicate rename for same or different elements
    const containsDuplicateRename = (arr) => {
        console.log("arr", arr)

        //check duplicateElements for Rename
        let dupElements = arr.map((obj) => {
            if (obj._parameter_id == "10") {
                return obj._documetkeys_id
            }
        })
            .filter((obj) => undefined !== obj)
        console.log("dupElements", dupElements)
        let hasDupElementsForRename = false
        if (dupElements.length > 1) {
            hasDupElementsForRename = dupElements.some((obj, index) => {
                return dupElements.indexOf(obj) !== index
            })
        }
        console.log("hasDupElementsForRename", hasDupElementsForRename)

        // if(isRenameExist){
        let valuesOfRename = arr.map((obj) => {
            if (obj._parameter_id == "10") {
                return obj._paramvalue
            }
        })
        valuesOfRename = valuesOfRename.filter((obj) => undefined !== obj)
        console.log("valuesOfRename", valuesOfRename)
        // }
        let hasDuplicateRenameValue = false
        if (valuesOfRename.length > 1) {
            hasDuplicateRenameValue = valuesOfRename.some((obj, index) => {
                return valuesOfRename.indexOf(obj) !== index
            })
        }
        console.log("hasDuplicateRenameValue", hasDuplicateRenameValue)

        if (hasDupElementsForRename || hasDuplicateRenameValue) {
            toast.error("Duplicate Rename not allowed", {
                position: "top-center",
                autoClose: 5000,
            })
            return true
        }
        return false

    }

    // check duplicate assign for same or different elements
    const containsDuplicateAssign = (arr) => {
        console.log("arr", arr)

        //check duplicateElements for Assign
        let dupElements = arr.map((obj) => {
            if (obj._parameter_id == "22") {
                return obj._documetkeys_id
            }
        })
            .filter((obj) => undefined !== obj)
        console.log("dupElements", dupElements)
        let hasDupElementsForAssign = false
        if (dupElements.length > 1) {
            hasDupElementsForAssign = dupElements.some((obj, index) => {
                return dupElements.indexOf(obj) !== index
            })
        }
        console.log("hasDupElementsForAssign", hasDupElementsForAssign)

        // if(isAssignExist){
        let valuesOfAssign = arr.map((obj) => {
            if (obj._parameter_id == "22") {
                return obj._paramvalue
            }
        })
        valuesOfAssign = valuesOfAssign.filter((obj) => undefined !== obj)
        console.log("valuesOfAssign", valuesOfAssign)
        // }
        let hasDuplicateAssignValue = false
        if (valuesOfAssign.length > 1) {
            hasDuplicateAssignValue = valuesOfAssign.some((obj, index) => {
                return valuesOfAssign.indexOf(obj) !== index
            })
        }
        console.log("hasDuplicateAssignValue", hasDuplicateAssignValue)

        if (hasDupElementsForAssign || hasDuplicateAssignValue) {
            toast.error("Duplicate Assign not allowed", {
                position: "top-center",
                autoClose: 5000,
            })
            return true
        }
        return false
    }

    // check if the value of position is only a number
    const containsPositionNotANumber = (arr) => {
        console.log("arr", arr)
        let isPositionExist = arr.some((obj) => obj._parameter_id == "11")
        console.log("isPositionExist", isPositionExist)

        let valuesOfPosition = arr.map((obj) => {
            if (obj._parameter_id == "11") {
                return obj._paramvalue
            }
        })
            .filter((obj) => undefined !== obj)
        console.log("valuesOfPosition", valuesOfPosition)
        let wholeNumberPattern = /^\d+$/;
        let isWholeNumber = valuesOfPosition.every((str) => wholeNumberPattern.test(str))
        console.log("isWholeNumber", isWholeNumber)
        if (isWholeNumber == false) {
            toast.error("Position must be a positive whole number")
            return true
        }
        return false

    }

    // check if the value of truncate is only a number
    const containsTruncateNotANumber = (arr) => {
        console.log("arr", arr)
        let isTruncateExist = arr.some((obj) => obj._parameter_id == "9")
        console.log("isTruncateExist", isTruncateExist)

        let valuesOfTruncate = arr.map((obj) => {
            if (obj._parameter_id == "9") {
                return obj._paramvalue
            }
        })
            .filter((obj) => undefined !== obj)
        console.log("valuesOfTruncate", valuesOfTruncate)
        let wholeNumberPattern = /^\d+$/;
        let isWholeNumber = valuesOfTruncate.every((str) => wholeNumberPattern.test(str))
        console.log("isWholeNumber", isWholeNumber)
        if (isWholeNumber == false) {
            toast.error("Truncate must be a positive whole number")
            return true
        }
        return false

    }

    // check duplicate position for same or different elements
    const containsDuplicateKeysPosition = (arr) => {
        console.log("arr", arr)
        // isDuplicateExist =  keySpecData.some((obj,index)=>{
        //   keySpecData.findIndex((item)=>(item._parameter_id == obj._parameter_id) == index)
        // })
        // console.log("isDuplicateExist",isDuplicateExist)

        //check if position exist
        let isPositionExist = arr.some((obj) => obj._parameter_id == "11")
        console.log("isPositionExist", isPositionExist)

        //check duplicateElements for positions
        let dupElements = arr.map((obj) => {
            if (obj._parameter_id == "11") {
                return obj._documetkeys_id
            }
        })
            .filter((obj) => undefined !== obj)
        console.log("dupElements", dupElements)
        let hasDupElementsForPosition = false
        if (dupElements.length > 1) {
            hasDupElementsForPosition = dupElements.some((obj, index) => {
                return dupElements.indexOf(obj) !== index
            })
        }
        console.log("hasDupElementsForPosition", hasDupElementsForPosition)

        // if(isPositionExist){
        let valuesOfPosition = arr.map((obj) => {
            if (obj._parameter_id == "11") {
                return obj._paramvalue
            }
        })
        valuesOfPosition = valuesOfPosition.filter((obj) => undefined !== obj)
        console.log("valuesOfPosition", valuesOfPosition)
        // }
        let numberOfElements = documentKeysList.length

        let isPositionGreater = valuesOfPosition.some((obj) => obj > numberOfElements)
        let invalidPositions = false
        invalidPositions = valuesOfPosition.some((obj) => {
            if (obj > numberOfElements || obj <= 0) {
                return true
            }
        })
        console.log("isPositionGreater", isPositionGreater, invalidPositions)
        // if (isPositionGreater || valuesOfPosition.includes("0")) {
        //   toast.error("Invalid Positions")
        //   return true
        // }
        if (invalidPositions) {
            toast.error("Invalid Positions")
            return true
        }
        let hasDuplicatePositionValue = false
        if (valuesOfPosition.length > 1) {
            hasDuplicatePositionValue = valuesOfPosition.some((obj, index) => {
                return valuesOfPosition.indexOf(obj) !== index
            })
        }
        console.log("hasDuplicatePositionValue", hasDuplicatePositionValue)

        if (hasDupElementsForPosition || hasDuplicatePositionValue) {
            toast.error("Duplicate Positions not allowed")
            return true
        }

        return false

        // isDuplicateExist = keySpecData.some((obj) => {
        //   if (keySpecData.indexOf(obj) !== keySpecData.lastIndexOf(obj)) {
        //     return true
        //   }
        //   return false

        // })
        // console.log("isDuplicateExist", isDuplicateExist)

    }

    // check duplicate hide for same elements
    const containsDuplicateHide = (arr) => {
        console.log("arr", arr)
        let dupElements = arr.map((obj) => {
            if (obj._parameter_id == "12") {
                return obj._documetkeys_id
            }
        })
            .filter((obj) => undefined !== obj)
        console.log("dupElements", dupElements)
        let hasDupElementsForHide = false
        if (dupElements.length > 1) {
            hasDupElementsForHide = dupElements.some((obj, index) => {
                return dupElements.indexOf(obj) !== index
            })
        }
        console.log("hasDupElementsForHide", hasDupElementsForHide)
        if (hasDupElementsForHide) {
            toast.error("Duplicate Hide not allowed")
            return true
        }
        return false
    }

    // useEffect(() => {
    //   console.log("inputData", inputData, docID, IDNo)
    // }, [inputData, IDNo, docID])

    // useEffect(() => {
    //   errorCheck()
    // }, [isError])

    // const errorCheck = ()=>{
    //   if (isError) {
    //     console.log("errorcheck",isError)
    //     toast.error(messages.fillAllFields, {
    //       position: "top-center",
    //       autoClose: 5000,
    //     })
    //   }
    // }

    const switchDefaultRule = async () => {
        setLoading(true)
        try {
            if ("defaultRuleData" in defaultRulePayload.data1) {
                let response = await createOneRule(defaultRulePayload)
                console.log("switchDefaultRule", response)
                if (response.status === 200) {
                    toast.success(messages.addedSuccess, {
                        position: "top-center",
                        autoClose: 5000,
                    });
                }
                else {
                    toast.error(messages.somethingWrong);
                }
            }
        }
        catch (err) {
            console.log("switchDefaultRuleErr", err)

        }
        finally {
            setRuleName("")
            setRuleDescription("")
            setDocID("")
            setCustomername("")
            setParameterValue("")
            setParamDocValue("")
            setFiles("")
            setIsDefaultRule(false)
            setIsLambda(false)
            setKeySpecData([])
            setDocSpecData([])
            handleAddColumnOk()
            setNewKeysModal(false);
            setIsModalOpen(false);
            handleCancel()
            setDefaultCheckModal(false)
            setDefaultRulePayload()
            setLoading(false)
        }
    }

    const onSubmitRequestApi = async () => {

        if (docSpecData.length > 0 && docSpecData.some((obj) => obj._documetkeys_id === "" || obj._parameter_id === "")) {
            setIsError(true)
            toast.error(messages.fillAllFields, {
                position: "top-center",
                autoClose: 5000,
            })

            return
        }
        else if (keySpecData.length > 0 && keySpecData.some((obj) => obj._documetkeys_id === "" || obj._parameter_id === "")) {
            setIsError(true)
            toast.error(messages.fillAllFields, {
                position: "top-center",
                autoClose: 5000,
            })

            return
        }
        else {
            setIsError(false)
        }

        if (docSpecData.length > 0) {
            let isPositionNotANumber2 = containsPositionNotANumber(docSpecData)
            let isTruncateNotANumber2 = containsTruncateNotANumber(docSpecData)
            let isDuplicatePositionExist2 = containsDuplicateKeysPosition(docSpecData)
            let isDuplicateTruncateExist2 = containsDuplicateTruncate(docSpecData)
            let isDuplicateHideExist2 = containsDuplicateHide(docSpecData)
            let isDuplicateRenameExist2 = containsDuplicateRename(docSpecData)
            let isDuplicateAssignExist2 = containsDuplicateAssign(docSpecData)
            if (isPositionNotANumber2 || isTruncateNotANumber2 || isDuplicatePositionExist2 || isDuplicateTruncateExist2 || isDuplicateHideExist2 || isDuplicateRenameExist2 || isDuplicateAssignExist2) {
                return
            }
        }

        if (keySpecData.length > 0) {
            let isPositionNotANumber = containsPositionNotANumber(keySpecData)
            let isTruncateNotANumber = containsTruncateNotANumber(keySpecData)
            let isDuplicatePositionExist = containsDuplicateKeysPosition(keySpecData)
            let isDuplicateTruncateExist = containsDuplicateTruncate(keySpecData)
            let isDuplicateHideExist = containsDuplicateHide(keySpecData)
            let isDuplicateRenameExist = containsDuplicateRename(keySpecData)
            let isDuplicateAssignExist = containsDuplicateAssign(keySpecData)
            if (isPositionNotANumber || isTruncateNotANumber || isDuplicatePositionExist || isDuplicateTruncateExist || isDuplicateHideExist || isDuplicateRenameExist || isDuplicateAssignExist) {
                // toast.error("Duplicate Not Allowed",{
                //   position: "top-center",
                //   autoClose: 5000,
                // })
                return
            }
        }


        // const { document_id, customer_id, doc_type, email, } =
        //   inputData;
        // let sendData = {
        //   _document_id: inputData._document_id,
        //   email: initialValues.email,
        //   _documetkeys_id: inputData._documetkeys_id,
        //   // _documetkeys_id: docID,
        // }

        // let sendData = {
        //   // inputData._document_id,
        //   //   "id": IDNo,
        //   //   "email": email,
        //   "_document_id": docID,
        //   "operation": "CreateRule",
        //   "_rule_name": ruleName,
        //   "_description": ruleDescription,
        //   "useremail": initialValues.email,

        //   "docSpec": {
        //     "operation": "DocRule",
        //     "_parameter_id": parameterValue,
        //     "_paramvalue_doc": paramDocValue,
        //   },

        //   "keyrulepayload": keySpecData
        // }

        if (radioSelection == 1 && customRule == "") {
            toast.error(messages.fillRequiredFields)
            return
        }
        console.log("paramterID==>", customRule, customRuleList)
        let parameterID = customRuleList.filter(item => item.value == customRule)
        // return

        let sendData = {
            // inputData._document_id,
            //   "id": IDNo,
            //   "email": email,

            "data1": {
                // "operation": "CreateRule",
                "_document_id": docID,
                "_comp_id": customerName,
                "_rule_name": ruleName,
                "_description": ruleDescription,
                "useremail": initialValues.email,
                // "_rule_type": isLambda == true && radioSelection == 1 ? "lambdacustom" : isLambda == false && radioSelection == 2 ? "lambdarule" : radioSelection == 1 && files == "customfile" ? "custom" : radioSelection == 1 && files == "lambdafile" ? "lambda" : "rule",
                // "_rule_type": "rule",
                "_custom_rule_id": (customRule == "" || radioSelection == 2) ? null : customRule,
                "_custom_rule_name": (customRuleName == "" || radioSelection == 2) ? null : customRuleName,
                parameter: radioSelection == 1 ? parameterID[0].name : "",
                // lambda: isLambda == true ? 1 : 0
                isDefaultRule: isDefaultRule,
                isLambda: isLambda == true ? 1 : 0,
                isDocument: (radioSelection == 1) ? 1 : 0,
                isHeader: radioSelection == 2 ? 1 : 0,
                isFile: 0
            },
            "data2": {
                // "operation": "DocRule",
                // "_parameter_id": parameterValue,
                // "_paramvalue_doc": paramDocValue,
                "docrulepayload": docSpecData
            },
            "data3": {
                // "operation": "KeyRule",
                "keyrulepayload": keySpecData
            }
        }

        if ((radioSelection == 1 || radioSelection == 3) && customRule !== "") {
            delete sendData.data2
            delete sendData.data3
        }

        console.log("sendData", sendData)
        if (sendData.data1._rule_name == "" || sendData.data1._description == "" || sendData.data1._comp_id == "" || sendData.data1._document_id == "") {
            toast.error(messages.fillAllFields)
            return
        }

        if (isDefaultRule == true) {
            setLoading(true)
            try {
                let defaultRulesForComp = await getDefaultRules(customerName, isDefaultRule)
                console.log("defaultRulesForComp", defaultRulesForComp)
                let defaultRulesData = defaultRulesForComp.data.data
                if (defaultRulesData.length > 0) {
                    let { rule_id, rule_name, description } = defaultRulesData[0]
                    setDefaultRule({
                        ruleId: rule_id, ruleName: rule_name, ruleDescription: description,
                    })
                    setDefaultCheckModal(true)
                    sendData.data1["defaultRuleData"] = defaultRulesData[0]
                    setDefaultRulePayload(sendData)
                    setLoading(false)
                    return
                }
            }
            catch (err) {
                console.log("err", err)
            }
            setLoading(false)
        }

        // return
        // if (_document_id == "" || customer_id || doc_type || email == "" || _format_id == ""  ) {
        //   toast.error("Please fill all fields...");
        // } 
        // else {
        // let inputData2 = { "document_id": "100", "email": "pallav.panda@rapidqube.com", "_document_id": "", "doc_type": "PDF", }
        setLoading(true)
        try {
            // const response = await createNewRules(sendData)
            const response = await createOneRule(sendData)
            console.log("createNewRulesres", response)
            if (response.status === 200) {
                // setInputData(initialValues);
                toast.success(messages.addedSuccess, {
                    position: "top-center",
                    autoClose: 5000,
                });

            }
            else {
                toast.error(messages.somethingWrong);
            }
        } catch (error) {
            console.log("sapsingledataerror", error)
            setLoading(false)
            // setInputData(initialValues);

            // if ("response" in error) {
            //   toast.error(error.response.data.message, {
            //     position: "top-center",
            //     autoClose: 5000,
            //   });
            // } else {
            //   toast.error(error.message, {
            //     position: "top-center",
            //     autoClose: 5000,
            //   });
            // }

            if ("response" in error) {
                if (error.response.status == 403 && error.response.data.defaultRule.length > 1) {
                    let { rule_id, rule_name, description } = error.response.data.defaultRule[0]
                    setDefaultRule({
                        ruleId: rule_id, ruleName: rule_name, ruleDescription: description
                    })
                    return
                }
            }
            else {
                setError(error)
            }
            // setError(error)
        }
        // }
        setRuleName("")
        setRuleDescription("")
        setDocID("")
        setCustomername("")
        setParameterValue("")
        setParamDocValue("")
        setFiles("")
        setIsDefaultRule(false)
        setIsLambda(false)
        setKeySpecData([])
        setDocSpecData([])
        handleAddColumnOk()
        setNewKeysModal(false);
        setIsModalOpen(false);
        handleCancel()
        // getUserRulesList()
        setDefaultRulePayload()
        setLoading(false)
    };

    useEffect(() => {
        setHideSelect(true)
    }, [handleAddColumnCancel])

    const FetchApiDetails = async () => {
        try {
            const response = await getAllParameterRules()
            console.log("getParameterRules", response);
            let res = response.data.data
            let finalArr = []
            finalArr.push(
                {

                    "_paramname": "Select",
                    "_paramtype": "Select",
                })
            res.map((obj) => {
                if (obj._paramtype == "Document wise") {
                    finalArr.push(obj)
                }
            })
            console.log("finalArr", finalArr)

            // setApiDetailState()
            setApiDetailState(finalArr);
            // setHideSelect(false)
        } catch (error) {
            console.log(error);
            setError(error)
        }
    };



    const getDocumentKeysList = async () => {

        setLoading(true)
        let sendData = {
            comp_id: customerName,
            docid: docID
        }
        // const response = await getDocumentKeys(customerName)
        try {
            const response = await getTargetColKeys(sendData)
            console.log("getDocumentKeys", response);

            if (response.status !== 200) {
                setDocumentKeysList([])
                setLoading(false)
                setColumnName("")
                setSelectedType("key_wise")
                // toast.error(response.message)
                return
            }
            let res = response.resData
            let data = res.data.data
            console.log("DataRow_", data)
            setColRowData(data)
            setColumnName("")
            setSelectedType("key_wise")
            // let finalArr = []
            // let filterRawData = []
            // console.log("rawData_", rawData)
            // data.map((obj) => {
            //     rawData.map((item, i) => {
            //         if (item.documetkeys_id === obj._documetkeys_id) {
            //             filterRawData.push({
            //                 label: obj?._key,
            //                 value: obj?._documetkeys_id,
            //                 type: obj?._key_type,
            //                 seq: obj?._seq_no
            //             })
            //         }
            //     })
            // })
            // setFilterDocumentKeyList(filterRawData)
            // data.map((obj) => {
            //     finalArr.push({
            //         label: obj?._key,
            //         value: obj?._documetkeys_id,
            //         type: obj?._key_type,
            //         seq: obj?._seq_no
            //     })
            // })
            // // console.log("finalArr_",finalArr)
            // setDocumentKeysList(finalArr);
        }
        catch (err) {
            setError(err)

        }
        setLoading(false)
    }


    useEffect(() => {
        FetchApiDetails()
        fetchRowDataDetails()
    }, [])


    const handleCancel = () => {
        setIsModalOpen(false);
        // setParameter({
        //   name: "Select a Parameter Type",
        //   value: ""
        // })
        setParameterValue("")
        setParamDocValue("")
        setApiDetailState([])

    };

    console.log("newRule", newRuleData)

    useEffect(() => {
        if (radioSelection == 2) {
            return
        }
        updateParameterList()
        setCustomRule("")
    }, [files, isLambda, customRuleList, radioSelection])

    const updateParameterList = () => {
        console.log("updateParam_", customRuleList)
        if (customRuleList.length > 0) {
            let finalArr = []
            customRuleList.forEach((obj) => {
                if (obj.rulestype !== null) {
                    let trimmedRulestype = obj.rulestype.trim()
                    // if (trimmedRulestype == files) {
                    //   finalArr.push(obj)
                    // }
                    if (isLambda) {
                        if (trimmedRulestype == "lambdafile") {
                            finalArr.push(obj)
                        }
                    }
                    else if (radioSelection == 3) {
                        if (trimmedRulestype == "spfile") {
                            finalArr.push(obj)
                        }
                    } else {
                        if (trimmedRulestype == "customfile") {
                            finalArr.push(obj)
                        }
                    }
                }
            })
            setParameterFilesList(finalArr)
        }
    }

    const handleFilesType = (e) => {
        console.log("handleFilesType", e, filesList, customRuleList)
        const { name, value } = e.target;
        setInputData({ ...inputData, [name]: value });
        setFiles(value)
        // updateParameterList(value)
    }


    const handleExpCancel = () => {
        console.log("cancel")
        setSelectdeKeyFrom("")
        setSelectdeKeyTo("")
        setExpression("")
        setFromValue("")
        setToValue("")
        setAssignValue("")
        setExpressionModal(false)
        setIsSubString(false)
        setIsConcat(false)
        setIsMap(false)
    }

    const fetchRowDataDetails = async () => {
        try {
            const response = await getRawDataDetails()
            let data = response.data.data
            setRawData(data)
            console.log("getRawDataDetails", response, data);
        } catch {
            console.log("catch error")
        }
    }

    const handleColumnChange = (e) => {
        setColumnName(e.target.value)
    }

    const handleSelectedType = (e) => {
        console.log("SelectedType_", e)
        setSelectedType(e)
    }

    const handleSubmit = async () => {
        let sendData = {
            "data": {
                "addDynamicKeys": [
                    {
                        "key": columnName,
                        "description": "",
                        "key_type": selectedType,
                        "comp_id": customerName,
                        "docid": docID
                    }
                ]
            }
        }

        setLoading(true)
        try {
            let response = await addNewItemsForEdit(sendData)
            console.log("responseaddnewitems", response)
            if (response.status == 200) {
                toast.success(response.data.msg)
                getDocumentKeysList()
            }
            else {
                toast.error(response.data.message)
                setColumnName("")
                setSelectedType("key_wise")
            }
        }
        catch (error) {
            console.log("erroraddnewitems", error)
            // toast.error(error.response.data.msg)
            // setError(error)
        }
        setLoading(false)

    }

    const handleDeleteAllCancel = () => {
        setIsDeleteModal(false)
    }

    const handleDeleteAllSubmit = async() => {
        console.log("params", deleteData.data)
        let deleteColResponse = await deleteTargetColKeys(deleteData.data.documetkeys_id)
        console.log("deleteColResponse", deleteColResponse);
        if (deleteColResponse.status == 200) {
            toast.success(deleteColResponse.data.msg)
            getDocumentKeysList()
        }

        setIsDeleteModal(false)
    }


    return (
        <>
            <Loader loading={loading} />
            <Toaster position="top-center" reverseOrder={false} />
            <ErrorCatch err={error} />

            <Modal
                className="p-2"
                // zIndex= {1000}
                open={isDeleteModal}
                // style={{ top: 20, minWidth: 900, maxWidth: 1200 }}
                onCancel={handleDeleteAllCancel}
                maskClosable={false}
                footer={[
                    <div className="d-flex justify-content-end gap-1">
                        <Button
                            className="btn btn-primary btn-sm"
                            type="primary"
                            onClick={handleDeleteAllSubmit}
                        >
                            Submit
                        </Button>
                        <Button className="btn btn-secondary btn-sm" type="secondary" onClick={handleDeleteAllCancel}>
                            Cancel
                        </Button>
                    </div>
                ]}
            >
                <div class="row">
                    <div className="col text-center">
                        <img src={warning} className="imgicon"></img>
                    </div>
                </div>

                <div className=" text-center py-2">
                    <h3>
                        Do you want to Delete the column
                    </h3>
                </div>
            </Modal>

            <div className="container"
            >
                <Modal
                    // width={500}
                    className="p-2"
                    zIndex={900}
                    open={isAddColumnModal}
                    onOk={handleAddColumnOk}
                    onCancel={handleAddColumnCancel}
                    footer={null}
                    maskClosable={false}
                    style={{ top: 20, minWidth: 900, maxWidth: 1200 }}
                    destroyOnClose
                >
                    <div class="row">
                        <div className="col text-center">
                            <p className="heading-popup">Add New Column</p>
                        </div>
                    </div>

                    <div className="container"
                        ref={containerRef}
                        style={{
                            height: "28rem",
                            overflowY: "auto", overflowX: "hidden"
                        }}
                    >
                        <div class="row mb-3 mt-2">
                            <div className="col-md-6">

                                <SelectInput
                                    margin="0"
                                    selectLabel="*Customer"
                                    name="_comp_id"
                                    value={customerName}
                                    onChange={(e) => handleCustomerName(e)}
                                    optionsList={customerList}
                                />
                            </div>
                            <div className="col-md-6">
                                <SelectInput
                                    margin="0"
                                    selectLabel="*Document Type"
                                    name="_documetkeys_id"
                                    value={docID}
                                    onChange={(e) => handleDocumentType(e)}
                                    optionsList={apiFormatState2}
                                />
                            </div>
                        </div>
                        <div className="d-flex mt-1">
                            <div className="col-md-4">
                                <InputBoxAntd
                                    name="*ColumnnName"
                                    value={columnName}
                                    onChange={(e) => handleColumnChange(e)}
                                    // disabled={isEnabled}
                                    placeholder="*values"
                                />
                            </div>
                            <div className="col-md-4 px-2">
                                <SelectInputAntd
                                    value={selectedType}
                                    onChange={(e) => handleSelectedType(e)}
                                    optionsList={typeList}
                                    placeholder="Select Type"
                                />
                            </div>
                            <div className="col-md-4 d-flex justify-content-end">
                                <BasicButton
                                    text="Submit"
                                    onClick={handleSubmit}
                                    disabled={columnName !== "" && selectedType !== "" ? false : true}
                                />
                            </div>
                        </div>
                        {colRowData.length > 0 &&
                            <div className="mt-2">
                                <AgGridComponent
                                    columnDef={columnData}
                                    height={300}
                                    rowData={colRowData}
                                />
                            </div>
                        }
                    </div>
                </Modal >
                <Toaster position="top-center" reverseOrder={false} />
            </div >
        </>
    );
}

export default AdminAddColumns;
