const ViewDocument = () =>{
    return(
        <>
        <div className="conatiner">
            <h6 className="mt-3 px-5"><b>Document Details</b></h6>
            <div className="row">
                </div>
            </div>
        </>
    )
}

export default ViewDocument