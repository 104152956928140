import { DataGrid } from "@mui/x-data-grid";
import { getRuleListForSuperAdmin } from "../../../../Services/AdminServices";
import { useEffect, useState } from "react";
import { Modal } from "antd";
import approvereject from "../../../../Assets/Images/approverejeact.png";
import Loader from "../../../../utils/Loader/Loader";
import ChangeRuleStatus from "../ChangeRuleStatus";
import { LuView } from "react-icons/lu";
import ViewRule from "./ViewRule";
import { darken, lighten, styled } from "@mui/material/styles";
import ErrorCatch from "../../../Reusable_Components/ErrorCatch";

// Dtata background change
const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .super-app-theme--Pending": {
    backgroundColor: getBackgroundColor("#F7F5BC", theme.palette.mode),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor("#F7F5BC", theme.palette.mode),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        "#F7F5BC",
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          "#F7F5BC",
          theme.palette.mode
        ),
      },
    },
  },

  "& .super-app-theme--Approved": {
    backgroundColor: getBackgroundColor(
      theme.palette.success.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode
        ),
      },
    },
  },
  "& .super-app-theme--Deactive": {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode
    ),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
    },
    "&.Mui-selected": {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode
      ),
      "&:hover": {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode
        ),
      },
    },
  },
}));

// Databackground close

const RequestedRulesList = ({ rulesList }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error,setError] = useState()
  const [isModaldataRefresh, setIsModaldataRefresh] = useState(false);
  const [input, setInput] = useState({
    _reason: "",
    _spec_status_id: "",
  });

  const [ViewModalOpen, setViewModalOpen] = useState(false);
  const [userActive, setUserActive] = useState();
  const [data, setData] = useState([]);
  const [userRoute, setUserRoute] = useState(false);
  const [params, setParams] = useState([]);
  const [sortOrder, setSortOrder] = useState("des"); // Sorting order: 'asc' or 'desc'
  const [getRulesViewData, setGetRoulesViewData] = useState([]);
  const [loading, setLoading] = useState(false);

  const tableHeight = 380;
  //Table Content
  const RequestedRulesColumns = [
    {
      headerClassName: "super-app-theme--header",
      field: "_rule_id",
      headerName: "ID",
      width: 70,
    },
    {
      headerClassName: "super-app-theme--header",
      width: 80,
      renderCell: (params) => {
        // console.log("kysa aarha hai?", params.row._rule_id)
        return (
          <a onClick={() => RulesView(params.row._rule_id)} class="btn">
            <LuView />
          </a>
        );
      },
    },
    {
      field: "_rule_name",
      headerClassName: "super-app-theme--header",
      headerName: "Rule Name",
      width: 200,
    },
    {
      field: "_requester_name",
      headerClassName: "super-app-theme--header",
      headerName: "Requested user",
      width: 200,
    },
    {
      field: "_spec_status_name",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      width: 85,
    },
    {
      field: "_description",
      headerClassName: "super-app-theme--header",
      headerName: "Description",
      width: 270,
    },
    {
      field: "action1",
      headerClassName: "super-app-theme--header",
      headerName: "Approve/Reject",
      width: 150,
      renderCell: (params) => {
        return (
          <a onClick={() => showModal(params)} class="btn btn-ligh btn-sm  ">
            <img src={approvereject} style={{ width: "1.2rem" }} />

          </a>
        );
      },
    },
  ];

  const getRulesList = async () => {
    setLoading(true);
    try{
      await getRuleListForSuperAdmin().then((res) => {
        let response = res.data.data;
        console.log("getRuleListForSuperAdminres", response);
        response.map((item) => {
          if (item._status_id === 2) {
            item._status_id = "InActive";
          } else if (item._status_id === 1) {
            item._status_id = "Active";
          } else {
            item._status_id = "Pending";
          }
          return item;
        });
        setData([...response]);
      });
    }
    catch(err){
      setError(err)
    }
    setLoading(false);
  };

  const showModal = (act) => {
    console.log("Active", act.row);
    setInput({
      _reason: "",
      _spec_status_id: "",
    });
    setIsModalOpen(true);
    setUserActive(act.row);
    setIsModaldataRefresh(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    getRulesList();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsModaldataRefresh(false);
  };

  useEffect(() => {
    getRulesList();
  }, []);

  useEffect(() => {
    if (rulesList == true) {
      getRulesList();
    }
  }, [rulesList]);

  // Rule View Modal Functions
  const RulesView = (ruleid) => {
    setViewModalOpen(true);
    setGetRoulesViewData(ruleid);
  };
  const RulesViweOk = () => {
    setViewModalOpen(false);
  };

  const RulesViewCancel = () => {
    setViewModalOpen(false);
  };
  const handleChangeCheckbox = (params) => {
    // console.log("handle check box", params);
    let colDef = params.field;
    let data = params.row;
    if (colDef === "action") {
      setParams(data);
      setUserRoute(true);
    }
  };
  return (
    <>
    <ErrorCatch err={error}/>
      <div className="container">
        <div className="col d-flext justify-content-start mb-3">
          <h6 className="px-3 mt-2">
            <b>Requested Rules</b>
          </h6>
        </div>

        {data.length > 0 ? (
          <div
            className="datatable"
            style={{ height: tableHeight, width: "100%", overflowY: "auto" }}
          >
            <StyledDataGrid
              sx={{
                borderColor: "secondary",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#0E3386",
                  fontSize: "12px",
                  color: "white",
                  padding: "10px",

                  // Set your desired header background color
                },
                "& .MuiDataGrid-cell": {
                  fontSize: "10px",
                  padding: "10px",
                  borderRight: "2px solid #e0e0e0",
                  // Adjust the font size as needed
                  // Adjust the cell padding as needed
                },
              }}
              rowHeight={30}
              getRowId={(data) => data._rule_id}
              className="datagrid"
              rows={data}
              getRowClassName={(params) =>
                `super-app-theme--${params.row._spec_status_name}`
              }
              columns={RequestedRulesColumns}
              checkboxSelection={false}
              pageSize={9}
              onCellClick={(params) => handleChangeCheckbox(params)}
              rowsPerPageOptions={[9]}
              hideFooter
            />
          </div>
        ) : (
          <div className="container">
            <div className="row justify-content-center">
              <div className="col d-flex justify-content-center">
                {" "}
                No Rows to show
              </div>
            </div>
          </div>
        )}

        <div className="d-flex justify-content-center">
          <Modal
            className="p-2 w-25"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            maskClosable={false}
            style={{ top: 20, minWidth: 300, maxWidth: 600 }}
          >
            <ChangeRuleStatus data={userActive} handleCancel={handleCancel} />
          </Modal>

          <Modal
            title="View Rules"
            maskClosable={false}
            open={ViewModalOpen}
            onOk={RulesViweOk}
            onCancel={RulesViewCancel}
            style={{ top: 20, minWidth: 300, maxWidth: 600 }}
          >
            <ViewRule ruleid={getRulesViewData} />
          </Modal>
        </div>
      </div>
    </>
  );
};

export default RequestedRulesList;
