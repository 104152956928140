const requestedCompanyColumn = [
    {
      id: "1",
      field: "user_id",
      headerName: "User Id",
      // width: 80,
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    {
      id: "7",
      field: "comp_name",
      headerName: "Company Name",
      // width: 150,
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },

    {
      id: "8",
      field: "company_status_id",
      headerName: "Company Status",
      // width: 150,
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },

    {
      id: "2",
      field: "name",
      headerName: "Name",
      // width: 150,
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },

    {
      id: "3",
      field: "email",
      headerName: "Email",
      // width: 200,
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },

    {
      id: "4",
      field: "phone",
      headerName: "Phone",
      // width: 120,
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },

    {
      id: "5",
      field: "address",
      headerName: "Address",
      // width: 200,
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
  ];

  export default requestedCompanyColumn