import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import env from "../../../../Services/Environment";
import { getResponseFormat, getRules, createNewRules, createOneRule, getAllParameterRules, getDocumentKeys,getDocumentName,getActiveCustomer } from "../../../../Services/AdminServices";
import { Modal } from "antd";
import { BsPlus, BsFillTrashFill } from 'react-icons/bs';
import Loader from "../../../../utils/Loader/Loader";
import SetRulesForDocuments from "./SetRulesforDocuments";
import { useStyleRegister } from "antd/es/theme/internal";
import ErrorCatch from "../../../Reusable_Components/ErrorCatch";
import { useSelector } from "react-redux";
import CryptoJS from 'crypto-js';

function AddNewRule({ handleNewRuleOk, isNewRuleModal, handleNewRuleCancel }) {
  const userDetails = useSelector((state) => state)
  const navigate = useNavigate();
  const [customerList, setCustomerList] = useState([])
  const [apiFormatState2, setApiFormatState2] = useState([]);
  const [responseFormat, setResponseFormat] = useState([])
  const [ruleFormat, setRuleFormat] = useState([])
  const [error,setError] = useState()
  const [customerPlaceHolder, setCustomerPlaceholder] = useState({
    name: "Select Customer",
    value: ""
  })
  const [docType, setDocType] = useState({
    name: "Select a Document Type",
    value: ""
  })

  const [formatType, setFormatType] = useState({
    name: "Select a Format Type",
    value: ""
  })

  // const [keyType, setKeyType] = useState({
  //   name: "Key",
  //   value: ""
  // })

  const [ruleType, setRuleType] = useState({
    name: "Select a Rule Type",
    value: ""
  })

  const [keySpecData, setKeySpecData] = useState([])
  const [keySpecField, setKeySpecField] = useState({
    // "operation": "KeyRule",
    "_paramvalue": "",
    "_parameter_id": "",
    "_documetkeys_id": "",
  })

  const [isError, setIsError] = useState(false)

  const handleAddField = () => {
    setKeySpecData([...keySpecData, { ...keySpecField }])
    setKeySpecField({
      // "operation": "KeyRule",
      "_paramvalue": "",
      "_parameter_id": "",
      "_documetkeys_id": "",
    })
  }

  const handleDeleteField = (index) => {
    let newKeySpecData = [...keySpecData]
    newKeySpecData.splice(index, 1)
    setKeySpecData(newKeySpecData)
  }


  const [apiDetailState, setApiDetailState] = useState([]);

  const [hideSelect, setHideSelect] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newRuleData, setNewRuleData] = useState({})
  const [parameterValue, setParameterValue] = useState("")
  const [paramDocValue, setParamDocValue] = useState("")
  const [parameter, setParameter] = useState({
    name: "Select a Parameter Type",
    value: ""
  })

  const [newKeysModal, setNewKeysModal] = useState(false)
  const [newKeys, setNewKeys] = useState()
  const [documentKeysList, setDocumentKeysList] = useState([])
  const [parameterRulesList, setParameterRulesList] = useState([])
  const [newValueNo, setNewValueNo] = useState()
  const [isSetRuleModalOpen, setIsSetRuleModalOpen] = useState(false)

  const [docKeys, setDocKeys] = useState("")
  const [paramRule, setParamRule] = useState("")

  const userDetailsLog = userDetails.auth.userDetails

  const handleNewValueNo = (e) => {
    const { name, value } = e.target;
    let val = e.target.value.replace(/[A-Za-z' `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/g, '');
    setNewValueNo(val)
  };

  const handleNewKeys = (e) => {
    const { name, value } = e.target;
    setNewKeys(value)
  }

  const [newParamNames, setNewParamNames] = useState()
  const handleNewParamNames = (e) => {
    const { name, value } = e.target;
    // setInputData({ ...inputData, [name]: value });
    setNewParamNames(value)
  }

  const [ruleSpec, setRuleSpec] = useState([])

  useEffect(() => {
    if (isNewRuleModal) {
      setRuleName("")
      setRuleDescription("")
    }
  }, [isNewRuleModal])

  useEffect(() => {
    if (isModalOpen) {
      // setParameter({
      //   name: "Select a Parameter Type",
      //   value: ""
      // })
      setParameterValue("")
      setParamDocValue("")
      FetchApiDetails()
    }
  }, [isModalOpen])

  const encrypt = (plaintext) => {
    console.log("encryptPlaintext", plaintext);
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(plaintext))
      .replace(/=+$/, '') // Remove padding
      .replace(/\+/g, '-') // Replace + with -
      .replace(/\//g, '_'); // Replace / with _
  };

  const getActiveCustomerList = async () => {
    try {
      let currentUserLoginEmail = encrypt(userDetailsLog.email)
      const response = await getActiveCustomer(currentUserLoginEmail);
      console.log("getActiveCustomer", response.data.data);
      setCustomerList(response.data.data);
    } catch (error) {
      console.log(error);
      setError(error)
    }
  };

  const getAPIFormat2 = async () => {
    try {
      const response = await getDocumentName();
      console.log("getAPIFormat2", response.data.data);
      setApiFormatState2(response.data.data);
    } catch (error) {
      console.log(error);
      setError(error)
    }
  };

  const getDocumentResponseFormat = async () => {
    try {
      const response = await getResponseFormat()
      console.log("getDocumentResponseFormat", response.data.data);
      setResponseFormat(response.data.data);
    } catch (error) {
      console.log(error);
      setError(error)
    }
  };

  const getRuleType = async () => {
    try {
      const response = await getRules()
      console.log("getRules", response.data.data);
      setRuleFormat(response.data.data);
    } catch (error) {
      console.log(error);
      setError(error)
    }
  };

  useEffect(() => {
    getDocumentResponseFormat()
    getActiveCustomerList()
    getAPIFormat2();
    // getRuleType()
    setCustomerPlaceholder({
      name: "Select Customer",
      value: ""
    })
    setDocType({
      name: "Select a Document Type",
      value: ""
    })
    setRuleName("")
    setRuleDescription("")
    setDocID("")
    setCustomername("")
    // setIDNo()
  }, []);

  useEffect(() => {
    getDocumentKeysList()
    getParameterRulesList()

    // setKeyType({
    //   name: "Keys",
    //   value: ""
    // })
    setFormatType({
      name: "Param Rule",
      value: ""
    })
    setDocKeys("")
    setParamRule("")
    setIDNo()
  }, [])

  const initialValues = {
    email: localStorage.getItem("Logindata"),
    // _document_id: "",
    // _documetkeys_id: "",

  };
  let email = localStorage.getItem("Logindata")

  const [inputData, setInputData] = useState();
  const [customerName, setCustomername] = useState("")
  const [docID, setDocID] = useState("")
  const [ruleName, setRuleName] = useState("")
  const [ruleDescription, setRuleDescription] = useState("")
  const [IDNo, setIDNo] = useState()
  const [formatID, setFormatID] = useState("")
  const [ruleID, setRuleID] = useState("")
  const [loading, setLoading] = useState(false)
  // const [salesOrderNo, setSalesOrderNo] = useState()

  const handleCustomerName = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
    setCustomername(value)
  };

  const handleDocumentType = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
    setDocID(value)
  };

  const handleRuleName = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
    setRuleName(value)
  };

  const handleRuleDescription = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
    setRuleDescription(value)
  };


  const handleIDNo = (e) => {
    const { name, value } = e.target;
    let val = e.target.value.replace(/[A-Za-z' `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/g, '');
    setInputData({ ...inputData, [name]: val });
    setIDNo(val)
  };

  // truncate - 9
  // rename - 10
  // position - 11
  // hide - 12

  // check duplicate truncate for same elements
  const containsDuplicateTruncate = () => {
    console.log("keyspecdata", keySpecData)
    let dupElements = keySpecData.map((obj) => {
      if (obj._parameter_id == "9") {
        return obj._documetkeys_id
      }
    })
      .filter((obj) => undefined !== obj)
    console.log("dupElements", dupElements)
    let hasDupElementsForTruncate = false
    if (dupElements.length > 1) {
      hasDupElementsForTruncate = dupElements.some((obj, index) => {
        return dupElements.indexOf(obj) !== index
      })
    }
    console.log("hasDupElementsForTruncate", hasDupElementsForTruncate)
    if (hasDupElementsForTruncate) {
      toast.error("Duplicate Truncate not allowed")
      return true
    }
    return false
  }

  // check duplicate rename for same or different elements
  const containsDuplicateRename = () => {
    console.log("keyspecdata", keySpecData)

    //check duplicateElements for Rename
    let dupElements = keySpecData.map((obj) => {
      if (obj._parameter_id == "10") {
        return obj._documetkeys_id
      }
    })
      .filter((obj) => undefined !== obj)
    console.log("dupElements", dupElements)
    let hasDupElementsForRename = false
    if (dupElements.length > 1) {
      hasDupElementsForRename = dupElements.some((obj, index) => {
        return dupElements.indexOf(obj) !== index
      })
    }
    console.log("hasDupElementsForRename", hasDupElementsForRename)

    // if(isRenameExist){
    let valuesOfRename = keySpecData.map((obj) => {
      if (obj._parameter_id == "10") {
        return obj._paramvalue
      }
    })
    valuesOfRename = valuesOfRename.filter((obj) => undefined !== obj)
    console.log("valuesOfRename", valuesOfRename)
    // }
    let hasDuplicateRenameValue = false
    if (valuesOfRename.length > 1) {
      hasDuplicateRenameValue = valuesOfRename.some((obj, index) => {
        return valuesOfRename.indexOf(obj) !== index
      })
    }
    console.log("hasDuplicateRenameValue", hasDuplicateRenameValue)

    if (hasDupElementsForRename || hasDuplicateRenameValue) {
      toast.error("Duplicate Rename not allowed")
      return true
    }
    return false

  }

  // check duplicate position for same or different elements
  const containsDuplicateKeysPosition = () => {
    console.log("keyspecdata", keySpecData)
    // isDuplicateExist =  keySpecData.some((obj,index)=>{
    //   keySpecData.findIndex((item)=>(item._parameter_id == obj._parameter_id) == index)
    // })
    // console.log("isDuplicateExist",isDuplicateExist)

    //check if position exist
    let isPositionExist = keySpecData.some((obj) => obj._parameter_id == "11")
    console.log("isPositionExist", isPositionExist)

    //check duplicateElements for positions
    let dupElements = keySpecData.map((obj) => {
      if (obj._parameter_id == "11") {
        return obj._documetkeys_id
      }
    })
      .filter((obj) => undefined !== obj)
    console.log("dupElements", dupElements)
    let hasDupElementsForPosition = false
    if (dupElements.length > 1) {
      hasDupElementsForPosition = dupElements.some((obj, index) => {
        return dupElements.indexOf(obj) !== index
      })
    }
    console.log("hasDupElementsForPosition", hasDupElementsForPosition)

    // if(isPositionExist){
    let valuesOfPosition = keySpecData.map((obj) => {
      if (obj._parameter_id == "11") {
        return obj._paramvalue
      }
    })
    valuesOfPosition = valuesOfPosition.filter((obj) => undefined !== obj)
    console.log("valuesOfPosition", valuesOfPosition)
    // }
    let numberOfElements = documentKeysList.length

    let isPositionGreater = valuesOfPosition.some((obj) => obj > numberOfElements)
    let invalidPositions = false 
    invalidPositions = valuesOfPosition.some((obj) => {
      if(obj > numberOfElements || obj <= 0){
        return true
      }
    })
    console.log("isPositionGreater", isPositionGreater,invalidPositions)
    // if (isPositionGreater || valuesOfPosition.includes("0")) {
    //   toast.error("Invalid Positions")
    //   return true
    // }
    if (invalidPositions) {
      toast.error("Invalid Positions")
      return true
    }
    let hasDuplicatePositionValue = false
    if (valuesOfPosition.length > 1) {
      hasDuplicatePositionValue = valuesOfPosition.some((obj, index) => {
        return valuesOfPosition.indexOf(obj) !== index
      })
    }
    console.log("hasDuplicatePositionValue", hasDuplicatePositionValue)

    if (hasDupElementsForPosition || hasDuplicatePositionValue) {
      toast.error("Duplicate Positions not allowed")
      return true
    }

    return false

    // isDuplicateExist = keySpecData.some((obj) => {
    //   if (keySpecData.indexOf(obj) !== keySpecData.lastIndexOf(obj)) {
    //     return true
    //   }
    //   return false

    // })
    // console.log("isDuplicateExist", isDuplicateExist)

  }

  // check duplicate hide for same elements
  const containsDuplicateHide = () => {
    console.log("keyspecdata", keySpecData)
    let dupElements = keySpecData.map((obj) => {
      if (obj._parameter_id == "12") {
        return obj._documetkeys_id
      }
    })
      .filter((obj) => undefined !== obj)
    console.log("dupElements", dupElements)
    let hasDupElementsForHide = false
    if (dupElements.length > 1) {
      hasDupElementsForHide = dupElements.some((obj, index) => {
        return dupElements.indexOf(obj) !== index
      })
    }
    console.log("hasDupElementsForHide", hasDupElementsForHide)
    if (hasDupElementsForHide) {
      toast.error("Duplicate Hide not allowed")
      return true
    }
    return false
  }

  // useEffect(() => {
  //   console.log("inputData", inputData, docID, IDNo)
  // }, [inputData, IDNo, docID])

  // useEffect(() => {
  //   errorCheck()
  // }, [isError])

  // const errorCheck = ()=>{
  //   if (isError) {
  //     console.log("errorcheck",isError)
  //     toast.error("Please fill all field", {
  //       position: "top-center",
  //       autoClose: 5000,
  //     })
  //   }
  // }

  const onSubmitRequestApi = async (e) => {
    let isDuplicatePositionExist = containsDuplicateKeysPosition()
    let isDuplicateTruncateExist = containsDuplicateTruncate()
    let isDuplicateHideExist = containsDuplicateHide()
    let isDuplicateRenameExist = containsDuplicateRename()
    if (isDuplicatePositionExist || isDuplicateTruncateExist || isDuplicateHideExist || isDuplicateRenameExist) {
      return
    }

    if (keySpecData.some((obj) => obj._documetkeys_id === "" || obj._parameter_id === "" || obj._paramvalue === "")) {
      setIsError(true)
      toast.error("Please fill all field", {
        position: "top-center",
        autoClose: 5000,
      })
      setLoading(false)
      return
    }
    else {
      setIsError(false)
    }

    // const { document_id, customer_id, doc_type, email, } =
    //   inputData;
    // let sendData = {
    //   _document_id: inputData._document_id,
    //   email: initialValues.email,
    //   _documetkeys_id: inputData._documetkeys_id,
    //   // _documetkeys_id: docID,
    // }

    // let sendData = {
    //   // inputData._document_id,
    //   //   "id": IDNo,
    //   //   "email": email,
    //   "_document_id": docID,
    //   "operation": "CreateRule",
    //   "_rule_name": ruleName,
    //   "_description": ruleDescription,
    //   "useremail": initialValues.email,

    //   "docSpec": {
    //     "operation": "DocRule",
    //     "_parameter_id": parameterValue,
    //     "_paramvalue_doc": paramDocValue,
    //   },

    //   "keyrulepayload": keySpecData
    // }

    setLoading(true)
    let sendData = {
      // inputData._document_id,
      //   "id": IDNo,
      //   "email": email,

      "data1": {
        // "operation": "CreateRule",
        "_document_id": docID,
        // "_comp_id": customerName,
        "_rule_name": ruleName,
        "_description": ruleDescription,
        "useremail": initialValues.email,
      },

      "data2": {
        // "operation": "DocRule",
        "_parameter_id": parameterValue,
        "_paramvalue_doc": paramDocValue,
      },

      "data3": {
        // "operation": "KeyRule",
        "keyrulepayload": keySpecData
      }
    }

    console.log("sendData", sendData)
    // if (_document_id == "" || customer_id || doc_type || email == "" || _format_id == ""  ) {
    //   toast.error("Please fill all fields...");
    // } 
    // else {
    // let inputData2 = { "document_id": "100", "email": "pallav.panda@rapidqube.com", "_document_id": "", "doc_type": "PDF", }
    try {
      // const response = await createNewRules(sendData)
      const response = await createOneRule(sendData)
      console.log("createNewRulesres", response)
      if (response.status === 200) {
        // setInputData(initialValues);
        toast.success("your request sent successfully", {
          position: "top-center",
          autoClose: 5000,
        });

      }
      else {
        toast.error("Something went wrong, Can you please try again");
      }
    } catch (error) {
      console.log("sapsingledataerror", error)
      // setInputData(initialValues);
      // toast.error(error.response.data.data, {
      //   position: "top-center",
      //   autoClose: 5000,
      // });
      setError(error)
    }
    // }
    setRuleName("")
    setRuleDescription("")
    setParameterValue("")
    setParamDocValue("")
    setKeySpecData([])
    handleNewRuleOk()
    setNewKeysModal(false);
    setIsModalOpen(false);
    handleCancel()
    setLoading(false)
  };

  useEffect(() => {
    setHideSelect(true)
  }, [handleNewRuleCancel])

  const FetchApiDetails = async () => {
    try {
      const response = await getAllParameterRules()
      console.log("getParameterRules", response);
      let res = response.data.data
      let finalArr = []
      finalArr.push(
        {

          "_paramname": "Select",
          "_paramtype": "Select",
        })
      res.map((obj) => {
        if (obj._paramtype == "Document wise") {
          finalArr.push(obj)
        }
      })
      console.log("finalArr", finalArr)

      // setApiDetailState()
      setApiDetailState(finalArr);
      // setHideSelect(false)
    } catch (error) {
      console.log(error);
      setError(error)
    }
  };

  const getDocumentKeysList = async () => {
    setLoading(true)
    try {
      const response = await getDocumentKeys()
      console.log("getDocumentKeys", response);

      if (response.status !== 200) {
        toast.error(response.message)
        setDocumentKeysList([])
        setLoading(false)
        return
      }
      let res = response.resData
      setDocumentKeysList(res.data.data);
    } catch (error) {
      console.log(error);
      setError(error)
    }
    setLoading(false)
  }

  const getParameterRulesList = async () => {
    setLoading(true)
    try {
      // const response = await getParameterRules()
      const response = await getAllParameterRules()
      console.log("getParameterRules", response);
      let res = response.data.data
      let finalArr = []
      res.map((obj) => {
        if (obj._paramtype == "keys wise") {
          finalArr.push(obj)
        }
      })
      console.log("finalArr", finalArr)
      setParameterRulesList(finalArr);
    } catch (error) {
      console.log(error);
      setError(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    FetchApiDetails()
  }, [])

  const showModal = () => {
    // setSelectedRow(params.row)
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    // setParameter({
    //   name: "Select a Parameter Type",
    //   value: ""
    // })
    setParameterValue("")
    setParamDocValue("")
    setApiDetailState([])
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    // setParameter({
    //   name: "Select a Parameter Type",
    //   value: ""
    // })
    setParameterValue("")
    setParamDocValue("")
    setApiDetailState([])

  };

  const newRuleModalCancel = () => {
    setNewKeysModal(false);
  };

  const handleParameterValue = (e) => {
    const { name, value } = e.target;
    console.log("parametervalue", value, name)
    if (value == "Select") {
      setParameterValue("")
      return
    }
    setParameterValue(value)
  };

  const handleparamDocValue = (e) => {
    const { name, value } = e.target;
    setParamDocValue(value)
  };

  const handleAfterNewRule = () => {
    setNewRuleData({
      "_document_id": docID,
      // "_comp_id": customerName,
      "_rule_name": ruleName,
      "_description": ruleDescription,
      "useremail": initialValues.email,
      // "docSpec": {
      //   "_parameter_id": parameterValue,
      //   "_paramvalue_doc": paramDocValue,
      // },
      // "keySpec": ruleSpec
    })
    // setHideSelect(true)
    // let cloneApiDetailState = _.clon
    setParameterValue("")
    setParamDocValue("")
    setApiDetailState([])
    setApiDetailState([...apiDetailState])
    setIsModalOpen(true)

  }

  const handleAfterDocSpec = () => {
    setNewKeysModal(true)
    setNewKeys("")
    setNewParamNames("")
    setNewValueNo("")

    setNewRuleData({
      ...newRuleData,
      "docSpec": {
        "_parameter_id": parameterValue,
        "_paramvalue_doc": paramDocValue,
      },
      // "keySpec": ruleSpec

    })
  }

  const handleSelectKey = (e, index) => {
    let newKeySpecData = [...keySpecData]
    newKeySpecData[index]._documetkeys_id = e.target.value
    setKeySpecData(newKeySpecData)
  }

  const handleSelectParameters = (e, index) => {
    let newKeySpecData = [...keySpecData]
    newKeySpecData[index]._parameter_id = e.target.value
    setKeySpecData(newKeySpecData)
  }

  const handleKeyParamValue = (e, index) => {
    let newKeySpecData = [...keySpecData]
    newKeySpecData[index]._paramvalue = e.target.value
    setKeySpecData(newKeySpecData)
  }

  console.log("newRule", newRuleData)

  return (
    <>
      <Loader loading={loading} />
      <Toaster position="top-center" reverseOrder={false} />
      <ErrorCatch err={error}/>
      <div className="container"
      >
        <Modal
          // width={500}
          className="p-2"
          zIndex={900}
          open={isNewRuleModal}
          onOk={handleNewRuleOk}
          onCancel={handleNewRuleCancel}
          footer={null}
          destroyOnClose
        >
          <div class="row mb-3">
            <div className="col text-center mb-3">
              <h6>
                <b>Add New Rules</b>
              </h6>
            </div>
          </div>

          {/* <div class="row mb-3 d-flex justify-content-center">
            <div className="col-md-12">
              <select
                name="_comp_id"
                onChange={(e) => handleCustomerName(e)}
                class="form-control"
              >
                <option value={customerPlaceHolder.value} disabled selected hidden>{customerPlaceHolder.name}</option>
                {customerList.map((val) => {
                  const { comp_id, _customername } = val;
                  return <option value={comp_id}>{_customername}</option>;
                })}
              </select>
            </div>
          </div> */}

          <div class="row mb-3 d-flex justify-content-center">
            <div className="col-md-12">
              <select
                name="_documetkeys_id"
                onChange={(e) => handleDocumentType(e)}
                class="form-control"
              >
                <option value={docType.value} disabled selected hidden>{docType.name}</option>
                {apiFormatState2.map((val) => {
                  const { _document_id, _docname } = val;
                  return <option value={_document_id}>{_docname}</option>;
                })}
              </select>
            </div>
          </div>

          <div class="row mb-3 d-flex justify-content-center">
            <div className="col-md-12">
              <input
                type="text"
                name="_rule_name"
                className="form-control"
                // value={inputData._document_id}
                value={ruleName}
                // onChange={(e) => handleInput(e)}
                onChange={(e) => handleRuleName(e)}
                class="form-control"
                placeholder={"Rule Name"}
              />
            </div>
          </div>

          {/* <div class="row mb-3 d-flex justify-content-center">
                <div className="col-md-6">
                  <input
                    type="text"
                    name="_description"
                    className="form-control"
                    // value={inputData._document_id}
                    value={ruleDescription}
                    onChange={(e) => handleRuleDescription(e)}
                    class="form-control"
                    placeholder={"Rule Description"}
                  />
                </div>
              </div> */}

          <div class="row mb-3 d-flex justify-content-center">
            <div className="col-md-12">
              <textarea
                type="text"
                name="_description"
                className="form-control"
                // value={inputData._document_id}
                value={ruleDescription}
                onChange={(e) => handleRuleDescription(e)}
                class="form-control"
                placeholder={"Rule Description"}
              />
            </div>
          </div>



          {/* <div className="row mb-3 ">
              <div className="col d-flex justify-content-center gap-3 mt-3">
                <button
                  disabled={(docID !== "") && (ruleName !== "") && (ruleDescription !== "") ? false : true }
                  type="submit"
                  onClick={(e) => onSubmitRequestApi(e)}
                  class="float-start btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </div> */}

          <div className="row mb-3 ">
            <div className="col d-flex justify-content-center gap-3 mt-3">
            {/* (customerName !== "") && */}
              <button
                disabled={(docID !== "") &&  (ruleName !== "") && (ruleDescription !== "") ? false : true}
                // type="submit"
                onClick={handleAfterNewRule}
                class="float-start btn btn-primary"
              >
                Next
              </button>
            </div>
          </div>
        </Modal>

        {/* enter document spec */}
        <Modal
          className="p-2"
          zIndex={910}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="container">
            <div className="row">
              <div className="col text-center mb-3">
                <h6>
                  <b>Set Document Rules</b>
                </h6>
              </div>
            </div>

            <div className="">
              <select
                name="_parameter_id"
                onChange={(e) => handleParameterValue(e)}
                class="form-control"
              >
                {
                  apiDetailState.map((val) =>
                    // const { _parameter_id, _paramname } = val;
                    <option value={val._parameter_id}>{val._paramname}</option>
                  )
                }


              </select>

              <div className="mt-3 mb-3">
                <input
                  type="text"
                  name="_paramvalue_doc"
                  // value={inputData._paramvalue_doc}
                  value={paramDocValue}
                  // onChange={(e) => handleInput(e)}
                  onChange={(e) => handleparamDocValue(e)}
                  class="form-control"
                  placeholder="Value"
                ></input>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  // type="submit"
                  disabled={(parameterValue !== "") && (paramDocValue !== "") ? false : true}
                  onClick={handleAfterDocSpec}
                  class="float-start btn btn-primary"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </Modal>

        {/* enter key spec */}

        <div
          style={{ zIndex: "930 !important" }}
        >
          <Modal
            className="p-2"
            // zIndex= {920}
            open={newKeysModal}
            // open={isSetRuleModalOpen}
            onCancel={newRuleModalCancel}
            footer={null}
          >
            <h6>Add New Keys</h6>

            <div className="row justify-content-end">

              <div className="col-sm-2 mt-2">
                <div style={{ marginRight: "10px" }}>
                  <button
                    // style={{ padding: "0 5px" }}
                    // onClick={onSubmitRequestApi}
                    onClick={handleAddField}
                    class="btn btn-primary btn-sm"
                  // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                  >
                    {/* <BsPlus />  */}
                    Add
                  </button>

                </div>
              </div>

              <div className="col-sm-2 mt-2 d-flex justify-content-end">
                <div style={{ marginRight: "10px" }}>
                  <button
                    // style={{ padding: "0 5px" }}
                    onClick={onSubmitRequestApi}
                    class="btn btn-danger btn-sm"
                    // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                    disabled={keySpecData.length > 0 ? false : true}
                  // disabled={keySpecData.some((obj) => (obj._documetkeys_id === "" || obj._parameter_id === "" || obj._paramvalue === "")) ? true : false}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-3"
              style={{ height: "15rem", overflowY: "auto", overflowX: "hidden" }}
            >
              {keySpecData.map((obj, index) => (
                <div key={index} className="row mt-2">
                  <div className="col-sm-2 mt-2">
                    <select
                      style={{ height: "28px" }}
                      // id={obj._ruleskeys_id}
                      // value={newKeys}
                      // onChange={(e) => handleNewKeys(e)}
                      value={obj._documetkeys_id}
                      onChange={(e) => { handleSelectKey(e, index) }}

                    >
                      <option value={""} disabled selected hidden>{"keys"}</option>
                      {documentKeysList.map((val, inx) => {
                        const { _documetkeys_id, _key } = val;
                        return <option key={inx} value={_documetkeys_id}>{_key}</option>;
                      })}
                    </select>
                  </div>

                  <div className="col-sm-3 mt-2">
                    <select
                      style={{ height: "28px" }}
                      // id={obj._ruleskeys_id}
                      // value={newParamNames}
                      // onChange={(e) => handleNewParamNames(e)}
                      value={obj._parameter_id}
                      onChange={(e) => { handleSelectParameters(e, index) }}

                    >
                      <option value={""} disabled selected hidden>{"Parameters"}</option>
                      {parameterRulesList.map((val, inx) => {
                        const { _parameter_id, _paramname } = val;
                        return <option key={inx} value={_parameter_id}>{_paramname}</option>;
                      })}
                    </select>
                  </div>

                  <div className="col-sm-5 mt-2">
                    <input
                      style={{ height: "28px" }}
                      type="text"
                      name="newValueNo"
                      className="w-50"
                      // id={"docKeys"}
                      // value={newValueNo}
                      // onChange={(e) => handleNewValueNo(e)}
                      value={obj._paramvalue}
                      onChange={(e) => { handleKeyParamValue(e, index) }}

                      class="form-control"
                      placeholder="value"

                    />
                  </div>

                  {/* <div className="col-sm-2 mt-2">
                <div style={{ marginRight: "10px" }}>
                  <button
                    // style={{ padding: "0 5px" }}
                    // onClick={onSubmitRequestApi}
                    onClick={handleAddField}
                    class="btn btn-primary btn-sm"
                  // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                  >
                    <BsPlus />
                  </button>

                </div>
              </div> */}

                  <div className="col-sm-2 mt-2">
                    <div style={{ marginRight: "10px" }}>
                      <button
                        // style={{ padding: "0 5px" }}
                        onClick={() => handleDeleteField(index)}
                        class="btn btn-danger btn-sm"
                      // disabled={((newValueNo !== "") && (newKeys !== "") && (newParamNames !== "")) ? false : true}
                      >
                        <BsFillTrashFill />
                      </button>
                    </div>
                  </div>

                </div>
              ))}
            </div>

          </Modal>
        </div>
      </div>
    </>
  );
}

export default AddNewRule;
